import { IaOLO } from "../interfaces/aolo.interface";
import { Util } from "../utils/util";
import '../../css/online-ordering/gift-card.css';

export class GiftCards {

    private _giftCards: GiftCards[];
    private _passcode: string | null;

    constructor(passcode : string | null) {
        this._giftCards = [];
        this._passcode = passcode;
        this._init(aOLO);
    }

    private _init = (localAolo: IaOLO): void => {
        this._renderPage();
        this._setEventListeners();
        this.OpenDialog(localAolo);
    }

    public OpenDialog = (localAolo: IaOLO): void => {
        Util.DialogFadeIn("dia_gift_cards", localAolo);
    }

    public CloseDialog = (): void => {
        Util.DialogFadeOut("dia_gift_cards");
    }

    private _renderPage() {
        this._loadGiftCards();
    }

    private _setEventListeners = (): void => {
        const self = this;
        Util.setElement("onclick", "btn_gift_cards_close", () => { self.CloseDialog(); });
    }

    private _loadGiftCards = (): void => {
        const result = aOLOModules.LoyaltyProvider.fetchGiftCards(this._passcode);
        return;
    }

}
