import { DialogCreators } from "../../../utils/dialog-creators";
import { CustomerProviderEnum } from "../../../types/enums";
import { Names } from "../../../utils/i18n";
import { IaOLO } from "../../../interfaces/aolo.interface";
import { ISignInResponse } from "../../api/interfaces/profile-service.interfaces";
import { CustomerService } from "../../api/customer-service";
import { ICustomerProvider } from "../interfaces/customer-provider.interface";
import { ISendResetPassword, ISignInByPhone } from "../../api/interfaces/customer-service.interface";

export class AdoraCustomerProvider implements ICustomerProvider {
    private _customerProvider: CustomerProviderEnum = CustomerProviderEnum.ADORA;
    private _customerService: CustomerService;
    private _aOLO: IaOLO;

    constructor(localAolo: IaOLO, customerService: CustomerService) {
        this._aOLO = localAolo;
        this._customerService = customerService;
    }

    signInByPhone = async (phone: string, countryId: number, code: string, app: boolean, rememberMe: boolean): Promise<ISignInResponse> => {
        const payload: ISignInByPhone = {
            store_key: this._aOLO.storeInfo.StoreKey,
            phone: phone,
            country_id: countryId,
            code: code,
            app: app,
            remember_me: rememberMe
        };

        const response = await this._customerService.signInByPhone(payload);
        return response;
    }

    forgotPassword = async (email: string): Promise<void> => {
        const payload: ISendResetPassword = {
            store_key: this._aOLO.storeInfo.StoreKey,
            email: email
        };

        const response = await this._customerService.forgotPassword(payload);
        if (response)
            DialogCreators.messageBoxOk(Names("PasswordReset").replace("{email}", email), this._aOLO.buttonHoverStyle);
        else
            DialogCreators.messageBoxOk(Names("UnableResetPassword"), this._aOLO.buttonHoverStyle);
    }
}