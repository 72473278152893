type BindableElement = HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement | HTMLDivElement;

interface BindingInfo<T> {
    key: keyof T;
    element: BindableElement;
    handleEvent: EventListener;
}

export class TwoWayBindingHelper<T extends object> {
    private _obj: T;
    private readonly _bindings: Map<keyof T, BindingInfo<T>[]>;

    constructor(obj: T) {
        this._obj = obj;
        this._bindings = new Map<keyof T, BindingInfo<T>[]>();
    }

    public bindToInput(key: keyof T, elementId: string): void {
        const element = document.getElementById(elementId) as BindableElement;
        if (!element)
            return;

        const handleEvent = this._createEventHandler(key, element);

        element.addEventListener('input', handleEvent);
        element.addEventListener('change', handleEvent);

        const bindingInfo: BindingInfo<T> = {
            key,
            element,
            handleEvent,
        };

        if (!this._bindings.has(key)) {
            this._bindings.set(key, []);
        }
        this._bindings.get(key)!.push(bindingInfo);

        // Define getter and setter for the property if not already defined
        if (!Object.getOwnPropertyDescriptor(this._obj, key)) {
            let value = this._obj[key];
            Object.defineProperty(this._obj, key, {
                get: () => value,
                set: (newValue) => {
                    value = newValue;
                    this._updateElementsForKey(key, newValue);
                },
                configurable: true,
                enumerable: true,
            });
        }

        // Initialize element with the current value
        this._updateElement(element, this._obj[key]);
    }

    private _createEventHandler(key: keyof T, element: BindableElement): EventListener {
        return () => {
            const newValue = this._getElementValue(element);
            if (this._obj[key] !== newValue) {
                this._obj[key] = newValue;
            }
        };
    }

    private _updateElementsForKey(key: keyof T, newValue: any): void {
        const bindings = this._bindings.get(key);
        if (bindings) {
            for (const binding of bindings) {
                this._updateElement(binding.element, newValue);
            }
        }
    }

    private _updateElement(element: BindableElement, value: any): void {
        if (element instanceof HTMLInputElement) {
            if (element.type === 'checkbox' || element.type === 'radio')
                element.checked = !!value;
            else
                element.value = value != null ? String(value) : '';
        } else if ('value' in element)
            (element as HTMLInputElement).value = value != null ? String(value) : '';
    }

    private _getElementValue(element: BindableElement): any {
        if (element instanceof HTMLInputElement) {
            if (element.type === 'checkbox' || element.type === 'radio')
                return element.checked;
            else
                return element.value;
        } else if ('value' in element)
            return (element as HTMLInputElement).value;
        else
            return null;
    }

    public clearListeners(): void {
        for (const bindings of this._bindings.values()) {
            for (const binding of bindings) {
                binding.element.removeEventListener('input', binding.handleEvent);
                binding.element.removeEventListener('change', binding.handleEvent);
            }
        }
        this._bindings.clear();
    }
}