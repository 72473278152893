import './menu-item-card.css';

import { IDataItem } from '../../../interfaces/data.interface';
import { Common } from '../../../common';
import { Util } from '../../../utils/util';

export class MenuItemCard {
    private _item: IDataItem;
    private _element: HTMLElement;

    constructor(item: IDataItem, languageCode: string, handler?: (event: MouseEvent) => void) {
        this._item = item;
        this._element = this._createElement(languageCode);
        this._addClickHandler(handler);
    }

    private _createElement(languageCode: string): HTMLElement {
        const name = Common.GetName(this._item.Names, languageCode);
        const description = Common.GetDescription(this._item.Descriptions, languageCode);

        const html = `
            <div id="div_menu_items_item_${this._item.ItemId}" class="menu-item-card-small" data-id="${this._item.ItemId}">
                <div class="menu-item-card-small__image-container">
                    ${(this._item.ImageURL && this._item.ImageURL != "") ? `<img class="menu-item-card-small__image" src="${this._item.ImageURL}" alt="${name}">` : ""}
                </div>
                <div class="menu-item-card-small__label-container">
                    <span class="menu-item-card-small__label" ltagj="${Util.toLtagj(this._item.Names)}">${name}</span>
                    <span class="menu-item-card-small__label-desc" ltagj="${Util.toLtagj(this._item.Descriptions)}">${description}</span>
                </div>
            </div>`;

        return Util.createHtmlElementFromTemplate(html);
    }

    public get element(): HTMLElement {
        return this._element;
    }

    private _addClickHandler(handler?: (event: MouseEvent) => void): void {
        if (!handler)
            return;

        Util.setElement("onclick", this._element, handler);
    }
}