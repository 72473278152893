import EventEmitter from 'eventemitter3';

export const createEventBus = () => new EventEmitter();

export enum EventTypes {
    ORDER_TYPE_CHANGED = "order_type_change",
    LOGGED_IN = "logged_in",
    LOGGED_OUT = "logged_out",
    LOCATION_CHANGE = "location_change",
    LOCATION_REROUTE = "location_reroute",
    DELIVERY_ADDRESS_CHANGED = "delivery_address_changed",
    STORE_CHANGED = "store_changed",
    DATA_RETRIEVED = "data_retrieved"
}