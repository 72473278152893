import { Common } from '../common';
import { Util } from '../utils/util';
import { Names } from '../utils/i18n';
import { IMyHistory } from './interfaces/history.interface';

import '../../css/shared/history.css';
import { IaOLO } from '../interfaces/aolo.interface';

/**
 * Class representing a user's loyalty history.
 */
export class MyHistory {
    private _loyaltyHistory: IMyHistory[] = [];
    private _historyDate: string = "1900-01-01";
    /**
     * Indicates whether the entire loyalty history has been retrieved.
     */
    private _historyComplete: boolean = false;

    constructor() {
        this._initPage();
    }

    /**
     * Initializes the page and sets up event handlers.
     * @private
     */
    private _initPage = (): void => {
        this._setPage();
        this._setEventListeners();
    }

    /**
     * Sets up the loyalty history page.
     * @private
     * @async
     * @returns A promise that resolves when the loyalty history has been retrieved and rendered.
     */
    private _setPage = async (): Promise<void> => {
        await this._getLoyaltyHistory();
        const noHistoryDiv = document.getElementById("div_history_no_history");
        const historyDiv = document.getElementById("div_history_history");

        if (!this._historyComplete) {
            Util.hideElement("div_history_no_history");
            Util.showElement("btn_history_show_more");
            this._renderPage(aOLO);
            let showMoreBtn = document.getElementById("btn_history_show_more");
            if (showMoreBtn)
                showMoreBtn.onclick = async () => { await this._setPage(); };
        } else {
            if (noHistoryDiv && historyDiv && historyDiv.innerHTML == "") {
                noHistoryDiv.classList.remove("hidden");
                noHistoryDiv.innerHTML = Names("MyHistory_FirstTimeMessage");
            }

            Util.hideElement("btn_history_show_more");
        }
    }

    private _setEventListeners = (): void => {
        const self = this;
        Util.setElement("onclick", "btn_history_close", () => { self.closeDialog(); });
    }

    public closeDialog = (): void => {
        Util.DialogFadeOut("dia_history");
    }

    /**
     * Retrieves the loyalty history from the server.
     * @private
     * @async
     * @returns {Promise<void>} - A promise that resolves when the loyalty history has been retrieved.
     */
    private _getLoyaltyHistory = async (): Promise<void> => {
        let loyaltyHistory = await aOLO.Modules.ProfileService.fetchLoyaltyHistory(this._historyDate, aOLO) || [];
        this._historyComplete = (loyaltyHistory.length == 0);
        this._loyaltyHistory = this._loyaltyHistory.concat(loyaltyHistory);
    }

    /**
     * Renders the loyalty history on the page.
     * @private
     * @returns {void}
     */
    private _renderPage = (localAolo: IaOLO): void => {
        let mainDiv = document.getElementById("div_history_history");

        if (mainDiv) {
            mainDiv.innerHTML = "";
            let divContainer = document.getElementById("div_history_month_template") as HTMLElement;

            if (typeof divContainer !== 'object')
                throw Error("No Div Found");

            let currentMonth = 0;
            let currDiv: HTMLElement | null = null;
            this._loyaltyHistory.forEach((item: IMyHistory, index: number) => {
                let month = new Date(item.date).getMonth() + 1;
                let year = new Date(item.date).getFullYear();

                if (currentMonth != month) {
                    if (index !== 0 && currDiv && mainDiv)
                        mainDiv.appendChild(currDiv);
                    currentMonth = month;

                    // Create a header
                    currDiv = divContainer.cloneNode(true) as HTMLElement;
                    if (currDiv) {
                        currDiv.classList.remove("hidden");
                        currDiv.id = `div_history_month_${Common.getMonthText(month)}_${year}`;
                        let currHeader = currDiv.querySelector(".history-header");
                        if (currHeader)
                            currHeader.innerHTML = `<span ltag="${Common.getMonthText(month) + "Only"}">${Names(Common.getMonthText(month) + "Only")}</span> ${year}`;
                        let rows = currDiv.querySelector(".history-rows");
                        if (rows)
                            rows.innerHTML == "";
                    }
                }

                // Create a row
                let currRows = currDiv ? currDiv.querySelector(".history-rows") : null;
                let rows = divContainer.querySelector(".history-row");
                let currRow = rows ? rows.cloneNode(true) as HTMLElement : null;
                if (currRow)
                    currRow.classList.remove("hidden");

                let type = "";
                let earned = "";
                let info = "";

                switch (item.type) {
                    case 1:
                        if (currRow)
                            currRow.children[0].classList.add("icon-C-Reward");
                        earned = (item.earned) ? "EarnedREW" : "Redeemed";
                        info = Common.GetName(JSON.parse(item.info), localAolo.Temp.languageCode);
                        break;
                    case 2:
                        if (currRow)
                            currRow.children[0].classList.add("icon-Z-Savings");
                        type = (item.info != "1") ? "points" : "point";
                        earned = (item.earned) ? "EarnedPTS" : "Redeemed";
                        info = item.info;
                        break;
                    case 3:
                        if (currRow)
                            currRow.children[0].classList.add("icon-Y-Loyalty");
                        earned = (item.earned) ? "Achieved" : "DowngradedTo";
                        info = Common.GetName(JSON.parse(item.info), localAolo.Temp.languageCode);
                        break;
                }

                if (currRow) {
                    currRow.children[1].innerHTML = `${new Date(item.date).toLocaleDateString()} - <span ltag="${earned}">${Names(earned)}</span> <span ${item.type != 2 ? `ltagj='${item.info}'` : ``}>${info}</span> ${item.type == 2 ? `<span ltag="${type}">${Names(type)}</span>` : ``}`;
                    if (currRows)
                        currRows.appendChild(currRow);
                }

                if (index == this._loyaltyHistory.length - 1) {
                    if (mainDiv && currDiv)
                        mainDiv.appendChild(currDiv);
                    this._historyDate = item.date;
                }
            })
        }
    }
}