import { IDataCategory } from "../interfaces/data.interface";
import { IName } from "../interfaces/global.interfaces";
import { Common } from "../common";
import { Util } from "../utils/util";
import { OnlineOrderingUtil } from "./online-ordering-util";
import { IaOLO } from "../interfaces/aolo.interface";

import '../../css/online-ordering/categories.css';

export class Categories {
    private _categories: IDataCategory[] = [];
    private _localAolo: IaOLO;
    constructor(categories: IDataCategory[], aOLO: IaOLO) {
        this._categories = categories;
        this._localAolo = aOLO;
        this._init();
    }

    private _init = (): void => {
        this._renderPage();
        this._setEventListeners();
    }

    private _renderPage = (): void => {
        let divCats = document.getElementById("div_categories");
        if (!divCats)
            return;

        Util.removeChildren("div_categories");
        const btnClass = (this._localAolo.Temp.ButtonHoverStyles[this._localAolo.data.Settings.OLBTN] != "") ? this._localAolo.Temp.ButtonHoverStyles[this._localAolo.data.Settings.OLBTN] : "";
        let html = "";
        let counter = 0;
        this._categories.forEach((category) => {
            if ((this._localAolo.data.Settings.ISCC && !this._categoryHasItems(category.ItemWebCategoryId, true)) || (!this._categoryHasItems(category.ItemWebCategoryId) && !category.IsMealDeal))
                return;

            if (counter === 0)
                html += `<div id="div_categories_arrow" class="icon-show-more-after" ltagj="${Util.toLtagj(category.Names)}">${Common.GetName(category.Names, this._localAolo.Temp.languageCode)}</div>`;
            counter++;
            html += `
                <a id="a_category_${category.ItemWebCategoryId}" name="catLink" class="${btnClass}" data-catid="${category.ItemWebCategoryId}" data-mealdeal="${category.IsMealDeal}" data-catname="${Util.toLtagj(category.Names)}">
                    <div ltagj="${Util.toLtagj(category.Names)}">${Common.GetName(category.Names, this._localAolo.Temp.languageCode)}</div>
                </a>`;
        });

        divCats.innerHTML = html;
    }

    private _setEventListeners = (): void => {
        const self = this;
        Util.setElement("onclick", "div_categories_arrow", (event: MouseEvent) => { self._categoryClickedArrow(event); });

        const categoryAs = Array.from(document.getElementsByName("catLink")) as HTMLInputElement[];
        for (const category of categoryAs) {
            category.onclick = function (event: MouseEvent) {
                self._categoryClicked(event, Number(category.dataset.catid), JSON.parse(decodeURIComponent(category.dataset.catname || "")) || []);
            };
        }
    }

    private _categoryClicked = (event: MouseEvent, webCategoryId: number, categoryName: IName[]): void => {
        let catHeight = 0;

        Util.showElement("div_items");
        Util.hideElement("div_coupons");

        let divCats = document.getElementById("div_categories");
        if (divCats) {
            let divCatsParent = divCats.parentNode as HTMLElement;
            if (divCatsParent?.classList.contains("containerTopCat"))
                catHeight = divCats.offsetHeight;
        }

        let anchor = document.getElementById(`h1CatAnchor_${webCategoryId}`);
        if (anchor) {
            let offSet = -20;
            if (this._localAolo.layoutStyleID === 2 || this._localAolo.layoutStyleID === 3) {
                offSet = -80;
            }
            let anchorTop = anchor.offsetTop - this._localAolo.Temp.HeaderHeight - catHeight + offSet;
            OnlineOrderingUtil.GUI_ScrollTo(anchorTop, this._localAolo.Temp.HeaderHeight);
        }

        let arrowDiv = document.getElementById("div_categories_arrow");
        if (arrowDiv) {
            arrowDiv.innerText = Common.GetName(categoryName, this._localAolo.Temp.languageCode);
            arrowDiv.setAttribute("ltagj", Util.toLtagj(categoryName));
        }
        this._categoryClickedArrow(event);

        if (this._localAolo.Modules.DataLayer)
            this._localAolo.Modules.DataLayer.itemScrollListeners();
    }

    private _categoryClickedArrow = (event: MouseEvent): void => {
        if (!this._localAolo.mediaMax576.matches && !this._localAolo.mediaMax840.matches)
            return;

        const catLinks = document.getElementsByName("catLink");
        const expanded = catLinks[0].classList.contains("expand");

        catLinks.forEach(link => {
            if (expanded) {
                link.classList.remove("expand");
            } else
                link.classList.add("expand");
        });

        OnlineOrderingUtil.ToggleOrderItems(true, this._localAolo);
    }

    private _categoryHasItems = (catID: number, isCallCenter: boolean = false): boolean => {
        const storeItems = isCallCenter ? OnlineOrderingUtil.getCallCenterStore(this._localAolo.Order.StoreID, this._localAolo.data.Stores)?.Items ?? "" : "";
        const categoryItems = this._localAolo.data.Items.filter(mItem => mItem.WebCategoryId === catID);

        if (isCallCenter) {
            for (const mItem of categoryItems) {
                const itemId = `*${mItem.ItemId}*`;
                if (storeItems.includes(itemId) && OnlineOrderingUtil.GetItemSizes(mItem, this._localAolo.Order.OrderTypeID).length > 0)
                    return true;
            }
        } else {
            for (const mItem of categoryItems) {
                if (OnlineOrderingUtil.GetItemSizes(mItem, this._localAolo.Order.OrderTypeID).length > 0)
                    return true;
            }
        }

        return false;
    }
}
