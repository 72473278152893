import { IDataDonation, IPaymentOptions } from "../interfaces/data.interface";
import { IServiceGetGiftCardBalance } from "./interfaces/online-ordering-service.interface";
import { Names } from "../utils/i18n";
import { Common } from "../common";
import { CardBrand, Curbside, LoggedInStatus, LoyaltyProvider, OrderType, CreditCardAvsOption, CreditCardOption } from "../types/enums";
import { Util } from "../utils/util";
import { DialogCreators } from "../utils/dialog-creators";
import { OnlineOrderingUtil } from "./online-ordering-util";
import {
    calculateTip, CheckCCValue, createCashOption, createCreditOnDeliveryOption, GetCardBrand, getNotificationSettings, Gratuity_Format_Value, GUI_CHEOUT_Payment,
    setHeartland, SetOtherTip, Submit, OpenPaySubmit, txtCCkeyUp, txtCCkeyDown
} from "./checkout-util";
import { IaOLO } from "../interfaces/aolo.interface";
import { TwoWayBinding } from "../two-way-binding";
import { GiftCards } from "./gift-cards";

import '../../css/online-ordering/checkout.css';

export class Checkout {
    private _item: any;
    private _bindings: TwoWayBinding[] = [];
    private _OrderResult: Function;
    private _submitDataValidating: boolean = true;
    private _isDeliveryBilling: boolean = false;

    constructor(OrderResult: Function) {
        this._OrderResult = OrderResult;
        this._item = this._initiateModel();
        this._init();
    }

    private _initiateModel = (): any => {
        return {
            redeemCurrency: "",
            CountryCodeId: 0,
            NotifyCountryCodeId: 0,
            phoneFormatLanguage: Util.getDefaultCultureCode(aOLO.User.CountryCodeId || aOLO.storeInfo.Country.CountryID || 1, aOLO.data.Countries)
        };
    }

    private _init = async (): Promise<void> => {
        const success = await aOLOModules.LoyaltyProvider.batchComparison(true);
        if (!success)
            return;

        this._renderPage();
        this._setTwoWayListeners();
        this._setEventListeners();
        this._openDialog(aOLO);
        this._populateLists();
    }

    private _setTwoWayListeners = (): void => {
        this._bindings = [
            new TwoWayBinding(this._item, "redeemCurrency", document.getElementById("txt_checkout_redeem_currency_amount")),
            new TwoWayBinding(this._item, "CountryCodeId", document.getElementById("dropdown_checkout_country_code")),
        ];
        const notifyCountryCodeIdElement = document.getElementById("dropdown_checkout_notification_country_code");
        if (notifyCountryCodeIdElement)
            this._bindings.push(new TwoWayBinding(this._item, "NotifyCountryCodeId", notifyCountryCodeIdElement));
    }

    private _renderPage(): void {
        this.CheckOutContinue();
        this._GUI_DonationSection();
        this._setRedeemCurrencyGUI();
        this._GUI_SetupCheckoutDialog();
        this._GUI_CHKOUT_CC_Exp_Year();


        Util.setElement("innerHTML", "div_checkout_order_type", `<span>${OnlineOrderingUtil.GetOrderTypeName(aOLO.Order.OrderTypeID, aOLO)}</span>`);
        Util.hideElement("lbl_checkout_credit_expect_call");

        if (aOLO.Modules.DataLayer) {
            aOLO.Modules.DataLayer.begin_checkout(aOLO.Order.Items);
            aOLO.Modules.DataLayer.add_shipping_info(aOLO.Order.Items);
        }
    }

    /**
     * Sets up event listeners for phone fields.
     * @private
     * @returns {void}
     */
    private _setPhoneListener = (element: string, format: string) => {
        // Phone
        const phone = document.getElementById(element);
        if (!phone)
            return;

        phone.setAttribute('placeholder', Util.formatPhoneNumber("5555555555", format));

        phone.onkeyup = (event) => {
            const key = event.key;
            // Check if the pressed key is the backspace key
            if (key === "Backspace" || key === "Delete") {
                // Handle backspace
                // You might want to do something specific here when backspace is pressed
            } else {
                // If the key pressed is not backspace, then format the phone number
                Util.formatPhoneNumberAuto(phone.id, format);
            }
        }
    }

    /**
     * Populates dropdowns for country code selectors
     * @private
     * @returns {void}
     */
    private _populateLists = (): void => {
        Util.populateCountryCodeSelectList("dropdown_checkout_country_code", "txt_checkout_phone", aOLO.User?.CountryCodeId || aOLO.storeInfo?.Country?.CountryID || 1)
        Util.populateCountryCodeSelectList("dropdown_checkout_notification_country_code", "tel_checkout_notification_text", aOLO.User?.MarketingCountryId || aOLO.storeInfo?.Country?.CountryID || 1)
    }



    private _setEventListeners = (): void => {
        const self = this;

        Util.setElement("onchange", "chk_checkout_contactless", () => { self.ContactLess_CurbSide(); });
        Util.setElement("onchange", "chk_checkout_curbside", () => { self.ContactLess_CurbSide(); });

        Util.setElement("onchange", "rdo_checkout_in_store", () => { GUI_CHEOUT_Payment(); });
        Util.setElement("onchange", "rdo_checkout_cash", () => { GUI_CHEOUT_Payment(); });
        Util.setElement("onchange", "rdo_checkout_existing_credit", () => { GUI_CHEOUT_Payment(); });
        Util.setElement("onchange", "rdo_checkout_credit", () => { GUI_CHEOUT_Payment(); });
        Util.setElement("onchange", "rdo_checkout_credit_on_delivery", () => { GUI_CHEOUT_Payment(); });

        Util.setElement("onchange", "chk_checkout_billing_add", () => { self._setDeliveryAddressAsBilling(); });

        Util.setElement("onchange", "rdo_checkout_tip_0", () => { calculateTip(); });
        Util.setElement("onchange", "rdo_checkout_tip_10", () => { calculateTip(); });
        Util.setElement("onchange", "rdo_checkout_tip_15", () => { calculateTip(); });
        Util.setElement("onchange", "rdo_checkout_tip_20", () => { calculateTip(); });
        Util.setElement("onchange", "rdo_checkout_tip_other", () => { SetOtherTip(); });

        const tip = document.getElementById("txt_checkout_tip") as HTMLInputElement;
        if (tip) {
            tip.oninput = () => { self.Gratuity_Edited(); };
            tip.onblur = () => { Gratuity_Format_Value(); };
            tip.onfocus = () => {
                if (tip)
                    tip.select();
            };
        }

        Util.setElement("onclick", "btn_checkout_privacy_terms_terms", () => { Common.showTerms(aOLO); });
        Util.setElement("onclick", "btn_checkout_privacy_terms_privacy", () => { Common.showPrivacy(aOLO); });

        Util.setElement("onclick", "btn_checkout_gift_card_apply", () => { self.GetGiftCardBalance(); });

        const ccTxt = document.getElementById("txt_checkout_credit_entry_card_number");
        if (ccTxt) {
            ccTxt.oninput = () => { CheckCCValue(); };
            ccTxt.onkeyup = (event) => { txtCCkeyUp(event); };
            ccTxt.onkeydown = (event) => { txtCCkeyDown(event); };
        }
        if (OnlineOrderingUtil.IsOpenPay()) { //openpay
            Util.setElement("onclick", "btn_checkout_submit", () => { OpenPaySubmit(); });
        }else{
            Util.setElement("onclick", "btn_checkout_submit", () => { Submit(false); });
        }
        Util.setElement("onclick", "btn_checkout_close", () => { self.CloseDialog(); });

        const emailChk = document.getElementById("chk_checkout_notification_email") as HTMLInputElement;
        if (emailChk)
            emailChk.onclick = function () {
                const input = document.getElementById("eml_checkout_notification_email") as HTMLInputElement;
                if (input) {
                    input.disabled = !emailChk.checked;
                    if (!input.disabled)
                        input.value = aOLO.User.Email || Util.getElementValue("txt_checkout_email");
                }
            };

        const txtChk = document.getElementById("chk_checkout_notification_text") as HTMLInputElement;
        if (txtChk)
            txtChk.onclick = function () {
                const countryCodeInput = document.getElementById("dropdown_checkout_notification_country_code") as HTMLInputElement;
                let countryId = aOLO.User?.CountryCodeId || aOLO.storeInfo?.Country?.CountryID;
                if (!countryCodeInput)
                    return;

                countryCodeInput.disabled = !txtChk.checked;
                if (!countryCodeInput.disabled) {
                    const customerPhoneCountryCode = document.getElementById("dropdown_checkout_notification_country_code") as HTMLSelectElement;
                    if (customerPhoneCountryCode) {
                        const selectedOption = customerPhoneCountryCode.options[customerPhoneCountryCode.selectedIndex];
                        countryId = Number(selectedOption?.value) || aOLO.User?.CountryCodeId || aOLO.storeInfo?.Country?.CountryID;

                        const defaultCountryId = countryId || 1;
                        const defaultOption = countryCodeInput.querySelector(`[vaue='${defaultCountryId}']`) as HTMLOptionElement;
                        if (defaultOption) {
                            countryCodeInput.value = customerPhoneCountryCode.options[customerPhoneCountryCode.selectedIndex]?.value || defaultOption.value || Util.getElementValue("dropdown_checkout_notification_country_code");
                            defaultOption.selected = true;
                            defaultOption.text = defaultOption.dataset.abr ? defaultOption.dataset.abr : defaultOption.text;
                        }
                    }
                }

                const input = document.getElementById("tel_checkout_notification_text") as HTMLInputElement;
                if (input) {
                    input.disabled = !txtChk.checked;
                }
            };

        Util.setElement("onclick", "btn_checkout_redeem_currency_apply", () => { this._applyRedeemCurrency(); });
        Util.setElement("onclick", "btn_checkout_import_digital_gift_card", () => { this._giftCardPasscodeDialog() });
    }

    private _openDialog = (localAolo: IaOLO) => {
        Util.DialogFadeIn("dia_checkout", localAolo);
    }

    private _giftCardPasscodeDialog = (): void => {
        DialogCreators.InputBox(Names("EnterPasscode"),
            false,
            null,
            null,
            aOLO,
            [{
                text: Names("Confirm"), close: true, callBack: (passcode) => {
                    new GiftCards(passcode);
                }

            }, {
                text: Names("Cancel"), close: true, callBack: null
            }], null, null);
        return;
    }

    public CheckOutContinue() {
        // Clear Contact Info Fields
        Util.setElement("value", "txt_checkout_name", "");
        Util.setElement("value", "txt_checkout_last_name", "");
        Util.setElement("value", "txt_checkout_email", "");
        Util.setElement("value", "txt_checkout_phone", "");

        if (aOLO.Order.OrderTypeID === OrderType.DELIVERY) {
            const zoneMinDelAmount = Util.Float2(this._getMinimumDeliveryAmtForZone(aOLO.Temp.Address?.ZoneID));
            if (aOLO.Order.SubTotal - aOLO.Order.Discount < Util.Float2(aOLO.Order.OrderTypeSubType.MinAmount) + zoneMinDelAmount) {
                DialogCreators.messageBoxOk(Names("MinDelAmt").replace("??", Util.formatNumber((aOLO.Order.OrderTypeSubType.MinAmount) + zoneMinDelAmount)), aOLO.buttonHoverStyle);
                this.CloseDialog();
                return;
            }

            if ((aOLO.data.Settings.CRGMIL > 0 || aOLO.data.Settings.EMILES > 0) && (!!aOLO.Temp?.Address?.Latitude && !!aOLO.Temp?.Address?.Longitude)) { //setting 107 & 86
                const zone = aOLO.data.DeliveryZones.find(zone => zone.ZoneId === aOLO.Temp?.Address?.ZoneID);
                if (zone) {
                    //@ts-ignore
                    OnlineOrderingUtil.GetDeliveryDistanceFromStore(zone.ZoneCharge, new google.maps.LatLng(Util.Float12(aOLO.storeInfo.Latitude), Util.Float12(aOLO.storeInfo.Longitude)),
                        //@ts-ignore
                        new google.maps.LatLng(Util.Float12(aOLO.Temp.Address.Latitude), Util.Float12(aOLO.Temp.Address.Longitude)), aOLO, aOLOModules);
                }
                else {
                    //@ts-ignore
                    OnlineOrderingUtil.GetDeliveryDistanceFromStore(0, new google.maps.LatLng(Util.Float12(aOLO.storeInfo.Latitude), Util.Float12(aOLO.storeInfo.Longitude)),
                        //@ts-ignore
                        new google.maps.LatLng(Util.Float12(aOLO.Temp.Address.Latitude), Util.Float12(aOLO.Temp.Address.Longitude)), aOLO, aOLOModules);
                }
            }
        }

        if (aOLO.data.Settings.CCG && aOLO.data.Settings.TPDS && aOLO.data.Settings.TPDM === 1) //setting 77 & 164 & 181
            calculateTip();

        if (aOLO.data.Settings.CRBMSG !== "") //setting 171
            Util.setElement("innerText", "p_checkout_curbside_info", aOLO.data.Settings.CRBMSG);

        if (aOLO.data.Settings.CCI && OnlineOrderingUtil.IsHeartland(aOLO.data.Settings.ISCC, aOLO.Order.StoreID, aOLO.data.Settings.HPK) && !aOLO.Temp.HeartlandSet)
            setHeartland();

        this._GUI_CHKOUT_Set_Dialog_Elements(true);
    }

    private _GUI_CHKOUT_Set_Dialog_Elements = (setPayments: boolean): void => {
        const payOptions: IPaymentOptions | undefined = aOLO.data.Settings.OPO.find(x => x.OrderTypeID == aOLO.Order.OrderTypeID);
        if (!(aOLO.User.GU == LoggedInStatus.EXTERNAL || aOLO.User.GU == LoggedInStatus.LOGGEDIN)) {
            Util.hideElement("div_checkout_contact_info");
            Util.showElement("div_checkout_contact");
        } else {
            Util.showElement("div_checkout_contact_info");
            Util.hideElement("div_checkout_contact");

            const phone = (aOLO.User.Phone !== "") ? aOLO.User.Phone : "";
            Util.setElement("innerText", "div_checkout_customer_name", `${aOLO.User.Name} ${aOLO.User.LastName}`);
            Util.setElement("innerText", "div_checkout_customer_email", aOLO.User.Email);
            const country = aOLO.data.Countries.find(x => x.CountryID === aOLO.User.CountryCodeId) || aOLO.storeInfo.Country;
            Util.setElement("innerText", "div_checkout_customer_country_code", country.CountryCode || "");
            const phoneFormat = Util.getPhoneFormat(phone || "", country.IsoCode);
            Util.setElement("innerText", "div_checkout_customer_phone", Util.formatPhoneNumber(phone || "", phoneFormat));
        }

        const notif = getNotificationSettings();
        if (aOLO.User.GU == LoggedInStatus.LOGGEDIN) {
            Util.hideElement("div_checkout_privacy_terms");

            if (notif.IsEmailNotify && !notif.EMAIL.dataRaw)
                Util.setElement("value", "eml_checkout_notification_email", aOLO.User.Email);
            if (notif.IsTextNotify && !notif.TEXT.dataRaw)
                Util.setElement("value", "tel_checkout_notification_text", Util.formatPhoneNumber(aOLO.User.Phone || "", Names("PhoneFormat", this._item.phoneFormatLanguage)));
            if (notif.IsTextNotify && !notif.TEXTCOUNTRYCODE.dataRaw)
                Util.setElement("value", "dropdown_checkout_country_code", aOLO.User.CountryCodeId || 1);
            if (notif.IsEmailNotify)
                Util.hideElement("div_checkout_notification_email");
        } else {
            if (notif.IsEmailNotify)
                Util.showElement("div_checkout_notification_email");
        }

        if (aOLO.data.Settings.CURBSD > 0 && aOLO.Order.OrderTypeID === OrderType.TAKE_OUT) {
            Util.showElement("div_checkout_curbside");

            if (aOLO.data.Settings.CURBSD == Curbside.CUSTOMER_CAR_INFO)
                Util.showElement("div_checkout_car");
            else
                Util.hideElement("div_checkout_car");
        } else
            Util.hideElement("div_checkout_curbside");

        if (aOLO.Order.OrderTypeID === OrderType.DELIVERY && payOptions?.CreditCardAvsOption !== CreditCardAvsOption.DO_NOT_VALIDATE) {
            Util.showElement("div_checkout_delivery");
            Util.showElement("lbl_checkout_credit_address_chk");
            Util.showElement("div_checkout_billing_add");
        } else {
            Util.hideElement("div_checkout_delivery");
            Util.hideElement("lbl_checkout_credit_address_chk");
            Util.hideElement("div_checkout_billing_add");
        }

        if (setPayments)
            this._GUI_CHKOUT_PaymentOptions();

        if (aOLO.data.Settings.TGI && aOLO.data.Settings.TGI.length > 0 && !aOLO.QrCode) {
            Util.showElement("div_checkout_togo_supplies");
            if (document.getElementById("div_checkout_togo_supplies_options")?.children.length === 0)
                this._GUI_CHKOUT_TogoSupplies();
        } else
            Util.hideElement("div_checkout_togo_supplies");


    }

    private _getCreditCardTypeByID = (cardTypeID: number): string => {
        const cardTypes = ["", "Visa", "MasterCard", "Discover", "Amex", "JCB", "DinersClub", "enRoute", "Interac", "Flash", "Debit", "GiftCard", "EBT", "LevelUp", "Voyager", "Wex", "Other"];
        return cardTypes[cardTypeID];
    }

    private _GUI_CHKOUT_PaymentOptions = (): void => {
        Util.removeChildren("div_checkout_cash");
        Util.removeChildren("div_checkout_credit_on_delivery");

        Util.hideElement("div_checkout_in_store");
        Util.hideElement("div_checkout_cash");
        Util.hideElement("div_checkout_credit");
        Util.hideElement("div_checkout_credit_entry_save_token");
        Util.hideElement("div_checkout_credit_on_delivery");
        Util.hideElement("div_checkout_existing_credit");

        Util.showElement("lbl_checkout_credit_entry_billing_address");
        Util.showElement("txt_checkout_credit_entry_billing_address");
        Util.showElement("lbl_checkout_credit_entry_billing_zip");
        Util.showElement("txt_checkout_credit_entry_billing_zip");

        //Util.hideElement("div_checkout_existing_credit_cvv");
        //'{"Values":[{"OTID":1,"CSH":false,"PIS":true,"COF":true,"CC":true,"CSHA":"0","CSHL":"","PISA":"1","PISL":"250.00","COFC":"1","COFCT":"","CCO":"1","CCCO":"2","CCAO":"6","CCPVO":"3","CCCT":"","CCAT":"85.00","CCPVOT":"150.00"},{"OTID":2,"CSH":false,"PIS":true,"COF":true,"CC":true,"CSHA":"0","CSHL":"","PISA":"1","PISL":"250.00","COFC":"1","COFCT":"","CCO":"1","CCCO":"2","CCAO":"6","CCPVO":"3","CCCT":"","CCAT":"85.00","CCPVOT":"250.00"},{"OTID":3,"CSH":true,"PIS":false,"COF":true,"CC":true,"CSHA":"1","CSHL":"150.00","PISA":"0","PISL":"","COFC":"1","COFCT":"","CCO":"1","CCCO":"2","CCAO":"6","CCPVO":"3","CCCT":"","CCAT":"85.00","CCPVOT":"250.00"}]}'
        const payOptions: IPaymentOptions | undefined = aOLO.data.Settings.OPO.find(x => x.OrderTypeID == aOLO.Order.OrderTypeID); // ? aOLO.data.Settings.OPO[aOLO.Order.OrderTypeID] : [];
        if (payOptions == null) return;
        if (aOLO.QrCode) {
            Util.hideElement("div_checkout_contact_container");
            Util.hideElement("div_checkout_notification");
            Util.hideElement("div_checkout_togo_supplies");
            (document.getElementById("rdo_checkout_credit") as HTMLInputElement).checked = true;
            GUI_CHEOUT_Payment(false);
        } else {
            if (((payOptions.Cash && payOptions.CashAlternate == 0) ||
                (payOptions.Cash && payOptions.CashAlternate > 0 && aOLO.Order.AmountDue < payOptions.CashLimit)) && !aOLO.Temp.Curbside) {
                createCashOption();
                Util.showElement("div_checkout_cash");
            }
            if (((payOptions.PayInStore && payOptions.PayInStoreAlternate == 0) ||
                (payOptions.PayInStore && payOptions.PayInStoreAlternate > 0  &&  aOLO.Order.AmountDue< payOptions.PayInStoreLimit)) && !aOLO.Temp.Curbside)  // } && aOLO.data.Settings.OPIL >= aOLO.Order.AmountDue && paymentOptions.includes(OnlinePaymentOption.PAY_IN_STORE))
                Util.showElement("div_checkout_in_store");
 
            if (payOptions.CreditCard) {
                if (payOptions.CreditCardOption == CreditCardOption.CHARGE || payOptions.CreditCardOption == CreditCardOption.PRE_VALIDATED) {
                    Util.showElement("div_checkout_credit");
                    if (payOptions.CardOnFile && aOLO.User.GU && aOLO.User.GU == LoggedInStatus.LOGGEDIN) {
                        Util.setElement("innerHTML", "lbl_checkout_credit_entry_save_token", Names("SaveCreditCard"));
                        Util.showElement("div_checkout_credit_entry_save_token");
                    } 
                    if (payOptions.CreditCardOption == CreditCardOption.PRE_VALIDATED) 
                        Util.showElement("div_checkout_credit_card_pre_auth_note");

                    if (payOptions.CreditCardAvsOption == CreditCardAvsOption.DO_NOT_VALIDATE) {
                        Util.hideElement("lbl_checkout_credit_address_chk");
                        Util.hideElement("div_checkout_billing_add");
                        Util.hideElement("lbl_checkout_credit_entry_billing_address");
                        Util.hideElement("txt_checkout_credit_entry_billing_address");
                        Util.hideElement("lbl_checkout_credit_entry_billing_zip");
                        Util.hideElement("txt_checkout_credit_entry_billing_zip");
                    } else if (payOptions.CreditCardAvsOption == CreditCardAvsOption.ZIP_ONLY_PROMPT_VALIDATE) {
                        Util.hideElement("lbl_checkout_credit_entry_billing_address");
                        Util.hideElement("txt_checkout_credit_entry_billing_address");
                    } else if (payOptions.CreditCardAvsOption == CreditCardAvsOption.ADDRESS_ONLY_PROMPT_VALIDATE) {
                        Util.hideElement("lbl_checkout_credit_entry_billing_zip");
                        Util.hideElement("txt_checkout_credit_entry_billing_zip");
                    }
                } else if (payOptions.CreditCardOption == CreditCardOption.CHARGE_UPON_DELIVERY) {
                    createCreditOnDeliveryOption();
                    Util.showElement("div_checkout_credit_on_delivery");
                }
            }
            if (payOptions.CardOnFile && aOLO.User && aOLO.User.Wallet && aOLO.User.Wallet.length > 0 ) {
                for (const cc of aOLO.User.Wallet) {
                    if (cc.CTKN !== "")
                        Util.setElement("innerText", "spn_checkout_existing_credit", `${this._getCreditCardTypeByID(cc.CCTID)} xxxxxxxxxxxx${cc.CCL4}`);
                }
                Util.showElement("div_checkout_existing_credit");
            } 


        }

        if (aOLO.Temp.Curbside || aOLO.Temp.Contactless) {
            Util.hideElement("div_checkout_in_store");
            Util.hideElement("div_checkout_cash");

            Util.setElement("checked", "rdo_checkout_in_store", false);
            Util.setElement("checked", "rdo_checkout_cash", false);
        }
    }

    private _GUI_CHKOUT_TogoSupplies = (): void => {
        const togoItems = aOLO.data.Settings.TGI.split(']');
        const div = document.getElementById("div_checkout_togo_supplies_options");

        for (let i = 0; i < togoItems.length; i++) {
            const tgi = togoItems[i];
            if (div)
                Common.createCheckBox(div, `chk_checkout_togo_supply${i}`, "chk_checkout_togo_supply", false, tgi, null, tgi);
        }
    }

    public ContactLess_CurbSide = (): void => {
        aOLO.Temp.Contactless = Util.getElementChecked("chk_checkout_contactless");
        aOLO.Temp.Curbside = Util.getElementChecked("chk_checkout_curbside");
        if (aOLO.Order.OrderTypeID === OrderType.DELIVERY)
            aOLO.Temp.Curbside = false;
        else
            aOLO.Temp.Contactless = false;

        if (aOLO.Temp.Contactless) {
            Util.hideElement("div_checkout_curbside_info");
            Util.showElement("p_checkout_contactless_info");
        } else {
            Util.showElement("div_checkout_curbside_info");
            Util.hideElement("p_checkout_contactless_info");
        }

        this._GUI_CHKOUT_PaymentOptions();
    }

    public Gratuity_Edited = (): void => {
        let tip = Number(Util.getElementValue("txt_checkout_tip").replace(/,/g, '')) || 0;

        if (tip >= 0) {
            tip = Util.Float2(tip);
            const orderTotal = Util.Float2(aOLO.Order.SubTotal - aOLO.Order.Discount + aOLO.Order.OrderTypeCharge);
            if (tip === 0)
                Util.setElement("checked", "rdo_checkout_tip_0", true);
            else if (tip === Util.Float2(orderTotal * 0.10))
                Util.setElement("checked", "rdo_checkout_tip_10", true);
            else if (tip === Util.Float2(orderTotal * 0.15))
                Util.setElement("checked", "rdo_checkout_tip_15", true);
            else if (tip === Util.Float2(orderTotal * 0.2))
                Util.setElement("checked", "rdo_checkout_tip_20", true);
            else
                Util.setElement("checked", "rdo_checkout_tip_other", true);

            aOLO.Order.Tip = tip;
            OnlineOrderingUtil.GUI_SetOrder_Total(false, aOLO, aOLOModules);
            Util.hideElement("spn_checkout_tip_error");
        } else {
            Util.showElement("spn_checkout_tip_error");
            Util.setElement("checked", "rdo_checkout_tip_other", true);
        }

        this._GUI_CHKOUT_PaymentOptions();
    }

    public CloseDialog = (): void => {
        this._removeEventListeners();
        Util.setElement("checked", "rdo_checkout_cash", false);
        Util.DialogFadeOut("dia_checkout");
    }

    public GetGiftCardBalance = async (): Promise<void> => {
        const cardNo = Util.getElementValue("txt_checkout_gift_card_number").trim();
        const pin = Util.getElementValue("txt_checkout_gift_card_pin").trim();

        const clearCardNo = Util.cleanNonDigits(cardNo);
        const clearPin = Util.cleanNonDigits(pin);

        if (clearCardNo.length < 8) {
            DialogCreators.messageBoxOk(Names("InvalidCardNumber"), aOLO.buttonHoverStyle, null, "txt_checkout_gift_card_number");
            return;
        }

        const pinDiv = document.getElementById("div_checkout_gift_card_pin");
        if (clearPin.length < 4 && pinDiv && !pinDiv.classList.contains("hidden")) {
            DialogCreators.messageBoxOk(Names("InvalidPin"), aOLO.buttonHoverStyle, null, "txt_checkout_gift_card_pin");
            return;
        }

        if (this.IsGiftCardApplied(cardNo)) {
            DialogCreators.messageBoxOk(Names("GiftCardAlreadyApplied"), aOLO.buttonHoverStyle, null, "txt_checkout_gift_card_number");
            return;
        }

        const gcard = {
            SKY: aOLO.storeInfo.StoreKey,
            CNO: clearCardNo,
            PIN: clearPin
        };

        const response = await aOLO.Modules.OnlineOrderingService.getGiftCardBalance(gcard, aOLO);
        if (response.success) {
            if (response.balance === 0)
                DialogCreators.messageBoxOk(Names("GiftCardBalanceIs0"), aOLO.buttonHoverStyle, null, "txt_checkout_gift_card_number");
            else
                this.GUI_AddGiftcard(response, gcard.PIN);
        } else {
            if (response.balance === 0)
                DialogCreators.messageBoxOk(Names("GiftCardBalanceIs0"), aOLO.buttonHoverStyle, null, "txt_checkout_gift_card_number");
            else if (response.message)
                DialogCreators.messageBoxOk(response.message, aOLO.buttonHoverStyle, null, "txt_checkout_gift_card_number");
        }
    }

    public IsGiftCardApplied = (cardNo: string): boolean => {
        return aOLO.Temp.GiftCards.some(gCard => gCard.cardNo === cardNo);
    }

    public GUI_AddGiftcard = (response: IServiceGetGiftCardBalance, PIN: string): void => {
        const rndString = (Math.floor(Math.random() * 100) + 1).toString();
        const divID = `div_checkout_applied_gift_${rndString}`;

        const giftCard = {
            cardNo: response.cardNo,
            balance: response.balance,
            pin: PIN,
            appliedAmount: 0,
            randomId: rndString
        };
        aOLO.Temp.GiftCards.push(giftCard);

        OnlineOrderingUtil.GUI_SetOrder_Total(true, aOLO, aOLOModules);
        this.DisablePaymentOptions();
        const html = `
            <div id="${divID}" class="giftcard" data-card="${JSON.stringify(giftCard)}">
                <div class="giftcard_Number" aria-label="gift card number">${giftCard.cardNo}</div>
                <div class="gridColsAuto1x">
                    <label class="gridCol1 giftcard_Label" for="div_checkout_gift_start_balance_${rndString}">Starting Balance:</label>
                    <div id="div_checkout_gift_start_balance_${rndString}" class="gridCol2 giftcard_Amount">${Util.formatMoney(Util.Float2(giftCard.balance))}</div>
                </div>
                <div class="gridColsAuto1x">
                    <label class="gridCol1 giftcard_Label" for="div_checkout_gift_applied_amount_${rndString}">Applied to order:</label>
                    <div id="div_checkout_gift_applied_amount_${rndString}" class="gridCol2 giftcard_Amount">${Util.formatMoney(Util.Float2(giftCard.appliedAmount))}</div>
                </div>
                <div class="gridColsAuto1x">
                    <label class="gridCol1 giftcard_Label" for="div_checkout_gift_end_balance_${rndString}">Ending balance:</label>
                    <div id="div_checkout_gift_end_balance_${rndString}" class="gridCol2 giftcard_Amount">${Util.formatMoney(Util.Float2(giftCard.balance - giftCard.appliedAmount))}</div>
                </div>
                <div class="giftcard_Remove">
                    <button id="btn_checkout_gift_remove_${rndString}" class="btnLink" ltag="Remove">${Names("Remove")}</button>
                </div>
            </div>`;

        const mainDiv = document.getElementById("div_checkout_applied_gifts");
        if (mainDiv)
            mainDiv.appendChild(Util.createHtmlElementFromTemplate(html));

        const self = this;
        Util.setElement("onclick", `btn_checkout_gift_remove_${rndString}`, () => { self.RemoveGiftCard(giftCard.cardNo, divID); });
        Util.setElement("value", "txt_checkout_gift_card_number", "");
        Util.setElement("value", "txt_checkout_gift_card_pin", "");
    }
    public DisablePaymentOptions = (): void => {
        const payInStore = document.getElementById("rdo_checkout_in_store") as HTMLInputElement;
        const payCC = document.getElementById("rdo_checkout_credit") as HTMLInputElement;
        const payEXCC = document.getElementById("rdo_checkout_existing_credit") as HTMLInputElement;
        if (aOLO.Order.AmountDue <= 0) {
            if (payInStore.checked)
                Util.setElement("checked", "rdo_checkout_in_store", false);
            if (payCC.checked) {
                Util.setElement("checked", "rdo_checkout_credit", false);
                Util.hideElement("div_checkout_credit_entry");
            }
            if (payEXCC.checked) {
                Util.setElement("checked", "rdo_checkout_existing_credit", false);
                Util.hideElement("div_checkout_existing_credit_cvv");
            }
            Util.setElement("DISABLED", "div_checkout_payment_options", true);
        } else {
            Util.setElement("DISABLED", "div_checkout_payment_options", false);
        }
    }
    public RemoveGiftCard = (cardNo: string, divId: string): void => {
        const div = document.getElementById(divId);
        if (div)
            div.remove();

        const index = aOLO.Temp.GiftCards.findIndex(gCard => gCard.cardNo === cardNo);
        if (index !== -1)
            aOLO.Temp.GiftCards.splice(index, 1);

        OnlineOrderingUtil.GUI_SetOrder_Total(true, aOLO, aOLOModules);
        this.DisablePaymentOptions();
    }

    private _getMinimumDeliveryAmtForZone = (zoneId: number | undefined): number => {
        if (!zoneId || aOLO.data.DeliveryZones.length === 0)
            return 0;

        const foundZone = aOLO.data.DeliveryZones.find(zone => zone.ZoneId === zoneId);
        return foundZone ? foundZone.ZoneMinDeliveryAmount : 0;
    }

    private _txtCCkeyDown = (event: KeyboardEvent): void => {
        const txtCC = document.getElementById("txt_checkout_credit_entry_card_number") as HTMLInputElement;
        if (!txtCC)
            return;

        const ccValue = txtCC.value;
        let cPos = txtCC.selectionStart || 0;
        let isLeftDash = false;
        let isPrevLeftDash = false;
        let isRightDash = false;
        let isNextRightDash = false;

        if (cPos > 0)
            isLeftDash = (ccValue.substring(cPos - 1, cPos) === "-");

        if (cPos > 1)
            isPrevLeftDash = (ccValue.substring(cPos - 2, cPos - 1) === "-");

        if (cPos > 0)
            isRightDash = (ccValue.substring(cPos, cPos + 1) === "-");

        if (cPos > 0)
            isNextRightDash = (ccValue.substring(cPos + 1, cPos + 2) === "-");

        if (event.key === "Backspace" && isLeftDash)
            cPos = cPos - 1;
        else if (event.key === "Delete" && isRightDash)
            cPos = cPos + 1;
        else if (event.key === "ArrowLeft" && isPrevLeftDash)
            cPos = cPos - 1;
        else if (event.key === "ArrowRight" && isNextRightDash)
            cPos = cPos + 1;

        txtCC.setSelectionRange(cPos, cPos);
    }

    private _txtCCkeyUp = (event: KeyboardEvent): void => {
        const txtCC = document.getElementById("txt_checkout_credit_entry_card_number") as HTMLInputElement;
        if (!txtCC)
            return;

        const ccValue = txtCC.value;
        let cPos = txtCC.selectionStart || 0;

        let isLeftDash = false;
        let isRightDash = false;
        if (cPos > 0)
            isLeftDash = (ccValue.substring(cPos - 1, cPos) === "-");

        if (cPos > 0)
            isRightDash = (ccValue.substring(cPos, cPos + 1) === "-");

        if (event.key !== "ArrowLeft" && event.key !== "ArrowRight") {
            const cLen = ccValue.length;
            const formatedCCValue = this._formatCreditCard(ccValue);
            txtCC.value = this._formatCreditCard(formatedCCValue);
            const fcLen = formatedCCValue.length;
            if ((fcLen > cLen && cPos == cLen) || isRightDash)
                cPos += 1;

            if (event.key === "Backspace" && isLeftDash)
                cPos -= 1;

            txtCC.setSelectionRange(cPos, cPos);
        }
    }

    private _formatCreditCard = (cno: string): string => {
        cno = Util.cleanNonDigits(cno);
        const brand = GetCardBrand(cno);
        let formated = "";

        if (brand == CardBrand.AMEX) {
            if (cno.length <= 4)
                formated = cno;
            else if (cno.length < 11)
                formated = `${cno.substring(0, 4)}-${cno.substring(4)}`;
            else
                formated = `${cno.substring(0, 4)}-${cno.substring(4, 10)}-${cno.substring(10)}`;
        } else {
            if (cno.length <= 4)
                formated = cno;
            else if (cno.length < 9)
                formated = `${cno.substring(0, 4)}-${cno.substring(4)}`;
            else if (cno.length < 13)
                formated = `${cno.substring(0, 4)}-${cno.substring(4, 8)}-${cno.substring(8)}`;
            else
                formated = `${cno.substring(0, 4)}-${cno.substring(4, 8)}-${cno.substring(8, 12)}-${cno.substring(12)}`;
        }
        return formated;
    }

    /**
     * Renders the donation section of the UI.
     */
    private _GUI_DonationSection = (): void => {
        if (aOLO.data.Donations.length === 0) {
            Util.hideElement("div_checkout_donation");
            return;
        }

        Util.removeChildren("div_checkout_donation_items");
        for (const donation of aOLO.data.Donations) {
            const name = Common.GetNewName(donation.Names, aOLO.Temp.languageCode);
            const description = Common.GetNewName(donation.Descriptions, aOLO.Temp.languageCode);

            const divMaster = document.createElement('div');
            divMaster.id = `div_checkout_donation_${donation.DonationId}`;

            if (donation.ImageUrl) {
                divMaster.classList.add("checkout-donation-item");
                divMaster.appendChild(this._createDonationImageSection(donation, name));
            }

            const divContent = document.createElement("div");
            if (!donation.ImageUrl)
                divContent.classList.add("checkout-donation-item-amount");

            divContent.appendChild(this._createDonationTitle(name));
            divContent.appendChild(this._createDonationDescription(description));

            if (!donation.ImageUrl && donation.RedirectURL)
                divContent.appendChild(this._createDonationLearnMoreLink(donation));

            divContent.appendChild(this._createDonationOptions(donation));
            divMaster.appendChild(divContent);

            const form = document.getElementById("div_checkout_donation_items");
            if (form)
                form.append(divMaster);
            Util.setElement("checked", `rdo_checkout_donation_no_thanks_${donation.DonationId}`, true);
        }
        Util.showElement("div_checkout_donation");
        OnlineOrderingUtil.GUI_SetOrder_Total(false, aOLO, aOLOModules);
    }

    /**
     * Creates an image section for the donation UI element.
     * @param {IDataDonation} donation - The donation data object.
     * @param {string} name - The name of the donation.
     * @returns {HTMLElement} - The image section element.
     */
    private _createDonationImageSection = (donation: IDataDonation, name: string): HTMLElement => {
        const divImage = document.createElement("div");
        divImage.classList.add("checkout-donation-image");

        const image = document.createElement("img");
        image.alt = `Image for donation of ${name}`;
        image.src = donation.ImageUrl || "";
        divImage.appendChild(image);

        if (donation.RedirectURL)
            divImage.appendChild(this._createDonationLearnMoreLink(donation));

        return divImage;
    }

    /**
     * Creates a 'Learn More' link for the donation UI element.
     * @param {IDataDonation} donation - The donation data object.
     * @returns {HTMLElement} - The 'Learn More' link element.
     */
    private _createDonationLearnMoreLink = (donation: IDataDonation): HTMLElement => {
        const imageLink = document.createElement("a");
        imageLink.innerText = Names("LearnMore");
        imageLink.href = donation.RedirectURL || "";
        imageLink.target = "_blank";

        return imageLink;
    }

    /**
     * Creates a title for the donation UI element.
     * @param {string} name - The name of the donation.
     * @returns {HTMLElement} - The title element.
     */
    private _createDonationTitle = (name: string): HTMLElement => {
        const divTitle = document.createElement('div');
        divTitle.classList.add("title3");
        divTitle.classList.add("p1");
        divTitle.style.paddingTop = '0px';
        divTitle.innerHTML = Names("DonationQuestion").replace("???", name);

        return divTitle;
    }

    /**
     * Creates a description for the donation UI element.
     * @param {string} description - The description of the donation.
     * @returns {HTMLElement} - The description element.
     */
    private _createDonationDescription = (description: string): HTMLElement => {
        const divDescription = document.createElement('span');
        divDescription.classList.add("p");
        divDescription.innerHTML = description;

        return divDescription;
    }

    /**
     * Creates the donation options (radio buttons) for the donation UI element.
     * @param {IDataDonation} donation - The donation data object.
     * @returns {HTMLElement} - The donation options element.
     */
    private _createDonationOptions = (donation: IDataDonation): HTMLElement => {
        const self = this;
        const divOptions = document.createElement('div');
        divOptions.id = `div_checkout_donation_amount_${donation.DonationId}`;
        divOptions.classList.add("radioBar");

        if (donation.IsRounded) {
            const inputRoundUp = document.createElement('input');
            const labelRoundUp = document.createElement('label');
            inputRoundUp.id = `rdo_checkout_donation_round_up_${donation.DonationId}`;
            inputRoundUp.type = "radio";
            inputRoundUp.name = `rdo_checkout_donation_donation_${donation.DonationId}`;
            inputRoundUp.onchange = function () { self._calculateDonation(donation.DonationId); }
            labelRoundUp.id = `lbl_checkout_donation_round_up_${donation.DonationId}`;
            labelRoundUp.htmlFor = `rdo_checkout_donation_round_up_${donation.DonationId}`;
            labelRoundUp.innerHTML = Names("DonationRoundUp");
            divOptions.append(inputRoundUp);
            divOptions.append(labelRoundUp);
        }

        if (donation.Amount1) {
            const inputAmount1 = document.createElement('input');
            const labelAmount1 = document.createElement('label');
            inputAmount1.id = `rdo_checkout_donation_amount1_${donation.DonationId}`;
            inputAmount1.type = "radio";
            inputAmount1.name = `rdo_checkout_donation_donation_${donation.DonationId}`;
            inputAmount1.onchange = function () { self._calculateDonation(donation.DonationId, donation.Amount1); }
            labelAmount1.htmlFor = `rdo_checkout_donation_amount1_${donation.DonationId}`;
            labelAmount1.innerHTML = `${Names("Donate")} ${Util.formatMoney(donation.Amount1)}`;
            divOptions.append(inputAmount1);
            divOptions.append(labelAmount1);
        }

        if (donation.Amount2) {
            const inputAmount2 = document.createElement('input');
            const labelAmount2 = document.createElement('label');
            inputAmount2.id = `rdo_checkout_donation_amount2_${donation.DonationId}`;
            inputAmount2.type = "radio";
            inputAmount2.name = `rdo_checkout_donation_donation_${donation.DonationId}`;
            inputAmount2.onchange = function () { self._calculateDonation(donation.DonationId, donation.Amount2); }
            labelAmount2.htmlFor = `rdo_checkout_donation_amount2_${donation.DonationId}`;
            labelAmount2.innerHTML = `${Names("Donate")} ${Util.formatMoney(donation.Amount2)}`;
            divOptions.append(inputAmount2);
            divOptions.append(labelAmount2);
        }

        if (donation.Amount3) {
            const inputAmount3 = document.createElement('input');
            const labelAmount3 = document.createElement('label');
            inputAmount3.id = `rdo_checkout_donation_amount3_${donation.DonationId}`;
            inputAmount3.type = "radio";
            inputAmount3.name = `rdo_checkout_donation_donation_${donation.DonationId}`;
            inputAmount3.onchange = function () { self._calculateDonation(donation.DonationId, donation.Amount3); }
            labelAmount3.htmlFor = `rdo_checkout_donation_amount3_${donation.DonationId}`;
            labelAmount3.innerHTML = `${Names("Donate")} ${Util.formatMoney(donation.Amount3)}`;
            divOptions.append(inputAmount3);
            divOptions.append(labelAmount3);
        }

        const inputNoThanks = document.createElement('input');
        const labelNoThanks = document.createElement('label');
        inputNoThanks.id = `rdo_checkout_donation_no_thanks_${donation.DonationId}`;
        inputNoThanks.type = "radio";
        inputNoThanks.name = `rdo_checkout_donation_donation_${donation.DonationId}`;
        inputNoThanks.onchange = function () { self._calculateDonation(donation.DonationId, 0); }
        aOLO.Order.Donations.Detail.push({ ID: donation.DonationId, Amount: 0 });
        labelNoThanks.htmlFor = `rdo_checkout_donation_no_thanks_${donation.DonationId}`;
        labelNoThanks.innerHTML = Names("DonationNoThanks");
        divOptions.append(inputNoThanks);
        divOptions.append(labelNoThanks);

        return divOptions;
    }

    private _setDeliveryAddressAsBilling = (): void => {
        this._isDeliveryBilling = Util.getElementChecked("chk_checkout_billing_add");
        if (aOLO.Temp.Address && this._isDeliveryBilling) {
            //set the delivery address as billing address
            Util.setElement("value", "txt_checkout_credit_entry_billing_address", `${aOLO.Temp.Address.StreetNo} ${aOLO.Temp.Address.StreetName}${aOLO.Temp.Address.Address2 ? ' #' +
                aOLO.Temp.Address.Address2 : ''}, ${aOLO.Temp.Address.City}, ${aOLO.Temp.Address.State}`);
            Util.setElement("value", "txt_checkout_credit_entry_billing_zip", aOLO.Temp.Address.Zip);
        }
        else if (!this._isDeliveryBilling) {
            Util.setElement("value", "txt_checkout_credit_entry_billing_address", ``);
            Util.setElement("value", "txt_checkout_credit_entry_billing_zip", '');
        }
    }

    private _calculateDonation = (id: number, amount?: number): void => {
        if (aOLO.data.Donations.length === 0)
            return;

        const donation = aOLO.Order.Donations.Detail.find(ngo => ngo.ID === id);
        if (donation) {
            if (typeof amount === 'number' && isFinite(amount)) {
                donation.Amount = amount;
            } else {
                const noDonationOrder = OnlineOrderingUtil.calcOrderTotal(aOLO.Order.SubTotal, aOLO.Order.Discount, aOLO.Order.OrderTypeCharge,
                    aOLO.Order.CollectedTax, aOLO.Order.Tip, aOLO.Order.ServiceCharges.Amount, 0, aOLO.Order.TaxInSubTotal);
                donation.Amount = parseFloat(Util.formatNumber(Math.ceil(noDonationOrder) - noDonationOrder));
            }
        }
        aOLO.Order.TotalDonation = 0.0;
        aOLO.Order.Donations.Detail.forEach(ngo => { aOLO.Order.TotalDonation += ngo.Amount });

        OnlineOrderingUtil.GUI_SetOrder_Total(false, aOLO, aOLOModules);
        this._GUI_CHKOUT_PaymentOptions();
    }

    private _setRedeemCurrencyGUI = (): void => {
        if (!aOLOModules.LoyaltyProvider.hasBankedCurrency() || !aOLO.User?.LoyaltyData) {
            Util.hideElement("div_checkout_redeem_currency");
            return;
        }

        Util.hideElement("div_checkout_redeem_currency_applied");
        Util.showElement("div_checkout_redeem_currency");

        Util.setElement("innerText", "div_checkout_redeem_currency_available_amount", Util.formatMoney(aOLO.User.LoyaltyData.BankedCurrency || 0));
        this._setAppliedRedeemCurrencyGUI();
    }

    private _applyRedeemCurrency = async (): Promise<void> => {
        if (aOLO.Order.Coupons.find(x => x.CouponId == 10 && x.RewardId == null)) {
            DialogCreators.messageBoxOk(Names("RedeemRewardHasAlreadyBeenAppliedError"), aOLO.buttonHoverStyle);
            return;
        }

        const amountLeftOrder = (aOLO.Order.SubTotal - aOLO.Order.Discount);
        const amount = Number(this._item.redeemCurrency);
        if (amount == 0) {
            DialogCreators.messageBoxOk(Names("PleaseEnterAmountGreaterThan").replace("{{x}}", Util.formatMoney(0)), aOLO.buttonHoverStyle);
            return;
        } else if (amount > amountLeftOrder) {
            DialogCreators.messageBoxOk(Names("PleaseEnterAmountLessThanAmountDue").replace("{{x}}", Util.formatMoney(amountLeftOrder)), aOLO.buttonHoverStyle);
            return;
        } else if (amount > (aOLO.User.LoyaltyData?.BankedCurrency || 0)) {
            DialogCreators.messageBoxOk(Names("PleaseEnterAmountWithinRangeFrom").replace("{{x}}", Util.formatMoney(0)).replace("{{y}}", Util.formatMoney(aOLO.User.LoyaltyData?.BankedCurrency || 0)), aOLO.buttonHoverStyle);
            return;
        }

        await aOLOModules.LoyaltyProvider.applyBankedCurrency(amount);
        this._setAppliedRedeemCurrencyGUI();
    }

    private _setAppliedRedeemCurrencyGUI = (): void => {
        const amount = aOLOModules.LoyaltyProvider.getAppliedBankedCurrency();
        this._item.redeemCurrency = "";

        if (!aOLO.User.LoyaltyData?.BankedCurrency || amount == 0)
            return;

        Util.setElement("innerText", "div_checkout_redeem_currency_applied_amount", Util.formatMoney(amount));
        Util.setElement("innerText", "div_checkout_redeem_currency_remaining_amount", Util.formatMoney(aOLO.User.LoyaltyData.BankedCurrency - amount));

        Util.setElement("onclick", "btn_checkout_redeem_currency_remove", () => { this._removeBankedCurrency(); });
        Util.showElement("div_checkout_redeem_currency_applied");
    }

    private _removeBankedCurrency = async (): Promise<void> => {
        await aOLOModules.LoyaltyProvider.removeBankedCurrency();
        Util.hideElement("div_checkout_redeem_currency_applied");
    }

    private _GUI_SetupCheckoutDialog = (): void => {


        //setting 98
        if (aOLO.data.Settings.COMSG) {
            Util.setElement("innerHTML", "p_checkout_store_message", aOLO.data.Settings.COMSG);
            Util.showElement("div_checkout_store_message");
        }

        //setting 76
        if (aOLO.data.Settings.OOC)
            Util.hideElement("div_checkout_comment");
        else
            Util.showElement("div_checkout_comment");

        //setting 77 & 164 & 181
        if (aOLO.data.Settings.CCG && aOLO.data.Settings.TPDS && aOLO.data.Settings.TPDM === 1) {
            Util.setElement("checked", "rdo_checkout_tip_20", true);
            calculateTip();
        }           
        else
            Util.setElement("checked", "rdo_checkout_tip_other", true);

        if (aOLO.data.Settings.CLESS === 1) //setting 136
            Util.showElement("div_checkout_contactless");
        else
            Util.hideElement("div_checkout_contactless");

        if (aOLO.data.Settings.CCG === true) //setting 77
            Util.showElement("div_checkout_tip");
        else
            Util.hideElement("div_checkout_tip");

        if (aOLOModules.LoyaltyProvider.getLoyaltyProvider() !== LoyaltyProvider.PUNCHH)
            Util.hideElement("btn_checkout_import_digital_gift_card")


    }

    private _GUI_CHKOUT_CC_Exp_Year = (): void => {
        const ddlYear = document.getElementById("ddl_checkout_credit_entry_expiration_year") as HTMLSelectElement;
        if (!ddlYear)
            return;

        const selYear = ddlYear.value;
        ddlYear.innerHTML = "";
        const sYear = Util.NowStore(aOLO.Temp.TimeOffset).getFullYear();

        for (let i = sYear; i < sYear + 10; i++) {
            const option = document.createElement("option");
            option.text = i.toString();
            option.value = (i % 100).toString();
            if ((i % 100).toString() === selYear)
                option.selected = true;
            ddlYear.add(option);
        }
    }

    private _removeEventListeners = (): void => {
        this._bindings.forEach(binding => {
            binding.removeEventListeners();
        });
    }
}