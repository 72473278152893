import { IDataItem, IDataModifierDisplayGroup, IItemModifierDisplayGroupModifier } from "../interfaces/data.interface";
import { ICustomize } from "./interfaces/customize-item.interface";
import { IOrderItem, IOrderItemModifier } from "../interfaces/order.interface";
import { Names } from "../utils/i18n";
import { Util } from "../utils/util";
import { Common } from "../common";
import { DialogCreators } from "../utils/dialog-creators";
import { OnlineOrderingUtil } from "./online-ordering-util";

import '../../css/online-ordering/customize-item.css';
import { IaOLO } from "../interfaces/aolo.interface";
import { IApplyCouponByIdParams } from "./interfaces/coupons.interface";

export class CustomizeItem {
    private _itemIndex: number = 0;
    private _sizeId: number | null = null;
    private _quantity: number = 1;
    private _itemKey: number = -1;
    private _show: boolean = true;
    private _editingItem1Index: number = -1;
    private _editingItem2Index: number = -1;
    private _itemHist: IOrderItem[] = [];
    private _editing: boolean = false;
    private _customize: ICustomize = {
        HalfCount: 1,
        Items: []
    };
    private _editingCartItem: boolean = false;
    private _localAolo: IaOLO;


    constructor(aOLO: IaOLO, itemIndexKey: number, sizeId?: number | null, quantity?: number, show?: boolean, editingCartItem?: boolean) {
        this._localAolo = aOLO;
        if (sizeId === undefined && quantity === undefined)
            this._itemKey = itemIndexKey;
        else {
            this._itemIndex = itemIndexKey;
            this._sizeId = (sizeId != undefined && sizeId != null) ? sizeId : null;
            this._quantity = quantity || 1;
        }

        if (show !== undefined)
            this._show = show;

        if (editingCartItem !== undefined)
            this._editingCartItem = editingCartItem;

        this._init();
    }

    private _init = (): void => {
        if (this._itemKey === -1)
            this._renderNewPage();
        else
            this._renderEditPage();
        this._setEventListeners();

        if (this._show)
            this.OpenDialog();
    }

    public GetEditing = (): boolean => {
        return this._editing;
    }

    public GetItem1 = (): IOrderItem | null => {
        return this._customize.Items[0] || null;
    }

    public GetItem2 = (): IOrderItem | null => {
        return this._customize.Items.length > 1 ? this._customize.Items[1] : null;
    }

    public OpenDialog = (): void => {
        Util.DialogFadeIn("dia_customize_item", this._localAolo, "btn_customize_item_add_to_order");
        const itmDiv = document.getElementById("div_customize_item_items");
        if (itmDiv)
            itmDiv.scrollIntoView();
    }

    public CloseDialog = (): void => {
        Util.DialogFadeOut("dia_customize_item");
    }

    private _setEventListeners = (): void => {
        const self = this;
        Util.setElement("onclick", "btn_customize_item_make_half", () => { self.makeItemHalfHalf(); });
        Util.setElement("onchange", "ddl_customize_item_item1_items", () => { self._changeCustItem(); });
        Util.setElement("onchange", "ddl_customize_item_item1_items_half", () => { self._changeCustItemHalf1(); });
        Util.setElement("onchange", "ddl_customize_item_item2_items_half", () => { self._changeCustItemHalf2(); });

        Util.setElement("onchange", "ddl_customize_item_item1_quantity", () => { self._setCustItemQty(); });
        Util.setElement("onchange", "ddl_customize_item_whole_quantity", () => { self._setCustItemQty(); });

        Util.setElement("onclick", "btn_customize_item_item1_remove", () => { self._removeFirstHalf(); });
        Util.setElement("onclick", "btn_customize_item_item2_remove", () => { self._removeSecondHalf(); });
        Util.setElement("onclick", "btn_customize_item_add_to_order", () => { self.addSelectedToOrder(); });

        Util.setElement("onclick", "btn_customize_item_point_half1", () => { self._scrollToHalf1(); });
        Util.setElement("onclick", "btn_customize_item_point_half2", () => { self._scrollToHalf2(); });

        const item1Size = document.getElementById("ddl_customize_item_item1_size");
        if (item1Size)
            item1Size.onchange = (event: Event) => {
                if (!event.isTrusted)
                    return;
                this._setCustItemSize();
            };

        const item2Size = document.getElementById("ddl_customize_item_whole_size");
        if (item2Size)
            item2Size.onchange = (event: Event) => {
                if (!event.isTrusted)
                    return;
                this._setCustItemSize();
            };

        Util.setElement("onclick", "btn_customize_item_close", () => {
            self._revertEditing();
            self.CloseDialog();
        });

        Util.setElement("onclick", "btn_customize_item_instructions_title", () => {
            let icon = document.getElementById("icon_customize_item_instructions_title");
            if (!icon)
                return;

            if (icon.classList.contains("icon-show-more")) {
                Util.setElementClass("add", "icon_customize_item_instructions_title", "icon-show-less");
                Util.setElementClass("remove", "icon_customize_item_instructions_title", "icon-show-more");
                Util.showElement("div_customize_item_instructions_body");
            } else {
                Util.setElementClass("add", "icon_customize_item_instructions_title", "icon-show-more");
                Util.setElementClass("remove", "icon_customize_item_instructions_title", "icon-show-less");
                Util.hideElement("div_customize_item_instructions_body");
            }
        });
    }

    private _renderNewPage = (): void => {
        let mItem = this._localAolo.data.Items[this._itemIndex];
        let elements = document.getElementsByName("SingleModDG");
        elements.forEach(element => element.remove());

        Util.setElement("value", "txt_customize_item_comment", "");
        this._editing = false;

        if (mItem !== null) {
            const sizeId = this._sizeId || 0;
            if (this._sizeId == null)
                Util.LogError(`customize-item -> _renderNewPage -> this._sizeId: ${this._sizeId} -> sizeId: ${sizeId}`, new Error("SizeId is null"), this._localAolo);
            this._customize = {
                HalfCount: 1,
                Items: []
            };

            const oItem: IOrderItem = {
                ItemId: mItem.ItemId,
                Name: JSON.stringify(mItem.Names),
                Index: this._itemIndex,
                SizeId: sizeId,
                Quantity: this._quantity,
                Modifiers: OnlineOrderingUtil.Item_Get_Default_Mods(mItem, sizeId, this._localAolo),
                Price: 0.0,
                MinPrice: 0.0,
                HalfHalfPrice: 0.0,
                MenuPrice: 0.0,
                MenuPriceTax: 0.0,
                Tax: 0.0,
                PriceIncludesTax: false,
                Taxes: [],
                TaxAmount: 0.0,
                BeforeTaxDiscount: 0,
                AfterTaxDiscount: 0,
                LoyaltyDiscountsQTY: 0,
                NonLoyaltyDiscountsQTY: 0,
                LoyaltyDiscountsAmount: 0,
                DiscountedMarkedQuantity: 0,
                DiscountedQuantity: 0,
                ItemWebCategoryId: mItem.WebCategoryId,
                ItemCategoryId: mItem.ItemCategoryId,
                ItemKey: -1,
                ItemRecId: 0,
                HalfCount: 1,
                HalfIndex: 0,
                Edit: false,
                Comment: "",
                IsBuildYourOwn: mItem.IsBuildYourOwn
            };

            if (oItem.SizeId == null)
                Util.LogError("customize-item -> _renderNewPage", new Error("SizeId is null"), this._localAolo);

            this._customize.Items.push(oItem);
        }

        Util.setElement("disabled", "ddl_customize_item_item1_quantity", !!this._localAolo.Dialog.MealDeal);
        Util.setElement("disabled", "ddl_customize_item_whole_quantity", !!this._localAolo.Dialog.MealDeal);

        this._setInstructions();
        this._setItems();
        this._updateAddToOrderButtonText(Names("AddToOrder"));
        OnlineOrderingUtil.GUI_ItemCommentBoxDisplay(this._localAolo.data.Settings.OIC);
        this._localAolo.Temp.AddingCustomItemToOrder = false;
        this._priceSelectedItem(1);
        this._setMultiChoiceModCalories(mItem, this._sizeId, 1);

        Util.setElementClass("add", "div_customize_item_item1_name", "this-ci-header");
        Util.setElementClass("remove", "div_customize_item_half_selector", "this-ci-header");

        if (this._localAolo.Modules.DataLayer)
            this._localAolo.Modules.DataLayer.view_item(this._customize.Items[0]);
    }

    private _renderEditPage = (): void => {
        this._editing = true;
        this._customize = {
            HalfCount: 1,
            Items: []
        };

        this._editingItem1Index = -1;
        this._editingItem2Index = -1;

        for (const [index, oItem] of this._localAolo.Order.Items.entries()) {
            if (oItem.ItemKey !== this._itemKey)
                continue;

            const tempItemp = Util.deepClone(oItem) as IOrderItem;
            if (tempItemp.SizeId == null)
                Util.LogError("customize-item -> _renderEditPage -> tempItemp", new Error("SizeId is null"), this._localAolo);

            if (tempItemp.HalfCount === 1) {
                this._customize.Items.push(tempItemp);
                this._editingItem1Index = index;
                this._itemHist[index] = JSON.parse(JSON.stringify(tempItemp));
                break;
            } else if (tempItemp.HalfCount === 2 && tempItemp.HalfIndex === 1) {
                this._customize.Items.push(tempItemp);
                this._editingItem1Index = index;
                this._itemHist[index] = JSON.parse(JSON.stringify(tempItemp));
            } else if (tempItemp.HalfCount === 2 && tempItemp.HalfIndex === 2) {
                this._customize.Items.push(tempItemp);
                this._customize.HalfCount = 2;
                this._editingItem2Index = index;
                this._itemHist[index] = JSON.parse(JSON.stringify(tempItemp));
                break;
            }
        }

        this._setItems();
        this._updateAddToOrderButtonText(Names("ApplyChanges"));
        this._localAolo.Temp.AddingCustomItemToOrder = false;
        this._priceSelectedItem(1);
    }

    private _setInstructions = (): void => {
        Util.hideElement("div_customize_item_instructions");
        Util.removeChildren("div_customize_item_instructions_body");

        if (!this._localAolo.data.Instructions)
            return;

        const mainDiv = document.getElementById("div_customize_item_instructions_body");
        for (const ins of this._localAolo.data.Instructions) {
            const iComment = ins.Items.find(x => x.ItemWebCategoryId === this._customize.Items[0].ItemWebCategoryId && x.SizeId === this._sizeId);
            if (!iComment)
                continue;

            const chk = document.createElement('input');
            chk.id = `chk_customize_item_Instructions_${ins.CommentId}`;
            chk.value = ins.CommentId.toString();
            chk.dataset.iwcid = this._customize.Items[0].ItemWebCategoryId.toString();
            chk.type = 'checkbox';
            chk.setAttribute('name', 'chk_customize_item_Instructions');

            const label = document.createElement('label');
            label.style.position = "absolute";
            label.style.top = "50%";
            label.style.transform = "translate(0, -50%)";
            label.setAttribute("for", `chk_customize_item_Instructions_${ins.CommentId}`);
            label.innerHTML = Common.GetNewName(ins.Names, this._localAolo.Temp.languageCode);
            const div = document.createElement('div');
            div.classList.add('checkRadio');
            div.append(chk);
            div.append(label);

            if (mainDiv) {
                mainDiv.appendChild(div);
                Util.showElement("div_customize_item_instructions");
            }
        }
    }

    private _setItems = (): void => {
        const isHH = this._customize.Items.length > 1;
        let iID2 = 0;

        Util.setElement("value", "ddl_customize_item_item1_quantity", this._customize.Items[0].Quantity);
        Util.setElement("value", "ddl_customize_item_whole_quantity", this._customize.Items[0].Quantity);

        if (isHH) {
            Util.showElement("div_customize_item_whole");
            Util.showElement("div_customize_item_half_selector");
            Util.hideElement("div_customize_item_item1_items");
            Util.hideElement("div_customize_item_item1_size");
            Util.hideElement("div_customize_item_item1_quantity");
            Util.setElementClass("add", "div_customize_item_items", "custItemHalfHalf");

            iID2 = this._customize.Items[1].ItemId;
        } else {
            Util.hideElement("div_customize_item_whole");
            Util.hideElement("div_customize_item_half_selector");
            Util.showElement("div_customize_item_items");
            Util.showElement("div_customize_item_item1_items");
            Util.showElement("div_customize_item_item1_size");
            Util.showElement("div_customize_item_item1_quantity");
            Util.setElementClass("remove", "div_customize_item_items", "custItemHalfHalf");
        }

        for (const [index, oItem] of this._customize.Items.entries()) {
            const mItem = this._localAolo.data.Items.find(x => x.ItemId === oItem.ItemId);
            if (!mItem)
                continue;

            let allowedSizes = null;

            if (oItem.Comment)
                Util.setElement("value", "txt_customize_item_comment", oItem.Comment);

            if (this._localAolo.Dialog.MealDeal) {
                const coupon = this._localAolo.Modules.Coupon.GetCoupon(this._localAolo.Dialog.MealDeal.GetCouponId(), true, this._localAolo.Order.OrderTypeID);
                if (coupon?.Items) {
                    const group = this._localAolo.Dialog.MealDeal.GetSelectedGroup();
                    allowedSizes = coupon.Items.filter(x => x.GroupIndex == group && x.ItemId == mItem?.ItemId).map(x => x.SizeId);
                }
            }

            if (index === 0) {
                let mItem2 = null;
                if (isHH)
                    mItem2 = this._localAolo.data.Items.find(x => x.ItemId === this._customize.Items[1].ItemId);

                const imgItem1Half = document.getElementById("img_customize_item_item1_image_half");
                if (imgItem1Half) {
                    imgItem1Half.setAttribute("src", mItem.ImageURL);
                    imgItem1Half.setAttribute("alt", `Menu item image for ${Common.GetName(mItem.Names, this._localAolo.Temp.languageCode)}`);
                }

                const imgItem1 = document.getElementById("img_customize_item_item1_image");
                if (imgItem1) {
                    imgItem1.setAttribute("src", mItem.ImageURL);
                    imgItem1.setAttribute("alt", `Menu item image for ${Common.GetName(mItem.Names, this._localAolo.Temp.languageCode)}`);
                }

                Util.setElement("innerText", "div_customize_item_item1_name", Common.GetName(mItem.Names, this._localAolo.Temp.languageCode));

                this._setItemDDLs(mItem.WebCategoryId, mItem.ItemId, iID2);
                this._setItemSizes(mItem, this._localAolo.Order.OrderTypeID, oItem.SizeId, mItem2, allowedSizes);
            } else {
                const imgItem2 = document.getElementById("img_customize_item_item2_image");
                if (imgItem2) {
                    imgItem2.setAttribute("src", mItem.ImageURL);
                    imgItem2.setAttribute("alt", `Menu item image for ${Common.GetName(mItem.Names, this._localAolo.Temp.languageCode)}`);
                }
            }

            this._setSingleChoiceMods(mItem, oItem, index + 1, isHH);
            const hasMultiCHoiceMods = this._setMultiChoiceMods(mItem, oItem, index + 1);

            const dialogContent = document.getElementsByClassName("dialogContent")[0];
            if (dialogContent) {
                if (!isHH && !hasMultiCHoiceMods) {
                    dialogContent.classList.add("autoHeight");
                    Util.setElementClass("add", "dia_customize_item", "autoWidth");
                } else {
                    dialogContent.classList.remove("autoHeight");
                    Util.setElementClass("remove", "dia_customize_item", "autoWidth");
                }
            }

            this._checkHideHalfHalfButtonBySize(oItem.ItemId);
            this._displayItemsCalories(index);
            this._setMultiChoiceModCalories(mItem, oItem.SizeId, index + 1);
        }

        this._displayItemsPrice();
    }

    private _setItemDDLs = (catID: number, item1ID: number, item2ID: number): void => {
        let items = this._localAolo.data.Items;

        Util.removeChildren("ddl_customize_item_item1_items");
        Util.removeChildren("ddl_customize_item_item1_items_half");
        Util.removeChildren("ddl_customize_item_item2_items_half");

        if (this._localAolo.Dialog.MealDeal) {
            const coupon = this._localAolo.Modules.Coupon.GetCoupon(this._localAolo.Dialog.MealDeal.GetCouponId(), true, this._localAolo.Order.OrderTypeID);
            if (coupon?.Items) {
                const selectedGroup = this._localAolo.Dialog.MealDeal.GetSelectedGroup();
                const allowedSizes = coupon.Items.filter(x => x.GroupIndex == selectedGroup).map(x => x.ItemId);
                items = items.filter(x => allowedSizes.includes(x.ItemId));
            }
        }

        const selectedSize = parseInt(Util.getElementValue("ddl_customize_item_whole_size"));

        const ddlItem1 = document.getElementById("ddl_customize_item_item1_items") as HTMLSelectElement;
        const ddlItem1Half = document.getElementById("ddl_customize_item_item1_items_half") as HTMLSelectElement;
        const ddlItem2Half = document.getElementById("ddl_customize_item_item2_items_half") as HTMLSelectElement;

        if (!ddlItem1 || !ddlItem1Half || !ddlItem2Half)
            return

        const itemsByWebCategory = items.filter(x => x.WebCategoryId === catID);
        for (const itm of itemsByWebCategory) {
            const iNam = Common.GetName(itm.Names, this._localAolo.Temp.languageCode);

            const option1 = document.createElement("option");
            option1.text = iNam;
            option1.value = itm.ItemId.toString();
            if (itm.ItemId === item1ID) {
                option1.selected = true;
            }
            ddlItem1.appendChild(option1);

            const optionH1 = document.createElement("option");
            optionH1.text = iNam;
            optionH1.value = itm.ItemId.toString();
            if (itm.ItemId === item1ID) {
                optionH1.selected = true;
            }
            ddlItem1Half.appendChild(optionH1);

            if (!itm.IsHalfable)
                continue;

            const orderType = itm.OrderTypes.find(x => x.OrderTypeId == this._localAolo.Order.OrderTypeID);
            if (!orderType)
                continue;

            const sizes = orderType.Sizes.filter(x => x.SizeId == selectedSize && x.IsHalfable);
            if (sizes.length > 0) {
                const optionH2 = document.createElement("option");
                optionH2.text = iNam;
                optionH2.value = itm.ItemId.toString();
                if (itm.ItemId === item2ID) {
                    optionH2.selected = true;
                }
                ddlItem2Half.appendChild(optionH2);
            }
        }

        if (this._localAolo.Dialog.MealDeal) {
            ddlItem1.disabled = true;
            ddlItem1Half.disabled = true;
        }
    }

    private _setItemSizes = (mItem: IDataItem, orderTypeID: number, sizeID: number, mItemHalf2?: IDataItem | null, allowedSizes?: number[] | null): void => {
        Util.removeChildren("ddl_customize_item_item1_size");
        Util.removeChildren("ddl_customize_item_whole_size");

        let sizes = OnlineOrderingUtil.GetItemSizes(mItem, orderTypeID);
        if (sizes.length === 1 && sizeID === 0) {
            Util.hideElement("div_customize_item_item1_size");
        } else {
            let sizDDL = document.getElementById("ddl_customize_item_item1_size") as HTMLSelectElement;
            let sizDDLWhol = document.getElementById("ddl_customize_item_whole_size") as HTMLSelectElement;

            if (!sizDDL || !sizDDLWhol)
                return;

            if (allowedSizes) {
                let tempSizes = sizes.map(x => x.SizeId);
                tempSizes = allowedSizes.filter(x => tempSizes.includes(x));

                if (tempSizes.length > 0)
                    sizes = sizes.filter(x => tempSizes.includes(x.SizeId));

                if (!sizes.map(x => x.SizeId).includes(sizeID)) {
                    this._customize.Items[0].SizeId = sizes[0].SizeId;
                    if (sizes[0].SizeId == null)
                        Util.LogError("customize-item -> _setItemSizes", new Error("SizeId is null"), this._localAolo);
                }
            }

            let selectedIndex = -1;
            let selectedWholIndex = -1;
            for (const size of sizes) {
                if (mItemHalf2 && !OnlineOrderingUtil.ItemHasSize(mItemHalf2, orderTypeID, size.SizeId))
                    continue;

                // Check If size is available for QR
                if (this._localAolo.QrCode && !size.IsOfferedQR)
                    continue;

                let option = document.createElement("option");
                let sizDesc = Common.GetDescription(this._localAolo.data.Sizes[size.Index].Descriptions, this._localAolo.Temp.languageCode);
                option.text = `${Common.GetName(this._localAolo.data.Sizes[size.Index].Names, this._localAolo.Temp.languageCode)} (${sizDesc})`;
                option.value = size.SizeId.toString();

                sizDDL.add(option);
                if (size.SizeId === sizeID) {
                    option.selected = true;
                    selectedIndex = sizDDL.options.length - 1;
                }


                if (size.IsHalfable) {
                    let optionW = document.createElement("option");
                    optionW.text = `${Common.GetName(this._localAolo.data.Sizes[size.Index].Names, this._localAolo.Temp.languageCode)} (${sizDesc})`;
                    optionW.value = size.SizeId.toString();
                    sizDDLWhol.add(optionW);
                    if (size.SizeId === sizeID) {
                        optionW.selected = true;
                        selectedWholIndex = sizDDLWhol.options.length - 1;
                    }
                }
            }

            sizDDL.selectedIndex = selectedIndex !== -1 ? selectedIndex : 0;
            sizDDLWhol.selectedIndex = selectedWholIndex !== -1 ? selectedWholIndex : 0;

            sizDDL.disabled = (allowedSizes && sizes.length == 1) ? true : false;
        }
    }

    private _setSingleChoiceMods = (mItem: IDataItem, oItem: IOrderItem, halfIndex: number, isHH: boolean): void => {
        const divWhole = document.getElementById("div_customize_item_whole");
        const divSingle_Choice_Options = document.getElementById(`div_customize_item_item${halfIndex}_single_choice_options`);

        if (!mItem?.ModifierDisplayGroups || !divWhole || !divSingle_Choice_Options)
            return;

        if (!isHH) {
            const singleMods = document.querySelectorAll("[id^='div_customize_item_single_modifier_display_group_']");
            singleMods.forEach((mod) => mod.remove());
        }

        for (const [index, iMdg] of mItem.ModifierDisplayGroups.entries()) {
            const mdg = this._localAolo.data.ModifierDisplayGroups[iMdg.Index];
            if (!mdg.IsVisible)
                continue;

            if (!isHH || (isHH && mdg.IsHalfable)) {
                if (mdg.MaxSelectionCount === 1 || mdg.MustToggle) {
                    const modDiv = divSingle_Choice_Options.querySelector(`#div_customize_item_single_modifier_display_group_${mdg.ModifierDisplayGroupId}`);
                    if (modDiv) modDiv.remove();

                    const oMod = this._getSingleChoiceModifier_oItem(oItem, mdg.ModifierDisplayGroupId);
                    divSingle_Choice_Options.appendChild(this._setSingleChoiceMod(mItem.ItemId, mdg, index, iMdg.Modifiers, oMod, halfIndex, this._localAolo.Temp.DefPreModID, false));
                }
            } else {
                const modDiv = divSingle_Choice_Options.querySelector(`#div_customize_item_single_modifier_display_group_${mdg.ModifierDisplayGroupId}`);
                if (modDiv) modDiv.remove();

                if (halfIndex === 1) {
                    const wholModDiv = divWhole.querySelector(`#divCustWholeMods_${mdg.ModifierDisplayGroupId}`);
                    if (wholModDiv) wholModDiv.remove();

                    const oMod = this._getSingleChoiceModifier_oItem(oItem, mdg.ModifierDisplayGroupId);
                    const divWholMod = this._setSingleChoiceMod(mItem.ItemId, mdg, index, iMdg.Modifiers, oMod, halfIndex, this._localAolo.Temp.DefPreModID, true);
                    divWholMod.id = `divCustWholeMods_${mdg.ModifierDisplayGroupId}`;
                    divWholMod.classList.add("widthMinMobile");

                    divWhole.appendChild(divWholMod);
                }
            }
        }
    }

    private _setMultiChoiceMods = (mItem: IDataItem, oItem: IOrderItem, halfIndex: number): boolean => {
        Util.removeChildren(`div_customize_item_item${halfIndex}_multi_choice_options`);

        if (!mItem?.ModifierDisplayGroups)
            return false;

        for (const [index, modGroup] of mItem.ModifierDisplayGroups.entries()) {
            const modGroupDef = this._localAolo.data.ModifierDisplayGroups[modGroup.Index];
            if (modGroupDef.IsVisible && modGroupDef.MaxSelectionCount > 1 && !modGroupDef.MustToggle)
                this._setMultiChoiceMod(oItem, mItem.ItemId, modGroupDef, index, modGroup.Modifiers, halfIndex, this._localAolo.Temp.DefPreModID);
        }

        return true;
    }

    private _checkHideHalfHalfButtonBySize = (itemId: number): void => {
        let halfableSize = true;
        let oItem = this._customize.Items[0];
        if (oItem.SizeId == null)
            Util.LogError("customize-item -> _checkHideHalfHalfButtonBySize", new Error("SizeId is null"), this._localAolo);
        let mItem = this._localAolo.data.Items.find(x => x.ItemId == itemId);
        if (oItem && mItem) {
            let sizes = mItem.OrderTypes.find(x => x.OrderTypeId == this._localAolo.Order.OrderTypeID)?.Sizes;
            if (sizes) {
                halfableSize = sizes.find(x => x.SizeId == oItem.SizeId)?.IsHalfable || false;
                if (!mItem.IsHalfable)
                    halfableSize = false;
            }
        }

        if (halfableSize)
            Util.showElement("btn_customize_item_make_half");
        else
            Util.hideElement("btn_customize_item_make_half");
    }

    private _setSingleChoiceMod = (itmID: number, mdg: IDataModifierDisplayGroup, iDGIDX: number, mods: IItemModifierDisplayGroupModifier[], oMod: IOrderItemModifier | null, halfIndex: number, preModID: number, whole: boolean): HTMLElement => {
        if (oMod?.PreModifierId)
            preModID = oMod.PreModifierId;

        const div = document.createElement("div");
        div.id = `div_customize_item_single_modifier_display_group_${mdg.ModifierDisplayGroupId}`;
        div.setAttribute("name", "SingleModDG");

        const lbl = document.createElement("label");
        lbl.classList.add("title2", "right");
        lbl.innerText = Common.GetName(mdg.Names, this._localAolo.Temp.languageCode);
        div.appendChild(lbl);

        const divSel = document.createElement("div");
        div.appendChild(divSel);

        const selectedModID = oMod?.ModifierId || null;
        const modDDLID = whole ? `ddl_customize_item_modifiers_whole_${mdg.ModifierDisplayGroupId}` : `ddl_customize_item_modifiers_${itmID}_${mdg.ModifierDisplayGroupId}_${halfIndex}`;
        let preModDDLID = mdg.IsWeightChangeable ? `ddl_customize_item_premodifiers_${itmID}_${mdg.ModifierDisplayGroupId}_${halfIndex}` : "";

        if (mdg.IsWeightChangeable) {
            let preModExp: number[] = [];
            for (let i = 0; i < mods.length; i++) {
                let mod = mods[i];
                preModExp = preModExp.concat(mod.PreModifierException);
            }

            const selPreMod = this._getPreMod(preModID, true, preModExp);
            selPreMod.id = preModDDLID;
            divSel.appendChild(selPreMod);

            selPreMod.onchange = (event) => {
                if (!event.isTrusted) return;
                const modDDL = document.getElementById(modDDLID) as HTMLSelectElement;
                const modID = parseInt(modDDL?.value ?? "0");
                const iModIDX = modDDL?.selectedIndex ?? 0;
                const preModID = parseInt(selPreMod.value);
                if (preModID !== this._localAolo.Temp.DefPreModID)
                    selPreMod.classList.add("warning");
                else
                    selPreMod.classList.remove("warning");
                this.alterSelectedItemMod(itmID, iDGIDX, modID, iModIDX, preModID, halfIndex, whole, false);
            };
        }

        const selMod = this._getModSelect(mods, selectedModID);
        selMod.id = modDDLID;
        divSel.appendChild(selMod);

        selMod.onchange = (event) => {
            if (!event.isTrusted) return;
            const preModDDL = document.getElementById(preModDDLID) as HTMLSelectElement;
            let preModID = preModDDL ? parseInt(preModDDL.value ?? "0") : this._localAolo.Temp.DefPreModID;
            const modID = parseInt(selMod.value);
            const iModIDX = selMod.selectedIndex;
            const secondItem = this._customize.Items[1];
            const secondMenuItem = secondItem ? (this._localAolo.data.Items.find(x => x.ItemId === secondItem.ItemId) || null) : null;
            const canAlter = this.alterSelectedItemMod(itmID, iDGIDX, modID, iModIDX, preModID, halfIndex, whole, false) && this._checkWholeModifiersCompatible(secondMenuItem);
            if (canAlter)
                selMod.dataset.preindex = iModIDX.toString();
            else
                selMod.selectedIndex = parseInt(selMod.dataset.preindex ?? "0");
        };

        return div
    }

    private _setMultiChoiceMod = (oItem: IOrderItem, itmID: number, mdg: IDataModifierDisplayGroup, iDGIDX: number, mods: IItemModifierDisplayGroupModifier[], halfIndex: number, preModID: number): void => {
        const modsDiv = document.getElementById(`div_customize_item_item${halfIndex}_multi_choice_options`);
        let lastModWebCatID = 0;

        for (let i = 0; i < mods.length; i++) {
            let mod = mods[i];
            let oMod = oItem.Modifiers.find(x => x.ModifierId === mod.ModifierId) || null;
            let checked = mod.IsDefault;

            if (oMod !== null) {
                checked = !(mod.IsDefault && oMod.PreModifierId === this._localAolo.Temp.NonPreModID);
                preModID = oMod.PreModifierId;
            }

            if (lastModWebCatID !== mod.ModifierWebCategoryId) {
                let mCat = OnlineOrderingUtil.SetCategoryHeader(this._localAolo.data.ModifierCategories[mod.ModifierWebCategoryIndex].Names, null, false, this._localAolo.Temp.languageCode);
                mCat.classList.add("m1-lr");
                lastModWebCatID = mod.ModifierWebCategoryId;
                if (modsDiv)
                    modsDiv.appendChild(mCat);
            }

            const modId = `divCustMod_${halfIndex}_${mdg.ModifierDisplayGroupId}_${mod.ModifierId}`;
            const modDiv = modsDiv ? modsDiv.querySelector(`#${modId}`) : null;
            if (modDiv)
                modDiv.remove();

            let div = document.createElement("div");
            div.id = modId;
            div.classList.add(this._localAolo.data.Settings.MODIMG === 0 ? "modifierCheckNoImg" : "modifierCheck");

            let chk = document.createElement("input");
            chk.type = "checkbox";
            chk.id = `chkItmMod_${halfIndex}_${mod.ModifierId}`;
            chk.checked = checked;
            chk.onchange = (event) => {
                if (!event.isTrusted) return;

                const isChecked = chk.checked;
                const preModID = isChecked ? this._localAolo.Temp.DefPreModID : this._localAolo.Temp.NonPreModID;
                const preModDiv = document.getElementById(`ddlItmPreMod_${itmID}_${halfIndex}_${mod.ModifierId}`) as HTMLSelectElement;
                if (preModDiv)
                    preModDiv.value = preModID.toString();

                if (!this.alterSelectedItemMod(itmID, iDGIDX, mod.ModifierId, i, preModID, halfIndex, false, false))
                    chk.checked = !isChecked;
            };
            div.appendChild(chk);

            let lbl = document.createElement("label");
            lbl.setAttribute("for", `chkItmMod_${halfIndex}_${mod.ModifierId}`);
            div.appendChild(lbl);

            let spnNam = document.createElement("span");
            spnNam.innerText = Common.GetName(this._localAolo.data.Modifiers[mod.Index].Names, this._localAolo.Temp.languageCode);
            spnNam.classList.add("gridCol1");
            lbl.appendChild(spnNam);

            let spnCal = document.createElement("span");
            spnCal.id = `spnCustModCal_${halfIndex}_${mdg.ModifierDisplayGroupId}_${mod.ModifierId}`;
            lbl.appendChild(spnCal);

            let imageURL = this._localAolo.data.Modifiers[mod.Index].ImageUrl;
            if (imageURL !== "") {
                let img = document.createElement("img");
                img.setAttribute("src", imageURL);
                img.setAttribute("alt", Common.GetName(this._localAolo.data.Modifiers[mod.Index].Names, this._localAolo.Temp.languageCode));
                lbl.appendChild(img);
            }

            let chkDef = document.createElement("input");
            chkDef.type = "checkbox";
            chkDef.id = `chkItmMod_Def_${halfIndex}_${mod.ModifierId}`;
            chkDef.tabIndex = -1;
            chkDef.setAttribute("aria-label", "default");
            chkDef.checked = mod.IsDefault;
            div.appendChild(chkDef);

            let spnDef = document.createElement("span");
            div.appendChild(spnDef);

            if (mdg.IsWeightChangeable) {
                let preMod = this._getPreMod(preModID, false, mod.PreModifierException);
                preMod.id = `ddlItmPreMod_${itmID}_${halfIndex}_${mod.ModifierId}`;
                preMod.setAttribute("aria-label", "amount");
                preMod.classList.add("customize_item_premod");
                preMod.onchange = (event) => {
                    if (!event.isTrusted) return;
                    const preModID = parseInt(preMod.value);
                    const isDefaultPreMod = preModID === this._localAolo.Temp.DefPreModID;
                    preMod.classList.toggle("warning", !isDefaultPreMod);

                    this.alterSelectedItemMod(itmID, iDGIDX, mod.ModifierId, i, preModID, halfIndex, false, false, false);
                };
                div.appendChild(preMod);
            }

            if (modsDiv)
                modsDiv.appendChild(div);
        }
    }

    private _getPreMod = (preModID: number, includeNo: boolean, preModExp: number[] | null): HTMLSelectElement => {
        const selPreMod = document.createElement("select");
        selPreMod.style.paddingRight = "0";
        selPreMod.setAttribute("aria-label", "weight");

        if (this._localAolo.data.PreModifiers.length === 0)
            return selPreMod;

        for (const pMod of this._localAolo.data.PreModifiers) {
            if (preModExp?.includes(pMod.PreModifierId))
                continue;

            if (includeNo || pMod.PreModifierId !== this._localAolo.Temp.NonPreModID) {
                let option = document.createElement("option");
                option.text = Common.GetName(pMod.Names, this._localAolo.Temp.languageCode);
                option.value = pMod.PreModifierId.toString();
                if (pMod.PreModifierId === preModID)
                    option.selected = true;

                if (pMod.PreModifierId !== this._localAolo.Temp.DefPreModID)
                    option.classList.add("warning");

                selPreMod.appendChild(option);
            }
        }
        return selPreMod;
    }

    private _getModValidSize(mItem: IDataItem, iMod: IItemModifierDisplayGroupModifier, isHalfHalf: boolean): number | null {
        const sizes = OnlineOrderingUtil.GetItemSizes(mItem, this._localAolo.Order.OrderTypeID);
        for (const size of sizes) {
            if (!isHalfHalf || (isHalfHalf && size.IsHalfable)) {
                const modPrice = OnlineOrderingUtil.GetModifierAddToPrice(iMod, size.SizeId);
                if (modPrice > -1)
                    return size.SizeId;
            }
        }
        return null;
    }

    public alterSelectedItemMod = (itmID: number, iDGIDX: number, modID: number, iModIDX: number, preModID: number, halfIndex: number, whole: boolean, ignoreSize: boolean, newModifier: boolean = true): boolean => {
        if (iModIDX === -1)
            return false;

        const oItem = this._customize.Items[halfIndex - 1];
        if (oItem.SizeId == null)
            Util.LogError("customize-item -> alterSelectedItemMod 00", new Error("SizeId is null"), this._localAolo);
        const mItem = this._localAolo.data.Items.filter(x => x.ItemId === oItem.ItemId)[0];
        const iMdg = mItem.ModifierDisplayGroups[iDGIDX];
        const iMod = iMdg.Modifiers[iModIDX];
        const iSize = OnlineOrderingUtil.GetItemSize(mItem, this._localAolo.Order.OrderTypeID, oItem.SizeId);
        let sizeName = iSize ? Common.GetName(this._localAolo.data.Sizes[iSize.Index].Names, this._localAolo.Temp.languageCode) : "";
        const mdg = this._localAolo.data.ModifierDisplayGroups[iMdg.Index];

        this._globalErrorValueSet("alterSelectedItemMod");
        this._globalErrorValueAddMsg("mItem");
        this._globalErrorValueAddObj(mItem);
        this._globalErrorValueAddMsg("oItem");
        this._globalErrorValueAddObj(oItem);
        this._globalErrorValueAddMsg("halfIndex");
        this._globalErrorValueAddMsg(halfIndex.toString());
        this._globalErrorValueAddMsg("iDGIDX");
        this._globalErrorValueAddMsg(iDGIDX.toString());
        this._globalErrorValueAddMsg("iModIDX");
        this._globalErrorValueAddMsg(iModIDX.toString());

        if (preModID !== this._localAolo.Temp.NonPreModID && !this._checkDisplayGroupMax(oItem, iDGIDX))
            return false;

        if (newModifier && preModID !== this._localAolo.Temp.NonPreModID && !this._checkModifiersMax(mItem, oItem, iDGIDX))
            return false;

        if (!ignoreSize && !OnlineOrderingUtil.isModOfferedOnSize(iMod, oItem.SizeId)) {
            const self = this;
            const itemName = Common.GetName(mItem.Names, this._localAolo.Temp.languageCode);
            const mod = this._localAolo.data.Modifiers[iMod.Index];
            const modName = Common.GetName(mod.Names, this._localAolo.Temp.languageCode);

            if (this._localAolo.Dialog && this._localAolo.Dialog.MealDeal) {
                DialogCreators.messageBoxOk(Names("SelModNoSize").replace("{modName}", modName).replace("{sizeName}", sizeName).replace("{itemName}", itemName), this._localAolo.buttonHoverStyle);
                const currentModifierId = this._customize.Items[0].Modifiers[iDGIDX].ModifierId;
                const isHH = this._customize.Items.length > 1;
                if (isHH)
                    Util.setElement("value", `ddl_customize_item_modifiers_whole_${iMdg.ModifierDisplayGroupId}`, currentModifierId);
                else
                    Util.setElement("value", `ddl_customize_item_modifiers_${itmID}_${iMdg.ModifierDisplayGroupId}_${halfIndex}`, currentModifierId);
                return false;
            } else {
                DialogCreators.messageBox(Names("SelModNoSizeSwitch").replace("{modName}", modName).replace("{sizeName}", sizeName).replace("{itemName}", itemName), this._localAolo.buttonHoverStyle, [
                    {
                        "text": Names("Yes"), "callBack": () => {
                            const isHH = this._customize.Items.length > 1;
                            const sizeId = self._getModValidSize(mItem, iMod, isHH);
                            if (!sizeId)
                                Util.LogError(`customize-item -> alterSelectedItemMod 000 -> ${sizeId}`, new Error("SizeId is null"), this._localAolo);
                            if (sizeId != null) {
                                if (isHH) {
                                    this._customize.Items[0].SizeId = sizeId;
                                    if (sizeId == null)
                                        Util.LogError("customize-item -> alterSelectedItemMod 1", new Error("SizeId is null"), this._localAolo);
                                    this._customize.Items[1].SizeId = sizeId;
                                    self.alterSelectedItemMod(itmID, iDGIDX, modID, iModIDX, preModID, halfIndex, whole, true, newModifier);
                                    Util.setElement("value", "ddl_customize_item_whole_size", sizeId);
                                    const wholeModifier = document.getElementById(`ddl_customize_item_modifiers_whole_${iMdg.ModifierDisplayGroupId}`) as HTMLSelectElement;
                                    if (wholeModifier) {
                                        wholeModifier.value = iMod.ModifierId.toString();
                                        wholeModifier.dataset.preindex = iModIDX.toString();
                                    }
                                } else {
                                    this._customize.Items[0].SizeId = sizeId;
                                    if (sizeId == null)
                                        Util.LogError("customize-item -> alterSelectedItemMod 2", new Error("SizeId is null"), this._localAolo);
                                    self.alterSelectedItemMod(itmID, iDGIDX, modID, iModIDX, preModID, halfIndex, whole, true, newModifier);
                                    Util.setElement("value", "ddl_customize_item_item1_size", sizeId);
                                    const halfModifier = document.getElementById(`ddl_customize_item_modifiers_${itmID}_${iMdg.ModifierDisplayGroupId}_${halfIndex}`) as HTMLSelectElement;
                                    if (halfModifier) {
                                        halfModifier.value = iMod.ModifierId.toString();
                                        halfModifier.dataset.preindex = iModIDX.toString();
                                    }
                                }
                                self._setCustItemSize();
                            } else
                                DialogCreators.messageBoxOk(Names("UnableFindProperSizeApply"), this._localAolo.buttonHoverStyle);
                        }
                    },
                    {
                        "text": Names("No"), "callBack": () => {
                            const currentModifierId = this._customize.Items[0].Modifiers[iDGIDX].ModifierId;
                            const isHH = this._customize.Items.length > 1;
                            if (isHH)
                                Util.setElement("value", `ddl_customize_item_modifiers_whole_${iMdg.ModifierDisplayGroupId}`, currentModifierId);
                            else
                                Util.setElement("value", `ddl_customize_item_modifiers_${itmID}_${iMdg.ModifierDisplayGroupId}_${halfIndex}`, currentModifierId);
                        }
                    }]);
            }
            return false;
        }

        let adjusted = false;
        let existingModIdxInDG = -1;
        if (mdg.MustToggle || mdg.MaxSelectionCount === 1)
            existingModIdxInDG = this._getFirstModifierIndexOfoItemInDisplayGroup(oItem, mdg.ModifierDisplayGroupId);

        if ((mdg.MustToggle || mdg.MaxSelectionCount === 1) && existingModIdxInDG > -1) {
            let nMod = oItem.Modifiers[existingModIdxInDG];
            nMod.ModifierId = iMod.ModifierId;
            nMod.Index = iMod.Index;
            nMod.iIndex = iModIDX;
            nMod.iModifierDisplayGroupIndex = iDGIDX;
            nMod.PreModifierId = preModID;
            nMod.IsDefault = iMod.IsDefault;
            nMod.MergedModifierDisplayGroupId = iMod.MergedModifierDisplayGroupId;
            nMod.CountForPricing = iMod.CountForPricing;
            nMod.CountForPricingByItself = iMod.CountForPricingByItself;
            adjusted = true;
        } else {
            for (const [index, mod] of oItem.Modifiers.entries()) {
                if (mod.ModifierId === modID) {
                    if (iMod.IsDefault || preModID !== this._localAolo.Temp.NonPreModID) {
                        mod.PreModifierId = preModID;
                    } else if (preModID === this._localAolo.Temp.NonPreModID) {
                        oItem.Modifiers.splice(index, 1);
                    }
                    adjusted = true;
                    break;
                }
            }
        }

        if (!adjusted && preModID !== this._localAolo.Temp.NonPreModID) {
            // the line above should state if (!adjusted && preModID !== this._localAolo.Temp.NonPreModID) {
            const nMod: IOrderItemModifier = {
                ModifierId: modID,
                Name: iMod.Name,
                Index: iMod.Index,
                ModifierDisplayGroupIndex: iMdg.Index,
                iIndex: iModIDX,
                iModifierDisplayGroupIndex: iDGIDX,
                PreModifierId: preModID,
                IsDefault: iMod.IsDefault,
                ModifierDisplayGroupId: mdg.ModifierDisplayGroupId,
                MergedModifierDisplayGroupId: iMod.MergedModifierDisplayGroupId,
                CountForPricing: iMod.CountForPricing,
                CountForPricingByItself: iMod.CountForPricingByItself,
                Price: 0
            };
            oItem.Modifiers.push(nMod);
            if (OnlineOrderingUtil.GetItemPrice(mItem, oItem, this._localAolo).Price < 0) {
                oItem.Modifiers.pop();
                DialogCreators.messageBoxOk(Names("ReachMax"), this._localAolo.buttonHoverStyle);
                return false;
            }
        }

        this._priceSelectedItem(halfIndex);
        if (whole) {
            halfIndex += 1;
            if (halfIndex === 3)
                halfIndex = 1;

            return this.alterSelectedItemMod(itmID, iDGIDX, modID, iModIDX, preModID, halfIndex, false, ignoreSize);
        } else
            return true;
    }

    private _getModSelect = (mods: IItemModifierDisplayGroupModifier[], selectedModID: number | null): HTMLSelectElement => {
        const selMod = document.createElement("select");

        for (const [index, mod] of mods.entries()) {
            const cal = OnlineOrderingUtil.GetModifierCalories(Common.GetDescription(this._localAolo.data.Modifiers[mod.Index].Descriptions, this._localAolo.Temp.languageCode));

            let option = document.createElement("option");
            option.text = (cal !== "") ? `${Common.GetName(this._localAolo.data.Modifiers[mod.Index].Names, this._localAolo.Temp.languageCode)} (${cal})` : Common.GetName(this._localAolo.data.Modifiers[mod.Index].Names, this._localAolo.Temp.languageCode);
            option.value = mod.ModifierId.toString();
            if (selectedModID === null && mod.IsDefault) {
                option.selected = true;
                selMod.setAttribute("data-preindex", index.toString());
            } else if (selectedModID !== null && mod.ModifierId === selectedModID) {
                option.selected = true;
                selMod.setAttribute("data-preindex", index.toString());
            }
            selMod.add(option);
        }

        return selMod;
    }

    private _setMultiChoiceModCalories = (mItem: IDataItem, sizeID: number | null, halfIndex: number): void => {
        try {
            let disGrpModCount = [];
            let serveCals = OnlineOrderingUtil.getItemSizeMinMaxCalories(mItem, sizeID);
            let servingName = (mItem.ServingNames) ? Common.GetName(mItem.ServingNames, this._localAolo.Temp.languageCode) : "Slice";

            for (const modDG of mItem.ModifierDisplayGroups) {
                for (const mod of modDG.Modifiers) {
                    let spnCalid = `spnCustModCal_${halfIndex}_${modDG.ModifierDisplayGroupId}_${mod.ModifierId}`;
                    let snp = document.getElementById(spnCalid);
                    if (!snp)
                        continue;

                    let mCal = OnlineOrderingUtil.GetModifierCalories(Common.GetDescription(this._localAolo.data.Modifiers[mod.Index].Descriptions, this._localAolo.Temp.languageCode));
                    if (mCal === "") {
                        let modCount = 0;
                        if (disGrpModCount[mod.MergedModifierDisplayGroupId]) {
                            modCount = disGrpModCount[mod.MergedModifierDisplayGroupId];
                        } else {
                            disGrpModCount[mod.MergedModifierDisplayGroupId] = OnlineOrderingUtil.getMenuItemModCount(mItem, mod.MergedModifierDisplayGroupId);
                            modCount = disGrpModCount[mod.MergedModifierDisplayGroupId];
                        }
                        if (modCount == 0)
                            modCount = 1;
                        let cals = OnlineOrderingUtil.getMenuItemModCalories(mod, sizeID, modCount, this._localAolo.Temp.DefPreModID);
                        if (cals > 0) {
                            if (mItem.CalorieDisplayId == 1) // per serving
                                mCal = `${Math.trunc(cals / serveCals.serves)} cal. / ${servingName}`;
                            else if (mItem.CalorieDisplayId == 2) // fraction 
                                mCal = `${Math.trunc(cals / serveCals.serves)} cal. per 1/${serveCals.serves} ${servingName}`;
                        } else
                            mCal = "";
                    }
                    snp.innerText = mCal;
                }
            }
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("_setMultiChoiceModCalories", ex, this._localAolo);
        }
    }

    private _displayItemsPrice = (): void => {
        Util.setElement("innerText", "spn_customize_item_item_price", `${Util.formatMoney(this._customize.Items[0].HalfHalfPrice)}`);
    }

    private _displayItemsCalories = (halfIndex: number): void => {
        let oItem = this._customize.Items[halfIndex];
        let mItem = this._localAolo.data.Items.filter(x => x.ItemId === oItem.ItemId)[0];
        const calories = OnlineOrderingUtil.getItemCalories(mItem, oItem, this._localAolo.Order.OrderTypeID, oItem.SizeId, this._localAolo);
        if (this._customize.Items.length == 1)
            Util.setElement("innerText", "lbl_customize_item_calories", Common.GetName(calories, this._localAolo.Temp.languageCode));
        else
            Util.setElement("innerText", `spn_customize_item_item${(halfIndex + 1)}_calories`, Common.GetName(calories, this._localAolo.Temp.languageCode));
    }

    private _getFirstModifierIndexOfoItemInDisplayGroup = (oItem: IOrderItem, ModifierDisplayGroupId: number): number => {
        return oItem.Modifiers.findIndex(mod => mod.ModifierDisplayGroupId === ModifierDisplayGroupId);
    }

    private _getAvailableModSizes = (mItem: IDataItem, iMod: IItemModifierDisplayGroupModifier): { Names: string, Count: number } => {
        const sizes = OnlineOrderingUtil.GetItemSizes(mItem, this._localAolo.Order.OrderTypeID);
        let sizeNames = "";

        let count = sizes.reduce((count, size) => {
            if (OnlineOrderingUtil.GetModifierAddToPrice(iMod, size.SizeId) !== -1) {
                let sizeName = Common.GetName(this._localAolo.data.Sizes[size.Index].Names, this._localAolo.Temp.languageCode);
                sizeNames += `"${sizeName}", `;
                count++;
            }
            return count;
        }, 0);

        sizeNames = sizeNames.substring(0, sizeNames.length - 2);
        return {
            Names: sizeNames,
            Count: count
        };
    }

    private _globalErrorValueSet = (msg: string): void => {
        this._localAolo.globalErrorValue = msg;
    }

    private _globalErrorValueAddMsg = (msg: string): void => {
        if (!this._localAolo.globalErrorValue)
            this._localAolo.globalErrorValue = "";

        this._localAolo.globalErrorValue += ` >> ${msg}`;
    }

    private _globalErrorValueAddObj = (obj: unknown): void => {
        if (!this._localAolo.globalErrorValue)
            this._localAolo.globalErrorValue = "";

        try {
            this._localAolo.globalErrorValue += ` >> ${JSON.stringify(obj)}`;
        } catch (ex: unknown) {
            if (ex instanceof Error)
                this._localAolo.globalErrorValue += ` >> Adding Error Object Error > ${ex.message}`;
        }
    }

    private _checkDisplayGroupMax = (oItem: IOrderItem, iModifierDisplayGroupIndex: number): boolean => {
        const mItem = OnlineOrderingUtil.GetItem(oItem.ItemId, this._localAolo.data.Items);
        if (!mItem)
            return true;

        const mdg = this._localAolo.data.ModifierDisplayGroups[mItem.ModifierDisplayGroups[iModifierDisplayGroupIndex].Index];
        const modCount = oItem.Modifiers.filter(mod => mod.ModifierDisplayGroupId === mdg.ModifierDisplayGroupId).length;

        if (mdg.MaxSelectionCount > 1 && !mdg.MustToggle && mdg.IsVisible && modCount >= mdg.MaxSelectionCount) {
            DialogCreators.messageBoxOk(Names("ReachedMaxNumberModifiersOnItem"), this._localAolo.buttonHoverStyle);
            return false;
        }

        return true;
    }

    private _checkModifiersMax = (mItem: IDataItem, oItem: IOrderItem, iModifierDisplayGroupIndex: number): boolean => {
        const mdg = this._localAolo.data.ModifierDisplayGroups[mItem.ModifierDisplayGroups[iModifierDisplayGroupIndex].Index];
        if (mdg.MaxSelectionCount == 1 || mdg.MustToggle || !mdg.IsVisible)
            return true;

        let mCount = oItem.Modifiers.reduce((count, mod) => {
            if (!mod.IsDefault) {
                const dGroup = this._getModifierDisplayGroup(mItem, mod.ModifierId);
                if (dGroup && !dGroup.MustToggle && dGroup.MaxSelectionCount !== 1)
                    count++;
            }
            return count;
        }, 0);

        if (mCount >= mItem.MaximumModifierCount) {
            DialogCreators.messageBoxOk(Names("ReachedMaxNumberModifiersOnItem"), this._localAolo.buttonHoverStyle);
            return false;
        }
        return true;
    }

    private _getModifierDisplayGroup = (mItem: IDataItem, modID: number): IDataModifierDisplayGroup | null => {
        for (const mdg of mItem.ModifierDisplayGroups) {
            const mod = mdg.Modifiers.find(m => m.ModifierId === modID);
            if (mod)
                return this._localAolo.data.ModifierDisplayGroups[mdg.Index];
        }

        return null;
    }

    private _checkWholeModifiersCompatible = (mItem: IDataItem | null): boolean => {
        if (!mItem)
            return true;

        const firstOrderItem = this._customize.Items[0];
        if (firstOrderItem.SizeId == null)
            Util.LogError("customize-item -> _checkWholeModifiersCompatible", new Error("SizeId is null"), this._localAolo);

        for (const modifier of firstOrderItem.Modifiers) {
            let modGroup = this._localAolo.data.ModifierDisplayGroups.filter(x => x.ModifierDisplayGroupId === modifier.MergedModifierDisplayGroupId)[0];
            if (modGroup.IsHalfable)
                continue;

            const selectedModifier = mItem.ModifierDisplayGroups.find(x => x.ModifierDisplayGroupId === modGroup.ModifierDisplayGroupId)?.Modifiers || [];
            let modifierApplicable = selectedModifier.findIndex(x => x.ModifierId === modifier.ModifierId) !== -1;
            if (!modifierApplicable) {
                const mod = this._localAolo.data.Modifiers.find(x => x.ModifierId === modifier.ModifierId);
                DialogCreators.messageBoxOk(Names("ModifierIsNotCompatible").replace("???", mod ? Common.GetName(mod.Names, this._localAolo.Temp.languageCode) : "Unknown Modifier"), this._localAolo.buttonHoverStyle);
                return false;
            }
        }

        return true;
    }

    private _getSingleChoiceModifier_oItem = (oItem: IOrderItem, mdgID: number): IOrderItemModifier | null => {
        return oItem.Modifiers.find(oMod => oMod.ModifierDisplayGroupId === mdgID) || null;
    }

    private _updateAddToOrderButtonText = (buttonText: string): void => {
        Util.setElement("innerText", "btn_customize_item_add_to_order", buttonText);
    }

    public makeItemHalfHalf = (): void => {
        let item1 = this._customize.Items[0];
        if (item1.SizeId == null)
            Util.LogError("customize-item -> makeItemHalfHalf", new Error("SizeId is null"), this._localAolo);

        if (!this._checkSizeIsHalfable(this._localAolo.data.Items[item1.Index], item1.SizeId)) {
            return;
        }
        let item2 = JSON.parse(JSON.stringify(item1));
        if (this._editing) {
            item2.ItemRecId = OnlineOrderingUtil.GetRecID(this._customize.Items);
        } else {
            item2.ItemRecId = 0;
        }
        this._customize.Items.push(item2);
        this._setItems();
        this._priceSelectedItem(1);
        this._priceSelectedItem(2);
        this._displayItemsPrice();
        this._displayItemsCalories(0);
        this._displayItemsCalories(1);

        Util.setElementClass("remove", "div_customize_item_item1_name", "this-ci-header");
        Util.setElementClass("add", "div_customize_item_half_selector", "this-ci-header");
    }

    private _checkSizeIsHalfable = (mItem: IDataItem, sizeID: number): boolean => {
        let iSize = OnlineOrderingUtil.GetItemSize(mItem, this._localAolo.Order.OrderTypeID, sizeID);
        if (iSize === null || !iSize.IsHalfable) {
            DialogCreators.messageBoxOk(Names("SizeNoHalf"), this._localAolo.buttonHoverStyle);
            return false;
        }
        return iSize.IsHalfable;
    }

    private _changeCustItem = (): void => {
        let itemId = parseInt(Util.getElementValue("ddl_customize_item_item1_items"));
        this.custItemChange(itemId, 1);
    }

    private _changeCustItemHalf1 = (): void => {
        let itemId = parseInt(Util.getElementValue("ddl_customize_item_item1_items_half"));
        this.custItemChange(itemId, 1);
    }

    private _changeCustItemHalf2 = (): void => {
        const itemId = parseInt(Util.getElementValue("ddl_customize_item_item2_items_half"));
        this.custItemChange(itemId, 2);
    }

    private _setCustItemSize = (): void => {
        let isHH = this._customize.Items.length > 1;
        let cSizeID = this._customize.Items[0].SizeId;
        let szID = 0;

        if (isHH) {
            szID = parseInt(Util.getElementValue("ddl_customize_item_whole_size"));
            if (!szID)
                Util.LogError(`customize-item -> _setCustItemSize 0 -> ${szID}`, new Error("SizeId is null"), this._localAolo);

            if (!this._checkSizeIsHalfable(this._localAolo.data.Items[this._customize.Items[0].Index], szID) ||
                !this._checkSizeIsHalfable(this._localAolo.data.Items[this._customize.Items[1].Index], szID) ||
                !this._checkItemModsForSizes(this._customize.Items[0], szID) ||
                !this._checkItemModsForSizes(this._customize.Items[1], szID)) {
                Util.setElement("value", "ddl_customize_item_whole_size", cSizeID);
                return;
            }
        } else {
            szID = parseInt(Util.getElementValue("ddl_customize_item_item1_size"));
            if (!szID)
                Util.LogError(`customize-item -> _setCustItemSize 1 -> ${szID}`, new Error("SizeId is null"), this._localAolo);

            if (!this._checkItemModsForSizes(this._customize.Items[0], szID)) {
                Util.setElement("value", "ddl_customize_item_item1_size", this._sizeId || cSizeID);
                this._setInstructions();
                this._setCustItemSizeCountinue(isHH, this._sizeId || cSizeID);
                return;
            }
        }

        this._sizeId = szID;
        this._setInstructions();
        this._setCustItemSizeCountinue(isHH, szID);
    }

    private _checkItemModsForSizes = (oItem: IOrderItem, sizeId: number): boolean => {
        let mItem = this._localAolo.data.Items.find(x => x.ItemId === oItem.ItemId);
        if (!mItem)
            return false;

        let iSize = OnlineOrderingUtil.GetItemSize(mItem, this._localAolo.Order.OrderTypeID, sizeId);

        for (const oMod of oItem.Modifiers) {
            const miMdg = mItem.ModifierDisplayGroups.find(x => x.ModifierDisplayGroupId === oMod.ModifierDisplayGroupId);
            if (!miMdg)
                continue;

            const miMod = miMdg.Modifiers.find(x => x.ModifierId === oMod.ModifierId);
            if (!miMod)
                continue;

            if (!OnlineOrderingUtil.isModOfferedOnSize(miMod, sizeId)) {
                const gMod = this._localAolo.data.Modifiers[miMod.Index];
                const modName = Common.GetName(gMod.Names, this._localAolo.Temp.languageCode);
                const sizeName = iSize ? Common.GetName(this._localAolo.data.Sizes[iSize.Index].Names, this._localAolo.Temp.languageCode) : "";
                DialogCreators.messageBoxOk(Names("IsNotBeingOfferedOn").replace("??", modName).replace("???", sizeName), this._localAolo.buttonHoverStyle);

                // find largest appropriate size for given modifier
                const sizes = OnlineOrderingUtil.GetItemSizes(mItem, this._localAolo.Order.OrderTypeID).slice().sort((a, b) => b.Index - a.Index);
                this._sizeId = sizes.find(size => OnlineOrderingUtil.isModOfferedOnSize(miMod, size.SizeId))?.SizeId || null;
                return false;
            }
        }
        return true;
    }

    private _setCustItemSizeCountinue = (isHH: boolean, szID: number): void => {
        let oItem1 = this._customize.Items[0];
        if (oItem1.SizeId == null)
            Util.LogError(`customize-item -> _setCustItemSizeCountinue 00`, new Error("SizeId is null"), this._localAolo);

        let currentSizeID = oItem1.SizeId;
        oItem1.SizeId = szID;
        if (szID == null)
            Util.LogError("customize-item -> _setCustItemSizeCountinue 1", new Error("SizeId is null"), this._localAolo);
        let mItem1 = this._localAolo.data.Items.filter(x => x.ItemId === oItem1.ItemId)[0];
        if (OnlineOrderingUtil.GetItemPrice(mItem1, oItem1, this._localAolo).Price < 0) {
            oItem1.SizeId = currentSizeID;
            if (currentSizeID == null)
                Util.LogError("customize-item -> _setCustItemSizeCountinue 2", new Error("SizeId is null"), this._localAolo);
            DialogCreators.messageBoxOk(Names("ReachMax"), this._localAolo.buttonHoverStyle);
            Util.setElement("value", "ddl_customize_item_item1_size", currentSizeID);
        } else if (isHH) {
            let oItem2 = this._customize.Items[1];
            oItem2.SizeId = szID;
            if (szID == null)
                Util.LogError("customize-item -> _setCustItemSizeCountinue 3", new Error("SizeId is null"), this._localAolo);
            let mItem2 = this._localAolo.data.Items.filter(x => x.ItemId === oItem2.ItemId)[0];
            if (OnlineOrderingUtil.GetItemPrice(mItem2, oItem2, this._localAolo).Price < 0) {
                oItem1.SizeId = currentSizeID;
                oItem2.SizeId = currentSizeID;
                if (currentSizeID == null)
                    Util.LogError("customize-item -> _setCustItemSizeCountinue 4", new Error("SizeId is null"), this._localAolo);
                DialogCreators.messageBoxOk(Names("ReachMax"), this._localAolo.buttonHoverStyle);
                Util.setElement("value", "ddl_customize_item_item1_size", currentSizeID);
                Util.setElement("value", "ddl_customize_item_whole_size", currentSizeID);
            } else {
                this._priceSelectedItem(1);
                this._priceSelectedItem(2);
                this._displayItemsPrice();
            }
            this._setMultiChoiceModCalories(mItem1, szID, 1);
            this._setMultiChoiceModCalories(mItem2, szID, 2);
        } else {
            this._priceSelectedItem(1);
            this._displayItemsPrice();
            this._setMultiChoiceModCalories(mItem1, szID, 1);
            this._checkHideHalfHalfButtonBySize(oItem1.ItemId);
        }

        this._displayItemsCalories(0);

        if (isHH)
            this._displayItemsCalories(1);
    }

    private _setCustItemQty = (): void => {
        const isHH = this._customize.Items.length > 1;
        const qty = isHH ? parseInt(Util.getElementValue("ddl_customize_item_whole_quantity")) : parseInt(Util.getElementValue("ddl_customize_item_item1_quantity"));

        Util.setElement("value", "ddl_customize_item_item1_quantity", qty);
        Util.setElement("value", "ddl_customize_item_whole_quantity", qty);

        let oItem = this._customize.Items[0];
        if (oItem.SizeId == null)
            Util.LogError(`customize-item -> _setCustItemQty`, new Error("SizeId is null"), this._localAolo);

        oItem.Quantity = qty;
        if (this._customize.Items.length === 2) {
            let oItem = this._customize.Items[1];
            oItem.Quantity = qty;
        }

        this._taxSelectedItem();
    }

    private _removeFirstHalf = (): void => {
        this._customize.Items.shift();
        this._customize.Items[0].HalfCount = 1;
        this._customize.Items[0].HalfIndex = 0;
        if (this._customize.Items[0].SizeId == null)
            Util.LogError(`customize-item -> _removeFirstHalf`, new Error("SizeId is null"), this._localAolo);
        OnlineOrderingUtil.PriceItem(this._customize.Items[0], this._localAolo);
        this._setItems();
        this._displayItemsPrice();
        this._displayItemsCalories(0);
        Util.setElementClass("add", "div_customize_item_item1_name", "this-ci-header");
        Util.setElementClass("remove", "div_customize_item_half_selector", "this-ci-header");
    }

    private _removeSecondHalf = (): void => {
        this._customize.Items.pop();
        this._customize.Items[0].HalfCount = 1;
        this._customize.Items[0].HalfIndex = 0;
        if (this._customize.Items[0].SizeId == null)
            Util.LogError(`customize-item -> _removeSecondHalf`, new Error("SizeId is null"), this._localAolo);
        OnlineOrderingUtil.PriceItem(this._customize.Items[0], this._localAolo);
        this._setItems();
        this._displayItemsPrice();
        this._displayItemsCalories(0);
        Util.setElementClass("add", "div_customize_item_item1_name", "this-ci-header");
        Util.setElementClass("remove", "div_customize_item_half_selector", "this-ci-header");
    }

    public addSelectedToOrder = async (): Promise<void> => {
        if (this._localAolo.Temp.AddingCustomItemToOrder)
            return;

        this._localAolo.Temp.AddingCustomItemToOrder = true;
        let itemKey = -1;

        let halfChecked1 = true;
        let halfChecked2 = true;
        if (this._customize.Items.length > 1) {
            halfChecked1 = OnlineOrderingUtil.IsItemComplete(this._customize.Items[0], true, `${Names("SelTop1Half")}<br/>${Names("SelCheese")}`, this._localAolo);
            halfChecked2 = OnlineOrderingUtil.IsItemComplete(this._customize.Items[1], halfChecked1, `${Names("SelTop2Half")}<br/>${Names("SelCheese")}`, this._localAolo);
        } else
            halfChecked1 = OnlineOrderingUtil.IsItemComplete(this._customize.Items[0], true, null, this._localAolo);

        if (halfChecked1 && halfChecked2) {
            itemKey = await this._addSelectedToOrder_Continue();
            if (itemKey == -1)
                return;
        } else
            this._localAolo.Temp.AddingCustomItemToOrder = false;

        let mealDealFinished = await OnlineOrderingUtil.MealDealAddToOrder(itemKey, this._localAolo);

        if (mealDealFinished)
            delete this._localAolo.Dialog.MealDeal;
        else if (!this._editingCartItem)
            await this._localAolo.Modules.Coupon.AutoApplyCoupons();

        await aOLOModules.LoyaltyProvider.batchComparison(true);

        if (this._localAolo.Modules.DataLayer && itemKey !== -1)
            this._localAolo.Modules.DataLayer.add_to_cart(this._localAolo.Order.Items.find(x => x.ItemKey == itemKey));
    }

    private _scrollToHalf1 = (): void => {
        const h1 = document.getElementById("div_customize_item_item1");
        if (!h1)
            return;

        const anchorTop = OnlineOrderingUtil.getElementTop(h1, "div_customize_item_scroll") - 32;
        let scrollOptionsSmooth = {
            left: 0,
            top: anchorTop,
            behavior: 'smooth' as 'smooth' | 'auto'
        };

        const scrollDiv = document.getElementById("div_customize_item_scroll");
        if (scrollDiv)
            scrollDiv.scrollTo(scrollOptionsSmooth);
    }

    private _scrollToHalf2 = (): void => {
        const h2 = document.getElementById("div_customize_item_item2");
        if (!h2)
            return;

        const anchorTop = OnlineOrderingUtil.getElementTop(h2, "div_customize_item_scroll") - 32;
        let scrollOptionsSmooth = {
            left: 0,
            top: anchorTop,
            behavior: 'smooth' as 'smooth' | 'auto'
        };

        const scrollDiv = document.getElementById("div_customize_item_scroll");
        if (scrollDiv)
            scrollDiv.scrollTo(scrollOptionsSmooth);
    }

    private _revertEditing = (): void => {
        for (let x = 0; x < this._itemHist.length; x++) {
            let oldItem = this._itemHist[x];
            if (!oldItem)
                continue;

            for (let i = 0; i < this._localAolo.Order.Items.length; i++) {
                if (this._localAolo.Order.Items[i].ItemKey === oldItem.ItemKey &&
                    this._localAolo.Order.Items[i].HalfIndex === oldItem.HalfIndex) {
                    this._localAolo.Order.Items[i] = oldItem;
                    break;
                }
            }
        }
        this._itemHist = [];
    }

    public custItemChange = (itemId: number, halfIndex: number): void => {
        let isHH = this._customize.Items.length > 1;
        const mItem = this._localAolo.data.Items.find(x => x.ItemId == itemId);
        if (!mItem)
            return;

        let oItem = this._customize.Items[halfIndex - 1];
        if (oItem.SizeId == null)
            Util.LogError(`customize-item -> custItemChange -> ${halfIndex}`, new Error("SizeId is null"), this._localAolo);

        if (isHH && (!this._checkSizeIsHalfable(mItem, oItem.SizeId) || !this._checkWholeModifiersCompatible(mItem))) {
            if (halfIndex === 1)
                Util.setElement("value", "ddl_customize_item_item1_items_half", oItem.Index);
            else
                Util.setElement("value", "ddl_customize_item_item2_items_half", oItem.Index);
            return;
        }

        oItem.Modifiers = OnlineOrderingUtil.Item_Get_Default_Mods(mItem, oItem.SizeId, this._localAolo);
        oItem.ItemId = mItem.ItemId;
        oItem.Index = mItem.Index;
        oItem.BeforeTaxDiscount = 0;
        oItem.AfterTaxDiscount = 0;

        let sizeID = oItem.SizeId;
        let size = OnlineOrderingUtil.GetItemSize(mItem, this._localAolo.Order.OrderTypeID, sizeID);
        if (size === null) {
            size = OnlineOrderingUtil.GetItemDefaultSize(mItem, this._localAolo.Order.OrderTypeID, sizeID, this._localAolo.data.Settings.DSZID);
            let sizeName = Common.GetName(this._localAolo.data.Sizes[size.Index].Names, this._localAolo.Temp.languageCode);
            let sizeMsg = Names("ItemSizeNotHalfHalf");
            sizeMsg = sizeMsg.replace("???", Common.GetName(mItem.Names, this._localAolo.Temp.languageCode));
            sizeMsg = sizeMsg.replace("??", sizeName);
            DialogCreators.messageBoxOk(sizeMsg, this._localAolo.buttonHoverStyle);
            oItem.SizeId = size.SizeId;
            sizeID = oItem.SizeId;
        }

        if (isHH) {
            let mItem1 = this._localAolo.data.Items[this._customize.Items[0].Index];
            let mItem2 = this._localAolo.data.Items[this._customize.Items[1].Index];
            let selectedSizeID = this._filterHalfHalfSizes(mItem1, mItem2, sizeID);
            if (selectedSizeID > -1) {
                if (selectedSizeID == null)
                    Util.LogError(`customize-item -> custItemChange 1 -> ${selectedSizeID}`, new Error("SizeId is null"), this._localAolo);
                this._customize.Items[0].SizeId = selectedSizeID;
                this._customize.Items[1].SizeId = selectedSizeID;
                this._priceSelectedItem(1);
                this._priceSelectedItem(2);
            } else
                DialogCreators.messageBoxOk(Names("InvalidCommonSize"), this._localAolo.buttonHoverStyle, null, null);

            if (halfIndex === 1)
                this._applyNoneHalfableModifiers(mItem2, halfIndex);
            else
                this._applyNoneHalfableModifiers(mItem1, halfIndex);
        } else {
            this._priceSelectedItem(1);
            this._setItemSizes(mItem, this._localAolo.Order.OrderTypeID, sizeID);
        }

        if (halfIndex === 1) {
            const imgItem1Half = document.getElementById("img_customize_item_item1_image_half");
            if (imgItem1Half) {
                imgItem1Half.setAttribute("src", mItem.ImageURL);
                imgItem1Half.setAttribute("alt", `Menu item image for ${Common.GetName(mItem.Names, this._localAolo.Temp.languageCode)}`);
            }

            Util.setElement("innerText", "div_customize_item_item1_name", Common.GetName(mItem.Names, this._localAolo.Temp.languageCode));

            const imgItem1 = document.getElementById("img_customize_item_item1_image");
            if (imgItem1) {
                imgItem1.setAttribute("src", mItem.ImageURL);
                imgItem1.setAttribute("alt", `Menu item image for ${Common.GetName(mItem.Names, this._localAolo.Temp.languageCode)}`);
            }
        } else {
            const imgItem2 = document.getElementById("img_customize_item_item2_image");
            if (imgItem2) {
                imgItem2.setAttribute("src", mItem.ImageURL);
                imgItem2.setAttribute("alt", `Menu item image for ${Common.GetName(mItem.Names, this._localAolo.Temp.languageCode)}`);
            }
        }

        this._setSingleChoiceMods(mItem, oItem, halfIndex, isHH);
        this._setMultiChoiceMods(mItem, oItem, halfIndex);
        this._setMultiChoiceModCalories(mItem, oItem.SizeId, halfIndex);
    }

    private _filterHalfHalfSizes = (mItem1: IDataItem, mItem2: IDataItem, sizeID: number): number => {
        let selectedSizeID = -1;
        const sizDDL = document.getElementById("ddl_customize_item_item1_size") as HTMLSelectElement;
        if (sizDDL)
            sizDDL.innerText = "";

        const sizDDLWhol = document.getElementById("ddl_customize_item_whole_size") as HTMLSelectElement;
        if (sizDDLWhol)
            sizDDLWhol.innerText = "";

        let orderTypeID = this._localAolo.Order.OrderTypeID;

        let sizes1 = OnlineOrderingUtil.GetItemSizes(mItem1, orderTypeID);
        let sizes2 = OnlineOrderingUtil.GetItemSizes(mItem2, orderTypeID);

        let xSize = null;

        for (let i = 0; i < sizes1.length; i++) {
            let siz1 = sizes1[i];

            if (!xSize && siz1.SizeId === sizeID)
                xSize = sizes1[i];

            for (let j = 0; j < sizes2.length; j++) {
                let siz2 = sizes2[j];

                if (!xSize && siz2.SizeId === sizeID)
                    xSize = sizes2[j];

                if (siz1.SizeId !== siz2.SizeId)
                    continue;

                let sizName = Common.GetName(this._localAolo.data.Sizes[siz1.Index].Names, this._localAolo.Temp.languageCode);
                let sizDesc = Common.GetDescription(this._localAolo.data.Sizes[siz1.Index].Descriptions, this._localAolo.Temp.languageCode);

                let option = document.createElement("option");
                option.text = `${sizName} (${sizDesc})`;
                option.value = siz1.SizeId.toString();
                if (siz1.SizeId === sizeID) {
                    option.selected = true;
                    selectedSizeID = sizeID;
                }
                if (sizDDL)
                    sizDDL.add(option);

                let optionW = document.createElement("option");
                optionW.text = `${sizName} (${sizDesc})`;
                optionW.value = siz1.SizeId.toString();
                if (siz1.SizeId === sizeID)
                    optionW.selected = true;

                if (sizDDLWhol)
                    sizDDLWhol.add(optionW);

                break;
            }
        }
        return selectedSizeID;
    }

    private _applyNoneHalfableModifiers = (nonChangedHalfmItem: IDataItem, changedIndex: number): void => {
        let changedoItem = this._customize.Items[changedIndex - 1];
        let notChangedoItem = this._customize.Items[changedIndex % 2];
        if (changedoItem.SizeId == null)
            Util.LogError(`customize-item -> _applyNoneHalfableModifiers 0 -> ${changedIndex}`, new Error("SizeId is null"), this._localAolo);

        if (notChangedoItem.SizeId == null)
            Util.LogError(`customize-item -> _applyNoneHalfableModifiers 1 -> ${changedIndex}`, new Error("SizeId is null"), this._localAolo);

        if (!nonChangedHalfmItem || !nonChangedHalfmItem.ModifierDisplayGroups)
            return;

        for (const iMdg of nonChangedHalfmItem.ModifierDisplayGroups) {
            let mdg = this._localAolo.data.ModifierDisplayGroups[iMdg.Index];
            if (!mdg.IsVisible || mdg.IsHalfable)
                continue;

            let iMod = this._getSingleChoiceModifier_oItem(notChangedoItem, mdg.ModifierDisplayGroupId);
            let existingModIdxInDG = this._getFirstModifierIndexOfoItemInDisplayGroup(changedoItem, mdg.ModifierDisplayGroupId);
            if (!iMod || existingModIdxInDG <= -1)
                continue;

            let nMod = changedoItem.Modifiers[existingModIdxInDG];
            nMod.ModifierId = iMod.ModifierId;
            nMod.Index = iMod.Index;
            nMod.iIndex = iMod.iIndex;
            nMod.iModifierDisplayGroupIndex = iMod.iModifierDisplayGroupIndex;
            nMod.PreModifierId = iMod.PreModifierId;
            nMod.IsDefault = iMod.IsDefault;
            nMod.CountForPricing = iMod.CountForPricing;
            nMod.CountForPricingByItself = iMod.CountForPricingByItself;
        }
    }

    private _priceSelectedItem = (halfIndex: number): void => {
        let oItem = this._customize.Items[halfIndex - 1];
        if (oItem.SizeId == null)
            Util.LogError(`customize-item -> _priceSelectedItem 1 -> ${halfIndex}`, new Error("SizeId is null"), this._localAolo);
        let mItem = this._localAolo.data.Items.find(x => x.ItemId === oItem.ItemId);
        if (!mItem?.Prices)
            return;

        let iPrice = OnlineOrderingUtil.GetItemPrice(mItem, oItem, this._localAolo);
        let price = iPrice.Price;
        if (price < 0) price = -price;
        oItem.MenuPrice = price;
        oItem.MenuPriceTax = iPrice.Tax;
        oItem.Tax = iPrice.Tax;
        oItem.PriceIncludesTax = iPrice.TaxIncluded
        oItem.Price = price;
        oItem.MinPrice = iPrice.MinPrice;
        oItem.HalfHalfPrice = price;
        if (this._customize.Items.length === 2) {
            const highestPrice = this._localAolo.data.Settings.HHP === 1;
            const maxPrice = Math.max(0, this._customize.Items[0].MenuPrice, this._customize.Items[1].MenuPrice);
            const maxPriceTax = Math.max(0, this._customize.Items[0].MenuPriceTax, this._customize.Items[1].MenuPriceTax);
            const totalPrice = this._customize.Items[0].MenuPrice + this._customize.Items[1].MenuPrice;
            const totalPriceTax = this._customize.Items[0].MenuPriceTax + this._customize.Items[1].MenuPriceTax;

            // hhPrice it the half half item price
            let hhPrice = Util.Float2(maxPrice);
            let hhPriceTax = Util.Float2(maxPriceTax);
            if (!highestPrice) {
                hhPrice = Util.Float2(totalPrice / 2);
                hhPriceTax = Util.Float2(totalPriceTax / 2);
            }

            //1=Highest Price]2=Average Price
            //let hhPrice = price;
            //let hhPriceTax = Util.Float2(iPrice.Tax);
            //if (highestPrice) {
            //    if (hhPrice < this._customize.Items[0].MenuPrice) {
            //        hhPrice = this._customize.Items[0].MenuPrice;
            //        hhPriceTax = this._customize.Items[0].MenuPriceTax;
            //    }
            //    if (hhPrice < this._customize.Items[1].MenuPrice) {
            //        hhPrice = this._customize.Items[1].MenuPrice;
            //        hhPriceTax = this._customize.Items[1].MenuPriceTax;
            //    }
            //} else {
            //    if (halfIndex === 1) {
            //        hhPrice = (price + this._customize.Items[1].MenuPrice) / 2;
            //        hhPriceTax = (iPrice.Tax + this._customize.Items[1].MenuPriceTax) / 2;
            //    }
            //    if (halfIndex === 2) {
            //        hhPrice = (price + this._customize.Items[0].MenuPrice) / 2;
            //        hhPriceTax = (iPrice.Tax + this._customize.Items[0].MenuPriceTax) / 2;
            //    }
            //}


            hhPrice = Util.Float2(hhPrice);
            this._customize.Items[0].HalfHalfPrice = hhPrice;
            this._customize.Items[1].HalfHalfPrice = hhPrice;
            if (highestPrice) {
                this._customize.Items[0].Price = Util.Float2(hhPrice / 2);
                this._customize.Items[0].Tax = Util.Float2(hhPriceTax / 2);
                this._customize.Items[1].Price = Util.Float2(hhPrice / 2);
                this._customize.Items[1].Tax = Util.Float2(hhPriceTax / 2);
            } else {
                this._customize.Items[0].Price = Util.Float2(this._customize.Items[0].MenuPrice / 2);
                this._customize.Items[0].Tax = Util.Float2(this._customize.Items[0].MenuPriceTax / 2);
                this._customize.Items[1].Price = Util.Float2(this._customize.Items[1].MenuPrice / 2);
                this._customize.Items[1].Tax = Util.Float2(this._customize.Items[1].MenuPriceTax / 2);
            }


            //this._customize.Items[0].HalfHalfPrice = hhPrice;
            //this._customize.Items[0].Price = Util.Float2(hhPrice / 2);
            //this._customize.Items[0].Tax = Util.Float2(hhPriceTax / 2);

            //this._customize.Items[1].HalfHalfPrice = hhPrice;
            //this._customize.Items[1].Price = Util.Float2(hhPrice / 2);
            //this._customize.Items[1].Tax = Util.Float2(hhPriceTax / 2);

            const totalAssignedPrice = this._customize.Items[0].Price + this._customize.Items[1].Price;
            if (totalAssignedPrice != hhPrice) {
                const diff = Util.Float2(hhPrice - totalAssignedPrice);
                this._customize.Items[1].Price = Util.Float2(this._customize.Items[1].Price + diff);
            }
            OnlineOrderingUtil.TaxItem(mItem, this._customize.Items[0], this._localAolo);
            OnlineOrderingUtil.TaxItem(mItem, this._customize.Items[1], this._localAolo);
        }

        this._taxSelectedItem();
        this._displayItemsPrice();
        this._displayItemsCalories(halfIndex - 1);
    }

    private _addSelectedToOrder_Continue = async (): Promise<number> => {
        const isHH = this._customize.Items.length > 1;
        const qty = isHH ? parseInt(Util.getElementValue("ddl_customize_item_whole_quantity")) : parseInt(Util.getElementValue("ddl_customize_item_item1_quantity"));

        let removedCouponIDs: { couponId: number, offerCode: string | null }[] = [];
        if (this._editing) {
            let currItem = this._customize.Items[0];
            if (currItem.SizeId == null)
                Util.LogError(`customize-item -> _addSelectedToOrder_Continue 0`, new Error("SizeId is null"), this._localAolo);
            let couponKeys = this._localAolo.Order.ItemsCoupons.filter(x => x.ItemKey == currItem.ItemKey).map(x => x.CouponKey);
            let redeemItemCouponKey = this._localAolo.Order.Coupons.filter(x => couponKeys.includes(x.CouponKey)).find(x => x.CouponId == 7);
            if (redeemItemCouponKey) {
                let points = OnlineOrderingUtil.GetLoyaltyPoints(currItem.ItemId, currItem.SizeId, this._localAolo.data.LoyaltyItems);
                await OnlineOrderingUtil.RedeemPoints(points, currItem.ItemKey, this._localAolo);
            }
            removedCouponIDs = await this._localAolo.Modules.Coupon.RemoveCouponByItemKey(currItem.ItemKey);
        }
        let iky = OnlineOrderingUtil.GetNextItemKey(this._localAolo.Order.Items);
        let itmH1 = this._customize.Items[0];
        let mItem = this._localAolo.data.Items.find(x => x.ItemId === itmH1.ItemId) || null;
        let currItem = this._localAolo.Order.Items.find(x => x.Index == itmH1.Index);
        if (currItem && removedCouponIDs.length > 0 && itmH1.SizeId != currItem?.SizeId) {
            itmH1.IsRedeemed = false;
            removedCouponIDs = removedCouponIDs.filter(x => x.couponId != 7);
        }

        if (itmH1.SizeId == null) {
            Util.LogError("customize-item -> _addSelectedToOrder_Continue 1", new Error("SizeId is null"), this._localAolo);
            DialogCreators.messageBoxOk("There was an error adding the item. Please select a size.", this._localAolo.buttonHoverStyle);
            return -1;
        }

        const itm: IOrderItem = {
            ItemId: itmH1.ItemId,
            Name: itmH1.Name,
            Index: itmH1.Index,
            SizeId: itmH1.SizeId,
            Quantity: qty,
            Modifiers: itmH1.Modifiers,
            Price: itmH1.Price,
            MinPrice: itmH1.MinPrice,
            HalfHalfPrice: itmH1.HalfHalfPrice,
            MenuPrice: itmH1.MenuPrice,
            MenuPriceTax: itmH1.MenuPriceTax,
            Tax: itmH1.Tax,
            PriceIncludesTax: itmH1.PriceIncludesTax,
            Taxes: itmH1.Taxes,
            TaxAmount: itmH1.TaxAmount,
            BeforeTaxDiscount: 0,
            AfterTaxDiscount: 0,
            DiscountedMarkedQuantity: 0,
            DiscountedQuantity: 0,
            ItemWebCategoryId: mItem?.WebCategoryId || -1,
            ItemCategoryId: mItem?.ItemCategoryId || -1,
            IsRedeemed: itmH1.IsRedeemed,
            ItemKey: this._editing ? itmH1.ItemKey : iky,
            ItemRecId: this._editing ? itmH1.ItemRecId : OnlineOrderingUtil.GetRecID(this._customize.Items),
            HalfIndex: isHH ? 1 : 0,
            HalfCount: isHH ? 2 : 1,
            Edit: true,
            Comment: Util.getElementValue("txt_customize_item_comment"),
            Instructions: [] as { CommentId: number }[],
            LoyaltyDiscountsQTY: itmH1.LoyaltyDiscountsQTY,
            NonLoyaltyDiscountsQTY: itmH1.NonLoyaltyDiscountsQTY,
            LoyaltyDiscountsAmount: itmH1.LoyaltyDiscountsAmount,
            IsBuildYourOwn: itmH1.IsBuildYourOwn
        };

        if (itm.Instructions && document.getElementById("icon_customize_item_instructions_title")?.classList.contains('icon-show-less')) {
            const chkInstruction = Array.from(document.getElementsByName('chk_customize_item_Instructions')) as HTMLInputElement[];
            for (const instruction of chkInstruction) {
                if (instruction.checked)
                    itm.Instructions.push({ CommentId: parseInt(instruction.value) });
            }
        }

        if (!this._editing) {
            this._localAolo.Order.Items.push(itm);
        } else {
            OnlineOrderingUtil.TaxItem(mItem, itm, this._localAolo);
            this._localAolo.Order.Items[this._editingItem1Index] = itm;
        }

        if (isHH) {
            let itmH2 = this._customize.Items[1];
            let mItem2 = this._localAolo.data.Items.find(x => x.ItemId === itmH2.ItemId) || null;

            if (itmH2.SizeId == null) {
                Util.LogError("customize-item -> _addSelectedToOrder_Continue 2", new Error("SizeId is null"), this._localAolo);
                DialogCreators.messageBoxOk("There was an error adding the item. Please select a size.", this._localAolo.buttonHoverStyle);
                return -1;
            }

            const itm2: IOrderItem = {
                ItemId: itmH2.ItemId,
                Name: itmH2.Name,
                Index: itmH2.Index,
                SizeId: itmH2.SizeId,
                Quantity: qty,
                Modifiers: itmH2.Modifiers,
                Price: itmH2.Price,
                MinPrice: itmH2.MinPrice,
                HalfHalfPrice: itmH2.HalfHalfPrice,
                MenuPrice: itmH2.MenuPrice,
                MenuPriceTax: itmH2.MenuPriceTax,
                Tax: itmH2.Tax,
                PriceIncludesTax: itmH2.PriceIncludesTax,
                Taxes: itmH2.Taxes,
                TaxAmount: itmH2.TaxAmount,
                BeforeTaxDiscount: 0,
                AfterTaxDiscount: 0,
                DiscountedMarkedQuantity: 0,
                DiscountedQuantity: 0,
                ItemWebCategoryId: mItem2?.WebCategoryId || -1,
                ItemCategoryId: mItem2?.ItemCategoryId || -1,
                ItemKey: this._editing ? itmH2.ItemKey : iky,
                ItemRecId: this._editing ? itmH2.ItemRecId : OnlineOrderingUtil.GetRecID(this._customize.Items),
                HalfIndex: 2,
                HalfCount: 2,
                Edit: true,
                Comment: Util.getElementValue("txt_customize_item_comment"),
                LoyaltyDiscountsQTY: itmH2.LoyaltyDiscountsQTY,
                NonLoyaltyDiscountsQTY: itmH2.NonLoyaltyDiscountsQTY,
                LoyaltyDiscountsAmount: itmH2.LoyaltyDiscountsAmount,
                IsBuildYourOwn: itmH2.IsBuildYourOwn
            };

            if (!this._editing)
                this._localAolo.Order.Items.push(itm2);
            else if (this._editingItem2Index > -1) {
                OnlineOrderingUtil.TaxItem(mItem2, itm2, this._localAolo);
                this._localAolo.Order.Items[this._editingItem2Index] = itm2;
            } else
                this._localAolo.Order.Items.splice(this._editingItem1Index + 1, 0, itm2);
        } else if (this._editing && this._editingItem2Index > -1)
            this._localAolo.Order.Items.splice(this._editingItem2Index, 1);

        Util.setElement("value", "txt_customize_item_comment", "");

        [this._localAolo.Order.Taxes, this._localAolo.Order.TaxesDetail] = OnlineOrderingUtil.TaxOrder(this._localAolo.Order, this._localAolo);

        OnlineOrderingUtil.CalculateTotals(this._localAolo);

        if (this._editing) {
            for (const coupon of removedCouponIDs) {
                if (coupon.couponId == 7) {
                    let points = OnlineOrderingUtil.GetLoyaltyPoints(itm.ItemId, itm.SizeId, this._localAolo.data.LoyaltyItems);
                    itm.IsRedeemed = false;
                    await OnlineOrderingUtil.RedeemPoints(points, itm.ItemKey, this._localAolo);
                } else {
                    const params: IApplyCouponByIdParams = {
                        couponId: coupon.couponId,
                        showErrorMsg: true
                    };
                    await this._localAolo.Modules.Coupon.ApplyCouponByID(params);
                }
            }
        }

        OnlineOrderingUtil.GUI_SetOrder(this._localAolo, aOLOModules);
        if (this._localAolo.Dialog.CustomizeItem)
            this.CloseDialog();
        return itm.ItemKey;
    }

    private _taxSelectedItem = (): void => {
        for (const oItem of this._customize.Items) {
            const mItem = this._localAolo.data.Items.find(x => x.ItemId === oItem.ItemId) || null;
            OnlineOrderingUtil.TaxItem(mItem, oItem, this._localAolo);
        }
    }

    public SetItemComment = (comment: string): void => {
        Util.setElement("value", "txt_customize_item_comment", comment);
    }
}