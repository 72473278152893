import '../css/custom-datalist.css';

export class CustomDatalist {
    private container: HTMLElement;
    private input: HTMLInputElement;
    private items: { text: string, value: string }[];
    private shouldDisplayList: boolean;

    constructor(inputId: string, containerId: string, items: { text: string, value: string }[]) {
        this.shouldDisplayList = true;
        this.container = document.getElementById(containerId) as HTMLElement;
        this.input = document.getElementById(inputId) as HTMLInputElement;
        this.items = items;
        this.setContainer();
        this.setInputEventListener();
        this.createList();
        this.filterList(this.input.value);
    }

    private setContainer(): void {
        this.container.innerHTML = "";
        this.container.setAttribute('role', 'listbox');

        // Check if the click target is not a child of this.container
        document.addEventListener('click', (event: MouseEvent) => {
            if (!this.container.contains(event.target as Node) && !this.input.contains(event.target as Node)) {
                this.container.style.display = 'none';
            }
        });
    }

    private setInputEventListener(): void {
        this.input.addEventListener('input', () => {
            this.shouldDisplayList = true;
            this.filterList(this.input.value);
        });

        this.input.addEventListener('click', () => {
            if (this.container.children.length > 0) {
                this.container.style.display = 'block';
            }
        });

        // Add keyboard event listener
        this.input.addEventListener('keydown', (event: KeyboardEvent) => {
            const visibleItems = Array.from(this.container.children).filter(child => (child as HTMLElement).style.display !== 'none');

            if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
                event.preventDefault();
                const currentIndex = visibleItems.findIndex(item => item.classList.contains('active'));

                let newIndex = currentIndex;
                if (event.key === 'ArrowDown' && currentIndex < visibleItems.length - 1) {
                    newIndex = currentIndex + 1;
                } else if (event.key === 'ArrowUp' && currentIndex > 0) {
                    newIndex = currentIndex - 1;
                }

                if (currentIndex !== -1) {
                    visibleItems[currentIndex].classList.remove('active');
                }

                visibleItems[newIndex].classList.add('active');
                visibleItems[newIndex].scrollIntoView({ block: 'nearest' });
            } else if (event.key === 'Enter' || event.key === "Tab") {
                const activeItem = this.container.querySelector('.active') as HTMLElement;
                if (activeItem) {
                    this.input.value = activeItem.textContent || '';

                    // Manually dispatch the input event
                    const inputEvent = new Event('input', { bubbles: true });
                    this.input.dispatchEvent(inputEvent);

                    this.shouldDisplayList = false;
                    this.container.style.display = 'none';
                }
            }
        });
    }

    private createList(): void {
        this.container.className = 'custom-dropdown-list';

        this.items.forEach(item => {
            const listItem = document.createElement('li');
            listItem.className = 'custom-dropdown-item';
            listItem.textContent = item.text;
            listItem.dataset.value = item.value;
            listItem.setAttribute('role', 'option');
            this.container.appendChild(listItem);

            listItem.addEventListener('click', () => {
                this.input.value = item.text;

                // Manually dispatch the input event
                const inputEvent = new Event('input', { bubbles: true });
                this.input.dispatchEvent(inputEvent);

                this.shouldDisplayList = false;
                this.container.style.display = 'none';
            });
        });
    }

    private filterList(filter: string): void {
        Array.from(this.container.children).forEach(child => {
            const item = child as HTMLElement;
            if (item.textContent && item.textContent.toLowerCase().includes(filter.toLowerCase())) {
                item.style.display = 'block';
            } else {
                item.style.display = 'none';
            }
        });

        if (filter === '' || !this.shouldDisplayList) {
            this.container.style.display = 'none';
        } else {
            this.container.style.display = 'block';
        }
    }

    public updateDataList(data: Array<{ text: string; value: string }>): void {
        this.items = data;

        while (this.container.firstChild) {
            this.container.removeChild(this.container.firstChild);
        }

        this.createList();
    }
}
