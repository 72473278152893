import { Util } from '../utils/util';
import { Names, NamesJson } from '../utils/i18n';
import { ITracker, ITrackerOrderItem } from './interfaces/tracker.interface';
import { DialogCreators } from '../utils/dialog-creators';

import '../../css/brand/tracker.css';
import { IaOLO } from '../interfaces/aolo.interface';
import { IAddressFormat } from '../interfaces/global.interfaces';

/**
 * A class representing a tracker for an order.
 * @class
 */
export class Tracker {
    /**
     * @private
     * @property {ITracker} _trackerData - The tracker data for the order.
     */
    private _trackerData: ITracker;
    /**
     * @private
     * @property {any} _trackerTimeoutInterval - The interval object for the tracker timeout.
     */
    private _trackerTimeoutInterval: any;
    /**
     * @private
     * @property {number} _orderId - The ID of the order being tracked.
     */
    private _orderId: number;
    private _brandFunction: Function | null;

    /**
     * Creates an instance of the Tracker class.
     * @constructor
     * @param {number} orderId - The ID of the order being tracked.
     */
    constructor(orderId: number, brandFunction: Function | null) {
        this._orderId = orderId;
        this._brandFunction = brandFunction;
        delete aOLO.RedirectToTracker;
        this._trackerData = this._setDefault();
        this._init(aOLO);
        this._setDlgEventListeners();
    }

    private _setDlgEventListeners = (): void => {
        const self = this;
        Util.setElement("onclick", "btn_tracker_close", () => { self.closeDialog(); });
    }

    public closeDialog = (): void => {
        Util.DialogFadeOut("dia_tracker");
    }

    /**
     * Sets default values for the tracker data object.
     * @private
     * @returns {ITracker} - The default tracker data.
     */
    private _setDefault = (): ITracker => {
        return {
            order: {
                address: null,
                deliveryCharge: 0,
                discount: 0,
                discounts: null,
                firstName: "",
                grand: 0,
                id: 0,
                items: null,
                lastName: "",
                number: 0,
                phone: "",
                serviceCharge: 0,
                status: {
                    estimatedDeliveryTime: null,
                    printTime: "",
                    statusesInfo: [],
                    trackerStatusOK: false
                },
                subTotal: 0,
                tax: 0
            },
            storeAddressInfo: {
                name: "",
                streetNumber: "",
                address1: "",
                address2: "",
                address3: "",
                address4: "",
                address5: "",
                city: "",
                state: "",
                zip: "",
                countryId: 1,
                phone: ""
            }
        } as ITracker;
    }

    /**
     * Initializes the tracker and fetches tracker data.
     * @private
     * @async
     * @returns {Promise<void>} - A promise that resolves once the initialization is complete.
     */
    private _init = async (localAolo: IaOLO): Promise<void> => {
        const result = await this._fetchTrackerData();
        if (result == 0) {
            this._renderPage();
            this._setEventListeners();
        } else if (result == -1) {
            DialogCreators.messageBoxOk(Names("Tracker_IncorrectAccount_Error"), localAolo.buttonHoverStyle);
            history.replaceState(null, document.title, location.href);
            // 2DO
            //if (this._brandFunction)
            //    Common.logout(this._brandFunction, localAolo);
        } else if (result == -2) {
            DialogCreators.messageBoxOk(Names("Tracker_FetchTrackerData_Error"), localAolo.buttonHoverStyle);
            history.replaceState(null, document.title, location.href);
            if (this._brandFunction) {
                // 2DO
                //if (localAolo.ProfileUser?.isLoggedIn())
                //    this._brandFunction("myorders", false);
                //else
                //    this._brandFunction("signin", true);
            }
        }
    }

    /**
     * Fetches tracker data from the server.
     * @private
     * @async
     * @returns {Promise<number>} - A promise that resolves with a number indicating the result of the fetch.
     *                              0 indicates success, -1 indicates incorrect account, -2 indicates error.
     */
    private _fetchTrackerData = async (): Promise<number> => {
        let data;
        // 2DO
        //if (aOLO.ProfileUser?.isLoggedIn()) {
        //    data = await aOLO.Modules.ProfileService.fetchTrackerUserData(this._orderId, aOLO);
        //    if (data?.order.items == null)
        //        return -1;
        //} else
        //    data = await aOLO.Modules.ProfileService.fetchTrackerData(this._orderId, aOLO);

        if (data) {
            this._trackerData = data;
            return 0;
        }
        return -2;
    }

    /**
     * Renders the tracker page with tracker data.
     * @private
     * @returns {void}
     */
    private _renderPage = () => {
        Util.setElement("innerHTML", "spn_tracker_order_number", (" #" + this._trackerData.order.number || ""));
        Util.setElement("innerHTML", "spn_tracker_transaction_number", ("&nbsp;" + this._trackerData.order.id || ""));

        const storeAddress = this._trackerData.storeAddressInfo;
        const tempAddress: IAddressFormat = {
            StreetNo: storeAddress.streetNumber,
            Address1: storeAddress.address1,
            Address2: storeAddress.address2,
            Address3: storeAddress.address3,
            Address4: storeAddress.address4,
            Address5: storeAddress.address5,
            City: storeAddress.city,
            State: storeAddress.state,
            ZipCode: storeAddress.zip,
            CountryID: storeAddress.countryId,
            AddressTypeID: 0
        };

        const addressObject = Util.formatAddressObject(tempAddress, aOLO.data.Countries);

        Util.setElement("innerHTML", "div_store_address1", addressObject.address1);
        if (addressObject.address3)
            Util.setElement("innerHTML", "div_store_address2", addressObject.address3);
        Util.setElement("innerHTML", "div_store_city_state", addressObject.cityState);

        const phoneCountryId = aOLO.User.CountryCodeId || aOLO.storeInfo.Country.CountryID || 1;
        const phoneFormatLanguage = Util.getDefaultCultureCode(phoneCountryId, aOLO.data.Countries)
        Util.setElement("innerHTML", "div_store_phone", Util.formatPhoneNumber(this._trackerData.storeAddressInfo.phone, Names("PhoneFormat", phoneFormatLanguage)));

        if (this._trackerData.order.status.estimatedDeliveryTime) {
            Util.showElement("div_tracker_delivery_status");
            let date = new Date(this._trackerData.order.status.estimatedDeliveryTime);
            Util.setElement("innerHTML", "div_tracker_estimated_delivery_time", date.toLocaleTimeString(Util.GetBrowserLanguage(), { hour: '2-digit', minute: '2-digit' }));
        } else
            Util.hideElement("div_tracker_delivery_status");

            // 2DO
        //if (aOLO.ProfileUser?.isLoggedIn()) {
        //    Util.hideElement("btn_tracker_details");
        //    Util.showElement("div_tracker_details");

        //    Util.setElement("innerHTML", "lbl_tracker_customer_name", (this._trackerData.order.firstName + " " + this._trackerData.order.lastName));

        //    if (this._trackerData.order.address) {
        //        Util.showElement("lbl_tracker_customer_address1");
        //        Util.showElement("lbl_tracker_customer_address2");
        //        Util.showElement("lbl_tracker_customer_city_state");

        //        const customerAddress = this._trackerData.order.address;
        //        const tempAddress: IAddressFormat = {
        //            StreetNo: customerAddress.streetNo,
        //            Address1: customerAddress.address1,
        //            Address2: customerAddress.address2,
        //            Address3: customerAddress.address3,
        //            Address4: customerAddress.address4,
        //            Address5: customerAddress.address5,
        //            City: customerAddress.city,
        //            State: customerAddress.state,
        //            ZipCode: customerAddress.zip,
        //            CountryID: customerAddress.countryId,
        //            AddressTypeID: 0
        //        };

        //        const addressObject = Util.formatAddressObject(tempAddress, aOLO.data.Countries);

        //        Util.setElement("innerHTML", "lbl_tracker_customer_address1", addressObject.address1);
        //        if (addressObject.address3)
        //            Util.setElement("innerHTML", "lbl_tracker_customer_address2", addressObject.address3);
        //        Util.setElement("innerHTML", "lbl_tracker_customer_city_state", addressObject.cityState);
        //    } else {
        //        Util.hideElement("lbl_tracker_customer_address1");
        //        Util.hideElement("lbl_tracker_customer_address2");
        //        Util.hideElement("lbl_tracker_customer_city_state");
        //    }

        //    Util.setElement("innerHTML", "lbl_tracker_customer_phone", Util.formatPhoneNumber(this._trackerData.order.phone, Names("PhoneFormat", phoneFormatLanguage)));

        //    Util.setElement("innerHTML", "div_tracker_subtotal", Util.formatMoney(this._trackerData.order.subTotal));

        //    if (this._trackerData.order.discount == 0)
        //        Util.hideElement("div_tracker_discount_total_parent");
        //    else {
        //        Util.setElement("innerHTML", "div_tracker_discount_total", Util.formatMoney(this._trackerData.order.discount));
        //        Util.showElement("div_tracker_discount_total_parent");
        //    }

        //    if (this._trackerData.order.deliveryCharge == 0) {
        //        Util.hideElement("div_tracker_delivery_charge_parent");
        //    } else {
        //        Util.setElement("innerHTML", "div_tracker_delivery_charge", Util.formatMoney(this._trackerData.order.deliveryCharge));
        //        Util.showElement("div_tracker_delivery_charge_parent");
        //    }

        //    Util.setElement("innerHTML", "div_tracker_tax", Util.formatMoney(this._trackerData.order.tax));
        //    Util.setElement("innerHTML", "div_tracker_total", Util.formatMoney(this._trackerData.order.grand));

        //    this._renderItems();
        //    this._renderDiscounts();
        //} else {
        //    Util.showElement("btn_tracker_details");
        //    Util.hideElement("div_tracker_details");
        //}

        this._renderProgressBar();

        if (this._trackerData.order.status.trackerStatusOK)
            Util.hideElement("lbl_status_error_message");
        else {
            Util.setElement("innerHTML", "lbl_status_error_message", Names("Tracker_Status_Error") + Util.formatDateTime(new Date(this._trackerData.order.status.printTime)));
            Util.showElement("lbl_status_error_message");
        }
    }

    /**
     * Renders the progress bar based on the current status of the order.
     * @returns {void}
     */
    private _renderProgressBar = (): void => {
        let progressUl = document.getElementById("ul_tracker_progressbar");
        if (!progressUl)
            return;

        Util.removeChildren(progressUl);

        let activeStatusIndex = this._trackerData.order.status.statusesInfo.filter(x => x.isCompleted).length - 1;

        for (const [index, status] of this._trackerData.order.status.statusesInfo.entries()) {
            let classStr = "tracker-uncompleted";
            if (status.isCompleted)
                classStr = (index == activeStatusIndex) ? "tracker-active" : "tracker-completed";

            const html = `
                    <li id="tr_status_${index}" class="${classStr}">
                        <div><img class="bubble" src="${status.imageURL}" alt="Notification Image for ${NamesJson(status.statusName)}"></div>
                        <div ltagj='${status.statusName}'>${NamesJson(status.statusName)}</div>
                    </li>`;

            progressUl.innerHTML += html;
        }
    }

    /**
     * Renders the items table based on the items in the order.
     * @returns {void}
     */
    private _renderItems = (): void => {

        function getItemSuffix(item: ITrackerOrderItem) {
            if ((item.halfIndex == 1) && (item.halfCount !== 1)) {
                let suff = "";
                for (let i = 1; i <= item.halfCount; i++) {
                    suff += " & 1/" + item.halfCount;
                }
                return suff.substring(2);
            }
            return "";
        }

        function getHalfStr(item: ITrackerOrderItem) {
            return "1/" + item.halfCount + " ";
        }

        function getItemName(item: ITrackerOrderItem) {
            let itemName = "";
            let mods = "";
            if (item.halfIndex == 0) {
                itemName = item.size + " " + item.name + getItemSuffix(item)
            }
            else if (item.halfIndex == 1) {
                itemName = item.size + " " + item.itemCategoryName + getItemSuffix(item) + "<br>&nbsp;&nbsp;" + getHalfStr(item) + item.name;
            } else if (item.halfIndex > 1) {
                itemName = "&nbsp;&nbsp;" + getHalfStr(item) + item.name;
            }

            if (item.mods)
                for (let j = 0; j < item.mods.length; j++) {
                    mods += (!item.mods[j].isDefault)
                        ? ("<br>&nbsp;&nbsp;" + item.mods[j].modName) : "";
                }
            return itemName + mods;
        }

        function getItemPrice(item: ITrackerOrderItem) {
            if (item.halfIndex == 0 && item.price && item.quantity)
                return Util.formatMoney(item.price * item.quantity)
            if (item.halfIndex == 1 && item.halfHalfPrice && item.quantity)
                return Util.formatMoney(item.halfHalfPrice * item.quantity);
            return "";
        }

        let div = document.getElementById("div_tracker_items");
        Util.removeChildren("div_tracker_items")

        if (!this._trackerData.order.items || !div)
            return;

        let items = this._trackerData.order.items.filter(x => (!x.isRemoved));

        for (const [index, item] of items.entries()) {
            const html = `
                <div id="div_template_row_item_${index}" class="tracker-order-detail-line">
                    <div ltagj="">${getItemName(item)}</div>
                    <div id="div_tracker_discount_total">${getItemPrice(item)}</div>
                </div>`;

            div.innerHTML += html;
        }
    }

    /**
     * Renders the discounts table based on the discounts in the order.
     * @returns {void}
     */
    private _renderDiscounts = (): void => {
        let div = document.getElementById("div_tracker_discounts");
        Util.removeChildren("div_tracker_discounts");

        if (!this._trackerData.order.discounts || !div)
            return;

        for (const [index, discount] of this._trackerData.order.discounts.entries()) {
            const html = `
                <div id="div_template_row_discount_${index}" class="tracker-order-detail-line">
                    <div><span ltag="Discount">${Names("Discount")}</span><br>&nbsp;&nbsp;${discount.name}</div>
                    <div id="div_tracker_discount_total">${Util.formatMoney(discount.amount || 0)}</div>
                </div>`;

            div.innerHTML += html;
        }
    }

    /**
     * Sets event listeners for the tracker page, such as the click event for the details button and
     * the auto-refresh interval.
     * @private
     * @returns {void}
     */
    private _setEventListeners = (): void => {
        let self = this;
        Util.setElement("onclick", "btn_tracker_details", () => { self._displayOrderDetails(); })

        this._setAutoRefreshInterval();
    }

    /**
     * Displays the order details for the tracker page. If the user is not logged in, redirects
     * them to the sign in page. If the user is a brand sign in, sets the aOLO.RedirectToTracker and
     * aOLO.TrackerId values to the current order id, then displays the sign in page. If the user is
     * not a brand sign in, shows the sign in dialog.
     * @private
     * @async
     * @returns {Promise<void>}
     */
    private _displayOrderDetails = async (): Promise<void> => {
        // 2DO
        //if (!aOLO.ProfileUser?.isLoggedIn()) {
        //    this.removeEventListeners();
        //    if (aOLO.isBrandSignIn) {
        //        aOLO.RedirectToTracker = true;
        //        aOLO.TrackerId = this._orderId;
        //        if (aOLO.TrackerId == null)
        //            Util.LogError(`_displayOrderDetails --> this._orderId: ${this._orderId}`, new Error(), aOLO);

        //        if (this._brandFunction)
        //            await this._brandFunction("signin", false);
        //    } else {
        //        //trSignInDlgShow();
        //    }
        //}
    }

    /**
     * Updates the tracker data by fetching the latest data and re-rendering the page.
     * @async
     * @returns {Promise<void>}
     */
    public updateTracker = async (): Promise<void> => {
        await this._fetchTrackerData();
        this._renderPage();
    }

    /**
     * Removes event listeners from the tracker page, specifically the auto-refresh interval.
     * @returns {void}
     */
    public removeEventListeners = (): void => {
        clearInterval(this._trackerTimeoutInterval);
    }

    /**
     * Sets the auto-refresh interval for the tracker page.
     * @private
     * @returns {void}
     */
    private _setAutoRefreshInterval = (): void => {
        this._trackerTimeoutInterval = setInterval(this.updateTracker.bind(this), 60000);
    }
}