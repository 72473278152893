import { FieldType } from '../types/enums';
import { Common } from '../common';
import { Util } from '../utils/util';
import { Names } from '../utils/i18n';
import { IAddress, IAddressResolver } from './interfaces/address.interfaces';
import { IProfileAddress } from './interfaces/profile.interfaces';
import { DialogCreators } from '../utils/dialog-creators';

import '../../css/shared/address.css';
import { IaOLO } from '../interfaces/aolo.interface';

/**
 * A class representing an address.
 * @class
 */
export class Address {
    /**
     * @private
     * @property {IAddress} _addr - An object containing address properties.
     */
    _addr: IAddress;

    /**
     * Creates an instance of Address.
     * @constructor
     * @param {*} address - An object representing an address.
     */
    constructor(address: IProfileAddress) {
        let addr = JSON.parse(JSON.stringify(address));
        this._addr = {
            AID: addr.AID || 0,
            ADDR1: addr.ADDR1 || "",
            ADDR2: addr.ADDR2 || "",
            ADDR3: addr.ADDR2 || "",
            ADDR4: addr.ADDR2 || "",
            ADDR5: addr.ADDR2 || "",
            CITY: addr.CITY || "",
            IPRM: addr.IPRM || false,
            LAT: addr.LAT || 0,
            LON: addr.LON || 0,
            STA: addr.STA || "",
            STRNO: addr.STRNO || "",
            ZIP: addr.ZIP || "",
            COUNTRY: addr.COUNTRY || ""
        };
        this._initAddress(aOLO);
    }

    /**
     * Initializes the address.
     * @private
     * @returns {void}
     */
    _initAddress = (localAolo: IaOLO): void => {
        this._hideErrorElements();
        this._setValues();
        this._renderAddress();
        Util.DialogFadeIn("dia_profile_address", localAolo);
    }

    /**
     * Hides error elements.
     * @private
     * @returns {void}
     */
    _hideErrorElements = (): void => {
        Util.hideElement("spn_profile_address_error");
    }

    /**
     * Renders the address.
     * @private
     * @returns {void}
     */
    _renderAddress = (): void => {
        let datalist = document.getElementById("dl_profile_address");
        if (datalist)
            datalist.innerHTML = "";

        let self = this;
        Util.setElement("oninput", "txt_profile_address", [Util.UpdateAddressList.bind(null, "txt_profile_address", "txt_profile_address_unit", "dl_profile_address", aOLO.data.ZipCodes, aOLO, self._setNewAddress)]);
        Util.setElement("onchange", "txt_profile_address_unit", self._apartmentOnChange);
        Util.setElement("onclick", "btn_profile_address_save", self._addressSave);
        Util.setElement("onclick", "chk_profile_address", self._defaultOnClick);
        Util.setElement("onclick", "btn_profile_address_close", self._closeDialog);
    }

    /**
     * Sets the values of the address fields on the address page.
     * @private
     * @returns {void}
     */
    _setValues = (): void => {
        Util.setElement("value", "txt_profile_address", `${this._addr.STRNO} ${this._addr.ADDR1}`);
        Util.setElement("value", "txt_profile_address_unit", this._addr.ADDR2);
        Util.setElement("value", "txt_profile_address_city", this._addr.CITY);
        Util.setElement("value", "txt_profile_address_state", this._addr.STA);
        Util.setElement("value", "txt_profile_address_zip", this._addr.ZIP);
        Util.setElement("checked", "chk_profile_address", this._addr.IPRM);
    }

    /**
     * Updates the value of the _addr.ADDR2 property when the apartment input field value is changed.
     * @private
     * @returns {void}
     */
    _apartmentOnChange = (): void => {
        let unitInput = document.getElementById("txt_profile_address_unit") as HTMLInputElement;
        if (unitInput)
            this._addr.ADDR2 = unitInput.value;
    }

    /**
     * Updates the value of the _addr.IPRM property when the checkbox is clicked.
     * @private
     * @returns {void}
     */
    _defaultOnClick = (): void => {
        let check = document.getElementById("chk_profile_address") as HTMLInputElement;
        if (check) 
            this._addr.IPRM = check.checked;
    }

    /**
     * Closes the address dialog box.
     * @private
     * @returns {void}
     */
    _closeDialog = (): void => {
        Util.DialogFadeOut("dia_profile_address");
    }

    /**
     * A method that checks whether the submitted data is valid.
     * @private
     * @async
     * @returns {Promise<boolean>} - A boolean indicating whether the submitted data is valid.
     */
    _checkSubmittedData = async (): Promise<boolean> => {

        /**
         * Adds a validation error message to the error flag.
         * @param message - The result of a validation check.
         * @returns {void}
         */
        function addValidationErrorMsg(message: string): void {
            if (message !== "") {
                error = true;
                ul.appendChild(Common.GetLi(message));
            }
        }

        let error = false;
        let ul = document.createElement("ul");
        ul.classList.add("warning");

        let value = Util.getElementValue("txt_profile_address");
        addValidationErrorMsg(await Common.ValidateInput(true,
            value,
            FieldType.CUSTOM,
            "spn_profile_address_error",
            true,
            aOLO
        ));

        if (error) {
            DialogCreators.messageBoxOk(ul, aOLO.buttonHoverStyle);
            return false;
        }
        return true;
    }

    /**
     * A method that saves the address and updates the UI.
     * @private
     * @async
     * @returns {Promise<void>} - A Promise that resolves when the address is saved.
     */
    _addressSave = async (): Promise<void> => {
        if (await this._checkSubmittedData()) {
            let profile = aOLO.Dialog.Profile;
            if (profile) {
                let addr = profile.GetAddresses().find(x => x.AID === this._addr.AID);
                if (addr !== undefined) {
                    addr.ADDR1 = this._addr.ADDR1;
                    addr.ADDR2 = this._addr.ADDR2;
                    addr.STRNO = this._addr.STRNO;
                    addr.CITY = this._addr.CITY;
                    addr.STA = this._addr.STA;
                    addr.ZIP = this._addr.ZIP;
                    addr.LAT = this._addr.LAT;
                    addr.LON = this._addr.LON;
                    if (this._addr.IPRM)
                        profile.addressSetDefault(this._addr.AID.toString());
                    profile.dirty = true;
                    profile.renderAddressList();
                }
            }

            this._closeDialog();
        }
    }

    /**
     * Sets the values of the address fields based on the data provided.
     * @private
     * @async
     * @param {Object} data - The data containing information about the new address.
     * @returns {Promise<void>}
     */
    _setNewAddress = async (data: any): Promise<void> => {
        let value;
        let options = document.getElementById("dl_profile_address") as HTMLElement;
        if (options) {
            let children = options.children;
            for (let i = 0; i < children.length; i++) {
                let child = children[i] as HTMLOptionElement;
                if (child.value === data && child.dataset.value) {
                    value = JSON.parse(child.dataset.value);
                    break;
                }
            }
        }

        if (value) {
            let addr1 = Util.toStartCase(value.StName + " " + value.StSuffixAbbr);
            if (value.Latitude == 0 || value.Longitude == 0) {
                let addr = new AddressResolver(addr1, "", value.StNo, value.City, value.State, value.ZipCode);
                let coords = await addr.resolveGeoCoordThis();
                if (coords == null || coords.lat == 0 || coords.lng == 0) {
                    DialogCreators.messageBoxOk(`${Names("CannotResolveAddress")} ${addr.toString()}`, aOLO.buttonHoverStyle);
                    return;
                } else if (coords) {
                    this._addr.LAT = coords.lat;
                    this._addr.LON = coords.lng;
                }
            } else {
                this._addr.LAT = value.Latitude;
                this._addr.LON = value.Longitude;
            }

            this._addr.ADDR1 = addr1;
            this._addr.ADDR2 = "";
            this._addr.STRNO = value.StNo;
            this._addr.CITY = value.City;
            this._addr.STA = value.State;
            this._addr.ZIP = value.ZipCode;
            this._setValues();
        }
    }
}

/**
 * A class representing an address resolver that can resolve a string address into its geocoordinates using Google Maps Geocoding API.
 * @class
 */
class AddressResolver {
    /**
     * @private
     * @property {IAddressResolver} _addr - An object containing address properties.
     */
    _addr: IAddressResolver;

    /**
     * Creates an instance of AddressResolver.
     *
     * @constructor
     * @param {string} addr1 - The first line of the address.
     * @param {string} addr2 - The second line of the address.
     * @param {string} strno - The street number of the address.
     * @param {string} city - The city of the address.
     * @param {string} state - The state of the address.
     * @param {string} zip - The zip code of the address.
     */
    constructor(addr1: string, addr2: string, strno: string, city: string, state: string, zip: string) {
        this._addr = {
            addr1: addr1 || "",
            addr2: addr2 || "",
            strno: strno || "",
            city: city || "",
            state: state || "",
            zip: zip || "",
            lat: 0,
            lng: 0
        }
    }

    /**
     * Returns a string representation of the address.
     * @returns {string} - The string representation of the address.
     */
    toString = () => {
        let res = "";
        res += (this._addr.strno !== "") ? this._addr.strno : "";
        res += (this._addr.addr1 !== "") ? " " + this._addr.addr1 : "";
        res += (this._addr.addr2 !== "") ? " " + this._addr.addr2 : "";
        res += (this._addr.city !== "") ? " " + this._addr.city : "";
        res += (this._addr.state !== "") ? " " + this._addr.state : "";
        res += (this._addr.zip !== "") ? " " + this._addr.zip : "";
        return res.trim();
    }

    /**
     * Checks if the geocoordinates are set for the address.
     * @private
     * @async
     * @returns {boolean} - True if the geocoordinates are set for the address, false otherwise.
     */
    _coordSet = () => ((this._addr.lat) ?? 0 !== 0) && ((this._addr.lng) ?? 0 !== 0);

    /**
     * Resolves the geocoordinates of the address using Google Maps Geocoding API.
     * @returns {Promise<{ lng: number, lat: number } | null>} - A promise that resolves with an object containing the longitude and latitude of the address, or null if the coordinates could not be resolved.
     */
    resolveGeoCoordThis = async (): Promise<{ lng: number, lat: number } | null> => {
        if (this._coordSet())
            return null;
        let geo = await this._resolveGeoCoord(this.toString());
        this._addr.lat = geo.lat;
        this._addr.lng = geo.lng;
        return geo;
    }

    /**
     * Resolves the geographic coordinates for the given address string.
     * @private
     * @async
     * @param {string} addressStr - The address string to be resolved.
     * @returns {Promise<{lng: number, lat: number}>} - A promise that resolves to an object with the longitude and latitude of the address.
    */
    _resolveGeoCoord = async (addressStr: string): Promise<{ lng: number, lat: number }> => {
        let res = { lng: 0, lat: 0 };
        //@ts-ignore
        let gmaps = new google.maps.Geocoder();
        try {
            let gaddr = await gmaps.geocode({ address: addressStr });
            if (gaddr.results.length == 1) {
                res.lng = gaddr.results[0].geometry.location.lng();
                res.lat = gaddr.results[0].geometry.location.lat();
            }
            else
                console.log(`${Names("GoogleMapsErrorAmbiguous")} ${addressStr}`);
        }
        catch {
            console.log(`${Names("GoogleMapsErrorResolvingAddress")} ${addressStr}`);
        }
        return res;
    }
}