import { IElementDataset, IDataChallenge, IDataChallengeMember, IDataChallengeSubMember, IDataChallengeEvent } from "../interfaces/data.interface";
import { Util } from "../utils/util";
import { Common } from "../common";
import { IOrderChallenge, IOrderChallengeMember, IOrderChallengeEvent } from "../interfaces/order.interface";

import '../../css/online-ordering/challenge.css';
import { DialogCreators } from "../utils/dialog-creators";
import { IaOLO } from "../interfaces/aolo.interface";

export class Challenge {
    private _challenge: IDataChallenge;
    private _item: { memberId: number, events: any[] }[] = [];
    private _orderChallanges: IOrderChallenge[] | any;

    constructor(challenge: IDataChallenge, orderChallenges: IOrderChallenge[] | undefined) {
        this._challenge = challenge;
        this._orderChallanges = aOLO.Order.Challenges;// orderChallenges;
        this._init(aOLO);
    }

    private _init = (localAolo: IaOLO): void => {
        Util.setElement('value', 'txt_challenge_search', '');
        this._renderPage();
        this._setEventListeners(localAolo);
        this._openDialog(localAolo);
    }

    private _renderPage = (): void => {
        Util.setElement("innerText", "spn_challenge_header_text", Common.GetNewName(this._challenge.Names, aOLO.Temp.languageCode));

        const membersDiv = document.getElementById("div_challenge_members");
        if (!membersDiv)
            return;

        membersDiv.innerHTML = "";
        for (const member of this._challenge.Members) {
            membersDiv.appendChild(this._createMemberCheckbox(member));
        }
    }

    private _setEventListeners = (localAolo: IaOLO): void => {
        Util.setElement("onclick", "btn_challenge_close", () => { this._closeDialog(); });
        Util.setElement("oninput", "txt_challenge_search", () => { this._searchFunctionality(); });
        Util.setElement("onclick", "btn_challenge_apply", () => { this._applyChallenge(localAolo); });
    }

    private _challenge_open_field_remove(e: Event) {
        let ele = <HTMLInputElement>e?.target;
        if (ele == null)
            return;

        let openfield = ele.dataset.openfield;

        if (openfield)
            document.getElementById(openfield)?.remove();
    }

    private _challenge_open_field_add(e: Event) {
        let ele = <HTMLInputElement>e?.target;
        if (ele == null)
            return;

        ele.classList.add("hidden");
        let openfield = ele.dataset.openfield;
        if (!openfield)
            return;

        let val = JSON.parse(openfield);

        let buttonsName = `btn_challenge_open_field_m${val.MemberId}_e${val.EventId}_${(val.IsExtrsField && val.SubMemberId) ? `s${val.SubMemberId}` : `subMember`}`;
        let removeButtonsName = buttonsName + `_remove`;
        let addButtonsName = buttonsName + `_add`;

        let removeButtons = document.getElementsByName(removeButtonsName);
        removeButtons.forEach(x => { x.classList.remove('hidden'); });
        let addButtons = document.getElementsByName(addButtonsName);
        addButtons.forEach(x => { x.classList.add('hidden'); });
        let randomId = 'divOpenFiled_' + Math.floor(Math.random() * 1000000);
        let container = document.getElementById(
            `div_challenge_open_field_m${val.MemberId}_e${val.EventId}_${(val.IsExtrsField && val.SubMemberId) ? `s${val.SubMemberId}` : `subMember`}`
        );

        if (container) {
            container.appendChild(
                this._getEventOpenField(val.MemberId, val.EventId, val.IsExtrsField, val.SubMemberId, val.Title, val.Index + 1, randomId, '', true, false)
            );
        }
    }

    private _createMemberCheckbox = (member: IDataChallengeMember): HTMLElement => {
        let oChalleng: IOrderChallenge | any = this._orderChallanges?.find((x: IOrderChallenge) => x.challengeId == this._challenge.ChallengeId);
        let oChallengMember: IOrderChallengeMember | any = oChalleng?.members?.find((x: IOrderChallengeMember) => x.memberId == member.MemberId);
        const memberChecked: boolean = oChallengMember ? true : false;

        const storeDate = Util.NowStore(aOLO.Temp.TimeOffset);
        const activeEvent = member.Events.filter(evt => Util.compareDates(new Date(evt.EventDate), storeDate) === 0);
        let eventBlock: HTMLElement = this._createDiv(null, null, 'hide-show-onchecked m2-t');
        if (activeEvent.length > 0) {

            for (const event of activeEvent) {
                let oEvent: IOrderChallengeEvent = oChallengMember?.events.find((x: IOrderChallengeEvent) => x.eventId == event.EventId);
                const eventChecked = memberChecked ? (oEvent ? true : false) : true;
                let ds: IElementDataset[] = [{ name: 'eid', value: event.EventId.toString() }, { name: 'mid', value: member.MemberId.toString() }];
                let eventDiv: HTMLElement = this._createDiv(null, null, 'checkRadio m2-l', null, [
                    this._createCheckRadio('checkbox', `chk_challenge_event_${event.EventId}`, 'chk_challenge_event', 'hide-show-siblings', eventChecked, ds),
                    this._createLabel(`chk_challenge_event_${event.EventId}`, event.EventName, null, null, 'challenge-titles')
                ]);
                eventBlock.appendChild(eventDiv);


                if (event.EntryTypeId == 2) { // open filed
                    eventDiv.appendChild(
                        this._createDiv(`div_challenge_open_field_m${member.MemberId}_e${event.EventId}_subMember`, null, 'hide-show-onchecked', null, [
                            this._getCurrentOpenFields(member.MemberId, event.EventId, false, null, event.SubMemberTitle, (oEvent && oEvent.subMember) ? oEvent.subMember : null)
                        ]));

                } else if (event.EntryTypeId == 1 && event.SubMembers && event.SubMembers.length > 0) {
                    let membersDiv: HTMLElement = this._createDiv(null, null, 'hide-show-onchecked m2-t', null, [
                        this._createLabel(null, event.SubMemberTitle, null, null, 'title2 challenge-titles')
                    ]);
                    event.SubMembers.forEach((x) => {
                        let oEvent: IOrderChallengeEvent = oChallengMember?.events.find((y: IOrderChallengeEvent) => y.eventId == event.EventId && y.subMemberId == x.SubMemberId);
                        let name = `chkRdo_challenge_event_m${member.MemberId}_e${event.EventId}`;
                        let id = name + `_s${x.SubMemberId}`;
                        let type = event.IsMultipleSelectionsAllowed ? 'checkbox' : 'radio';
                        let subMemberDiv: HTMLElement = this._createDiv(null, null, 'checkRadio m1-l', null, [
                            this._createCheckRadio(type, id, name, 'hide-show-siblings', oEvent ? true : false, null),
                            this._createLabel(id, x.SubMemberName, null, null, 'challenge-titles')
                        ]);
                        if (x.IsExtraField) {
                            if (x.PredefinedValues == '') {
                                let randomId = 'divOpenFiled_' + Math.floor(Math.random() * 1000000);
                                let id: string = `div_challenge_open_field_m${member.MemberId}_e${event.EventId}_s${x.SubMemberId}`;
                                subMemberDiv.appendChild(
                                    this._createDiv(id, null, 'hide-show-onchecked', null, [
                                        this._createDiv(null, null, 'm2-l', null, [this._createLabel(null, x.ExtraFieldTitle, null, null, 'title2 challenge-titles')]),
                                        this._getCurrentOpenFields(member.MemberId, event.EventId, true, x.SubMemberId, x.ExtraFieldTitle, (oEvent && oEvent.extra) ? oEvent.extra : null)
                                    ]));
                            } else {
                                subMemberDiv.appendChild(this._createDiv(null, null, 'm2-l hide-show-onchecked', null, [
                                    this._createLabel(null, x.ExtraFieldTitle, null, null, 'title2 challenge-titles'),
                                    this._getEventExtraField(member.MemberId, event.EventId, x, (oEvent && oEvent.extra) ? oEvent.extra : '')
                                ]));
                            }
                        }
                        membersDiv.appendChild(subMemberDiv);
                    });
                    eventDiv.appendChild(membersDiv);
                }
            }
        }

        return this._createDiv(null, 'div_challenge_member', 'member-check', [{ name: 'text', value: member.Name }],
            [this._createDiv(null, null, 'checkRadio', null,
                [this._createCheckRadio('checkbox', `chk_challenge_member_${member.MemberId}`, 'chk_challenge_member', 'hide-show-siblings', memberChecked, [{ name: 'mid', value: member.MemberId.toString() }]),
                this._createLabel(`chk_challenge_member_${member.MemberId}`, member.Name, null, null, 'challenge-titles'),
                    eventBlock
                ]
            )]);
    }

    private _getCurrentOpenFields(memberId: number, eventId: number, isExtraField: boolean, subMemberId: number | null, title: string, currentFiledValues: string | null): HTMLElement {
        let currentOpenFiledValues: string[] = [];
        if (currentFiledValues && currentFiledValues != '')
            currentOpenFiledValues = currentFiledValues.split(',');

        let addButton: boolean = true;
        let removeButton: boolean = false;

        let preValue: string = '';
        if (currentOpenFiledValues.length > 0)
            preValue = currentOpenFiledValues[0].trim();

        if (currentOpenFiledValues.length > 1) {
            addButton = false;
            removeButton = true;
        }

        let randomId = `divOpenFiled_${Math.floor(Math.random() * 1000000)}`;
        let openFiledDivs: HTMLElement = this._createDiv(null, null, null, null, [
            this._getEventOpenField(memberId, eventId, isExtraField, subMemberId, title, 0, randomId, preValue, addButton, removeButton)
        ]);

        if (currentOpenFiledValues.length > 1) {
            for (let i = 1; i < currentOpenFiledValues.length; i++) {
                if (i == currentOpenFiledValues.length - 1) {
                    addButton = true;
                    removeButton = false;
                }
                let preValue: string = currentOpenFiledValues[i].trim();
                let randomId = 'divOpenFiled_' + Math.floor(Math.random() * 1000000);
                openFiledDivs.appendChild(this._getEventOpenField(memberId, eventId, isExtraField, subMemberId, title, i, randomId, preValue, addButton, removeButton));
            }
        }
        return openFiledDivs;
    }

    private _createDiv(id: string | null = null, name: string | null = null, cssClass: string | null = null, datasets: IElementDataset[] | null = null, children: HTMLElement[] | null = null): HTMLElement {
        let div: HTMLElement = document.createElement('div');
        if (id && id != '') div.id = id;
        if (cssClass && cssClass != '') this._splitCssClasses(div, cssClass);
        if (name && name != '') div.setAttribute("name", name);
        if (datasets && datasets.length > 0)
            datasets.forEach(x => {
                div.dataset[x.name] = x.value;
            });
        if (children != null && children.length > 0)
            children.forEach(x => {
                div.appendChild(x);
            });

        return div;
    }

    private _createLabel(lableFor: string | null, text: string | null, id: string | null = null, name: string | null = null, cssClass: string | null = null): HTMLElement {
        let lbl: HTMLElement = document.createElement('label');
        if (id && id != '') lbl.id = id;
        if (cssClass && cssClass != '') this._splitCssClasses(lbl, cssClass);
        if (name && name != '') lbl.setAttribute('name', name);
        if (text && text != '') lbl.innerText = text;
        if (lableFor && lableFor != '') lbl.setAttribute('for', lableFor);
        return lbl;
    }

    private _createCheckRadio(type: string, id: string | null, name: string | null, cssClass: string | null, value: boolean | null, datasets: IElementDataset[] | null): HTMLInputElement {
        let inp: HTMLInputElement = document.createElement('input');
        inp.type = type;
        if (id && id != '') inp.id = id;
        if (cssClass && cssClass != '') this._splitCssClasses(inp, cssClass);
        if (name && name != '') inp.setAttribute("name", name);
        if (value) inp.checked = value;
        if (datasets && datasets.length > 0)
            datasets.forEach(x => {
                inp.dataset[x.name] = x.value;
            });
        return inp;
    }

    private _createInput(type: string, id: string | null, name: string | null, cssClass: string | null, value: string | null, maxLength: number | null, datasets: IElementDataset[] | null): HTMLInputElement {
        let inp: HTMLInputElement = document.createElement('input');
        inp.type = type;
        if (id && id != '') inp.id = id;
        if (cssClass && cssClass != '') this._splitCssClasses(inp, cssClass);
        if (name && name != '') inp.setAttribute("name", name);
        if (value && value != '') inp.value = value;
        if (maxLength && maxLength > 0) inp.maxLength = maxLength;
        if (datasets && datasets.length > 0)
            datasets.forEach(x => {
                inp.dataset[x.name] = x.value;
            });
        return inp;
    }

    private _createButton(id: string | null, name: string | null, cssClass: string | null, datasets: IElementDataset[] | null, clickHandler: Function | null = null): HTMLButtonElement {
        let btn: HTMLButtonElement = document.createElement('button');
        if (id && id != '') btn.id = id;
        if (cssClass && cssClass != '') this._splitCssClasses(btn, cssClass);
        if (name && name != '') btn.setAttribute("name", name);
        if (datasets && datasets.length > 0)
            datasets.forEach(x => {
                btn.dataset[x.name] = x.value;
            });
        if (clickHandler) btn.onclick = (event) => { clickHandler(event); };
        return btn;
    }

    private _splitCssClasses(element: HTMLElement, cssClass: string) {
        let classes: string[] = cssClass.split(' ');
        classes.forEach((x) => {
            if (x != '')
                element.classList.add(x);
        });
    }

    private _getEventExtraField(memberId: number, eventId: number, subMember: IDataChallengeSubMember, oPreValues: string): HTMLElement {
        let preVales: string[] = [];
        if (subMember.PredefinedValues.includes(']'))
            preVales = subMember.PredefinedValues.split(']');
        else if (subMember.PredefinedValues.includes('\n'))
            preVales = subMember.PredefinedValues.split('\n');
        let childElements: HTMLElement[] = [];
        let conter = 1;
        preVales.forEach(x => {
            let checked: boolean = oPreValues.includes(x);
            let name = `chkRdo_challenge_event_m${memberId}_e${eventId}_s${subMember.SubMemberId}`;
            let id = name + `_x${conter}`;
            let type = subMember.IsMultipleSelectionsAllowed ? 'checkbox' : 'radio';
            let ds: IElementDataset[] = [{ name: 'value', value: x }];
            childElements.push(
                this._createDiv(null, null, 'checkRadio m2-l', null, [
                    this._createCheckRadio(type, id, name, 'hide-show-siblings', checked, ds),
                    this._createLabel(id, x, null, null, 'challenge-titles')
                ]));
            conter++;
        });

        return this._createDiv(null, null, null, null, childElements);
    }

    private _getEventOpenField(memberId: number, eventId: number, isExtrsField: boolean, subMemberId: number | null,
        title: string, index: number, randomId: string, preValue: string,
        addButton: boolean, removeButton: boolean): HTMLElement {

        let btnData = {
            MemberId: memberId,
            EventId: eventId,
            IsExtrsField: isExtrsField,
            SubMemberId: subMemberId,
            Index: index,
            Title: title
        };
        let childElements: HTMLElement[] = [];
        if (title != '')
            childElements.push(
                this._createDiv(null, null, 'm2-tl gridColsAuto1x_resp', null,
                    [this._createLabel(null, title, null, null, 'nowarp title3 challenge-titles')]
                ));

        let name = `txt_challenge_open_field_m${memberId}_e${eventId}_${(isExtrsField && subMemberId) ? `s${subMemberId}` : `subMember`}`;
        let id = name + `_idx${index}`;
        let btnName = name.replace("txt_", "btn_");
        var self = this;

        let btnAdd: HTMLButtonElement = this._createButton(null, `${btnName}_add`, `add-remove-extra icon-plus ${addButton ? '' : 'hidden'}`, [{ name: 'openfield', value: JSON.stringify(btnData) }]);
        btnAdd.onclick = (event) => { self._challenge_open_field_add(event) };

        let btnRemove: HTMLButtonElement = this._createButton(null, `${btnName}_remove`, `add-remove-extra icon-remove ${removeButton ? '' : 'hidden'}`, [{ name: 'openfield', value: randomId }]);
        btnRemove.onclick = (event) => { self._challenge_open_field_remove(event) };

        childElements.push(
            this._createDiv(null, null, 'gridCol2 add-remove-extra-container', null, [
                this._createInput('text', id, name, null, preValue, 60, null), btnAdd, btnRemove
            ])
        );
        return this._createDiv(randomId, null, 'm2-tl gridColsAuto1x_resp', null, childElements);
    }

    private _searchFunctionality = (): void => {
        const searchText = Util.getElementValue("txt_challenge_search");
        const memberDivs = Array.from(document.getElementsByName("div_challenge_member")) as HTMLInputElement[];

        let index = 0;
        for (const div of memberDivs) {
            const divText = div.dataset.text || "";
            if (searchText == "") {
                //div.classList.add(index % 2 == 0 ? "prm-row" : "alt-row");
                Util.showElement(div);
                index++;
                continue;
            }

            if (!divText.toLowerCase().includes(searchText.toLowerCase())) {
                Util.hideElement(div);
            } else {
                //div.classList.add(index % 2 == 0 ? "prm-row" : "alt-row");
                Util.showElement(div);
                index++;
            }
        }
    }

    private _validateChallengeValues = (localAolo: IaOLO): boolean => {
        this._item = [];
        for (const member of this._challenge.Members) {
            let memberChkBoxId = `chk_challenge_member_${member.MemberId}`;
            let memberChkBox = <HTMLInputElement>document.getElementById(memberChkBoxId);
            if (!memberChkBox.checked)
                continue;
            let memberEvents: any[] = [];
            const storeDate = Util.NowStore(aOLO.Temp.TimeOffset);
            const activeEvent = member.Events.filter(evt => Util.compareDates(new Date(evt.EventDate), storeDate) === 0);
            if (activeEvent.length > 0) {
                for (const event of activeEvent) {
                    let eventChkBoxId = `chk_challenge_event_${event.EventId}`;
                    let eventChkBox = <HTMLInputElement>document.getElementById(eventChkBoxId);
                    if (!eventChkBox.checked)
                        continue;

                    if (event.EntryTypeId == 2) {
                        let txtName = `txt_challenge_open_field_m${member.MemberId}_e${event.EventId}_subMember`;
                        let subMembersText = <HTMLInputElement[]><any>document.getElementsByName(txtName);
                        let subMember = '';
                        subMembersText.forEach(x => {
                            if (x.value != '') {
                                if (subMember != '')
                                    subMember += ', ';
                                subMember += x.value;
                            }
                        });
                        if (event.IsRequired && subMember == '') {
                            DialogCreators.messageBoxOk(`${event.SubMemberTitle} is required.`, localAolo.buttonHoverStyle);
                            return false;
                        } else {
                            memberEvents.push({
                                eventId: event.EventId,
                                subMember: subMember,
                                subMemberId: null,
                                extra: null
                            });

                        }

                    } else if (event.EntryTypeId == 1 && event.SubMembers && event.SubMembers.length > 0) {
                        var BreakException = {};
                        try {
                            let selectedSubMemebrCount: number = 0;
                            event.SubMembers.forEach((x) => {
                                let id = `chkRdo_challenge_event_m${member.MemberId}_e${event.EventId}_s${x.SubMemberId}`;
                                let sm = <HTMLInputElement><any>document.getElementById(id);
                                //subMembersChkRdo.forEach(sm => {
                                if (sm && sm.checked) {
                                    selectedSubMemebrCount++;
                                    let extra: string | any = null;
                                    if (x.IsExtraField) {
                                        if (x.PredefinedValues == '') {
                                            let txtName = `txt_challenge_open_field_m${member.MemberId}_e${event.EventId}_s${x.SubMemberId}`;
                                            let extraText = <HTMLInputElement[]><any>document.getElementsByName(txtName);
                                            extraText.forEach(x => {
                                                if (x.value != '') {
                                                    if (extra == null) extra = '';
                                                    if (extra != '') extra += ', ';
                                                    extra += x.value;
                                                }
                                            });
                                            if (x.IsRequired && extra == null) {
                                                DialogCreators.messageBoxOk(`${x.ExtraFieldTitle} is required.`, localAolo.buttonHoverStyle);
                                                throw BreakException;

                                            }
                                        } else {
                                            let name = `chkRdo_challenge_event_m${member.MemberId}_e${event.EventId}_s${x.SubMemberId}`;
                                            let extraChkRdo = <HTMLInputElement[]><any>document.getElementsByName(name);
                                            extraChkRdo.forEach(xt => {
                                                if (xt.checked) {
                                                    if (extra == null) extra = '';
                                                    if (extra != '') extra += ', ';
                                                    extra += xt.dataset.value;
                                                }
                                            });
                                            if (x.IsRequired && extra == null) {
                                                DialogCreators.messageBoxOk(`${x.ExtraFieldTitle} is required.`, localAolo.buttonHoverStyle);
                                                throw BreakException;
                                            }
                                        }
                                    }
                                    memberEvents.push({
                                        eventId: event.EventId,
                                        subMember: x.SubMemberName,
                                        subMemberId: x.SubMemberId,
                                        extra: extra
                                    });
                                }
                                //});
                            });
                            if (selectedSubMemebrCount == 0 && event.IsRequired) {
                                DialogCreators.messageBoxOk(`${event.SubMemberTitle} is required.`, localAolo.buttonHoverStyle);
                                return false;
                            }
                        } catch (e) {
                            return false;
                        }
                    } else {
                        memberEvents.push({
                            eventId: event.EventId,
                            subMember: null,
                            subMemberId: null,
                            extra: null
                        });
                    }
                }
            }
            this._item.push({
                memberId: member.MemberId,
                events: memberEvents
            });

        }

        return true;
    }

    private _applyChallenge = (localAolo: IaOLO): void => {
        if (this._validateChallengeValues(localAolo)) {
            if (this._item.length == 0) {
                delete localAolo.Order.Challenges;
                Util.hideElement('div_cart_order_challenges');
            }

            else {
                localAolo.Order.Challenges = [{
                    challengeId: this._challenge.ChallengeId,
                    members: this._item
                }];

                const challengeData: IDataChallenge = localAolo.data.Challenges.find(x => x.ChallengeId == this._challenge.ChallengeId)!;
                const challengeName: string = Common.GetNewName(challengeData.Names, localAolo.Temp.languageCode);
                Util.setElement('innerHtml', 'spn_cart_order_challenges', challengeName);

                let challengeHtml: String = "";
                for (const [challengeIndex, orderChallenge] of localAolo.Order.Challenges.entries()) {

                    for (const [memberIndex, orderChallengeMember] of orderChallenge.members.entries()) {
                        const memberData : IDataChallengeMember = challengeData.Members.find(x => x.MemberId === orderChallengeMember.memberId)!;
                        const memberName : String = memberData.Name;
                        challengeHtml += `<div style="margin-left:1rem">${memberName}</div>`;

                        const processedEvents: number[] = [];
                        for (const [eventIndex, event] of orderChallengeMember.events.entries()) {
                            const eventData: IDataChallengeEvent = memberData.Events.find(x => x.EventId == event.eventId)!;
                            const eventName: String = eventData.EventName;

                            // Event has already been added to the displayed events. Only add Submembers/Extras
                            if (!processedEvents.includes(event.eventId)) {
                                challengeHtml += `<div style="margin-left:2rem">${eventName}</div>`;
                                challengeHtml += `<div style="margin-left:3rem">${event.subMember}</div>`;
                                if (event.extra) {
                                    const extras: String[] = event.extra.split(',');
                                    for (const extra of extras) {
                                        challengeHtml += `<div style="margin-left:4rem">${extra}</div>`;
                                    }
                                }
                                processedEvents.push(event.eventId);
                            }

                            // Add Event, Submembers, and Extras.
                            else {
                                challengeHtml += `<div style="margin-left:3rem">${event.subMember}</div>`;
                                if (event.extra) {
                                    const extras: String[] = event.extra.split(',');
                                    for (const extra of extras) {
                                        challengeHtml += `<div style="margin-left:4rem">${extra}</div>`;
                                    }
                                }
                            }
                        }
                    }
                }
                Util.setElement('innerHtml', 'div_cart_order_challenges_members', challengeHtml);
                Util.showElement('div_cart_order_challenges');

                // Event Listener for remove button
                Util.setElement("onclick", "btn_cart_challenges_remove", () => {
                    delete aOLO.Order.Challenges;
                    Util.hideElement("div_cart_order_challenges");
                });
            }
            this._closeDialog();
        }

    }

    private _openDialog = (localAolo: IaOLO): void => {
        Util.DialogFadeIn("dia_challenge", localAolo);
    }

    private _removeEventListeners = (): void => {
        Util.setElement("onclick", "btn_challenge_close", null);
        Util.setElement("onclick", "btn_challenge_apply", null);
        const membersDiv = document.getElementById("div_challenge_members");
        if (membersDiv)
            membersDiv.innerHTML = "";
    }

    private _closeDialog = (): void => {
        Util.DialogFadeOut("dia_challenge");
        this._removeEventListeners();
    }
}