import EventEmitter from "eventemitter3";
import "./hamburger.css"
import { EventTypes } from "../../../core/event-bus";
import { Pages } from "../../../types/enums";
import { User } from "../../../models/user";
import { LoginDialog } from "../../popups/Login/login";
import { ICustomerProvider } from "../../../services/customer/interfaces/customer-provider.interface";
import { LoginManager } from "../../../services/auth/login-manager";
import { IaOLO } from "../../../interfaces/aolo.interface";

export class Hamburger {
    private readonly _eventBus: EventEmitter;
    private readonly _user: User;
    private readonly _customerProvider: ICustomerProvider;
    private readonly _loginManager: LoginManager;
    private readonly _aOLO: IaOLO;


    private _loggedIn: boolean;

    private readonly _hamburgerIcon: HTMLElement | null;
    private readonly _hamburgerContainer: HTMLElement | null;
    private readonly _hamburgerMenu: HTMLElement | null;
    private readonly _hamburgerLinks: HTMLElement | null;
    private readonly _hamburgerLogin: HTMLElement | null;

    constructor(eventBus: EventEmitter, customerProvider: ICustomerProvider, loginManager: LoginManager, user: User, aOLO: IaOLO) {
        this._eventBus = eventBus;
        this._user = user;
        this._customerProvider = customerProvider;
        this._loginManager = loginManager;
        this._aOLO = aOLO;

        this._hamburgerIcon = document.getElementById("div_header_hamburger_menu");
        this._hamburgerContainer = document.getElementById("div_hamburger_container");
        this._hamburgerMenu = document.getElementById("div_hamburger_menu");
        this._hamburgerLinks = document.getElementById("ul_hamburger_menu_items");
        this._hamburgerLogin = document.getElementById("btn_hamburger_log_in");

        this._loggedIn = false;
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.LOGGED_IN, (isLoggedIn: boolean) => this._loggedIn = isLoggedIn);
        this._eventBus.on(EventTypes.LOGGED_OUT, (isLoggedOut: boolean) => this._loggedIn = !isLoggedOut);
    }

    public init = (): void => {
        this._setEventListeners();
        this._eventBusListeners();
    }

    public open = (): void => {
        this._hamburgerContainer?.classList.toggle("active", true);
    }

    public close = (): void => {
        this._hamburgerContainer?.classList.toggle("active", false);
    }

    private readonly _setEventListeners = (): void => {
        this._hamburgerIcon?.addEventListener("click", () => {
            this.open();
        })

        this._hamburgerContainer?.addEventListener("click", () => {
            this.close();
        })

        this._hamburgerMenu?.addEventListener("click", (e: Event) =>
            e.stopPropagation()
        );

        this?._hamburgerLinks?.querySelectorAll("[data-page]")?.forEach(
            (e: Element) => e.addEventListener("click",
                (e: Event) => {
                    this._eventBus.emit(
                        EventTypes.LOCATION_CHANGE,
                        Pages[(e.target as HTMLElement).dataset?.page as keyof typeof Pages],
                    )
                }
            ))

        this?._hamburgerLogin?.addEventListener("click", () => {
            console.log("HERE")
            const dialog = new LoginDialog(this._eventBus, this._customerProvider, this._loginManager, this._aOLO);
            dialog.init();
        });
    }
}