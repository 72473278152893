import { CustomerProviderEnum } from "../../types/enums";
import { IaOLO } from "../../interfaces/aolo.interface";
import { CustomerService } from "../../services/api/customer-service";
import { ICustomerProvider } from "./interfaces/customer-provider.interface";
import { AdoraCustomerProvider } from "./providers/adora-customer-provider";
import { PunchhCustomerProvider } from "./providers/punchh-customer-provider";

export class CustomerProviderFactory {

    static getCustomerProvider(localAolo: IaOLO): ICustomerProvider {
        const customerService = new CustomerService(localAolo);

        switch (localAolo.data.Loyalty.ProviderId) {
            case CustomerProviderEnum.ADORA:
                return new AdoraCustomerProvider(localAolo, customerService);
            case CustomerProviderEnum.PUNCHH:
                return new PunchhCustomerProvider(localAolo, customerService);
            default:
                return new AdoraCustomerProvider(localAolo, customerService);
        }
    }
}