import EventEmitter from "eventemitter3";
import { EventTypes } from "../../core/event-bus";
import { Util } from "../../utils/util";
import { LoyaltyProvider } from "../../types/enums";
import { Names, setPageLanguage } from "../../utils/i18n";
import { Common } from '../../common';
import { IaOLO } from '../../interfaces/aolo.interface';
import { ILoyaltyProvider } from '../loyalty/interfaces/loyalty-provider.interface';
import { DialogCreators } from '../../utils/dialog-creators';
import { ISignInResponse } from '../api/interfaces/profile-service.interfaces';
import { User } from '../../models/user';
import { ILoginAsync } from "../../components/popups/Login/login.interface";

export class LoginManager {
    private readonly _eventBus: EventEmitter;
    private readonly _loyaltyProvider: ILoyaltyProvider;
    private readonly _user: User;
    private readonly _aOLO: IaOLO;

    constructor(eventBus: EventEmitter, loginService: ILoyaltyProvider, user: User, aOLO: IaOLO) {
        this._eventBus = eventBus;
        this._loyaltyProvider = loginService;
        this._user = user;
        this._aOLO = aOLO;
    }

    public readonly signInAsync = async (email: string, password: string, rememberMe: boolean): Promise<void> => {
        const signInData: ILoginAsync = {
            storeKey: this._aOLO.storeInfo.StoreKey,
            email: email,
            password: password,
            rememberMe: rememberMe,
            app: Util.isAppView(),
            incompleteProfile: false // 2DO: incompleteProfile,
        };

        const result = await this._loyaltyProvider.signInAsync(signInData);
        const signedIn = await this._postSignIn(result);
    }

    public readonly signInFromJwtAsync = async (): Promise<void> => {
        let getUserResult;
        let getUserResultJwt;
        let getUserResultRefreshJwt;

        // Sign the user in if we SSO token
        if (this._aOLO.singleSignOnToken && this._loyaltyProvider.hasSingleSignOn()) {
            const result = await this._loyaltyProvider.signInViaSSOAsync();
            getUserResult = result?.user;
            getUserResultJwt = result?.jwt;
            getUserResultRefreshJwt = result?.refreshToken;
        } else {
            const result = await this._loyaltyProvider.getUserAsync();
            getUserResult = result?.user;
            getUserResultJwt = result?.jwt;
        }

        if (!getUserResult)
            return;

        this._user.user = getUserResult;

        this._handleSuccessfulSignIn(getUserResultJwt, getUserResultRefreshJwt);
        this._syncUserSettings();
    }

    public logout = async (user: User, error: boolean = false): Promise<void> => {
        if (await user.logout() || error) {
            if (error)
                localStorage.removeItem("tk");

            if (Util.isAppView()) {
                //@ts-ignore
                if (typeof deleteAppUserToken == "function")
                    //@ts-ignore
                    deleteAppUserToken("");
            }

            user.updateUser({ CustomerId: 0, ProfileId: 0, LoyaltyData: null });

            this._eventBus.emit(EventTypes.LOGGED_OUT);

            // Do not use this or self here.
            //Common.setHamburgerMenuItems(user, localaOLO);

            //if (localaOLO.isBrandSignIn)
            //    logOutFunction("signin", true);
            //else {
            //    if (localaOLO.Modules.OrderCart)
            //        localaOLO.Modules.OrderCart.CleanCart();
            //    await logOutFunction();
            //}
        }
    }

    private readonly _postSignIn = async (result: ISignInResponse): Promise<boolean> => {
        // Handle unsuccessful sign-in
        if (!result.success) {
            this._handleSignInFailure(result);
            return false;
        }

        if (result.user)
            this._user.user = result.user;

        if (this._user.getProperty("ProfileId") == 0 && this._loyaltyProvider.getLoyaltyProvider() !== LoyaltyProvider.PUNCHH) {
            DialogCreators.messageBoxOk(Names("UnableLogin"), this._aOLO.buttonHoverStyle, null, "txt_login_email");
            Util.LogError("SignIn _postSignIn", new Error("Sign in successful with ProfileId = 0"), this._aOLO);
            return false;
        }

        if (result.jwt === null) {
            this._handleMissingJwt();
            return false;
        }

        await this._handleSuccessfulSignIn(result.jwt, result.refreshToken);
        this._syncUserSettings();

        if (!this._user.getProperty("IsProfileComplete")) {
            this._handleIncompleteProfile();
            return true;
        }

        // 2DO: Maybe this is not relevant anymore or needs refactoring elsewhere
        //if (this._brandFunction) {
        //    if (aOLO.RedirectToTracker)
        //        await this._brandFunction("tracker", false);
        //    else if (aOLO.profilePage)
        //        await this._brandFunction("profile", true);
        //    else
        //        await this._brandFunction("loyalty", true);
        //} else
        this._user.updateUser({ GU: 2 });
        return true;
    }

    /**
    * Handles sign-in failure scenarios such as timeouts or invalid credentials.
    * @private
    * @param {ISignInResponse} result - The result of the sign-in attempt.
    */
    private readonly _handleSignInFailure = (result: ISignInResponse): void => {
        if (result.timeout)
            return;

        const message =
            this._loyaltyProvider.getLoyaltyProvider() === LoyaltyProvider.PUNCHH
                ? result.message || ""
                : Names("UnableLogin");
        DialogCreators.messageBoxOk(message, this._aOLO.buttonHoverStyle, null, "txt_login_email");
    };

    /**
     * Handles the case where the JWT is missing after sign-in.
     * @private
     */
    private readonly _handleMissingJwt = (): void => {
        // 2DO: Refactor this. Probably emit
        //if (this._brandFunction)
        //    this._brandFunction("profile", false, true);
        //else if (this._profileFunction)
        //    this._profileFunction(true);
    };

    /**
     * Handles a successful sign-in by updating the JWT, refresh token, and fetching discounts.
     * @private
     * @async
     * @param {ISignInResponse} result - The result of the sign-in attempt.
     */
    private readonly _handleSuccessfulSignIn = async (jwt?: string, refreshToken?: string): Promise<void> => {
        if (jwt === null)
            return;

        await this._user.signIn(jwt, refreshToken);
        await this._loyaltyProvider.getActiveDiscountBasket();
        this._eventBus.emit(EventTypes.LOGGED_IN);
        
        // 2DO: Send Logged In Emit to Hamburger Menu
        //Common.setHamburgerMenuItems(aOLO);
    };

    /**
     * Synchronizes user settings such as dark mode and language.
     * @private
     */
    private readonly _syncUserSettings = (): void => {
        if (this._user.getProperty("IsDarkMode") !== this._aOLO.Temp.DarkMode) {
            Common.toggleDarkMode(this._aOLO.Temp, this._user);
        }

        if (this._user.getProperty("CultureCode") !== this._aOLO.Temp.languageCode) {
            setPageLanguage(this._user.getProperty("CultureCode") || "en-us");
        }
    };

    /**
     * Handles the case where the user profile is incomplete.
     * @private
     */
    private readonly _handleIncompleteProfile = (): void => {
        // 2DO: Refactor this. Probably emit
        //if (this._brandFunction)
        //    this._brandFunction('profile', true, true);
        //else if (this._profileFunction)
        //    this._profileFunction(true);
    };


}