import { IApplyCouponByIdParams, ICouponFunctions, IQualifyItem, IQualifyItemExt, IRemovedCoupon, ITotalCouponPrice, IvItem } from "./interfaces/coupons.interface";
import { IUserCampaignCodesData, IUserLoyaltyReward } from "../models/interfaces/user.interface";
import { IOrderCoupon, IOrderItem } from "../interfaces/order.interface";
import { IDataCoupon } from "../interfaces/data.interface";
import { Names } from "../utils/i18n";
import { Common } from "../common";
import { Util } from "../utils/util";
import { MealDeal } from "./meal-deal";
import { CouponIdList, CouponTypeList } from "../types/enums";
import { DialogCreators } from "../utils/dialog-creators";
import { OnlineOrderingUtil } from "./online-ordering-util";
import { ITempIncompleteDiscount } from "../interfaces/temp.interface";

export class Coupon {
    private _functions: ICouponFunctions;

    constructor(functions: ICouponFunctions) {
        this._functions = functions;
    }

    public RemoveCouponAll = async (): Promise<{ couponId: number, offerCode: string | null }[]> => {
        return await this.RemoveCouponByItemKey(null);
    }

    public RemoveCouponByItemKey = async (itemKey: number | null, refresh: boolean = false): Promise<IRemovedCoupon[]> => {
        let i = aOLO.Order.ItemsCoupons.length;
        const removedCouponKeys: number[] = [];
        while (i--) {
            const iCoupon = aOLO.Order.ItemsCoupons[i];
            if (iCoupon.ItemKey === itemKey || itemKey === null) {
                if (!removedCouponKeys.includes(iCoupon.CouponKey)) {
                    removedCouponKeys.push(iCoupon.CouponKey);
                }
                this._removeDiscountFromItem(iCoupon.ItemKey, iCoupon.HalfIndex, iCoupon.BeforeTaxDiscount, iCoupon.AfterTaxDiscount, (iCoupon.DiscountedQuantity === 1));
                aOLO.Order.ItemsCoupons.splice(i, 1);
            }
        }

        const removedCouponIDs: IRemovedCoupon[] = [];
        for (const couponKey of removedCouponKeys) {
            const coupon = aOLO.Order.Coupons.find(x => x.CouponKey === couponKey);
            if (!coupon)
                continue;

            let rewardId = null;
            const reward = aOLO.User.LoyaltyData?.Rewards.find(x => x.RewardId == Number(coupon.RewardId));
            if (reward)
                rewardId = reward.RewardId;

            removedCouponIDs.push({
                couponId: coupon.CouponId,
                offerCode: (coupon.IsCampaignCode || coupon.IsPromotionCode) ? coupon.CouponCode : null,
                rewardId: rewardId,
                isBankedCurrency: coupon.IsBankedCurrency
            });
        }

        for (const couponKey of removedCouponKeys)
            await this.RemoveCouponByKey(couponKey, refresh);

        return removedCouponIDs;
    }

    private _removeDiscountFromItem = (itemKey: number, halfIndex: number, beforeTaxDiscount: number, afterTaxDiscount: number, clearDiscountedQuantity: boolean): number => {
        let arrayIndex = -1;
        for (const [index, oItem] of aOLO.Order.Items.entries()) {
            if (oItem.ItemKey === itemKey && oItem.HalfIndex === halfIndex) {
                oItem.BeforeTaxDiscount -= beforeTaxDiscount;
                oItem.AfterTaxDiscount -= afterTaxDiscount;
                if (clearDiscountedQuantity) {
                    oItem.DiscountedQuantity -= 1;
                }
                const mItem = aOLO.data.Items.find(x => x.ItemId === oItem.ItemId) || null;
                OnlineOrderingUtil.TaxItem(mItem, oItem, aOLO);
                arrayIndex = index;
            } else if (oItem.ItemKey === itemKey && oItem.HalfCount == 1)
                if (clearDiscountedQuantity && oItem.DiscountedQuantity > 0) {
                    oItem.DiscountedQuantity -= 1;
                }
        }
        return arrayIndex;
    }

    public RemoveCouponByKey = async (couponKey: number, refresh: boolean) => {
        let i = aOLO.Order.ItemsCoupons.length;
        let itemKey = 0;
        while (i--) {
            const iCoupon = aOLO.Order.ItemsCoupons[i];
            if (iCoupon.CouponKey === couponKey) {
                itemKey = iCoupon.ItemKey;
                this._removeDiscountFromItem(iCoupon.ItemKey, iCoupon.HalfIndex, iCoupon.BeforeTaxDiscount, iCoupon.AfterTaxDiscount, (iCoupon.DiscountedQuantity === 1));
                aOLO.Order.ItemsCoupons.splice(i, 1);
            }
        }

        for (let i = 0; i < aOLO.Order.Coupons.length; i++) {
            if (aOLO.Order.Coupons[i].CouponKey === couponKey) {
                const couponCode = aOLO.Order.Coupons[i].CouponCode;

                // Loyalty Discount
                if (aOLO.Order.Coupons[i].CouponId === 7 && itemKey !== 0)
                    await OnlineOrderingUtil.RedeemPoints(Number(couponCode), itemKey, aOLO);

                // invalidate if single use coupon
                if (couponCode.toLowerCase().includes("suc_"))
                    await this._invalidateSingleUseCoupon(couponCode);

                aOLO.Order.Coupons.splice(i, 1);
                break;
            }
        }

        if (refresh) {
            OnlineOrderingUtil.ReCalculateTax(aOLO);
            this._functions.GUI_SetOrder(aOLO, aOLOModules);
            await this.AutoApplyCoupons();
        }
    }

    public ApplyCouponByID = async (params: IApplyCouponByIdParams): Promise<boolean | null> => {
        const {
            couponId,
            discount = null,
            showErrorMsg = false,
            showApplyMsg = false,
            couponCode = null,
            itemKey = null,
            channelId = null,
            autoApplying = false,
            isCampaignCode = false,
            isPromotionCode = false,
            rewardId = null,
            runBatch = true,
            isBankedCurrency = false
        } = params;

        if (aOLO.Order.Total != 0) {
            const coupon = this.GetCoupon(couponId, true, aOLO.Order.OrderTypeID, channelId);
            if (!coupon)
                return false;

            if (couponId <= 11 && discount) {
                coupon.MaximumDiscountAmount = discount;
                coupon.Discount = discount;
            }
            return await this.ApplyCoupon(coupon, (couponCode ? couponCode : null), showErrorMsg, showApplyMsg, itemKey, autoApplying, isCampaignCode, isPromotionCode, rewardId, runBatch, isBankedCurrency);
        } else if (showErrorMsg)
            DialogCreators.messageBoxOk(Names("CannotUseCouponTotalZero"), aOLO.buttonHoverStyle);
        return false;
    }

    public ApplyCoupon = async (coupon: IDataCoupon, couponCode: string | null, showErrorMsg: boolean, showApplyMsg: boolean, itemKey?: number | null, autoApplying?: boolean, isCampaignCode?: boolean, isPromotionCode?: boolean, rewardId?: number | null, runBatch: boolean = true, isBankedCurrency: boolean = false): Promise<boolean | null> => {
        let reward = null;
        if (aOLO.User?.LoyaltyData?.Rewards && aOLO.User.LoyaltyData.Rewards.length > 0)
            if (rewardId && !couponCode)
                reward = aOLO.User.LoyaltyData.Rewards.find(x => x.RewardId === rewardId);
            else
                reward = aOLO.User.LoyaltyData.Rewards.find(x => x.RewardId === Number(couponCode));

        let offer = null;
        if ((isCampaignCode || isPromotionCode) && aOLO.User?.Offers?.Codes && aOLO.User.Offers.Codes.length > 0)
            offer = aOLO.User.Offers.Codes.find(x => x.CouponCode.toLowerCase() === couponCode);


        if (coupon.IsScheduled && !this.CheckCouponSCHS(coupon)) {
            if (showErrorMsg) {
                DialogCreators.messageBoxOk(Names("InvalidScheduleCode"), aOLO.buttonHoverStyle);
            }
            return false;
        }

        if (reward && reward.ExpiryDate && !aOLOModules.LoyaltyProvider.discountValidForOrderTime(reward.ExpiryDate)) {
            if (showErrorMsg) {
                DialogCreators.messageBoxOk(Names("InvalidReward"), aOLO.buttonHoverStyle);
            }
            return false;
        }

        if (coupon.CouponId !== 7 && !reward && !this._checkOneCouponOnly(autoApplying))
            return null;

        if (!couponCode) couponCode = ""; //TODO
        let returnValue = true;
        let isActive = true;
        let couponKey = 0;
        let discountAmount = 0.00;
        let qualifiedList: IvItem[] = [];
        let totalAmounts;
        let oItem: IOrderItem;
        let nItem: IOrderItem;

        if (!this.checkMinimumOrderAmount(coupon, autoApplying || false, false)) {
            if (!aOLO.Temp.IncompleteDiscounts) {
                aOLO.Temp.IncompleteDiscounts = [];
            }
            if (!aOLO.Temp.IncompleteDiscounts.some(cpn => cpn.couponID === coupon.CouponId)) {
                aOLO.Temp.IncompleteDiscounts.push({ couponID: coupon.CouponId, couponCode: couponCode, channelID: null, isCampaignCode: isCampaignCode, isPromotionCode: isPromotionCode });
                aOLO.Order.Coupons = aOLO.Order.Coupons.filter(cpn => cpn.CouponId !== coupon.CouponId);
                await this._functions.GUI_SetOrder(aOLO, aOLOModules);
            }
            return false;
        }

        if (!this._checkCouponLimit(coupon, autoApplying))
            return false;

        // Get Qualifying Items for the given coupon
        qualifiedList = this._getQualifiedItemList(coupon, itemKey, reward);

        if (qualifiedList?.length > 0) {
            totalAmounts = this._getTotalCouponItemsPrices(qualifiedList);
            const cDiscount = coupon.Discount;
            switch (coupon.CouponTypeId) {
                case CouponTypeList.MANAGER_DISCOUNT:
                case CouponTypeList.NEXT_ORDER_CREDIT:
                case CouponTypeList.LOYALTY_DISCOUNT:
                case CouponTypeList.EMPLOYEE_DISCOUNT:
                case CouponTypeList.EMPLOYEE_CONSUMPTION:
                    if (coupon.CouponId === CouponIdList.MANAGER_DOLLAR_OFF ||
                        coupon.CouponId === CouponIdList.LOYALTY_OFFER_THIRDPARTY ||
                        coupon.CouponId === CouponIdList.LOYALTY_REWARD_THIRDPARTY
                    ) {
                        discountAmount = cDiscount;
                    } else if (coupon.CouponId === CouponIdList.MANAGER_PERCENT_OFF_ORDER ||
                        coupon.CouponId === CouponIdList.EMPLOYEE_DISCOUNT ||
                        coupon.CouponId === CouponIdList.EMPLOYEE_CONSUMPTION
                    ) {
                        discountAmount = (aOLO.Order.SubTotal - aOLO.Order.Discount + aOLO.Order.OrderTypeSubType.OrderTypeCharge) * cDiscount / 100;
                        isActive = true;
                    } else if (coupon.CouponId === CouponIdList.MANAGER_FIXED_PRICE) {
                        discountAmount = totalAmounts.OrderItemPrice - cDiscount;
                        isActive = true;
                    } else if (coupon.CouponId === CouponIdList.MANAGER_NEXT_ORDER_CREDIT_DOLLAR_OFF) {
                        discountAmount = cDiscount;
                    } else if (coupon.CouponId === CouponIdList.MANAGER_NEXT_ORDER_CREDIT_PERCENT_OFF) {
                        discountAmount = (aOLO.Order.SubTotal - aOLO.Order.Discount + aOLO.Order.OrderTypeSubType.OrderTypeCharge) * cDiscount / 100;
                        isActive = true;
                    } else if (coupon.CouponId === CouponIdList.MANAGER_PERCENT_OFF_ITEM) {
                        discountAmount = (totalAmounts.OrderItemPrice * coupon.Discount / 100);
                    } else if (coupon.CouponId === CouponIdList.LOYALTY_REDEEM_ITEM) {
                        discountAmount = totalAmounts.OrderItemPrice;
                    }
                    break;
                case CouponTypeList.DOLLAR_OFF:
                    discountAmount = cDiscount;
                    isActive = true;
                    break;
                case CouponTypeList.FIXED_PRICE:
                    if (qualifiedList) {
                        let iPrice = totalAmounts.CouponItemPrice;
                        if (iPrice > totalAmounts.OrderItemPrice) iPrice = totalAmounts.OrderItemPrice;
                        discountAmount = iPrice - cDiscount - totalAmounts.CouponAddOnPrice;
                    }
                    //discountAmount = totalAmounts.CouponItemPrice - cDiscount - totalAmounts.CouponAddOnPrice;
                    break;
                case CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER:
                    discountAmount = (aOLO.Order.SubTotal - aOLO.Order.Discount) * cDiscount / 100;
                    isActive = true;
                    break;
                case CouponTypeList.PERCENTAGE_OFF_AN_ITEM:
                    discountAmount = (totalAmounts.OrderItemPrice * cDiscount / 100) - totalAmounts.CouponAddOnPrice;
                    //if (coupon.Items.length === 0) isActive = true;
                    break;
                case CouponTypeList.PERCENTAGE_OFF_AN_ITEM_IN_CATEGORY_OR_BOGO_IN_CATEGORY:
                    discountAmount = totalAmounts.OrderItemPrice * cDiscount / 100;
                    break;
                case CouponTypeList.PERCENTAGE_OFF_ALL_ITEMS_IN_CATEGORY:
                    discountAmount = totalAmounts.OrderItemPrice * cDiscount / 100;
                    break;
                case CouponTypeList.PERCENTAGE_OFF_ITEM_IN_CATEGORY_BASED_ON_PRICE:
                    discountAmount = (totalAmounts.CouponItemPrice * cDiscount / 100) - totalAmounts.CouponAddOnPrice;
                    break;
                case CouponTypeList.UPGRADE:
                    if (qualifiedList) {
                        //vItem.oPrice = vItem.oPrice / vItem.HalfCount;
                        let price = 0.00;
                        // if half and half Item got qualified check both halfs for price
                        if (qualifiedList.length > 1) {
                            let highiestPrice = 0.0;
                            let AveragePrice = 0.0;
                            for (let j = 0; j < qualifiedList.length; j++) {
                                oItem = aOLO.Order.Items[qualifiedList[j].ItemArrayIndex];
                                for (let i = 0; i < coupon.Upgrades.length; i++) {
                                    if (oItem.SizeId === coupon.Upgrades[i].BuySizeId) {
                                        const mItem = aOLO.data.Items[oItem.Index];
                                        let price = OnlineOrderingUtil.GetItemPrice(mItem, oItem, aOLO).Price;
                                        if (price < 0) price = -price;
                                        if (price > highiestPrice) highiestPrice = price;
                                        AveragePrice += price;
                                        break;
                                    }
                                }
                            }
                            if (aOLO.data.Settings.HHP === 1) {
                                price = highiestPrice;
                            } else if (aOLO.data.Settings.HHP === 2) {
                                price = Util.Float2(AveragePrice / Util.Float2(qualifiedList.length));
                            }
                        } else {
                            let oItem = aOLO.Order.Items[qualifiedList[0].ItemArrayIndex];
                            for (let i = 0; i < coupon.Upgrades.length; i++) {
                                if (oItem.SizeId === coupon.Upgrades[i].BuySizeId) {
                                    nItem = Util.deepClone(oItem);
                                    nItem.SizeId = coupon.Upgrades[i].PriceSizeId;
                                    let priced = OnlineOrderingUtil.PriceItem(nItem, aOLO);
                                    if (priced)
                                        price = nItem.Price;
                                }
                                break;
                            }
                        }
                        let orderItemPrice = 0;
                        for (let i = 0; i < qualifiedList.length; i++) {
                            orderItemPrice += qualifiedList[i].oPrice;
                        }
                        if (price > 0) {
                            discountAmount = orderItemPrice - price;
                        }
                    }
                    break;
                case CouponTypeList.QUANTITY_PRICE:
                case CouponTypeList.MIX_AND_MATCH_PRICE:
                    discountAmount = 0;
                    for (const item of qualifiedList) {
                        if (item.HalfCount && item.cPrice > (cDiscount / item.HalfCount)) {
                            discountAmount += (item.cPrice - (cDiscount / item.HalfCount) - item.AddOn);
                        }
                    }
                    isActive = true;
                    break;
                case CouponTypeList.MIX_AND_MATCH_PERCENTAGE:
                    discountAmount = 0;
                    for (const item of qualifiedList) {
                        if (item.oPrice > 0) {
                            discountAmount += (item.oPrice * cDiscount / 100) - item.AddOn;
                        }
                    }
                    isActive = true;
                    break;
                case CouponTypeList.QUANTITY_DISCOUNT:
                    discountAmount = 0;
                    if (qualifiedList) {
                        for (const item of qualifiedList) {
                            if (item.HalfCount && item.cPrice > (cDiscount / item.HalfCount)) {
                                discountAmount += (cDiscount / item.HalfCount);
                            }
                        }
                        discountAmount = discountAmount - totalAmounts.CouponAddOnPrice;
                    }
                    isActive = true;
                    break;
            }
            discountAmount = Util.Float2(discountAmount);
            if (discountAmount > 0) {
                let maxDiscount = coupon.MaximumDiscountAmount;
                if (maxDiscount > 0 && discountAmount > maxDiscount) {
                    discountAmount = maxDiscount;
                }
                if (discountAmount > aOLO.Order.SubTotal - aOLO.Order.Discount + aOLO.Order.OrderTypeSubType.OrderTypeCharge) {
                    discountAmount = aOLO.Order.SubTotal - aOLO.Order.Discount + aOLO.Order.OrderTypeSubType.OrderTypeCharge;
                }
                if (discountAmount > 0) {
                    this._splitDiscount(qualifiedList, totalAmounts.OrderItemPrice, discountAmount, coupon.IsBeforeTax, coupon.CouponTypeId);
                    this._addDiscountsToItems(qualifiedList);
                    couponKey = this._addCouponToOrder(coupon.CouponId, couponCode, coupon.Discount, discountAmount, coupon.IsBeforeTax, isActive, coupon.AllowOtherCoupons, coupon.ChannelID, autoApplying, isCampaignCode, isPromotionCode, rewardId, isBankedCurrency);
                    this._addCouponToItemsCoupon(qualifiedList, couponKey, coupon.Priority, isActive, (coupon.AllowOtherCoupons ? 0 : 1));
                    if (aOLO.affectedItemsArrayIndexes) {
                        for (let i = 0; i < aOLO.Order.Items.length; i++) {
                            if (aOLO.Order.Items[i].DiscountedMarkedQuantity > 0 && aOLO.affectedItemsArrayIndexes.indexOf(i) === -1)
                                aOLO.affectedItemsArrayIndexes.push(i);
                        }
                    } else
                        OnlineOrderingUtil.ReCalculateTax(aOLO);

                    if (coupon.AllowOtherCoupons)
                        this._clearMarkedItems();
                    else
                        this._useMarkedItems();

                    await this._functions.GUI_SetOrder(aOLO, aOLOModules);

                } else
                    returnValue = false;
            } else {
                if (showErrorMsg)
                    DialogCreators.messageBoxOk(`${Names("CouponNotQualified")}</br>${Common.GetDescription(coupon.Descriptions, aOLO.Temp.languageCode)}`, aOLO.buttonHoverStyle);
                returnValue = false;
            }
        } else {
            if (showErrorMsg) {
                if (coupon.Descriptions)
                    DialogCreators.messageBoxOk(`${Names("CouponNotQualified")}</br>${Common.GetDescription(coupon.Descriptions, aOLO.Temp.languageCode)}`, aOLO.buttonHoverStyle);
                else
                    DialogCreators.messageBoxOk(Names("CouponNotQualified"), aOLO.buttonHoverStyle);
            }
            returnValue = false;
        }

        if (returnValue) {
            // Check For Minimum Quantity Once more
            if (!this.checkMinimumOrderAmount(coupon, autoApplying || false, true)) {
                if (!aOLO.Temp.IncompleteDiscounts) {
                    aOLO.Temp.IncompleteDiscounts = [];
                }
                if (!aOLO.Temp.IncompleteDiscounts.some(cpn => cpn.couponID === coupon.CouponId)) {
                    aOLO.Temp.IncompleteDiscounts.push({ couponID: coupon.CouponId, couponCode: couponCode, channelID: null, isCampaignCode: isCampaignCode, isPromotionCode: isPromotionCode });
                    aOLO.Order.Coupons = aOLO.Order.Coupons.filter(cpn => cpn.CouponId !== coupon.CouponId);
                }
                await this.RemoveCouponByKey(couponKey, false);
                OnlineOrderingUtil.ReCalculateTax(aOLO);
                await this._functions.GUI_SetOrder(aOLO, aOLOModules);

                return false;
            }

            if (showApplyMsg)
                DialogCreators.messageBoxFade(Names("CouponApplied"));

            if (aOLO.mediaMax576.matches || aOLO.mediaMax840.matches)
                OnlineOrderingUtil.ToggleOrderItems(false, aOLO);

            if (reward) {
                reward.Used = true;
                if (aOLO.Dialog.Rewards)
                    aOLO.Dialog.Rewards.CloseDialog();
            } else if (offer)
                offer.Used = true;

            if (runBatch)
                aOLOModules.LoyaltyProvider.batchComparison(true);
        }

        return returnValue;
    }

    private _checkOneCouponOnly = (autoApplying?: boolean): boolean => {
        let coupons = aOLO.Order.Coupons.filter(x => x.CouponId != 7);
        if (coupons.length > 0) {
            let oneCouponLimit = aOLO.data.Settings.CPNLIM;
            if (oneCouponLimit === 2 || oneCouponLimit === 3) {
                !autoApplying && DialogCreators.messageBoxOk(Names("CpnLimitOne"), aOLO.buttonHoverStyle);
                return false;
            }
        }
        return true;
    }

    public checkMinimumOrderAmount = (coupon: IDataCoupon, autoApplying: boolean, isVerifying: boolean): boolean => {
        const CalculationMethod = {
            IncludeAllWithDiscountedPrice: 0,
            IncludeAllWithOriginalPrice: 1,
            ExcludeItemsWithDiscountsOrLoyalty: 2,
            ExcludeDiscountedExceptLoyaltyWithOriginalPrice: 3,
            ExcludeDiscountedExceptLoyaltyWithDiscountedPrice: 4
        };

        if (coupon.CouponTypeId == CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER && isVerifying)
            return true;

        if (coupon.MinimumOrderAmount > 0) {
            let orderAmount = 0;

            this._calculateDiscountedQTYs();

            switch (coupon.CalculationMethodID) {
                case null:
                case undefined:
                case CalculationMethod.IncludeAllWithDiscountedPrice:
                    orderAmount = aOLO.Order.SubTotal - aOLO.Order.Discount;
                    break;
                case CalculationMethod.IncludeAllWithOriginalPrice:
                    orderAmount = aOLO.Order.SubTotal;
                    break;
                case CalculationMethod.ExcludeItemsWithDiscountsOrLoyalty:
                    for (const oItem of aOLO.Order.Items) {
                        orderAmount += this._calculateNonDiscountedItems(oItem);
                    }
                    break;
                case CalculationMethod.ExcludeDiscountedExceptLoyaltyWithOriginalPrice:
                    for (const oItem of aOLO.Order.Items) {
                        //handle any non discounted items
                        orderAmount += this._calculateNonDiscountedItems(oItem);

                        //handle loyalty with original price
                        if (oItem.LoyaltyDiscountsQTY > 0) {
                            orderAmount += Util.Float2(oItem.Price);
                        }
                    }
                    break;
                case CalculationMethod.ExcludeDiscountedExceptLoyaltyWithDiscountedPrice:
                    for (const oItem of aOLO.Order.Items) {
                        //handle any non discounted items
                        orderAmount += this._calculateNonDiscountedItems(oItem);

                        //handle loyalty with original price
                        if (oItem.LoyaltyDiscountsQTY > 0) {
                            orderAmount += oItem.LoyaltyDiscountsAmount;
                        }
                    }
                    break;
            }

            if (orderAmount < coupon.MinimumOrderAmount) {
                !autoApplying && DialogCreators.messageBoxOk(Names("CPNMINAMT").replace("??", coupon.MinimumOrderAmount.toFixed(2)), aOLO.buttonHoverStyle);
                return false;
            }
        }
        return true;
    }

    private _calculateDiscountedQTYs = (): void => {
        //Calculate total discounted and total discounted loyalty for each item to help with calculation methods 2-4
        for (const oItem of aOLO.Order.Items) {
            let loyaltyDiscountsQty = 0;
            let nonLoyaltyDiscountsQTY = 0;
            let loyaltyDiscountsAmount = 0;
            const iCoupons = aOLO.Order.ItemsCoupons.filter(x => x.ItemKey == oItem.ItemKey && x.HalfIndex == oItem.HalfIndex);
            for (const iCpn of iCoupons) {
                const appliedCoupon = aOLO.Order.Coupons.find(x => x.CouponKey == iCpn.CouponKey);
                if (appliedCoupon?.RewardId != null) {
                    loyaltyDiscountsQty += 1;
                    loyaltyDiscountsAmount += Util.Float2(oItem.Price - (appliedCoupon.Amount / oItem.HalfCount));
                } else {
                    nonLoyaltyDiscountsQTY += 1;
                }
            }
            oItem.LoyaltyDiscountsQTY = loyaltyDiscountsQty;
            oItem.NonLoyaltyDiscountsQTY = nonLoyaltyDiscountsQTY;
            oItem.LoyaltyDiscountsAmount = loyaltyDiscountsAmount;
        }
    }

    private _calculateNonDiscountedItems = (oItem: IOrderItem): number => {
        let orderAmount = 0;
        const nonDiscountedQTY = oItem.Quantity - oItem.NonLoyaltyDiscountsQTY - oItem.LoyaltyDiscountsQTY;
        if (nonDiscountedQTY > 0) {
            orderAmount += Util.Float2(oItem.Price * nonDiscountedQTY);
        }
        return orderAmount
    }

    private _checkCouponLimit = (coupon: IDataCoupon, autoApplying?: boolean): boolean => {
        const limit = coupon.LimitsPerOrder;
        if (limit <= 0)
            return true;

        const count = aOLO.Order.Coupons.filter(c => c.CouponId === coupon.CouponId).length;
        if (count >= limit) {
            !autoApplying && DialogCreators.messageBoxOk(Names("CPNLIMIT").replace("??", limit.toString()), aOLO.buttonHoverStyle);
            return false;
        }

        return true;
    }

    private _getQualifiedItemList = (coupon: IDataCoupon, itemKey?: number | null, reward?: IUserLoyaltyReward | null): IvItem[] => {
        let qualifiedList: IvItem[] = [];

        switch (true) {
            case coupon.CouponId === CouponIdList.LOYALTY_OFFER_THIRDPARTY:
            case coupon.CouponId === CouponIdList.LOYALTY_REWARD_THIRDPARTY:
                qualifiedList = this._qualifyAllItems(coupon, reward);
                //qualifiedList = this._qualifyLoyaltyItems();
                break;

            case coupon.CouponTypeId === CouponTypeList.MANAGER_DISCOUNT:
                qualifiedList = this._qualifyAllItems(coupon, reward);
                break;

            case coupon.CouponTypeId === CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER:
                if (coupon.IsVerificationNeeded && coupon.Items && coupon.Items.length > 0) {
                    qualifiedList = this.QualifyCouponItems(coupon, false, false, true);
                    if (qualifiedList)
                        qualifiedList = this._qualifyAllItems();
                } else if (coupon.AllowOtherCoupons) {
                    qualifiedList = this._qualifyAllItems();
                } else {
                    qualifiedList = this._qualifyAllNotDiscountedItems();
                }
                break;

            case coupon.CouponTypeId === CouponTypeList.PERCENTAGE_OFF_AN_ITEM_IN_CATEGORY_OR_BOGO_IN_CATEGORY:
                qualifiedList = this._qualifyAnItemInCategory(coupon);
                break;

            case coupon.CouponTypeId === CouponTypeList.PERCENTAGE_OFF_ALL_ITEMS_IN_CATEGORY:
                qualifiedList = this._qualifyAllItemsInCategory(coupon);
                break;

            //case coupon.CouponTypeId === CouponTypeList.PERCENTAGE_OFF_TWO_ITEMS_IN_CATEGORY:
            //    qualifiedList = QualifyTwoItemsInCategory(coupon);
            //    break;
            case coupon.CouponTypeId === CouponTypeList.MIX_AND_MATCH_PERCENTAGE:
            case coupon.CouponTypeId === CouponTypeList.MIX_AND_MATCH_PRICE:
                qualifiedList = this.QualifyCouponItems(coupon, false, false, true);

                let allOthersQualifiedItems = this.QualifyCouponItems(coupon, false, true, false);
                while (allOthersQualifiedItems.length > 0) {
                    qualifiedList.push(...allOthersQualifiedItems);
                    allOthersQualifiedItems = this.QualifyCouponItems(coupon, false, true, false);
                }

                if (coupon.MinimumItemQuantity && qualifiedList.length < coupon.MinimumItemQuantity) {
                    qualifiedList = [];
                    this._clearMarkedItems();
                }
                break;

            case coupon.Items && coupon.Items?.length > 0:
                qualifiedList = this.QualifyCouponItems(coupon, false, false, true);
                break;

            case !coupon.IsVerificationNeeded:
                if (coupon.AllowOtherCoupons)
                    qualifiedList = (itemKey != null) ? this._qualifyAllItems().filter(x => x.ItemKey === itemKey) : this._qualifyAllItems();
                else
                    qualifiedList = (itemKey != null) ? this._qualifyAllNotDiscountedItems().filter(x => x.ItemKey === itemKey) : this._qualifyAllNotDiscountedItems();
                break;
        }

        return qualifiedList;
    }

    private _qualifyLoyaltyItems = (): IvItem[] => {
        let qualifiedGroupList: IvItem[] = [];

        for (const [index, oItem] of aOLO.Order.Items.entries()) {
            const mItem = aOLO.data.Items.find(x => x.ItemId === oItem.ItemId);
            if (mItem?.IsLoyalty) {
                oItem.DiscountedMarkedQuantity = oItem.Quantity;

                for (let j = 0; j < oItem.Quantity; j++) {
                    const vItem = {
                        ItemArrayIndex: index,
                        QTYIndex: j + 1,
                        cPrice: 0.00,
                        oPrice: (oItem.HalfCount > 1) ? oItem.HalfHalfPrice / oItem.HalfCount : oItem.Price,
                        ItemKey: oItem.ItemKey,
                        HalfIndex: oItem.HalfIndex,
                        ItemRecID: oItem.ItemRecId,
                        AddOn: 0.00,
                        BeforeTaxDiscount: 0.0,
                        AfterTaxDiscount: 0.0
                    };
                    qualifiedGroupList.push(vItem);
                }
            }
        }
        return qualifiedGroupList;
    }

    private _qualifyAllItems = (coupon?: IDataCoupon, reward?: IUserLoyaltyReward | null): IvItem[] => {
        if (coupon && !coupon.AllowOtherCoupons && aOLO.Order.Coupons?.length > 0 && !reward)
            return [];

        let qualifiedGroupList: IvItem[] = [];
        for (const [index, oItem] of aOLO.Order.Items.entries()) {
            oItem.DiscountedMarkedQuantity = oItem.Quantity;

            for (let j = 0; j < oItem.Quantity; j++) {
                const vItem = {
                    ItemArrayIndex: index,
                    QTYIndex: j + 1,
                    cPrice: 0.00,
                    oPrice: (aOLO.Order.Items[index].HalfCount > 1) ? aOLO.Order.Items[index].HalfHalfPrice : aOLO.Order.Items[index].Price,
                    ItemKey: aOLO.Order.Items[index].ItemKey,
                    HalfIndex: aOLO.Order.Items[index].HalfIndex,
                    ItemRecID: aOLO.Order.Items[index].ItemRecId,
                    AddOn: 0.00,
                    BeforeTaxDiscount: 0.0,
                    AfterTaxDiscount: 0.0
                };
                qualifiedGroupList.push(vItem);
            }
        }

        return qualifiedGroupList;
    }

    private _qualifyAnItemInCategory = (coupon: IDataCoupon): IvItem[] => {
        let qualifiedGroupList: IvItem[] = [];

        if (coupon.IsBOGO) {
            switch (coupon.BOGOSelectionTypeID) {
                case 1:  //Auto select - Discount the lowest priced item
                    var vItem1 = this._qualifyHighestItemInCategory(coupon);
                    if (vItem1) {
                        vItem1.cPrice = 0.00; // don't add coupon item price of the bogos first item
                        vItem1.oPrice = 0.00;
                        qualifiedGroupList.push(vItem1);
                        var vItem2 = this._qualifyLowestItemInCategory(coupon);
                        if (vItem2) {
                            qualifiedGroupList.push(vItem2);
                            return qualifiedGroupList;
                        } else {
                            let oItem = aOLO.Order.Items[vItem1.ItemArrayIndex];
                            if (oItem.DiscountedMarkedQuantity > 0)
                                oItem.DiscountedMarkedQuantity = 0;
                        }
                    }
                    break;
                case 2: //Auto select - Discount the second highest priced item
                    vItem1 = this._qualifyHighestItemInCategory(coupon);
                    if (vItem1) {
                        vItem1.cPrice = 0.00; // don't add coupon item price of the bogos first item
                        vItem1.oPrice = 0.00;
                        qualifiedGroupList.push(vItem1);
                        vItem2 = this._qualifyHighestItemInCategory(coupon);
                        if (vItem2) {
                            qualifiedGroupList.push(vItem2);
                            return qualifiedGroupList;
                        } else {
                            let oItem = aOLO.Order.Items[vItem1.ItemArrayIndex];
                            if (oItem.DiscountedMarkedQuantity > 0)
                                oItem.DiscountedMarkedQuantity = 0;
                        }
                    }
                    break;
                case 3: //Auto select - Discount the highest priced item
                    vItem1 = this._qualifyHighestItemInCategory(coupon);
                    if (vItem1) {
                        qualifiedGroupList.push(vItem1);
                        vItem2 = this._qualifyHighestItemInCategory(coupon);
                        if (vItem2) {
                            vItem2.cPrice = 0.00;
                            vItem2.oPrice = 0.00;
                            qualifiedGroupList.push(vItem2);
                            return qualifiedGroupList;
                        } else {
                            let oItem = aOLO.Order.Items[vItem1.ItemArrayIndex];
                            if (oItem.DiscountedMarkedQuantity > 0)
                                oItem.DiscountedMarkedQuantity = 0;
                        }
                    }
                    break;
            }
        } else {
            for (const cpnItmCat of coupon.ItemCategories) {
                for (const [index, oItem] of aOLO.Order.Items.entries()) {
                    if (!oItem.IsRemoved && ((oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity) < oItem.Quantity) || coupon.AllowOtherCoupons) {
                        let mItem = aOLO.data.Items.find(x => x.ItemId === oItem.ItemId) || null;

                        if (mItem?.ItemCategoryId !== cpnItmCat.CategoryId)
                            continue;

                        for (let k = 0; k < oItem.Quantity - oItem.DiscountedQuantity; k++) {
                            if (oItem.HalfCount > 1)
                                this._markAllHalves(oItem.ItemKey);
                            else
                                oItem.DiscountedMarkedQuantity += 1;

                            const vItem = {
                                ItemArrayIndex: index,
                                QTYIndex: oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity + 1,
                                cPrice: 0.00,
                                oPrice: (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price,
                                ItemKey: oItem.ItemKey,
                                HalfIndex: oItem.HalfIndex,
                                ItemRecID: oItem.ItemRecId,
                                AddOn: 0.00,
                                BeforeTaxDiscount: 0.0,
                                AfterTaxDiscount: 0.0
                            };
                            qualifiedGroupList.push(vItem);
                        }
                    }
                }
            }
        }
        return qualifiedGroupList;
    }

    private _qualifyHighestItemInCategory = (coupon: IDataCoupon): IvItem | null => {
        let maxPrice = 0.0;
        let itemIndex = -1;
        for (const cat of coupon.ItemCategories) {
            for (const [index, oItem] of aOLO.Order.Items.entries()) {
                if (((oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity) >= oItem.Quantity) && !coupon.AllowOtherCoupons)
                    continue;

                let mItem = aOLO.data.Items.find(x => x.ItemId === oItem.ItemId);
                if (mItem?.ItemCategoryId !== cat.CategoryId)
                    continue;
                const oPrice = (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price;

                if (oPrice > maxPrice) {
                    maxPrice = oPrice;
                    itemIndex = index;
                }
            }
        }

        if (itemIndex > -1) {
            let oItem = aOLO.Order.Items[itemIndex];
            if (oItem.HalfCount > 1)
                this._markAllHalves(oItem.ItemKey);
            else
                oItem.DiscountedMarkedQuantity += 1;

            const vItem = {
                ItemArrayIndex: itemIndex,
                QTYIndex: oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity + 1,
                oPrice: (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price,
                cPrice: 0.00,
                ItemKey: oItem.ItemKey,
                HalfIndex: oItem.HalfIndex,
                ItemRecID: oItem.ItemRecId,
                AddOn: 0.00,
                BeforeTaxDiscount: 0.0,
                AfterTaxDiscount: 0.0
            };
            return vItem;
        }
        return null;
    }

    private _qualifyLowestItemInCategory = (coupon: IDataCoupon): IvItem | null => {
        let minPrice = 1000000.0;
        let itemIndex = -1;
        for (const cat of coupon.ItemCategories) {
            for (const [index, oItem] of aOLO.Order.Items.entries()) {
                if (((oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity) >= oItem.Quantity) && !coupon.AllowOtherCoupons)
                    continue;

                let mItem = aOLO.data.Items.find(x => x.ItemId === oItem.ItemId);
                if (mItem?.ItemCategoryId !== cat.CategoryId)
                    continue;

                let oPrice = 0.0;
                if (oItem.HalfCount > 1)
                    oPrice = oItem.HalfHalfPrice;
                else
                    oPrice = oItem.Price;

                if (oPrice < minPrice) {
                    minPrice = oPrice;
                    itemIndex = index;
                }
            }
        }
        if (itemIndex > -1) {
            let oItem = aOLO.Order.Items[itemIndex];
            if (oItem.HalfCount > 1)
                this._markAllHalves(oItem.ItemKey);
            else
                oItem.DiscountedQuantity += 1;

            const vItem = {
                ItemArrayIndex: itemIndex,
                QTYIndex: oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity + 1,
                cPrice: 0.00,
                oPrice: (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price,
                ItemKey: oItem.ItemKey,
                HalfIndex: oItem.HalfIndex,
                ItemRecID: oItem.ItemRecId,
                AddOn: 0.00,
                BeforeTaxDiscount: 0.0,
                AfterTaxDiscount: 0.0
            };
            return vItem;
        }
        return null;
    }

    private _qualifyAllItemsInCategory = (coupon: IDataCoupon): IvItem[] => {
        let qualifiedGroupList: IvItem[] = [];

        for (const cat of coupon.ItemCategories) {
            for (const [index, oItem] of aOLO.Order.Items.entries()) {
                if ((((oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity) >= oItem.Quantity) && !coupon.AllowOtherCoupons) || oItem.IsRedeemed)
                    continue;

                let mItem = aOLO.data.Items.find(x => x.ItemId === oItem.ItemId);
                if (mItem?.ItemCategoryId !== cat.CategoryId)
                    continue;

                for (let k = 0; k < oItem.Quantity - oItem.DiscountedQuantity; k++) {
                    if (oItem.HalfCount > 1)
                        this._markAllHalves(oItem.ItemKey);
                    else
                        oItem.DiscountedMarkedQuantity += 1;

                    const vItem = {
                        ItemArrayIndex: index,
                        QTYIndex: oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity + 1,
                        cPrice: 0.00,
                        oPrice: (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price,
                        ItemKey: oItem.ItemKey,
                        HalfIndex: oItem.HalfIndex,
                        ItemRecID: oItem.ItemRecId,
                        AddOn: 0.00,
                        BeforeTaxDiscount: 0.0,
                        AfterTaxDiscount: 0.0
                    };
                    qualifiedGroupList.push(vItem);
                }
            }
        }
        return qualifiedGroupList;
    }

    public QualifyCouponItems = (coupon: IDataCoupon, autoApplying: boolean, qualifyAnyQTY: boolean, clearMarkedQTY: boolean, returnItems: boolean = false): IvItem[] => {
        const BOGOType = {
            Lowest: 1,
            Second_Highest: 2,
            Highest: 3
        };

        let QualifyingGroup = -1;
        let qualifiedGroupList: IvItem[] = [];
        let groupCount = 0;
        let vItem: IvItem | null = null;
        let vItemList: IvItem[] | null = null;

        const couponGroups = Array.from(new Set(coupon.Items.map(x => x.GroupIndex)));
        for (const group of couponGroups) {
            groupCount += 1;
            QualifyingGroup = group;
            if (coupon.IsBOGO && groupCount === 1) {
                switch (coupon.BOGOSelectionTypeID) {
                    case BOGOType.Lowest:  //Auto select - Discount the lowest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(coupon, QualifyingGroup, BOGOType.Highest);
                        break;
                    case BOGOType.Second_Highest: //Auto select - Discount the second highest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(coupon, QualifyingGroup, BOGOType.Highest);
                        break;
                    case BOGOType.Highest: //Auto select - Discount the highest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(coupon, QualifyingGroup, BOGOType.Highest);
                        break;
                }
                if (vItem) {
                    vItem.cPrice = 0.00; // don't add coupon item price of the bogos first item
                    vItem.oPrice = 0.00;
                    qualifiedGroupList.push(vItem);
                }
            } else if (coupon.IsBOGO && groupCount === 2) {
                switch (coupon.BOGOSelectionTypeID) {
                    case BOGOType.Lowest:  //Auto select - Discount the lowest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(coupon, QualifyingGroup, BOGOType.Lowest);
                        break;
                    case BOGOType.Second_Highest: //Auto select - Discount the second highest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(coupon, QualifyingGroup, BOGOType.Second_Highest);
                        break;
                    case BOGOType.Highest: //Auto select - Discount the highest priced item
                        vItem = this._qualifyCouponItemsGroupByBOGOType(coupon, QualifyingGroup, BOGOType.Highest);
                        break;
                }
                if (vItem) {
                    if (vItem.HalfIndex > 0) {
                        const qualifiedHalves = this._qualifyAllHalfs(vItem);
                        qualifiedGroupList = qualifiedGroupList.concat(qualifiedHalves);
                    } else
                        qualifiedGroupList.push(vItem);
                    vItemList = qualifiedGroupList;
                }
            } else {
                vItemList = this._qualifyCouponItemsGroupQTY(coupon, QualifyingGroup, autoApplying, qualifyAnyQTY);
                if (vItemList) {
                    for (const item of vItemList) {
                        vItem = item;

                        if (item.HalfIndex > 0)
                            qualifiedGroupList = qualifiedGroupList.concat(this._qualifyAllHalfs(item));
                        else
                            qualifiedGroupList.push(item);
                    }
                } else
                    vItem = null;
            }

            if (!vItem && !returnItems)
                break;
        }

        // if last group has qualified or there was no coupon items to qualified return the list otherwise claer marked items and return nothing;
        if (vItemList || QualifyingGroup === -1) {
            if (returnItems)
                aOLO.Order.Items.filter(x => x.DiscountedMarkedQuantity > 0).forEach(x => x.DiscountedMarkedQuantity = 0);
            return qualifiedGroupList;
        } else {
            if (clearMarkedQTY) {
                for (const oItem of aOLO.Order.Items) {
                    if (oItem.DiscountedMarkedQuantity > 0)
                        oItem.DiscountedMarkedQuantity = 0;
                }
            }
            if (returnItems)
                return qualifiedGroupList;
            return [];
        }
    }

    private _qualifyCouponItemsGroupByBOGOType = (coupon: IDataCoupon, group: number, priceType: number): IvItem | null => {
        let oItemIndexes: IQualifyItemExt[] = [];
        let finalItem: IQualifyItemExt = {
            Index: -1,
            QTYIndex: -1,
            CouponItemIndex: -1
        };

        let groupItems = coupon.Items.filter(cpn => cpn.GroupIndex == group);
        // important don't change this to forEach
        for (let i = 0; i < groupItems.length; i++) {
            let cItem = groupItems[i];
            let qItem = this._findCouponItem(coupon, cItem.ItemId, cItem.SizeId, cItem.ModifierCount);
            if (qItem.Index > -1) {
                qItem.CouponItemIndex = i;
                oItemIndexes.push(qItem);
                i--; // important this make to look for same item again
            }
        }


        //for (const [index, cItem] of groupItems.entries()) {
        //    let qItem = this._findCouponItem(coupon, cItem.ItemId, cItem.SizeId, cItem.ModifierCount);
        //    if (qItem.Index > -1) {
        //        qItem.CouponItemIndex = index;
        //        oItemIndexes.push(qItem);
        //    }
        //}

        if (oItemIndexes.length > 0) {
            let iIndex = -1;
            let finalPrice = (priceType == 1) ? Number.MAX_VALUE : 0.00;
            let highestIndex: IQualifyItemExt | null = null;

            if (priceType == 2)
                highestIndex = oItemIndexes.find(x => aOLO.Order.Items[x.Index].Price == Math.max(...oItemIndexes.map(x => aOLO.Order.Items[x.Index].Price))) || null;

            for (const [index, oItemIndex] of oItemIndexes.entries()) {
                const item = aOLO.Order.Items[oItemIndex.Index];
                const iPrice = item.HalfCount > 1 ? item.HalfHalfPrice : item.Price;

                if (priceType == 1 && iPrice < finalPrice) {
                    finalPrice = iPrice;
                    finalItem = oItemIndex;
                    iIndex = index;
                } else if (priceType == 2) {
                    if (oItemIndexes.length > 1 && highestIndex && (oItemIndex.Index != highestIndex.Index || oItemIndex.QTYIndex != highestIndex.QTYIndex) && iPrice > finalPrice) {
                        finalPrice = iPrice;
                        finalItem = oItemIndex;
                        iIndex = index;
                    } else if (oItemIndexes.length - index == 1) {
                        finalItem = oItemIndex;
                        iIndex = index;
                    }
                } else if (priceType == 3 && iPrice > finalPrice) {
                    finalPrice = iPrice;
                    finalItem = oItemIndex;
                    iIndex = index;
                }
            }

            for (const [index, oItemIndex] of oItemIndexes.entries()) {
                if (index != iIndex) {
                    let item = aOLO.Order.Items[oItemIndex.Index];
                    if (item.HalfCount > 1)
                        this._unMarkAllHalves(item.ItemKey);
                    else
                        item.DiscountedMarkedQuantity -= 1;
                }
            }
        }

        if (finalItem.Index > -1) {
            const cItem = groupItems[finalItem.CouponItemIndex];
            return this._getQualifiedCouponItem(finalItem, cItem.ItemId, cItem.SizeId, cItem.ModifierCount, cItem.AddOnPrice);
        }
        return null;
    }

    private _getQualifiedCouponItem = (qItem: IQualifyItem, cItemID: number, cSizeID: number, cModifierCount: number, cAddOnPrice: number): IvItem | null => {
        if (qItem.Index <= -1)
            return null;

        const oItem = aOLO.Order.Items[qItem.Index];
        const vItem = {
            ItemArrayIndex: qItem.Index,
            QTYIndex: qItem.QTYIndex,
            cPrice: this._priceCouponItem(cItemID, cSizeID, cModifierCount),
            oPrice: oItem.Price, //(oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price, ;BUG #36324 (Commented old code)
            ItemKey: oItem.ItemKey,
            HalfIndex: oItem.HalfIndex,
            HalfCount: oItem.HalfCount,
            ItemRecID: oItem.ItemRecId,
            AddOn: cAddOnPrice,
            BeforeTaxDiscount: 0.0,
            AfterTaxDiscount: 0.0
        };
        return vItem;
    }

    private _priceCouponItem = (itemID: number, sizeID: number, modifierCount: number): number => {
        const mItem = OnlineOrderingUtil.GetItem(itemID, aOLO.data.Items);
        if (!mItem)
            return 0.00;

        const matchingPrice = mItem.Prices.find(price => price.SizeId === sizeID);
        if (!matchingPrice)
            return 0.00;

        let price = Util.Float2(matchingPrice.Price) + (Util.Float2(matchingPrice.PerModifierPrice) * modifierCount);
        price = Math.max(price, Util.Float2(matchingPrice.MinimumPrice));
        return price;
    }

    private _isItemEligibleForCoupon = (oItem: IOrderItem, itemID: number, sizeID: number, modifierCount: number, coupon: IDataCoupon): boolean => {
        return ((oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity) < oItem.Quantity || (coupon.AllowOtherCoupons && oItem.DiscountedMarkedQuantity < oItem.Quantity))
            && oItem.ItemId === itemID
            && oItem.SizeId === sizeID
            && (!coupon.ExactMatch || (coupon.ExactMatch && this._getItemPricedModifierCount(oItem) >= modifierCount))
            && (!coupon.AllowOtherCoupons || (coupon.AllowOtherCoupons && !this._checkSameCouponAppliedQtyTimes(oItem, coupon)));
    };

    private _checkSameCouponAppliedQtyTimes = (oItem: IOrderItem, coupon: IDataCoupon): boolean => {
        let itemCoupons = aOLO.Order.ItemsCoupons.filter(iCop => iCop.ItemKey == oItem.ItemKey && iCop.HalfIndex == oItem.HalfIndex && iCop.ItemRecId == oItem.ItemRecId);
        let count = 0;
        itemCoupons.forEach((iCpn) => {
            if (aOLO.Order.Coupons.find(x => x.CouponKey == iCpn.CouponKey && x.CouponId == coupon.CouponId)) {
                count++;
            }
        });
        return (count >= oItem.Quantity);
    }

    private _updateQualifyingItem = (qItem: IQualifyItemExt, oItem: IOrderItem, i: number): IQualifyItemExt => {
        const updatedQItem: IQualifyItemExt = {
            Index: i,
            QTYIndex: oItem.DiscountedQuantity + oItem.DiscountedMarkedQuantity + 1,
            CouponItemIndex: qItem.CouponItemIndex
        };
        if (oItem.HalfCount > 1) {
            this._markAllHalves(oItem.ItemKey);
        } else {
            oItem.DiscountedMarkedQuantity += 1;  // Notice: This modifies the passed oItem directly.
        }
        return updatedQItem;
    };

    private _checkCouponAppliedToItem = (oItem: IOrderItem, applyingCouponKeys: number[]): boolean => {
        return aOLO.Order.ItemsCoupons.some(x => x.ItemKey == oItem.ItemKey && applyingCouponKeys.includes(x.CouponKey));
    }

    private _findCouponItem = (coupon: IDataCoupon, itemID: number, sizeID: number, modifierCount: number): IQualifyItemExt => {
        let qItem: IQualifyItemExt = {
            Index: -1,
            QTYIndex: -1,
            CouponItemIndex: -1
        };
        const applyingCouponKeys = aOLO.Order.Coupons.filter(c => c.CouponId == coupon.CouponId).map(c => c.CouponKey) || [];
        for (const [index, oItem] of aOLO.Order.Items.entries()) {
            if (this._isItemEligibleForCoupon(oItem, itemID, sizeID, modifierCount, coupon)) {
                qItem = this._updateQualifyingItem(qItem, oItem, index);
                break;
            }
        }
        return qItem;
    };


    private _getItemPricedModifierCount = (oItem: IOrderItem): number => {
        let mCount = 0;
        for (const mod of oItem.Modifiers) {
            if (mod.CountForPricing || mod.CountForPricingByItself)
                mCount += 1;
        }
        return mCount;
    }

    private _qualifyAllHalfs = (vItem: IvItem): IvItem[] => {
        let vItems: IvItem[] = [];
        const halfCount = vItem.HalfCount || 1;
        const originalCPrice = vItem.cPrice;
        const cPrice = Util.Float2(vItem.cPrice / halfCount);
        const diffcPrice = Util.Float2(originalCPrice - (cPrice * halfCount));
        vItem.cPrice = cPrice + diffcPrice;
        //vItem.oPrice = Util.Float2(vItem.oPrice / halfCount); ;BUG #36324 (Commented old code)
        vItems.push(vItem);

        let discountedMarkedQTY = 0;
        for (const oItem of aOLO.Order.Items) {
            if (vItem.ItemKey === oItem.ItemKey && vItem.HalfIndex === oItem.HalfIndex) {
                discountedMarkedQTY = oItem.DiscountedMarkedQuantity;
                break;
            }
        }

        for (const [index, oItem] of aOLO.Order.Items.entries()) {
            if (vItem.ItemKey === oItem.ItemKey && vItem.HalfIndex !== oItem.HalfIndex) {
                oItem.DiscountedMarkedQuantity = discountedMarkedQTY;
                const vItm = {
                    ItemArrayIndex: index,
                    QTYIndex: vItem.QTYIndex,
                    cPrice: cPrice,
                    oPrice: vItem.oPrice,
                    ItemKey: oItem.ItemKey,
                    HalfIndex: oItem.HalfIndex,
                    HalfCount: oItem.HalfCount,
                    ItemRecID: oItem.ItemRecId,
                    AddOn: vItem.AddOn,
                    BeforeTaxDiscount: 0.0,
                    AfterTaxDiscount: 0.0,
                };
                vItems.push(vItm);
            }
        }

        return vItems;
    }

    private _qualifyCouponItemsGroupQTY = (coupon: IDataCoupon, group: number, autoApplying: boolean, qualifyAnyQTY: boolean): IvItem[] | null => {
        let vItems: IvItem[] = [];
        const itemsToQualify = coupon.Items.filter(item => item.GroupIndex === group);
        for (const cItem of itemsToQualify) {
            const qty = cItem.Quantity;
            for (let j = 0; j < qty; j++) {
                const vItem = this._qualifyCouponItemsGroup(coupon, group, cItem.ItemIndex, autoApplying);
                if (vItem) {
                    vItems.push(vItem);
                } else if (!qualifyAnyQTY) {
                    vItems.forEach(vItem => {
                        let oItem = aOLO.Order.Items.find(x => x.ItemKey === vItem.ItemKey);
                        if (oItem) {
                            if (oItem.HalfCount > 1)
                                this._unMarkAllHalves(oItem.ItemKey);
                            else
                                oItem.DiscountedMarkedQuantity -= 1;
                        }
                    });
                    vItems = [];
                    break;
                }
            }
            if (vItems.length > 0 && !qualifyAnyQTY)
                return vItems;
        }

        return vItems.length > 0 ? vItems : null;
    }

    private _qualifyCouponItemsGroup = (coupon: IDataCoupon, group: number, itemIndex: number, autoApplying: boolean): IvItem | null => {
        let qItem: IQualifyItem = { Index: -1, QTYIndex: -1 };
        let vItem;
        const cItem = coupon.Items.find(x => x.GroupIndex === group && x.ItemIndex === itemIndex);
        if (cItem) {
            qItem = this._findCouponItem(coupon, cItem.ItemId, cItem.SizeId, cItem.ModifierCount);
            if (qItem.Index === -1 && cItem.ModifierCount > 0 && ((!autoApplying && !coupon.ExactMatch) || (autoApplying && !coupon.AutoApplyExactMatch))) {
                let mCount = cItem.ModifierCount;
                while (mCount > 0) {
                    mCount -= 1;
                    qItem = this._findCouponItem(coupon, cItem.ItemId, cItem.SizeId, mCount);
                    if (qItem.Index > -1)
                        break;
                }
            }

            if (qItem.Index > -1)
                vItem = this._getQualifiedCouponItem(qItem, cItem.ItemId, cItem.SizeId, cItem.ModifierCount, cItem.AddOnPrice);
        }

        return vItem || null;
    }

    private _qualifyAllNotDiscountedItems = (coupon?: IDataCoupon): IvItem[] => {
        if (coupon && !coupon.AllowOtherCoupons && aOLO.Order.Coupons?.length > 0)
            return [];

        let qualifiedGroupList: IvItem[] = [];
        for (const [index, oItem] of aOLO.Order.Items.entries()) {
            if (oItem.Quantity <= oItem.DiscountedQuantity)
                continue;

            oItem.DiscountedMarkedQuantity = oItem.Quantity - oItem.DiscountedQuantity;
            for (let j = 0; j < oItem.Quantity; j++) {
                const vItem = {
                    ItemArrayIndex: index,
                    QTYIndex: j + 1,
                    cPrice: 0.00,
                    oPrice: oItem.Price,
                    ItemKey: oItem.ItemKey,
                    HalfIndex: oItem.HalfIndex,
                    ItemRecID: oItem.ItemRecId,
                    AddOn: 0.00,
                    BeforeTaxDiscount: 0.0,
                    AfterTaxDiscount: 0.0
                };

                if (oItem.HalfCount > 1)
                    vItem.oPrice = oItem.HalfHalfPrice;

                qualifiedGroupList.push(vItem);
            }

        }
        return qualifiedGroupList;
    }

    private _getTotalCouponItemsPrices = (qualifiedList: IvItem[]): ITotalCouponPrice => {
        let couponItemPrice = 0.0;
        let orderItemPrice = 0.0;
        let couponAddOnPrice = 0.0;

        for (const item of qualifiedList) {
            couponItemPrice += Util.Float2(item.cPrice);
            orderItemPrice += Util.Float2(item.oPrice);
            couponAddOnPrice += Util.Float2(item.AddOn);
        }

        return {
            CouponItemPrice: Util.Float2(couponItemPrice),
            OrderItemPrice: Util.Float2(orderItemPrice),
            CouponAddOnPrice: Util.Float2(couponAddOnPrice),
        };
    }

    private _splitDiscount = (qualifiedList: IvItem[], totalDiscountedItemsPrice: number, discountAmount: number, isBeforeTax: boolean, couponTypeID: number): void => {
        let tSplit = 0.00;
        if (isBeforeTax) {
            this._splitDiscountBeforeTax(qualifiedList, totalDiscountedItemsPrice, discountAmount, couponTypeID);
        } else {
            for (let i = 0; i < qualifiedList.length; i++) {
                if (qualifiedList[i].cPrice > 0) {
                    qualifiedList[i].AfterTaxDiscount = Util.Float2((qualifiedList[i].oPrice * discountAmount / totalDiscountedItemsPrice) - qualifiedList[i].AddOn);
                    tSplit += qualifiedList[i].AfterTaxDiscount;
                }
            }
            qualifiedList[qualifiedList.length - 1].AfterTaxDiscount += (discountAmount - tSplit) // this is to clear rounding discrepency
        }
    }

    private _splitDiscountBeforeTax = (qualifiedList: IvItem[], totalDiscountedItemsPrice: number, discountAmount: number, couponTypeID: number): void => {
        const qualifiedListDiscountNotMaxed = [];
        let indexToClearRounding = 0;
        let tSplit = 0.00;
        let discountToRedistribute = 0.00;
        let actualDiscountAmount = discountAmount;
        let updatedTotalDiscountedItemsPrice = totalDiscountedItemsPrice;
        for (let i = 0; i < qualifiedList.length; i++) {
            if (qualifiedList[i].cPrice > 0 ||
                couponTypeID === CouponTypeList.MANAGER_DISCOUNT ||
                couponTypeID === CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER ||
                couponTypeID === CouponTypeList.PERCENTAGE_OFF_ALL_ITEMS_IN_CATEGORY ||
                couponTypeID === CouponTypeList.LOYALTY_DISCOUNT
            ) {
                const oItem = aOLO.Order.Items.find(x => x.ItemKey === qualifiedList[i].ItemKey);
                if (oItem) {
                    const beforeTaxDiscount = Util.Float2((qualifiedList[i].oPrice * discountAmount / totalDiscountedItemsPrice) - qualifiedList[i].AddOn);
                    //currentItemDiscount takes into account discounts applied previously from other coupons and discounts currently being applied through discount redistribution (if applicable)
                    const currentItemDiscount = oItem.AfterTaxDiscount + oItem.BeforeTaxDiscount + qualifiedList[i].AfterTaxDiscount + qualifiedList[i].BeforeTaxDiscount;
                    //apply the discount to the current' item's discount only if it hasn't surprassed item price
                    if (currentItemDiscount < qualifiedList[i].oPrice) {
                        qualifiedList[i].BeforeTaxDiscount += (currentItemDiscount + beforeTaxDiscount) > qualifiedList[i].oPrice ? qualifiedList[i].oPrice - currentItemDiscount : beforeTaxDiscount;
                        tSplit += beforeTaxDiscount;
                        qualifiedListDiscountNotMaxed.push(qualifiedList[i]);
                        indexToClearRounding = i;
                    } else {
                        discountToRedistribute += beforeTaxDiscount;
                        updatedTotalDiscountedItemsPrice -= qualifiedList[i].oPrice;
                        actualDiscountAmount = actualDiscountAmount - beforeTaxDiscount;
                    }
                }
            }
        }
        qualifiedList[indexToClearRounding].BeforeTaxDiscount += Util.Float2(actualDiscountAmount - tSplit) // this is to clear rounding discrepency
        if (discountToRedistribute > 0) {
            this._splitDiscountBeforeTax(qualifiedListDiscountNotMaxed, updatedTotalDiscountedItemsPrice, discountToRedistribute, couponTypeID);
        }
    }

    private _addDiscountsToItems = (qualifiedList: IvItem[]): void => {
        for (const item of qualifiedList) {
            aOLO.Order.Items[item.ItemArrayIndex].BeforeTaxDiscount += item.BeforeTaxDiscount;
            aOLO.Order.Items[item.ItemArrayIndex].AfterTaxDiscount += item.AfterTaxDiscount;
        }
    }

    private _addCouponToOrder = (couponID: number, couponCode: string, discount: number, discountAmount: number, isBeforeTax: boolean, isActive: boolean, allowOtherCoupons: boolean, channelID?: number | null, autoApplying?: boolean, isCampaignCode?: boolean, isPromotionCode?: boolean, rewardId?: number | null, isBankedCurrency?: boolean): number => {
        const oCoupon: IOrderCoupon = {
            CouponKey: (aOLO.Order.Coupons.length === 0) ? 1 : aOLO.Order.Coupons[aOLO.Order.Coupons.length - 1].CouponKey + 1,
            CouponId: couponID,
            CouponCode: couponCode,
            Discount: discount,
            Amount: discountAmount,
            IsBeforeTax: isBeforeTax,
            IsActive: isActive,
            AllowOtherCoupons: allowOtherCoupons,
            IsAdded: false,
            IsRemoved: false,
            AutoApplied: autoApplying || false,
            ChannelID: channelID || null,
            IsPromotionCode: isPromotionCode || false,
            IsCampaignCode: isCampaignCode || false,
            RewardId: rewardId || null,
            IsBankedCurrency: isBankedCurrency || false,
            RedemptionReference: null,
            BasketItemId: null
        };
        aOLO.Order.Coupons.push(oCoupon);
        return oCoupon.CouponKey;
    }

    private _addCouponToItemsCoupon = (qualifiedList: IvItem[], couponKey: number, priority: number, isActive: boolean, allowOtherCoupons: number): void => {
        for (const item of qualifiedList) {
            const itemCoupon = {
                ItemKey: item.ItemKey,
                HalfIndex: item.HalfIndex,
                ItemRecId: item.ItemRecID,
                CouponKey: couponKey,
                QTYIndex: item.QTYIndex,
                BeforeTaxDiscount: item.BeforeTaxDiscount,
                AfterTaxDiscount: item.AfterTaxDiscount,
                DiscountedQuantity: allowOtherCoupons,
                IsActive: isActive,
                Priority: priority,
            };
            aOLO.Order.ItemsCoupons.push(itemCoupon);
        }
    }

    private _markAllHalves = (itemKey: number): void => {
        aOLO.Order.Items.forEach(oItem => {
            if (oItem.ItemKey === itemKey)
                oItem.DiscountedMarkedQuantity += 1;
        });
    }

    private _unMarkAllHalves = (itemKey: number): void => {
        aOLO.Order.Items.forEach(oItem => {
            if (oItem.ItemKey == itemKey)
                oItem.DiscountedMarkedQuantity -= 1;
        });
    }

    private _clearMarkedItems = (): void => {
        aOLO.Order.Items.forEach(oItem => {
            oItem.DiscountedMarkedQuantity = 0;
        });
    }

    private _useMarkedItems = (): void => {
        aOLO.Order.Items.forEach(oItem => {
            oItem.DiscountedQuantity += oItem.DiscountedMarkedQuantity;
            oItem.DiscountedMarkedQuantity = 0;
        });
    }

    public AutoApplyCoupons = async (): Promise<void> => {
        const autoApplyCoupons: IDataCoupon[] = aOLO.data.Coupons.filter(cpn => cpn.IsAutoApply) || [];

        for (const coupon of aOLO.Order.Coupons) {
            if (!coupon.RewardId) {
                const mCoupon = this.GetCoupon(coupon.CouponId, true, aOLO.Order.OrderTypeID, coupon.ChannelID);
                const couponTypesList = [CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER, CouponTypeList.PERCENTAGE_OFF_ALL_ITEMS_IN_CATEGORY, CouponTypeList.MIX_AND_MATCH_PRICE, CouponTypeList.MIX_AND_MATCH_PERCENTAGE];
                if (mCoupon && couponTypesList.includes(mCoupon.CouponTypeId)) {
                    await this.RemoveCouponByKey(coupon.CouponKey, true);
                    const params: IApplyCouponByIdParams = {
                        couponId: coupon.CouponId,
                        couponCode: coupon.CouponCode
                    };
                    await this.ApplyCouponByID(params);
                }
            }

            if (coupon.RewardId) {
                const reward = aOLO.User.LoyaltyData?.Rewards.find(x => x.RewardId == coupon.RewardId);
                if (!reward)
                    continue;

                await aOLOModules.LoyaltyProvider.removeReward(reward);
                await aOLOModules.LoyaltyProvider.addReward(reward);
            } else if (coupon.IsBankedCurrency) {
                const amount = coupon.Amount;
                await aOLOModules.LoyaltyProvider.removeBankedCurrency();
                await aOLOModules.LoyaltyProvider.applyBankedCurrency(amount);
            }
        }

        // Try to Apply Incomplete Coupons
        const tempDiscounts = Util.deepClone(aOLO.Temp.IncompleteDiscounts) as ITempIncompleteDiscount[];
        for (const coupon of tempDiscounts) {
            if (coupon.rewardId) {
                const reward = aOLO.User.LoyaltyData?.Rewards.find(x => x.RewardId == coupon.rewardId);
                if (reward) {
                    await aOLOModules.LoyaltyProvider.addReward(reward);
                    aOLO.Temp.IncompleteDiscounts = aOLO.Temp.IncompleteDiscounts.filter(x => x.rewardId !== coupon.rewardId);
                    OnlineOrderingUtil.GUI_SetOrder_Total(true, aOLO, aOLOModules);
                    break;
                }
            }

            const params: IApplyCouponByIdParams = {
                couponId: coupon.couponID,
                couponCode: coupon.couponCode,
                channelId: coupon.channelID,
                autoApplying: true,
                isCampaignCode: coupon.isCampaignCode,
                isPromotionCode: coupon.isPromotionCode
            };
            const result = await this.ApplyCouponByID(params);
            if (result)
                await this.RemoveIncompleteCouponById(coupon.couponID);
        }

        for (const coupon of autoApplyCoupons) {
            const params: IApplyCouponByIdParams = {
                couponId: coupon.CouponId,
                autoApplying: true,
            };
            await this.ApplyCouponByID(params);
        }
    }

    public RemoveIncompleteCouponById = async (couponId: number): Promise<void> => {
        const index = aOLO.Temp.IncompleteDiscounts.findIndex(cpn => cpn.couponID === couponId);
        if (index == -1)
            return;

        const item = aOLO.Temp.IncompleteDiscounts[index];

        if (item.rewardId) {
            const reward = aOLO.User.LoyaltyData?.Rewards.find(x => x.RewardId == item.rewardId);
            if (reward)
                await aOLOModules.LoyaltyProvider.removeReward(reward);
        }

        if (item.isBankedCurrency) {
            await aOLOModules.LoyaltyProvider.removeBankedCurrency();
        }

        if ((item.isCampaignCode || item.isPromotionCode) && aOLO.User.Offers?.Codes) {
            const offer = aOLO.User.Offers.Codes.find(x => x.CouponCode.toLowerCase() == item.couponCode);
            if (offer)
                offer.Used = false;
        }

        aOLO.Temp.IncompleteDiscounts.splice(index, 1);
        OnlineOrderingUtil.GUI_SetOrder_Total(true, aOLO, aOLOModules);
    }

    public GetCoupon = (couponId: number, checkOTST: boolean, orderTypeID: number | null, channelID?: number | null): IDataCoupon | null => {
        const coupon = aOLO.data.Coupons.find(cpn => cpn.CouponId === couponId);
        if (coupon) {
            coupon.ChannelID = channelID || null;
        }
        if (checkOTST && coupon?.OrderTypeSubTypes && coupon?.OrderTypeSubTypes.length > 0)
            return (coupon.OrderTypeSubTypes.find(x => x.OrderTypeId === orderTypeID) ? coupon : null);

        return coupon || null;
    }

    public CheckCouponOTST = (coupon: IDataCoupon, orderTypeID: number): boolean => {
        return !!coupon.OrderTypeSubTypes?.find(x => x.OrderTypeId === orderTypeID);
    }

    public VerifyCouponDate = (coupon: IDataCoupon): boolean => {
        if (coupon.StartDate == null && coupon.EndDate == null)
            return true;

        const tempOrderDate = aOLO.Order.FutureDate ? aOLO.Order.FutureDate : aOLO.Order.OrderStartTime;
        const orderDate = new Date(new Date(Util.deepClone(tempOrderDate)).setHours(0, 0, 0, 0));
        if (orderDate.getTime() >= new Date(coupon.StartDate?.replaceAll("-", "/") || "").getTime() && orderDate.getTime() <= new Date(coupon.EndDate?.replaceAll("-", "/") || "").getTime())
            return true;

        return false;
    }

    public CheckCouponSCHS = (coupon: IDataCoupon): boolean => {
        if (!coupon.IsScheduled)
            return true;

        let promiseDate;
        let dayOfWeek = 0;
        let promiseMinutes = 0;
        if (aOLO.Order.FutureDate === null) {
            promiseDate = Util.NowStore(aOLO.Temp.TimeOffset);
            const promiseBDate = new Date(Util.GetBUSDate(promiseDate, aOLO));
            dayOfWeek = promiseBDate.getDay();
            promiseMinutes = promiseDate.getMinutes() + (60 * promiseDate.getHours());
        }
        else {
            promiseDate = new Date(aOLO.Order.FutureDate);
            dayOfWeek = promiseDate.getDay();
            promiseMinutes = aOLO.Order.FutureMinute;
        }

        return coupon.Schedules ? coupon.Schedules.some(schedule => {
            return schedule.WeekDayNumber === dayOfWeek && schedule.IsActive && schedule.StartDayMinute <= promiseMinutes && schedule.EndDayMinute >= promiseMinutes;
        }) : true;
    }

    public CheckCouponHasQualifyingItems = async (couponId: number, couponCode?: string | null, orderID?: number | null, reward?: IUserLoyaltyReward | null, channelID?: number | null, isCampaignCode?: boolean, isPromotionCode?: boolean, offer?: IUserCampaignCodesData | null): Promise<void> => {
        let coupon = this.GetCoupon(couponId, true, aOLO.Order.OrderTypeID, channelID);

        if (!coupon && reward) {
            await this._loadCouponByID(reward.CouponId);
            coupon = this.GetCoupon(couponId, true, aOLO.Order.OrderTypeID, channelID);
        }

        if (!coupon)
            return;

        if (aOLOModules.LoyaltyProvider.onlyOneDiscountAllowed(reward?.RewardId || null))
            return;

        if (coupon.Items.length > 0) {
            const params: IApplyCouponByIdParams = {
                couponId: couponId,
                showApplyMsg: true,
                couponCode: couponCode ? couponCode : null,
                isCampaignCode: isCampaignCode,
                isPromotionCode: isPromotionCode,
                rewardId: reward ? reward.RewardId : null
            };
            const applied = await this.ApplyCouponByID(params);

            if (applied) {
                await this.RemoveIncompleteCouponById(couponId);
            } else {
                const mealDealFunctions = {
                    DeleteItem: this._functions.DeleteItem,
                    GUI_SetOrder: this._functions.GUI_SetOrder,
                    Item_AddToOrder: this._functions.Item_AddToOrder
                };

                aOLO.Dialog.MealDeal = new MealDeal(mealDealFunctions, coupon, (couponCode ? couponCode : null), (orderID ? orderID : null), (reward ? reward.RewardId : null), isCampaignCode, isPromotionCode);

                if (aOLO.Modules.DataLayer)
                    aOLO.Modules.DataLayer.mealDealScrollListeners();
            }
        } else {
            if (couponCode) {
                const result = await this.ApplyCoupon(coupon, couponCode, false, true, undefined, undefined, isCampaignCode, isPromotionCode);
                if (!result)
                    this._functions.addIncompleteCouponToCart(coupon.CouponId, couponCode, aOLO.Temp.IncompleteDiscounts, aOLO, aOLOModules, channelID, isCampaignCode, isPromotionCode);
                else {
                    if (offer)
                        offer.Used = true;
                }
            } else if (orderID) {
                const result = await this.ApplyCoupon(coupon, `bx${orderID}`, false, true, undefined, undefined, isCampaignCode, isPromotionCode);
                if (!result)
                    this._functions.addIncompleteCouponToCart(coupon.CouponId, '', aOLO.Temp.IncompleteDiscounts, aOLO, aOLOModules, channelID, isCampaignCode, isPromotionCode);
            } else if (reward) {
                const params: IApplyCouponByIdParams = {
                    couponId: couponId,
                    showApplyMsg: true,
                    isCampaignCode: isCampaignCode,
                    isPromotionCode: isPromotionCode,
                    rewardId: reward.RewardId
                };
                const result = await this.ApplyCouponByID(params);
                if (result)
                    reward.Used = true;
                else
                    this._functions.addIncompleteCouponToCart(coupon.CouponId, "", aOLO.Temp.IncompleteDiscounts, aOLO, aOLOModules, channelID, isCampaignCode, isPromotionCode, reward.RewardId);
            } else {
                const params: IApplyCouponByIdParams = {
                    couponId: couponId,
                    showApplyMsg: true,
                    couponCode: couponCode ? couponCode : null,
                    isCampaignCode: isCampaignCode,
                    isPromotionCode: isPromotionCode
                };
                const result = await this.ApplyCouponByID(params);
                if (!result)
                    this._functions.addIncompleteCouponToCart(coupon.CouponId, "", aOLO.Temp.IncompleteDiscounts, aOLO, aOLOModules, channelID, isCampaignCode, isPromotionCode);
            }
        }

        if (aOLO.Modules.DataLayer)
            aOLO.Modules.DataLayer.select_promotion(coupon, !!aOLO.specialCode, couponCode);
    }

    private _loadCouponByID = async (couponId: number): Promise<void> => {
        const data = {
            CID: couponId,
            SKY: aOLO.storeInfo.StoreKey
        };
        const response = await aOLO.Modules.OnlineOrderingService.getCouponById(data, aOLO);

        if (response.success) {
            aOLO.data.Coupons.push(this._mapCoupon(response.coupon));
        } else if (!response.serverError)
            DialogCreators.messageBoxOk(Names("UnableToRetrieveReward"), aOLO.buttonHoverStyle);
    }

    private _mapCoupon = (couponJSON: string): IDataCoupon => {
        const cpn = JSON.parse(couponJSON);
        const coupon = {
            CouponId: cpn.CPID,
            CouponTypeId: cpn.CPTID,
            AllowOtherCoupons: cpn.AOC,
            AutoApplyExactMatch: cpn.AAEXCMCH,
            BackgroundColor: cpn.TBGC,
            BOGOSelectionTypeID: cpn.BGSTID,
            Descriptions: cpn.DSCRs,
            Discount: cpn.DIC,
            ExactMatch: cpn.EXCMCH,
            ImageURL: cpn.IURL,
            IsBeforeTax: cpn.IBTX,
            IsBOGO: cpn.BOGO,
            IsHiddenOnline: cpn.IHDN,
            IsPriceReducer: cpn.IPR,
            IsProfileRequired: cpn.IOPR,
            IsScheduled: cpn.SCH,
            IsVerificationNeeded: cpn.IVN,
            IsAutoApply: cpn.IAA,
            IsAppOnly: false,
            LimitsPerOrder: cpn.LPO,
            MaximumDiscountAmount: cpn.MXAMT,
            MinimumItemQuantity: cpn.MINITMQTY,
            MinimumOrderAmount: cpn.MIAMT,
            CalculationMethodID: cpn.CALCID,
            Names: cpn.NAMs,
            Priority: cpn.PRITY,
            WebCategoryId: cpn.WCID,
            StartDate: cpn.SDATE,
            EndDate: cpn.EDATE,
            Codes: cpn.CCODS?.map((cd: any) => ({
                CouponCode: cd.CCOD
            })) || [],
            Items: cpn.CITMS?.map((itm: any) => ({
                AddOnPrice: itm.AOPRC,
                GroupIndex: itm.GRP,
                ItemId: itm.IID,
                ItemIndex: itm.IIDX,
                ModifierCount: itm.MCNT,
                Quantity: itm.QTY,
                SizeId: itm.SZID
            })) || [],
            ItemCategories: cpn.CITMCATS?.map((cat: any) => ({
                CategoryId: cat.CATID
            })) || [],
            OrderTypeSubTypes: cpn.OTSTS?.map((ot: any) => ({
                OrderTypeId: ot.OT,
                OrderTypeSubId: ot.OST
            })) || [],
            Schedules: cpn.SCHS?.map((sch: any) => ({
                WeekDayNumber: sch.WKDNO,
                IsActive: sch.ISACT,
                StartDayMinute: sch.STRTMIN,
                EndDayMinute: sch.ENDMIN
            })) || [],
            Upgrades: cpn.UGRDS?.map((upg: any) => ({
                BuySizeId: upg.BSZID,
                PriceSizeId: upg.PSZID,
                UpgradeIndex: upg.UGRDIDX
            })) || []
        };

        return coupon;
    }

    public ApplyCouponByCode = async (couponCode: string, isOffer: boolean = false, isPromotion: boolean = false, isCampaign: boolean = false): Promise<void> => {
        if (couponCode === "") {
            DialogCreators.messageBoxOk(Names("InvalidCouponCode"), aOLO.buttonHoverStyle);
            return;
        }

        if (aOLOModules.LoyaltyProvider.onlyOneDiscountAllowed(null))
            return;

        if (this.IsCouponNotAllowedInFundraiser())
            return;

        couponCode = couponCode.toLowerCase();

        // If coupon code from User Offers
        if (isPromotion) {
            await this._applyCouponByPromotionCode(couponCode, isOffer, true);
            return;
        }

        // If coupon code from User Offers
        if (isCampaign) {
            await this._applyCouponByCampaignCode(couponCode, isOffer, true);
            return;
        }

        const tempOrderDate = aOLO.Order.FutureDate ? aOLO.Order.FutureDate : aOLO.Order.OrderStartTime;
        const orderDate = new Date(new Date(Util.deepClone(tempOrderDate)).setHours(0, 0, 0, 0));
        // Check if normal coupon code, advertisement code, or channel code in that order
        const cpn = await this._getCouponByCode(couponCode, orderDate, aOLO.storeInfo.StoreID);
        if (cpn) {
            this._applyCouponByCode_Continue(cpn, couponCode, false, false, null);
            return;
        }

        let found = false;

        // If deep link campaign or promotion coupon code
        if (!found)
            found = await this._getCouponByCloudDeepLink(couponCode);

        // Check if coupon is a single use coupon
        if (!found) {
            const response = await this._validateSingleUseCoupon(couponCode);
            if (response.success) {
                if (response.orderNo && response.orderNo !== 0)
                    aOLO.Order.OrderNo = response.orderNo;

                if (response.orderId)
                    aOLO.Order.OrderID = response.orderId;

                found = await this._applyCouponByPromotionCode(couponCode, isOffer, false, true);
            } else {
                if (response.message == "Promotion has been redeemed.") {
                    DialogCreators.messageBoxOk(Names("CouponRedeemed"), aOLO.buttonHoverStyle);
                    return;
                }
                if (response.message == "Promotion cannot be applied multiple times.") {
                    DialogCreators.messageBoxOk(Names("CouponCannotApplyMultiple"), aOLO.buttonHoverStyle);
                    return;
                }
            }
        }

        // Check if coupon code is a promotion
        if (!found)
            found = await this._applyCouponByPromotionCode(couponCode, isOffer, false);

        // Check if coupon code is a campaign
        if (!found)
            found = await this._applyCouponByCampaignCode(couponCode, isOffer, false);

        if (!found)
            DialogCreators.messageBoxOk(Names("InvalidCouponCode"), aOLO.buttonHoverStyle);
    }

    public IsCouponNotAllowedInFundraiser(): boolean {
        const fundraiser = aOLO.data.Fundraisers.find((fund) => fund.FundraiserId == aOLO.Order.Fundraiser?.ID);
        if (aOLO.Order.Fundraiser && fundraiser && !fundraiser.IsCouponAllowed) {
            DialogCreators.messageBoxOk(Names("CouponCodeErrorWithFundraiser").replace("??", fundraiser.Name), aOLO.buttonHoverStyle);
            return true;
        }
        return false;
    }

    private async _getCouponByCloudDeepLink(couponCode: string): Promise<boolean> {
        if (aOLO.CloudCoupon?.CouponCode.toLowerCase() !== couponCode)
            return false;

        if (aOLO.CloudCoupon.IsCampaignCode) {
            const coupon = await this._getCouponByCampaignCode(aOLO.CloudCoupon.CouponCode, aOLO.CloudCoupon.StoreId, true);
            if (coupon) {
                this._applyCouponByCode_Continue(coupon, couponCode, true, false, null);
                return true;
            }
        } else {
            const coupon = await this._getCouponByPromotionCode(aOLO.CloudCoupon.CouponCode, aOLO.CloudCoupon.StoreId, true);
            if (coupon) {
                this._applyCouponByCode_Continue(coupon, couponCode, false, true, null);
                return true;
            }
        }

        return false;
    }

    private async _validateSingleUseCoupon(couponCode: string) {
        const data = {
            couponCode: couponCode.replace(/-/g, ""),
            orderId: aOLO.Order.OrderID,
            storeKey: aOLO.storeInfo.StoreKey
        };

        return await aOLO.Modules.OnlineOrderingService.validatePromotionCode(data, aOLO);
    }

    private async _invalidateSingleUseCoupon(couponCode: string) {
        const data = {
            couponCode: couponCode,
            orderId: aOLO.Order.OrderID,
            storeKey: aOLO.storeInfo.StoreKey
        };

        return await aOLO.Modules.OnlineOrderingService.invalidatePromotionCode(data, aOLO);
    }

    private async _applyCouponByPromotionCode(couponCode: string, isOffer: boolean, error: boolean, isSUC?: boolean): Promise<boolean> {
        const coupon = await this._getCouponByPromotionCode(couponCode, aOLO.storeInfo.StoreID, error);
        if (!coupon)
            return false;

        const offer = isOffer ? aOLO.User.Offers?.Codes.find(x => x.CouponCode.toLowerCase() === couponCode) || null : null;
        if (isSUC) {
            couponCode = `SUC_${couponCode}`
        }
        this._applyCouponByCode_Continue(coupon, couponCode, false, true, offer);
        return true;
    }

    private async _applyCouponByCampaignCode(couponCode: string, isOffer: boolean, error: boolean): Promise<boolean> {
        const coupon = await this._getCouponByCampaignCode(couponCode, aOLO.storeInfo.StoreID, error);
        if (!coupon)
            return false;

        const offer = isOffer ? aOLO.User.Offers?.Codes.find(x => x.CouponCode.toLowerCase() === couponCode) || null : null;
        this._applyCouponByCode_Continue(coupon, couponCode, true, false, offer);
        return true;
    }

    private _applyCouponByCode_Continue = (coupon: IDataCoupon, couponCode: string, isCampaignCode: boolean, isPromotionCode: boolean, offer: IUserCampaignCodesData | null): void => {
        if (this.CheckCouponOTST(coupon, aOLO.Order.OrderTypeID)) {
            if (this.CheckCouponSCHS(coupon)) {
                this.CheckCouponHasQualifyingItems(coupon.CouponId, couponCode, null, null, coupon.ChannelID, isCampaignCode, isPromotionCode, offer);
                Util.setElement("value", "txt_cart_coupon_code", "");
            } else
                DialogCreators.messageBoxOk(Names("InvalidScheduleCode"), aOLO.buttonHoverStyle);
        } else
            DialogCreators.messageBoxOk(Names("InvalidOrderTypeCode").replace("??", OnlineOrderingUtil.GetOrderTypeName(aOLO.Order.OrderTypeID, aOLO)), aOLO.buttonHoverStyle);
    }

    private _getCouponByCode = async (couponCode: string, orderDate: Date, storeId: number): Promise<IDataCoupon | null> => {
        const data = {
            couponCode: couponCode,
            order_date: orderDate,
            storeId: storeId,
            is_app: Util.isAppView()
        };

        const response = await aOLO.Modules.OnlineOrderingService.getCouponByCouponCode(data, aOLO);
        if (response.success && response.couponId != null) {
            const couponId = response.couponId;
            return aOLO.data.Coupons.find(x => x.CouponId === couponId) ?? null;
        }

        return null;
    }

    private _getCouponByPromotionCode = async (couponCode: string, storeId: number, error: boolean): Promise<IDataCoupon | null> => {
        const data = {
            couponCode: couponCode,
            storeId: storeId
        };
        const response = await aOLO.Modules.OnlineOrderingService.getCouponByPromotionCode(data, aOLO);

        if (response.success && response.coupon != "") {
            const coupon = this._mapCoupon(response.coupon);
            if (!aOLO.data.Coupons.some(x => x.CouponId == coupon.CouponId))
                aOLO.data.Coupons.push(coupon);
            return coupon;
        } else if (!response.success && !response.serverError && error)
            DialogCreators.messageBoxOk(Names("UnableToRetrieveSurveyCoupon"), aOLO.buttonHoverStyle);

        return null;
    }

    private _getCouponByCampaignCode = async (couponCode: string, storeId: number, error: boolean): Promise<IDataCoupon | null> => {
        const data = {
            couponCode: couponCode,
            storeId: storeId
        };
        const response = await aOLO.Modules.OnlineOrderingService.getCouponByCampaignCode(data, aOLO);

        if (response.success && response.coupon != "") {
            const coupon = this._mapCoupon(response.coupon);
            if (!aOLO.data.Coupons.some(x => x.CouponId == coupon.CouponId))
                aOLO.data.Coupons.push(coupon);
            return coupon;
        } else if (!response.success && !response.serverError && error)
            DialogCreators.messageBoxOk(Names("UnableToRetrieveCampaignCoupon"), aOLO.buttonHoverStyle);

        return null;
    }

    public RemoveNotApplicableOrderTypeCoupons = async (orderTypeID: number): Promise<void> => {
        for (let i = aOLO.Order.Coupons.length - 1; i >= 0; i--) {
            let coupon = aOLO.Order.Coupons[i];
            let cpn = this.GetCoupon(coupon.CouponId, true, orderTypeID, coupon.ChannelID);
            if (cpn === null)
                await this.RemoveCouponByKey(coupon.CouponKey, false);
        }
    }
}