/**
 * The loyalty provider.
 * @enum {number}
 */
export enum LoyaltyProvider {
    NONE = 0,
    ADORA = 1,
    PUNCHH = 4
}

export enum CustomerProviderEnum {
    NONE = 0,
    ADORA = 1,
    PUNCHH = 4
}

/**
 * The type of loyalty program.
 * @enum {number}
 */
export enum LoyaltyProgramType {
    REWARDS = 1,
    REDEEM_POINTS = 2,
    MEMBERSHIP = 3
};

/**
 * The type of form field.
 * @enum {string}
 */
export enum FieldType {
    PHONE = "phone",
    EMAIL = "email",
    DAY = "day",
    MONTH = "month",
    NAME = "name",
    ZIP = "zip",
    PASSWORD = "password",
    COMPAREPASSWORDS = "comparepasswords",
    OLDPASSWORD = "checkifcurrentpassword",
    BOOLEAN = "boolean",
    CUSTOM = "custom",
    CAR = "car",
    FAILED = "failed"
};

export enum LoggedInStatus {
    ANONYMOUS = 0,
    EXTERNAL = 1,
    LOGGEDIN = 2
};

export enum CardBrand {
    VISA = 1,
    MASTERCARD = 2,
    AMEX = 3,
    DISCOVER = 4,
    DINERSCLUB = 5,
    JCB = 6,
    DINERS = 7,
    UNKNOWN = 0
}

export enum OrderType {
    DINE_IN = 1,
    TAKE_OUT = 2,
    DELIVERY = 3
}

export enum MerchantProcessor {
    Mercury = 1,
    Valutec = 2,
    Adora = 3,
    Heartland = 4,
    LevelUp = 5,
    MX = 6,
    SmartTransactions = 7,
    WorldPayTriPOS = 8,
    Elavon = 9,
    OpenPay = 10,
    O2GPay = 11,
}

export enum ItemStyle {
    VERTICAL_SIZE_BTN = 1,
    VERTICAL_OPTIONS = 2,
    HORIZONTAL_IMAGE_LEFT = 3,
    HORIZONTAL_IMAGE_RIGHT = 4
}

export enum AddressType {
    HOUSE = 1,
    GATED_HOUSE = 2,
    APT = 3,
    BUSINESS = 4,
    SCHOOL = 5,
    HOTEL = 6,
    PARK = 7
}

export enum Curbside {
    NO = 0,
    PRINT_ONLY = 1,
    PARKING_STALLS_WITH_TEXTING = 2,
    PARKING_STALLS_WITH_EMAIL_LINK = 3,
    CUSTOMER_CAR_INFO = 4
}

export enum PaymentType {
    PAY_IN_STORE = 0,
    CASH = 1,
    CREDIT_CARD = 2,
    WALLET_CREDIT_CARD = 3,
    CREDIT_CARD_MOBILE = 4
}

export enum OnlinePaymentOption {
    PAY_IN_STORE = "0",
    CASH = "1",
    CREDIT_CARD = "2",
    CREDIT_CARD_UPON_DELIVERY = "3",
    CREDIT_CARD_PRE_VALIDATE = "4",
}

export enum StoreStatusId {
    ACTIVE = 1,
    TEMPORARILY_CLOSED = 2,
    CLOSED = 3,
    INACTIVE = 4,
    COMING_SOON = 5
}

export enum AddressValidation {
    INVALID = 0,
    VALID = 1,
    OUT_OF_DELIVERY_ZONE = 2,
    OUT_OF_TIME = 3,
    INVALID_NO_ZIP = 4,
    BLOCKED = 5,
    INVALID_ST_NUMBER = 6,
    INVALID_ST_NAME = 7,
    INVALID_NO_STREET_NUMBER = 8
}

export enum CouponIdList {
    MANAGER_DOLLAR_OFF = 1,
    MANAGER_PERCENT_OFF_ORDER = 2,
    MANAGER_FIXED_PRICE = 3,
    MANAGER_NEXT_ORDER_CREDIT_DOLLAR_OFF = 4,
    MANAGER_NEXT_ORDER_CREDIT_PERCENT_OFF = 5,
    MANAGER_PERCENT_OFF_ITEM = 6,
    LOYALTY_REDEEM_ITEM = 7,
    EMPLOYEE_DISCOUNT = 8,
    EMPLOYEE_CONSUMPTION = 9,
    LOYALTY_OFFER_THIRDPARTY = 10,
    LOYALTY_REWARD_THIRDPARTY = 11
}

export enum CouponTypeList {
    MANAGER_DISCOUNT = 1,
    NEXT_ORDER_CREDIT = 2,
    LOYALTY_DISCOUNT = 3,
    EMPLOYEE_DISCOUNT = 4,
    EMPLOYEE_CONSUMPTION = 5,
    DOLLAR_OFF = 10,
    FIXED_PRICE = 20,
    PERCENTAGE_OFF_ENTIRE_ORDER = 30,
    PERCENTAGE_OFF_AN_ITEM = 31,
    PERCENTAGE_OFF_AN_ITEM_IN_CATEGORY_OR_BOGO_IN_CATEGORY = 32,
    PERCENTAGE_OFF_ALL_ITEMS_IN_CATEGORY = 33,
    PERCENTAGE_OFF_TWO_ITEMS_IN_CATEGORY = 34,
    PERCENTAGE_OFF_ITEM_IN_CATEGORY_BASED_ON_PRICE = 35, // eg. 49% one topping pizza only
    UPGRADE = 40,
    QUANTITY_PRICE = 50,
    QUANTITY_DISCOUNT = 51,
    MIX_AND_MATCH_PRICE = 52,
    MIX_AND_MATCH_PERCENTAGE = 53
}

export enum BahamasCoordinates {
    LAT = 25.0343,
    LONG = -77.3963
}

export enum EmailTextMarketingType {
    NONE = 0,
    EMAIL_ONLY = 1,
    TEXT_ONLY = 2,
    EMAIL_AND_TEXT = 3
}

export enum AddressLookupType {
    NONE = 0,
    INTERNAL = 1,
    GOOGLE = 2
}

export enum Setting81 {
    NONE = 0,
    DESKTOP_AND_APP = 1,
    DESKTOP_ONLY = 2,
    APP_ONLY = 3
}

export enum PayInStoreAlternate {
    NONE = 0,
    CREDIT_CARD = 1,
    PRE_VALIDATED_CREDIT_CARD = 2
}
export enum CashAlternate {
    NONE = 0,
    CREDIT_CARD = 1,
    PRE_VALIDATED_CREDIT_CARD = 2
}
export enum CardOnFileCvvOption {
    IGNORE = 0,
    MUST_MATCH = 1,
    MUST_MATCH_OVER_THERSHOLD = 2
}

export enum CreditCardPreValidationOption {
    DO_NOT_VALIDATE = 0,
    VALIDATE = 1,
    VALIDATE_OVER_THERSHOLD = 2

}

export enum CreditCardAvsOption {
    DO_NOT_VALIDATE = 0,
    ADDRESS_ONLY_PROMPT_VALIDATE = 1,
    ZIP_ONLY_PROMPT_VALIDATE = 2,
    ADDRESS_OR_ZIP_MUST_MATCH = 3,
    ADDRESS_AND_ZIP_MUST_MATCH = 4,
    DO_NOT_VALIDATE_UNDER_THRESHOLD_ADDRESS_OR_ZIP_MUST_MATCH_OVER_THERSHOLD = 5,
    DO_NOT_VALIDATE_UNDER_THRESHOLD_ADDRESS_AND_ZIP_MUST_MATCH_OVER_THERSHOLD = 6,
    ADDRESS_OR_ZIP_MUST_MATCH_UNDER_THRESHOLD_ADDRESS_AND_ZIP_MUST_MATCH_OVER_THERSHOLD = 7
}

export enum CreditCardCvvOption {
    IGNORE = 0,
    MUST_MATCH = 1,
    MUST_MATCH_OVER_THERSHOLD = 2
}
export enum CreditCardOption {
    CHARGE = 1,
    PRE_VALIDATED = 2,
    CHARGE_UPON_DELIVERY = 3
}

export enum Pages {
    LANDING = "landing",
    LOCATIONS = "locations",
    REWARDS = "rewards",
    FAVORITES = "favorites",
    MENU = "menu",
    ORDERS = "orders"
}