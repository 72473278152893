export class DialogManager {
    private static openDialogCount = 0;

    /**
     * Checks if the browser supports the HTMLDialogElement.
     * @returns {boolean} True if supported, false otherwise.
     */
    private static isDialogSupported(): boolean {
        return typeof HTMLDialogElement === 'function';
    }

    /**
     * Locks the body to prevent background scrolling.
     * @param {IaOLO} localAolo - The local object for body locking.
     */
    //private static lockBody(localAolo: IaOLO): void {
    private static lockBody(): void {
        const html = document.documentElement;

        //// Store the initial overflow style
        //const originalOverflow = html.style.overflow;

        //// Temporarily disable scrollbars
        //html.style.overflow = 'hidden';

        //// Measure the full width of the <html> element
        //const fullWidth = html.clientWidth;

        //// Restore the original overflow style to enable scrollbars
        //html.style.overflow = 'scroll';

        //// Measure the width with scrollbars
        //const scrollbarWidth = fullWidth - html.clientWidth;

        //// Restore the original overflow style
        //html.style.overflow = originalOverflow;

        //html.style.paddingRight = `${6}px`;
        html.classList.add('body-locked');
        // Additional logic related to localAolo can be added here

        /**
        * Locks the body of the document to prevent scrolling, and adjusts the padding of the header and category div if they exist,
        * to prevent the scrollbar from pushing content to the side.
        * @returns {void}
        */
        //private static LockBody(localAolo: IaOLO) {
        //    if (!localAolo)
        //        return;

        //    document.getElementsByTagName("body")[0].style.overflow = "hidden";
        //    //document.getElementsByTagName("body")[0].style.height = "100vh";
        //    document.getElementsByTagName("html")[0].style.paddingRight = `${localAolo.scrollbarWidth}px`;

        //    const headers = document.getElementsByTagName("header");
        //    if (headers && headers.length > 0)
        //        document.getElementsByTagName("header")[0].style.paddingRight = `${localAolo.scrollbarWidth}px`;

        //    const divlang = document.getElementById("div_lang");
        //    if (divlang)
        //        divlang.style.paddingRight = `${localAolo.scrollbarWidth}px`;
        //}
    }

    /**
     * Unlocks the body to allow background scrolling.
     */
    private static unlockBody(): void {
        document.documentElement.classList.remove('body-locked');

        //document.body.style.overflow = "auto";
        //document.getElementsByTagName("html")[0].style.paddingRight = "0";
        //document.getElementsByTagName("header")[0].style.paddingRight = "0";
        //const dCats = document.getElementById("div_categories");
        //if (dCats)
        //    dCats.style.paddingRight = "0";

        //const divlang = document.getElementById("div_lang");
        //if (divlang)
        //    divlang.style.paddingRight = "0";
    }

    /**
     * Shows a dialog with optional fade-in effect.
     * @param {string | HTMLElement} dialogID - The ID or element of the dialog to show.
     * @param {IaOLO} localAolo - The local object for body locking.
     * @param {string | null} [focusElementID] - The ID of the element to focus after showing the dialog.
     * @param {boolean} [useFade=false] - Whether to use fade-in effect.
     */
    public static showDialog(dialogId: string | HTMLElement, focusElementId?: string | null, useFade: boolean = false): void {
        const dialog = (typeof dialogId === 'string' ? document.getElementById(dialogId) : dialogId) as HTMLDialogElement;
        if (!dialog || dialog.open)
            return;

        // Alert user is browser does not support dialog element
        if (!this.isDialogSupported()) {
            alert('Your browser does not support dialogs. Please update your browser for the best experience.');
            return;
        }

        this.lockBody();

        //dialog.classList.add("dialogFaded");
        //dialog.classList.remove("opacity1");

        // Show the dialog
        dialog.showModal();

        // Set accessibility attributes
        dialog.setAttribute('aria-modal', 'true');
        dialog.setAttribute('role', 'dialog');

        // Add the base dialog class for styling
        dialog.classList.add('dialog');
        //document.body.style.setProperty("overflow", "hidden", "!important");
        //dialog.scrollTop = 0;

        //setTimeout(() => { dialog.classList.add('opacity1'); }, 50);
        if (useFade) {
            // Start the fade-in effect
            requestAnimationFrame(() => {
                dialog.classList.add('open');
            });
        } else {
            dialog.classList.add('open');
        }

        // Manage focus
        if (focusElementId) {
            const focusElement = document.getElementById(focusElementId);
            if (focusElement)
                focusElement.focus();
            else
                dialog.focus();
        } else
            dialog.focus();

        // Update dialog open counter
        this.openDialogCount++;
    }

    /**
     * Hides a dialog with optional fade-out effect.
     * @param {string | HTMLElement} dialogID - The ID or element of the dialog to hide.
     * @param {boolean} [destroy=false] - Whether to remove the dialog from the DOM after hiding it.
     * @param {boolean} [useFade=false] - Whether to use fade-out effect.
     */
    public static hideDialog(dialogId: string | HTMLElement, destroy: boolean = false, useFade: boolean = false): void {
        const dialog = (typeof dialogId === 'string' ? document.getElementById(dialogId) : dialogId) as HTMLDialogElement;
        if (!dialog || !dialog.open)
            return;

        //dialog.classList.remove("opacity1");
        //dialog.classList.add("fadeout");

        if (useFade) {
            // Start the fade-out effect
            dialog.classList.remove('open');

            const onTransitionEnd = () => {
                dialog.removeEventListener('transitionend', onTransitionEnd);
                dialog.close();

                if (destroy) {
                    dialog.remove();
                }

                this.openDialogCount--;
                if (this.openDialogCount === 0)
                    this.dialogClosed();
            };

            dialog.addEventListener('transitionend', onTransitionEnd);
        } else {
            dialog.close();
            dialog.classList.remove('open');

            if (destroy)
                dialog.remove();

            this.openDialogCount--;
            if (this.openDialogCount === 0)
                this.dialogClosed();
        }

        //const dialogInner = document.getElementById(dialogID) as HTMLElement;
        //if (dialogInner) {
        //    //@ts-ignore
        //    dialogInner.close();
        //    dialogInner.classList.remove("fadeout");

        //    const focusId = dialogInner.getAttribute("data-focus-element-id");
        //    if (focusId) {
        //        const focusElement = document.getElementById(focusId) as HTMLInputElement;
        //        if (focusElement) {
        //            focusElement.focus();
        //            try { focusElement.select(); } catch { }
        //        }
        //    }
        //    if (destroy)
        //        dialogInner.remove();
        //}

        //if (this.GetOpenedDialogCount() === 0)
        //    this.DialogClosed();


        // Remove accessibility attributes
        dialog.removeAttribute('aria-modal');
        dialog.removeAttribute('role');
    }

    //public static DialogFadeOut(dialogID: string, destroy?: boolean): void {
    //    const dialog = document.getElementById(dialogID);
    //    if (!dialog) return;

    //    dialog.classList.remove("opacity1");
    //    dialog.classList.add("fadeout");

    //    setTimeout(
    //        () => {
    //            const dialogInner = document.getElementById(dialogID) as HTMLElement;
    //            if (dialogInner) {
    //                //@ts-ignore
    //                dialogInner.close();
    //                dialogInner.classList.remove("fadeout");

    //                const focusId = dialogInner.getAttribute("data-focus-element-id");
    //                if (focusId) {
    //                    const focusElement = document.getElementById(focusId) as HTMLInputElement;
    //                    if (focusElement) {
    //                        focusElement.focus();
    //                        try { focusElement.select(); } catch { }
    //                    }
    //                }
    //                if (destroy)
    //                    dialogInner.remove();
    //            }

    //            if (this.GetOpenedDialogCount() === 0)
    //                this.DialogClosed();
    //        },
    //        500);
    //}

    //private static GetOpenedDialogCount(): number {
    //    let count = 0;
    //    const dialogs = Array.from(document.getElementsByTagName('dialog'));
    //    for (const dialog of dialogs) {
    //        if (dialog.open)
    //            count++;
    //    }
    //    return count;
    //}

    /**
     * Handles the logic when all dialogs are closed.
     */
    private static dialogClosed(): void {
        if (this.openDialogCount !== 0)
            return;

        this.unlockBody();

        // Additional logic when all dialogs are closed can be added here
        // For example, resetting styles or cleaning up
    }

    /**
    * Shows a dialog with the given ID and optionally sets focus to an element inside the dialog.
    * @param {string} dialogID - The ID of the dialog to show.
    * @param {string|null} focusElementID - The ID of the element to set focus to when the dialog is shown. If null or undefined, no focus will be set.
    * @returns {void}
    */
    //public static DialogShow(dialogID: string, localAolo: IaOLO, focusElementID: string | null): void {
    //    const dialog = document.getElementById(dialogID) as HTMLDialogElement;
    //    if (dialog && !dialog.open) {
    //        this.LockBody(localAolo);
    //        dialog.classList.add("opacity1");
    //        dialog.showModal();

    //        if (focusElementID) {
    //            const fElement = document.getElementById(focusElementID);
    //            if (fElement)
    //                fElement.focus();
    //        }
    //    }
    //}

    ///**
    //* Hides the dialog with the given ID.
    //* @param {string} dialogID - The ID of the dialog to hide.
    //* @returns {void}
    //*/
    //public static DialogHide(dialogID: string): void {
    //    const dialog = document.getElementById(dialogID) as HTMLDialogElement;
    //    if (dialog) {
    //        dialog.close();
    //        dialog.classList.remove("opacity1");
    //        dialog.classList.remove("fadeout");
    //        this.DialogClosed(); // DO NOT COMMENT THIS
    //    }
    //}
}