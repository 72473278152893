import './landing.css';

import Swiper from 'swiper';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import { EventEmitter } from 'eventemitter3';

import { EventTypes } from '../../../core/event-bus';
import { Util } from '../../../utils/util';
import { IaOLO } from '../../../interfaces/aolo.interface';
import { IDataDesktopBanner } from './landing.interface';
import { Common } from '../../../common';
import { OrderType, Pages } from '../../../types/enums';
import { OrderTypeSelector } from '../../shared/OrderTypeSelector/order-type-selector';
import { getSupportedLanguages, Names } from '../../../utils/i18n';
import { IAddressFormat, IName } from '../../../interfaces/global.interfaces';
import { User } from '../../../models/user';
import { ProfileService } from '../../../services/api/profile-service';

/**
 * Class representing the Landing page.
 */
export class Landing {
    private readonly _eventBus: EventEmitter;
    private readonly _profileService: ProfileService;
    private readonly _user: User;
    private _bannerData: IDataDesktopBanner;
    private _orderTypeSelected: OrderType | null = null;
    private _storeKeySelected: string = "";
    private readonly _aOLO: IaOLO;

    /**
     * Creates an instance of the Landing page.
     * @constructor
     */
    constructor(eventBus: EventEmitter, profileService: ProfileService, user: User, localAolo: IaOLO) {
        this._eventBus = eventBus;
        this._profileService = profileService
        this._user = user;
        this._aOLO = localAolo;
        this._bannerData = this._initialBannerModel();
        this._eventBusListeners();
    }

    private readonly _initialBannerModel = (): IDataDesktopBanner => {
        return {
            Banners: [],
            RotationActive: false,
            RotationPeriod: 1,
            SlideIdx: 0
        };
    }

    public initAsync = async (): Promise<void> => {
        this._showPage();
        await this._renderPageAsync();
        this._setEventListeners();
    }

    private readonly _showPage = (): void => {
        Util.showComponent("landing");
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.ORDER_TYPE_CHANGED, this._orderTypeChange);
        this._eventBus.on(EventTypes.LOGGED_IN, this._loggedInEvent);
        this._eventBus.on(EventTypes.LOGGED_OUT, this._loggedOutEvent);
    }

    private readonly _renderPageAsync = async (): Promise<void> => {
        if (this._user.isLoggedIn())
            this._loggedInEvent();
        else
            this._loggedOutEvent();

        await this._displayBannersAsync();
    }

    private readonly _setEventListeners = () => {
        Util.setElement("onclick", "btn_landing_location", this._landingLocationOnClick);

        Util.setElement("onclick", "btn_landing_order_now", async () => {
            if (this._storeKeySelected) {
                this._eventBus.emit(EventTypes.LOCATION_CHANGE, Pages.MENU);
                this._eventBus.emit(EventTypes.STORE_CHANGED, this._storeKeySelected);
            } else {
                const orderTypeId = !this._orderTypeSelected ? OrderType.TAKE_OUT : this._orderTypeSelected;
                this._eventBus.emit(EventTypes.LOCATION_CHANGE, Pages.LOCATIONS, { orderTypeId: orderTypeId });
            }
        });

        for (const [index, banner] of this._bannerData.Banners.entries()) {
            if (banner.CouponID == 0)
                continue;

            Util.setElement("onclick", `div_landing_banner_image_${index}`, () => {
                console.log(`Image ${index + 1} clicked`);
                // 2DO: Apply coupon
                //    aOLO.orderCouponId = banner.CouponID;
                //    brandDisplayPage('locations', true);
            });
        }
    }

    private readonly _landingLocationOnClick = (): void => {
        const orderTypeId = !this._orderTypeSelected ? OrderType.TAKE_OUT : this._orderTypeSelected;
        let addressId = null;

        if (orderTypeId == OrderType.DELIVERY) {
            const primaryAddress = this._user.getProperty("Addresses")?.find(x => x.IPRM);
            if (primaryAddress)
                addressId = primaryAddress.AID;
        }

        this._eventBus.emit(EventTypes.LOCATION_CHANGE, Pages.LOCATIONS, { orderTypeId: orderTypeId, selectedStoreKey: this._storeKeySelected, addressId: addressId });
    }

    private readonly _displayBannersAsync = async (): Promise<void> => {
        let bannerLoaded = false;
        const profileId = this._user.getProperty("ProfileId");
        if (profileId && profileId > 0 && this._user.getProperty("DefaultStore")) {
            // Customer Default Store
            const newDesktopBanner = await this._loadStoreBannerDataAsync(this._user.getProperty("DefaultStore")?.StoreKey || null);
            this._mapBannerData(newDesktopBanner);
            bannerLoaded = true;
        } else if (this._aOLO.Dialog.Locations && Util.isAppView()) {
            // Geolocate the closest store
            const locations = this._aOLO.Dialog.Locations.GetLocations();
            if (locations && locations.length > 0) {
                const newDesktopBanner = await this._loadStoreBannerDataAsync(locations[0].storeKey);
                this._mapBannerData(newDesktopBanner);
                bannerLoaded = true;
            }
        }

        if (!bannerLoaded) {
            // Brand Store or requested store in URL 
            const newDesktopBanner = await this._loadStoreBannerDataAsync(this._aOLO.brandInfo?.brandStoreKey || this._aOLO.storeInfo.StoreKey);
            this._mapBannerData(newDesktopBanner);
        }

        this._renderBanners();
    }

    private readonly _loadStoreBannerDataAsync = async (storeKey: string | null | undefined): Promise<string> => {
        const response = await this._profileService.getLandingBanner(storeKey, this._aOLO);
        return response;
    }

    private readonly _orderTypeChange = (orderTypeId: OrderType): void => {
        this._orderTypeSelected = orderTypeId;

        if (this._user.isLoggedIn()) {
            this._landingStoreLocation();
            this._createOrderButtonText(true, orderTypeId);
        }
    }

    private readonly _createOrderButtonText = (loggedIn: boolean, orderTypeId: OrderType): void => {
        const languages = getSupportedLanguages();
        let nameObj: IName[] = [];

        if (loggedIn) {
            const orderType = this._aOLO.data.OrderTypeSubTypes.find(x => x.OrderTypeId === orderTypeId);
            const orderTypeName = orderType ? Common.GetName(orderType.Names, this._aOLO.Temp.languageCode) : "Undefined";
            nameObj = languages.map(lang => {
                const tempText = Names("StartOrderWithOrderType", lang).replace("{{order_type}}", orderTypeName);
                return { CULT: lang, NAM: tempText };
            });

            Util.setElement("innerText", "btn_landing_order_now", Names("StartOrderWithOrderType").replace("{{order_type}}", orderTypeName));
            Util.setElement("ltagj", "btn_landing_order_now", Util.toLtagj(nameObj));
        } else {
            nameObj = languages.map(lang => {
                const tempText = Names("StartOrder", lang);
                return { CULT: lang, NAM: tempText };
            });
            Util.setElement("innerText", "btn_landing_order_now", Names("StartOrder"));
            Util.setElement("ltagj", "btn_landing_order_now", Util.toLtagj(nameObj));
        }
    }

    private readonly _landingStoreLocation = (): void => {
        if (this._user.isLoggedIn()) {
            switch (this._orderTypeSelected) {
                case OrderType.DINE_IN:
                case OrderType.TAKE_OUT:
                    this._landingStoreLocationNonDelivery();
                    break;
                case OrderType.DELIVERY:
                    this._landingStoreLocationDelivery();
                    break;
            }
            return;
        }

        const userLocation = Util.getUserLocationFromCookie();
        if (!userLocation) {
            Util.visibilityOffElement("btn_landing_location");
        } else {
            switch (this._orderTypeSelected) {
                case OrderType.DINE_IN:
                case OrderType.TAKE_OUT:
                    // 2DO: Find closest location, Change Name of button
                    Util.visibilityOnElement("btn_landing_location");
                    break;
                case OrderType.DELIVERY:
                    Util.visibilityOffElement("btn_landing_location");
                    break;
            }
        }
    }

    private readonly _landingStoreLocationNonDelivery = (): void => {
        if (this._orderTypeSelected == OrderType.DINE_IN) {
            Util.setElement("innerText", "span_landing_address_orderType", Names("DineInAt"));
            Util.setElement("ltag", "span_landing_address_orderType", "DineInAt");
        } else {
            Util.setElement("innerText", "span_landing_address_orderType", Names("PickUpFrom"));
            Util.setElement("ltag", "span_landing_address_orderType", "PickUpFrom");
        }

        const defaultStore = this._user.getProperty("DefaultStore");
        if (defaultStore) {
            this._storeKeySelected = defaultStore.StoreKey;
            const tempAddress: IAddressFormat = {
                StreetNo: defaultStore.StreetNumber,
                Address1: defaultStore.Address1,
                Address2: defaultStore.Address2,
                Address3: "",
                Address4: "",
                Address5: "",
                City: "",
                State: "",
                ZipCode: "",
                CountryID: defaultStore.CountryId || 1,
                AddressTypeID: 0
            };

            const addressObject = Util.formatAddressObject(tempAddress, this._aOLO.data.Countries);
            Util.setElement("innerText", "span_landing_address", addressObject.address1);

            Util.visibilityOnElement("btn_landing_location");
        } else
            Util.visibilityOffElement("btn_landing_location");
    }

    private readonly _landingStoreLocationDelivery = (): void => {
        const primaryAddress = this._user.getProperty("Addresses")?.find(x => x.IPRM);
        if (primaryAddress) {
            Util.setElement("innerText", "span_landing_address_orderType", Names("DeliverTo"));
            Util.setElement("ltag", "span_landing_address_orderType", "DeliverTo");

            const tempAddress: IAddressFormat = {
                StreetNo: primaryAddress.STRNO,
                Address1: primaryAddress.ADDR1,
                Address2: primaryAddress.ADDR2,
                Address3: primaryAddress.ADDR3,
                Address4: primaryAddress.ADDR4,
                Address5: primaryAddress.ADDR5,
                City: primaryAddress.CITY,
                State: primaryAddress.STA,
                ZipCode: primaryAddress.ZIP,
                CountryID: primaryAddress.CID || 1,
                AddressTypeID: 0
            };

            const addressObject = Util.formatAddressObject(tempAddress, this._aOLO.data.Countries);
            Util.setElement("innerText", "span_landing_address", addressObject.address1);

            Util.visibilityOnElement("btn_landing_location");
        } else
            Util.visibilityOffElement("btn_landing_location");
    }

    private readonly _renderBanners = (): void => {
        const bannerCount = this._bannerData.Banners.length;
        if (bannerCount == 0)
            return;

        this._createImageDivs();
        this._createCarousel();

        Util.showElement("div_landing_banner_parent");
        Util.hideElement("div_landing_banner_placeholder");
    }

    private readonly _createImageDivs = (): void => {
        const divBanner = document.getElementById("div_landing_banner");
        if (!divBanner)
            return

        Util.setElement("innerHTML", divBanner, "");

        for (const [index, banner] of this._bannerData.Banners.entries()) {
            const html = `
                <div id="div_landing_banner_image_${index}" class="swiper-slide">
                    <img src="${banner.ImageURL}" alt="Banner ${index}" />
                </div>`;

            divBanner.innerHTML += html;
        }
    }

    private readonly _createCarousel = (): void => {
        const swiper = new Swiper('.swiper-container', {
            modules: [Pagination, Autoplay],
            loop: true,
            autoplay: {
                delay: (this._bannerData.RotationPeriod * 1000),
                disableOnInteraction: false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                type: 'bullets',
                bulletActiveClass: 'bullet-active'
            },
        });
    }

    private readonly _mapBannerData = (data: any): void => {
        const newData: IDataDesktopBanner = {
            Banners: data.Data?.map((banner: any) => ({
                CouponID: banner.LCID,
                ImageURL: banner.IURL
            })) || [],
            RotationActive: data.RIA || false,
            RotationPeriod: data.RP || 1,
            SlideIdx: 1
        };

        this._bannerData = newData as any;
    }

    private readonly _loggedInEvent = (): void => {
        Util.showElement("div_landing_order_type_parent");
        const orderTypeId = !this._orderTypeSelected ? OrderType.TAKE_OUT : this._orderTypeSelected;
        const orderSelector = new OrderTypeSelector(this._eventBus, "div_landing_order_types", "landing", this._aOLO);
        orderSelector.init(orderTypeId);

        Util.hideElement("div_landing_order_types_placeholder");
        this._eventBus.emit(EventTypes.ORDER_TYPE_CHANGED, orderTypeId);
    }

    private readonly _loggedOutEvent = (): void => {
        const orderTypeId = !this._orderTypeSelected ? OrderType.TAKE_OUT : this._orderTypeSelected;
        this._storeKeySelected = "";
        Util.hideElement("div_landing_order_type_parent");
        this._eventBus.emit(EventTypes.ORDER_TYPE_CHANGED, orderTypeId);
    }
}