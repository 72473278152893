import './meal-deal-item-card.css';

import { IDataCoupon } from '../../../interfaces/data.interface';
import { Common } from '../../../common';
import { Util } from '../../../utils/util';
import { Coupon } from '../../../online-ordering/coupon';

export class MealDealItemCard {
    private _item: IDataCoupon;
    private _element: HTMLElement;

    constructor(couponClass: Coupon, item: IDataCoupon, languageCode: string) {
        this._item = item;
        this._element = this._createElement(languageCode);
        this._addClickHandler(couponClass);
    }

    private _createElement(languageCode: string): HTMLElement {
        const name = Common.GetName(this._item.Names, languageCode);
        const description = Common.GetDescription(this._item.Descriptions, languageCode);

        // 2DO: Show Price section using coupon.CouponTypeId and coupon.Discount

        const html = `
           <div id="div_menu_item_hot_deal_${this._item.CouponId}" class="card-hot-deal">
                ${this._item.ImageURL && this._item.ImageURL !== "" ?
                `<div class="card-hot-deal-image">
                        <img id="img_menu_item_hot_deal_${this._item.CouponId}" src="${this._item.ImageURL}" alt="${name}">
                    </div>` :
                ""}
                <div class="card-hot-deal-content">
                    <div>
                        <h2 class="card-hot-deal-title" ltagj="${Util.toLtagj(this._item.Names)}">${name}</h2>
                        <p class="card-hot-deal-description" ltagj="${Util.toLtagj(this._item.Descriptions)}">${description}</p>
                    </div>
                    <p class="card-hot-deal-price">STARTING AT $8.99</p>
                </div>
            </div>`;

        return Util.createHtmlElementFromTemplate(html);
    }

    public get element(): HTMLElement {
        return this._element;
    }

    private _addClickHandler(couponClass: Coupon): void {
        this._element.onclick = () => {
            if (couponClass.IsCouponNotAllowedInFundraiser())
                return;
            couponClass.CheckCouponHasQualifyingItems(this._item.CouponId);
        };

        const image = this._element.querySelector(`img_menu_item_hot_deal_${this._item.CouponId}`) as HTMLImageElement;
        if (image) {
            image.onerror = () => { image.style.display = "none"; };
        }
    }
}