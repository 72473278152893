import { Util } from "../utils/util";
import { OnlineOrderingUtil } from "./online-ordering-util";
import { Names } from "../utils/i18n";
import { DialogCreators } from "../utils/dialog-creators";
import { IDataHolidayOrderType } from "../interfaces/data.interface";

import '../../css/online-ordering/limited-time.css';
import { IaOLO } from "../interfaces/aolo.interface";

export class LimitedTime {
    private _date: Date;
    private _selectedMinute: number | null;
    private _orderTypeId: number;
    private _orderCount: { minute: number, count: number }[] = [];
    private _maxCount: number | null = null;

    constructor(orderTypeId: number, date: Date | null, selectedMinute: number | null) {
        if (date == null) {
            const dateText = Util.getElementValue("txt_order_type_time_later_date");
            const dateObj = Util.GetDateInputDate(dateText);
            this._date = dateObj ? dateObj : new Date();
        } else
            this._date = date;

        this._orderTypeId = orderTypeId;
        this._selectedMinute = selectedMinute;
        this._init();
    }

    private _init = async (): Promise<void> => {
        await this._getData();
        this._renderPage();
        this._setEventListeners();
        this.openDialog(aOLO);
    }

    private _getData = async (): Promise<void> => {
        const payload = {
            SKY: aOLO.storeInfo.StoreKey,
            BDATE: Util.formatDateInput(this._date),
            OTID: this._orderTypeId,
            GUID: aOLO.Order.GUID
        };
        const result = await aOLO.Modules.OnlineOrderingService.getLimitedTimes(payload, aOLO);
        if (result.success)
            this._cleanOrderCount(result.data);
    }

    private _cleanOrderCount = (data: { min: number, cnt: number }[]): void => {
        for (const item of data) {
            this._orderCount.push({
                minute: item.min >= 240 ? item.min : item.min + 1440,
                count: item.cnt
            });
        }
    }

    private _renderPage = (): void => {
        if (!this._selectedMinute) {
            Util.setElement("value", "txt_order_type_time_later_time", "");
            const button = document.getElementById("btn_order_type_select_limited_time");
            if (button)
                button.innerText = Names("SelectTime");
        }

        Util.setElement("innerText", "spn_limited_time_header_text", Util.formatDate(this._date));

        const selectedDate = OnlineOrderingUtil.GetDate_StartTime_EndTime(this._date, 0, false, aOLO, aOLO.Order.OrderTypeSubType, false);
        let startTime = selectedDate.StartOrderMinute - aOLO.Temp.WaitTime;
        startTime = startTime - (startTime % 15);
        const endTime = selectedDate.EndOrderMinute - (selectedDate.EndOrderMinute % 15);
        let startSkip = ((startTime % 60) > 0) ? (startTime % 60) / 15 : 0;
        const rows = Math.ceil((endTime - (startTime - startSkip * 15)) / 60);

        let throttleInfo = null;
        if (selectedDate.ThrottleInfo) {
            throttleInfo = selectedDate.ThrottleInfo.find(x => x.checked && x.orderTypeId == this._orderTypeId) || null;
            if (throttleInfo)
                this._maxCount = throttleInfo.orderAmount;
        }

        let html = "";
        for (let i = 0; i < rows; i++) {
            html += this._buildTimeRow(startTime, endTime, startSkip, throttleInfo);
            startTime += 60 - (startSkip * 15);
            startSkip = 0;
        }

        Util.setElement("innerHTML", "div_limited_time_times", html);
    }

    private _setEventListeners = (): void => {
        Util.setElement("onclick", "btn_limited_time_close", () => this.closeDialog());
        Util.setElement("onclick", "btn_limited_time_cancel", () => this.closeDialog());

        const self = this;
        const timeSlotButtons = Array.from(document.getElementsByName("btn_limited_time_slot")) as HTMLButtonElement[];
        for (const button of timeSlotButtons) {
            button.onclick = async function () {
                const minutes = Number(button.dataset.minute);
                const minTime = Number(button.dataset.min);
                const maxTime = Number(button.dataset.max);
                await self._timeClick(minutes, minTime, maxTime);
            }
        }
    }

    private _timeClick = async (minutes: number, minTime: number, maxSpan: number): Promise<void> => {
        const payload = {
            GUID: aOLO.Order.GUID,
            SKY: aOLO.storeInfo.StoreKey,
            BDATE: Util.formatDateInput(this._date),
            OTID: this._orderTypeId,
            MAX: this._maxCount,
            MIN: minutes,
            SMIN: minTime,
            EMIN: minTime + maxSpan
        };
        const result = await aOLO.Modules.OnlineOrderingService.reserveLimitedTimes(payload, aOLO);
        if (!result.success)
            return;

        if (result.data == null) {
            if (aOLO.Dialog.OrderType)
                aOLO.Dialog.OrderType.setLimitedTime(minutes);
            this.closeDialog();
        } else {
            DialogCreators.messageBoxOk(Names("SelectedTimeNotAvailableSelectNew"), aOLO.buttonHoverStyle);
            this._cleanOrderCount(result.data);
            this._renderPage();
            this._setEventListeners();
        }
    }

    private _buildTimeRow = (rowStartMinute: number, storeEndMinute: number, startSkipAmount: number, throttleInfo: IDataHolidayOrderType | null): string => {
        if (startSkipAmount > 0)
            rowStartMinute -= (startSkipAmount * 15);

        let groupAmount = 15;
        const disableList = [];
        if (throttleInfo) {
            if (throttleInfo.minutes == 60) {
                const tempTime = this._calculateAmountOfOrder(rowStartMinute, throttleInfo);
                disableList.push(...tempTime);
            }

            else if (throttleInfo.minutes == 30) {
                for (let i = 0; i < 2; i++) {
                    const tempStartTime = rowStartMinute + (30 * i);
                    const tempTime = this._calculateAmountOfOrder(tempStartTime, throttleInfo);
                    disableList.push(...tempTime);
                }
            }

            else if (throttleInfo.minutes == 15) {
                for (let i = 0; i < 4; i++) {
                    const tempStartTime = rowStartMinute + (15 * i);
                    const tempTime = this._calculateAmountOfOrder(tempStartTime, throttleInfo);
                    disableList.push(...tempTime);
                }
            }

            groupAmount = throttleInfo.minutes;
        }


        let buttons = "";
        for (let i = 0; i < 4; i++) {
            const minutes = rowStartMinute + (15 * i);
            const time = Util.getDateFromMinutes(minutes, aOLO.Temp.TimeOffset);
            const hide = (minutes > storeEndMinute) || (startSkipAmount != 0 && i + 1 <= startSkipAmount);
            const disabled = disableList.includes(minutes);

            let startMinute = minutes;
            if (startMinute % groupAmount != 0)
                startMinute = startMinute - (startMinute % groupAmount);

            if (disabled && !hide) {
                buttons += `
                    <div>
                        <span class="time-disabled">${Util.formatTime(time)}</span>
                    </div>`;
            } else {
                const throttleMinutes = (throttleInfo && throttleInfo.minutes) ? throttleInfo.minutes - 15 : 0;
                buttons += `
                    <div>
                        <button id="btn_limited_time_slot_${minutes}" name="btn_limited_time_slot" data-minute="${minutes}" data-min="${startMinute}" data-max="${throttleMinutes}" class="${aOLO.buttonHoverStyle} ${hide ? "hidden" : ""} ${this._selectedMinute == minutes ? "time-selected" : ""}">${Util.formatTime(time)}</button>
                    </div>`;
            }
        }

        const html = `<div>${buttons}</div>`;
        return html;
    }

    private _calculateAmountOfOrder = (groupStart: number, throttleInfo: IDataHolidayOrderType): number[] => {
        const disableList = [];
        let orderCountByTime = 0;
        for (let j = 0; j < throttleInfo.minutes; j += 15) {
            orderCountByTime += this._orderCount.find(x => x.minute == groupStart + j)?.count || 0;
        }

        if (orderCountByTime >= throttleInfo.orderAmount) {
            for (let j = 0; j < throttleInfo.minutes; j += 15) {
                disableList.push(groupStart + j);
            }
        }

        return disableList;
    }

    public openDialog = (localAolo: IaOLO): void => {
        Util.DialogFadeIn("dia_limited_time", localAolo);
    }

    private _removeEventListeners = (): void => {
        Util.setElement("onclick", "btn_limited_time_close", null);
        Util.setElement("onclick", "btn_limited_time_cancel", null);
    }

    public closeDialog = (): void => {
        this._removeEventListeners();
        Util.DialogFadeOut("dia_limited_time");
    }
}