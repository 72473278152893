import { IaOLO } from '../interfaces/aolo.interface';
import { IDataFundraiser } from "../interfaces/data.interface";
import { Names } from "../utils/i18n";
import { Common } from "../common";
import { Util } from "../utils/util";
import { DialogCreators } from "../utils/dialog-creators";

import '../../css/online-ordering/fundraiser.css';

export class Fundraiser {
    private _fundraiserCode: string;
    private _fundraiser: IDataFundraiser | null = null;
    private _localaOLO: IaOLO;

    constructor(fundraiserCode: string, localaOLO: IaOLO) {
        this._fundraiserCode = fundraiserCode;
        this._localaOLO = localaOLO;
        this._init();
    }

    private _init = (): void => {
        const success = this._renderPage();
        if (success) {
            this._setEventListeners();
            this.OpenDialog();
        }
    }

    public OpenDialog = (): void => {
        Util.DialogFadeIn("dia_fundraiser", this._localaOLO);
    }

    private _renderPage(): boolean {
        if (this._fundraiserCode === "") {
            DialogCreators.messageBoxOk(Names("InvalidFundraiserCode"), this._localaOLO.buttonHoverStyle);
            return false;
        }

        this._fundraiser = this._getFundraiserByCode();

        if (!this._fundraiser) {
            DialogCreators.messageBoxOk(Names("InvalidFundraiserCode"), this._localaOLO.buttonHoverStyle);
            return false;
        }





        if (!this._fundraiser.IsCouponAllowed && this._localaOLO.Order.Coupons.length > 0) {
            const msg = Names("FundraiserCodeErrorWithCoupon");
            DialogCreators.messageBoxOk(msg.replace("??", this._fundraiser.Name), this._localaOLO.buttonHoverStyle);
            return false;
        }

        Util.setElement("value", "txt_cart_fundraiser_code", "");
        this._localaOLO.Order.Fundraiser = { ID: this._fundraiser.FundraiserId };

        Util.setElement("value", "txt_fundraiser_first_custom_field", "");
        Util.setElement("value", "txt_fundraiser_second_custom_field", "");
        Util.setElement("innerText", "lbl_fundraiser_title", this._fundraiser.Name);

        if (this._fundraiser.IsCustomField1) {
            Util.setElement("innerText", "lbl_fundraiser_first_custom_field", Common.GetName(this._fundraiser.CustomField1Names, this._localaOLO.Temp.languageCode));
            Util.showElement("div_fundraiser_first_custom_field");
        } else
            Util.hideElement("div_fundraiser_first_custom_field");

        if (this._fundraiser.IsCustomField2) {
            Util.setElement("innerText", "lbl_fundraiser_second_custom_field", Common.GetName(this._fundraiser.CustomField2Names, this._localaOLO.Temp.languageCode));
            Util.showElement("div_fundraiser_second_custom_field");
        } else
            Util.hideElement("div_fundraiser_second_custom_field");

        return true;
    }

    private _setEventListeners = (): void => {
        const self = this;
        Util.setElement("onclick", "btn_fundraiser_close", () => { self.Close(); });
        Util.setElement("onclick", "btn_fundraiser_save", () => { self._save(); });
        Util.setElement("onclick", "btn_fundraiser_cancel", () => { self.Close(); });
    }

    private _getFundraiserByCode = (): IDataFundraiser | null => {
        return this._localaOLO.data.Fundraisers.find(x => x.Code == this._fundraiserCode && new Date(x.EndDateTime) >= Util.NowStore(this._localaOLO.Temp.TimeOffset)) || null;
    }

    private _save = (): void => {
        const firstField = document.getElementById("div_fundraiser_first_custom_field");
        const secondField = document.getElementById("div_fundraiser_second_custom_field");

        if (firstField && !firstField.classList.contains("hidden")) {
            if (Util.getElementValue("txt_fundraiser_first_custom_field") == "") {
                const text = document.getElementById("lbl_fundraiser_first_custom_field")?.innerText || "";
                DialogCreators.messageBoxOk(Names("FundraiserValueIsEmpty") + text, this._localaOLO.buttonHoverStyle);
                return;
            } else if (this._localaOLO.Order.Fundraiser) {
                this._localaOLO.Order.Fundraiser.FIRST = Util.getElementValue("txt_fundraiser_first_custom_field");
            }
        }

        if (secondField && !secondField.classList.contains("hidden")) {
            if (Util.getElementValue("txt_fundraiser_second_custom_field") == "") {
                const text = document.getElementById("lbl_fundraiser_second_custom_field")?.innerText || "";
                DialogCreators.messageBoxOk(Names("FundraiserValueIsEmpty") + text, this._localaOLO.buttonHoverStyle);
                return;
            } else if (this._localaOLO.Order.Fundraiser) {
                this._localaOLO.Order.Fundraiser.SECOND = Util.getElementValue("txt_fundraiser_second_custom_field");
            }
        }

        DialogCreators.messageBoxOk(Names("FundraiserCodeOK"), this._localaOLO.buttonHoverStyle);
        Util.showElement("div_cart_fundraiser");

        Util.setElement("innerText", "div_cart_fundraiser_name", this._fundraiser?.Name || "");
        Util.setElement("onclick", "btn_cart_fundraiser_remove", () => {
            Util.hideElement("div_cart_fundraiser");
            this._localaOLO.Order.Fundraiser = null;
        });

        this.Close();
    }

    public Close = (): void => {
        Util.DialogFadeOut("dia_fundraiser");
    }
}