import { LoyaltyProvider } from "../../types/enums";
import { IaOLO } from "../../interfaces/aolo.interface";
import { LoyaltyService } from "../../services/api/loyalty-service";
import { ILoyaltyProvider } from "./interfaces/loyalty-provider.interface";
import { AdoraProvider } from "./providers/adora-provider";
import { NoneProvider } from "./providers/none-provider";
import { PunchhProvider } from "./providers/punchh-provider";

export class LoyaltyProviderFactory {

    static getLoyaltyProvider(localAolo: IaOLO): ILoyaltyProvider {
        const loyaltyService = new LoyaltyService(localAolo, localAolo.LoyaltyApiUrl, localAolo.pkey);

        const couponLimit = localAolo.data.Settings.CPNLIM;
        let oneCouponLimit = false;
        if (couponLimit)
            oneCouponLimit = (couponLimit === 2 || couponLimit === 3);

        switch (localAolo.data.Loyalty.ProviderId) {
            case LoyaltyProvider.ADORA:
                return new AdoraProvider(localAolo, loyaltyService, oneCouponLimit);
            case LoyaltyProvider.PUNCHH:
                return new PunchhProvider(localAolo, loyaltyService, oneCouponLimit);
            case LoyaltyProvider.NONE:
                return new NoneProvider(localAolo, loyaltyService, oneCouponLimit);
        }

        return new NoneProvider(localAolo, loyaltyService, oneCouponLimit);
    }
}