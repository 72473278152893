import { ILoginAsync } from "../../components/popups/Login/login.interface";
import { AdoraHttpClient } from "../../utils/http-client";
import { IaOLO } from "../../interfaces/aolo.interface";
import { IUser } from "../../models/interfaces/user.interface";
import { Util } from "../../utils/util";
import { ISignInResponse, IaOLOUser, IaOLOUserLoyaltyReward, IGetUserResponse} from "./interfaces/profile-service.interfaces";
import { IAddDiscountToOrder, IBatchRedemptionProcess, IFetchGiftCardsBalance, IFetchGiftCards, IGetActiveDiscountBasket, IGetStoreLoyaltyConfigurationInfo, ILoyaltyServiceGenericResponse, IRemoveDiscountFromOrder, ISignInViaSSOAsync, ISignInAsync, ICreateFinishOrderPayloadLoyaltyResult, IPostOrderResponse } from "./interfaces/loyalty-service.interface";

export class LoyaltyService {
    private readonly _apiLoyaltyUrl: string;
    private readonly _publicKey: string;
    private readonly _aOLO: IaOLO;

    constructor(localAolo: IaOLO, loyaltyApiUrl: string, publicKey: string) {
        this._apiLoyaltyUrl = loyaltyApiUrl;
        this._publicKey = publicKey;
        this._aOLO = localAolo;
    }

    get jwt(): string | null {
        return AdoraHttpClient.getJWT();
    }

    get refresh_token(): string | null {
        return AdoraHttpClient.getRefreshToken();
    }

    /**
    * Makes an HTTP request.
    * @private
    * @async
    * @param {string} method - The HTTP method (e.g. 'GET', 'POST', 'PUT', 'DELETE').
    * @param {string} url - The URL of the HTTP resource.
    * @param {object|null} payload - The payload to send with the request.
    * @param {string|null} token - The authorization token to send with the request.
    * @returns {Promise<Response>} A promise that resolves to the HTTP response.
    */
    private readonly _httpRequest = async (method: string, url: string, payload: object | null, token: string | null = null, refreshToken: string | null = null, showProgress: boolean = true, noCache: boolean = true): Promise<Response> => {
        let businessDate = new Date().toISOString();
        try {
            businessDate = Util.GetBUSDate(Util.NowStore(this._aOLO.Temp.TimeOffset), this._aOLO).toISOString();
        } catch { }
        const response = await AdoraHttpClient.httpRequest(showProgress, method, url, payload, token, refreshToken, noCache, this._aOLO.storeInfo.StoreKey, businessDate);

        if (response.status !== 401) {
            if (response.status === 408)
                return response;

            // Check for new JWT
            if (response.headers.has('Authorization')) {
                const authHeader = response.headers.get('Authorization');
                const token = authHeader?.split(' ')[1] || "";
                AdoraHttpClient.setJWT(token);
            }
            if (response.headers.has('rtk')) {
                const refreshToken = response.headers.get('rtk');
                if (refreshToken)
                    AdoraHttpClient.setRefreshToken(refreshToken);
            }
            return response;
        }

        this.notAuthHttpResourceEvent();
        return response;
    }

    private readonly _mapUser = (data: IaOLOUser): IUser => {
        const user: IUser = {
            CustomerId: data.CID,
            ProfileId: data.PID,
            LoyaltyReferenceID: data.LRID,
            StoreId: data.SID,
            Name: data.NAM,
            LastName: data.LNAM,
            Phone: data.PHN,
            CountryCodeId: data.CCDID,
            Email: data.EML,
            IsMarketingSurvey: data.ISV,
            IsMarketingEmail: data.IEML,
            IsMarketingText: data.ITXT,
            MarketingPhone: data.MKPHN,
            MarketingCountryId: data.MKCCDID,
            BirthDate: data.BD,
            IsFavoriteStore: data.FAVST,
            FavoriteStoreId: data.FAVSTID,
            FavoriteStores: data.FAVSTRs?.map(x => x.SKEY) || [],
            CultureCode: data.CLT,
            IsDarkMode: data.IDK,
            IsLoyalty: data.ILY,
            IsBlocked: data.IBLKD,
            IsAge13: data.A13,
            TermsPrivacyPolicy: data.TPP,
            Addresses: data.ADRS,
            Allergies: data.ALGS,
            Dietary: data.DITS,
            Wallet: data.WLTS,
            IsProfileComplete: data.IPC,
            LoyaltyData: data.LOYDT ? {
                Rewards: data.LOYDT.rewards?.map((rew: IaOLOUserLoyaltyReward) => ({
                    RewardId: rew.RID,
                    CouponId: rew.CPID,
                    ExpiryDate: rew.EXDT,
                    Expired: rew.EXP,
                    Names: rew.NAMs,
                    Amount: rew.discount_amount
                })) || [],
                CurrentPoints: data.LOYDT.current_points,
                EarnedPoints: data.LOYDT.earned_points,
                PointsLastUpdatedDate: data.LOYDT.points_last_updated_date,
                LoyaltyJoinedDate: data.LOYDT.loyalty_joined_date,
                TierId: data.LOYDT.tier,
                TierName: data.LOYDT.tier_name || null,
                BankedCurrency: data.LOYDT.banked_currency || null,
                UnbankedPoints: data.LOYDT.unbanked_points || null,
            } : null,
            Offers: data.Offers || null,
            DefaultStore: data.DEFST ? {
                StoreKey: data.DEFST.SKEY,
                StreetNumber: data.DEFST.STRNO,
                Address1: data.DEFST.ADDR1,
                Address2: data.DEFST.ADDR2,
                CountryId: data.DEFST.CID
            } : null,
        };

        return user;
    }

    /**
     * Callback function for not authorized HTTP resource event.
     * @returns {void}
     */
    public notAuthHttpResourceEvent = (): void => {
        // 2DO: Emit LogOut

        const self = this;
        //Common.logout(this._aOLO.isBrandSignIn ? function () {
        //    if (self._brandFunction)
        //        self._brandFunction("signin", true);
        //} : async () => {
        //    if (self._oloFunction)
        //        self._oloFunction(this._aOLO.Temp.languageCode)
        //}, aOLO, this._user, true);
    }

    /*********************************
     ******* Loyalty API Calls *******
     *********************************/
    /**
     * Attempts to log in the user with the given credentials.
     * @async
     * @param brandId The ID of the brand to log in to.
     * @param email The user's email address.
     * @param password The user's password.
     * @param culture The user's preferred language/culture.
     * @param rememberMe The user's selection of the "Remember Me" option.
     * @returns {Promise<ISignInResponse>} 
     *          An object with a `success` property indicating whether the login was successful, and optionally a `user` property containing the user's data if the login was successful.
     */
    public signIn = async (data: ILoginAsync): Promise<ISignInResponse> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": data.storeKey, "profile": { "EML": data.email, "pwd": data.password }, "is_app": data.app, "remember_me": data.rememberMe, "incomplete_profile": data.incompleteProfile, "location": "olo" }), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/sign_in`, payload);

        if (response.status == 200) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            if (result.success) {
                const data = JSON.parse(result.data);
                const user = this._mapUser(data.User);
                const jwt = data.JWT;
                const token = data.Token ?? null;
                const refreshToken = data.RefreshToken;
                return { success: true, loggedIn: true, user: user, jwt: jwt, refreshToken: refreshToken, token: token, timeout: false };
            }
            return { success: false, loggedIn: false, message: result.message, timeout: false }
        }
        return { success: false, loggedIn: false, timeout: response.status === 408 };
    }

    public signInViaSSO = async (data: ISignInViaSSOAsync): Promise<ISignInResponse> => {
        const payload = await Util.encrypt(JSON.stringify(data), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/sign_in_sso`, payload);

        if (response.status == 200) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            if (result.success) {
                const data = JSON.parse(result.data);
                const user = this._mapUser(data.User);
                const jwt = data.JWT;
                const token = data.Token ?? null;
                const refreshToken = data.RefreshToken;
                return { success: true, loggedIn: true, user: user, jwt: jwt, refreshToken: refreshToken, token: token, timeout: false };
            }
            return { success: false, loggedIn: false, message: result.message, timeout: false }
        }
        return { success: false, loggedIn: false, timeout: response.status === 408 };
    }

    public signInByApp = async (appToken: string, localAolo: IaOLO): Promise<ISignInResponse> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": localAolo.storeInfo.StoreKey, "appToken": appToken }), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/sign_in_by_app`, payload);
        if (response.status == 200) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            if (result.success) {
                const data = JSON.parse(result.data);
                const user = this._mapUser(data.User);
                const jwt = data.JWT;
                const token = data.Token;
                return { success: true, loggedIn: true, user: user, jwt: jwt, token: token, timeout: false };
            }
            return { success: false, loggedIn: false, message: result.message, timeout: false }
        }
        return { success: false, loggedIn: false, timeout: response.status === 408 };
    }

    /**
     * Gets the user's profile data from the server.
     * @async
     * @returns {Promise<any|null>} A promise that resolves with the user's profile data if successful, otherwise null.
     */
    public getUser = async (): Promise<IGetUserResponse> => {
        if (!this.jwt)
            return { success: false, timeout: false };

        const encryptedPayload = await Util.encrypt(JSON.stringify({ store_key: this._aOLO.storeInfo.StoreKey }), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/get_user`, encryptedPayload, this.jwt, this.refresh_token, true);

        if (response.status == 200) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            if (result.success) {
                const data = JSON.parse(result.data);
                const user = this._mapUser(data.profile);
                const jwt = data.jwt;
                return { success: true, user: user, jwt: jwt, timeout: false }
            }
            return { success: false, message: result.message, timeout: false };
        }
        return { success: false, timeout: response.status === 408 };
    }

    public async addDiscountToOrder(payload: IAddDiscountToOrder): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/discount/add_selection_to_basket`, encryptedPayload, this.jwt, this.refresh_token, true);

        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }

    public async removeDiscountFromOrder(payload: IRemoveDiscountFromOrder): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/discount/remove_item_from_basket`, encryptedPayload, this.jwt, this.refresh_token, true);

        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }

    public async getActiveDiscountBasket(payload: IGetActiveDiscountBasket): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/discount/get_active_basket`, encryptedPayload, this.jwt, this.refresh_token, true);

        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }

    public async fetchGiftCards(payload: IFetchGiftCards): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/fetch_gift_cards`, encryptedPayload, this.jwt, this.refresh_token, true);
        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }

    public async fetchGiftCardsBalance(payload: IFetchGiftCardsBalance): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/fetch_gift_cards_balance`, encryptedPayload, this.jwt, this.refresh_token, true);
        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }

    public async postOrderAsync(payload: ICreateFinishOrderPayloadLoyaltyResult): Promise<IPostOrderResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/post_order`, encryptedPayload, null, null, true);
        if (response.status !== 200)
            return { success: false, message: await AdoraHttpClient.getResponsePayload(response)};

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, message: data.message };
    }

    /****************************
     ******* Punchh Calls *******
     ****************************/
    public getStoreLoyaltyConfigurationInfo = async (payload: IGetStoreLoyaltyConfigurationInfo): Promise<ILoyaltyServiceGenericResponse> => {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/configuration`, encryptedPayload, this.jwt, this.refresh_token, true);
        if (response.status == 401)
            return { success: false, data: null, message: "Unauthorized", timeout: false };

        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: null, timeout: false };
    }

    public async batchRedemptionProcess(payload: IBatchRedemptionProcess): Promise<ILoyaltyServiceGenericResponse> {
        const encryptedPayload = await Util.encrypt(JSON.stringify(payload), this._publicKey);
        const response = await this._httpRequest('POST', `${this._apiLoyaltyUrl}olo/discount/batch_redemption`, encryptedPayload, this.jwt, this.refresh_token, true);
        if (response.status !== 200)
            return { success: false, data: null, message: await AdoraHttpClient.getResponsePayload(response), timeout: response.status === 408 };

        const data = await AdoraHttpClient.getResponsePayload(response);
        return { success: data.success, data: data.data, message: data.message, timeout: false };
    }
}