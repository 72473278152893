import { LoyaltyProvider } from "../../../types/enums";
import { IUser, IUserLoyaltyReward } from "../../../models/interfaces/user.interface";
import { IGetUserResponse, ISignInResponse } from "../../api/interfaces/profile-service.interfaces";
import { ILoyaltyProvider } from "../interfaces/loyalty-provider.interface";
import { Util } from "../../../utils/util";
import { LoyaltyService } from "../../api/loyalty-service";
import { IaOLO } from "../../../interfaces/aolo.interface";
import { IProfile } from "../../../shared/interfaces/profile.interfaces";
import { Names } from "../../../utils/i18n";
import { DialogCreators } from "../../../utils/dialog-creators";
import { IOrder } from "../../../interfaces/order.interface";
import { ILoginAsync } from "../../../components/popups/Login/login.interface";

export class NoneProvider implements ILoyaltyProvider {
    private _loyaltyProvider: LoyaltyProvider = LoyaltyProvider.NONE;
    private _loyaltyService: LoyaltyService;
    private _phoneFormatLanguage: string;
    private _aOLO: IaOLO;
    private _hasPointRedemption: boolean = false;
    private _hasRewardRedemption: boolean = false;
    private _hasMembershipTiers: boolean = false;
    private _hasBankedCurrency: boolean = false;
    private _hasDiscountCodes: boolean = false;
    private _hasSingleSignOn: boolean = false;
    private _onlyOneDiscountAllowed: boolean = false;

    constructor(localAolo: IaOLO, loyaltyService: LoyaltyService, onlyOneDiscountAllowed: boolean) {
        this._loyaltyService = loyaltyService;
        this._aOLO = localAolo;
        this._onlyOneDiscountAllowed = onlyOneDiscountAllowed;
        this._phoneFormatLanguage = Util.getDefaultCultureCode(this._aOLO.User?.CountryCodeId || this._aOLO.storeInfo?.Country?.CountryID || 1, this._aOLO.data?.Countries);
    }

    hasPointRedemption(): boolean {
        return this._hasPointRedemption;
    }

    hasRewardRedemption(): boolean {
        return this._hasRewardRedemption;
    }

    hasMembershipTiers(): boolean {
        return this._hasMembershipTiers;
    }

    hasBankedCurrency(): boolean {
        return this._hasBankedCurrency;
    }

    hasDiscountCodes(): boolean {
        return this._hasDiscountCodes;
    }

    hasSingleSignOn(): boolean {
        return this._hasSingleSignOn;
    }

    onlyOneDiscountAllowed(rewardId: number | null): boolean {
        if (!this._onlyOneDiscountAllowed)
            return false;

        if (this._aOLO.Order.Coupons.length > 0) {
            DialogCreators.messageBoxOk(Names("OnlyOneDiscountAllowedAtATimeError"), this._aOLO.buttonHoverStyle);
            return true;
        }

        if (this._aOLO.Temp.IncompleteDiscounts.length > 0 && !this._aOLO.Temp.IncompleteDiscounts.map(x => x.rewardId).includes(rewardId)) {
            DialogCreators.messageBoxOk(Names("OnlyOneDiscountAllowedAtATimeError"), this._aOLO.buttonHoverStyle);
            return true;
        }

        return false;
    }

    discountValidForOrderTime(expiryDate: string | null): boolean {
        return false;
    }

    isLoyaltyDown(): boolean {
        return false;
    }

    getLoyaltyProvider(): LoyaltyProvider {
        return this._loyaltyProvider;
    }

    getHamburgerMenuOptions(): string[] {
        return [
            'li_vertical_menu_profile',
            'li_vertical_menu_orders',
            'li_vertical_menu_logout',
        ];
    }

    signInAsync = async (data: ILoginAsync): Promise<ISignInResponse> => {
        const result: ISignInResponse = await this._loyaltyService.signIn(data);

        const isApp = Util.isAppView();
        if (isApp && result.success && result.token) {
            //@ts-ignore
            if (typeof saveAppUserToken == "function" && result.token != null)
                //@ts-ignore
                saveAppUserToken(result.token);
        }
        return result;
    }

    signInViaSSOAsync = async (): Promise<ISignInResponse> => {
        throw new Error("Method not implemented.");
    }

    signInByAppAsync = async (token: string): Promise<ISignInResponse> => {
        const result = await this._loyaltyService.signInByApp(token, aOLO);

        const isApp = Util.isAppView();
        if (isApp && result.success && result.token) {
            //@ts-ignore
            if (typeof saveAppUserToken == "function" && result.token != null)
                //@ts-ignore
                saveAppUserToken(result.token);
        }
        return result;
    }

    /**
     * Gets the user's profile data from the server.
     * @async
     * @returns {Promise<any|null>} A promise that resolves with the user's profile data if successful, otherwise null.
     */
    getUserAsync = async (): Promise<IGetUserResponse> => {
        return await this._loyaltyService.getUser();
    }

    getStartupInfo = async (): Promise<void> => {
        return;
    }

    getActiveDiscountBasket = async (): Promise<void> => {
        return;
    }

    addReward = async (reward: any): Promise<void> => {
        return;
    }

    removeReward = async (reward: IUserLoyaltyReward): Promise<void> => {
        return;
    }

    getAppliedBankedCurrency(): number {
        return 0;
    }

    applyBankedCurrency = async (currencyToApply: number): Promise<void> => {
        return;
    }

    removeBankedCurrency = async (): Promise<void> => {
        return;
    }

    batchComparison = async (query: boolean): Promise<boolean> => {
        return true;
    }

    fetchGiftCards = async (passcode: string | null): Promise<any> => {
        return;
    }

    fetchGiftCardsBalance = async (uuids: string[]): Promise<any> => {
        return;
    }

    renderSurveyOffersList(closeDialogFunction: Function, brandFunction: Function | null): void {
        return;
    }

    renderMembershipList(): void {
        return;
    }

    renderUserLoyaltyBalance(): void {
        return;
    }

    renderRewardsList(closeDialogFunction: Function): void {
        return;
    }

    setProfileValues = (profile: IProfile, isSignUp: boolean, monthOnClickFunction: Function, signUpFunction: Function, updateProfileFunction: Function,
        renderAddressListFunction: Function, setUserAllergiesFunction: Function, setUserDietaryFunction: Function, deleteProfileFunction: Function): void => {
        let header = document.getElementById("h2_profile_header");
        let btn = document.getElementById("btn_profile_profile");
        let btnDelete = document.getElementById("btn_profile_delete");

        Util.hideElement("div_profile_join_loyalty");

        monthOnClickFunction();

        Util.hideElement("div_profile_age_13");
        Util.setElementClass("remove", "div_profile", "condensed");

        const allergies = document.getElementById("div_profile_allergies");
        const dietary = document.getElementById("div_profile_dietary");

        if (profile.ProfileId != 0) {
            Util.showElement("div_profile_delete");
            Util.hideElement("div_profile_password1");
            Util.hideElement("div_profile_password2");
            Util.hideElement("div_profile_terms_privacy_policy");
            Util.showElement("div_profile_addresses")
            //Util.showElement("div_profile_wallet")

            if (header) {
                header.innerText = Names("Profile");
                header.setAttribute("ltag", "Profile");
            }
            if (btnDelete) {
                btnDelete.innerText = Names("DeleteMyProfile");
                btnDelete.setAttribute("ltag", "DeleteMyProfile");
                btnDelete.onclick = function () {
                    deleteProfileFunction();
                };
            }
            if (btn) {
                btn.innerText = Names("UpdateMyProfile");
                btn.setAttribute("ltag", "UpdateMyProfile");
                btn.onclick = function () { updateProfileFunction };
            }

            Util.setElement("disabled", "txt_profile_mark_promo_text", !profile.IsMarketingText);

            if (profile.Addresses?.length > 0) {
                Util.showElement("div_profile_all_addresses");
                Util.hideElement("spn_profile_addresses")
                renderAddressListFunction()
            } else {
                Util.hideElement("div_profile_all_addresses")
                Util.showElement("spn_profile_addresses");
            }


            if (aOLO.data.Settings?.PRIVPOL === 0 && !profile.IsAge13)
                Util.showElement("div_profile_age_13");

            if (aOLO.User && aOLO.User.IsProfileComplete == false) {
                Util.showElement("div_profile_terms_privacy_policy");
                if (!profile.Email) {
                    Util.showElement("div_profile_password1");
                    Util.showElement("div_profile_password2");
                }
            }
        } else {
            Util.hideElement("div_profile_delete");
            Util.showElement("div_profile_password1");
            Util.showElement("div_profile_password2");
            Util.showElement("div_profile_terms_privacy_policy");
            Util.hideElement("div_profile_addresses")
            Util.hideElement("div_profile_wallet")

            if (header) {
                header.innerText = Names("CreateAccount");
                header.setAttribute("ltag", "CreateAccount");
            }

            if (btn) {
                btn.innerText = Names("SignUp");
                btn.setAttribute("ltag", "SignUp");
                btn.onclick = function () { signUpFunction() };
            }

            if (aOLO.SignUpLoyaltyLink?.PhoneNumber)
                Util.setElement("value", "txt_profile_phone", Util.formatPhoneNumber(profile.Phone, Names("PhoneFormat", this._phoneFormatLanguage)));

            Util.setElement("disabled", "txt_profile_mark_promo_text", true);

            if (aOLO.data.Settings?.PRIVPOL === 0)
                Util.showElement("div_profile_age_13");

            if (allergies && allergies.classList.contains("hidden") && dietary && dietary.classList.contains("hidden"))
                Util.setElementClass("add", "div_profile", "condensed");
        }

        if (aOLO.data.Settings?.BDAYREQ === 0)
            Util.hideElement("div_profile_birthday");

        Util.setElement("value", "txt_profile_phone", Util.formatPhoneNumber(profile.Phone, Names("PhoneFormat", this._phoneFormatLanguage)));

        Util.setElement("value", "sel_profile_birth_month", profile.BirthMonth);
        Util.setElement("value", "sel_profile_birth_day", profile.BirthDay);
        Util.setElement("value", "txt_profile_mark_promo_text", (profile.MarketingPhone ? Util.formatPhoneNumber(profile.MarketingPhone, Names("PhoneFormat", this._phoneFormatLanguage)) : Util.formatPhoneNumber(profile.Phone, Names("PhoneFormat", this._phoneFormatLanguage))));

        setUserAllergiesFunction();
        setUserDietaryFunction()

        if (aOLO.User && aOLO.User.IsProfileComplete == false)
            Util.showElement("spn_profile_incomplete_error");

        if (aOLO.isBrandSignIn)
            Util.showElement("div_profile_order_now");
        else
            Util.hideElement("div_profile_order_now");
    }

    renderAddressList = (profile: IProfile, addressEditFunction: Function, addressRemoveFunction: Function, addressSetDefaultFunction: Function): void => {
        let div = document.getElementById("div_profile_all_addresses");
        if (div) {
            div.innerHTML = "";

            profile.Addresses.forEach(address => {
                let adr = `${address.STRNO} ${address.ADDR1}${address.ADDR2 ? ` address.ADDR2` : ``}, ${address.CITY}, ${address.STA} ${address.ZIP}`;
                let html = `
                    <div id="div_profile_address_${address.AID}" class="m1-tb">
                        <span>${adr}</span> <br>
                        <div>
                            <a id="a_profile_address_${address.AID}_edit" name="a_profile_address_edit" data-id="${address.AID}" class="aolo-link" ltag="Edit">${Names("Edit")}</a>  |  <a id="a_profile_address_${address.AID}_remove" name="a_profile_address_remove" data-id="${address.AID}" class="aolo-link" ltag="Remove">${Names("Remove")}</a>
                            ${(address.IPRM == false) ? ` | <a id="a_profile_address_${address.AID}_default" name="a_profile_address_default" data-id="${address.AID}" class="aolo-link" ltag="SetAsDefault">${Names("SetAsDefault")}</a>` : ``}
                        </div>
                    </div>`;

                if (div)
                    div.innerHTML += html;
            });
        }

        let editButtons = document.getElementsByName("a_profile_address_edit");
        editButtons.forEach(button => {
            Util.setElement("onclick", button, addressEditFunction.bind(null, button.dataset.id || ""));
        });

        let removeButtons = document.getElementsByName("a_profile_address_remove");
        removeButtons.forEach(button => {
            Util.setElement("onclick", button, addressRemoveFunction.bind(null, button.dataset.id || ""));
        });

        let defaultButtons = document.getElementsByName("a_profile_address_default");
        defaultButtons.forEach(button => {
            Util.setElement("onclick", button, addressSetDefaultFunction.bind(null, button.dataset.id || ""));
        });

    }

    setIncompleteReward(rewardId: number | null): void {
        return;
    }

    postOrder = async (order: IOrder, redemptionIds?: number[] | undefined): Promise<void> => {
        return;
    }
}