import { IDataStore } from "../interfaces/data.interface";
import { IWaitTime } from "./interfaces/call-center-stores.interface";
import { Names } from "../utils/i18n";
import { Common } from "../common";
import { OrderType } from "../types/enums";
import { Util } from "../utils/util";
import { DialogCreators } from "../utils/dialog-creators";
import { OnlineOrderingUtil } from "./online-ordering-util";

import '../../css/online-ordering/call-center-stores.css';
import { IaOLO } from "../interfaces/aolo.interface";

export class CallCenterStoresDialog {
    private _setStoreOrderInfo: (storeId: number, orderTypeId: number) => Promise<void>;

    constructor(setStoreOrderInfo: (storeId: number, orderTypeId: number) => Promise<void>) {
        this._setStoreOrderInfo = setStoreOrderInfo;
        this._init();
        Util.DialogFadeIn("dia_call_center_stores", aOLO);
    }

    private _init = (): void => {
        this._renderPage();
        this._setEventListeners();
    }

    private _setEventListeners = (): void => {
        let closeBtn = document.getElementById("btn_call_center_stores_close");
        if (closeBtn)
            closeBtn.onclick = async () => { await this._closeDialogButton(); };
    }

    private _renderPage = (): void => {
        for (let i = 0; i < aOLO.data.Stores.length; i++) {
            let store = aOLO.data.Stores[i];
            let storeLat = Util.Float12(store.Latitude);
            let storeLng = Util.Float12(store.Longitude);
            store.Distance = !aOLO.location.validated ? 0 : Util.Float2(Util.GetDistance(aOLO.location.latitude, aOLO.location.longitude, storeLat, storeLng, "M"));
        }
        aOLO.data.Stores.sort((a: IDataStore, b: IDataStore) => a.Distance - b.Distance);

        Util.removeChildren("div_call_center_stores");
        const divCCStores = document.getElementById("div_call_center_stores");
        const date = this._getInputDate();
        for (const store of aOLO.data.Stores) {
            const div = this._createStore(store, date);

            if (divCCStores)
                divCCStores.appendChild(div);
        }

        if (aOLO.Modules.Categories)
            Util.setElement("checked", `rdo_call_center_store_${aOLO.Order.StoreID}`, true);
    }

    private _createStore = (store: IDataStore, date: Date): HTMLElement => {
        const wtfo = this._getStoreWaitTime(store, OrderType.TAKE_OUT);
        if (wtfo?.FutureOrders === true) {
            const holiday = OnlineOrderingUtil.CheckHolidays(date, false, store.StoreId, aOLO);
            if (holiday?.HolidayStatusId === 5) //Open no online Time/Future ordering
                wtfo.FutureOrders = false;
        }

        const html = `
            <input type="radio" name="callCenterStore" id="rdo_call_center_store_${store.StoreId}">
            <label for="rdo_call_center_store_${store.StoreId}">
                <img src="${aOLO.data.Settings.CURL}/stores/store-${store.StoreId}.jpg" alt="store logo">
                <span>${store.Nickname}</span>
                <span>${store.Address1}</span>
                <span>${store.City}, ${store.State} ${store.ZipCode}</span>
                <span>${wtfo ? `Wait Time:<b>${wtfo.WaitTime} Minutes</b>` : ``}</span>
                <span>${(wtfo?.FutureOrders === false) ? `<b>Not accepting deferred orders.</b>` : ``}</span>
                <span class="right">${(store.Distance > 0) ? `${store.Distance} miles` : ``}</span>
            </label>`;

        const div = document.createElement("div");
        div.classList.add("callCenterStore");
        div.innerHTML = html;

        const radioButton = div.querySelector(`#rdo_call_center_store_${store.StoreId}`) as HTMLInputElement;
        if (radioButton)
            radioButton.onchange = async () => {
                await this._setCallCenterStore(store.StoreId);
            };

        return div;
    }

    private _getStoreWaitTime = (store: IDataStore, orderTypeID: number): IWaitTime | null => {
        const waitTime = store.WaitTimes.find(x => x.OrderTypeId == orderTypeID);
        if (!waitTime)
            return null;

        if (store.AutoWaitTimes) {
            const tMinutes = Util.nowTodayMinute(aOLO.Temp.TimeOffset);
            const savedWaitTime = store.AutoWaitTimes.find(x => x.MIN == (tMinutes - tMinutes % 15));

            if (savedWaitTime) {
                if (waitTime.OrderTypeId === 3 && !isNaN(savedWaitTime.DELMIN) && savedWaitTime.DELMIN > 0)
                    return {
                        WaitTime: savedWaitTime.DELMIN,
                        FutureOrders: waitTime.FutureOrders
                    };
                else if (waitTime.OrderTypeId === 3 && !isNaN(savedWaitTime.MAKEMIN) && savedWaitTime.MAKEMIN > 0)
                    return {
                        WaitTime: savedWaitTime.MAKEMIN,
                        FutureOrders: waitTime.FutureOrders
                    };
            }
        }

        return {
            WaitTime: waitTime.WaitTime,
            FutureOrders: waitTime.FutureOrders
        };
    }

    private _getInputDate = (): Date => {
        let later = Util.getElementChecked("rdo_order_type_time_later");
        if (!later)
            return Util.NowStore(aOLO.Temp.TimeOffset);

        Util.setElementClass("add", "div_order_type_time_later", "accordion-open");
        return Util.GetDateInputDate(Util.getElementValue("txt_order_type_time_later_date")) || Util.NowStore(aOLO.Temp.TimeOffset);
    }

    private _setCallCenterStore = async (storeID: number, orderTypeID?: number): Promise<void> => {
        let otid = aOLO.Temp.OrderTypeID ? aOLO.Temp.OrderTypeID : (orderTypeID ?? 1);

        if (!this._checkOrderItemsForCCStore(storeID, aOLO)) {
            Util.setElement("checked", `rdo_call_center_store_${aOLO.Order.StoreID}`, true);
            return;
        }

        if (otid !== OrderType.DELIVERY) {
            const store = OnlineOrderingUtil.getCallCenterStore(storeID, aOLO.data.Stores);
            if (!aOLO.location.validated)
                OnlineOrderingUtil.getLocation(aOLO);
            
            if (store && aOLO.location.validated) {
                const storeLat = Util.Float12(store.Latitude);
                const storeLng = Util.Float12(store.Longitude);
                OnlineOrderingUtil.CheckCustomerDistance(storeLat, storeLng,  aOLO, async () => {
                    await this._setOrderStore(storeID, otid);
                }, function () {
                    //do nothing
                });
            } else
                await this._setOrderStore(storeID, otid);
        } else
            await this._setOrderStore(storeID, otid);
    }

    private _setOrderStore = async (storeId: number, orderTypeId: number): Promise<void> => {
        await this._setStoreOrderInfo(storeId, orderTypeId);
        this.closeDialog();
    }

    private _checkOrderItemsForCCStore = (storeID: number, localAolo: IaOLO): boolean => {
        let isOkay = true;
        const store = OnlineOrderingUtil.getCallCenterStore(storeID, aOLO.data.Stores);
        if (store) {
            const storeItems = store.Items;
            for (let i = 0; i < localAolo.Order.Items.length; i++) {
                const oItem = localAolo.Order.Items[i];
                if (storeItems.indexOf(oItem.ItemId.toString()) === -1) {
                    const mItem = localAolo.data.Items[oItem.Index];
                    let msg = Names("NotOfferedInSelStore");
                    msg = msg.replace("??", Common.GetName(mItem.Names, localAolo.Temp.languageCode));
                    DialogCreators.messageBoxOk(msg, localAolo.buttonHoverStyle);
                    isOkay = false;
                    break;
                }
            }
        }
        return isOkay;
    }

    private _closeDialogButton = async (): Promise<void> => {
        this.closeDialog();
        if (aOLO.Temp.SelectedStoreID === 0 && aOLO.Dialog.OrderType)
            await aOLO.Dialog.OrderType.CallCenterStoreNotSelected();
    }

    public closeDialog = (): void => {
        Util.DialogFadeOut("dia_call_center_stores");
    }
}