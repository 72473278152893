import '../../css/progress.css';

import { Util } from "../utils/util";
import { Names } from "../utils/i18n";
import { DialogManager } from "../utils/dialog";
import { IaOLO } from "../interfaces/aolo.interface";

export class DialogCreators {
    /*******************************************/
    /**************** Input Box ****************/
    /*******************************************/

    private static inputBoxElement(message: string | HTMLElement, headerClose: boolean, headerText: string | null,
        checked: boolean | null, lblCheckbox: string | null, isPassword?: boolean | null): HTMLElement {

        const text = (message instanceof HTMLElement) ? message.outerHTML : message;
        const header = (headerClose || headerText) ? true : false;
        const html = `
            ${header ? `
                <div id="div_inputbox_dialog_header" class="formHeaderText">
                    ${headerText ? `<h1 id="h1_inputbox_dialog_header_text">${headerText}</h1>` : ``}
                    ${headerClose ? `<button id= "btn_inputbox_dialog_header_close" aria-label="close" class="btnLink icon-cancel" />` : ``}
                </div>` : ``}

            <div class="inputbox-body">
                <div>
                    <label id="p_inputbox_message" for="txt_inputbox" class="center">${text}</label>
                    <input type="${!!isPassword ? 'password' : 'text'}" id="txt_inputbox" autocomplete="false">
                </div>
                ${checked != null && lblCheckbox ?
                `<div class="p1-tb p1-l m2-t checkRadio" style="display: flex; align-items:center; justify-items:center; margin-left:0.5rem; margin-top:15px;">
                        <input type="checkbox"  ${checked ? 'checked' : ''} id="chk_inputbox" autocomplete="false">
                        <label id="lbl_chk_inputbox_message" for="chk_inputbox" class="center">${lblCheckbox}</label>
                    </div>` : ''}
                <div id="div_inputbox_buttons"></div>
            </div>`;

        const dialog = document.createElement("dialog");
        dialog.innerHTML = html;
        dialog.id = `dia_inputbox_${Math.floor(Math.random() * 99999) + 1}`;
        dialog.classList.add("inputbox");
        return dialog;
    }

    /**
    * Opens an input box dialog that prompts the user to enter a value.
    * @param {string} message - The message to display in the input box dialog.
    * @param {string} saveBtnText - The text to display on the "Save" button.
    * @param {string} cancelBtnText - The text to display on the "Cancel" button.
    * @param {string | null} focusElementID - The ID of the element to focus after closing the input box dialog.
    * @param {Function} [saveCallback] - The callback function to call when the user clicks the "Save" button. It takes a single parameter, which is the value entered in the input box.
    * @param {Function} [cancelCallback] - The callback function to call when the user clicks the "Cancel" button. It takes a single parameter, which is the value entered in the input box.
    * @returns {void}
    */
    public static InputBox(message: string, headerClose: boolean, headerText: string | null, focusElementID: string | null, localAolo: IaOLO,
        buttons: { text: string, close: boolean, callBack: ((value: string) => void) | null }[],
        checked: boolean | null, lblCheckbox: string | null, isPassword?: boolean | null): void {

        const dialog = this.inputBoxElement(message, headerClose, headerText, checked, lblCheckbox, isPassword);

        const inputBox = dialog.querySelector("#txt_inputbox") as HTMLInputElement;
        const buttonsDiv = dialog.querySelector("#div_inputbox_buttons") as HTMLButtonElement;

        buttons.forEach((x, index) => {
            const button = document.createElement(`button`) as HTMLElement;
            button.id = `btn_inputbox_${index}`;
            button.classList.add(localAolo.buttonHoverStyle || "");
            button.innerHTML = x.text;
            button.onclick = function () {
                if (x.close)
                    Util.DialogFadeOut(dialog.id, true);

                if (x.callBack && inputBox)
                    x.callBack(inputBox.value);
            };

            if (buttonsDiv)
                buttonsDiv.appendChild(button);
        });

        if (focusElementID && focusElementID !== "")
            dialog.setAttribute("data-focus-element-id", focusElementID);
        else
            dialog.removeAttribute("data-focus-element-id");

        document.body.appendChild(dialog);
        Util.setElement("onclick", "btn_inputbox_dialog_header_close", () => { Util.DialogFadeOut(dialog.id, true); });

        Util.DialogFadeIn(dialog, localAolo);

        // Set Button min widths
        const htmlButtons = Array.from(document.querySelectorAll('#div_inputbox_buttons > button')) as HTMLButtonElement[];
        let maxWidth = 130;

        // Find the maximum width needed for the buttons
        htmlButtons.forEach((button) => {
            const width = button.clientWidth;
            if (width > maxWidth) {
                maxWidth = width;
            }
        });

        // Set the width of all buttons to the maximum width
        htmlButtons.forEach((button) => {
            button.style.minWidth = maxWidth + "px";
        });

        if (inputBox)
            setTimeout(() => { inputBox.focus(); }, 200);
    }

    /*******************************************/
    /*************** Message Box ***************/
    /*******************************************/

    /**
    * Returns a message box element with the provided message and optional OK button.
    * @param {string | HTMLElement} message - The message to display in the message box. Can be a string or an HTML element.
    * @param {boolean} okBox - Optional boolean flag indicating whether to include an OK button. Default is true.
    * @returns {HTMLElement} The created message box element.
    */
    private static messageBoxElement(message: string | HTMLElement, okBox: boolean, buttonHoverStyle: string, errorId?: number): HTMLElement {
        const text = (message instanceof HTMLElement) ? message.outerHTML : message;
        const html = `
            <div>
                <div id="div_msgbox_message" class="center">${text}${(errorId) ? `<br>Ref: ${errorId}` : ``}</div>
                ${!okBox ? `<div id="div_msgbox_buttons"></div>` :
                `<div id="div_msgbox_button_ok">
                        <button id="btn_msgbox_ok" class="${buttonHoverStyle}">${Names(`OK`)}</button>
                    </div>`}
            </div>`;
        const dialog = document.createElement("dialog");
        dialog.innerHTML = html;
        dialog.id = `dia_msgbox_${Math.floor(Math.random() * 99999) + 1}`;
        dialog.classList.add("msgbox");
        return dialog;
    }

    /**
     * Displays a message box with an OK button.
     * @param {string | HTMLElement} message - The message to display in the message box. Can be a string or an HTML element.
     * @param {Function | null} callBack - Optional callback function to be called when the OK button is clicked.
     * @param {string | null} focusElementID - Optional ID of the element to focus after the message box is closed.
     * @returns {void}
     */
    public static messageBoxOk(message: string | HTMLElement, buttonHoverStyle: string, callBack?: Function | null, focusElementID?: string | null, errorId?: number): void {
        const dialog = this.messageBoxElement(message, true, buttonHoverStyle, errorId);

        const buttonOK = dialog.querySelector("#btn_msgbox_ok") as HTMLButtonElement;
        if (buttonOK)
            buttonOK.onclick = () => {
                DialogManager.hideDialog(dialog.id, true);
                if (callBack)
                    callBack();
            };

        if (focusElementID && focusElementID !== "")
            dialog.setAttribute("data-focus-element-id", focusElementID);
        else
            dialog.removeAttribute("data-focus-element-id");

        document.body.appendChild(dialog);
        DialogManager.showDialog(dialog);
}

    /**
    * Displays a message box with the provided message and buttons.
    * @param {string} message - The message to display in the message box.
    * @param {Object[]} buttons - An array of button objects. Each object should have a "text" property for the button text and an optional "callBack" property for a callback function to be called when the button is clicked.
    * @returns {string} Returns the message Box id
    */
    public static messageBox(message: string, buttonHoverStyle: string, buttons: { text: string, callBack: Function | null }[]): string {
        const dialog = this.messageBoxElement(message, false, buttonHoverStyle);

        const buttonsDiv = dialog.querySelector("#div_msgbox_buttons") as HTMLButtonElement;

        buttons.forEach((x, index) => {
            const button = document.createElement(`button`) as HTMLElement;
            button.id = `btn_msgbox_btn_${index}`;
            button.classList.add(buttonHoverStyle || "");
            button.innerHTML = x.text;
            button.onclick = () => {
                DialogManager.hideDialog(dialog.id, true);
                if (x.callBack)
                    x.callBack();
            };

            if (buttonsDiv)
                buttonsDiv.appendChild(button);
        });

        document.body.appendChild(dialog);
        dialog.focus(); //for touch devices to not auto focus on the first button
        DialogManager.showDialog(dialog);

        // Set Button min widths
        const htmlButtons = Array.from(document.querySelectorAll('#div_msgbox_buttons > button')) as HTMLButtonElement[];
        let maxWidth = 130;

        // Find the maximum width needed for the buttons
        htmlButtons.forEach((button) => {
            const width = button.offsetWidth;
            if (width > maxWidth) {
                maxWidth = width;
            }
        });

        // Set the width of all buttons to the maximum width
        htmlButtons.forEach((button) => {
            button.style.minWidth = maxWidth + "px";
        });

        return dialog.id;
    }

    public static messageBoxFade(message: string) {
        const html = `
            <dialog id="dia_message_box_fade" class="msgbox">
                <div>
                    <div class="center m1 p1 title2 warning">${message}</div>
                </div>
            </dialog>`;

        document.body.appendChild(Util.createHtmlElementFromTemplate(html));

        DialogManager.showDialog("dia_message_box_fade");
        setTimeout(
            () => {
                DialogManager.hideDialog("dia_message_box_fade", true);
            },
            1000);
    }

    public static async ProgressWrapper<T>(localAolo: IaOLO, promise: () => Promise<T>): Promise<T> {
        DialogCreators.Progress(true, localAolo);
        try {
            const result = await promise();
            await new Promise(resolve => setTimeout(resolve, 200));
            return result;
        } finally {
            DialogCreators.Progress(false, localAolo);
        }
    }

    /**
    * Asynchronously loads an iframe with a provided source URL.
    * The Progress function is called with a true parameter before loading, 
    * and with a false parameter after the iframe has loaded or if there's an error.
    * 
    * @param {HTMLIFrameElement} iFrame - The iframe to be loaded.
    * @param {string} src - The source URL of the content to be loaded in the iframe.
    * 
    * @returns {Promise<void>} A promise that resolves when the iframe has successfully loaded,
    * and rejects if there's an error loading the iframe.
    *
    * @example
    * loadIFrame(document.getElementById('myIFrame'), 'https://www.example.com')
    *     .then(() => console.log('iframe loaded'))
    *     .catch(error => console.error('Error loading iframe:', error));
    */
    public static async loadIFrame(iFrame: HTMLIFrameElement, src: string, localAolo: IaOLO): Promise<void> {
        DialogCreators.Progress(true, localAolo);
        return new Promise((resolve, reject) => {
            iFrame.onload = () => {
                resolve();
                DialogCreators.Progress(false, localAolo);
            };
            iFrame.onerror = (error) => {
                reject(error);
                DialogCreators.Progress(false, localAolo);
            };
            iFrame.src = src;
        });
    }

    /**
    * Displays or hides a progress dialog and updates the message if provided.
    * @param {boolean} show - Determines whether to show or hide the progress dialog.
    * @param {string} [message] - Optional message to display on the progress dialog.
    * @returns {void}
    */
    public static Progress(show: boolean, localAolo: IaOLO, message?: string): void {
        const divMessage = document.getElementById("spn_progress_message");
        if (divMessage) {
            if (message && message !== "")
                divMessage.innerText = message;
            else
                divMessage.innerText = Names("Loading");
        }

        if (show && !localAolo.progressOn) {
            localAolo.progressOn = true;
            Util.DialogFadeIn("dia_progress", localAolo);
        } else if (!show && localAolo.progressOn) {
            localAolo.progressOn = false;
            Util.DialogFadeOut("dia_progress");
        }
    }
}