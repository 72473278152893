import { setPageLanguage } from '../utils/i18n';

import '../../css/shared/language-bar.css';

/**
 * A class representing a language bar that allows the user to select a language.
 */
export class LanguageBar {

    /**
     * Creates a new LanguageBar instance.
     */
    constructor() {
        this._setEventListeners();
    }

    /**
     * Sets up event listeners for the language bar.
     * @private
     */
    private _setEventListeners = () => {
        const self = this;
        window.onclick = () => { self._hideNavBar(); };

        const myButtons = Array.from(document.getElementsByName('btns_language_bar'));
        for (const btn of myButtons) {
            btn.onclick = (event: MouseEvent) => { self._languageBarDisplayOptions(event); };
        }

        document.getElementsByName("btn_language_bar_languages").forEach((language: HTMLElement) => {
            language.onclick = () => { self._languageSelected(language.dataset.code ?? ""); };
        });
    }

    /**
     * Displays the language options in the language bar when the user clicks the button.
     * @param e - The click event that triggered this method.
     * @private
     */
    private _languageBarDisplayOptions = (e: MouseEvent): void => {
        e.stopPropagation();

        let element: HTMLElement | null = null;
        if (e.target instanceof HTMLButtonElement)
            element = e.target;
        if (e.target instanceof HTMLSpanElement)
            element = e.target.parentNode as HTMLElement;

        if (element) {
            const listView = element.nextElementSibling as HTMLElement;

            if (listView.style.visibility === 'visible') {
                listView.style.visibility = 'hidden';
                return;
            }
            let btnLeft = element.getBoundingClientRect().left;
            let vWidth = listView.offsetWidth;
            let wWidth = window.innerWidth;
            if (btnLeft + vWidth > wWidth)
                btnLeft = wWidth - vWidth - 25;
            listView.style.left = `${btnLeft}px`;
            listView.style.visibility = 'visible';
        }
    }

    /**
     * Sets the selected language when the user clicks on a language option.
     * @param code - The code for the selected language.
     * @private
     */
    private _languageSelected = (code: string): void => {
        setPageLanguage(code);
        this._hideNavBar();
        // 2DO: Refactor maybe?
        //if (aOLO.ProfileUser?.isLoggedIn())
        //    aOLO.ProfileUser.updateUserLanguage(code);
    }

    /**
     * Hides the language bar.
     * @private
     */
    private _hideNavBar = (): void => {
        document.getElementsByName("language-bar-nav").forEach((x: HTMLElement) => {
            x.style.visibility = 'hidden';
        });
    }
}