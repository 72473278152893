import { ITempIncompleteDiscount } from "../interfaces/temp.interface";
import { IOrderTypeFunctions } from "./interfaces/order-type.interface";
import { IOrderCartFunctions, IOrderCartLimitedTime } from "./interfaces/order-cart.interface";
import { Util } from "../utils/util";
import { DialogCreators } from "../utils/dialog-creators";
import { Names } from "../utils/i18n";
import { OnlineOrderingUtil } from "./online-ordering-util";
import { Common } from "../common";
import { Fundraiser } from "./fundraiser";
import { Upsale } from "./upsale";
import { OrderTypeDialog } from "./order-type";
import { Rewards } from "./rewards";
import { Challenge } from "./challenge";

import '../../css/online-ordering/order-cart.css';
import { Setting81 } from "../types/enums";

export class OrderCart {
    private _functions: IOrderCartFunctions;
    private _limitedTimesTimer: IOrderCartLimitedTime = {
        timeCountdown: 0,
        intervalId: null,
        dialogId: null
    };

    constructor(functions: IOrderCartFunctions) {
        this._functions = functions;
        this._init();
    }

    private _init = (): void => {
        this._renderPage();
        this._setEventListeners();
    }

    private _getOrderTypeFunctions(): IOrderTypeFunctions {
        return {
            GUI_SetOrder: this._functions.GUI_SetOrder,
            ReOrder: this._functions.ReOrder,
            SetOrderType_Pre: this._functions.SetOrderType_Pre,
            selectStoreForCheckedAddress: this._functions.selectStoreForCheckedAddress,
            setOrderTypeCharge: this._functions.setOrderTypeCharge,
            setStoreOrderInfo: this._functions.setStoreOrderInfo,
            GUI_LoadItems: this._functions.GUI_LoadItems
        };
    }

    private _setEventListeners = (): void => {
        const self = this;
        Util.setElement("onclick", "btn_cart_coupons_list", () => { self._GUI_DisplayCoupons(); });
        Util.setElement("onclick", "btn_cart_coupon_code", () => { aOLO.Modules.Coupon.ApplyCouponByCode(Util.getElementValue("txt_cart_coupon_code").trim()); });
        Util.setElement("onclick", "btn_cart_fundraiser_code", () => { self._applyFundraiserByCode(); });
        Util.setElement("onclick", "btn_cart_checkout", () => { self._checkUpsale(); });
        Util.setElement("onclick", "btn_cart_edit_order_time_type", () => {
            if (this._limitedTimesTimer.intervalId) {
                clearInterval(this._limitedTimesTimer.intervalId);
                this._limitedTimesTimer = {
                    timeCountdown: 0,
                    intervalId: null,
                    dialogId: null
                };
            }

            if (!aOLO.Dialog.OrderType)
                aOLO.Dialog.OrderType = new OrderTypeDialog(self._getOrderTypeFunctions(), !!aOLO.Order.FutureDate, true);
            else
                aOLO.Dialog.OrderType.new(self._getOrderTypeFunctions(), !!aOLO.Order.FutureDate, true);
        });

        Util.setElement("onclick", "btn_cart_rewards", () => { aOLO.Dialog.Rewards = new Rewards(aOLO, aOLOModules.LoyaltyProvider); });
    }

    public CleanCart = (): void => {
        Util.hideElement("div_cart_customer");
        Util.hideElement("div_cart_points_available");
        Util.hideElement("btn_cart_rewards");
        Util.removeChildren("div_cart_customer_name");
        Util.removeChildren("div_cart_customer_email");
        Util.removeChildren("div_cart_customer_phone");
        Util.removeChildren("spn_cart_points_available");
        Util.removeChildren("sp_cart_rewards_count");
    }

    private _renderPage = (): void => {
        Util.hideElement("hr_cart_codes");
        this._fundraiserSection();
        this._couponSection();
    }

    private _fundraiserSection = (): void => {
        const fundraisers = aOLO.data.Fundraisers.filter(x => new Date(x.StartDateTime) <= Util.NowStore(aOLO.Temp.TimeOffset) && new Date(x.EndDateTime).getTime() >= Util.NowStore(aOLO.Temp.TimeOffset).setHours(0, 0, 0, 0));
        if (fundraisers && fundraisers.length > 0) {
            Util.showElement("div_cart_fundraiser_code");
        } else
            Util.hideElement("div_cart_fundraiser_code");
    }

    public challengesSection = (): void => {
        if (aOLO.Order.BUSDate != null && aOLO.Order.FutureDate != null && new Date(aOLO.Order.BUSDate).setHours(0, 0, 0, 0) !== new Date(Util.GetBUSDate(Util.NowStore(aOLO.Temp.TimeOffset), aOLO)).setHours(0, 0, 0, 0)) {
            Util.hideElement("btn_cart_challenges");
            return;
        }

        const storeDate = Util.NowStore(aOLO.Temp.TimeOffset);
        const challenges = aOLO.data.Challenges.filter(item =>
            [-1, 0].includes(Util.compareDates(new Date(item.StartDate), storeDate)) &&
            [0, 1].includes(Util.compareDates(new Date(item.EndDate), storeDate))
        );

        if (challenges.length > 0) {
            const challenge = challenges[0];
            Util.showElement("btn_cart_challenges");
            const span = document.getElementById("spn_cart_challenges");
            if (span) {
                span.innerText = Common.GetNewName(challenge.Names, aOLO.Temp.languageCode);
                span.setAttribute("ltagj", Util.toLtagj(challenge.Names));
            }
            Util.setElement("onclick", "btn_cart_challenges", () => { new Challenge(challenge, aOLO.Order.Challenges); });
        } else
            Util.hideElement("btn_cart_challenges");
    }

    private _couponSection = (): void => {
        if (aOLO.data.Settings.CPNCD) {
            Util.showElement("div_cart_coupon_code");
            Util.showElement("hr_cart_codes");
        } else
            Util.hideElement("div_cart_coupon_code");

        if (aOLO.data.Settings.CPNBTN == Setting81.DESKTOP_AND_APP ||
            (aOLO.data.Settings.CPNBTN == Setting81.DESKTOP_ONLY && !Util.isAppView()) ||
            (aOLO.data.Settings.CPNBTN == Setting81.APP_ONLY && Util.isAppView()))
            Util.showElement("div_cart_coupons_list");
        else
            Util.hideElement("div_cart_coupons_list");
    }

    private _GUI_DisplayCoupons = (): void => {
        const fundraiser = aOLO.data.Fundraisers.find(fund => fund.FundraiserId == aOLO.Order.Fundraiser?.ID);
        if (fundraiser && !fundraiser.IsCouponAllowed) {
            let msg = Names("CouponCodeErrorWithFundraiser");
            DialogCreators.messageBoxOk(msg.replace("??", fundraiser.Name || ""), aOLO.buttonHoverStyle);
            return;
        }

        Util.hideElement("div_items");
        Util.showElement("div_coupons");

        OnlineOrderingUtil.ToggleOrderItems(true, aOLO);
        OnlineOrderingUtil.GUI_ScrollTo(0, aOLO.Temp.HeaderHeight);
        aOLO.floatSidebar.forceUpdate();

        if (aOLO.Modules.DataLayer)
            aOLO.Modules.DataLayer.couponScrollListeners();
    }

    private _applyFundraiserByCode = (): void => {
        aOLO.Dialog.Fundraiser = new Fundraiser(Util.getElementValue("txt_cart_fundraiser_code").trim(), aOLO);
    }

    private _checkUpsale = (): void => {
        if (!aOLO.Temp.UpSold) {
            aOLO.Temp.UpSold = true;

            let upsaleCats = [];
            for (const category of aOLO.data.Categories) {
                if (category.Upsale && !this._checkUpsaleCat(category.ItemWebCategoryId)) {
                    let upsaleCat = {
                        IWCID: category.ItemWebCategoryId,
                        IMG: category.ImageUrl,
                        NAM: Common.GetName(category.Names, aOLO.Temp.languageCode)
                    };
                    upsaleCats.push(upsaleCat);
                }
            }

            aOLO.Dialog.Upsale = new Upsale(this._functions.CheckOut, upsaleCats, aOLO);
        } else
            this._functions.CheckOut();
    }

    private _checkUpsaleCat = (categoryId: number): boolean => {
        return aOLO.Order.Items.some(item => item.ItemWebCategoryId === categoryId);
    }

    public showIncompleteDiscounts = (incompleteDiscounts: ITempIncompleteDiscount[] | undefined, orderTypeId: number, buttonStyle: string): void => {
        if (!incompleteDiscounts)
            return;

        const divDiscounts = document.getElementById("div_cart_discounts_block");
        if (!divDiscounts)
            return;

        const incompleteItems = document.getElementsByName("div_cart_discounts_incomplete");
        incompleteItems.forEach((itm: HTMLElement) => itm.remove());

        for (const cpn of incompleteDiscounts) {
            const coupon = aOLO.Modules.Coupon.GetCoupon(cpn.couponID, false, orderTypeId, cpn.channelID);
            if (!coupon)
                continue;

            let html = `
                <div name="div_cart_discounts_incomplete" class="incompleteCoupon">
                    <div>
                        <div ltag="InProgress" class="inProgress">${Names(`InProgress`)}</div>
                        <div class="couponName">
                            <div ltagj="${Util.toLtagj(coupon.Names)}">${Common.GetName(coupon.Names, aOLO.Temp.languageCode)}</div>
                            <button id="btn_cart_incomplete_remove_${cpn.couponID}" name="btn_cart_incomplete_remove" class="btnIcon icon-cancel" aria-label="remove" title="${Names(`Remove`)}" data-id="${cpn.couponID}">
                                <span class="hidden_label" ltag="Remove">${Names(`Remove`)}</span>
                            </button>
                        </div>
                    </div>
                <div>`;

            if (coupon.Items.length > 0)
                html += `
                <button id="btn_cart_incomplete_complete_${cpn.couponID}" name="btn_cart_incomplete_complete" class="${buttonStyle}" title="${Names(`CompleteRequiredItems`)}" ltag="CompleteRequiredItems" data-id="${cpn.couponID}">
                    ${Names(`CompleteRequiredItems`)}
                </button>`;

            html += `</div></div>`;

            divDiscounts.innerHTML += html;
        }

        const removeBtns = document.getElementsByName("btn_cart_incomplete_remove");
        removeBtns.forEach(btn => {
            btn.onclick = async () => { await aOLO.Modules.Coupon.RemoveIncompleteCouponById(Number(btn.dataset.id)); }
        });

        const completeBtns = document.getElementsByName("btn_cart_incomplete_complete");
        completeBtns.forEach(btn => {
            btn.onclick = () => {
                if (aOLO.Modules.Coupon.IsCouponNotAllowedInFundraiser()) {
                    return;
                }
                aOLO.Modules.Coupon.CheckCouponHasQualifyingItems(Number(btn.dataset.id));
            }
        });
    }

    public setLimitedTimesTimer = (): void => {
        this._limitedTimesTimer.timeCountdown = aOLO.data.Settings.LIMTIMEMAX;

        this._limitedTimesTimer.intervalId = setInterval(() => {
            this._limitedTimesTimer.timeCountdown -= 1;

            if (this._limitedTimesTimer.timeCountdown === 1) {
                const self = this;
                this._limitedTimesTimer.dialogId = DialogCreators.messageBox(Names("TimeFrameToExpireContinue"), aOLO.buttonHoverStyle, [
                    {
                        "text": Names("Yes"), "callBack": () => {
                            self._limitedTimeResetTimer();
                        }
                    },
                    {
                        "text": Names("No"), "callBack": () => {
                            self._limitedTimeTimerEnded();
                        }
                    }]);
            }

            if (this._limitedTimesTimer.timeCountdown <= 0)
                this._limitedTimeTimerEnded();
        }, 60 * 1000);
    }

    private _limitedTimeResetTimer = async (): Promise<void> => {
        if (!this._limitedTimesTimer.intervalId)
            return;

        this._limitedTimesTimer.timeCountdown = aOLO.data.Settings.LIMTIMEMAX;

        const payload = {
            SKY: aOLO.storeInfo.StoreKey,
            GUID: aOLO.Order.GUID,
            TIME: aOLO.data.Settings.LIMTIMEMAX
        };

        const result = await aOLO.Modules.OnlineOrderingService.resetLimitedTimeTimer(payload, aOLO);
        if (!result)
            this._limitedTimeTimerEnded();
    }

    private _limitedTimeTimerEnded = async (): Promise<void> => {
        if (!this._limitedTimesTimer.intervalId)
            return;

        const payload = {
            SKY: aOLO.storeInfo.StoreKey,
            GUID: aOLO.Order.GUID
        };
        const result = await aOLO.Modules.OnlineOrderingService.removeOrderLimitedTimes(payload, aOLO);
        if (!result)
            return;

        if (this._limitedTimesTimer.intervalId && this._limitedTimesTimer.dialogId) {
            clearInterval(this._limitedTimesTimer.intervalId);
            Util.DialogFadeOut(this._limitedTimesTimer.dialogId);
            this._limitedTimesTimer = {
                timeCountdown: 0,
                intervalId: null,
                dialogId: null
            };
        }

        await this._functions.resetTimeFulfillmentTimeExpired();
    }
}

