import { FieldType } from '../types/enums';
import { TwoWayBinding } from '../two-way-binding';
import { Common } from '../common';
import { Util } from '../utils/util';
import { Names } from '../utils/i18n';
import { IChangePassword } from './interfaces/change-password.interface';
import { DialogCreators } from '../utils/dialog-creators';

import '../../css/shared/change-password.css';
import { IaOLO } from '../interfaces/aolo.interface';

/**
 * Class representing Change Password functionality.
 * @class
 */
export class ChangePassword {
    /**
     * @private
     * @property {IChangePassword} _item - An object containing password properties.
     */
    private _item: IChangePassword;
    /**
     * @private
     * @property {TwoWayBinding[]} _bindings - An array of two-way bindings between the ChangePasswordInterface and input fields on the page.
     */
    private _bindings: TwoWayBinding[] = [];
    /**
     * @private
     * @property {{ obj: HTMLElement, method: any, func: any }[]} - The event listeners attached to the input fields and buttons.
     */
    private _eventListeners: { obj: HTMLElement, method: any, func: any }[] = [];
    /**
     * @private
     * @property {boolean} _isResetPassword - Flag indicating whether this is reset password dialog.
     */
    private _isResetPassword: boolean = false;
    private _brandFunction: Function | null;

    /**
     * Create a new ChangePassword instance.
     * @constructor
     */
    constructor(brandFunction: Function | null) {
        this._brandFunction = brandFunction;
        this._isResetPassword = (aOLO.RP && aOLO.RP.ProfileId != '');
        this._item = this._setDefault();
        this._initPassword();
    }

    /**
     * Initialize the password change functionality.
     * @private
     * @returns {void}
     */
    private _initPassword = (): void => {
        this._setTwoWayListeners();
        this._setEventListeners(aOLO);
        this._hideErrorElements();
        this._renderPage();
    }

    /**
     * Sets the default properties of a ChangePasswordInterface object.
     * @private
     * @returns {ChangePasswordInterface} - A new instance of the ChangePasswordInterface with default values.
     */
    private _setDefault = (): IChangePassword => {
        return {
            CID: aOLO.User.CustomerId || 0,
            EML: aOLO.User.Email || "",
            SKY: aOLO.storeInfo.StoreKey,
            BRAND: aOLO.storeInfo.BrandID,
            OLDPASS: "",
            NEWPASS1: "",
            NEWPASS2: ""
        };
    }

    /**
     * Renders the change password or reset password page.
     * @private
     * @returns {void}
     */
    private _renderPage = (): void => {
        const self = this;
        if (this._isResetPassword) {
            if (aOLO.RP?.MessageId == 1)
                DialogCreators.messageBoxOk(Names("ResetPass_SessionExpired_Error"), aOLO.buttonHoverStyle, () => {
                    if (self._brandFunction)
                        self._brandFunction("signin", true);
                });
            else if (aOLO.RP?.MessageId == 2)
                DialogCreators.messageBoxOk(Names("ResetPass_Unexpected_Error") + aOLO.RP?.Message, aOLO.buttonHoverStyle, () => {
                    if (self._brandFunction)
                        self._brandFunction("signin", true);
                });

            Util.hideElement("div_changepassword_currentPassword");
            Util.setElement("ltag", "h2_changepassword", "ResetPassword");
            Util.setElement("innerHTML", "h2_changepassword", Names("ResetPassword"));
            Util.setElement("ltag", "btn_changepassword", "ResetPassword");
            Util.setElement("innerHTML", "btn_changepassword", Names("ResetPassword"));
        } else {
            Util.showElement("div_changepassword_currentPassword");
            Util.setElement("ltag", "h2_changepassword", "ChangePassword");
            Util.setElement("innerHTML", "h2_changepassword", Names("ChangePassword"));
            Util.setElement("ltag", "btn_changepassword", "ChangePassword");
            Util.setElement("innerHTML", "btn_changepassword", Names("ChangePassword"));
        }
    }

    /**
     * Sets up two-way bindings between input fields on the page and properties of a ChangePasswordInterface object.
     * @private
     * @returns {void}
     */
    private _setTwoWayListeners = (): void => {
        this._bindings = [
            new TwoWayBinding(this._item, "OLDPASS", document.getElementById("txt_changepassword_currentPassword")),
            new TwoWayBinding(this._item, "NEWPASS1", document.getElementById("txt_changepassword_newPassword")),
            new TwoWayBinding(this._item, "NEWPASS2", document.getElementById("txt_changepassword_repeatPassword"))
        ];
    }

    /**
     * Hide the error elements for current password, new password, and repeat password.
     * @private
     * @returns {void}
     */
    private _hideErrorElements = (): void => {
        Util.hideElement("spn_changepassword_currentPassword_Error");
        Util.hideElement("spn_changepassword_newPassword_Error");
        Util.hideElement("spn_changepassword_repeatPassword_Error");
    }

    /**
     * Set the initial values for the current password, new password, and repeat password fields.
     * @private
     * @returns {void}
     */
    private _setEventListeners = (localAolo: IaOLO): void => {
        const self = this;
        Util.setElement("onclick", "btn_changepassword", async () => { await self._passwordClick(localAolo); });
        Util.setElement("onclick", "btn_changepassword_close", () => { self.closeDialog(); });

        const oldPassword = document.getElementById("txt_changepassword_currentPassword");
        if (oldPassword) {
            oldPassword.addEventListener("keyup", (event) => {
                self._handleEnterKey(event, localAolo);
            });
            this._eventListeners.push({ obj: oldPassword, method: "keyup", func: self._handleEnterKey });
        }

        const newPassword = document.getElementById("txt_changepassword_newPassword");
        if (newPassword) {
            newPassword.addEventListener("keyup", (event) => {
                self._handleEnterKey(event, localAolo);
            });
            this._eventListeners.push({ obj: newPassword, method: "keyup", func: self._handleEnterKey });
        }

        const newPassword2 = document.getElementById("txt_changepassword_repeatPassword");
        if (newPassword2) {
            newPassword2.addEventListener("keyup", (event) => {
                self._handleEnterKey(event, localAolo);
            });
            this._eventListeners.push({ obj: newPassword2, method: "keyup", func: self._handleEnterKey });
        }
    }

    /**
     * Handles the Enter key press event on the change password form.
     * @private
     * @param {any} event - The key up event object.
     * @returns {void}
     */
    private _handleEnterKey = (event: any, localAolo: IaOLO): void => {
        if (event.key === "Enter") {
            event.preventDefault();
            this._passwordClick(localAolo);
        }
    }

    /**
     * Handles the click event for the password button.
     * @private
     * @async
     * @returns {Promise<void>}
     */
    private _passwordClick = async (localAolo: IaOLO): Promise<void> => {
        if (this._isResetPassword)
            await this._resetPassword(localAolo);
        else
            await this._updatePassword();
    }

    public closeDialog = (): void => {
        Util.DialogFadeOut("dia_password");
    }

    /**
     * Check the submitted data for errors and return a boolean indicating if the data is valid.
     * @private
     * @async
     * @returns {Promise<boolean>}
     */
    private _checkSubmittedData = async (): Promise<boolean> => {

        /**
         * Adds a validation error message to the error flag.
         * @param message - The result of a validation check.
         * @returns {void}
         */
        function addValidationErrorMsg(message: string): void {
            if (message !== "") {
                error = true;
                ul.appendChild(Common.GetLi(message));
            }
        }

        let error = false;
        let ul = document.createElement("ul");
        ul.classList.add("warning");

        if (!this._isResetPassword) {
            addValidationErrorMsg(await Common.ValidateInput(true,
                this._item.OLDPASS,
                FieldType.OLDPASSWORD,
                "spn_changepassword_currentPassword_Error",
                true,
                aOLO
            ));
        }

        addValidationErrorMsg(await Common.ValidateInput(true,
            this._item.NEWPASS1,
            FieldType.PASSWORD,
            "spn_changepassword_newPassword_Error",
            true,
            aOLO
        ));

        addValidationErrorMsg(await Common.ValidateInput(true,
            [this._item.NEWPASS1, this._item.NEWPASS2],
            FieldType.COMPAREPASSWORDS,
            "spn_changepassword_repeatPassword_Error",
            true,
            aOLO
        ));

        if (error) {
            DialogCreators.messageBoxOk(ul, aOLO.buttonHoverStyle);
            return false;
        }
        return true;
    }

    /**
     * Update the user's password if the submitted data is valid.
     * @private
     * @async
     * @returns {Promise<void>}
     */
    private _updatePassword = async (): Promise<void> => {
        if (!await this._checkSubmittedData())
            return;

        let info = {
            oldPassword: this._item.OLDPASS,
            newPassword: this._item.NEWPASS1
        };

        let result = await aOLO.Modules.ProfileService.changePassword(info, aOLO);

        if (result) {
            // 2DO
            //if (this._brandFunction)
            //    Common.logout(this._brandFunction, aOLO);
            //else {
            //    if (aOLO.Dialog.ChangePassword)
            //        aOLO.Dialog.ChangePassword.closeDialog();
            //}
        } else
            DialogCreators.messageBoxOk(Names("PasswordSaveError"), aOLO.buttonHoverStyle);
    }

    /**
     * Removes all event listeners attached to the input fields used in the two-way bindings of this instance.
     * @returns {void}
     */
    public removeEventListeners = (): void => {
        this._bindings.forEach(binding => {
            binding.removeEventListeners();
        });

        this._eventListeners.forEach(listener => {
            listener.obj.removeEventListener(listener.method, listener.func);
        });

        delete aOLO.RP;
    }

    /**
     * Resets the password for the user.
     * @private
     * @async
     * @returns {Promise<void>}
     */
    private _resetPassword = async (localAolo: IaOLO): Promise<void> => {
        if (!await this._checkSubmittedData())
            return;

        const self = this;
        let info = {
            profileId: localAolo.RP.ProfileId,
            storeKey: localAolo.storeInfo.StoreKey,
            password: this._item.NEWPASS1
        };
        const result = await localAolo.Modules.ProfileService.resetPassword(info, localAolo);

        if (result.success) {
            // 2DO
            //if (result.isProfileComplete && (localAolo.ProfileUser?.isLoggedIn() || false))
            //    localAolo.User.IsProfileComplete = true;

            DialogCreators.messageBoxOk(Names("ResetPasswordSuccessful"), localAolo.buttonHoverStyle, () => {
                if (self._brandFunction)
                    self._brandFunction("signin", true);
            });
        } else
            DialogCreators.messageBoxOk(Names("UnableResetPassword"), localAolo.buttonHoverStyle, () => {
                if (self._brandFunction)
                    self._brandFunction("signin", true);
            });
    }
}
