import { LoyaltyProvider } from '../types/enums';
import { Profile } from '../shared/profile';
import { Util } from '../utils/util';
import { Names } from '../utils/i18n';
import { DialogCreators } from '../utils/dialog-creators';
import '../../css/shared/loyalty-rewards-points.css';

/**
 * The LoyaltySummary class represents a summary of a user's loyalty information and renders the loyalty summary page.
 * @class
 */
export class LoyaltySummary {
    private _profileInstance: Profile;
    private _brandFunction: Function | null;

    /**
     * Creates an instance of LoyaltySummary.
     * @constructor
     */
    constructor(profileInstance: Profile, brandFunction: Function | null) {
        this._profileInstance = profileInstance;
        this._brandFunction = brandFunction;
        this._init();
    }

    /**
     * Initializes the LoyaltySummary class by rendering the loyalty summary page.
     * @private
     * @returns {void}
     */
    private _init = (): void => {
        this._renderPage();
        this._setEventListeners();
    }

    /**
     * Renders the loyalty summary page with the user's loyalty information.
     * @private
     * @returns {void}
     */
    private _renderPage = (): void => {
        if (!aOLO.User) {
            Util.hideElement("div_loyalty_joined");
            Util.hideElement("div_loyalty_not_joined");
            Util.hideElement("div_loyalty_lifetime_points");
            Util.hideElement("div_loyalty_joined_date");
            Util.hideElement("div_loyalty_last_trans_date");
            return;
        }

        // General Customer Section
        Util.setElement("innerHTML", "spn_loyalty_name", `${aOLO.User.Name} ${aOLO.User.LastName}`);

        if (aOLOModules.LoyaltyProvider.getLoyaltyProvider() !== LoyaltyProvider.NONE && aOLO.User?.IsLoyalty && aOLO.User?.LoyaltyData) {
            Util.showElement("div_loyalty_joined");
            Util.hideElement("div_loyalty_not_joined");

            aOLOModules.LoyaltyProvider.renderUserLoyaltyBalance();

            // Rewards
            if (aOLOModules.LoyaltyProvider.hasRewardRedemption())
                aOLOModules.LoyaltyProvider.renderRewardsList(this.CloseDialog);
            else
                Util.hideElement("div_loyalty_rewards");

            // Membership
            if (aOLOModules.LoyaltyProvider.hasMembershipTiers())
                aOLOModules.LoyaltyProvider.renderMembershipList();
            else
                Util.hideElement("div_loyalty_membership");
        } else if (aOLOModules.LoyaltyProvider.getLoyaltyProvider() !== LoyaltyProvider.NONE && !aOLO.User.IsBlocked) {
            Util.hideElement("div_loyalty_joined");
            if (aOLOModules.LoyaltyProvider.getLoyaltyProvider() == LoyaltyProvider.ADORA)
                Util.showElement("div_loyalty_not_joined");
        } else {
            Util.hideElement("div_loyalty_joined");
            Util.hideElement("div_loyalty_not_joined");
            Util.hideElement("div_loyalty_lifetime_points");
            Util.hideElement("div_loyalty_joined_date");
            Util.hideElement("div_loyalty_last_trans_date");
        }

        // Offers
        aOLOModules.LoyaltyProvider.renderSurveyOffersList(this.CloseDialog, this._brandFunction);
    }

    private _setEventListeners = (): void => {
        const self = this;
        Util.setElement("onclick", "btn_loyalty_close", () => { this.CloseDialog(); });
        Util.setElement("onclick", "btn_loyalty_join_loyalty", () => { self._joinLoyaltyProgram(); });
    }

    public CloseDialog = (): void => {
        Util.DialogFadeOut("dia_loyalty");
    }


    /**
     * Private method that sets the user's loyalty program to active, updates their profile accordingly, and displays a success message.
     * @private
     * @return {Promise<void>}
     */
    private _joinLoyaltyProgram = async (): Promise<void> => {
        const self = this;
        aOLO.User.IsLoyalty = true;
        this._profileInstance.setLoyalty(true);
        let result = await this._profileInstance.updateProfileInternal();
        if (result)
            DialogCreators.messageBoxOk(Names("SuccessfullyJoinedLoyaltyProgram_MsgBoxOK"), aOLO.buttonHoverStyle, async () => {
               // 2DO: await Common.profileReload(aOLO);
                if (self._brandFunction)
                    self._brandFunction("loyalty", true);
            });
    }
}