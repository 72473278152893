import { IDescription, IName, INewName, IGeoLocationData, IAddressFormat } from '../interfaces/global.interfaces';
import { CustomDatalist } from '../custom-datalist';
import { IaOLO } from '../interfaces/aolo.interface';
import { IDataCountry } from '../interfaces/data.interface';

export class Util {
    private static useFixedTime: boolean = false;
    private static fixedTime: string = "";

    /*******************************************/
    /********* Format String *******************/
    /*******************************************/
    /**
    * Formats a number as a currency string in USD.
    * 
    * @param {number} amount - The amount to format.
    * @returns {string} - The formatted currency string.
    */
    public static formatMoney(amount: number): string {
        const formatter = new Intl.NumberFormat(aOLO.storeInfo.Country.DefaultCultureCode ? aOLO.storeInfo.Country.DefaultCultureCode : 'en-US', {
            style: 'currency',
            currency: aOLO.storeInfo.Country.CurrencyCode ? aOLO.storeInfo.Country.CurrencyCode : 'USD',
            currencyDisplay: 'narrowSymbol',
            minimumFractionDigits: 2
        });

        return formatter.format(Number(amount.toFixed(2)));
    }

    /**
    * Finds the default culture code for a country.
    * 
    * @param {string} isoCode - The iso code to search with.
    * @param {IDataCountry[]} countries - The country list to search within.
    * @returns {string} - The default culutre code.
    */
    public static getDefaultCultureCode(countryId: number, countries: IDataCountry[]): string {
        const country = countries.find(country => country.CountryID === countryId);
        return country ? country.DefaultCultureCode : 'en-us';
    }

    /**
    * Formats a number with two decimal places.
    * 
    * @param {number} amount - The number to format.
    * @returns {string} - The formatted number string.
    */
    public static formatNumber(amount: number): string {
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });

        return formatter.format(Number(amount.toFixed(2)));
    }

    /**
    * Formats a Date object into a localized date string.
    * 
    * @param {Date} date - The date to format.
    * @returns {string} - The formatted date string.
    */
    public static formatDate(date: Date, language: string | null = null): string {
        if (language == null)
            language = Util.GetBrowserLanguage();
        const options = {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        } as const;
        const newDate = new Date(date);
        const formatter = new Intl.DateTimeFormat(language, options);
        return formatter.format(newDate);
    }


    /**
    * Formats a Date object into a date string suitable for input fields (YYYY-MM-DD).
    * 
    * @param {Date} date - The date to format.
    * @returns {string} - The formatted date string for input fields.
    */
    public static formatDateInput(date: Date): string {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${year}-${month}-${day}`;
    }

    /**
    * Transforms a date string in the format "YYYY-MM-DD" to "MM/DD/YYYY".
    * 
    * @param {string} date - A string representation of a date in the format "YYYY-MM-DD".
    * @returns {string} The input date string in the format "MM/DD/YYYY".
    */
    public static formatDateFunc(date: string): string {
        const datePattern = /^\d{4}-\d{2}-\d{2}$/;
        if (!datePattern.test(date))
            throw new Error('Invalid date format. Expected format: YYYY-MM-DD');

        const [year, month, day] = date.split("-");

        return `${month}/${day}/${year}`;
    }

    /**
    * Formats a Date object into a localized date and time string.
    * 
    * @param {Date} date - The date to format.
    * @returns {string} - The formatted date and time string.
    */
    public static formatDateTime(date: Date, language: string | null = null): string {
        if (language == null)
            language = Util.GetBrowserLanguage();

        const options = {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        } as const;

        const newDate = new Date(date);
        const formatter = new Intl.DateTimeFormat(language, options);
        return formatter.format(newDate);
    }

    /**
    * Formats a Date object into a localized date and time string, including seconds.
    * 
    * @param {Date} date - The date to format.
    * @returns {string} - The formatted date and time string, including seconds.
    */
    public static formatDateTimeSeconds(date: Date): string {
        if (isNaN(new Date(date).getTime()))
            throw new Error('Invalid time value');

        const newDate = new Date(date);
        const formattedDate = newDate.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        }).replace(",", "");
        return formattedDate;
    }

    /**
    * Converts miles to kilometers
    * 
    * @param {Number} miles - number of miles to convert to kilometers.
    * @returns {Number} - The number of kilometers.
    */
    public static convertMilesToKilometers(miles: number): number {
        const conversionFactor = 1.60934;
        return (miles * conversionFactor);
    }

    /**
    * Formats a Date object into a localized time string.
    * 
    * @param {Date} date - The date to format.
    * @returns {string} - The formatted time string.
    */
    public static formatTime(date: Date, isoCode: string | undefined | null = null): string {
        if (isNaN(new Date(date).getTime()))
            throw new Error('Invalid time value');

        const now = new Date(date);
        const timeString = now.toLocaleTimeString(isoCode ? isoCode : [], { hour: '2-digit', minute: '2-digit', hour12: true });
        return timeString;
    }

    public static formatTimeByIsoCode(minutes: number, cultureCode: string): string {
        if (cultureCode == "en-us")
            cultureCode = "en-US";
        else if (cultureCode == 'sp-mx')
            cultureCode = "es-MX";

        // Calculate total minutes within a 24-hour period
        const normalizedMinutes = minutes % 1440;

        // Get the hour and minute components
        const hours = Math.floor(normalizedMinutes / 60);
        const mins = normalizedMinutes % 60;

        // Determine 12-hour or 24-hour format using Intl.DateTimeFormat
        const hourCycle = new Intl.DateTimeFormat(cultureCode, { hour: 'numeric' })
            .resolvedOptions().hourCycle;

        // Determine if the culture prefers a 12-hour format
        const is12HourFormat = hourCycle === 'h12' || hourCycle === 'h11';

        if (is12HourFormat) {
            // 12-hour format
            const period = hours >= 12 ? 'PM' : 'AM';
            const hour12 = hours % 12 === 0 ? 12 : hours % 12;
            return `${hour12}:${mins.toString().padStart(2, '0')} ${period}`;
        } else {
            // 24-hour format
            return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
        }
    }

    public static formatToISO8601(date: Date): string {
        // Get the timezone offset in minutes and convert it to hours and minutes
        const offset = -date.getTimezoneOffset();
        const offsetHours = Math.floor(Math.abs(offset) / 60);
        const offsetMinutes = Math.abs(offset) % 60;

        // Format the offset as a string "+hh:mm" or "-hh:mm"
        const offsetSign = offset >= 0 ? '+' : '-';
        const formattedOffset = `${offsetSign}${offsetHours.toString().padStart(2, '0')}:${offsetMinutes.toString().padStart(2, '0')}`;

        // Convert date to ISO string and replace 'Z' with the timezone offset
        return date.toISOString().replace('Z', formattedOffset);
    }

    /**
    * Adds a specific amount of time units to a given Date object.
    * 
    * @param {Date} date - The original date.
    * @param {number|string} value - The amount to add.
    * @param {string} addingUnit - The unit of time to add (e.g., 'd' for days, 'M' for months).
    * @returns {Date} - The new date after addition.
    */
    public static DateAdd(date: Date, value: number | string, addingUnit: string): Date {
        const iValue = (typeof value == "string") ? parseInt(value) : value;
        const iDate = new Date(date);
        switch (addingUnit) {
            case "ms":
                return new Date(iDate.setTime(iDate.getTime() + iValue));
            case "s":
                return new Date(iDate.setSeconds(iDate.getSeconds() + iValue));
            case "m":
                return new Date(iDate.setMinutes(iDate.getMinutes() + iValue));
            case "h":
                return new Date(iDate.setHours(iDate.getHours() + iValue));
            case "d":
                return new Date(iDate.setDate(iDate.getDate() + iValue));
            case "M":
                return new Date(iDate.setMonth(iDate.getMonth() + iValue));
        }
        return iDate;
    }

    public static DateDiff(diffIn: string, date1: Date, date2: Date): number {
        let millisecondsPerDiff = 1000 * 60 * 60 * 24;
        switch (diffIn) {
            case "ms":
                millisecondsPerDiff = 1;
                break;
            case "s":
                millisecondsPerDiff = 1000;
                break;
            case "m":
                millisecondsPerDiff = 1000 * 60;
                break;
            case "h":
                millisecondsPerDiff = 1000 * 60 * 60;
                break;
            case "d":
                date1 = new Date(Util.formatDate(date1, 'en-US'));
                date2 = new Date(Util.formatDate(date2, 'en-US'));
                millisecondsPerDiff = 1000 * 60 * 60 * 24;
                break;
            case "M":
                return date1.getFullYear() * 12 + date1.getMonth() - (date2.getFullYear() * 12 + date2.getMonth());
        }

        const date1_ms = date1.getTime();
        const date2_ms = date2.getTime();
        const difference_ms = date1_ms - date2_ms;
        return Math.round(difference_ms / millisecondsPerDiff);
    }

    public static GetDateInputDate(dateText: string): Date | null {
        if (dateText == "")
            return null;

        const dateParts = dateText.split('-');

        const year = parseInt(dateParts[0]);
        const month = parseInt(dateParts[1]) - 1;
        const day = parseInt(dateParts[2]);

        const today = new Date();
        const tYear = today.getFullYear();
        const tMonth = today.getMonth();
        const tDay = today.getDate();

        if (year >= tYear
            || (year >= tYear && month >= tMonth)
            || (year >= tYear && month >= tMonth && month >= tMonth && day >= tDay)) {
            return new Date(year, month, day);
        }
        return today;
    }

    /**
    * Returns a Date object set to the given minutes past midnight.
    * 
    * @param {number} minutes - The number of minutes past midnight.
    * @return {Date} - The Date object set to the specified time.
    */
    public static getDateFromMinutes(minutes: number, timeOffset: number): Date {
        const date = this.NowStore(timeOffset);
        date.setHours(0, 0, 0, 0);
        date.setMinutes(minutes);

        return date;
    }

    /*******************************************/
    /**************** Encryption ***************/
    /*******************************************/
    public static async encrypt(data: string, publicKey: string) {
        const publicKeyBuffer = this.base64ToBytes(publicKey);

        const cryptoKey = await crypto.subtle.importKey(
            "spki",
            publicKeyBuffer,
            {
                name: "RSA-OAEP",
                hash: { name: "SHA-256" }
            },
            false,
            ["encrypt"]
        );

        // Generate symmetric key and encrypt data
        const symmetricKey = await this.generateSymmetricKey();
        const encryptedData = await this.encryptWithSymmetricKey(symmetricKey, data);

        // Export the symmetric key as raw bytes
        const symmetricKeyBytes = await crypto.subtle.exportKey("raw", symmetricKey);

        // Encrypt the symmetric key using the RSA public key
        const encryptedSymmetricKey: ArrayBuffer = await crypto.subtle.encrypt(
            {
                name: "RSA-OAEP"
            },
            cryptoKey,
            symmetricKeyBytes
        );

        // Bundle the encrypted symmetric key, the IV, and the encrypted data
        const result = {
            encryptedSymmetricKey: this.bytesToBase64(new Uint8Array(encryptedSymmetricKey)),
            iv: this.bytesToBase64(encryptedData.iv),
            encryptedData: this.bytesToBase64(new Uint8Array(encryptedData.encryptedData)),
            tag: this.bytesToBase64(new Uint8Array(encryptedData.tag))
        };

        return result;
    }

    public static getBase64Code(charCode: number) {
        const base64codes = [
            255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
            255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
            255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 62, 255, 255, 255, 63,
            52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 255, 255, 255, 0, 255, 255,
            255, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14,
            15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 255, 255, 255, 255, 255,
            255, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
            41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51
        ];

        if (charCode >= base64codes.length) {
            throw new Error("Unable to parse base64 string.");
        }
        const code = base64codes[charCode];
        if (code === 255) {
            throw new Error("Unable to parse base64 string.");
        }
        return code;
    }

    public static bytesToBase64(bytes: number[] | Uint8Array) {
        const base64abc = [
            "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M",
            "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z",
            "a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m",
            "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z",
            "0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "+", "/"
        ];

        let result = '', i, l = bytes.length;
        for (i = 2; i < l; i += 3) {
            result += base64abc[bytes[i - 2] >> 2];
            result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
            result += base64abc[((bytes[i - 1] & 0x0F) << 2) | (bytes[i] >> 6)];
            result += base64abc[bytes[i] & 0x3F];
        }
        if (i === l + 1) { // 1 octet yet to write
            result += base64abc[bytes[i - 2] >> 2];
            result += base64abc[(bytes[i - 2] & 0x03) << 4];
            result += "==";
        }
        if (i === l) { // 2 octets yet to write
            result += base64abc[bytes[i - 2] >> 2];
            result += base64abc[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
            result += base64abc[(bytes[i - 1] & 0x0F) << 2];
            result += "=";
        }
        return result;
    }

    public static base64ToBytes(str: string) {
        if (str.length % 4 !== 0) {
            throw new Error("Unable to parse base64 string.");
        }
        const index = str.indexOf("=");
        if (index !== -1 && index < str.length - 2) {
            throw new Error("Unable to parse base64 string.");
        }
        let missingOctets = str.endsWith("==") ? 2 : str.endsWith("=") ? 1 : 0,
            n = str.length,
            result = new Uint8Array(3 * (n / 4)),
            buffer: number;
        for (let i = 0, j = 0; i < n; i += 4, j += 3) {
            buffer =
                Util.getBase64Code(str.charCodeAt(i)) << 18 |
                Util.getBase64Code(str.charCodeAt(i + 1)) << 12 |
                Util.getBase64Code(str.charCodeAt(i + 2)) << 6 |
                Util.getBase64Code(str.charCodeAt(i + 3));
            result[j] = buffer >> 16;
            result[j + 1] = (buffer >> 8) & 0xFF;
            result[j + 2] = buffer & 0xFF;
        }
        return result.subarray(0, result.length - missingOctets);
    }

    public static async generateSymmetricKey(): Promise<CryptoKey> {
        return await crypto.subtle.generateKey(
            {
                name: "AES-GCM",
                length: 256,
            },
            true,
            ["encrypt", "decrypt"]
        );
    }

    public static async encryptWithSymmetricKey(key: CryptoKey, data: string) {
        const iv = crypto.getRandomValues(new Uint8Array(12));
        const dataBuffer = new TextEncoder().encode(data);

        const encryptedData = await crypto.subtle.encrypt(
            {
                name: "AES-GCM",
                iv: iv,
            },
            key,
            dataBuffer
        );

        // Extract the authentication tag (last 16 bytes of the encrypted data)
        const tag = encryptedData.slice(-16);

        return {
            iv: iv,
            encryptedData: encryptedData.slice(0, -16), // Remove the authentication tag from the encrypted data
            tag: tag // Return the authentication tag
        };
    }

    /*******************************************/
    /***************** Dialogs *****************/
    /*******************************************/

    /**
     * Shows a dialog box by fading it in gradually.
     * @param {string | HTMLElement} dialogID - The ID of the dialog element or the dialog element itself.
     * @param {string} [focusElementID] - The ID of the element to set focus to when the dialog is shown.
     * @returns {void}
     */
    public static DialogFadeIn(dialogID: string | HTMLElement, localAolo: IaOLO, focusElementID?: string | null, useShow?: boolean): void {
        const dialog = (typeof dialogID === "string" ? document.getElementById(dialogID) : dialogID) as HTMLDialogElement;
        if (!dialog || (dialog && dialog.hasAttribute("open")))
            return;

        this.LockBody(localAolo);
        dialog.classList.add("dialogFaded");
        dialog.classList.remove("opacity1");
        if (this.isOldBrowserForDialog()) {
            if (!dialog.classList.contains("dialog-safari")) {
                //@ts-ignore
                dialogPolyfill.registerDialog(dialog);
                dialog.classList.add("dialog-safari");
            }
        } else if (useShow)
            dialog.show();
        else
            dialog.showModal();
        dialog.scrollTop = 0;
        setTimeout(() => { dialog.classList.add('opacity1'); }, 50);

        document.body.style.setProperty("overflow", "hidden", "!important");

        if (focusElementID) {
            const fElement = document.getElementById(focusElementID);
            if (fElement)
                fElement.focus();
        }
    }

    /**
    * Hides a dialog box by fading it out gradually.
    * @param {string} dialogID - The ID of the dialog element to hide.
    * @param {boolean} [destroy] - Whether to remove the dialog element from the DOM after hiding it.
    * @returns {void}
    */
    public static DialogFadeOut(dialogID: string, destroy?: boolean): void {
        const dialog = document.getElementById(dialogID);
        if (!dialog) return;

        dialog.classList.remove("opacity1");
        dialog.classList.add("fadeout");

        setTimeout(
            () => {
                const dialogInner = document.getElementById(dialogID) as HTMLElement;
                if (dialogInner) {
                    //@ts-ignore
                    dialogInner.close();
                    dialogInner.classList.remove("fadeout");

                    const focusId = dialogInner.getAttribute("data-focus-element-id");
                    if (focusId) {
                        const focusElement = document.getElementById(focusId) as HTMLInputElement;
                        if (focusElement) {
                            focusElement.focus();
                            try { focusElement.select(); } catch { }
                        }
                    }
                    if (destroy)
                        dialogInner.remove();
                }

                if (this.GetOpenedDialogCount() === 0)
                    this.DialogClosed();
            },
            500);
    }

    private static GetOpenedDialogCount(): number {
        let count = 0;
        const dialogs = Array.from(document.getElementsByTagName('dialog'));
        for (const dialog of dialogs) {
            if (dialog.open)
                count++;
        }
        return count;
    }

    /**
    * Handles the logic when a dialog is closed.
    * @returns {void}
    */
    public static DialogClosed(): void {
        if (this.GetOpenedDialogCount() !== 0)
            return;

        document.body.style.overflow = "auto";
        document.getElementsByTagName("html")[0].style.paddingRight = "0";
        document.getElementsByTagName("header")[0].style.paddingRight = "0";
        const dCats = document.getElementById("div_categories");
        if (dCats)
            dCats.style.paddingRight = "0";

        const divlang = document.getElementById("div_lang");
        if (divlang)
            divlang.style.paddingRight = "0";
    }

    /**
    * Shows a dialog with the given ID and optionally sets focus to an element inside the dialog.
    * @param {string} dialogID - The ID of the dialog to show.
    * @param {string|null} focusElementID - The ID of the element to set focus to when the dialog is shown. If null or undefined, no focus will be set.
    * @returns {void}
    */
    public static DialogShow(dialogID: string, localAolo: IaOLO, focusElementID: string | null): void {
        const dialog = document.getElementById(dialogID) as HTMLDialogElement;
        if (dialog && !dialog.open) {
            this.LockBody(localAolo);
            dialog.classList.add("opacity1");
            dialog.showModal();

            if (focusElementID) {
                const fElement = document.getElementById(focusElementID);
                if (fElement)
                    fElement.focus();
            }
        }
    }

    /**
    * Hides the dialog with the given ID.
    * @param {string} dialogID - The ID of the dialog to hide.
    * @returns {void}
    */
    public static DialogHide(dialogID: string): void {
        const dialog = document.getElementById(dialogID) as HTMLDialogElement;
        if (dialog) {
            dialog.close();
            dialog.classList.remove("opacity1");
            dialog.classList.remove("fadeout");
            this.DialogClosed(); // DO NOT COMMENT THIS
        }
    }

    /**
    * Locks the body of the document to prevent scrolling, and adjusts the padding of the header and category div if they exist,
    * to prevent the scrollbar from pushing content to the side.
    * @returns {void}
    */
    private static LockBody(localAolo: IaOLO) {
        if (!localAolo)
            return;

        document.getElementsByTagName("body")[0].style.overflow = "hidden";
        //document.getElementsByTagName("body")[0].style.height = "100vh";
        document.getElementsByTagName("html")[0].style.paddingRight = `${localAolo.scrollbarWidth}px`;

        const headers = document.getElementsByTagName("header");
        if (headers && headers.length > 0)
            document.getElementsByTagName("header")[0].style.paddingRight = `${localAolo.scrollbarWidth}px`;

        const divlang = document.getElementById("div_lang");
        if (divlang)
            divlang.style.paddingRight = `${localAolo.scrollbarWidth}px`;
    }

    /**
    * Logs an error to the server along with its location and message.
    * @param {string} location - The location where the error occurred.
    * @param {Error} error - The error object to log.
    * @returns {void}
    */
    public static LogError(location: string, error: Error, localAolo: IaOLO): void {
        this.LogErrors(`error at >> ${location} >> ${error.message}`, error, localAolo);
    }

    /**
    * Logs an error message and stack trace to the server.
    * @param {string} msg - The error message to log.
    * @param {Error} error - The error object containing the stack trace.
    * @returns {boolean} - Returns false.
    */
    public static LogErrors(msg: string, error: Error, localAolo: IaOLO): boolean {
        return this.LogErrorString(msg, error.stack ? error.stack : '', localAolo);
    }

    /**
    * Logs an error to the server along with its message and stack trace.
    * @param {string} msg - The error message to log.
    * @param {string} stack - The stack trace of the error to log.
    * @returns {boolean} Returns false to indicate that the error was logged successfully.
    */
    private static LogErrorString(msg: string, stack: string, localAolo: IaOLO): boolean {
        try {
            if (msg.indexOf("home/JSError") > -1)
                return false;

            if (msg.indexOf("home/GetDateTime") > -1)
                return false;

            if (stack.indexOf("gtm") > -1)
                return false;

            if (!localAolo.Temp)
                return false;

            if (!localAolo.Temp.LastLoggedErrorTime) {
                localAolo.Temp.LastLoggedErrorTime = 0;
                localAolo.Temp.LastLoggedErrorMsg = "";
            }

            const errorTime = new Date();
            const errorMilliSec = errorTime.getTime();

            if (localAolo.globalErrorValue && localAolo.globalErrorValue !== "") {
                msg += ` - values >> ${localAolo.globalErrorValue}`;
            }

            localAolo.globalErrorValue = "";

            if (localAolo.Temp.LastLoggedErrorTime + 5000 < errorMilliSec
                || localAolo.Temp.LastLoggedErrorMsg !== msg) {

                const err = {
                    sky: localAolo.storeInfo.StoreKey,
                    sdt: Util.formatDateTimeSeconds(this.NowStore(localAolo.Temp.TimeOffset)),
                    loc: "Online order",
                    msg: msg,
                    dtl: "",
                    stk: stack,
                    brws: this.GetBrowserName(),
                    oguid: (localAolo.Order?.GUID) ? localAolo.Order.GUID : "",
                };

                localAolo.Modules.OnlineOrderingService.postJSError(JSON.stringify(err), localAolo);
            }
            localAolo.Temp.LastLoggedErrorTime = errorMilliSec;
            localAolo.Temp.LastLoggedErrorMsg = msg;
        } catch (ex: unknown) {
            const ex1 = ex;
        }
        return false;
    }

    public static getScrollbarWidth(): number {
        // Creating invisible container
        const outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.overflow = 'scroll'; // forcing scrollbar to appear
        if (/Edge\/|Trident\/|MSIE /.test(navigator.userAgent)) {
            (outer.style as any).msOverflowStyle = 'scrollbar'; // needed for WinJS apps
        }
        document.body.appendChild(outer);

        // Creating inner element and placing it in the container
        const inner = document.createElement('div');
        outer.appendChild(inner);

        // Calculating difference between container's full width and the child width
        const scrollbarWidth = (outer.offsetWidth - inner.offsetWidth);

        // Removing temporary elements from the DOM
        if (outer.parentNode)
            outer.parentNode.removeChild(outer);

        return scrollbarWidth;
    }

    /**
    * Checks if a value is a valid phone number.
    * @param {string} value - The value to check.
    * @returns {boolean} - True if the value is a valid phone number, false otherwise.
    */
    public static CheckIsPhone(value: string): boolean {
        const targ = value.replace(/[^\d]/g, ''); // remove all non-digits
        return (targ !== null) && (targ !== "") && (targ.length === 10);
    }

    /**
    * Checks if a value is a valid email address.
    * @param {string} value - The value to check.
    * @returns {boolean} - True if the value is a valid email address, false otherwise.
    */
    public static CheckIsEmail(value: string): boolean {
        const filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
        return String(value).search(filter) != -1;
    }

    /**
    * Checks if a value is a valid password.
    * @param {string} value - The value to check.
    * @returns {boolean} - True if the value is a valid password, false otherwise.
    */
    public static CheckIsPassword(value: string): boolean {
        const filter = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[*+@#$%&!])[0-9a-zA-Z*+@#$%&!]{8,20}$/;
        return String(value).search(filter) != -1;
    }

    /**
    * Checks if the provided value is a valid email address.
    * @param {string} value - The email address to validate.
    * @returns {boolean} - Whether the provided value is a valid email address.
    */
    public static IsValidEmail(value: string): boolean {
        const filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/;
        return String(value).search(filter) !== -1;
    }

    /**
    * Fixes the case of the provided string by capitalizing the first letter of each word and lowercasing the rest.
    * @param {string} value - The string to fix the case of.
    * @returns {string} - The fixed string.
    */
    public static FixStringCase(value: string): string {
        const words = value.split(" ");
        let result = "";
        words.forEach(word => {
            if (word) {
                const firstChar = word.charAt(0).toUpperCase();
                const restOfWord = word.substring(1).toLowerCase();
                result += firstChar + restOfWord + ' ';
            }
        });
        return result.trim();
    }

    /**
    * Checks if the provided value is a number.
    * @param {(string|number)} value - The value to check.
    * @returns {boolean} - Whether the provided value is a number.
    */
    private static CheckIsNumber(value: string | number): boolean {
        if (typeof (value) == 'number') {
            value = value.toString();
        }
        if (typeof (value) == 'string') {
            if (this.CheckMinLength(value, 1)) {
                return !isNaN(Number(value));
            } else {
                return false;
            }
        }
        return false;
    }

    /**
    * Checks if the provided value is at least the provided minimum length.
    * @param {*} value - The value to check.
    * @param {number} minLength - The minimum length that the value must be.
    * @returns {boolean} - Whether the provided value is at least the provided minimum length.
    */
    private static CheckMinLength(value: any, minLength: number): boolean {
        if (value != undefined)
            return (value.length >= minLength);
        else
            return false;
    }

    /**
    * Check if the zip code is 5 digit numeric 
    * @param value - zip code to check
    * @returns - Whether the provided value is valid or not.
    */
    public static isValidZipCode(value: any): boolean {
        const rExp: RegExp = /^\d{5}$/;
        return rExp.test(value);
    }

    /**
    * Calculates the distance between two sets of latitude and longitude coordinates
    * using the Haversine Distance formula and returns the result in the specified unit.
    * @param {number} lat1 - The latitude of the first point in degrees.
    * @param {number} lon1 - The longitude of the first point in degrees.
    * @param {number} lat2 - The latitude of the second point in degrees.
    * @param {number} lon2 - The longitude of the second point in degrees.
    * @param {string} unit - The unit in which to return the distance, one of "M" for miles, 
    *                        "K" for kilometers, "N" for nautical miles, or "F" for feet.
    * @returns {number} - The calculated distance between the two points in the specified unit.
    */
    public static GetDistance(lat1: number, lon1: number, lat2: number, lon2: number, unit: string): number {
        //Haversine Distance calculation
        if (lat1 === lat2 && lon1 === lon2) {
            return 0;
        }
        else {
            const radlat1 = Math.PI * lat1 / 180;
            const radlat2 = Math.PI * lat2 / 180;
            const theta = lon1 - lon2;
            const radtheta = Math.PI * theta / 180;
            let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            if (dist > 1) {
                dist = 1;
            }
            dist = Math.acos(dist);
            dist = dist * 180 / Math.PI;
            dist = dist * 60 * 1.1515;                      // Miles
            if (unit === "K") { dist = dist * 1.609344; }   // Kilometers
            if (unit === "N") { dist = dist * 0.8684; }     // Nautical miles
            if (unit === "F") { dist = dist * 5280; }       // Feet
            return this.Float2(dist);
        }
    }

    public static IsHoliday(iDate: Date, localAolo: IaOLO): number {
        const hDate = new Date(iDate.getFullYear(), iDate.getMonth(), iDate.getDate());
        let holidayID = 0;
        const iYear = hDate.getFullYear();
        const newYearDay = new Date(iYear, 0, 1);
        const valentinesDay = new Date(iYear, 1, 14);
        const easterDate = this.GetEasterDate(iYear);
        const independenceDay = new Date(iYear, 6, 4);
        let memorialDay = new Date(iYear, 4, 31);
        const christmasEve = new Date(iYear, 11, 24);
        const christmas = new Date(iYear, 11, 25);
        const newYearEve = new Date(iYear, 11, 31);
        const superBowl = this.GetSuperBowlDate(iYear, localAolo);
        let dayOfWeek = memorialDay.getDay();
        while (dayOfWeek !== 1) {
            memorialDay = this.DateAdd(memorialDay, -1, "d");
            dayOfWeek = memorialDay.getDay();
        }
        let laborDay = new Date(iYear, 8, 1);
        dayOfWeek = laborDay.getDay();
        while (dayOfWeek !== 1) {
            laborDay = this.DateAdd(laborDay, 1, "d");
            dayOfWeek = laborDay.getDay();
        }
        const halloween = new Date(iYear, 9, 31);
        let thanksgivingDay = new Date(iYear, 10, 1);
        let mondayCount = 0;
        for (let i = 1; i < 31; i++) {
            thanksgivingDay = new Date(iYear, 10, i);
            dayOfWeek = thanksgivingDay.getDay();
            if (dayOfWeek === 4) {
                if (mondayCount === 3) {
                    break;
                }
                else {
                    mondayCount += 1;
                }
            }
        }
        if (newYearDay.getTime() == hDate.getTime()) {
            holidayID = 100;// New Year Day
        } else if (valentinesDay.getTime() == hDate.getTime()) {
            holidayID = 150;// valentinesDay
        } else if (easterDate.getTime() == hDate.getTime()) {
            holidayID = 200;// Easter
        } else if (memorialDay.getTime() == hDate.getTime()) {
            holidayID = 300;// Memorial Day
        } else if (independenceDay.getTime() == hDate.getTime()) {
            holidayID = 400;// independence Day
        } else if (laborDay.getTime() == hDate.getTime()) {
            holidayID = 500;
        } else if (halloween.getTime() == hDate.getTime()) {
            holidayID = 550;
        } else if (thanksgivingDay.getTime() == hDate.getTime()) {
            holidayID = 600;
        } else if (christmasEve.getTime() == hDate.getTime()) {
            holidayID = 700;
        } else if (christmas.getTime() == hDate.getTime()) {
            holidayID = 800;
        } else if (newYearEve.getTime() == hDate.getTime()) {
            holidayID = 900;
        } else if (superBowl && superBowl.getTime() == hDate.getTime()) {
            holidayID = 950;
        }
        return holidayID;
    }

    private static GetEasterDate(year: number): Date {
        let day = 0;
        let month = 0;
        const g = year % 19;
        const c = year / 100;
        const h = (c - Math.trunc(c / 4) - Math.trunc((8 * c + 13) / 25) + 19 * g + 15) % 30;
        const i = h - Math.trunc(h / 28) * (1 - Math.trunc(h / 28) * Math.trunc(29 / (h + 1)) * Math.trunc((21 - g) / 11));
        day = i - ((year + Math.trunc(year / 4) + i + 2 - c + Math.trunc(c / 4)) % 7) + 28;
        month = 3;
        if (day > 31) {
            month++;
            day -= 31;
        }
        return new Date(year, month - 1, Math.trunc(day));
    }

    private static GetSuperBowlDate(year: number, localAolo: IaOLO): Date {
        if (localAolo.data.StoreBusinessDate)
            return this.StoreDate(localAolo.data.StoreBusinessDate, localAolo.Temp.TimeOffset);

        switch (year) {
            case 2018:
                return new Date(year, 1, 4);
            case 2019:
                return new Date(year, 1, 3);
            case 2020:
                return new Date(year, 1, 2);
            case 2021:
                return new Date(year, 1, 7);
            case 2022:
                return new Date(year, 1, 13);
            case 2023:
                return new Date(year, 1, 12);
            case 2024:
                return new Date(year, 1, 11);
        }
        return new Date();
    }

    private static StoreDate(dateString: string, timeOffset: number): Date {
        let iDate = null;
        let iTime = null;
        if (!dateString) {
            return this.NowStore(timeOffset);
        }
        if (dateString.indexOf("T") > -1) {
            const spDateTime = dateString.split("T");
            iDate = spDateTime[0].split("-");
            iTime = spDateTime[1].split(":");
        } else if (dateString.indexOf(" ") > -1) {
            const spDateTime = dateString.split(" ");
            if (spDateTime[0].indexOf("/") > -1) {
                iDate = spDateTime[0].split("/");
                const yearVal = iDate[2];
                iDate[2] = iDate[1];
                iDate[1] = iDate[0];
                iDate[0] = yearVal;
            } else {
                iDate = spDateTime[0].split("-");
            }
            iTime = spDateTime[1].split(":");
            if (spDateTime.length > 2) {
                const ampm = spDateTime[2];
                const hr = parseInt(iTime[0]);
                if (ampm.toLowerCase() == 'pm' && hr >= 1 && hr < 12) {
                    iTime[0] = (hr + 12).toString();
                } else if (ampm.toLowerCase() == 'am' && hr == 12) {
                    iTime[0] = '0';
                }
            }
        } else if (dateString.indexOf("-") > -1) {
            iDate = dateString.split("-");
        } else if (dateString.indexOf(" ") > -1 || dateString.indexOf(":") > -1) {
            iTime = dateString.split(":");
        }
        let iYear = 1970;
        let iMonth = 1;
        let iDay = 1;
        let iHour = 0;
        let iMinute = 0;
        let iSecond = 0;
        if (iDate) {
            iYear = parseInt(iDate[0]);
            iMonth = parseInt(iDate[1]) - 1;
            iDay = parseInt(iDate[2]);
        }
        if (iTime) {
            iHour = parseInt(iTime[0]);
            iMinute = parseInt(iTime[1]);
            iSecond = parseInt(iTime[2]);
        }
        let nowDateTime = new Date();
        if (!iDate && !iTime && dateString != "") {
            try {
                nowDateTime = new Date(dateString);
            } catch (ex) { }
        } else {
            nowDateTime = new Date(iYear, iMonth, iDay, iHour, iMinute, iSecond, 0);
        }
        return nowDateTime;
    }

    /**
    * Rounds a floating point number to the specified number of decimal places and returns the result.
    * @param {string|number} num - The number to round.
    * @param {number} decimalPlaces - The number of decimal places to round to.
    * @returns {number} - The resulting rounded number.
    */
    public static FloatX(num: string | number, decimalPlaces: number): number {
        const number = this.FloatRemove_e(Number(num));
        return +(Math.round(Number(number + "e+" + decimalPlaces)) + "e-" + decimalPlaces);
    }

    /**
    * Removes the "e" notation from a floating point number and returns the result as a float.
    * @param {number} value - The value to remove "e" notation from.
    * @returns {number} - The resulting float value.
    */
    private static FloatRemove_e(value: number): number {
        let stringValue = parseFloat(value.toFixed(13)) + " ";
        if (stringValue.indexOf("e") === -1) {
            return parseFloat(stringValue);
        }
        for (let i = 13; i > 0; i--) {
            stringValue = parseFloat(value.toFixed(i)) + " ";
            if (stringValue.indexOf("e") === -1) {
                break;
            }
        }
        return parseFloat(stringValue);
    }

    /**
    * Converts a value to a floating point number.
    * @param {string|number} value - The value to convert to a floating point number.
    * @returns {number} - The resulting floating point number, or 0 if the value cannot be converted.
    */
    public static FloatNum(value: string | number): number {
        let num = 0;
        try {
            switch (typeof value) {
                case "string":
                    if (this.CheckIsNumber(value)) {
                        num = parseFloat(value);
                    }
                    break;
                case "number":
                    num = value;
                    break;
            }
        }
        catch (ex) {
            num = 0;
        }
        return num;
    }

    /**
    * Rounds a floating point number to 2 decimal places and returns the result.
    * @param {string|number} value - The number to round.
    * @returns {number} - The resulting rounded number.
    */
    public static Float2(value: string | number): number {
        let returnValue = 0.0;
        const num = Util.FloatNum(value);
        returnValue = Util.FloatX(num, 2);
        return returnValue;
    }

    /**
    * Rounds a floating point number to 5 decimal places and returns the result.
    * @param {string|number} value - The number to round.
    * @returns {number} - The resulting rounded number.
    */
    public static Float5(value: string | number): number {
        let returnValue = 0.0;
        const num = Util.FloatNum(value);
        returnValue = Util.FloatX(num, 5);
        return returnValue;
    }

    public static Float12(value: string | number): number {
        let returnValue = 0.0;
        const num = Util.FloatNum(value);
        returnValue = Util.FloatX(num, 12);
        return returnValue;
    }

    private static Repeat(text: string, length: number): string {
        length = length || 1;
        return Array(length + 1).join(text);
    }

    public static Right(text: string, maxLength: number, fillWith: string): string {
        if (!fillWith) fillWith = " ";
        const txt = this.Repeat(fillWith, maxLength) + text;
        return txt.substring(txt.length - maxLength);
    }

    public static RightText(text: string, length: number): string {
        const tLen = text.length;
        if (tLen < length) {
            return text;
        } else {
            return text.substring(tLen - length);
        }
    }

    /**
    * Converts a string to start case format (capitalizing the first letter of each word and converting the rest to lowercase).
    * @param {string} value - The string to convert to start case.
    * @returns {string} The string in start case format.
    */
    public static toStartCase(value: string): string {
        return value.replace(/\w\S*/g, function (txt: string): string {
            return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
        });
    }

    public static setFixedTimeUsage(useFixed: boolean): void {
        this.useFixedTime = useFixed;
    }

    public static setFixedTime(time: string): void {
        this.fixedTime = time;
    }

    /**
    * Returns the current date and time, accounting for a specified time offset.
    * @returns {Date} The current date and time, accounting for a specified time offset.
    */
    public static NowStore(timeOffset: number): Date {
        //@ts-ignore
        if (localStorage.tempNow)
            //@ts-ignore
            return new Date(localStorage.tempNow);// '9/14/2024 02:55:00 GMT-0700 (Pacific Daylight Time)'); // iDate
        const date = this.useFixedTime ? new Date(this.fixedTime) : new Date();
        const date_ms = date.getTime();
        const nowDateTime = new Date(date_ms + timeOffset);
        return nowDateTime;
    }

    public static nowTodayMinute(timeOffset: number): number {
        const d = this.NowStore(timeOffset);
        return d.getMinutes() + (d.getHours() * 60);
    }

    public static GetBUSDate(dateTime: Date, localAolo: IaOLO): Date {
        //const nowDateTime = Util.NowStore(localAolo.Temp.TimeOffset);
        let bDateTime = Util.NowStore(localAolo.Temp.TimeOffset);
        if (Object.prototype.toString.call(dateTime) === '[object Date]')
            bDateTime = dateTime;
        const busStartMin = localAolo.data.Settings.BSM;
        bDateTime = Util.DateAdd(bDateTime, -busStartMin, "m");

        return Util.DateAdd(new Date(bDateTime.setHours(0, 0, 0, 0)), busStartMin, 'm');
    }

    private static IsDateObject(date: Date): boolean {
        return Object.prototype.toString.call(date) === '[object Date]';
    }

    /**
    * Returns the name of the browser being used.
    * @returns {string} The name of the browser being used.
    */
    private static GetBrowserName(): string {
        const userAgent = navigator.userAgent;

        if (userAgent.indexOf("Firefox") > -1) {
            return "Firefox";
        } else if (userAgent.indexOf("Chrome") > -1) {
            return "Chrome";
        } else if (userAgent.indexOf("Safari") > -1) {
            return "Safari";
        } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
            return "Internet Explorer";
        } else if (userAgent.indexOf("Edge") > -1) {
            return "Edge";
        } else if (userAgent.indexOf("Opera") > -1) {
            return "Opera";
        } else {
            return "Unknown";
        }
    }

    public static isIncompatibleBrowser(): boolean {
        const userAgent = window.navigator.userAgent;
        const isIE = userAgent.indexOf("MSIE ") > -1 || userAgent.indexOf("Trident/") > -1;
        const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);

        if (isIE) return true;

        if (isSafari) {
            const safariVersion = userAgent.match(/Version\/(\d+\.\d+)/);
            if (safariVersion && safariVersion.length > 1) {
                const version = parseFloat(safariVersion[1]);
                return version < 14;
            }
        }

        return false;
    }

    private static isOldBrowserForDialog(): boolean {
        const userAgent = window.navigator.userAgent;
        const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
        const isFirefox = userAgent.toLowerCase().indexOf('firefox') > -1;

        if (isSafari) {
            const safariVersion = userAgent.match(/Version\/(\d+\.\d+)/);
            if (safariVersion && safariVersion.length > 1) {
                const version = parseFloat(safariVersion[1]);
                return version < 15.4;
            }
        }

        if (isFirefox) {
            const firefoxVersion = userAgent.match(/Firefox\/(\d+\.\d+)/);
            if (firefoxVersion && firefoxVersion.length > 1) {
                const version = parseFloat(firefoxVersion[1]);
                return version < 98;
            }
        }

        return false;
    }

    /**
    * Asynchronously updates the address list displayed in a specified data list element based on user input.
    * @async
    * @param {string} inputID - The ID of the input element containing user input.
    * @param {string} dataListID - The ID of the data list element to update with address options.
    * @param {any} callback - Optional callback function to execute before updating the address list.
    * @returns {Promise<void>} - Promise that resolves when the data list element has been updated.
    */
    public static async UpdateAddressList(inputID: string, apartmentId: string, dataListId: string, zipCodes: string, localAolo: IaOLO, callback: Function | null): Promise<void> {
        const input = this.getElementValue(inputID);
        if (!input) {
            this.setElement("innerHTML", dataListId, "");
            return;
        }

        if (input.length > 3 && input.length < 21) {
            const data = {
                addr: input,
                zips: zipCodes
            };
            const result = await localAolo.Modules.OnlineOrderingService.getAddressList(data, localAolo);
            this.SetAddressList(result, dataListId, apartmentId);
        } else if (input.length < 4) {
            this.setElement("innerHTML", dataListId, "");
        } else if (callback)
            await callback(input);
    }

    /**
    * Updates the address options displayed in a specified data list element based on the response from an endpoint.
    * @param {string} response - The response from the endpoint as a string.
    * @param {any} params - Additional parameters to pass to the function (in this case, the data list ID).
    * @returns {void}
    */
    private static SetAddressList(response: { Addr: any[] }, dataListId: string, apartmentId: string): void {
        if (this.isAppView())
            dataListId = `${dataListId}_wv`;

        if (response.Addr.length == 0) {
            this.setElement("innerHTML", dataListId, "");
            return;
        }

        const dList = document.getElementById(dataListId);
        const addrs = response;
        if (!addrs.Addr || !dList)
            return;

        const addList: string[] = [];
        const dlItems: { text: string, value: string }[] = [];
        for (const item of addrs.Addr) {
            let addr: string = item.StNo;
            if (item.StPreDirAbbr !== "") { addr += ' ' + item.StPreDirAbbr; }
            if (item.StName !== "") { addr += ' ' + Util.FixStringCase(item.StName); }
            if (item.StSuffixAbbr !== "") { addr += ' ' + Util.FixStringCase(item.StSuffixAbbr); }
            if (item.StPostDirAbbr !== "") { addr += ' ' + item.StPostDirAbbr; }
            if (item.City !== "") { addr += ' ' + item.City; }
            if (item.State !== "") { addr += ' ' + item.State; }
            if (item.ZipCode !== "") { addr += ' ' + item.ZipCode; }

            addList.push(addr);

            if (this.isAppView()) {
                dlItems.push({ text: addr, value: JSON.stringify(item) });
            } else {
                const found = Array.from(dList.children).some(child => (child as HTMLOptionElement).value === addr);

                if (!found) {
                    const option = document.createElement("option");
                    option.value = addr;
                    option.setAttribute("data-value", JSON.stringify(item));
                    option.onclick = () => {
                        this.setFocus(apartmentId);
                    };
                    dList.appendChild(option);
                }
            }
        }

        if (this.isAppView()) {
            new CustomDatalist(dataListId.replace("dl_", "txt_").replace("_wv", ""), dataListId, dlItems);

            dList.querySelectorAll("div").forEach((child) => {
                if (!addList.includes(child.innerText))
                    child.remove();
            });
        } else {
            while (dList.children.length > addList.length) {
                for (let i = 0; i < dList.children.length; i++) {
                    if (!addList.includes((dList.children[i] as HTMLOptionElement).value)) {
                        dList.children[i].remove();
                        break;
                    }
                }
            }
        }
    }

    /**
    * Hides the element with the given ID by adding the "hidden" class to its class list.
    * @param {string} elementId - The ID of the element to hide.
    * @returns {void}
    */
    public static hideElement(element: string | HTMLElement): void {
        const elementToModify = (typeof element === "string" ? document.getElementById(element) : element) as HTMLElement;
        if (elementToModify)
            elementToModify.classList.add("hidden");
    }

    /**
    * Shows the element with the given ID by removing the "hidden" class from its class list.
    * @param {string} elementId - The ID of the element to show.
    * @returns {void}
    */
    public static showElement(element: string | HTMLElement): void {
        const elementToModify = (typeof element === "string" ? document.getElementById(element) : element) as HTMLElement;
        if (elementToModify)
            elementToModify.classList.remove("hidden");
    }

    /**
    * Visibility off the element with the given ID by adding the "none-visibility" class to its class list.
    * @param {string} elementId - The ID of the element to hide.
    * @returns {void}
    */
    public static visibilityOffElement(element: string | HTMLElement): void {
        const elementToModify = (typeof element === "string" ? document.getElementById(element) : element) as HTMLElement;
        if (elementToModify)
            elementToModify.classList.add("none-visibility");
    }

    /**
    * Shows the element with the given ID by removing the "none-visibility" class from its class list.
    * @param {string} elementId - The ID of the element to show.
    * @returns {void}
    */
    public static visibilityOnElement(element: string | HTMLElement): void {
        const elementToModify = (typeof element === "string" ? document.getElementById(element) : element) as HTMLInputElement;
        if (elementToModify)
            elementToModify.classList.remove("none-visibility");
    }

    /**
    * Gets the value of the element with the given ID.
    * @param {string} elementId - The ID of the element to get the value of.
    * @returns {string} The value of the element.
    */
    public static getElementValue(elementId: string): string {
        const element = document.getElementById(elementId) as HTMLInputElement;
        if (element)
            return element.value;
        return "";
    }

    public static getElementChecked(elementId: string): boolean {
        const element = document.getElementById(elementId) as HTMLInputElement;
        if (element)
            return element.checked;
        return false;
    }

    /**
    * Sets the value of the given element using the specified method and value.
    * @param {string} method - The method to use to set the value of the element. Can be one of "VALUE", "CHECKED", "DISABLED", "INNERHTML", "ONCLICK", "ONCHANGE", or "ONINPUT". 
    *                          If the method is not recognized, it will be treated as an attribute name and set using the specified value.
    * @param {string | HTMLElement} element - The ID of the element to set the value of or a reference to the element itself.
    * @param {*} value - The value to set the element to.
    * @returns {void}
    */
    public static setElement(method: string, element: string | HTMLElement, value: any): void {
        const elementToModify = (typeof element === "string" ? document.getElementById(element) : element) as HTMLInputElement;
        if (elementToModify && value != null) {
            switch (method.toUpperCase()) {
                case "VALUE":
                    elementToModify.value = value.toString();
                    break;
                case "CHECKED":
                    elementToModify.checked = value;
                    break;
                case "DISABLED":
                    elementToModify.disabled = value;
                    break;
                case "INNERHTML":
                    elementToModify.innerHTML = value;
                    break;
                case "INNERTEXT":
                    elementToModify.innerText = value;
                    break;
                case "ONCLICK":
                    if (value == null)
                        elementToModify.onclick = null;
                    else
                        elementToModify.onclick = () => {
                            if (typeof value === "function") {
                                value();
                            } else if (Array.isArray(value)) {
                                value[0](...value.slice(1));
                            }
                        };
                    break;
                case "ONCHANGE":
                    if (value == null)
                        elementToModify.onchange = null;
                    else
                        elementToModify.onchange = function () {
                            if (typeof value === "function") {
                                value();
                            } else if (Array.isArray(value)) {
                                value[0](...value.slice(1));
                            }
                        };
                    break;
                case "ONINPUT":
                    if (value == null)
                        elementToModify.oninput = null;
                    else
                        elementToModify.oninput = function () {
                            if (typeof value === "function") {
                                value();
                            } else if (Array.isArray(value)) {
                                value[0](...value.slice(1));
                            }
                        };
                    break;

                case "ONBLUR":
                    if (value == null)
                        elementToModify.onblur = null;
                    else
                        elementToModify.onblur = function () {
                            if (typeof value === "function") {
                                value();
                            } else if (Array.isArray(value)) {
                                value[0](...value.slice(1));
                            }
                        };
                    break;

                case "ONFOCUS":
                    if (value == null)
                        elementToModify.onfocus = null;
                    else
                        elementToModify.onfocus = function () {
                            if (typeof value === "function") {
                                value();
                            } else if (Array.isArray(value)) {
                                value[0](...value.slice(1));
                            }
                        };
                    break;
                default:
                    elementToModify.setAttribute(method, value);
                    break;
            }
        }
    }

    /**
    * Sets or removes a CSS class from an element based on the method parameter.
    * @param {string} method - The method to be performed. Can be either "ADD" or "REMOVE".
    * @param {string} elementId - The ID of the element to be targeted.
    * @param {string} value - The CSS class to be added or removed.
    * @returns {void}
    */
    public static setElementClass(method: string, elementId: string, value: string): void {
        const element = document.getElementById(elementId) as HTMLInputElement;
        if (element) {
            switch (method.toUpperCase()) {
                case "ADD":
                    element.classList.add(value);
                    break;
                case "REMOVE":
                    element.classList.remove(value);
                    break;
            }
        }
    }

    public static removeChildren(element: HTMLElement | string): void {
        const elementToModify = (typeof element === "string" ? document.getElementById(element) : element) as HTMLInputElement;
        if (elementToModify) {
            while (elementToModify.firstChild) {
                elementToModify.removeChild(elementToModify.firstChild);
            }
        }
    }

    /**
    * Removes all non-numeric characters from a string.
    * @param {string} value - The string to be cleaned.
    * @returns {string} - The cleaned string.
    */
    public static cleanNonDigits(value: string): string {
        return value.replace(/\D/g, "");
    }

    /**
    * Formats a phone number into a standardized format.
    * @param {string} phoneNumber - The phone number to be formatted.
    * @returns {string} - The formatted phone number.
    */
    public static formatPhoneNumber(phoneNumber: string, format: string): string {
        // Remove all non-digit characters from the phone number
        const cleaned = phoneNumber.replace(/\D/g, '');

        // If there are no digits in the cleaned phone number, return an empty string
        if (cleaned.length === 0) {
            return '';
        }

        let formattedNumber = '';
        let index = 0;

        // Iterate over the format string
        for (let i = 0; i < format.length; i++) {
            // If the current character is 'X', replace it with the corresponding digit from the phone number
            if (format[i] === 'X') {
                if (index < cleaned.length) {
                    formattedNumber += cleaned[index];
                    index++;
                } else {
                    // If there are no more digits in the phone number, break out of the loop
                    break;
                }
            } else {
                // If the current character is not 'X', simply append it to the formatted number
                formattedNumber += format[i];
            }
        }

        return formattedNumber;
    }


    /**
    * Formats a phone number as the user types.
    * @param {string} id - The ID of the input element.
    * @param {string} key - The key that was pressed.
    * @returns {void}
    */
    public static formatPhoneNumberAuto(id: string, isoCode: string | null): void {
        const textbox = document.getElementById(id) as HTMLInputElement;
        if (!textbox)
            return;

        let input = textbox.value;

        // Strip all characters from the input except digits
        input = input.replace(/\D/g, '');

        const format = Util.getPhoneFormat(input, isoCode);
        textbox.value = Util.formatPhoneNumber(input, format);
    }

    public static GetBrowserLanguage(): string {
        return navigator.language;
    }

    /** *
     * Cleans a given phone number to just digits.
     * 
     * @param { string | null | undefined } isoCode The iso code used to determine the phone format.
     * @returns { string } The phone format corresponding the the isoCode.
     */
    public static getPhoneFormat(phoneNumber: string, isoCode: string | null | undefined): string {
        switch (isoCode) {
            case "US":
                return "(XXX) XXX-XXXX";
            case "MX":
                if (phoneNumber.startsWith("55") ||
                    phoneNumber.startsWith("56") ||
                    phoneNumber.startsWith("81") ||
                    phoneNumber.startsWith("33"))
                    return "XX XXXX XXXX";
                else
                    return "XXX XXX XX XX";
            default:
                return "(XXX) XXX-XXXX";
        }
    }

    public static populateCountryCodeSelectList(selectElementId: string, phoneElementId: string, selectedValue?: number): void {
        const select = document.getElementById(selectElementId) as HTMLSelectElement;
        if (!select)
            return;

        select.innerHTML = "";
        const countries: IDataCountry[] = aOLO.data.Countries.length > 0 ? aOLO.data.Countries : aOLO.brandInfo?.countries || [];

        for (const country of countries) {
            const option = document.createElement("option") as HTMLOptionElement;
            option.value = country.CountryID.toString();
            option.textContent = `${country.IsoCode} (${country.CountryCode})`;
            option.dataset.isoCode = country.IsoCode;
            option.dataset.name = `${country.Name} (${country.CountryCode})`; // Set selected display value
            option.dataset.abr = `${country.IsoCode} (${country.CountryCode})`; // Set selected display value
            select.appendChild(option); // Append the option to the select element
        }

        if (selectedValue) {
            setTimeout(() => {
                Util.setElement("value", select, selectedValue.toString());
            }, 100);
            Util.populateCountryCodeSelectList_PhoneFormat(phoneElementId, selectedValue);
        }

        select.onfocus = () => {
            const options = Array.from(select.options);
            for (const option of options) {
                option.textContent = option.dataset.name || option.text;
            }
        };

        select.onblur = () => {
            const options = Array.from(select.options);
            for (const option of options) {
                option.textContent = option.dataset.abr || option.text;
            }
        };

        select.onchange = async () => {
            const options = Array.from(select.options);
            for (const option of options) {
                option.textContent = option.dataset.abr || option.text;
            }

            Util.populateCountryCodeSelectList_PhoneFormat(phoneElementId, Number(select.value));

            const phoneInput = document.getElementById(phoneElementId) as HTMLSelectElement;
            if (phoneInput)
                Util.setFocus(phoneInput);
        }
    }

    public static setFocus(element: string | HTMLElement): void {
        const elementToModify = (typeof element === "string" ? document.getElementById(element) : element) as HTMLInputElement;
        if (elementToModify)
            elementToModify.focus();
    }

    public static populateCountryCodeSelectList_PhoneFormat(phoneElementId: string, countryCodeId: number): void {
        const phoneInput = document.getElementById(phoneElementId) as HTMLSelectElement;
        if (!phoneInput)
            return;

        const countries: IDataCountry[] = aOLO.data?.Countries || aOLO.brandInfo?.countries || []
        const phoneCountry = countries.find(x => x.CountryID === countryCodeId) || countries.find(x => x.CountryID === countryCodeId) || null;
        const isoCode = phoneCountry?.IsoCode || null;
        const newPhoneFormat = Util.getPhoneFormat(Util.getElementValue(phoneElementId).replace(/\D/g, ''), isoCode);

        phoneInput.value = Util.formatPhoneNumber(phoneInput.value, newPhoneFormat);
        phoneInput.setAttribute('placeholder', Util.formatPhoneNumber("5555555555", newPhoneFormat));

        phoneInput.onkeyup = (event) => {
            const key = event.key;
            if (key !== "Backspace" && key !== "Delete")
                Util.formatPhoneNumberAuto(phoneInput.id, isoCode);
        }
    }

    /**
    * Creates an HTMLElement from an HTML string.
    * 
    * @param {string} htmlContent - The HTML string to be transformed into an HTMLElement.
    * @returns {HTMLElement} The first child of the 'div' containing the supplied HTML string.
    */
    public static createHtmlElementFromTemplate(htmlContent: string): HTMLElement {
        const template = document.createElement("div") as HTMLElement;
        template.innerHTML = htmlContent;
        if (template.firstChild)
            return template.firstElementChild as HTMLElement;
        return document.createElement("div");
    }

    /**
    * Converts an array of IName or IDescription objects to a URI encoded JSON string.
    *
    * @param {IName[] | IDescription[] | INewName[]} items - The array of items to be encoded.
    * @returns {string} The URI encoded JSON string representation of the supplied array.
    */
    public static toLtagj(items: IName[] | IDescription[] | INewName[]): string {
        const string = JSON.stringify(items);
        return encodeURIComponent(string);
    }

    /**
    * Checks if the current environment is a WebView.
     * 
    * @returns {boolean} true if the environment is likely a WebView, false otherwise.
     */
    public static isAppView(): boolean {
        return localStorage.MobileView == "true";
        //&&
        //   /webview|wv|ip((?!.*Safari)|(?=.*like Safari))/i.test(window.navigator.userAgent);
    }

    public static isiOS(): boolean {
        return /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(navigator.userAgent);
    }

    public static deepClone(obj: any): any {
        return JSON.parse(JSON.stringify(obj));
    }

    /**
    * Compares two dates without considering the time.
    * 
    * @param {Date} date1 - First date to compare.
    * @param {Date} date2 - Second date to compare.
    * @returns {number} - Returns 0 if the dates are equal, -1 if date1 is before date2, and 1 if date1 is after date2.
    */
    public static compareDates(date1: Date, date2: Date): number {
        const d1 = new Date(date1);
        d1.setHours(0, 0, 0, 0);

        const d2 = new Date(date2);
        d2.setHours(0, 0, 0, 0);

        if (d1.getTime() === d2.getTime())
            return 0;
        else if (d1 < d2)
            return -1;
        else
            return 1;
    }

    /**
     * Saves location to cookie, expiring in 1 hour. 
     */
    public static saveGeoLocationToCookie(lat: number, long: number): void {
        const geolocationData = { latitude: lat, longitude: long };
        document.cookie = 'geoLocation=' + encodeURIComponent(JSON.stringify(geolocationData)) + '; max-age=3600' + '; path=/'; //1 hour expiration
    }

    /**
     * Retrieves geolocation data from a 'geolocation' cookie.
     *
     * Parses the 'geolocation' cookie to extract user's latitude and longitude,
     * stored as JSON. If the cookie is missing or data is malformed, returns null.
     *
     * @returns {IGeoLocationData | null} Geolocation object with latitude and longitude, or null if not found.
     */
    public static getGeoLocationFromCookie(): IGeoLocationData | null {
        const cookie = document.cookie;
        const name = "geoLocation=";
        const decodedCookie = decodeURIComponent(cookie);
        const ca = decodedCookie.split(';');
        for (let c of ca) {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                const geoLocation = c.substring(name.length, c.length);
                try {
                    return JSON.parse(geoLocation);
                } catch (e) {
                    return null;
                }
            }
        }
        return null;
    }

    /**
     * 
     * @returns Adora culture code based on browser's default language
     */
    public static GetBrowserCultureCode(): string {
        try {

            if (navigator) {
                return this.GetAdoraCultureCode(navigator.language);
            }

            return "en-us";
        } catch (e) {
            return "en-us";
        }
    }

    /**
     * 
     * @param languageCode browsers default language code. (en-US, en-GF, es-MX, es-GF)
     * @returns Adora culture code
     */
    private static GetAdoraCultureCode(languageCode: string): string {
        let cultureCode: string = "en-us";

        switch (true) {
            case languageCode.startsWith('en'): //cover all English languages e.g en-US, en-GF, en-TT...
                cultureCode = "en-us";
                break;
            case languageCode.startsWith('es'): //cover all Spanish languages e.g es-MX, es-NI, es-CR...
                cultureCode = "sp-mx";
                break;
            default:
                cultureCode = "en-us";

        }

        return cultureCode;
    }

    public static formatAddressObject(address: IAddressFormat, countries: IDataCountry[]): { address1: string, address2: string, address3: string, cityState: string, country: string } {
        const country = countries.find(x => x.CountryID == address.CountryID);
        const isoCode = country?.IsoCode || "";
        const countryName = country?.Name || "";

        let fullAddress = {
            address1: "",
            address2: "",
            address3: "",
            cityState: "",
            country: ""
        };

        // Construct address dynamically based on ISO code
        switch (isoCode) {
            case "MX":
                fullAddress.address1 += address.Address1 ? `${address.Address1}` : "";
                fullAddress.address1 += address.StreetNo ? ` ${address.StreetNo},` : "";

                if (address.Address2) {
                    switch (address.AddressTypeID) {
                        case 3:
                            fullAddress.address2 += ` Unidad ${address.Address2},`;
                            break;
                        case 4:
                            fullAddress.address2 += ` Suite ${address.Address2},`;
                            break;
                    }
                }

                fullAddress.address3 += address.Address3 ? ` ${address.Address3},` : ""; // Cologne/Neighborhood/Quarter
                fullAddress.address3 += address.Address4 ? ` ${address.Address4},` : ""; // Municipality
                fullAddress.cityState += address.ZipCode ? ` ${address.ZipCode}` : "";
                fullAddress.cityState += address.City ? ` ${address.City},` : "";
                fullAddress.cityState += address.State ? ` ${address.State},` : "";

                fullAddress.country += countryName ? ` ${countryName}` : "";
                break;
            case "US":
            default:
                fullAddress.address1 += address.StreetNo ? `${address.StreetNo} ` : "";
                fullAddress.address1 += address.Address1 ? `${address.Address1},` : "";

                if (address.Address2) {
                    switch (address.AddressTypeID) {
                        case 3:
                            fullAddress.address2 += ` Unit ${address.Address2},`;
                            break;
                        case 4:
                            fullAddress.address2 += ` Suite ${address.Address2},`;
                            break;
                    }
                }

                fullAddress.cityState += address.City ? ` ${address.City},` : "";
                fullAddress.cityState += address.State ? ` ${address.State} ` : "";
                fullAddress.cityState += address.ZipCode ? `${address.ZipCode},` : "";

                fullAddress.country += countryName ? ` ${countryName}` : "";
                break;
        }

        // Remove trailing comma if present
        fullAddress.address1 = fullAddress.address1.replace(/,\s*$/, "");
        fullAddress.address2 = fullAddress.address2.replace(/,\s*$/, "");
        fullAddress.address3 = fullAddress.address3.replace(/,\s*$/, "");
        fullAddress.cityState = fullAddress.cityState.replace(/,\s*$/, "");

        return fullAddress;
    }

    /**
     * converts minutes from midnight to hours AM/PM
     * @param minutes
     * @returns
     */
    public static formatMinutesToTime(minutes: number): string {
        const hours = Math.floor(minutes / 60);
        const minutesPast = minutes % 60;
        const amPm = (hours >= 12 && hours < 24) ? 'PM' : 'AM';
        const hours12 = hours % 12 || 12;
        const formattedMinutes = minutesPast.toString().padStart(2, '0');
        return `${hours12}:${formattedMinutes} ${amPm}`;
    }

    public static showComponent(id: string): void {
        const divs = document.getElementsByClassName("content-component");
        for (const div of divs) {
            if (div.id == `div_main_${id}`)
                div.classList.add("active");
            else
                div.classList.remove("active");
        }
    }

    public static getWeekdayNameById(weekdayId: number): string {
        switch (weekdayId) {
            case 0:
                return "Sunday";
            case 1:
                return "Monday";
            case 2:
                return "Tuesday";
            case 3:
                return "Wednesday";
            case 4:
                return "Thursday";
            case 5:
                return "Friday";
            case 6:
                return "Saturday";
            default:
                return "";
        }
    }

    public static getCookie(name: string): string | null {
        try {
            let cookieArr = document.cookie.split(";");
            for (let i = 0; i < cookieArr.length; i++) {
                let cookiePair = cookieArr[i].split("=");
                if (name == cookiePair[0].trim()) {
                    return decodeURIComponent(cookiePair[1]);
                }
            }
        }
        catch { }
        return null;
    }

    /**
     * Retrieves user location data from a cookie, if available.
     * 
     * @returns An object containing latitude and longitude if location data exists, otherwise null.
     */
    public static getUserLocationFromCookie(): { lat: number; lng: number } | null {
        const cookieLocation = Util.getCookie('locationData');
        return cookieLocation ? JSON.parse(cookieLocation) : null;
    }

    /**
     * Retrieves the user's geolocation and stores it in a cookie.
     * If location permissions are denied or unavailable, it sets default values for location.
     * @returns {Promise<void>} Resolves when location data is successfully retrieved or a default value is set.
     */
    public static async getDesktopLocationAsync(): Promise<void> {
        const cookieLocation = Util.getUserLocationFromCookie();
        if (cookieLocation)
            return;

        try {
            const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
            if (permissionStatus.state === 'granted') {
                const position = await Util.getCurrentPositionAsync();
                if (position)
                    Util.setLocationCookie(position.coords.latitude, position.coords.longitude, 3600);
                else 
                    Util.setLocationCookie(0, 0, -10); 
            }
        } catch (error) {
            console.error('Error while checking geolocation permissions:', error);
        }
    }

    public static async getCurrentPositionAsync(): Promise<GeolocationPosition | null> {
        return new Promise((resolve) => {
            navigator.geolocation.getCurrentPosition(
                (position) => { resolve(position); }, // Location services are enabled
                () => { resolve(null); } // Location services are disabled
            );
        });
    };

    /**
     * Sets the location data in a cookie with a specified expiration time.
     * @param {number} lat - Latitude value.
     * @param {number} lng - Longitude value.
     * @param {number} maxAge - The maximum age of the cookie in seconds. Use negative value to expire the cookie.
     */
    public static setLocationCookie(lat: number, lng: number, maxAge: number): void {
        try {
            const domain = window.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.');
            document.cookie = `locationData={"lat":${lat},"lng":${lng}};max-age=${maxAge};path=/;domain=${domain}`;
        } catch (error) {
            console.error('Error while setting location cookie:', error);
        }
    }

}