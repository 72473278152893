/*2023 Copyright (C) Adora POS, LLC - All Rights Reserved
 *WARNING! Commercial Software, All Use Must Be Licensed
 *This software is protected by U.S. Copyright Law and International
 *Treaties. Unauthorized use, duplication, reverse engineering,
 *any form of redistribution, or use in part or in whole other
 *than by prior, express, printed and signed license for use is
 *subject to civil and criminal prosecution. If you have received
 *this file in error, please notify copyright holder and destroy
 *this and any other copies as instructed.
 */

import { IComponentForm, IModReOrder, ISizeDropdown } from './interfaces/online-ordering.interface';
import { IData, IDataCategory, IDataCoupon, IDataItem, IDataOrderTypeSubType, IItemPrice, IDataDesktopBanner, IDataDesktopBannerImage } from '../interfaces/data.interface';
import { IName } from '../interfaces/global.interfaces';
import { IOrderItem, IOrderItemModifier } from '../interfaces/order.interface';
import { Util } from '../utils/util';
import { Names, setPageLanguage } from '../utils/i18n';
import { ItemStyle, LoggedInStatus, OrderType, Pages } from '../types/enums';
import { OrderTypeDialog } from './order-type';
import { OnlineOrderingService } from './online-ordering-service';
import { CustomizeItem } from './customize-item';
import { ProfileService } from '../services/api/profile-service';
import { Coupon } from './coupon';
import { LanguageBar } from '../shared/language-bar';
import { Categories } from './categories';
import { OrderCart } from './order-cart';
import { Checkout } from './checkout';
import { DataLayerService } from './data-layer-service';
import { VerticalMenu } from '../shared/vertical-menu';
import { Header } from '../components/shared/Header/header';
import { CallCenterStoresDialog } from './call-center-stores';
import { SignIn } from '../shared/sign-in';
import { Profile } from '../shared/profile';
import { NavigationBar } from '../components/shared/NavigationBar/navigation-bar';
import { DialogCreators } from '../utils/dialog-creators';
import { OnlineOrderingUtil } from './online-ordering-util';
import { IOrderTypeFunctions } from './interfaces/order-type.interface';
import { IOrderCartFunctions } from './interfaces/order-cart.interface';
import { Common, profileReloadApp } from '../common'
import { CheckOrderTime, OrderResult } from './checkout-util';
import { LoyaltyProviderFactory } from '../services/loyalty/loyalty-provider-factory';
import { CustomerProviderFactory } from '../services/customer/customer-provider-factory';
import '../../css/online-ordering/online-ordering.css';
import { IApplyCouponByIdParams } from './interfaces/coupons.interface';
import { IaOLO } from '../interfaces/aolo.interface';
import { IDateStartEndTime } from './interfaces/shared.interfaces';
import { Landing } from '../components/pages/Landing/landing';
import { createEventBus } from '../core/event-bus';
import { Router } from '../core/router';
import { Locations } from '../components/pages/Locations/locations';
import { Menu } from '../components/pages/Menu/menu';
import { User } from '../models/user';
import { Data } from '../models/data';
import { Order } from '../models/order';
import { LoginManager } from '../services/auth/login-manager';
import { ILoyaltyProvider } from '../services/loyalty/interfaces/loyalty-provider.interface';
import EventEmitter from 'eventemitter3';

export async function initiatePage(globalvar: any, cultureCode: string): Promise<void> {
    // If the page is loaded using the back or forward button, force a refresh
    const navigationEntries = window.performance.getEntriesByType('navigation') as PerformanceNavigationTiming[];
    if (navigationEntries.length > 0 && navigationEntries[0].type === 'back_forward') {
        window.location.reload();
    }

    let languageCode = window.localStorage.getItem("languageCode");

    if (!languageCode)
        languageCode = Util.GetBrowserCultureCode() || cultureCode;

    setGlobalVars(globalvar);

    aOLO.OloUISettings = {
        showSvgLocationIcons: true
    };

    document.addEventListener("DOMContentLoaded", () => {
        //const element = document.querySelector("body");
        //if (element)
        window.addEventListener("scroll", () => {
            const header = document.querySelector(".header");
            if (!header) return;

            // Get the scroll position, checking both documentElement and body
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

            // Add 'scrolled' class if scrolled down by 20 pixels or more
            if (scrollTop > 20) {
                header.classList.add("scrolled");
            } else {
                header.classList.remove("scrolled");
            }
        });
    });


    let storeStatus = null;
    Common.InitiateTemps(aOLO);

    //check for old browser, display warning, and that is it
    if (Util.isIncompatibleBrowser()) {
        DialogCreators.messageBox(Names("BrowserNotSupported"), aOLO.buttonHoverStyle, []);
        return;
    }

    // Check if page being loaded from app. Used for Punchh app at the moment.
    if (aOLO.singleSignOnToken) {
        localStorage.MobileView = "true";
    }

    //Common.setDarkMode(aOLO);

    try { setTimeOffset(aOLO.serverTime.UtcMilliseconds, aOLO.serverTime.StoreUtcOffset); } catch (ex: unknown) {
        if (ex instanceof Error) Util.LogError("InitiatePage SetTimeOffset", ex, aOLO);
    }
    try { aOLO.mediaMax576 = window.matchMedia("(max-width: 576px)"); } catch (ex: unknown) {
        if (ex instanceof Error) Util.LogError("InitiatePage mediaMax576", ex, aOLO);
    }
    try { aOLO.mediaMax840 = window.matchMedia("(max-width: 840px)"); } catch (ex: unknown) {
        if (ex instanceof Error) Util.LogError("InitiatePage mediaMax840", ex, aOLO);
    }
    try { aOLO.mediaMax576.addListener(mediaSizeChanged(true)); } catch (ex: unknown) {
        if (ex instanceof Error) Util.LogError("InitiatePage MediaSizeChanged", ex, aOLO);
    }
    try { aOLO.mediaMax840.addListener(mediaSizeChanged(false)); } catch (ex: unknown) {
        if (ex instanceof Error) Util.LogError("InitiatePage MediaSizeChanged", ex, aOLO);
    }
    try { aOLO.scrollbarWidth = Util.getScrollbarWidth(); } catch (ex: unknown) {
        if (ex instanceof Error) Util.LogError("InitiatePage getScrollbarWidth", ex, aOLO);
    }
    try { storeStatus = checkStoreStatus() } catch (ex: unknown) {
        if (ex instanceof Error) Util.LogError("InitiatePage CheckStoreStatus", ex, aOLO);
    }

    const getlocation = () => { OnlineOrderingUtil.getLocation(aOLO); }

    if (storeStatus) {
        try { setTimeout(getlocation, 1000); } catch (ex: unknown) {
            if (ex instanceof Error) Util.LogError("InitiatePage GetLocation", ex, aOLO);
        }

        await LoadData();
        if (!aOLO.data) {
            DialogCreators.messageBoxOk(Names("OLO_General_Error"), aOLO.buttonHoverStyle);
            return;
        }

        await Common.loadAndMergeTranslations(aOLO.nameTextUrl);

        try {
            setPageLanguage(languageCode);
            if (Util.isAppView()) {
                //@ts-ignore
                if (typeof getAppUserToken == "function")
                    //@ts-ignore
                    getAppUserToken(JSON.stringify({ loc: "olo", key: aOLO.pkey }));
                else
                    await startApp();
            } else
                await startApp();
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("InitiatePage startApp", ex, aOLO);
        }
    }

    // Curried version of LogErrors
    const curriedLogErrors = (msg: string, error: Error) => Util.LogErrors(msg, error, aOLO)
    // Set curriedLogErrors as the window.onerror handler
    window.onerror = curriedLogErrors as any;
}

async function checkUserSignedIn(eventBus: EventEmitter, loyaltyProvider: ILoyaltyProvider, user: User): Promise<void> {
    if (!user.isLoggedIn()) {
        const manager = new LoginManager(eventBus, loyaltyProvider, user, aOLO);
        await manager.signInFromJwtAsync();
    }

        //await Common.profileReload(user, aOLO);
}

export async function setAppUserToken(user: User, token: string): Promise<void> {
    await startApp();
    await profileReloadApp(null, startApp, token, user);
}

/**
* Sets the global variables object.
* @param {any} globalvar - The global variables object to set.
* @returns {void}
*/
function setGlobalVars(globalvar: any): void {
    globalThis.aOLO = JSON.parse(JSON.stringify(globalvar));
    aOLO.AddressLookupUrl = globalvar.AddressLookupUrl;
    const addIncompleteCouponToCart = OnlineOrderingUtil.addIncompleteCouponToCart;
    const DeleteItem = OnlineOrderingUtil.DeleteItem;
    const GUI_SetOrder = OnlineOrderingUtil.GUI_SetOrder;
    aOLO.Modules.Coupon = new Coupon({ addIncompleteCouponToCart, GUI_SetOrder, DeleteItem, Item_AddToOrder });
    aOLO.Modules.ProfileService = new ProfileService(aOLO.LoyaltyApiUrl);
    aOLO.Modules.OnlineOrderingService = new OnlineOrderingService(aOLO.AddressLookupUrl);
}

function setTimeOffset(serverUtcMilliseconds: number, storeUtcOffset: number): void {
    let iDate = new Date();
    let clientUtcMilliseconds = iDate.getTime();
    let ClientMSDiff = serverUtcMilliseconds - clientUtcMilliseconds;
    let clientUtcOffset = iDate.getTimezoneOffset();
    aOLO.Temp.TimeOffset = (clientUtcOffset + storeUtcOffset) * 60000 + ClientMSDiff;
}

function mediaSizeChanged(isMobile: boolean): void {
    let catLinks = document.getElementsByClassName("catLink");
    if (isMobile ? aOLO.mediaMax576.matches : aOLO.mediaMax840.matches) {
        for (let i = 0; i < catLinks.length; i++) {
            catLinks[i].classList.remove("hvr-rectangle-out");
        }
    } else {
        for (let i = 0; i < catLinks.length; i++) {
            catLinks[i].classList.add("hvr-rectangle-out");
        }
    }
}

function checkStoreStatus(): boolean {
    let ReqPeriod = 420;
    if (aOLO.times.LRT > ReqPeriod) {
        DialogCreators.messageBoxOk(Names("OLO_General_Error"), aOLO.buttonHoverStyle);
        return false;
    }
    return true;
}

export async function LoadOhpData(): Promise<void> {
    const data = await Common.LoadStoreData(aOLO.dataUrl);
    mapData(data);
}

export async function LoadData(): Promise<void> {
    try {
        DialogCreators.Progress(true, aOLO);
        let data = await Common.LoadStoreData(aOLO.dataUrl);
        if (data !== null) {
            DialogCreators.Progress(false, aOLO);
            mapData(data);
        } else {
            let interval = setInterval(async () => {
                data = await Common.LoadStoreData(aOLO.dataUrl);
                if (data !== null) {
                    mapData(data);
                    DialogCreators.Progress(false, aOLO);
                    clearInterval(interval);
                } else {
                    clearInterval(interval);
                    throw new Error();
                }
            }, 3000);
        }
    } catch (ex: unknown) {
        DialogCreators.Progress(false, aOLO);
        if (ex instanceof Error)
            Util.LogError(`LoadData -> LoadStoreData -> isMobile: ${localStorage.MobileView}`, ex, aOLO);
    }

    try {
        setTimeout(async () => {
            let calorieData = await LoadCalorieData();
            if (calorieData !== null) {
                mapCalorieData(calorieData);
            } else {
                let counter = 0;
                let maxRetries = 5;

                let interval = setInterval(async () => {
                    calorieData = await LoadCalorieData();
                    if (calorieData !== null) {
                        mapCalorieData(calorieData);
                        clearInterval(interval);
                    } else if (++counter >= maxRetries) {
                        clearInterval(interval);
                    }
                }, 10000);
            }
        }, 1000);
    } catch (ex: unknown) {
        if (ex instanceof Error)
            Util.LogError(`LoadData -> LoadCalorieData -> isMobile: ${localStorage.MobileView}`, ex, aOLO);
    }
}

async function LoadCalorieData(): Promise<null | any> {

    const myInit = Common.GetInit();
    const myRequest = new Request(aOLO.dataCalorieUrl);
    const response = await fetch(myRequest, myInit);
    if (response.status === 404)
        return null;
    else {
        try {
            return await response.json();
        } catch {
            return [];
        }
    }
}

export function mapData(data: any): void {
    try {
        let settings = data.SETS || {};
        settings.OPO = data.SETS.OPO.Values?.map((x: any) => ({
            OrderTypeID: x.OTID,
            PayInStore: x.PIS,
            PayInStoreLimit: x.PISL,
            PayInStoreAlternate: x.PISA,
            Cash: x.CSH,
            CashLimit: x.CSHL,
            CashAlternate: x.CSHA,
            CardOnFile: x.COF,
            CardOnFileCvvOption: x.COFC,
            CardOnFileCvvThreshold: x.COFCT,
            CreditCard: x.CC,
            CreditCardOption: x.CCO,
            CreditCardCvvOption: x.CCCO,
            CreditCardCvvThreshhold: x.CCCT,
            CreditCardAvsOption: x.CCAO,
            CreditCardAvsThreshhold: x.CCAT,
            CreditCardPreValidationOption: x.CCPVO,
            CreditCardPreValidationThreshhold: x.CCPVOT,
            GooglePay: x.GPAY,
            ApplePay: x.APAY
        })) || [];

        const newData: IData = {
            StoreInfo: {
                storeKey: data.SINFO.SKEY,
                streetNumber: data.SINFO.SNUM,
                address1: data.SINFO.ADD1,
                address2: data.SINFO.ADD2,
                address3: data.SINFO.ADD3,
                address4: data.SINFO.ADD4,
                address5: data.SINFO.ADD5,
                city: data.SINFO.CITY,
                state: data.SINFO.STA,
                zip: data.SINFO.ZIP,
                countryId: data.SINFO.CID,
                menuGroupId: data.SINFO.SMGID
            },
            DesktopBanner: {
                Banners: data.DBAN?.Data?.map((banner: any) => ({
                    CouponID: banner.LCID,
                    ImageURL: banner.IURL
                })) || [],
                RotationActive: data.DBAN?.RIA || false,
                RotationPeriod: data.DBAN?.RP || 1,
                SlideIdx: 1
            },
            Categories: data.CATS?.map((cat: any) => ({
                Default: cat.DEF,
                Descriptions: cat.DSCRs,
                Index: cat.IDX,
                ImageUrl: cat.IMGU,
                IsMealDeal: cat.ISMD,
                ItemWebCategoryId: cat.IWCID,
                Names: cat.NAMs,
                Upsale: cat.UPS
            })) || [],
            Challenges: data.CLGs?.map((clg: any) => ({
                ChallengeId: clg.ID,
                Names: clg.NAMs,
                StartDate: new Date(Util.formatDateFunc(clg.SDT)),
                EndDate: new Date(Util.formatDateFunc(clg.EDT)),
                Members: clg.MEMs?.map((mem: any) => ({
                    MemberId: mem.MID,
                    Name: mem.NAM,
                    Events: mem.EVTs?.map((evt: any) => ({
                        EventId: evt.EID,
                        EventName: evt.ENAM,
                        EventDate: new Date(Util.formatDateFunc(evt.EDATE)),
                        FundraisingPercentage: evt.FUND,
                        IsAllowCoupons: evt.IACPN,
                        IsSubMemberField: evt.ISMEM,
                        SubMemberTitle: evt.SMEMTL,
                        EntryTypeId: evt.ETID,
                        IsRequired: evt.IREQ,
                        IsMultipleSelectionsAllowed: evt.IMSALL,
                        SubMembers: evt.SMEMs?.map((smem: any) => ({
                            SubMemberId: smem.SMID,
                            SubMemberName: smem.SMNAM,
                            IsExtraField: smem.IEFLD,
                            ExtraFieldTitle: smem.EFLDTL,
                            EntryTypeId: smem.ETID,
                            IsRequired: smem.IREQ,
                            IsMultipleSelectionsAllowed: smem.IMSALL,
                            PredefinedValues: smem.PVALS
                        })) || []
                    })) || []
                })) || []
            })) || [],
            Channels: data.CHLS?.map((chl: any) => ({
                ChannelID: chl.CHID,
                ChannelName: chl.NAM,
                PromoCodePrefix: chl.PCP
            })) || [],
            Cities: data.CITIS || "",
            Countries: data.COUNTRIES?.map((country: any) => ({
                CountryID: country.CountryID,
                Name: country.Name,
                IsoCode: country.IsoCode,
                CountryCode: country.CountryCode,
                UnitOfMeasurement: country.UnitOfMeasurement,
                DefaultCurrencySymbol: country.DefaultCurrencySymbol,
                DefaultCultureCode: country.DefaultCultureCode
            })) || [],
            Coupons: data.CPNS?.map((cpn: any) => ({
                CouponId: cpn.CPID,
                CouponTypeId: cpn.CPTID,
                AllowOtherCoupons: cpn.AOC,
                AutoApplyExactMatch: cpn.AAEXCMCH,
                BackgroundColor: cpn.TBGC,
                BOGOSelectionTypeID: cpn.BGSTID,
                Descriptions: cpn.DSCRs,
                Discount: cpn.DIC,
                ExactMatch: cpn.EXCMCH,
                ImageURL: cpn.IURL,
                IsBeforeTax: cpn.IBTX,
                IsBOGO: cpn.BOGO,
                IsHiddenOnline: cpn.IHDN,
                IsPriceReducer: cpn.IPR,
                IsProfileRequired: cpn.IOPR,
                IsScheduled: cpn.SCH,
                IsVerificationNeeded: cpn.IVN,
                IsAutoApply: cpn.IAA,
                IsAppOnly: cpn.IAPPO,
                LimitsPerOrder: cpn.LPO,
                MaximumDiscountAmount: cpn.MXAMT,
                MinimumItemQuantity: cpn.MINITMQTY,
                MinimumOrderAmount: cpn.MIAMT,
                CalculationMethodID: cpn.CALCID,
                Names: cpn.NAMs,
                Priority: cpn.PRITY,
                WebCategoryId: cpn.WCID,
                StartDate: cpn.SDATE,
                EndDate: cpn.EDATE,
                Codes: cpn.CCODS?.map((cd: any) => ({
                    CouponCode: cd.CCOD
                })) || [],
                Items: cpn.CITMS?.map((itm: any) => ({
                    AddOnPrice: itm.AOPRC,
                    GroupIndex: itm.GRP,
                    ItemId: itm.IID,
                    ItemIndex: itm.IIDX,
                    ModifierCount: itm.MCNT,
                    Quantity: itm.QTY,
                    SizeId: itm.SZID
                })) || [],
                ItemCategories: cpn.CITMCATS?.map((cat: any) => ({
                    CategoryId: cat.CATID
                })) || [],
                OrderTypeSubTypes: cpn.OTSTS?.map((ot: any) => ({
                    OrderTypeId: ot.OT,
                    OrderTypeSubId: ot.OST
                })) || [],
                Schedules: cpn.SCHS?.map((sch: any) => ({
                    WeekDayNumber: sch.WKDNO,
                    IsActive: sch.ISACT,
                    StartDayMinute: sch.STRTMIN,
                    EndDayMinute: sch.ENDMIN
                })) || [],
                Upgrades: cpn.UGRDS?.map((upg: any) => ({
                    BuySizeId: upg.BSZID,
                    PriceSizeId: upg.PSZID,
                    UpgradeIndex: upg.UGRDIDX
                })) || []
            })) || [],
            DefaultPriceGroupId: data.DEFPGID,
            Donations: data.DON?.map((don: any) => ({
                Amount1: don.AMT1,
                Amount2: don.AMT2,
                Amount3: don.AMT3,
                Description: don.DES,
                DonationId: don.DID,
                ImageUrl: don.IMG,
                IsRounded: don.IRND,
                Names: don.NAM,
                RedirectURL: don.URL,
                Threshold: don.TH
            })) || [],
            Fundraisers: data.FUN?.map((fun: any) => ({
                FundraiserId: fun.FID,
                Name: fun.NAM,
                StartDateTime: fun.SDAT,
                EndDateTime: fun.EDAT,
                Code: fun.CDE,
                IsCouponAllowed: fun.ICPN,
                IsCustomField1: fun.ICF1,
                IsCustomField2: fun.ICF2,
                CustomField1Names: fun.CF1NAMs,
                CustomField2Names: fun.CF2NAMs
            })) || [],
            Holidays: data.HDAYS?.map((hol: any) => ({
                HolidayId: hol.HID,
                HolidayStatusId: hol.HSID,
                StartMinute: hol.SMIN,
                EndMinute: hol.EMIN,
                Info: hol.THRINFO ? JSON.parse(hol.THRINFO) : null
            })) || [],
            Hours: data.HUR?.map((hr: any) => ({
                OrderTakingStartDayMinute: hr.OTSDM,
                OrderTakingEndDayMinute: hr.OTEDM,
                OpenDayMinute: hr.ODM,
                CloseDayMinute: hr.CDM
            })) || [],
            Info: data.INFO,
            Instructions: data.INST?.map((com: any) => ({
                CommentId: com.COMMID,
                Items: com.ITM?.map((itm: any) => ({
                    ItemWebCategoryId: itm.ICID,
                    SizeId: itm.SID
                })) || [],
                Names: com.TEXT
            })) || [],
            Items: data.ITMS?.map((itm: any, index: number) => ({
                ItemId: itm.IID,
                Index: index,
                ItemCategoryId: itm.ICID,
                WebCategoryId: itm.IWCID,
                WebCategoryIndex: itm.WCIDX,
                SubCategoryId: itm.ISCID,
                SubCategoryIndex: itm.SCIDX,
                IsDefault: itm.IDEF,
                IsHalfable: itm.IHAF,
                CssClassName: itm.BCOL,
                ImageURL: itm.IMG,
                MinimumModifierCount: itm.MINMCNT,
                MaximumModifierCount: itm.MAXMCNT,
                PrepTime: itm.PTIM,
                MakeTime: itm.MTIM,
                CookTime: itm.CTIM,
                IsBuildYourOwn: itm.IBYO,
                IsGiftCard: itm.IGCD,
                IsGiftCert: itm.IGCT,
                IsLoyalty: itm.ILOY,
                IsCutWrap: itm.ISCR,
                IsMakeLine: itm.ISML,
                CalorieDisplayId: itm.CALDID,
                MinimumCustomerAge: itm.AGE,
                Names: itm.INAMS,
                Descriptions: itm.IDSCRS,
                ServingNames: itm.SNAMS,
                ModifierDisplayGroups: itm.MODGs?.map((mdg: any) => ({
                    ModifierDisplayGroupId: mdg.MDGRPID,
                    Index: mdg.IDX,
                    Modifiers: mdg.MODS?.map((mod: any) => ({
                        AddsToPrice: mod.ATPRC,
                        CountForPricing: mod.CFPRC,
                        CountForPricingByItself: mod.CFPRCBI,
                        IsDefault: mod.IDEF,
                        Index: mod.IDX,
                        IsHiddenOnOrder: mod.IHOORD,
                        ModifierId: mod.MID,
                        Name: mod.NAMS,
                        MergedModifierDisplayGroupId: mod.MMDGID,
                        ModifierWebCategoryId: mod.MWCID,
                        ModifierWebCategoryIndex: mod.MWCIDX,
                        PreModifierException: mod.XPMOD,
                        Calories: [],
                        Prices: mod.MPRCS?.map((prc: any) => ({
                            SizeId: prc.SZID,
                            AddOnPrice: prc.APRC
                        })) || []
                    })) || []
                })) || [],
                OrderTypes: itm.OTSS?.map((ot: any) => ({
                    CutWrap: ot.CWP,
                    Label: ot.LBL,
                    Make: ot.MAK,
                    OrderTypeId: ot.OTID,
                    Prep: ot.PRP,
                    Print: ot.PRT,
                    Sizes: ot.SIZS?.map((sz: any) => ({
                        IsDefault: sz.IDEF,
                        Index: sz.IDX,
                        IsHalfable: sz.IHLF,
                        MinimumCalories: sz.MICALS,
                        IsOfferedQR: sz.QR,
                        SizeId: sz.SZID
                    })) || [],
                })) || [],
                SizeCalories: itm.SIZCALS?.map((scal: any) => ({
                    MaximumCalories: scal.MXCALS,
                    MinimumCalories: scal.MICALS,
                    Servings: scal.SRVS,
                    SizeId: scal.SZID
                })) || [],
                Prices: itm.PRCS?.map((prc: any) => ({
                    MinimumPrice: prc.MIPRC,
                    MaximumPrice: prc.MXPRC,
                    PriceGroupId: prc.PGID,
                    PerModifierPrice: prc.PMPRC,
                    TaxIncluded: prc.TXIC,
                    Price: prc.PRC,
                    SizeId: prc.SZID
                })) || [],
                Taxes: itm.TAXS?.map((tax: any) => ({
                    TaxId: tax.TID,
                    Index: tax.IDX
                })) || []
            })) || [],
            Loyalty: {
                ProviderId: data.LOYINTG2?.ID || 0,
                Connection: data.LOYINTG2?.CON || null,
                ProgramTypes: data.LOYINTG2?.PTYPEs?.map((loy: any) => ({
                    ProgramTypeId: loy.PTID
                })) || []
            },
            LoyaltyItems: data.LOYITMS?.map((itm: any) => ({
                ItemId: itm.IID,
                SizeId: itm.SID,
                Points: itm.PON,
                IsChargeable: itm.ICHR
            })) || [],
            MembershipTiers: data.MEMs?.map((mem: any) => ({
                MembershipId: mem.ID,
                LifetimePoints: mem.LPTS,
                Names: mem.NAMs,
                Rewards: mem.RIDs?.map((rew: any) => ({
                    MembershipId: rew.ID,
                    RewardId: rew.RID
                })) || [],
            })) || [],
            ModifierCategories: data.MODCATS?.map((mcat: any) => ({
                ModifierWebCategoryId: mcat.MWCID,
                Descriptions: mcat.DSCRs,
                Index: mcat.IDX,
                Names: mcat.NAMs
            })) || [],
            ModifierDisplayGroups: data.MDGS?.map((mdg: any) => ({
                ModifierDisplayGroupId: mdg.MDGRPID,
                IsWeightChangeable: mdg.IWCHNG,
                IsHalfable: mdg.IHAF,
                IsVisible: mdg.ISVSB,
                MaxSelectionCount: mdg.MAXCNT,
                MinSelectionCount: mdg.MINCNT,
                MustToggle: mdg.MTGL,
                Names: mdg.NAMs
            })) || [],
            Modifiers: data.MODS?.map((mod: any) => ({
                ModifierId: mod.modID,
                Descriptions: mod.DSCRs,
                ImageUrl: mod.IMGU,
                Index: mod.IDX,
                IsCrust: mod.ICRST,
                ModifierWebCategoryId: mod.MWCID,
                Names: mod.NAMs
            })) || [],
            OrderTypeSubTypes: data.OTSTS?.map((otst: any) => ({
                OrderTypeId: otst.OTID,
                OrderTypeSubId: otst.OSTID,
                PriceGroupId: otst.PGID,
                Alias: otst.ALI,
                AskCustomerInfo: otst.ACINF,
                AskCustomerAddress: otst.ACADR,
                DefaultPay: otst.DPAY,
                EndMinute: otst.EMIN,
                FutureOrders: otst.FUO,
                MinAmount: otst.MAMT,
                Names: otst.TNAMs,
                OrderTypeCharge: otst.OTCH,
                OrderTypeChargeTaxId: otst.TXID,
                PausedUntil: otst.PT,
                Rate: otst.OTTXR,
                StartMinute: otst.SMIN,
                TaxTypeId: otst.TTID,
                TaxName: otst.TXNAM,
                WaitTime: otst.WT
            })) || [],
            PreModifiers: data.PMODS?.map((pmod: any) => ({
                PreModifierId: pmod.PMID,
                Descriptions: pmod.DSCRs,
                InventoryMultiplier: pmod.ICMUL,
                IsDefault: pmod.IDEF,
                IsNone: pmod.INON,
                IsHidden: pmod.IHID,
                IsDeleted: pmod.IDLT,
                Names: pmod.NAMs,
                PriceCountMultiplier: pmod.PCMUL
            })) || [],
            Rewards: data.REWs?.map((rew: any) => ({
                RewardId: rew.ID,
                Points: rew.PTS,
                IsTierBased: rew.ITB,
                IsTierUpgrade: rew.ITU,
                FrequencyId: rew.FID,
                Names: rew.NAMs
            })) || [],
            StoreBusinessDate: data.SBDAT,
            Settings: settings || {},
            Sizes: data.SZS?.map((size: any) => ({
                SizeId: size.SZID,
                Descriptions: size.DSCRs,
                Index: size.IDX,
                Names: size.NAMs
            })) || [],
            ServiceCharges: data.SRVCHRG?.map((srvchg: any) => ({
                ServiceChargeId: srvchg.SCHID,
                FixAmount: srvchg.FIXAMT,
                IncludesDeliveryCharge: srvchg.INCDC,
                IncludesSubtotal: srvchg.INCST,
                IncludesTax: srvchg.INCTX,
                MaxAmount: srvchg.MAXAMT,
                MaxCharge: srvchg.MAXCHRG,
                MinAmount: srvchg.MINAMT,
                MinCharge: srvchg.MINCHRG,
                Names: srvchg.NAM,
                Percentage: srvchg.PERCNT,
                TaxId: srvchg.TAXID,
                TaxName: srvchg.TAXNAM,
                TaxRate: srvchg.TAXRAT,
                TaxTypeId: srvchg.TAXTID,
                OrderTypeSubTypes: srvchg.OTSTS?.map((ot: any) => ({
                    OrderTypeId: ot.OTID,
                    OrderTypeSubId: ot.OTSID,
                })) || []
            })) || [],
            Stores: data.STRS?.map((str: any) => ({
                StoreId: str.SID,
                Color: str.COL,
                Name: str.NAM,
                SortingIndex: str.SNO,
                Nickname: str.NNAM,
                Address1: str.ADD1,
                City: str.CT,
                State: str.ST,
                ZipCode: str.ZP,
                Latitude: str.LT,
                Longitude: str.LG,
                Heartland: str.HPK,
                Items: str.AITM,
                Distance: 0,
                Holidays: str.HDAYS,
                Taxes: str.TAX?.map((tax: any) => ({
                    FixedAmount: tax.FAMT,
                    Rate: tax.RAT,
                    SizeId: tax.SZID,
                    TaxId: tax.TID
                })) || [],
                WaitTimes: str.WTS?.map((wt: any) => ({
                    FutureOrders: true,
                    OrderTypeId: wt.OTID,
                    WaitTime: wt.WT
                })) || []
            })) || [],
            SubCategories: data.SUBCATS?.map((scat: any) => ({
                ItemSubCategoryId: scat.SCID,
                Descriptions: scat.DSCRs,
                Index: scat.IDX,
                Names: scat.NAMs
            })) || [],
            Taxes: data.TAXES?.map((tax: any) => ({
                TaxId: tax.TID,
                FixAmount: tax.FAMT,
                OrderTypesExcluded: JSON.parse(tax.OTEXCs) || [],
                IsPercentageBase: tax.IPBAS,
                IsFixedAmountBase: tax.IFAMT,
                IsSizeBase: tax.ISZBAS,
                IsAddToSalesAmount: tax.IATSAMT,
                IsAffectedByDiscounts: tax.IEFFDIS,
                IsPerOrder: tax.IPORD,
                IsNextToItemIndividually: tax.DNTITM,
                Names: tax.NAMs,
                Rate: tax.TRT,
                SizeId: tax.SZID,
                TaxTypeId: tax.TTID
            })) || [],
            ZipCodes: data.ZIPS || "",
            DeliveryZones: data.ZONS?.map((zn: any) => ({
                ZoneId: zn.ZID,
                IsLimitedTime: zn.ILT,
                StoreId: zn.SID,
                ZoneCharge: zn.ZCHRG,
                ZoneMinDeliveryAmount: zn.MINDEL,
                ZoneTypeId: zn.ZTID,
                Polygons: zn.PGON?.map((pol: any) => ({
                    Latitude: pol.Lt,
                    Longitude: pol.Lg
                })) || [],
                LimitedTimes: zn.LIMTIME?.map((tm: any) => ({
                    WeekDay: tm.wDay,
                    StartMinute: tm.sMin,
                    EndMinute: tm.eMin
                })) || []
            })) || [],
            ZipTaxes: data.ZTAXES?.map((ztax: any) => ({
                TaxId: ztax.TID,
                FixAmount: ztax.FAMT,
                Rate: ztax.RT,
                SizeId: ztax.SZID,
                StoreId: ztax.SID,
                ZipCode: ztax.ZCD
            })) || [],
            OpenPayMerchant: data.OPMCH,
            MerchantPids: data.MPIDS,
            OPDevSId: "",
            OPSourceId: ""
        };

        aOLO.data = newData;
    }
    catch (error) {
        console.error("Error occurred in mapData:", error);
    }
}

function mapCalorieData(data: any[]): void {
    if (data.length == 0)
        return;

    // Create a lookup table
    const lookup = data.reduce((acc: any, cur: any) => {
        if (cur.CALS) {
            const key = `${cur.IID}-${cur.MID}`;
            acc[key] = cur.CALS.map((cal: any) => ({
                Calories: cal.CALS,
                ModifierCount: cal.MCNT,
                PreModifierId: cal.PMID,
                SizeId: cal.SID
            })) || [];
        }
        return acc;
    }, {});

    // Iterate over items
    for (const item of aOLO.data.Items) {
        for (const mdg of item.ModifierDisplayGroups) {
            for (const mod of mdg.Modifiers) {
                const key = `${item.ItemId}-${mod.ModifierId}`;
                if (lookup[key])
                    mod.Calories.push(...lookup[key]);
            }
        }
    }
}

export async function startApp(): Promise<void> {
    setDocHeight();

    if (!aOLO.data || !aOLO.data.OrderTypeSubTypes || aOLO.data.OrderTypeSubTypes.length === 0) {
        DialogCreators.messageBoxOk(Names("OLO_General_Error"), aOLO.buttonHoverStyle);
        return;
    }

    globalThis.aOLOModules = {
        LoyaltyProvider: LoyaltyProviderFactory.getLoyaltyProvider(aOLO),
        CustomerProvider: CustomerProviderFactory.getCustomerProvider(aOLO)
    };

    new LanguageBar();

    await getServerTime();


    loadOrderTypes(aOLO.data.OrderTypeSubTypes);
    initiateDefaults();
    initiateOrder();

    Util.showElement("div_cart_container");

    if (!Util.isAppView()) {
        await getFooterAsync();
        displayFooter();
    }

    await setOrderType(aOLO.Temp.OrderTypeID);
    //initializeGoogleMap();

    aOLO.Temp.CheckOrderTimeIntervalIsPaused = false;
    aOLO.Temp.CheckOrderTimeInterval = window.setInterval(async function () {
        if (!aOLO.Temp.CheckOrderTimeIntervalIsPaused)
            await CheckOrderTime(getOrderTypeFunctions);
    }, 10000);

    await initializeGUIElementsAsync();


    await setStartupDialogs();
}

function displayFooter(): void {
    const footer = document.getElementsByTagName("footer");
    if (footer.length > 0)
        Util.showElement(footer[0]);
}

async function getFooterAsync(): Promise<void> {
    try {
        const data = await getFooterHtmlAsync(aOLO.footerUrl);
        if (data !== null) {
            const footerIncludeDiv = document.getElementById('div_footer_include');
            if (footerIncludeDiv && data) {
                footerIncludeDiv.innerHTML = data;

                // Manually execute the script
                const scripts = footerIncludeDiv.getElementsByTagName('script');
                for (const script of scripts) {
                    const newScript = document.createElement('script');
                    newScript.textContent = script.textContent;
                    document.body.appendChild(newScript);
                }
            }
        }
    } catch { }
}

async function getFooterHtmlAsync(footerUrl: string): Promise<string | null> {
    try {
        const myInit = Common.GetInit();
        const myRequest = new Request(footerUrl);
        const response = await fetch(myRequest, myInit);
        if (response.ok)
            return await response.text();
        return null
    } catch {
        return null;
    }
}

export async function initializeGUIElementsAsync(): Promise<void> {
    const logout = Common.logout;
    new VerticalMenu({
        logout,
        changePage: null,
        brandDisplayPage: null,
        GUI_SetOrder_Customer,
        ReOrder,
        setStartupDialogs,
        signUpClick,
        startOrderTypePage,
        startApp
    });

    window.setTimeout(() => {
        try {
            //@ts-ignore
            if (dataLayer) {
                aOLO.Modules.DataLayer = new DataLayerService();
                aOLO.Modules.DataLayer.itemScrollListeners();
            }
        } catch { }
    }, 1000);

    const eventBus = createEventBus();

    if (Util.isAppView()) {
        const navbar = new NavigationBar(eventBus);
        navbar.init();
    }

    await Util.getDesktopLocationAsync();

    const router = new Router(eventBus);
    router.init();

    const profileService = new ProfileService(aOLO.LoyaltyApiUrl)
    const loyaltyProvider = LoyaltyProviderFactory.getLoyaltyProvider(aOLO);
    const customerProvider = CustomerProviderFactory.getCustomerProvider(aOLO);

    const user = new User(profileService, loyaltyProvider, aOLO);
    const data = new Data(eventBus, aOLO.PublicStorageUrl, aOLO);
    const order = new Order(eventBus, data, aOLO);

    const loginManager = new LoginManager(eventBus, loyaltyProvider, user, aOLO);

    const header = new Header(eventBus, customerProvider, loginManager, user, aOLO);
    header.init();

    const landingPage = new Landing(eventBus, profileService, user, aOLO);
    await landingPage.initAsync();

    const locationsPage = new Locations(eventBus, loyaltyProvider, user, order, aOLO);
    
    const menuPage = new Menu(eventBus, data, user, order, aOLO);

    // Define routes for each page
    router.addRoute(Pages.LANDING, async () => { await landingPage.initAsync(); });
    router.addRoute(Pages.LOCATIONS, async (data) => { await locationsPage.initAsync(data); });
    router.addRoute(Pages.MENU, async () => { await menuPage.initAsync(); });



    //const landing = new Landing(eventBus, aOLO);
    //await landing.initAsync();


    await checkUserSignedIn(eventBus, loyaltyProvider, user);
    Common.initiateUser(user);
    Common.setHamburgerMenuItems(user, aOLO);



    aOLO.Modules.Categories = new Categories(aOLO.data.Categories, aOLO);
    aOLO.Modules.OrderCart = new OrderCart(getOrderCartFunctions());
    GUI_LoadItems();
    GUI_LoadCoupons(aOLO.data.Coupons);
    OnlineOrderingUtil.GUI_SetOrder_Total(true, aOLO, aOLOModules);

    GUI_LoadDesktopBanner(aOLO.data.DesktopBanner);

    // Initial load
    //router.navigate(window.location.pathname);

    addEventListeners(user);
}

function setDocHeight() {
    document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
};

function addEventListeners(user: User): void {
    window.onbeforeunload = function () {
        if (!aOLO.isOKNavAway)
            return "Changes you made will not be saved.";
    };

    const sidebar = document.getElementById('div_cart_float') as HTMLElement;
    const content = document.getElementById('div_items_order') as HTMLElement;

    let topSpace: number = aOLO.Temp.HeaderHeight + 10;

    if (aOLO.layoutStyleID === 2 || aOLO.layoutStyleID === 3) {
        topSpace += 40;
    }
    //@ts-ignore
    aOLO.floatSidebar = FloatSidebar({
        sidebar: sidebar,
        relative: content,
        topSpacing: topSpace,
        bottomSpacing: 20
    });

    window.addEventListener('keydown', function (event) {
        if (event.key === "Escape") {
            const messageBoxIds = Array.from(document.querySelectorAll(`[id^="dia_msgbox_"]`)).map(x => x.id);

            const noEscapeDialogs = ["dia_signin", "dia_order_type", ...messageBoxIds];
            const isDialogOpen = noEscapeDialogs.some(dialogId => {
                const dialogElement = document.getElementById(dialogId);
                return dialogElement !== null && dialogElement.hasAttribute("open");
            });

            if (isDialogOpen)
                event.preventDefault();
        }
    }, true);
    window.addEventListener('resize', setDocHeight);
    window.addEventListener('orientationchange', setDocHeight);

    //window.onscroll = WindowsScrolled;

    window.addEventListener('keydown', (event) => {
        if (event.key === 'Escape')
            event.preventDefault();
    });

    setInterval(function () {
        if (user.isLoggedIn())
            aOLO.Modules.ProfileService.refreshToken(aOLO);
    }, 90 * 60 * 1000);

    const mobileCart = document.getElementById("btn_header_cart");
    if (mobileCart)
        mobileCart.onclick = () => { OnlineOrderingUtil.ToggleOrderItems(false, aOLO); };

    window.addEventListener('resize', updateBannerSize);
}

//function WindowsScrolled(): void {
//    if (aOLO.Temp.Mobile_Card)
//        return;

//    const categories = Array.from(document.querySelectorAll('*[id^="h1CatAnchor_"]')) as HTMLElement[];
//    const windowHeight = window.innerHeight;

//    let activeCatID = "";
//    let activeCatName = "";

//    for (const category of categories) {
//        if (!category)
//            continue;

//        const catid = category.dataset.catid;
//        if (OnlineOrderingUtil.getElementTop(category, "") > windowHeight * 0.60)
//            break;

//        activeCatID = catid || "";
//        activeCatName = category.innerText;
//    }

//    if (activeCatID === "")
//        return;

//    Util.setElement("innerText", "div_categories_arrow", activeCatName);

//    for (const category of categories) {
//        const catLink = document.getElementById(`a_category_${category.dataset.catid}`);
//        if (catLink)
//            catLink.classList.remove("active-category");
//    }

//    const catLink = document.getElementById(`a_category_${activeCatID}`);
//    if (catLink)
//        catLink.classList.add("active-category");
//}

async function getServerTime(): Promise<void> {
    let result = await aOLO.Modules.OnlineOrderingService.getServerTime(aOLO.storeInfo.StoreKey, aOLO);
    if (result)
        timeOffsetLoad(result);
    else
        DialogCreators.messageBoxOk(Names("ErrorGettingServerDate"), aOLO.buttonHoverStyle);
}

function timeOffsetLoad(response: { utcMilliseconds: number, storeUtcOffset: number }): void {
    setTimeOffset(response.utcMilliseconds + 200, response.storeUtcOffset);
}

function loadOrderTypes(orderTypes: IDataOrderTypeSubType[]): void {
    let hasDelivery = false;
    let hasTakeOut = false;
    let hasDineIn = false;

    let waitTimeDelivery = 0;
    let waitTimeTakeOut = 0;
    let waitTimeDineIn = 0;

    const tMinutes = Util.nowTodayMinute(aOLO.Temp.TimeOffset);

    for (const ot of orderTypes) {
        const waitTime = OnlineOrderingUtil.getWaitTimeByOT(ot, aOLO);
        Util.setElementClass("add", `div_order_type_${ot.OrderTypeId}`, `orderTypeRadioWidth${orderTypes.length}`);
        Util.showElement(`div_order_type_${ot.OrderTypeId}`);
        const orderTypeNameDiv = document.getElementById(`span_order_type_${ot.OrderTypeId}_name`);
        if (orderTypeNameDiv) {
            orderTypeNameDiv.innerHTML = Common.GetName(ot.Names, aOLO.Temp.languageCode);
            orderTypeNameDiv.setAttribute("ltagj", Util.toLtagj(ot.Names));
        }

        if (!aOLO.data.Settings.ISCC)
            Util.setElement("innerHTML", `span_order_type_${ot.OrderTypeId}_wait`, `${waitTime} <span ltag="Minutes">${Names("Minutes")}</span>`);
        else
            Util.setElement("innerHTML", `span_order_type_${ot.OrderTypeId}_wait`, "");

        if (ot.OrderTypeId === OrderType.DELIVERY) {
            if (tMinutes >= ot.StartMinute && tMinutes <= ot.EndMinute) {
                hasDelivery = true;
                waitTimeDelivery = waitTime;
            } else {
                Util.setElement("innerHTML", `span_order_type_${ot.OrderTypeId}_wait`, Util.formatMinutesToTime(ot.StartMinute) + " - " + Util.formatMinutesToTime(ot.EndMinute));
                Util.setElement("innerHTML", `span_order_type_${ot.OrderTypeId}_name`, Common.GetName(ot.Names, aOLO.Temp.languageCode) + " - " + Names("LIMITHOUS"));
            }
        }

        if (ot.OrderTypeId === OrderType.TAKE_OUT) {
            if (tMinutes >= ot.StartMinute && tMinutes <= ot.EndMinute) {
                hasTakeOut = true;
                waitTimeTakeOut = waitTime;
            } else {
                Util.setElement("innerHTML", `span_order_type_${ot.OrderTypeId}_wait`, Util.formatMinutesToTime(ot.StartMinute) + " - " + Util.formatMinutesToTime(ot.EndMinute));
                Util.setElement("innerHTML", `span_order_type_${ot.OrderTypeId}_name`, Common.GetName(ot.Names, aOLO.Temp.languageCode) + " - " + Names("LIMITHOUS"));
            }
        }

        if (ot.OrderTypeId === OrderType.DINE_IN) {
            if (tMinutes >= ot.StartMinute && tMinutes <= ot.EndMinute) {
                hasDineIn = true;
                waitTimeDineIn = waitTime;
            } else {
                Util.setElement("innerHTML", `span_order_type_${ot.OrderTypeId}_wait`, Util.formatMinutesToTime(ot.StartMinute) + " - " + Util.formatMinutesToTime(ot.EndMinute));
                Util.setElement("innerHTML", `span_order_type_${ot.OrderTypeId}_name`, Common.GetName(ot.Names, aOLO.Temp.languageCode) + " - " + Names("LIMITHOUS"));
            }
        }
    }

    if (aOLO.Order) {
        aOLO.Temp.OrderTypeID = aOLO.Order.OrderTypeID;
        switch (aOLO.Order.OrderTypeID) {
            case OrderType.DELIVERY:
                aOLO.Temp.WaitTime = waitTimeDelivery;
                Util.setElement("checked", "rdo_order_type_delivery", true);
                break;
            case OrderType.TAKE_OUT:
                aOLO.Temp.WaitTime = waitTimeTakeOut;
                Util.setElement("checked", "rdo_order_type_take_out", true);
                break;
            case OrderType.DINE_IN:
                aOLO.Temp.WaitTime = waitTimeDineIn;
                Util.setElement("checked", "rdo_order_type_dine_in", true);
                break;
        }
    } else {
        if (hasDelivery) {
            aOLO.Temp.OrderTypeID = OrderType.DELIVERY;
            aOLO.Temp.WaitTime = waitTimeDelivery;
            Util.setElement("checked", "rdo_order_type_delivery", true);
        } else if (hasTakeOut) {
            aOLO.Temp.OrderTypeID = OrderType.TAKE_OUT;
            aOLO.Temp.WaitTime = waitTimeTakeOut;
            Util.setElement("checked", "rdo_order_type_take_out", true);
        } else if (hasDineIn) {
            aOLO.Temp.OrderTypeID = OrderType.DINE_IN;
            aOLO.Temp.WaitTime = waitTimeDineIn;
            Util.setElement("checked", "rdo_order_type_dine_in", true);
        }
    }
}


function initiateDefaults(): void {
    if (aOLO.data.PreModifiers.length === 0) {
        DialogCreators.messageBoxOk(Names("PreModsNotSet"), aOLO.buttonHoverStyle);
        return;
    }

    aOLO.data.PreModifiers.forEach(preMod => {
        if (preMod.IsDefault)
            aOLO.Temp.DefPreModID = preMod.PreModifierId;
        else if (preMod.IsNone)
            aOLO.Temp.NonPreModID = preMod.PreModifierId;
    });
}

function initiateOrder(): void {
    aOLO.Order = {
        AddressID: 0,
        Address: null,
        AmountDue: 0,
        BUSDate: null,
        CollectedTax: 0.0,
        Coupons: [],
        Discount: 0.0,
        Distance: 0,
        Donations: { Detail: [] },
        FutureDate: null,
        FutureMinute: -1,
        Fundraiser: null,
        FundraiserID: 0,
        GUID: getNewOrderGUID(aOLO.storeInfo.StoreX, aOLO.data.Settings.STID.toString(16)),
        //GUID: aOLO.orderGuid,
        IsTaxExempt: false,
        Items: [],
        ItemsCoupons: [],
        ItemsMakeList: [],
        Key: getNewOrderKey(),
        OrderID: 0,
        OrderNo: 0,
        OrderTypeCharge: 0.0,
        OrderTypeSubID: 7, // online
        OrderTypeID: 0,
        OrderStartTime: new Date(),
        OrderEndTime: null,
        OrderTypeSubType: {
            OrderTypeId: 0,
            OrderTypeSubId: 0,
            PriceGroupId: 0,
            Alias: "",
            AskCustomerInfo: false,
            AskCustomerAddress: false,
            DefaultPay: 0,
            EndMinute: 0,
            FutureOrders: false,
            MinAmount: 0,
            Names: [],
            OrderTypeCharge: 0,
            OrderTypeChargeTaxId: 0,
            PausedUntil: null,
            Rate: 0,
            StartMinute: 0,
            TaxTypeId: 0,
            TaxNames: [],
            WaitTime: 0
        },
        Payments: [],
        PrintTime: null,
        PromiseTime: null,
        ServiceCharges: {
            Amount: 0.00,
            Detail: [],
            Tax: 0.0
        },
        StoreID: aOLO.storeInfo.StoreID,
        SubTotal: 0.0,
        Tax: 0.0,
        Taxes: [],
        TaxesDetail: [],
        Tip: 0.0,
        Total: 0.0,
        TotalDonation: 0.0,
        TaxInSubTotal: 0.0

    };
}

function getNewOrderKey(): string {
    try {
        let rnd = "7";
        rnd = rnd + Math.random().toString().replace(".", "");
        rnd = Util.Right(rnd, 1, "6");
        return Util.Right(aOLO.data.Settings.STID.toString().trim(), 7, "0") + rnd + Util.Right((parseInt((new Date().getTime() / 10).toString()).toString()), 11, "0");
    } catch (ex: unknown) {
        if (ex instanceof Error)
            Util.LogError("GetNewOrderKey", ex, aOLO);
    }
    return "";
}

function getNewOrderGUID(storeX: string, registerX: string): string {
    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
    let s8 = (storeX + S4() + S4()).substring(0, 8);
    let r4 = (registerX + S4()).substring(0, 4);
    return (s8 + "-" + S4() + "-4" + S4().substring(0, 3) + "-" + r4 + "-" + S4() + S4() + S4()).toLowerCase();
}

export async function setOrderType(orderTypeID: number, force: boolean = false): Promise<void> {
    if ((aOLO.Order.OrderTypeID === orderTypeID || !checkOrderItemsForNewOrderType(orderTypeID)) && !force)
        return;

    let otst = OnlineOrderingUtil.getOrderType(orderTypeID, aOLO);
    if (!otst)
        return;

    aOLO.Order.OrderTypeSubType = otst;
    aOLO.Temp.WaitTime = OnlineOrderingUtil.getWaitTimeByOT(otst, aOLO);
    if (aOLO.data.Settings.ISCC && aOLO.Temp.SelectedStoreID > 0) {
        let store = OnlineOrderingUtil.getCallCenterStore(aOLO.Temp.SelectedStoreID, aOLO.data.Stores);
        if (store)
            aOLO.Temp.WaitTime = OnlineOrderingUtil.getStoreWaitTime(store, orderTypeID, aOLO.Temp.TimeOffset);
    }
    aOLO.Order.OrderTypeID = orderTypeID;
    await recalculateOrderPrices();
    OnlineOrderingUtil.setOrderTypeCharge(aOLO, aOLOModules);

    if (aOLO.Order.OrderTypeID === OrderType.DELIVERY && aOLO.data.Settings.ISCC)
        await selectStoreForCheckedAddress();

    if (aOLO.Dialog.OrderType)
        aOLO.Dialog.OrderType.handleOrderTypeOrderTimeChange(true, false, true);

    initializeOrderTypeGUI();
}

export function getOrderTypeFunctions(): IOrderTypeFunctions {
    const setOrderTypeCharge = OnlineOrderingUtil.setOrderTypeCharge;
    const GUI_SetOrder = OnlineOrderingUtil.GUI_SetOrder;
    return {
        GUI_SetOrder,
        ReOrder,
        SetOrderType_Pre,
        selectStoreForCheckedAddress,
        setOrderTypeCharge,
        setStoreOrderInfo,
        GUI_LoadItems
    };
}

function getOrderCartFunctions(): IOrderCartFunctions {
    const setOrderTypeCharge = OnlineOrderingUtil.setOrderTypeCharge;
    const GUI_SetOrder = OnlineOrderingUtil.GUI_SetOrder;
    return {
        CheckOut,
        GUI_SetOrder,
        ReOrder,
        SetOrderType_Pre,
        selectStoreForCheckedAddress,
        setOrderTypeCharge,
        setStoreOrderInfo,
        resetTimeFulfillmentTimeExpired,
        GUI_LoadItems
    };
}

export async function resetTimeFulfillmentTimeExpired(): Promise<void> {
    DialogCreators.messageBoxOk(Names("FulfillmentTime").replace("???", OnlineOrderingUtil.GetOrderTypeName(aOLO.Order.OrderTypeID, aOLO)).replace("??", aOLO.Order.OrderTypeSubType.WaitTime.toString()), aOLO.buttonHoverStyle);
    aOLO.Order.FutureDate = null;
    aOLO.Order.FutureMinute = -1;
    aOLO.Order.PrintTime = Util.formatDateTimeSeconds(Util.NowStore(aOLO.Temp.TimeOffset));
    aOLO.Order.PromiseTime = Util.formatDateTimeSeconds(Util.DateAdd(Util.NowStore(aOLO.Temp.TimeOffset), aOLO.Order.OrderTypeSubType.WaitTime, "m"));
    if (aOLO.Dialog.OrderType)
        aOLO.Dialog.OrderType.handleOrderTypeOrderTimeChange(false, false);
    OnlineOrderingUtil.GUI_SetOrder(aOLO, aOLOModules);
}

export function CheckOut(): void {
    if (aOLO.Order.Items.length == 0) {
        DialogCreators.messageBoxOk(Names("NoItemAdded"), aOLO.buttonHoverStyle);
        return;
    }

    aOLO.Dialog.Checkout = new Checkout(OrderResult);
}

export async function setStoreOrderInfo(storeId: number, orderTypeId: number): Promise<void> {
    let store = OnlineOrderingUtil.getCallCenterStore(storeId, aOLO.data.Stores);
    if (!store) {
        if (aOLO.Dialog.CallCenterStores)
            aOLO.Dialog.CallCenterStores.closeDialog();
        return;
    }

    aOLO.Order.StoreID = storeId;

    aOLO.Modules.Categories = new Categories(aOLO.data.Categories, aOLO);
    aOLO.Modules.OrderCart = new OrderCart(getOrderCartFunctions());

    GUI_LoadItems();

    await setOrderType(orderTypeId);

    aOLO.Temp.WaitTime = OnlineOrderingUtil.getStoreWaitTime(store, orderTypeId, aOLO.Temp.TimeOffset);

    if (orderTypeId !== OrderType.DELIVERY)
        delete aOLO.Temp.Address;

    if (aOLO.Dialog.OrderType) {
        aOLO.Dialog.OrderType.handleOrderTypeOrderTimeChange(false, false, true);
        aOLO.Dialog.OrderType.setStoreWaitTimes(store);
        aOLO.Dialog.OrderType.setOrderTypeTime();
        aOLO.Dialog.OrderType.setStoreInfo();
    }

    GUI_UpdateHeaderStoreAddress();
}

async function recalculateOrderPrices(): Promise<void> {
    for (let i = 0; i < aOLO.Order.Items.length; i++) {
        let oItem = aOLO.Order.Items[i];
        if (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 1)) {
            OnlineOrderingUtil.PriceItem(oItem, aOLO);
        }
    }
    let removedCouponIDs = await aOLO.Modules.Coupon.RemoveCouponAll();
    for (const removedCoupon of removedCouponIDs) {
        const params: IApplyCouponByIdParams = {
            couponId: removedCoupon.couponId,
            showErrorMsg: true
        };
        await aOLO.Modules.Coupon.ApplyCouponByID(params);
    }
}

function checkOrderItemsForNewOrderType(orderTypeID: number): boolean {
    for (const oItem of aOLO.Order.Items) {
        const mItem = aOLO.data.Items.find(x => x.ItemId === oItem.ItemId);
        if (mItem && (!CheckItemOrderType(mItem, orderTypeID) || !OnlineOrderingUtil.ItemHasSize(mItem, orderTypeID, oItem.SizeId))) {
            let msg = Names("ChgOrdTypItmErrMsg");
            const iSize = OnlineOrderingUtil.GetItemSize(mItem, aOLO.Order.OrderTypeID, oItem.SizeId);
            const sizeName = iSize ? Common.GetName(aOLO.data.Sizes[iSize.Index].Names, aOLO.Temp.languageCode) : "";
            const itemName = Common.GetName(mItem.Names, aOLO.Temp.languageCode);
            msg = msg.replace("????", OnlineOrderingUtil.GetOrderTypeName(orderTypeID, aOLO));
            msg = msg.replace("???", sizeName);
            msg = msg.replace("???", sizeName);
            msg = msg.replace("??", itemName);
            msg = msg.replace("??", itemName);
            DialogCreators.messageBoxOk(msg, aOLO.buttonHoverStyle);
            Util.setElement("checked", `rdoOrderType_${OnlineOrderingUtil.GetOrderTypeName(orderTypeID, aOLO).replace(" ", "")}`, false);
            Util.setElement("checked", `rdoOrderType_${OnlineOrderingUtil.GetOrderTypeName(aOLO.Order.OrderTypeID, aOLO).replace(" ", "")}`, true);
            aOLO.Temp.OrderTypeID = aOLO.Order.OrderTypeID;
            if (aOLO.Dialog.OrderType)
                aOLO.Dialog.OrderType.closeDialog();
            return false;
        }
    }
    return true;
}

function CheckItemOrderType(mItem: IDataItem, orderTypeID: number): boolean {
    const orderType = mItem.OrderTypes.find(ot => ot.OrderTypeId === orderTypeID) || null;
    return (orderType != null);
}




export function GetItemCart(mItem: IDataItem): HTMLElement {
    switch (aOLO.itemStyleID) {
        case ItemStyle.VERTICAL_SIZE_BTN:
            return ItemStyle_1(mItem); // Toppers style
        case ItemStyle.VERTICAL_OPTIONS:
            return ItemStyle_2(mItem); // MMP Style
        case ItemStyle.HORIZONTAL_IMAGE_LEFT:
        case ItemStyle.HORIZONTAL_IMAGE_RIGHT:
            return ItemStyle_3_4(mItem); //Horizontal
    }
    return document.createElement("div");
}

function ItemStyle_1(mItem: IDataItem): HTMLElement {
    // toppers style
    const itemName = Common.GetName(mItem.Names, aOLO.Temp.languageCode).trim();
    const itemDesc = Common.GetDescription(mItem.Descriptions, aOLO.Temp.languageCode).trim();

    let htmlCont = "";
    if (OnlineOrderingUtil.MustCustomize(mItem, aOLO.data.ModifierDisplayGroups, aOLO.itemStyleID)) {
        if (IsMultiSizeItem(mItem, aOLO.Order.OrderTypeID))
            htmlCont = CreateItemSizeButtons(mItem, aOLO.Order.OrderTypeID).outerHTML;
        else {
            const sizeId = GetSingleSizeItemSizeID(mItem, aOLO.Order.OrderTypeID);
            htmlCont = `<div>${Create_Button_Customize(mItem, sizeId)}</div>`
        }
    } else {
        const sizes = Create_Size_Option(mItem, aOLO.Order.OrderTypeID);
        const mods = OnlineOrderingUtil.Create_Modifier_DropDown(mItem, aOLO);

        if (sizes.element !== null)
            htmlCont += sizes.element.outerHTML;

        if (mods.element !== null)
            htmlCont += mods.element.outerHTML;

        htmlCont += Create_Quantity_DropDown(mItem).outerHTML;
        htmlCont += `<div>${Create_Button_AddToOrder(mItem, sizes.sizeId, mods.DropdownIds)}</div>`;
    }

    const calories = OnlineOrderingUtil.getItemCalories(mItem, null, aOLO.Order.OrderTypeID, null, aOLO);
    const html = `
        <div id="div_menu_item_${mItem.ItemId}" name="div_menu_item" class="vMenuItem" data-iid="${mItem.ItemId}">
            <div>
                ${(mItem.ImageURL !== "") ? `<img alt="${itemName}" src="${mItem.ImageURL}">` : ``}
            </div>
            <div>
                <span ltagj="${Util.toLtagj(mItem.Names)}">${itemName}</span>
                <p ${(itemName !== itemDesc) ? `ltagj="${Util.toLtagj(mItem.Descriptions)}"` : ``}>${(itemName !== itemDesc) ? itemDesc : ``}</p>
                <div>
                    <div id="div_menu_item_calories_${mItem.ItemId}" class="vMenuItemCalorie" ltagj="${Util.toLtagj(calories)}">${Common.GetName(calories, aOLO.Temp.languageCode)}</div>
                </div>
            </div>
            ${htmlCont}
        </div>`;

    let htmlEle = Util.createHtmlElementFromTemplate(html);
    let sizeSelect = htmlEle.querySelector(`#sel_item_size_${mItem.ItemId}`) as HTMLSelectElement;

    if (sizeSelect)
        sizeSelect.onchange = (e) => UpdateCaloriesAndLoyaltyPoints(e, mItem, aOLO.Order.OrderTypeID);

    return htmlEle;
}

function ItemStyle_2(mItem: IDataItem): HTMLElement {
    const itemName = Common.GetName(mItem.Names, aOLO.Temp.languageCode).trim();
    const itemDesc = Common.GetDescription(mItem.Descriptions, aOLO.Temp.languageCode).trim();
    const defSize = OnlineOrderingUtil.GetItemDefaultSize(mItem, aOLO.Order.OrderTypeID, null, aOLO.data.Settings.DSZID);
    const points = OnlineOrderingUtil.GetLoyaltyPoints(mItem.ItemId, defSize?.SizeId, aOLO.data.LoyaltyItems);

    let htmlCont = "";
    let htmlPrice = "";

    if (OnlineOrderingUtil.MustCustomize(mItem, aOLO.data.ModifierDisplayGroups, aOLO.itemStyleID)) {
        if (defSize) {
            let defPrice = GetItemPriceForSize(mItem.Prices, defSize.SizeId);
            if (defPrice > 0)
                htmlPrice = `<div class="m1 right title2">${Util.formatMoney(defPrice)}</div>`;
        }
        htmlCont = `<div>${Create_Button_Customize(mItem, defSize.SizeId)}</div>`;
    } else if (OnlineOrderingUtil.CanCustomize(mItem, aOLO.data.ModifierDisplayGroups)) {
        const sizes = Create_Size_Option(mItem, aOLO.Order.OrderTypeID);
        if (sizes.element !== null)
            htmlCont += sizes.element.outerHTML;
        htmlCont += Create_Quantity_DropDown(mItem).outerHTML;
        htmlCont += `
            <div>
                ${Create_Button_Customize(mItem, sizes.sizeId)}
                ${Create_Button_AddToOrder(mItem, sizes.sizeId, null)}
            </div>`;
    } else {
        const sizes = Create_Size_Option(mItem, aOLO.Order.OrderTypeID);
        const mods = OnlineOrderingUtil.Create_Modifier_DropDown(mItem, aOLO);

        if (sizes.element !== null)
            htmlCont += sizes.element.outerHTML;

        if (mods.element !== null)
            htmlCont += mods.element.outerHTML;

        htmlCont += Create_Quantity_DropDown(mItem).outerHTML;
        htmlCont += `<div>${Create_Button_AddToOrder(mItem, sizes.sizeId, mods.DropdownIds)}</div>`;
    }

    const isLoyalty = (aOLOModules.LoyaltyProvider.hasPointRedemption() && aOLO.User.IsLoyalty);
    let pointHtml = "";
    if (points)
        pointHtml = `
            <div id="div_menu_item_points_${mItem.ItemId}" name="div_menu_item_points" class="vMenuItemPoint ${!isLoyalty ? `hidden` : ``}" data-iid="${mItem.ItemId}" data-pts="${points}">
                ${points} <span ltag="Points">${Names("Points")}</span>
            </div>`;

    const calories = OnlineOrderingUtil.getItemCalories(mItem, null, aOLO.Order.OrderTypeID, null, aOLO);
    const html = `
        <div id="div_menu_item_${mItem.ItemId}" name="div_menu_item" class="vMenuItem" data-iid="${mItem.ItemId}">
            <div>
                ${(mItem.ImageURL !== "") ? `<img alt="${itemName}" src="${mItem.ImageURL}">` : ``}
            </div>
            <div>
                <span ltagj="${Util.toLtagj(mItem.Names)}">${itemName}</span>
                <p ${(itemName !== itemDesc) ? `ltagj="${Util.toLtagj(mItem.Descriptions)}"` : ``}>${(itemName !== itemDesc) ? itemDesc : ``}</p>
                <div>
                    <div id="div_menu_item_calories_${mItem.ItemId}" class="vMenuItemCalorie" ltagj="${Util.toLtagj(calories)}">${Common.GetName(calories, aOLO.Temp.languageCode)}</div>
                    ${pointHtml}
                </div>
                ${htmlPrice}
            </div>
            ${htmlCont}
        </div>`;
    let htmlEle = Util.createHtmlElementFromTemplate(html);
    let sizeSelect = htmlEle.querySelector(`#sel_item_size_${mItem.ItemId}`) as HTMLSelectElement;
    if (sizeSelect)
        sizeSelect.onchange = (e) => UpdateCaloriesAndLoyaltyPoints(e, mItem, aOLO.Order.OrderTypeID);

    return htmlEle;
}

function ItemStyle_3_4(mItem: IDataItem): HTMLElement {
    //Horizontal
    const div1 = document.createElement("div");
    const div2 = document.createElement("div");
    const div3 = document.createElement("div");
    const span = document.createElement("span");
    const p = document.createElement("p");
    const name = Common.GetName(mItem.Names, aOLO.Temp.languageCode);

    div1.id = `div_menu_item_${mItem.ItemId}`;
    div1.setAttribute("name", "div_menu_item");
    div1.setAttribute("data-iid", mItem.ItemId.toString());
    div1.classList.add("hMenuItem");
    div1.setAttribute("tab-index", (1).toString());

    const btn = document.createElement("button");
    btn.setAttribute("aria-label", name);

    if (mItem.ImageURL !== "")
        div2.style.backgroundImage = `url(${mItem.ImageURL})`;

    div1.classList.add("hMenuItemContent");

    span.classList.add("menuItemName");
    span.innerText = name;
    span.setAttribute("ltagj", Util.toLtagj(mItem.Names));

    p.classList.add("hMenuItemDescription");
    p.innerText = Common.GetDescription(mItem.Descriptions, aOLO.Temp.languageCode);
    p.setAttribute("ltagj", Util.toLtagj(mItem.Descriptions));

    div3.appendChild(span);
    div3.appendChild(p);
    div3.classList.add("p1");
    div3.classList.add("width100");

    if (aOLO.itemStyleID === ItemStyle.HORIZONTAL_IMAGE_LEFT) {
        div2.classList.add("hMenuItemImgLeft");
        btn.appendChild(div2);
        btn.appendChild(div3);
    } else { // image right
        div2.classList.add("hMenuItemImgRight");
        btn.appendChild(div3);
        btn.appendChild(div2);
    }
    let defSize = OnlineOrderingUtil.GetItemDefaultSize(mItem, aOLO.Order.OrderTypeID, null, aOLO.data.Settings.DSZID);
    div1.appendChild(btn);
    btn.onclick = function () {
        CheckAgeAndCustomizeItem(mItem, defSize.SizeId, 1);
    };

    return div1;
}

function CheckAgeAndCustomizeItem(mItem: IDataItem, sizeId: number | null | undefined, qty: number): void {
    if (mItem.MinimumCustomerAge && mItem.MinimumCustomerAge > 0 && mItem.MinimumCustomerAge > aOLO.Temp.Age) {
        DialogCreators.messageBox(Names("AGE").replace("??", mItem.MinimumCustomerAge.toString()), aOLO.buttonHoverStyle, [
            {
                "text": Names("YesIm"), "callBack": () => {
                    aOLO.Temp.Age = mItem.MinimumCustomerAge;
                    aOLO.Dialog.CustomizeItem = new CustomizeItem(aOLO, mItem.Index, sizeId, qty);
                }
            },
            { "text": Names("NoImNot"), "callBack": null }]);
    }
    else
        aOLO.Dialog.CustomizeItem = new CustomizeItem(aOLO, mItem.Index, sizeId, qty);
}
function UpdateCaloriesAndLoyaltyPoints(e: Event, mItem: IDataItem, orderTypeID: number) {
    const target = e.target as HTMLSelectElement;
    const sizeID = parseInt(target.value);
    const itemId = mItem.ItemId;

    const calorieDiv = document.getElementById(`div_menu_item_calories_${itemId}`);
    if (calorieDiv) {
        const calories = OnlineOrderingUtil.getItemCalories(mItem, null, orderTypeID, sizeID, aOLO);
        calorieDiv.innerText = Common.GetName(calories, aOLO.Temp.languageCode);
        calorieDiv.setAttribute("ltagj", Util.toLtagj(calories));
    }

    const point = OnlineOrderingUtil.GetLoyaltyPoints(itemId, sizeID, aOLO.data.LoyaltyItems);
    let pointDiv = document.getElementById(`div_menu_item_points_${itemId}`);
    if (pointDiv) {
        pointDiv.innerHTML = point ? `${point} ${Names("Points")}` : "";
        if (point)
            pointDiv.dataset.pts = point.toString();
    }
}

function IsMultiSizeItem(mItem: IDataItem, orderTypeID: number): boolean {
    const ot = mItem.OrderTypes.find(ot => ot.OrderTypeId === orderTypeID);
    if (ot?.Sizes && ot.Sizes.length > 1)
        return true;
    return false;
}

function CreateItemSizeButtons(mItem: IDataItem, orderTypeID: number): HTMLElement {
    const div = document.createElement("div");
    let gridTemp = "";
    let sizeCount = 0;

    const ots = mItem.OrderTypes.find(ots => ots.OrderTypeId === orderTypeID);
    if (!ots) return div;

    for (const [index, size] of ots.Sizes.entries()) {
        const btn = document.createElement("button");
        btn.id = `btn_item_${mItem.Index}_${size.SizeId}`;
        btn.name = `btn_item_all`;
        btn.classList.add("vMenuItemBtnSize", aOLO.Temp.ButtonHoverStyles[aOLO.data.Settings.OLBTN]);
        btn.innerHTML = `${Common.GetName(aOLO.data.Sizes[size.Index].Names, aOLO.Temp.languageCode)}<br/>${Util.formatMoney(GetItemPriceForSize(mItem.Prices, size.SizeId))}`;
        btn.dataset.data = encodeURIComponent(JSON.stringify({ iidx: mItem.Index, sid: size.SizeId }));
        btn.style.gridColumn = (index + 1).toString();
        div.appendChild(btn);
        gridTemp += "auto ";
        sizeCount += 1;
    }

    div.style.gridTemplateRows = gridTemp;
    if (sizeCount > 4) {
        div.style.paddingLeft = "2px";
        div.style.paddingRight = "2px";
    }
    return div;
}

function GetItemPriceForSize(itemPrcs: IItemPrice[], sizeID: number | null): number {
    if (!itemPrcs)
        return 0;

    const item = itemPrcs.find(item => item.SizeId === sizeID);
    return item ? item.MinimumPrice : 0;
}

function GetSingleSizeItemSizeID(mItem: IDataItem, orderTypeID: number): number {
    const ot = mItem.OrderTypes.find(ot => ot.OrderTypeId === orderTypeID);
    return ot ? ot.Sizes[0].SizeId : 0;
}

function Create_Button_Customize(mItem: IDataItem, sizeId: number | null): string {
    const data = encodeURIComponent(JSON.stringify({ idx: mItem.Index, sizeId: sizeId }));

    const btnClass = aOLO.Temp.ButtonHoverStyles[aOLO.data.Settings.OLBTN];
    const html = `
        <div class="m1">
            <button id="btn_item_customize_${mItem.Index}" name="btn_item_customize" class="${btnClass} width100 customize" ltag="Customize" data-data="${data}">${Names("Customize")}</button>
        </div>`;

    return html;
}

function Create_Size_Option(mItem: IDataItem, orderTypeID: number): ISizeDropdown {
    const sizes = OnlineOrderingUtil.GetItemSizes(mItem, orderTypeID);

    if (sizes.length === 1 && sizes[0].SizeId === 0) {
        const html = `<div class="m1 right title2">${Util.formatMoney(GetItemPriceForSize(mItem.Prices, sizes[0].SizeId))}</div>`;
        return { element: Util.createHtmlElementFromTemplate(html), sizeId: 0, DropdownId: null };
    } else if (sizes.length === 1 && sizes[0].SizeId !== 0) {
        const html = `
            <div class="gridCols1x1x">
                <div class="m1 left">${Common.GetName(aOLO.data.Sizes[sizes[0].Index].Names, aOLO.Temp.languageCode)}</div>
                <div class="m1 right title2"> ${Util.formatMoney(GetItemPriceForSize(mItem.Prices, sizes[0].SizeId))}</div>
            </div>`;
        return { element: Util.createHtmlElementFromTemplate(html), sizeId: sizes[0].SizeId, DropdownId: null };
    } else {
        const defSize = OnlineOrderingUtil.GetItemDefaultSize(mItem, orderTypeID, null, aOLO.data.Settings.DSZID);
        const optionsHTML = sizes.map(siz => {
            const text: IName[] = [];
            for (const name of aOLO.data.Sizes[siz.Index].Names) {
                text.push({
                    NAM: `${Common.GetName(aOLO.data.Sizes[siz.Index].Names, aOLO.Temp.languageCode, name.CULT)} (${Common.GetDescription(aOLO.data.Sizes[siz.Index].Descriptions, aOLO.Temp.languageCode, name.CULT)}) ${Util.formatMoney(GetItemPriceForSize(mItem.Prices, siz.SizeId))}`,
                    CULT: name.CULT
                });
            }

            const selectedAttr = siz.SizeId === defSize.SizeId ? 'selected' : '';
            return `<option value="${siz.SizeId}" ${selectedAttr} ltagj="${Util.toLtagj(text)}">${Common.GetName(text, aOLO.Temp.languageCode)}</option>`;
        }).join('');

        const html = `
            <div class="m1-lr">
                <label ltag="SIZE" for="sel_item_size_${mItem.ItemId}">${Names("SIZE")}</label>
                <select id="sel_item_size_${mItem.ItemId}" class="m1-b">
                    ${optionsHTML}
                </select>
            </div>`;

        const element = Util.createHtmlElementFromTemplate(html);

        return { element: element, sizeId: null, DropdownId: `sel_item_size_${mItem.ItemId}` };
    }
}

function Create_Quantity_DropDown(mItem: IDataItem): HTMLElement {
    const options = Array.from({ length: 20 }, (_, i) => `<option value="${i + 1}">${i + 1}</option>`).join("");

    let html = `
        <div class="m1-lr">
            <label ltag="Quantity" for="sel_item_quantity_${mItem.ItemId}">${Names("Quantity")}</label>
            <select id="sel_item_quantity_${mItem.ItemId}" class="m1-b">
                ${options}
            </select>
        </div>`;

    return Util.createHtmlElementFromTemplate(html);
}

function Create_Button_AddToOrder(mItem: IDataItem, sizeId: number | null, modDropdownIds: string[] | null): string {
    const data = encodeURIComponent(JSON.stringify({ idx: mItem.Index, sizeId: sizeId, mods: modDropdownIds }));
    const btnClass = aOLO.Temp.ButtonHoverStyles[aOLO.data.Settings.OLBTN];

    const html = `
        <div class="m1">
            <button id="btn_item_add_to_order_${mItem.Index}" name="btn_item_add_to_order" class="${btnClass} width100" ltag="AddToOrder" data-data=${data}>${Names("AddToOrder")}</button>
        </div>`;

    return html;
}

function GetItemMods(mItem: IDataItem, modDropdownIds: string[] | null, sizeID: number | null, modifiers?: IModReOrder[]): IOrderItemModifier[] {
    let mods = [];
    const length = (modifiers) ? modifiers.length : (modDropdownIds ? modDropdownIds.length : 0);
    for (let i = 0; i < length; i++) {
        let iMod;
        let iIDX = 0;
        let iDGIDX = 0;
        let preModId = 0;
        if (modDropdownIds) {
            preModId = aOLO.Temp.DefPreModID;
            let ddl = document.getElementById(modDropdownIds[i]) as HTMLSelectElement;
            if (ddl) {
                const tmpiIDX = ddl.options[ddl.selectedIndex].getAttribute('data-mod-iidx');
                const tmpiDGIDX = ddl.options[ddl.selectedIndex].getAttribute('data-mdg-iidx');
                if (tmpiIDX && tmpiDGIDX) {
                    iIDX = Number(tmpiIDX);
                    iDGIDX = Number(tmpiDGIDX);
                    iMod = mItem.ModifierDisplayGroups[iDGIDX].Modifiers[iIDX];
                }
            }
        } else if (modifiers) {
            preModId = modifiers[i].preModId;
            iDGIDX = mItem.ModifierDisplayGroups.findIndex(modGroup => modGroup.ModifierDisplayGroupId == modifiers[i].modDisplayGroupId);
            let displayGroup = mItem.ModifierDisplayGroups[iDGIDX];
            iIDX = displayGroup.Modifiers.findIndex(mod => mod.ModifierId == modifiers[i].modId);
            iMod = displayGroup.Modifiers[iIDX];
        }

        if (!iMod)
            continue;

        /*if mod has add on price */
        if (iMod.Prices) {
            const hasPrice = iMod.Prices.find(x => x.SizeId === sizeID && x.AddOnPrice === -1);
            if (hasPrice)
                return [];
        }

        const mod = {
            ModifierId: iMod.ModifierId,
            Name: iMod.Name,
            Index: iMod.Index,
            ModifierDisplayGroupIndex: mItem.ModifierDisplayGroups[iDGIDX].Index,
            iIndex: iIDX,
            iModifierDisplayGroupIndex: iDGIDX,
            PreModifierId: preModId,
            IsDefault: iMod.IsDefault,
            ModifierDisplayGroupId: mItem.ModifierDisplayGroups[iDGIDX].ModifierDisplayGroupId,
            MergedModifierDisplayGroupId: iMod.MergedModifierDisplayGroupId,
            CountForPricing: iMod.CountForPricing,
            CountForPricingByItself: iMod.CountForPricingByItself,
            Price: 0
        };
        mods.push(mod);
    }
    return mods;
}

export function startOrderTypePage(bool: boolean): void {
    aOLO.Dialog.OrderType = new OrderTypeDialog(getOrderTypeFunctions(), bool, false);
}

export function signUpClick(): void {
    let signInInstance = new SignIn(null, setStartupDialogs, signUpClick, startOrderTypePage);
    aOLO.Dialog.Profile = new Profile(null, GUI_SetOrder_Customer, signInInstance);
    aOLO.Dialog.Profile.openDialog(aOLO);
}

export async function setStartupDialogs(): Promise<void> {
    if (aOLO.User.GU == LoggedInStatus.LOGGEDIN) {
        GUI_SetOrder_Customer();
        aOLO.Temp.Address = null;
        await GUI_SetUserLoggedIn();
    } else
        LoginDialog();
}

function LoginDialog(): void {
    Common.closeHamburgerMenu();
    if (aOLO.data.Settings.ISCC && aOLO.Temp.SelectedStoreID === 0) {
        Util.setElement("innerText", "div_header_store_address", "Order Center");
        Util.setElement("innerText", "div_header_store_city_state_zip", "");
    }
    aOLO.Dialog.SignIn = new SignIn(null, setStartupDialogs, signUpClick, startOrderTypePage, true);
    //document.body.style.setProperty("-webkit-overflow-scrolling", 'touch');
}

export async function Item_AddToOrder(itemIDX: number, sizeId: number | null, quantity: number, modDropdownIds: string[] | null, modifiers?: IModReOrder[]): Promise<void> {
    let mItem = aOLO.data.Items[itemIDX];
    let iKey = OnlineOrderingUtil.GetNextItemKey(aOLO.Order.Items);

    let mods = null;
    if (!mItem)
        return;

    if (mItem.MinimumCustomerAge && mItem.MinimumCustomerAge > 0 && mItem.MinimumCustomerAge > aOLO.Temp.Age) {
        DialogCreators.messageBox(Names("AGE").replace("??", mItem.MinimumCustomerAge.toString()), aOLO.buttonHoverStyle, [
            {
                "text": Names("YesIm"), "callBack": async () => {
                    aOLO.Temp.Age = mItem.MinimumCustomerAge;
                    await Item_AddToOrder(itemIDX, sizeId, quantity, modDropdownIds, modifiers);
                }
            },
            { "text": Names("NoImNot"), "callBack": null }]);
        return;
    }

    if (modDropdownIds !== null) {
        mods = GetItemMods(mItem, modDropdownIds, sizeId, modifiers);
        /* if a modifier is not offered on selected size */
        if (mods === null)
            return;
    } else
        mods = OnlineOrderingUtil.Item_Get_Default_Mods(mItem, sizeId, aOLO);

    const oItem: IOrderItem = {
        ItemId: mItem.ItemId,
        Name: JSON.stringify(mItem.Names),
        Index: mItem.Index,
        SizeId: sizeId || 0,
        Quantity: quantity,
        Modifiers: mods,
        Price: 0.0,
        MinPrice: 0.0,
        HalfHalfPrice: 0.0,
        MenuPrice: 0.0,
        MenuPriceTax: 0.0,
        Tax: 0.0,
        PriceIncludesTax: false,
        Taxes: [],
        TaxAmount: 0.0,
        BeforeTaxDiscount: 0,
        AfterTaxDiscount: 0,
        DiscountedMarkedQuantity: 0,
        DiscountedQuantity: 0,
        LoyaltyDiscountsQTY: 0,
        NonLoyaltyDiscountsQTY: 0,
        LoyaltyDiscountsAmount: 0,
        ItemWebCategoryId: mItem.WebCategoryId,
        ItemCategoryId: mItem.ItemCategoryId,
        ItemKey: iKey,
        ItemRecId: OnlineOrderingUtil.GetRecID(),
        HalfCount: 1,
        HalfIndex: 0,
        Edit: OnlineOrderingUtil.CanCustomize(mItem, aOLO.data.ModifierDisplayGroups),
        Comment: "",
        IsBuildYourOwn: mItem.IsBuildYourOwn,
        Instructions: []
    };

    OnlineOrderingUtil.PriceItem(oItem, aOLO);
    aOLO.Order.Items.push(oItem);
    [aOLO.Order.Taxes, aOLO.Order.TaxesDetail] = OnlineOrderingUtil.TaxOrder(aOLO.Order, aOLO);

    OnlineOrderingUtil.CalculateTotals(aOLO);

    let mealDealFinished = await OnlineOrderingUtil.MealDealAddToOrder(iKey, aOLO);

    OnlineOrderingUtil.GUI_SetOrder(aOLO, aOLOModules);

    if (mealDealFinished)
        delete aOLO.Dialog.MealDeal;
    else
        await aOLO.Modules.Coupon.AutoApplyCoupons();

    await aOLOModules.LoyaltyProvider.batchComparison(true);

    if (aOLO.Modules.DataLayer)
        aOLO.Modules.DataLayer.add_to_cart(aOLO.Order.Items.find(x => x.ItemKey == iKey));
}

export async function selectStoreForCheckedAddress(): Promise<void> {
    if (!aOLO.Temp.Address)
        return;

    let zone = OnlineOrderingUtil.CheckZones(aOLO.Temp.Address.Latitude, aOLO.Temp.Address.Longitude, []); //aOLO.data.DeliveryZones
    if (zone?.ZTID == 1)
        await setStoreOrderInfo(zone.SID, aOLO.Order.OrderTypeID);
}

export async function SetOrderType_Pre(orderTypeID: number, successFunction?: Function, force: boolean = false): Promise<void> {
    const functions = () => {
        aOLO.Modules.Categories = new Categories(aOLO.data.Categories, aOLO);
        aOLO.Modules.OrderCart = new OrderCart(getOrderCartFunctions());
        GUI_LoadItems();
        if (successFunction)
            successFunction();
    };

    aOLO.Temp.OrderTypeID = orderTypeID;
    if (aOLO.location && !aOLO.location.validated) {
        OnlineOrderingUtil.getLocation(aOLO);
    }

    if (!aOLO.data.Settings.ISCC && orderTypeID !== OrderType.DELIVERY && aOLO.location.validated && !aOLO.Temp.CustomerAcceptedDistanceDialog) {
        OnlineOrderingUtil.CheckCustomerDistance(aOLO.data.Settings.LAT, aOLO.data.Settings.LNG, aOLO, async () => {
            delete aOLO.Temp.DialogOpen;
            aOLO.Temp.CustomerAcceptedDistanceDialog = true;
            await CheckOrderCouponsForNewOrderType(orderTypeID, setOrderType, force);
            functions();
        }, () => {
            delete aOLO.Temp.DialogOpen;
            OnlineOrderingUtil.GotoStoreBrandPage(aOLO);
        });
    } else if (aOLO.data.Settings.ISCC && orderTypeID !== OrderType.DELIVERY && aOLO.Order.StoreID === aOLO.storeInfo.StoreID) {  // if the order store id is not the call center store id
        aOLO.Dialog.CallCenterStores = new CallCenterStoresDialog(setStoreOrderInfo);
        functions();
    } else {
        await CheckOrderCouponsForNewOrderType(orderTypeID, setOrderType);
        functions();
    }
}

async function CheckOrderCouponsForNewOrderType(orderTypeID: number, _setOrderType: (orderTypeId: number, force?: boolean) => Promise<void>, force: boolean = false): Promise<void> {
    let couponsOK = true;

    for (const coupon of aOLO.Order.Coupons) {
        let cpn = aOLO.Modules.Coupon.GetCoupon(coupon.CouponId, true, orderTypeID, coupon.ChannelID);
        if (cpn !== null)
            continue;

        couponsOK = false;
        DialogCreators.messageBox(Names("CpnOTNotOffered"), aOLO.buttonHoverStyle, [
            {
                "text": Names("Continue"), "callBack": async function () {
                    await aOLO.Modules.Coupon.RemoveNotApplicableOrderTypeCoupons(orderTypeID);
                    await _setOrderType(orderTypeID);
                }
            },
            {
                "text": Names("DontContinue"), "callBack": () => {
                    if (aOLO.Dialog.OrderType)
                        aOLO.Dialog.OrderType.selectOrderTypeButton();
                }
            }]);
        break;
    }

    if (couponsOK)
        await _setOrderType(orderTypeID, force);
}

/**
* Handle the click event for the reorder button on an order card
* @private
* @async
* @param {number} orderId - The ID of the order to reorder
* @returns {Promise<void>}
*/
export async function ReOrder(orderId: number): Promise<void> {
    let items = await aOLO.Modules.ProfileService.getOrderItems(orderId, aOLO);
    let unavailableItems: string[] = [];
    let errors: { itemId: number, itemName: string }[] = [];

    let newItem: CustomizeItem;
    items.forEach(item => {
        const mItem = aOLO.data.Items.find(itm => itm.ItemId == item.itemId) || null;
        try {
            if (!mItem)
                throw Error;

            // Add whole item or first half
            if (item.halfIndex != 2) {
                newItem = new CustomizeItem(aOLO, mItem.Index, item.sizeId, item.quantity, false);
                const newItem1 = newItem.GetItem1();

                // Add item 1 modifiers
                if (item.mods && newItem1) {
                    for (const mod of item.mods) {
                        if (!aOLO.data.PreModifiers.some(premod => premod.PreModifierId == mod.preModId)) { // Premodifier is not available or is inactive
                            let itemNameCulture = mItem.Names.find(x => x.CULT.toLowerCase() == aOLO.Temp.languageCode.toLowerCase());
                            unavailableItems.push(itemNameCulture?.NAM || mItem.Names[0].NAM)
                            return;
                        }
                        const modFound = newItem1.Modifiers.find(x => x.ModifierId == mod.modId && x.PreModifierId == mod.preModId && x.ModifierDisplayGroupId == mod.modDisplayGroupId);
                        if (modFound)
                            continue;
                        const modGroupIndex = mItem.ModifierDisplayGroups.findIndex(x => x.ModifierDisplayGroupId == mod.modDisplayGroupId);
                        const modGroup = mItem.ModifierDisplayGroups.find(x => x.ModifierDisplayGroupId == mod.modDisplayGroupId);
                        const modIndex = modGroup ? modGroup?.Modifiers.findIndex(x => x.ModifierId == mod.modId) : -1;
                        newItem.alterSelectedItemMod(newItem1.ItemId, modGroupIndex, mod.modId, modIndex, mod.preModId, 1, false, false);
                    }
                }

                // Add item comment
                if (item.comment)
                    newItem.SetItemComment(item.comment);
            }

            // Add second half item
            if (item.halfIndex == 2) {
                newItem.makeItemHalfHalf();
                newItem.custItemChange(mItem.ItemId, 2);

                const newItem2 = newItem.GetItem2();

                // Add item 1 modifiers
                if (item.mods && newItem2) {
                    for (const mod of item.mods) {
                        const modFound = newItem2.Modifiers.find(x => x.ModifierId == mod.modId && x.PreModifierId == mod.preModId && x.ModifierDisplayGroupId == mod.modDisplayGroupId);
                        if (modFound)
                            continue;

                        const modGroupIndex = mItem.ModifierDisplayGroups.findIndex(x => x.ModifierDisplayGroupId == mod.modDisplayGroupId);
                        const modGroup = mItem.ModifierDisplayGroups.find(x => x.ModifierDisplayGroupId == mod.modDisplayGroupId);
                        const modIndex = modGroup ? modGroup?.Modifiers.findIndex(x => x.ModifierId == mod.modId) : -1;
                        newItem.alterSelectedItemMod(newItem2.ItemId, modGroupIndex, mod.modId, modIndex, mod.preModId, 2, false, false);
                    }
                }
            }

            // Add item to order cart
            if ([0, 2].includes(item.halfIndex))
                newItem.addSelectedToOrder();
        } catch (e: any) {
            errors.push({ itemId: item.itemId, itemName: Common.GetNewName(item.names, aOLO.Temp.languageCode) });
        }
    });

    if (unavailableItems.length > 0) {
        let items = `<br>`;
        unavailableItems.forEach(item => {
            items += `<br> - ${item}`;
        })

        let msg = `${Names("UnavailableItemModifications1")} ${items}`;
        msg += `<br><br> ${Names("UnavailableItemModifications2")}`
        DialogCreators.messageBoxOk(msg, aOLO.buttonHoverStyle);
    }

    if (errors.length > 0) {
        let unfoundItems = "";
        errors.forEach((x, index) => {
            unfoundItems += (index == 0) ? x.itemName : `, ${x.itemName}`;
        });

        if (errors.length == items.length)
            DialogCreators.messageBoxOk(Names("MyOrders_ReOrder_AllError"), aOLO.buttonHoverStyle);
        else if (errors.length == 1)
            DialogCreators.messageBoxOk(`${unfoundItems} ${Names("MyOrders_ReOrder_SingleError")}`, aOLO.buttonHoverStyle);
        else
            DialogCreators.messageBoxOk(`${unfoundItems} ${Names("MyOrders_ReOrder_MultipleError")}`, aOLO.buttonHoverStyle);
    }
}

/***********************/
/********* GUI *********/
/***********************/
export function initializeOrderTypeGUI(): void {
    GUI_UpdateHeaderStoreAddress();
    if (aOLO.Dialog.OrderType)
        aOLO.Dialog.OrderType.setStoreInfo();
    OnlineOrderingUtil.GUI_SetOrder(aOLO, aOLOModules);
    GUI_LoadCoupons(aOLO.data.Coupons);
}

export function GUI_LoadItems(): void {
    const items = aOLO.data.Items;
    let divItems = document.getElementById("div_items");
    if (!divItems)
        return;

    Util.removeChildren("div_items");
    let lastCatID = 0;
    let lastSubCatID = 0;

    let catDivClass = "vMenuItems";
    if (aOLO.itemStyleID === ItemStyle.HORIZONTAL_IMAGE_LEFT || aOLO.itemStyleID === ItemStyle.HORIZONTAL_IMAGE_RIGHT) {
        catDivClass = "hMenuItems";
    }
    divItems.classList.add(catDivClass);

    let storeItems = "";
    if (aOLO.data.Settings.ISCC) {
        const store = OnlineOrderingUtil.getCallCenterStore(aOLO.Order.StoreID, aOLO.data.Stores);
        if (store) {
            storeItems = store.Items;
        }
    }

    let defCategory: IDataCategory | null = null;
    const categories = aOLO.data.Categories;
    for (const category of categories) {
        if (category.Default)
            defCategory = category;

        if (category.IsMealDeal) {
            if (lastCatID !== category.ItemWebCategoryId) {
                divItems.appendChild(OnlineOrderingUtil.SetCategoryHeader(category.Names, category.ItemWebCategoryId, true, aOLO.Temp.languageCode));
            }
            GUI_GetMealDealCarts(category.ItemWebCategoryId);
        } else {
            const categoryItems = items.filter(x => x.WebCategoryId === category.ItemWebCategoryId);
            for (const mItem of categoryItems) {
                if (aOLO.data.Settings.ISCC && storeItems !== "") {
                    const itemId = `*${mItem.ItemId}*`;
                    if (storeItems.indexOf(itemId) === -1)
                        continue;
                }

                let itemSizes = OnlineOrderingUtil.GetItemSizes(mItem, aOLO.Order.OrderTypeID);

                // Check if QR
                if (aOLO.QrCode)
                    itemSizes = itemSizes.filter(x => x.IsOfferedQR);

                if (itemSizes.length === 0)
                    continue;

                if (lastSubCatID !== mItem.SubCategoryId && mItem.SubCategoryId !== 0 && aOLO.data.SubCategories)
                    divItems.appendChild(OnlineOrderingUtil.SetCategoryHeader(aOLO.data.SubCategories[mItem.SubCategoryIndex].Names, mItem.WebCategoryId, false, aOLO.Temp.languageCode));
                else if (lastCatID !== mItem.WebCategoryId)
                    divItems.appendChild(OnlineOrderingUtil.SetCategoryHeader(aOLO.data.Categories[mItem.WebCategoryIndex].Names, mItem.WebCategoryId, false, aOLO.Temp.languageCode));

                divItems.appendChild(GetItemCart(mItem));
                lastSubCatID = mItem.SubCategoryId;
                lastCatID = category.ItemWebCategoryId;
            }
        }

        lastSubCatID = 0;
        lastCatID = category.ItemWebCategoryId;
    }

    if (aOLOModules.LoyaltyProvider.hasPointRedemption() && aOLO.User.IsLoyalty)
        document.getElementsByName("divMenuItemPoints").forEach(x => Util.showElement(x));

    if (defCategory)
        window.setTimeout(() => {
            if (defCategory)
                anchorDefaultCategory(defCategory.ItemWebCategoryId);
        }, 800);

    ItemsSetEventListeners();
}

function ItemsSetEventListeners() {
    let divItems = document.getElementById("div_items");
    if (!divItems)
        return;

    const customizeButtons = Array.from(document.getElementsByName("btn_item_customize"));
    for (const button of customizeButtons) {
        Util.setElement("onclick", button, () => {
            const data = JSON.parse(decodeURIComponent(button.dataset.data || ""));
            const mItem = aOLO.data.Items[data.idx];
            const sizeId = Number(Util.getElementValue(`sel_item_size_${mItem.ItemId}`)) || data.sizeId;
            const qty = Number(Util.getElementValue(`sel_item_quantity_${mItem.ItemId}`)) || 1;
            CheckAgeAndCustomizeItem(mItem, sizeId, qty);
        });
    }

    const addToOrderButtons = Array.from(document.getElementsByName("btn_item_add_to_order"));
    for (const button of addToOrderButtons) {
        Util.setElement("onclick", button, async () => {
            const data = JSON.parse(decodeURIComponent(button.dataset.data || ""));
            const mItem = aOLO.data.Items[data.idx];
            const sizeId = Number(Util.getElementValue(`sel_item_size_${mItem.ItemId}`)) || data.sizeId;
            const qty = Number(Util.getElementValue(`sel_item_quantity_${mItem.ItemId}`)) || 1;
            await Item_AddToOrder(data.idx, Number(sizeId), qty, data.mods);
        });
    }

    const customizeTopButtons = Array.from(document.getElementsByName("btn_item_all"));
    for (const button of customizeTopButtons) {
        Util.setElement("onclick", button, () => {
            const data = JSON.parse(decodeURIComponent(button.dataset.data || ""));
            const mItem = aOLO.data.Items[data.iidx];
            CheckAgeAndCustomizeItem(mItem, data.sid, 1);
        });
    }
}

function anchorDefaultCategory(catID: number): void {
    if (!catID)
        return;

    try {
        let catHeight = 0;
        let divCats = document.getElementById("div_categories");
        if (divCats) {
            let divCatsParent = divCats.parentNode as HTMLElement;
            if (divCatsParent?.classList.contains("containerTopCat"))
                catHeight = divCats.offsetHeight;
        }

        let anchor = document.getElementById(`h1CatAnchor_${catID}`);
        if (anchor) {
            let offSet = -20;
            if (aOLO.layoutStyleID === 2 || aOLO.layoutStyleID === 3) {
                offSet = -80;
            }
            let anchorTop = anchor.offsetTop - aOLO.Temp.HeaderHeight - catHeight + offSet;
            window.scrollTo(0, anchorTop);
        }
    } catch { }
}


export function GUI_UpdateHeaderStoreAddress(): void {
    let store = OnlineOrderingUtil.getCallCenterStore(aOLO.Order.StoreID, aOLO.data.Stores);
    if (!store)
        return;

    Util.setElement("innerText", "div_header_store_address", store.Address1);
    Util.setElement("innerText", "div_header_store_city_state_zip", `${store.City}, ${store.State} ${store.ZipCode}`);
}

export function GUI_SetUserLoggedIn(): void {
    aOLO.Dialog.OrderType = new OrderTypeDialog(getOrderTypeFunctions(), false, false);

    if (aOLOModules.LoyaltyProvider.hasPointRedemption()) {
        Util.showElement("div_cart_points_available");
        Util.setElement("innerText", "spn_cart_points_available", `${(aOLO.User.LoyaltyData?.CurrentPoints || 0) - aOLO.Temp.loyaltyPointsRedeemed} `);
        const elements = document.getElementsByName("div_menu_item_points");
        elements.forEach(x => Util.showElement(x));
    }

    if (aOLOModules.LoyaltyProvider.hasRewardRedemption()) {
        const rewardCount = ((aOLO.User.LoyaltyData?.Rewards !== null) && (aOLO.User.LoyaltyData?.Rewards !== undefined)) ? aOLO.User.LoyaltyData.Rewards.length : 0;
        const offerCount = ((aOLO.User?.Offers !== null) && (aOLO.User?.Offers !== undefined)) ? aOLO.User.Offers.Codes.length : 0;
        Util.showElement("btn_cart_rewards");
        Util.setElement("innerText", "sp_cart_rewards_and_offers_count", rewardCount + offerCount);
    } else
        Util.hideElement("btn_cart_rewards");
}

function GUI_LoadCoupons(coupons: IDataCoupon[]): void {
    Util.removeChildren("div_coupons");

    if (!coupons)
        return;

    let divCpn = document.getElementById("div_coupons");
    for (const coupon of coupons) {
        if ((!coupon.IsHiddenOnline && coupon.WebCategoryId === 0)
            && aOLO.Modules.Coupon.CheckCouponOTST(coupon, aOLO.Order.OrderTypeID)
            && aOLO.Modules.Coupon.CheckCouponSCHS(coupon)
            && aOLO.Modules.Coupon.VerifyCouponDate(coupon)
            && (!coupon.IsAppOnly || (coupon.IsAppOnly && Util.isAppView()))
            && (!coupon.IsProfileRequired || (coupon.IsProfileRequired && aOLO.User.ProfileId != 0)))
            if (divCpn)
                divCpn.appendChild(GUI_GetCouponButton(coupon));
    }
}

function GUI_SetOrder_Get_Item(oItem: IOrderItem, endLine: boolean, displayMods: boolean, displayPoints: boolean): HTMLElement {
    let divItem = document.createElement("div");
    let loyaltyPoint = 0;
    if (displayPoints && oItem.DiscountedQuantity > 0)
        return divItem;

    let mItem = aOLO.data.Items.find(x => x.ItemId === oItem.ItemId);
    if (!mItem)
        return divItem;

    divItem.classList.add("orderItem");

    let itemName = Common.GetName(mItem.Names, aOLO.Temp.languageCode);

    if (displayPoints)
        loyaltyPoint = OnlineOrderingUtil.GetLoyaltyPoints(oItem.ItemId, oItem.SizeId, aOLO.data.LoyaltyItems) || 0;

    if (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 1)) {
        // Item Name
        let title = "";
        let sizeName = "";

        if ((oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 1)) && oItem.SizeId > 0) {
            let iSize = OnlineOrderingUtil.GetItemSize(mItem, aOLO.Order.OrderTypeID, oItem.SizeId);
            sizeName = (iSize === null) ? "Unknown" : `<span ltagj="${Util.toLtagj(aOLO.data.Sizes[iSize.Index].Names)}">${Common.GetName(aOLO.data.Sizes[iSize.Index].Names, aOLO.Temp.languageCode)}</span> - `;
        }

        title += sizeName;

        if (oItem.HalfCount > 1 && oItem.HalfIndex === 1)
            title += `<span ltag="HHP">${Names("HHP")}</span>`;
        else
            title += `<span ltagj="${Util.toLtagj(mItem.Names)}">${itemName}</span>`;

        // Price
        const price = (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price;

        divItem.innerHTML += `
            <div class="cart-item-name-price">
                    <div>${title}</div>
                    <div>${Util.formatMoney(price * oItem.Quantity)}</div>
            </div>`;
    }

    if (oItem.HalfCount == 2) {
        let half = (oItem.HalfIndex === 1) ? "FirstHalf" : "SecondHalf";
        divItem.innerHTML += `
            <div class="title3">
                <span ltag="${half}">${Names(half)}</span>
            </div>
            <div>
                <span ltagj="${Util.toLtagj(mItem.Names)}">${itemName}</span>
            </div>`;
    }

    // Modifiers
    if (displayMods)
        divItem.innerHTML += GUI_SetOrder_Get_Item_Mods(oItem);

    if (oItem.HalfCount == 1 || (oItem.HalfCount == 2 && oItem.HalfIndex == 2)) {
        // Calories
        const calories = OnlineOrderingUtil.getItemCalories(mItem, oItem, aOLO.Order.OrderTypeID, oItem.SizeId, aOLO)
        divItem.innerHTML += `
            <div class="cart-item-calories">
                <div ltagj="${Util.toLtagj(calories)}">${Common.GetName(calories, aOLO.Temp.languageCode)}</div>
            </div>`;

        // Item tax
        for (const tax of oItem.Taxes.filter(x => x.DisplayNextToItem === true)) {
            let taxData = aOLO.data.Taxes.find(t => t.TaxId === tax.TaxId);
            var taxName = "Store-added tax";
            if (taxData && taxData.OrderTypesExcluded.includes(aOLO.Order.OrderTypeID)) {
                continue;
            }
            else if (tax.TaxTypeName) {
                const taxTypeNameArray = JSON.parse(tax.TaxTypeName.toString());
                taxName = taxTypeNameArray.find((x: { NAM: string; CULT: string; }) => x.CULT.toLowerCase() === aOLO.Temp.languageCode.toLowerCase())?.NAM || taxName;
            }

            divItem.innerHTML += `
                <div class="cart-item-calories">
                    <div>${taxName} ${Util.formatMoney(tax.Amount)}</div>
                </div>`;
        }

        /* Quantity, Edit, Delete */
        let divQtyEditDel = "";

        // Quantity
        let options = "";
        for (let i = 1; i < 21; i++) {
            options += `<option value="${i}" ${(oItem.Quantity === i) ? `selected="true"` : ""}>${i}</option>`;
        }

        divQtyEditDel += `
            <div class="cart-item-quantity">
                <div>
                    <label for="ddl_cart_item_quantity_${oItem.ItemKey}" ltag="Quantity">${Names("Quantity")}</label>:
                </div>
                <select id="ddl_cart_item_quantity_${oItem.ItemKey}" name="ddl_cart_item_quantity" data-iky="${oItem.ItemKey}">${options}</select>
            </div>`;

        // Edit
        if (oItem.Edit) {
            divQtyEditDel += `
                <div>
                    <button id="btn_cart_item_edit_${oItem.ItemKey}" name="btn_cart_item_edit" class="btn-icon-link" ltag="Edit" data-iky="${oItem.ItemKey}">${Names("Edit")}</button>
                </div>`;
        }

        // Delete
        divQtyEditDel += `
            <div>
                <button id="btn_cart_item_remove_${oItem.ItemKey}" name="btn_cart_item_remove" class="btn-icon-link" ltag="Remove" data-iky="${oItem.ItemKey}">${Names("Remove")}</button>
            </div>`;

        divItem.innerHTML += `<div class="cart-item-quantity-edit-remove">${divQtyEditDel}</div>`;

        // Points
        if (aOLOModules.LoyaltyProvider.hasPointRedemption() && aOLO.User?.LoyaltyData) {
            let points = OnlineOrderingUtil.GetLoyaltyPoints(oItem.ItemId, oItem.SizeId, aOLO.data.LoyaltyItems);
            if (points) {
                let innerText = "";
                let hidden = (oItem.IsRedeemed || points > (aOLO.User.LoyaltyData?.CurrentPoints || 0));
                if (oItem.Quantity > 1)
                    innerText = (points) ? `<span ltag="Redeem">${Names("Redeem")}</span>&nbsp1&nbsp<span ltag="For">${Names("For")}</span>&nbsp${points}&nbsp<span ltag="Points">${Names("Points")}</span>` : "";
                else
                    innerText = (points) ? `<span ltag="RedeemFor">${Names("RedeemFor")}</span>&nbsp${points}&nbsp<span ltag="Points">${Names("Points")}</span>` : "";

                let btnPnts =
                    `<button id="btn_cart_item_redeem_${oItem.ItemKey}" name="btn_cart_item_redeem" class="${aOLO.buttonHoverStyle} cart-item-redeem-btn ${(hidden ? "hidden" : "")}" data-pts="${points}" data-iky="${oItem.ItemKey}">${innerText}</button>`;

                divItem.innerHTML += btnPnts;
            }
        }
    }

    // Instructions 
    if (oItem.Instructions && oItem.Instructions.length > 0) {
        let instructionText = "";
        for (const inst of oItem.Instructions) {
            const instruction = aOLO.data.Instructions.find(x => x.CommentId === inst.CommentId);
            if (instruction)
                instructionText += `${Common.GetNewName(instruction.Names, aOLO.Temp.languageCode)}   `;
        }

        divItem.innerHTML += `<div class="cart-item-row">${instructionText}</div>`;
    }

    let discRedim = GUI_SetOrder_Get_Item_Discount(oItem);
    if (discRedim)
        divItem.innerHTML += `<div class="cart-item-row" ltag="${discRedim}">${Names(discRedim)}</div>`;

    if (oItem.Comment !== "" && (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 2)))
        divItem.innerHTML += `<div class="cart-item-row">${oItem.Comment}</div>`;

    if (displayPoints && (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 2)))
        divItem.innerHTML += `<div style="text-align: center;">${loyaltyPoint} Points required</div>`;

    if (endLine)
        divItem.innerHTML += `<hr class="lineThemeDot75 m2-tb">`;

    return divItem;
}

function GUI_GetMealDealCarts(wCatId: number): void {
    if (aOLO.data.Coupons.length === 0)
        return;

    const divItems = document.getElementById("div_items");

    for (const coupon of aOLO.data.Coupons) {
        if (divItems && !coupon.IsHiddenOnline && coupon.WebCategoryId === wCatId
            && aOLO.Modules.Coupon.CheckCouponOTST(coupon, aOLO.Order.OrderTypeID)
            && aOLO.Modules.Coupon.CheckCouponSCHS(coupon)
            && aOLO.Modules.Coupon.VerifyCouponDate(coupon)
            && (!coupon.IsAppOnly || (coupon.IsAppOnly && Util.isAppView()))
            && (!coupon.IsProfileRequired || (coupon.IsProfileRequired && aOLO.User.ProfileId != 0)))
            divItems.appendChild(GUI_GetMealDealCart(coupon));
    }
}

export function GUI_SetOrder_Customer(): void {
    if ((aOLO.User.GU == LoggedInStatus.EXTERNAL || aOLO.User.GU == LoggedInStatus.LOGGEDIN)) {
        Util.setElement("innerText", "div_cart_customer_name", `${aOLO.User.Name} ${aOLO.User.LastName}`);
        Util.setElement("innerText", "div_cart_customer_email", aOLO.User.Email);
        const country = aOLO.data.Countries.find(x => x.CountryID == aOLO.User.CountryCodeId) || aOLO.storeInfo.Country;
        Util.setElement("innerText", "div_cart_customer_country_code", country.CountryCode || "");
        const phoneFormatLanguage = Util.getDefaultCultureCode(aOLO.User.CountryCodeId || aOLO.storeInfo.Country.CountryID || 1, aOLO.data.Countries)
        Util.setElement("innerText", "div_cart_customer_phone", Util.formatPhoneNumber(aOLO.User.Phone || "", Names("PhoneFormat", phoneFormatLanguage)));

        Util.showElement("div_cart_customer");
    } else
        Util.hideElement("div_cart_customer");
}

function GUI_GetCouponButton(coupon: IDataCoupon): HTMLElement {
    const div = document.createElement("div");
    div.classList.add("p1", "coupon");
    div.dataset.cid = coupon.CouponId.toString();
    const divCol2 = document.createElement("div");
    divCol2.classList.add("coupon-col2");

    const divBtn = document.createElement("div");
    divBtn.classList.add("right");
    const btn = document.createElement("button");
    btn.innerText = Names("Apply");
    btn.setAttribute("ltag", "Apply");
    btn.classList.add(aOLO.Temp.ButtonHoverStyles[aOLO.data.Settings.OLBTN]);
    btn.onclick = function () {
        if (aOLO.Modules.Coupon.IsCouponNotAllowedInFundraiser()) {
            return;
        }

        if (aOLOModules.LoyaltyProvider.onlyOneDiscountAllowed(null))
            return;

        aOLO.Modules.Coupon.CheckCouponHasQualifyingItems(coupon.CouponId);
    };
    divBtn.appendChild(btn);

    if (coupon.ImageURL) {
        const img = document.createElement("img");
        img.src = coupon.ImageURL;
        img.style.width = '100%';
        img.alt = `Coupon image for a ${Common.GetName(coupon.Names, aOLO.Temp.languageCode)}`;
        if (aOLO.data.Settings.CPNIMG == 2) {
            const divImg = document.createElement("div");
            divImg.style.position = 'relative';
            divBtn.classList.add("coupon-img-btn");
            divCol2.style.padding = '0px';
            div.style.padding = '0px';
            img.style.borderRadius = 'var(--radius)';
            img.style.border = 'var(--border)';
            div.style.border = 'none';
            div.style.backgroundColor = 'transparent';
            divImg.appendChild(img);
            divImg.appendChild(divBtn);
            divCol2.appendChild(divImg);
        } else {
            const divCol1 = document.createElement("div");
            div.style.display = '-webkit-inline-box';
            divCol1.classList.add("coupon-col1");
            divCol2.style.width = '70%';
            divCol1.appendChild(img);
            div.appendChild(divCol1);
        }
    }

    if (aOLO.data.Settings.CPNIMG != 2 || !coupon.ImageURL) {
        const p = document.createElement("p");
        p.innerText = Common.GetDescription(coupon.Descriptions, aOLO.Temp.languageCode);
        p.setAttribute("ltagj", Util.toLtagj(coupon.Descriptions));
        divCol2.appendChild(p);
        divCol2.appendChild(divBtn);
    }

    div.appendChild(divCol2);
    return div;
}

function GUI_SetOrder_Get_Item_Mods(oItem: IOrderItem): string {
    let html = `<div class="m1-lr">`;

    let listMods: string[] = [];
    for (const mod of oItem.Modifiers) {
        let name = "";
        let modName = Common.GetName(aOLO.data.Modifiers[mod.Index].Names, aOLO.Temp.languageCode);
        if (mod.PreModifierId !== aOLO.Temp.DefPreModID) {
            let preMod = OnlineOrderingUtil.GetPreMod(mod.PreModifierId, aOLO.data.PreModifiers);
            let pName = preMod ? Common.GetName(preMod.Names, aOLO.Temp.languageCode) : "";
            name = `<span class="warning" ${(preMod && pName != "") ? `ltagj="${Util.toLtagj(preMod?.Names)}"` : ""}>${pName}</span> `;
        }

        if (mod.PreModifierId !== aOLO.Temp.DefPreModID || !mod.IsDefault) {
            let preModClass = "";

            if (mod.PreModifierId !== aOLO.Temp.DefPreModID)
                preModClass = "modAdjust";
            else if (!mod.IsDefault)
                preModClass = "modAdd";
            else
                preModClass = "modDef";

            listMods.push(name + `<span class="${preModClass}" ltagj="${Util.toLtagj(aOLO.data.Modifiers[mod.Index].Names)}">${modName}</span>`);
        }
    }

    return `${listMods.join(", ")}</div>`;
}

function GUI_SetOrder_Get_Item_Discount(oItem: IOrderItem): string | null {
    const coupon = aOLO.Order.ItemsCoupons.find(icpn => icpn.ItemKey === oItem.ItemKey);
    if (!!coupon)
        return (GUI_SetOrder_GetOrderCouponIDByCouponKey(coupon.CouponKey) === 7) ? "Redeemed" : "Discounted";
    return null;
}

function GUI_GetMealDealCart(coupon: IDataCoupon): HTMLElement {
    let div1 = document.createElement("div");
    let div2 = document.createElement("div");
    let img = document.createElement("img");

    div1.id = `divMealDeal_${coupon.CouponId}`;
    div1.setAttribute("name", "mealDealCart");
    div1.dataset.cid = coupon.CouponId.toString();
    div1.classList.add("vMenuItem");
    div1.style.gridTemplateRows = "auto 1fr auto";


    let divImg = document.createElement("div");
    div1.appendChild(divImg);

    if (coupon.ImageURL && coupon.ImageURL !== "") {
        img.onerror = function () {
            this.style.display = "none";
        };
        img.src = coupon.ImageURL;
        img.setAttribute("alt", Common.GetName(coupon.Names, aOLO.Temp.languageCode));
        divImg.appendChild(img);
    }

    let itemName = Common.GetName(coupon.Names, aOLO.Temp.languageCode);
    let span = document.createElement("span");
    span.innerText = itemName;
    span.setAttribute("ltagj", Util.toLtagj(coupon.Names));
    div2.appendChild(span);

    let itemDesc = Common.GetDescription(coupon.Descriptions, aOLO.Temp.languageCode);
    let p = document.createElement("p");
    p.innerText = itemDesc;
    p.setAttribute("ltagj", Util.toLtagj(coupon.Descriptions));
    div2.appendChild(p);

    div1.appendChild(div2);

    let divPrc = document.createElement("div");
    divPrc.classList.add("m1", "right", "title1");
    div2.appendChild(divPrc);

    let divBtn = document.createElement("div");
    divBtn.appendChild(Create_Button_MealDeal(coupon));
    div1.appendChild(divBtn);

    return div1;
}

function GUI_SetOrder_GetOrderCouponIDByCouponKey(key: number): number | null {
    let coupon = aOLO.Order.Coupons.find(cpn => cpn.CouponKey === key);
    return coupon ? coupon.CouponId : null;
}

function Create_Button_MealDeal(coupon: IDataCoupon): HTMLElement {
    let btn = document.createElement("button");
    btn.classList.add("gridCol2");
    btn.classList.add(aOLO.Temp.ButtonHoverStyles[aOLO.data.Settings.OLBTN]);
    btn.setAttribute("ltag", "Select");
    btn.innerText = Names("Select");
    btn.onclick = function () {
        if (aOLO.Modules.Coupon.IsCouponNotAllowedInFundraiser()) {
            return;
        }
        aOLO.Modules.Coupon.CheckCouponHasQualifyingItems(coupon.CouponId);
    };

    return btn;
}

function GUI_LoadDesktopBanner(desktopBanner: IDataDesktopBanner): void {
    let divBanner = document.getElementById("div_banner");
    if (!desktopBanner || !desktopBanner.Banners || desktopBanner.Banners.length <= 0 || !divBanner)
        return;

    Util.removeChildren("div_banner");
    let nBanner = desktopBanner.Banners?.length || 0;
    let slideBtns = nBanner > 1 && !desktopBanner.RotationActive;
    for (let i = 0; i < nBanner; i++) {
        if (desktopBanner.Banners[i].CouponID > 0 && !aOLO.data.Coupons.find(cpn => cpn.CouponId === desktopBanner.Banners[i].CouponID))
            continue;

        divBanner.appendChild(GUI_GetBannerButton(desktopBanner.Banners[i], slideBtns));
    }

    aOLO.data.DesktopBanner.SlideIdx = 1;
    showBannerSlides(aOLO.data.DesktopBanner.SlideIdx);
    if (desktopBanner.RotationActive) {
        setInterval(function () {
            plusBannerSlides(1);
        }, (desktopBanner.RotationPeriod || 1) * 1000);
    }
}

function plusBannerSlides(n: number) {
    showBannerSlides(aOLO.data.DesktopBanner.SlideIdx += n);
}

function showBannerSlides(n: number) {
    let slides = Array.from(document.getElementsByClassName("banner-slides")) as HTMLElement[];
    if (slides.length == 0) return;
    if (n > slides.length)
        aOLO.data.DesktopBanner.SlideIdx = 1

    if (n < 1)
        aOLO.data.DesktopBanner.SlideIdx = slides.length;

    Array.from(slides).forEach(slide => slide.style.display = "none");
    (slides[aOLO.data.DesktopBanner.SlideIdx - 1]).style.display = "block";
}

function GUI_GetBannerButton(banner: IDataDesktopBannerImage, slideBtns: boolean): HTMLElement {
    let div = document.createElement("div");
    div.classList.add("banner-slides");

    let img = document.createElement("img");
    img.src = banner.ImageURL;
    img.alt = "banner";
    img.classList.add("banner-img");
    img.addEventListener('load', updateBannerSize);

    let divImg = document.createElement("div");
    divImg.classList.add("banner-slide");

    if (slideBtns) {
        let aLeftBtn = document.createElement("a");
        aLeftBtn.classList.add("banner-prev");
        aLeftBtn.onclick = function () { plusBannerSlides(-1); };
        aLeftBtn.innerHTML = '&#10094;';
        divImg.appendChild(aLeftBtn);

        let aRightBtn = document.createElement("a");
        aRightBtn.classList.add("banner-next");
        aRightBtn.onclick = function () { plusBannerSlides(1); };
        aRightBtn.innerHTML = '&#10095;';
        divImg.appendChild(aRightBtn);
    }

    if (banner.CouponID > 0) {
        let divBtn = document.createElement("div");
        divBtn.classList.add("right", "coupon-img-btn");
        let btn = document.createElement("button");
        btn.innerText = Names("Apply");
        btn.setAttribute("ltag", "Apply");
        btn.classList.add(aOLO.Temp.ButtonHoverStyles[aOLO.data.Settings.OLBTN]);
        btn.onclick = function () {
            if (aOLO.Modules.Coupon.IsCouponNotAllowedInFundraiser()) {
                return;
            }

            if (aOLOModules.LoyaltyProvider.onlyOneDiscountAllowed(null))
                return;

            aOLO.Modules.Coupon.CheckCouponHasQualifyingItems(banner.CouponID);
        };
        let cpnNames = aOLO.data.Coupons.find(cpn => cpn.CouponId === banner.CouponID)?.Names;
        if (cpnNames)
            img.alt = `banner coupon ${Common.GetName(cpnNames, aOLO.Temp.languageCode)}`;
        divBtn.appendChild(btn);
        divImg.appendChild(divBtn);
    }
    divImg.appendChild(img);
    div.appendChild(divImg);

    return div;
}

const updateBannerSize = () => {
    const divBanner = document.getElementById("div_banner");
    const divItems = document.getElementById("div_items");
    const imgBanner = Array.from(document.getElementsByClassName("banner-img")) as HTMLImageElement[];
    if (!divItems || !divBanner || !imgBanner)
        return;

    divBanner.style.maxWidth = '0px';
    const maxWidth = getComputedStyle(divItems, null).width;
    divBanner.style.maxWidth = maxWidth;

    let newHeightArray: number[] = [];
    Array.from(imgBanner).forEach(img => {
        img.style.maxWidth = maxWidth;
        img.naturalWidth > parseInt(maxWidth) ?
            newHeightArray.push(img.naturalHeight * parseInt(maxWidth) / img.naturalWidth) :
            newHeightArray.push(img.naturalHeight);
    });
    let maxHeight = Math.max(...newHeightArray);
    divBanner.style.height = `${maxHeight}px`;
};
