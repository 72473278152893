import { initiatePage, setAppUserToken } from "./online-ordering";
import { goBack, setMobileLocation } from "../common";

import '../../css/icons.css';
import '../../css/dialog.css';
import '../../css/grid.css';
import '../../css/default.css';
import '../../css/check-radio.css';
import '../../css/radio-bar.css';
import '../../css/shared/dark-mode.css';
import '../../css/fancy-title.css';
import { Util } from "../utils/util";
import { OnlineOrderingUtil } from "./online-ordering-util";

export function initiateOLOPage(globalvar: any, cultureCode: string): void {
    initiatePage(globalvar, cultureCode);
}

export { setAppUserToken, setMobileLocation, goBack };

if (process.env.NODE_ENV === 'development') {
    (globalThis as any).Util = Util;
    (globalThis as any).OnlineOrderingUtil = OnlineOrderingUtil;
}