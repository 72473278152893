import { EventEmitter } from 'eventemitter3';
import { EventTypes } from '../core/event-bus';
import { IData } from '../interfaces/data.interface';
import { IaOLO } from '../interfaces/aolo.interface';
import { DialogCreators } from '../utils/dialog-creators';
import { Common } from '../common';
import { Util } from '../utils/util';
import { Data } from './data';
import { IOrder } from '../interfaces/order.interface';

export class Order {
    private readonly _eventBus: EventEmitter;
    private readonly _data: Data;
    private readonly _order: IOrder;
    private _selectedStoreKey: string = "";
    private readonly _aOLO: IaOLO;

    constructor(eventBus: EventEmitter, data: Data, aOLO: IaOLO) {
        this._eventBus = eventBus;
        this._data = data;
        this._aOLO = aOLO;
        this._order = this._initiateOrder();
        this._eventBusListeners();
    }

    get order(): IOrder {
        return this._order;
    }

    private readonly _eventBusListeners = (): void => {
        this._eventBus.on(EventTypes.STORE_CHANGED, this._storeChangedAsync);
        this._eventBus.on(EventTypes.ORDER_TYPE_CHANGED, this._orderTypeChanged);
    }

    private readonly _storeChangedAsync = async (storeKey: string): Promise<void> => {
        //Check Cart items and store is still good.
    }

    private readonly _orderTypeChanged = async (orderTypeId: number): Promise<void> => {
        this._order.OrderTypeID = orderTypeId;
    }

    private readonly _initiateOrder = (): IOrder => {
        const register = this._data.getProperty("Settings")?.STID || 0;

        return {
            AddressID: 0,
            Address: null,
            AmountDue: 0,
            BUSDate: null,
            CollectedTax: 0.0,
            Coupons: [],
            Discount: 0.0,
            Distance: 0,
            Donations: { Detail: [] },
            FutureDate: null,
            FutureMinute: -1,
            Fundraiser: null,
            FundraiserID: 0,
            GUID: this._getNewOrderGUID(this._aOLO.storeInfo.StoreX, register.toString(16)),
            IsTaxExempt: false,
            Items: [],
            ItemsCoupons: [],
            ItemsMakeList: [],
            Key: this._getNewOrderKey(register),
            OrderID: 0,
            OrderNo: 0,
            OrderTypeCharge: 0.0,
            OrderTypeSubID: 7, // online
            OrderTypeID: 0,
            OrderStartTime: new Date(),
            OrderEndTime: null,
            OrderTypeSubType: {
                OrderTypeId: 0,
                OrderTypeSubId: 0,
                PriceGroupId: 0,
                Alias: "",
                AskCustomerInfo: false,
                AskCustomerAddress: false,
                DefaultPay: 0,
                EndMinute: 0,
                FutureOrders: false,
                MinAmount: 0,
                Names: [],
                OrderTypeCharge: 0,
                OrderTypeChargeTaxId: 0,
                PausedUntil: null,
                Rate: 0,
                StartMinute: 0,
                TaxTypeId: 0,
                TaxNames: [],
                WaitTime: 0
            },
            Payments: [],
            PrintTime: null,
            PromiseTime: null,
            ServiceCharges: {
                Amount: 0.00,
                Detail: [],
                Tax: 0.0
            },
            StoreID: this._aOLO.storeInfo.StoreID,
            SubTotal: 0.0,
            Tax: 0.0,
            Taxes: [],
            TaxesDetail: [],
            Tip: 0.0,
            Total: 0.0,
            TotalDonation: 0.0,
            TaxInSubTotal: 0.0
        };
    }

    private readonly _getNewOrderGUID = (storeX: string, registerX: string): string => {
        function S4() {
            return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
        }
        let s8 = (storeX + S4() + S4()).substring(0, 8);
        let r4 = (registerX + S4()).substring(0, 4);
        return (s8 + "-" + S4() + "-4" + S4().substring(0, 3) + "-" + r4 + "-" + S4() + S4() + S4()).toLowerCase();
    }

    private readonly _getNewOrderKey = (register: number): string => {
        try {
            let rnd = "7";
            rnd = rnd + Math.random().toString().replace(".", "");
            rnd = Util.Right(rnd, 1, "6");
            return Util.Right(register.toString().trim(), 7, "0") + rnd + Util.Right((parseInt((new Date().getTime() / 10).toString()).toString()), 11, "0");
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("GetNewOrderKey", ex, this._aOLO);
        }
        return "";
    }
}