import './locations.css';

import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { EventEmitter } from 'eventemitter3';
import { EventTypes } from '../../../core/event-bus';
import { Util } from '../../../utils/util';
import { ILoyaltyProvider } from '../../../services/loyalty/interfaces/loyalty-provider.interface';
import { User } from '../../../models/user';
import { IaOLO } from '../../../interfaces/aolo.interface';
import { AddressValidation, LoyaltyProvider, OrderType, Pages, StoreStatusId } from '../../../types/enums';
import { ILocation, IStoreLocation, IStoreLocationHour } from './locations.interface';
import { TwoWayBindingHelper } from '../../../utils/two-way-binding';
import { IUserAddress } from '../../../models/interfaces/user.interface';
import { IAddressFormat, IName } from '../../../interfaces/global.interfaces';
import { AddressService } from '../../../services/address/address-service';
import { ITempAddress } from '../../../interfaces/temp.interface';
import { getSupportedLanguages, Names } from '../../../utils/i18n';
import { Common } from '../../../common';
import { DialogCreators } from '../../../utils/dialog-creators';
import { OrderTypeSelector } from '../../shared/OrderTypeSelector/order-type-selector';
import { Order } from '../../../models/order';

export class Locations {
    private readonly _eventBus: EventEmitter;
    private readonly _loyaltyProvider: ILoyaltyProvider;
    private readonly _user: User;
    private readonly _order: Order;
    private readonly _bindingHelper: TwoWayBindingHelper<ILocation>;
    private readonly _item: ILocation;
    private readonly _aOLO: IaOLO;

    constructor(eventBus: EventEmitter, loyaltyProvider: ILoyaltyProvider, user: User, order: Order, localAolo: IaOLO) {
        this._eventBus = eventBus;
        this._loyaltyProvider = loyaltyProvider;
        this._user = user;
        this._order = order;
        this._aOLO = localAolo;
        this._item = this._initialModel();
        this._bindingHelper = new TwoWayBindingHelper(this._item);
        this._eventBusListeners();
    }

    private readonly _initialModel = (): ILocation => {
        return {
            useSvgIcon: this._aOLO.OloUISettings.showSvgLocationIcons,
            orderTypeId: OrderType.TAKE_OUT,
            locations: [],
            currentLatitude: 0,
            currentLongitude: 0,
            map: null,
            defaultMapZoom: 12,
            markers: [],
            infoWindow: null,
            markerCluster: null,
            selectedStore: "",
            deliveryAddress: null,
            streetAddress: "",
            apartment: "",
            deliveryInstructions: "",
            deliveryClass: null
        };
    }

    public initAsync = async (data: { orderTypeId: OrderType, selectedStoreKey: string, addressId: number | null } | null): Promise<void> => {
        if (data) {
            this._item.orderTypeId = data.orderTypeId;
            this._item.selectedStore = data.selectedStoreKey;
        }

        Util.hideElement("div_locations_store_list");
        Util.showElement("div_locations_store_list_placeholder");

        this._showPage();
        if (this._item.locations.length == 0)
            this._item.locations = await this._fetchStoreListAsync();
        
        await this._renderPageAsync(data ? data.addressId : null);
        this._setTwoWayListeners();
        this._setEventListeners();
    }

    private readonly _showPage = (): void => {
        Util.showComponent("locations");
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.ORDER_TYPE_CHANGED, this._orderTypeChangedAsync);
        this._eventBus.on(EventTypes.DELIVERY_ADDRESS_CHANGED, this._deliveryAddressChanged);
        this._eventBus.on(EventTypes.LOGGED_IN, this._loggedIn);
        this._eventBus.on(EventTypes.LOGGED_OUT, this._loggedOut);
    }

    private readonly _setTwoWayListeners = (): void => {
        this._bindingHelper.bindToInput("streetAddress", `txt_locations_delivery_address`);
        this._bindingHelper.bindToInput("apartment", `txt_locations_delivery_apartment`);
        this._bindingHelper.bindToInput("deliveryInstructions", `txt_locations_delivery_instructions`);
    }

    /**
     * Initializes and renders the page by setting up order types and map location.
     * Retrieves the user's location from cookies or geolocation API, or falls back to a default location if needed.
     */
    private readonly _renderPageAsync = async (addressId: number | null): Promise<void> => {
        this._createOrderTypes();
        this._renderDeliverySection();

        if (this._user.isLoggedIn())
            this._loggedIn()
        else
            this._loggedOut();

        Util.hideElement("div_locations_store_favorites");
        Util.hideElement("div_locations_store_list");

        // Check if current location is set, otherwise try to load location from cookie or geolocation
        if (this._item.currentLatitude === 0 || this._item.currentLongitude === 0) {
            const cookieLocation = Util.getUserLocationFromCookie();

            if (cookieLocation)
                await this._initializeMapWithStores(cookieLocation.lat, cookieLocation.lng);
            else if (!Util.isAppView())
                await this._getLocationFromGeolocationAsync();
            else
                await this._initializeMapWithDefaultLocationAsync();
        } else {
            // If location is already set, initialize the map with it
            await this._initializeMapWithStores(this._item.currentLatitude, this._item.currentLongitude);
        }

        await this._deliveryBoxOnClickAsync(addressId);

        setTimeout(() => {
            if (this._user.isLoggedIn())
                Util.showElement("div_locations_store_favorites");
            else
                Util.showElement("div_locations_store_list");
            Util.hideElement("div_locations_store_list_placeholder");
        }, 1000);
    }

    private readonly _setEventListeners = (): void => {
        Util.setElement("onclick", "btn_locations_order_now", this._startOrderOnClick);
        Util.setElement("onclick", "div_locations_address_form_header", this._newDeliveryBoxOnClickAsync);

        Util.setElement("onclick", "div_locations_store_list_selector_favorite", this._favoriteListSelectorOnClick);
        Util.setElement("onclick", "div_locations_store_list_selector_nearby", this._nearbyListSelectorOnClick);

        this._setLoggedInEventListeners();
    }

    private readonly _setLoggedInEventListeners = (): void => {
        const userAddresses = this._user.getProperty("Addresses") || [];
        for (const address of userAddresses) {
            Util.setElement("onclick", `div_locations_delivery_user_address_${address.AID}`, async () => {
                Util.setElementClass("remove", "div_locations_address_form_content", "open");
                Util.setElementClass("remove", "div_locations_address_form_header", "open");
                await this._deliveryBoxOnClickAsync(address.AID);
            })
        }

        for (const location of this._item.locations) {
            const div = document.getElementById(`div_locations_store_favorite_${location.storeKey}`);
            if (div) {
                div.onclick = (event) => {
                    event.stopPropagation();
                    this._favoriteHeartOnClickAsync(location.storeKey);
                };
            }

            const favoriteDiv = document.getElementById(`div_locations_store_favorite_favorite_${location.storeKey}`);
            if (favoriteDiv) {
                favoriteDiv.onclick = (event) => {
                    event.stopPropagation();
                    this._favoriteHeartOnClickAsync(location.storeKey);
                };
            }
        }
    }

    private readonly _loggedIn = (): void => {
        //Show selector
        this._renderFavoriteStoreList();
        Util.showElement("div_locations_store_list_selector");
        Util.setElementClass("add", "div_locations_store_favorites", "signed-in");
        Util.setElementClass("add", "div_locations_store_list", "signed-in");
        this._favoriteListSelectorOnClick();

        // show Hearts
        const heartDivs = Array.from(document.querySelectorAll(".favorite-section")) as HTMLDivElement[];
        for (const div of heartDivs) {
            Util.showElement(div);
        }

        const userAddresses = this._user.getProperty("Addresses") || [];
        if (userAddresses.length == 0) {
            Util.setElementClass("add", "div_locations_delivery_list_new", "active");
            Util.hideElement("spn_locations_address_form_header_icon");
            Util.setElementClass("add", "div_locations_address_form_header", "open");
            Util.setElementClass("add", "div_locations_address_form_content", "open");
        } else {
            Util.setElementClass("remove", "div_locations_delivery_list_new", "active");
            Util.showElement("spn_locations_address_form_header_icon");
            Util.setElementClass("remove", "div_locations_address_form_header", "open");
            Util.setElementClass("remove", "div_locations_address_form_content", "open");

            // Build user address section
            this._createUserDeliveryAddresses();
        }

        this._setLoggedInEventListeners();
    }

    private readonly _loggedOut = (): void => {
        //Show selector
        Util.hideElement("div_locations_store_list_selector");
        Util.hideElement("div_locations_store_favorites");
        Util.setElementClass("remove", "div_locations_store_favorites", "signed-in");
        Util.showElement("div_locations_store_list");
        Util.setElementClass("remove", "div_locations_store_list", "signed-in");

        const heartDivs = Array.from(document.querySelectorAll(".favorite-section")) as HTMLDivElement[];
        for (const div of heartDivs) {
            Util.hideElement(div);
        }

        // Set New Address as selected
        Util.setElementClass("add", "div_locations_delivery_list_new", "active");
        Util.hideElement("spn_locations_address_form_header_icon");
        Util.setElementClass("add", "div_locations_address_form_header", "open");
        Util.setElementClass("add", "div_locations_address_form_content", "open");

        Util.setElement("innerHTML", "div_locations_delivery_list_user", "");
    }

    private readonly _newDeliveryBoxOnClickAsync = async (): Promise<void> => {
        Util.setElementClass("add", "div_locations_address_form_content", "open");
        Util.setElementClass("add", "div_locations_address_form_header", "open");
        await this._deliveryBoxOnClickAsync(null);
    }

    private readonly _deliveryBoxOnClickAsync = async (addressId: number | null, displayError: boolean = true): Promise<void> => {
        this._clearSelectedAddressClass(addressId);

        if (addressId != null) {
            Util.setElementClass("add", `div_locations_delivery_user_address_${addressId}`, "active");
            Util.setElementClass("remove", "div_locations_address_form_content", "open");

            if (this._item.deliveryClass) {
                const userAddress = this._user.getProperty("Addresses")?.find(x => x.AID === addressId) || null;
                if (userAddress)
                    await this._item.deliveryClass.checkDeliveryAddressAsync(userAddress, displayError);
            }
        }
    }

    private readonly _favoriteListSelectorOnClick = (): void => {
        Util.setElementClass("add", "div_locations_store_list_selector_favorite", "active");
        Util.setElementClass("remove", "div_locations_store_list_selector_nearby", "active");

        Util.showElement("div_locations_store_favorites");
        Util.hideElement("div_locations_store_list");
    }

    private readonly _nearbyListSelectorOnClick = (): void => {
        Util.setElementClass("add", "div_locations_store_list_selector_nearby", "active");
        Util.setElementClass("remove", "div_locations_store_list_selector_favorite", "active");

        Util.hideElement("div_locations_store_favorites");
        Util.showElement("div_locations_store_list");
    }

    private readonly _clearSelectedAddressClass = (addressId: number | null): void => {
        const allAddresses = Array.from(document.querySelectorAll(".user-address")) as HTMLDivElement[];
        for (const address of allAddresses) {
            address.classList.remove("active");
        }

        const div = document.querySelector(".address-form");
        if (div) {
            if (addressId == null || addressId == 0)
                div.classList.add("active");
            else
                div.classList.remove("active");
        }
    }

    private readonly _createOrderTypes = (): void => {
        const orderSelector = new OrderTypeSelector(this._eventBus, "div_locations_order_types", "locations", this._aOLO);
        orderSelector.init(this._item.orderTypeId);

        Util.hideElement("div_locations_order_types_placeholder");
        //this._eventBus.emit(EventTypes.ORDER_TYPE_CHANGED, this._item.orderTypeId);
    }

    /**
     * Attempts to get the user's current location via the browser's geolocation API.
     * If successful, saves the location in a cookie and initializes the map.
     * If unsuccessful, falls back to a default location.
     */
    private readonly _getLocationFromGeolocationAsync = async (): Promise<void> => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                this._saveLocationToCookie(position.coords.latitude, position.coords.longitude);
                await this._initializeMapWithStores(position.coords.latitude, position.coords.longitude);
            },
            async () => {
                await this._initializeMapWithDefaultLocationAsync();
            }
        );
    }

    /**
     * Saves the user's location in a cookie for future access.
     * 
     * @param latitude - The user's latitude.
     * @param longitude - The user's longitude.
     */
    private readonly _saveLocationToCookie = (latitude: number, longitude: number): void => {
        const locationData = JSON.stringify({ lat: latitude, lng: longitude });
        const domain = window.location.hostname.split('.').reverse().slice(0, 2).reverse().join('.');
        document.cookie = `locationData=${locationData};max-age=3600;path=/;domain=${domain}`;
    }

    /**
     * Initializes the map with a default location based on the user's country code.
     * 
     * @param countryCode - Optional ISO country code (e.g., "US") to determine the default location.
     */
    private readonly _initializeMapWithDefaultLocationAsync = async (countryCode: string = "US"): Promise<void> => {
        const defaultLocation = this._getDefaultUserCoordinates(countryCode);
        await this._initializeMapWithStores(defaultLocation.lat, defaultLocation.lng);
    }

    private readonly _orderTypeChangedAsync = async (orderTypeId: OrderType): Promise<void> => {
        this._item.orderTypeId = orderTypeId;
        await this._createMapMarkersAndMarkerClusterAsync();
        this._renderStoreList();
        this._renderFavoriteStoreList();
        this._mapShowLocations();

        if (orderTypeId !== OrderType.DELIVERY) {
            Util.hideElement("div_locations_delivery_list");
            Util.showElement("div_locations_non_delivery_list");
            Util.setElement("disabled", "btn_locations_order_now", true);
            Util.setElementClass("remove", "div_main_locations", "delivery");
        } else {
            const primaryUserAddress = (this._user.getProperty("Addresses") || []).find(x => x.IPRM);
            const addressId = (primaryUserAddress ? primaryUserAddress.AID : null);
            await this._deliveryBoxOnClickAsync(addressId, false);
            Util.showElement("div_locations_delivery_list");
            Util.hideElement("div_locations_non_delivery_list");
            Util.setElementClass("add", "div_main_locations", "delivery");
        }

        this._setLoggedInEventListeners();
    }

    private readonly _renderDeliverySection = (): void => {
        if (this._user.isLoggedIn())
            this._createUserDeliveryAddresses();

        this._createDeliveryAddressComponent();
    }

    private readonly _createUserDeliveryAddresses = (): void => {
        let cardHtmls = "";
        const addresses = this._user.getProperty("Addresses") || [];
        for (const address of addresses) {
            const card = this._userAddressCard(address);
            cardHtmls += card;
        }
        Util.setElement("innerHTML", "div_locations_delivery_list_user", cardHtmls);
    }

    private readonly _userAddressCard = (address: IUserAddress): string => {
        const tempAddress: IAddressFormat = {
            StreetNo: address.STRNO,
            Address1: address.ADDR1,
            Address2: address.ADDR2,
            Address3: address.ADDR3,
            Address4: address.ADDR4,
            Address5: address.ADDR5,
            City: address.CITY,
            State: address.STA,
            ZipCode: address.ZIP,
            CountryID: address.CID || 1,
            AddressTypeID: 0
        };

        const addressObject = Util.formatAddressObject(tempAddress, this._aOLO.data.Countries);
        const html = `
            <div id="div_locations_delivery_user_address_${address.AID}" class="user-address">
                <div>
                    <h4>${addressObject.address1}</h4>

                    ${addressObject.address3 ? `<p>${addressObject.address3}</p>` : ""}
                    <p>${addressObject.cityState}</p>
                </div>
            </div>`;
        return html;
    }

    private readonly _createDeliveryAddressComponent = (): void => {
        this._item.deliveryClass = new AddressService("txt_locations_delivery_address", "dl_locations_delivery_address", "txt_locations_delivery_apartment", this._item.locations, this._aOLO, this._deliveryAddressChanged);
        this._item.deliveryClass.init();
    }

    private readonly _deliveryAddressChanged = (status: AddressValidation, storeKey: string, address: ITempAddress | null): void => {
        switch (status) {
            case AddressValidation.VALID:
                this._deliveryAddressChangedSuccess(storeKey, address);
                break;
            case AddressValidation.INVALID:
            case AddressValidation.OUT_OF_DELIVERY_ZONE:
            case AddressValidation.OUT_OF_TIME:
            case AddressValidation.INVALID_NO_ZIP:
            case AddressValidation.BLOCKED:
            case AddressValidation.INVALID_ST_NUMBER:
            case AddressValidation.INVALID_ST_NAME:
            case AddressValidation.INVALID_NO_STREET_NUMBER:
            default:
                this._deliveryAddressChangedFailure(address);
                break;
        }
    }

    private readonly _deliveryAddressChangedSuccess = (storeKey: string, address: ITempAddress | null): void => {
        this._item.selectedStore = storeKey;
        this._item.deliveryAddress = address;
        this._order.order.Address = address;
        const store = this._item.locations.find(x => x.storeKey == storeKey);
        if (store)
            this._moveToStoreLocationOnMap(store);
        Util.setElement("disabled", "btn_locations_order_now", false);
    }

    private readonly _deliveryAddressChangedFailure = (address: ITempAddress | null): void => {
        this._clearSelectedAddressClass(address ? address.AddressID : null);
        this._item.selectedStore = "";
        this._item.deliveryAddress = null;
        this._order.order.Address = null;
        Util.setElement("disabled", "btn_locations_order_now", true);
    }

    private readonly _fetchStoreListAsync = async (): Promise<IStoreLocation[]> => {
        const locations = await this._aOLO.Modules.ProfileService.loadLocations(this._aOLO.storeInfo.BrandID, this._aOLO);
        return locations;
    }

    /**
     * Initializes the map with the user's position and renders nearby stores.
     * 
     * @param latitude - The user's latitude.
     * @param longitude - The user's longitude.
     */
    private readonly _initializeMapWithStores = async (latitude: number, longitude: number): Promise<void> => {
        await this._updateUserPositionAndStoreList(latitude, longitude);
        this._centerMapOnUser();
        if (this._item.map)
            this._item.map.setZoom(this._item.defaultMapZoom);
    }

    private readonly _startOrderOnClick = (): void => {
        this._eventBus.emit(EventTypes.LOCATION_CHANGE, Pages.MENU);
        this._eventBus.emit(EventTypes.STORE_CHANGED, this._item.selectedStore);
    }

    /**
     * Updates the user's current position, calculates distances to stores, and lists stores.
     * Loads the map if it hasn't been initialized.
     * 
     * @param latitude - The user's latitude.
     * @param longitude - The user's longitude.
     */
    private readonly _updateUserPositionAndStoreList = async (latitude: number, longitude: number): Promise<void> => {
        this._item.currentLatitude = latitude;
        this._item.currentLongitude = longitude;
        await this._calculateStoreDistancesAsync();

        this._renderStoreList();

        // Load Google Map if not already initialized
        if (!this._item.map)
            await this._initializeGoogleMap();
    }

    /**
     * Initializes and loads the Google Map with store locations, markers, clusters, and search functionality.
     */
    private readonly _initializeGoogleMap = async (): Promise<void> => {
        // Initialize the map
        await this._initializeMapAsync();

        this._createMapMarkersAndMarkerClusterAsync();

        // Set up the map's search box for finding locations
        this._initializeSearchBox();
    }

    /**
     * Initializes the Google Map and sets up default map settings.
     */
    private readonly _initializeMapAsync = async (): Promise<void> => {
        const mapDiv = document.getElementById("div_locations_map");
        if (!mapDiv)
            return;

        const { Map } = await (google.maps as any).importLibrary("maps") as google.maps.MapsLibrary;

        this._item.map = new Map(mapDiv, {
            zoom: aOLO.Temp.LocationPermissionDenied ? 4 : this._item.defaultMapZoom,
            mapId: 'Stores_Location_Map',
            gestureHandling: 'greedy',
            scaleControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            disableDefaultUI: true
        });

        // Add listener to update visible locations when map becomes idle
        this._item.map.addListener('idle', () => {
            this._mapShowLocations();
        });
    };

    private readonly _createMapMarkersAndMarkerClusterAsync = async (): Promise<void> => {
        const { AdvancedMarkerElement, PinElement } = await (google.maps as any).importLibrary("marker") as google.maps.MarkerLibrary;

        // Create markers for each store location
        this._createStoreMarkers(AdvancedMarkerElement, PinElement);

        // Initialize marker clustering
        this._initializeMarkerCluster();
    }

    /**
     * Creates markers for each store location and attaches them to the map.
     * 
     * @param AdvancedMarkerElement - The AdvancedMarkerElement class from Google Maps marker library.
     * @param PinElement - The PinElement class used to display store index on markers.
     */
    private readonly _createStoreMarkers = (AdvancedMarkerElement: any, PinElement: any): void => {
        // Clear existing markers
        this._item.markers.forEach((marker: any) => {
            marker.setMap(null);
        });

        // Reset the markers array
        this._item.markers = [];

        // Create new markers
        let storeIndex = 0;
        this._item.markers = this._item.locations.map(store => {
            storeIndex++;
            const displayIndex = storeIndex > 99 ? '99+' : storeIndex.toString();
            const orderTypeOffered = store.orderTypes.includes(this._item.orderTypeId);

            const storePosition = {
                lat: store.latitude,
                lng: store.longitude,
            };

            let markerContent: HTMLElement | undefined;

            if (this._item.useSvgIcon) {
                // Use custom SVG icon
                const url = orderTypeOffered ? `${this._aOLO.PublicStorageUrl}/online3/svg/location_pinpoint.svg` : `${this._aOLO.PublicStorageUrl}/online3/svg/location_pinpoint_disabled.svg`;
                const img = document.createElement("img");
                img.src = url;
                img.style.width = "30px";
                img.style.height = "30px";
                markerContent = img;
            } else {
                // Use numbered pin as default
                const color = orderTypeOffered ? "#EA4335" : "grey";
                const pinGlyph = new PinElement({
                    glyph: displayIndex,
                    glyphColor: "white",
                    background: color,
                    borderColor: color
                });
                markerContent = pinGlyph.element;
            }

            const marker = new AdvancedMarkerElement({
                position: storePosition,
                content: markerContent
            });

            marker.storeKey = store.storeKey;

            const infoWindowContent = this._createInfoWindowHtml(store);
            const infoWindow = new google.maps.InfoWindow({
                content: infoWindowContent,
            });

            // Attach click event to start an order for this store
            marker.addListener("click", () => {
                infoWindow.open(this._item.map, marker);
            });

            return marker;
        });
    }

    /**
     * Creates an HTML string for an InfoWindow displaying store details, such as name, address, phone, and hours.
     * @param {IStoreLocation} store - The store location object containing details about the store.
     * @returns {string} HTML string representing the content of the InfoWindow.
     */
    private readonly _createInfoWindowHtml = (store: IStoreLocation): string => {
        const addressObject = this._formatAddress(store);
        const phone = this._formatPhone(store.phone, store.countryId || 1);
        const hoursHtml = this._generateHoursHtml(store.hours);

        const contentString = `
            <div class="locations-info-window">
                <h2>${store.name}</h2>
                <p>
                    <strong>${Names("Address")}:</strong><br>
                    ${addressObject.address1},<br>
                    ${addressObject.address3 ? `${addressObject.address3}<br>,` : ""}
                    ${addressObject.cityState}
                </p>
                <p>
                    <strong>${Names("Phone")}:</strong><br>
                    ${phone}
                </p>

                <div>
                    <h3>${Names("StoreHours")}</h3>
                    <ul>
                        ${hoursHtml}
                    </ul>
                </div>
            </div>`;

        return contentString;
    }

    /**
     * Formats a phone number based on the country ID.
     * @param {string} phone - The phone number to format.
     * @param {number} countryId - The ID of the country for formatting.
     * @returns {string} The formatted phone number.
     */
    private readonly _formatPhone = (phone: string, countryId: number): string => {
        const phoneFormatLanguage = Util.getDefaultCultureCode(countryId, this._aOLO.data.Countries);
        return Util.formatPhoneNumber(phone, Names("PhoneFormat", phoneFormatLanguage));
    };

    /**
     * Generates an HTML string for the store's hours of operation for the current week.
     * @param {IStoreLocationHour[]} hours - Array of store hours for each weekday.
     * @returns {string} HTML string representing the hours of operation.
     */
    private readonly _generateHoursHtml = (hours: IStoreLocationHour[]): string => {
        const currentWeekday = new Date().getDay();
        let hoursHtml = "";

        for (let i = currentWeekday; i < currentWeekday + 7; i++) {
            const weekdayId = i % 7;
            const dayHours = hours.find(hour => hour.weekdayId === weekdayId);
            const hoursText = dayHours
                ? `${Util.formatTimeByIsoCode(dayHours.startMinute, this._aOLO.Temp.languageCode)} - ${Util.formatTimeByIsoCode(dayHours.endMinute, this._aOLO.Temp.languageCode)}`
                : Names("Closed");

            const weekdayName = Util.getWeekdayNameById(weekdayId);
            hoursHtml += `
            <li>
                <span>${Names(weekdayName)}:</span> <span>${hoursText}</span>
            </li>`;
        }

        return hoursHtml;
    };

    /**
     * Initializes marker clustering on the map to group nearby markers.
     */
    private readonly _initializeMarkerCluster = (): void => {
        const markers = this._item.markers;
        const map = this._item.map;

        // Create a marker clusterer to manage clusters of markers
        if (this._item.markerCluster)
            this._item.markerCluster.clearMarkers();
        this._item.markerCluster = new MarkerClusterer({ map, markers });
    };

    /**
     * Initializes the search box to allow users to search for specific locations.
     * Adjusts the map to show the search result and re-calculates distances.
     */
    private readonly _initializeSearchBox = (): void => {
        const input = document.getElementById("txt_locations_search_box") as HTMLInputElement;
        const searchBox = new (google.maps as any).places.SearchBox(input);

        // Bias search results toward current map viewport
        if (this._item.map)
            this._item.map.addListener("bounds_changed", () => {
                if (this._item.map)
                    searchBox.setBounds(this._item.map.getBounds());
            });

        // Handle places changed event to update map and location details
        searchBox.addListener("places_changed", async () => {
            const places = searchBox.getPlaces();
            if (places.length === 0)
                return;

            const bounds = new google.maps.LatLngBounds();
            bounds.extend(places[0].geometry.location);

            // Update current location coordinates based on the selected place
            this._item.currentLatitude = places[0].geometry.location.lat();
            this._item.currentLongitude = places[0].geometry.location.lng();
            await this._calculateStoreDistancesAsync();

            // Re-center the map to the new location
            if (this._item.map) {
                this._item.map.fitBounds(bounds);
                this._item.map.setZoom(this._item.defaultMapZoom);
            }
        });
    };

    /**
     * Displays store locations within the current map bounds. Hides locations outside the bounds.
     * If no stores are visible, zooms out the map to include stores. 
     */
    private _mapShowLocations(): void {
        if (!this._item.map)
            return;

        const bounds = this._item.map.getBounds();
        let visibleStoreCount = 0;

        // Show or hide markers based on their presence within the map bounds 
        for (const marker of this._item.markers) {
            const isWithinBounds = bounds?.contains(marker.position) || false;
            const storeElementId = `div_locations_store_${marker.storeKey}`;

            if (isWithinBounds) {
                Util.showElement(storeElementId);
                visibleStoreCount++;
            } else {
                Util.hideElement(storeElementId);
                if (this._item.selectedStore == marker.storeKey) {
                    this._item.selectedStore = "";
                    Util.setElementClass("remove", `div_locations_store_${marker.storeKey}`, "active");
                    Util.setElement("disabled", "btn_locations_order_now", true);
                }
            }
        }

        // If no stores are visible, zoom out to expand the visible area 
        this._adjustZoomIfNoStoresVisible(visibleStoreCount);
    }

    /**
     * Adjusts the map zoom level if no stores are visible within the current bounds.
     * @param visibleStoreCount - The count of stores currently visible within map bounds. 
     */
    private _adjustZoomIfNoStoresVisible(visibleStoreCount: number): void {
        if (visibleStoreCount !== 0)
            return;

        const currentZoom = this._item.map?.getZoom() || 2;
        const minZoomLevel = 4;

        if (this._item.map && currentZoom > minZoomLevel)
            this._item.map.setZoom(currentZoom - 2);
    }

    /**
     * Updates the distances for each location, sorts locations by distance,
     * and updates relevant UI elements and markers.
     */
    private readonly _calculateStoreDistancesAsync = async (): Promise<void> => {
        // Check if current latitude and longitude are set
        if (this._item.currentLatitude === 0 || this._item.currentLongitude === 0)
            return;

        // Calculate and update distance for each location
        for (const location of this._item.locations) {
            location.distance = Util.Float2(
                Util.GetDistance(this._item.currentLatitude, this._item.currentLongitude, location.latitude, location.longitude, "M")
            );
        }

        // Sort locations by distance
        this._item.locations.sort((a: IStoreLocation, b: IStoreLocation) => a.distance - b.distance);

        // Update UI elements for each location
        for (const [index, location] of this._item.locations.entries()) {
            // Update the distance display for each location
            const distanceSpan = document.getElementById(`spn_locations_store_distance_${location.storeKey}`);
            if (distanceSpan) {
                const distanceText = this._createDistanceText(location.distance);
                distanceSpan.innerText = distanceText.text;
                distanceSpan.setAttribute("ltagj", distanceText.ltagj);
            }

            if (this._item.useSvgIcon)
                continue;

            const locationIndex = index + 1;
            const locationDisplayIndex = locationIndex > 99 ? '99+' : `${locationIndex}`;

            // Update the index display for each store
            const storeIndexDiv = document.getElementById(`div_locations_store_index_${location.storeKey}`);
            if (storeIndexDiv)
                storeIndexDiv.innerText = locationDisplayIndex;

            // Update the marker with the display index if markers are available
            await this._updateMarkerContentAsync(location.storeKey, locationDisplayIndex);
        }
    }

    /**
    * Updates the marker content for a specific store by key.
    *
    * @param storeKey - The unique key identifying the store location
    * @param displayIndex - The display index to show on the marker (e.g., '1', '2', '99+')
    */
    private readonly _updateMarkerContentAsync = async (storeKey: string, displayIndex: string): Promise<void> => {
        if (!this._item.markers)
            return;

        const marker = this._item.markers.find((marker: any) => marker.storeKey === storeKey);
        if (!marker)
            return;

        const { PinElement } = await (google.maps as any).importLibrary("marker") as google.maps.MarkerLibrary;
        const pinGlyph = new PinElement({
            glyph: displayIndex,
            glyphColor: "white",
        });
        marker.content = pinGlyph.element;
    }

    /**
     * Renders the list of stores based on the user's favorite stores.
     * Updates the UI by attaching store cards to the store list container and adding event listeners.
     * 
     * @param favorites - Array of favorite stores with store keys and names.
     */
    private readonly _renderStoreList = (): void => {
        const storeListContainer = document.getElementById("div_locations_store_list");
        if (!storeListContainer)
            return;

        storeListContainer.innerHTML = "";

        // Generate and append store cards to the container
        const stores = this._generateStoreCards();
        for (const store of stores) {
            storeListContainer.appendChild(store);
        }
    }

    private readonly _renderFavoriteStoreList = (): void => {
        const storeFavoriteListContainer = document.getElementById("div_locations_store_favorites");
        if (!storeFavoriteListContainer)
            return;

        storeFavoriteListContainer.innerHTML = "";

        // Generate and append store cards to the container
        const stores = this._generateFavoriteStoreCards();
        for (const store of stores) {
            storeFavoriteListContainer.appendChild(store);
        }
    }

    /**
     * 
     * Generates a list of store cards (HTML elements) based on store data and favorites.
     * Sorts stores by favorite status and distance from the user's location.
     *
     * @param favorites - An array of favorite stores with store keys and names, or null if no favorites.
     * @returns An array of HTML elements representing the store cards.
     */
    private readonly _generateStoreCards = (): HTMLElement[] => {
        const storeCards: HTMLElement[] = [];
        const isBahamas = Common.isBahamas(this._item.currentLongitude, this._item.currentLatitude) ? "HARDCODEDBH" : null;

        // Retrieve the list of stores, optionally filtered for Bahamas
        const storeList = this._filterStores(isBahamas);

        // Generate store cards for open stores only
        let storeIndex = 0;
        for (const store of storeList) {
            storeIndex++;
            storeCards.push(this._createStoreCardElement(store, storeIndex, false));
        }

        return storeCards;
    }

    private readonly _generateFavoriteStoreCards = (): HTMLElement[] => {
        const storeCards: HTMLElement[] = [];
        const isBahamas = Common.isBahamas(this._item.currentLongitude, this._item.currentLatitude) ? "HARDCODEDBH" : null;

        // Retrieve the list of stores, optionally filtered for Bahamas
        const storeKeys = this._user.getProperty("FavoriteStores") || [];
        let storeList = this._filterStores(isBahamas);
        storeList = storeList.filter(x => storeKeys.includes(x.storeKey));

        // Generate store cards for open stores only
        let storeIndex = 0;
        for (const store of storeList) {
            storeIndex++;
            storeCards.push(this._createStoreCardElement(store, storeIndex, true));
        }

        return storeCards;
    }

    /**
     * Filters the list of stores based on a search term or location-specific criteria.
     * If searching for Bahamas, only returns stores in Bahamas; otherwise, filters by search terms.
     *
     * @param search - A search string or a special code (e.g., "HARDCODEDBH" for Bahamas filtering).
     * @returns An array of locations matching the search criteria.
     */
    private readonly _filterStores = (search: string | null): IStoreLocation[] => {
        // Filter specifically for stores located in Bahamas
        if (search === 'HARDCODEDBH')
            return this._item.locations.filter(store => store.state === "BH");

        // If no search term is provided, return all locations
        if (!search)
            return this._item.locations;

        // Filter locations based on multiple search terms
        const searchList = search.toLowerCase().split(' ');
        return this._item.locations.filter(location => {
            return (
                searchList.every(term =>
                    location.address1.toLowerCase().includes(term) ||
                    location.address2?.toLowerCase().includes(term) ||
                    location.name.toLowerCase().includes(term) ||
                    location.city.toLowerCase().includes(term) ||
                    location.state.toLowerCase().includes(term) ||
                    location.fullState?.toLowerCase().includes(term) ||
                    location.zip.toLowerCase().includes(term)
                )
            );
        });
    }

    /**
     * Creates an HTML element for displaying a store card with status, address, and distance.
     * @param {IStoreLocation} store - The store location data.
     * @param {number} index - The index of the store in the list, used for marker numbering.
     * @returns {HTMLElement} The HTML element representing the store card.
     */
    private readonly _createStoreCardElement = (store: IStoreLocation, index: number, isFavorite: boolean): HTMLElement => {
        const orderTypeOffered = store.orderTypes.includes(this._item.orderTypeId);
        const orderType = this._aOLO.data.OrderTypeSubTypes.find(x => x.OrderTypeId === this._item.orderTypeId);
        const orderTypeName = orderType ? Common.GetName(orderType.Names, this._aOLO.Temp.languageCode) : "Undefined";

        const storeStatusHtml = this._getStoreStatusHtml(store.statusId);
        const numberedIconHtml = this._getNumberedIconHtml(index, store.storeKey);
        const addressObject = this._formatAddress(store);
        const distanceSpanHtml = this._getDistanceHtml(store.distance, store.storeKey);
        const hoursInfoHtml = this._getHoursHtml(store.hours, store.storeKey);
        const favoriteHtml = this._getFavoriteSectionHtml(store.storeKey, isFavorite);

        const id = isFavorite ? `div_locations_store_favorite_${store.storeKey}` : `div_locations_store_${store.storeKey}`;

        const storeItemHtml = `
        <div id="${id}" class="store-address" ${orderTypeOffered ? "" : "disabled"}>
            <div>
                ${numberedIconHtml}
                <div class="store-section">
                    <h4>${addressObject.address1}</h4>
                    ${addressObject.address3 ? `<p>${addressObject.address3}</p>` : ""}
                    <p>${addressObject.cityState}</p>
                    <p>${distanceSpanHtml} &#8226; ${hoursInfoHtml}</p>
                    ${storeStatusHtml}
                </div>
                ${favoriteHtml}
            </div>
            ${orderTypeOffered ? "" : `<p class="store-error">${Names("OrderTypeNotOfferedAtThisLocation").replace("{{order_type}}", orderTypeName)}</p>`}
        </div>`;

        const div = Util.createHtmlElementFromTemplate(storeItemHtml);
        div.onclick = () => this._handleStoreClick(store, div);
        return div;
    }

    /**
     * Generates the HTML for displaying the store status based on its status ID.
     * @param {StoreStatusId} statusId - The store status ID.
     * @returns {string} HTML representing the store status.
     */
    private readonly _getStoreStatusHtml = (statusId: StoreStatusId): string => {
        const statusMap = {
            [StoreStatusId.ACTIVE]: "",
            [StoreStatusId.TEMPORARILY_CLOSED]: "TemporarilyClosed",
            [StoreStatusId.CLOSED]: "",
            [StoreStatusId.INACTIVE]: "Inactive",
            [StoreStatusId.COMING_SOON]: "ComingSoon",
        };
        const statusKey = statusMap[statusId];
        return statusKey ? `<h3 ltag="${statusKey}">${Names(statusKey)}</h3>` : "";
    };

    /**
     * Generates the numbered icon HTML based on index and store key.
     * @param {number} index - The index of the store.
     * @param {string} storeKey - The unique key of the store.
     * @returns {string} HTML representing the numbered icon or an empty string if using SVG icons.
     */
    private readonly _getNumberedIconHtml = (index: number, storeKey: string): string => {
        if (this._item.useSvgIcon)
            return "";

        const content = index > 99 ? '99+' : index;

        //<div class="icon-pin" style="width: 26px; height: 38px; fill: var(--btnColorBg);"></div>
        return `
        <div class="location-store-marker">
            <svg xmlns="http://www.w3.org/2000/svg" width="26px" height="38px" viewBox="0 0 26 37" fill="var(--btnColorBg)"><g><path d="M13 0C5.8175 0 0 5.77328 0 12.9181C0 20.5733 5.59 23.444 9.55499 30.0784C12.09 34.3207 11.3425 37 13 37C14.7225 37 13.975 34.2569 16.445 30.1422C20.085 23.8586 26 20.6052 26 12.9181C26 5.77328 20.1825 0 13 0Z"></path><path d="M13.0167 35C12.7836 35 12.7171 34.9346 12.3176 33.725C11.9848 32.6789 11.4854 31.0769 10.1873 29.1154C8.92233 27.1866 7.59085 25.6173 6.32594 24.1135C3.36339 20.5174 1 17.7057 1 12.6385C1.03329 6.19808 6.39251 1 13.0167 1C19.6408 1 25 6.23078 25 12.6385C25 17.7057 22.6699 20.55 19.6741 24.1462C18.4425 25.65 17.1443 27.2193 15.8793 29.1154C14.6144 31.0442 14.0818 32.6135 13.749 33.6596C13.3495 34.9346 13.2497 35 13.0167 35Z"></path></g></svg>
            <div id="div_locations_store_index_${storeKey}" class="location-store-marker-content">${content}</div>
        </div>`;
    };

    private readonly _getFavoriteSectionHtml = (storeKey: string, isFavorite: boolean): string => {
        if (!this._user.isLoggedIn() || this._loyaltyProvider.getLoyaltyProvider() === LoyaltyProvider.PUNCHH)
            return "";

        const isFavorited = this._user.getProperty("FavoriteStores")?.includes(storeKey) || false;
        const favoriteId = isFavorite ? `div_locations_store_favorite_favorite_${storeKey}` : `div_locations_store_favorite_${storeKey}`;

        return `
            <div id="${favoriteId}" class="favorite-section">
                <span id="spn_locations_store_favorite_${storeKey}" class="${isFavorited ? "icon-heart-filled" : "icon-favorites"}"></span>
            </div>`;
    };

    /**
     * Formats the store's address for display.
     * @param {IStoreLocation} store - The store location data.
     * @returns {IAddressFormat} The formatted address.
     */
    private readonly _formatAddress = (store: IStoreLocation): { address1: string, address2: string, address3: string, cityState: string, country: string } => {
        return Util.formatAddressObject({
            StreetNo: store.streetNumber,
            Address1: store.address1,
            Address2: store.address2,
            Address3: store.address3,
            Address4: store.address4,
            Address5: store.address5,
            City: store.city,
            State: store.state,
            ZipCode: store.zip,
            CountryID: store.countryId || 1,
            AddressTypeID: 0
        }, this._aOLO.data.Countries);
    };

    /**
     * Creates HTML for displaying the store distance.
     * @param {number} distance - The distance to the store.
     * @param {string} storeKey - The unique key of the store.
     * @returns {string} HTML representing the store distance.
     */
    private readonly _getDistanceHtml = (distance: number, storeKey: string): string => {
        const distanceText = this._createDistanceText(distance);
        return `<span id="spn_locations_store_distance_${storeKey}" ltagj="${distanceText.ltagj}">${distanceText.text}</span>`;
    };

    /**
     * Generates the HTML for displaying the store hours information.
     * @param {IStoreLocationHour[]} hours - The store's operating hours.
     * @param {string} storeKey - The unique key of the store.
     * @returns {string} HTML representing the store hours information.
     */
    private readonly _getHoursHtml = (hours: IStoreLocationHour[], storeKey: string): string => {
        const hoursInfo = this._createHoursText(hours);
        return `<span id="spn_locations_store_status_${storeKey}" ltagj="${hoursInfo.ltagj}">${hoursInfo.text}</span>`;
    };

    /**
     * Handles the click event for a store element, setting it as active and centering on the map.
     * @param {IStoreLocation} store - The store location data.
     * @param {HTMLElement} div - The store element that was clicked.
     */
    private readonly _handleStoreClick = (store: IStoreLocation, div: HTMLElement): void => {
        const storeCards = Array.from(document.querySelectorAll(".store-address"));
        for (const div of storeCards) {
            div.classList.remove("active");
        }

        this._item.selectedStore = store.storeKey;
        div.classList.add("active");
        Util.setElement("disabled", "btn_locations_order_now", false);

        this._moveToStoreLocationOnMap(store);
    };

    private readonly _moveToStoreLocationOnMap = (store: IStoreLocation): void => {
        const location = { lat: store.latitude, lng: store.longitude, type: "User" };

        if (this._item.map) {
            this._item.map.panTo(location);
            this._item.map.setZoom(12);
        }
    }

    /**
     * Creates a formatted text for store distance based on units of measurement and localizations.
     * @param {number} distance - The distance to the store.
     * @returns {{ text: string, ltagj: string }} An object containing the localized distance text and its language tag (ltagj).
     */
    private readonly _createDistanceText = (distance: number): { text: string, ltagj: string } => {
        const isMetric = this._aOLO.storeInfo.Country.UnitsOfMeasurement === "Metric";
        const formattedDistance = isMetric ? Util.Float2(Util.convertMilesToKilometers(distance)) : distance;
        const units = this._getDistanceUnits(formattedDistance, isMetric);

        const languages = getSupportedLanguages();
        const nameObj: IName[] = languages.map(lang => {
            const tempText = Names("StoreDistanceAway", lang)
                .replace("{distance}", `${formattedDistance}`)
                .replace("{type}", Names(units, lang));
            return { CULT: lang, NAM: tempText };
        });

        const text = Names("StoreDistanceAway")
            .replace("{distance}", `${formattedDistance}`)
            .replace("{type}", Names(units));
        return { text, ltagj: Util.toLtagj(nameObj) };
    }

    /**
     * Determines the appropriate unit label for the distance based on measurement system.
     * @param {number} distance - The distance value.
     * @param {boolean} isMetric - Whether the metric system is used.
     * @returns {string} The unit label ("mile", "miles", "kilometer", "kilometers").
     */
    private readonly _getDistanceUnits = (distance: number, isMetric: boolean): string => {
        if (isMetric)
            return distance === 1 ? "kilometer" : "kilometers";

        return distance === 1 ? "mile" : "miles";
    };

    /**
     * Creates a formatted text for store hours based on current time and store hours.
     * @param {IStoreLocationHour[]} hours - The store's operating hours.
     * @returns {{ text: string, ltagj: string }} An object containing the localized hours text and its language tag (ltagj).
     */
    private readonly _createHoursText = (hours: IStoreLocationHour[]): { text: string, ltagj: string } => {
        const { text, time, weekday } = this._getHoursInfo(hours);

        const languages = getSupportedLanguages();
        const nameObj: IName[] = languages.map(lang => {
            const tempText = Names(text, lang)
                .replace("{time}", time)
                .replace("{weekday}", Names(weekday, lang));
            return { CULT: lang, NAM: tempText };
        });

        const displayText = Names(text)
            .replace("{time}", time)
            .replace("{weekday}", Names(weekday));
        return { text: displayText, ltagj: Util.toLtagj(nameObj) };
    }

    /**
     * Determines the store's current status (open, closed, or opening soon) and provides the relevant text.
     * @param {IStoreLocationHour[]} hours - The store's operating hours.
     * @returns {{ text: string, time: string, weekday: string }} An object with text, time, and weekday for the store's hours status.
     */
    private readonly _getHoursInfo = (hours: IStoreLocationHour[]): { text: string, time: string, weekday: string } => {
        const currentDay = new Date().getDay();
        const currentTimeInMinutes = new Date().getHours() * 60 + new Date().getMinutes();
        const todayHours = hours.find(h => h.weekdayId === currentDay);

        if (todayHours) {
            if (currentTimeInMinutes < todayHours.startMinute) {
                // Store will open later today
                return { text: "OpensAt", time: Util.formatTimeByIsoCode(todayHours.startMinute, this._aOLO.Temp.languageCode), weekday: "" };
            } else if (currentTimeInMinutes < todayHours.endMinute) {
                // Store is currently open
                return { text: "OpenUntil", time: Util.formatTimeByIsoCode(todayHours.endMinute, this._aOLO.Temp.languageCode), weekday: "" };
            }
        }

        // Store is closed, find the next opening day
        for (let i = 1; i <= 7; i++) {
            const nextDay = (currentDay + i) % 7;
            const nextDayHours = hours.find(h => h.weekdayId === nextDay);
            if (nextDayHours) {
                return {
                    text: "ClosedOpensAt",
                    time: Util.formatTimeByIsoCode(nextDayHours.startMinute, this._aOLO.Temp.languageCode),
                    weekday: Util.getWeekdayNameById(nextDay)
                };
            }
        }

        return { text: "Closed", time: "", weekday: "" };
    };

    /**
     * Centers the map on the user's current location.
     * If the user's location is not set, it defaults to a central position.
     */
    private readonly _centerMapOnUser = (): void => {
        if (!this._item.map)
            return;

        const defaultLocation = this._getDefaultUserCoordinates("US");
        const userLocation = {
            lat: this._item.currentLatitude || defaultLocation.lat,
            lng: this._item.currentLongitude || defaultLocation.lng,
        };

        this._item.map.panTo(userLocation);
    }

    private readonly _getDefaultUserCoordinates = (country: string): { lat: number, lng: number } => {
        // 2DO: Use Brand's country to center to proper country
        switch (country) {
            case 'MX':
                return { lat: 19.4326, lng: -99.1332 }; // Mexico City, Mexico
            case 'CN':
                return { lat: 39.9042, lng: 116.4074 }; // Beijing, China
            case 'US':
            default:
                return { lat: 39.8283, lng: -98.5795 }; // Center of the United States
        }
    }

    private readonly _favoriteHeartOnClickAsync = async (storeKey: string): Promise<void> => {
        const isFavorited = this._user.getProperty("FavoriteStores")?.includes(storeKey) || false;
        if (isFavorited)
            await this._deleteFavoriteStore(storeKey);
        else
            await this._saveFavoriteStore(storeKey);

        this._renderFavoriteStoreList();
    }

    private readonly _saveFavoriteStore = async (storeKey: string): Promise<void> => {
        const result = await this._user.saveFavoriteStore(storeKey);
        if (result) {
            Util.setElementClass("add", `spn_locations_store_favorite_${storeKey}`, "icon-heart-filled");
            Util.setElementClass("remove", `spn_locations_store_favorite_${storeKey}`, "icon-favorites");
        } else
            DialogCreators.messageBoxOk(Names("favoriteStoreSaveName_Error"), this._aOLO.buttonHoverStyle);
    }

    /**
     * Deletes a store from the user's favorites.
     * @private
     * @async
     * @param {string} storeKey - The unique key for the store to delete.
     * @returns {Promise<void>} A promise that resolves with no value.
     */
    private readonly _deleteFavoriteStore = async (storeKey: string): Promise<void> => {
        const result = await this._user.deleteFavoriteStore(storeKey) || false;
        if (result) {
            Util.setElementClass("add", `spn_locations_store_favorite_${storeKey}`, "icon-favorites");
            Util.setElementClass("remove", `spn_locations_store_favorite_${storeKey}`, "icon-heart-filled");
        } else
            DialogCreators.messageBoxOk(Names("favoriteStoreDelete_Error"), this._aOLO.buttonHoverStyle);
    }

    private readonly _startOrder = async (storeKey: string, localAolo: IaOLO): Promise<void> => {
        // 2DO: Create new url for menu

        let url = "";
        //if (localAolo.Temp.BrandOfferCode)
        //    url = await this._getCampaignUrl(storeKey, localAolo);
        //else
        //    url = `${location.origin}/?id=${storeKey}`;

        //if (localAolo.specialCode)
        //    url += `&specialcode=${localAolo.specialCode}`;

        //if (localAolo.reOrderId) {
        //    url += `&reorder=${localAolo.reOrderId}`;
        //    delete localAolo.reOrderId;
        //}

        //if (localAolo.orderCouponId) {
        //    url += `&ordercoupon=${localAolo.orderCouponId}`;
        //    delete localAolo.orderCouponId;
        //}

        //if (Util.isAppView()) {
        //    url += "&mobile=true";
        //    const tempLoct = this._locations.find(l => l.storeKey === storeKey);
        //    if (tempLoct) {
        //        if (tempLoct.distance > 25) {
        //            let customerDistance = tempLoct.distance;
        //            let messageName = "XtraMiles";
        //            if (true) {
        //                customerDistance = Util.Float2(Util.convertMilesToKilometers(customerDistance));
        //                messageName = "XtraKilometers";
        //            }
        //            DialogCreators.messageBox(Names(messageName).replace("??", `<div class='warning bold inline'>${customerDistance}</div>`), localAolo.buttonHoverStyle, [
        //                { "text": Names("YesProceed"), "callBack": () => { this._yesCallBack(url); } },
        //                { "text": Names("NoOtherStore"), "callBack": this._noCallBack }]);
        //        } else {
        //            this._yesCallBack(url)
        //        }
        //    }
        //} else
        //    window.open(url, '_blank');
    }
}