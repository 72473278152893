import EventEmitter from "eventemitter3";
import { EventTypes } from "../core/event-bus";
import { Pages } from "../types/enums";
import { Util } from "../utils/util";

export class Router {
    private readonly _eventBus: EventEmitter;
    private readonly _routes: Record<Pages, { path: string, method: (data: null | any) => void }>;

    constructor(eventBus: EventEmitter) {
        this._eventBus = eventBus;
        this._routes = this._createRoutes();

        // Redirect to root on unsupported paths
        const currentPath = window.location.pathname;
        const initialPage = this._getPageFromPath(currentPath);

        if (initialPage !== Pages.LANDING)
            window.history.replaceState({ page: Pages.LANDING }, "", this._routes[Pages.LANDING].path);

        this._eventBusListeners();
        window.addEventListener("popstate", this._handlePopState.bind(this));
    }

    public init = (): void => {
        const currentPage = (window.history.state?.page as Pages) || Pages.LANDING;
        this._navigate(currentPage, null);
    }

    private readonly _eventBusListeners = (): void => {
        this._eventBus.on(EventTypes.LOCATION_CHANGE, this._navigate);
    }

    private readonly _createRoutes = (): Record<Pages, { path: string, method: () => void }> => {
        return {
            [Pages.LANDING]: {
                path: "/",
                method: () => { }
            },
            [Pages.LOCATIONS]: {
                path: "/locations",
                method: () => { }
            },
            [Pages.MENU]: {
                path: "/menu",
                method: () => { }
            },
            [Pages.REWARDS]: {
                path: "/rewards",
                method: () => { }
            },
            [Pages.FAVORITES]: {
                path: "/favorites",
                method: () => { }
            },
            [Pages.ORDERS]: {
                path: "/orders",
                method: () => { }
            }
        };
    }

    /**
     * Adds or updates a route in the routes list.
     * @param {Pages} page - The page enum to identify the route.
     * @param {() => void} callback - The method to execute when the route is navigated to.
     */
    public readonly addRoute = (page: Pages, callback: (data: null | any) => void): void => {
        const route = this._routes[page];
        if (!route)
            return;

        route.method = callback;
    }

    // Navigate to a page and update the URL
    private readonly _navigate = (page: Pages, data: any): void => {
        const route = this._routes[page];
        if (!route)
            return;

        // Hide Order Type/Address nav bar
        Util.hideElement("div_order_type_address_nav_bar");
        Util.setElementClass("remove", "footer", "nav-bar");

        const stateData = data != null ? JSON.stringify(data) : null;
        window.history.pushState({ page, data: stateData }, "", route.path);
        route.method(data);
    }

    private readonly _handlePopState = (event: PopStateEvent): void => {
        const page: Pages | undefined = event.state?.page;
        const data: null | any = event.state?.data;

        if (page && this._routes[page]) {
            const route = this._routes[page];
            this._eventBus.emit(EventTypes.LOCATION_CHANGE, page);
            const tempData = data != null ? JSON.parse(data) : null;
            route.method(tempData);
        } else {
            // Redirect to root if the state is undefined or doesn't match any route
            this._navigate(Pages.LANDING, null);
        }
    }

    // Helper method to get page enum from path
    private readonly _getPageFromPath = (path: string): Pages | undefined => {
        return Object.keys(this._routes).find(
            (key) => this._routes[key as Pages].path === path
        ) as Pages | undefined;
    }
}