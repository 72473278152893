import { Util } from '../utils/util';
import { Names, setPageLanguage } from '../utils/i18n';
import { IMyOrders, IOrderDetails } from './interfaces/my-orders.interface';
import { DialogCreators } from '../utils/dialog-creators';
import { IFetchOrderDetails } from '../services/api/interfaces/profile-service.interfaces';
import { Tracker } from '../brand/tracker';
import '../../css/shared/my-orders.css';
import { IaOLO } from '../interfaces/aolo.interface';
import { IAddressFormat } from '../interfaces/global.interfaces';

/**
 * Represents a class for managing the user's orders.
 * @class
 */
export class MyOrders {
    /**
     * @private
     * @property {IMyOrders[]} - An array of user's orders.
     */
    private _orders: IMyOrders[] = [];
    private _pageFunction: Function | null;
    private _reorderFunction: Function | null;

    /**
     * Initializes the class by rendering the user's orders.
     * @constructor
     */
    constructor(pageFunction: Function | null, reorderFunction: Function | null) {
        this._pageFunction = pageFunction;
        this._reorderFunction = reorderFunction;
        this._init(aOLO);
    }

    /**
     * Initializes the class by rendering the user's orders.
     * @private
     * @returns {void}
     */
    private _init = (localAolo: IaOLO): void => {
        this._renderMyOrders(true, localAolo);
    }

    /**
     * Renders the user's orders by fetching them from the server and displaying them on the page.
     * @private
     * @async
     * @param {boolean} reloadOrders - Indicates whether to reload the orders from the server.
     * @returns {Promise<void>}
     */
    private _renderMyOrders = async (reloadOrders: boolean = false, localAolo: IaOLO): Promise<void> => {
        let orders = await this.getOrders(reloadOrders, localAolo);
        let ordersCard = new ProfileOrderCards(orders, this._renderMyOrders, this._pageFunction, this._reorderFunction);
        let faveOrders = ordersCard.getFavoriteOrdersCards();
        let recentOrders = ordersCard.getRecentOrdersCards();

        if (faveOrders.length > 0) {
            Util.hideElement("div_myorders_no_favorite_orders");
            Util.showElement("div_myorders_has_favorite_orders");
            let favoriteOrdersDiv = document.getElementById("div_myorders_has_favorite_orders");
            if (favoriteOrdersDiv) {
                favoriteOrdersDiv.innerHTML = "";
                faveOrders.forEach((f: any) => {
                    if (favoriteOrdersDiv)
                        favoriteOrdersDiv.appendChild(f);
                });
            }
        } else {
            Util.showElement("div_myorders_no_favorite_orders");
            Util.hideElement("div_myorders_has_favorite_orders");
        }

        let recentOrdersDiv = document.getElementById("div_myorders_recent_orders");
        if (recentOrdersDiv && recentOrders.length > 0) {
            recentOrdersDiv.innerText = "";
            recentOrders.forEach(r => {
                if (recentOrdersDiv)
                    recentOrdersDiv.appendChild(r);
            });
        }

        setPageLanguage(aOLO.Temp.languageCode);
    }

    /**
     * Retrieves the user's orders from the server.
     * @private
     * @async
     * @param {boolean} reloadOrders - Indicates whether to reload the orders from the server.
     * @returns {Promise<IMyOrders[]>} - An array of user's orders.
     */
    public getOrders = async (reloadOrders: boolean, localAolo: IaOLO): Promise<IMyOrders[]> => {
        if (reloadOrders)
            return await this._fetchOrders(localAolo);

        return this._orders || await this._fetchOrders(localAolo);
    }

    /**
     * Fetches the user's orders from the server.
     * @private
     * @async
     * @returns {Promise<IMyOrders[]>} - An array of user's orders.
     */
    private _fetchOrders = async (localAolo: IaOLO): Promise<IMyOrders[]> => {
        this._orders = await aOLO.Modules.ProfileService.getOrders(localAolo);
        return this._orders;
    }
}

/**
 * Class representing a set of order cards.
 * @class
 */
class ProfileOrderCards {
    /**
     * @private 
     * @property {IMyOrders[]} - The list of orders to generate cards for.
     */
    private _orders: IMyOrders[] = [];
    /**
     * @private
     * @property {HTMLElement[]} - The list of favorite order cards.
     */
    private _favoriteOrdersCards: HTMLElement[] = [];
    /**
     * @private
     * @property {HTMLElement[]} - The list of recent order cards.
     */
    private _recentOrderCards: HTMLElement[] = [];
    /**
     * @private
     * @property {Function|null} - The function to call when a favorite icon is clicked.
     */
    private _toggleFavorite;
    private _pageFunction: Function | null;
    private _reorderFunction: Function | null;

    /**
     * Initializes the class by creating the user's order cards.
     * @constructor
     */
    constructor(orders: IMyOrders[], toggleFavorite: Function | null, pageFunction: Function | null, reorderFunction: Function | null) {
        this._orders = orders;
        this._toggleFavorite = toggleFavorite;
        this._pageFunction = pageFunction;
        this._reorderFunction = reorderFunction;
        this._setCards(aOLO);
        this._setEventListeners();
    }

    private _setEventListeners = (): void => {
        const self = this;
        Util.setElement("onclick", "btn_myorders_close", () => { self.closeDialog(); });
    }

    public closeDialog = (): void => {
        Util.DialogFadeOut("dia_myorders");
    }

    /**
     * Get the list of recent order cards.
     * @returns {HTMLElement[]} The list of recent order cards.
     */
    public getRecentOrdersCards = () => {
        return this._recentOrderCards;
    }

    /**
     * Get the list of favorite order cards.
     * @returns {HTMLElement[]} The list of favorite order cards.
     */
    public getFavoriteOrdersCards = () => {
        return this._favoriteOrdersCards;
    }

    /**
     * Generate and set the order cards based on the given orders.
     * @private
     */
    private _setCards = (localAolo: IaOLO) => {
        this._orders.forEach((o: IMyOrders) => {
            if (o.isFavorite) {
                this._favoriteOrdersCards.push(this._getCard(o, localAolo, this._toggleFavorite));
            } else {
                this._recentOrderCards.push(this._getCard(o, localAolo, this._toggleFavorite));
            }
        });
    }

    /**
     * Generate an order card based on the given order.
     * @private
     * @param {IMyOrders} order - The order to generate a card for.
     * @param {Function|null} toggleFavorite - The function to call when a favorite icon is clicked.
     * @returns {HTMLElement} The generated order card.
     */
    private _getCard = (order: IMyOrders, localAolo: IaOLO, toggleFavorite: Function | null): HTMLElement => {
        var self = this;
        let div = this._getCardTemplate(order);

        const btnFave = div.querySelector(`#btn_myorders_card_button_favorite_${order.id}`) as HTMLElement;
        if (btnFave)
            btnFave.onclick = () => { self._favoriteIconClick(order.id, order.isFavorite, localAolo, toggleFavorite); };

        const btnDetail = div.querySelector(`#btn_myorders_card_order_details_${order.id}`) as HTMLElement;
        if (btnDetail)
            btnDetail.onclick = () => { self._showOrderDetail(order.id, localAolo) }

        const btnReorder = div.querySelector(`#btn_myorders_card_reorder_${order.id}`) as HTMLElement;
        if (btnReorder) {
            if (order.isPosted) {
                btnReorder.onclick = () => { self._reOrder(order.id); };
                btnReorder.classList.remove("hidden");
            } else
                btnReorder.classList.add("hidden");
        }

        const btnTracker = div.querySelector(`#btn_myorders_card_track_order_${order.id}`) as HTMLElement;
        if (btnTracker) {
            if (!order.isPosted && new Date(order.orderDate).toDateString() == Util.NowStore(aOLO.Temp.TimeOffset).toDateString()) {
                btnTracker.onclick = () => { self._trackOrder(order.id); };
                btnTracker.classList.remove("hidden");
            } else
                btnTracker.classList.add("hidden");
        }

        return div;
    }

    /**
     * Generates the card template for a given order.
     * @private
     * @param {IMyOrders} order - The order for which to generate the card template.
     * @returns {HTMLElement} The generated card template.
     * @throws {Error} If the template container element is not found in the DOM.
     */
    private _getCardTemplate = (order: IMyOrders): HTMLElement => {
        let heart = `<span class='${(order.isFavorite) ? "icon-X-HeartFilled" : "icon-W-Heart"} heart'></span>`;
        // @ts-ignore
        let text = order.isFavorite ? order.favoriteName : Util.formatDate(new Date(order.orderDate))

        let divContainer = document.getElementById("div_myorders_card_template") as HTMLElement;

        if (typeof divContainer !== 'object')
            throw Error("No Div Found");

        const currDiv = divContainer.cloneNode(true) as HTMLElement;
        if (currDiv) {
            currDiv.classList.remove("hidden");
            currDiv.id = `div_myorders_card_${order.id}`;

            const storeName = currDiv.querySelector("#div_myorders_card_store_name") as HTMLElement;
            if (storeName)
                storeName.innerText = order.storeName || "";

            const cardName = currDiv.querySelector("#div_myorders_card_favorite_name") as HTMLElement;
            if (cardName)
                cardName.innerText = text || "";

            const orderTypeSpan = currDiv.querySelector("#div_myorders_card_order_type");
            if (orderTypeSpan)
                orderTypeSpan.setAttribute("ltagj", order.typeName);

            const favoriteBtn = currDiv.querySelector(".myorders-card-button-favorite");
            if (favoriteBtn) {
                favoriteBtn.id = `btn_myorders_card_button_favorite_${order.id}`;
                favoriteBtn.innerHTML = heart;
            }

            const orderDetailsBtn = currDiv.querySelector(".myorders-card-order-details");
            if (orderDetailsBtn)
                orderDetailsBtn.id = `btn_myorders_card_order_details_${order.id}`;

            const reorderBtn = currDiv.querySelector(".myorders-card-reorder");
            if (reorderBtn)
                reorderBtn.id = `btn_myorders_card_reorder_${order.id}`;

            const tracOrderBtn = currDiv.querySelector(".myorders-card-track-order");
            if (tracOrderBtn)
                tracOrderBtn.id = `btn_myorders_card_track_order_${order.id}`;
        }

        return currDiv;
    }

    /**
     * Shows the order details for a given order ID.
     * @private
     * @param {number} orderId - The ID of the order for which to show the details.
     * @returns {void}
     */
    private _showOrderDetail = (orderId: number, localAolo: IaOLO): void => {
        const self = this;
        this._fetchOrderDetails(orderId, localAolo).then((order) => {
            if (order) {
                const html = self._orderDetailsBody(order);
                DialogCreators.messageBoxOk(html, localAolo.buttonHoverStyle);
                //setPageLanguage(aOLO.Temp.languageCode);
            }
        });
    }

    /**
     * Fetches the order details for a given order ID.
     * @private
     * @async
     * @param {number} orderId - The ID of the order for which to fetch the details.
     * @returns {Promise<MyOrdersInterface | null>} A Promise that resolves with the fetched order details or null if an error occurs.
     */
    private _fetchOrderDetails = async (orderId: number, localAolo: IaOLO): Promise<IOrderDetails | null> => {
        const data = await localAolo.Modules.ProfileService.fetchOrderDetails(orderId, localAolo);
        if (data !== null) {
            const orderDetails = this._mapOrderDetails(data);
            return orderDetails;
        } else {
            DialogCreators.messageBoxOk(Names("MyOrders2_FetchOrderDetails_Error"), localAolo.buttonHoverStyle);
            return null;
        }
    }

    private _mapOrderDetails(data: IFetchOrderDetails): IOrderDetails {
        const orderDetails: IOrderDetails = {
            DeliveryCharge: data.DELCHG,
            Discount: data.DISC,
            Gratuity: data.GRAT,
            OrderDate: data.ODATE,
            OrderId: data.OID,
            OrderNumber: data.ONUM,
            OrderType: data.OTYPE,
            StoreName: data.SNAME,
            StoreStreetNumber: data.SSTRNUM,
            StoreAddress1: data.SADDR1,
            StoreAddress2: data.SADDR2,
            StoreAddress3: data.SADDR3,
            StoreAddress4: data.SADDR4,
            StoreAddress5: data.SADDR5,
            StoreCity: data.SCITY,
            StoreState: data.SSTATE,
            StoreZip: data.SZIP,
            StoreCountryId: data.SCID,
            StorePhone: data.SPHN,
            Subtotal: data.SUBTLT,
            TaxCollected: data.TAX,
            Items: data.Items?.map(item => ({
                Comment: item.COM,
                HalfHalfPrice: item.HHPRC,
                HalfIndex: item.HIDX,
                Name: item.NAM,
                Price: item.PRC,
                Quantity: item.QTY,
                Size: item.SIZE,
                Modifiers: item.MODS?.map(mod => ({
                    IsDefaultPreModifier: mod.IDEFPREMOD,
                    ModifierName: mod.MODNAM,
                    PreModifierName: mod.PREMODNAM
                })) || [],
            })) || [],
            Payments: data.Payments?.map(payment => ({
                AccountId: payment.ACCID,
                Amount: payment.AMT,
                ChangeDue: payment.CDUE,
                CardTypeId: payment.CTID,
                Gratuity: payment.GRAT,
                Name: payment.NAM,
                PaymentTypeId: payment.PTID,
            })) || []
        };

        return orderDetails;
    }

    private _orderDetailsBody(data: IOrderDetails): string {
        let itemCount = 0;
        data.Items.forEach(x => itemCount += x.Quantity);

        const tempAddress: IAddressFormat = {
            StreetNo: data.StoreStreetNumber,
            Address1: data.StoreAddress1,
            Address2: data.StoreAddress2,
            Address3: data.StoreAddress3,
            Address4: data.StoreAddress4,
            Address5: data.StoreAddress5,
            City: data.StoreCity,
            State: data.StoreState,
            ZipCode: data.StoreZip,
            CountryID: data.StoreCountryId,
            AddressTypeID: 0
        };

        const addressObject = Util.formatAddressObject(tempAddress, aOLO.data.Countries);

        let html = `
            <div class="order-detail">
                <div class="order-detail-header">
                    <div>
                        <span class="bold">${data.StoreName}</span>
                        <span>${addressObject.address1}</span>
                        ${addressObject.address3 ? `<span>${addressObject.address3}</span>` : ``}
                        <span>${addressObject.cityState}</span>
                        <span>${Util.formatPhoneNumber(data.StorePhone, Names("PhoneFormat", aOLO.storeInfo.Country.DefaultCultureCode))}</span>
                    </div>

                    <div class="order-detail-header-block"><br/></div>

                    <div>
                        <div>
                            <span class="bold">${Names("OrderType")}</span>
                            <span>${Names(data.OrderType.replaceAll(" ", ""))}</span>
                        </div>
                        <div>
                            <span class="bold">${Names("OrderTime")}</span>
                            <span>${Util.formatDateTime(new Date(data.OrderDate))}</span>
                        </div>
                        <div>
                            <span class="bold">${Names("OrderNumber")}</span>
                            <span>${data.OrderNumber}</span>
                        </div>
                        <div>
                            <span class="bold">${Names("TransactionNumber")}</span>
                            <span>${data.OrderId}</span>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="order-detail-items-header">
                        <div>${data.Items.length === 1 ? Names(`Item`) : Names(`Items`) }</div>
                        <div>${Names(`Quantity`)}</div>
                        <div>${Names(`Price`)}</div>
                    </div>
                    ${this._orderDetailsBodyItems(data)}
                </div>
                <div style="width: 100%; background-color: var(--colorOrderDetailHeader);"><br/></div>

                <div>
                    ${this._getOrderBodyTotalDetails(data)}
                </div>
            </div>
        `;

        return html;
    }

    private _orderDetailsBodyItems(data: IOrderDetails): string {
        let colorIndex = 0;
        let half1 = "";
        let half2 = "";
        let html = "";

        for (const item of data.Items) {
            let size = (item.Size.toLowerCase() == "none") ? "" : `${item.Size}`;
            let bgColor = null;

            if (colorIndex % 2 == 1)
                bgColor = "var(--colorOrderDetailAltRow)";

            let modes = "";
            for (const mod of item.Modifiers)
                modes += mod.IsDefaultPreModifier ? `${mod.ModifierName}, ` : `<span style='color: red;'>${mod.PreModifierName}</span> ${mod.ModifierName}, `;

            if (item.HalfIndex == 1) {
                const itemName = Names("HalfHalfPizzaOrderDetail").replace("??", size);
                half1 = "";
                half1 += this._getOrderDetailsBodyIndentRow(itemName, 5, false, true);
                half1 += this._getOrderDetailsBodyIndentRow(Names("FirstHalf"), 10, true, false);
                half1 += this._getOrderDetailsBodyIndentRow(item.Name, 15, false, true);

                if (modes != "")
                    half1 += this._getOrderDetailsBodyIndentRow(modes, 20, false, false);

            } else if (item.HalfIndex == 2) {
                let price = Util.formatMoney(item.HalfHalfPrice);
                half2 = ""
                half2 += this._getOrderDetailsBodyIndentRow(Names(`SecondHalf`), 10, true, false);
                half2 += this._getOrderDetailsBodyIndentRow(item.Name, 15, false, true);

                if (modes !== "")
                    half2 += this._getOrderDetailsBodyIndentRow(modes, 20, false, false);

                if (item.Comment !== "")
                    half2 += this._getOrderDetailsBodyIndentRow(`${Names("Comment")}: ${item.Comment}`, 25, true, false);

                html += `
                    <div class="order-detail-items-body">
                        <div style="${bgColor ? `background-color: ${bgColor};` : ``}">
                            ${half1}
                            ${half2}
                        </div>
                        <div style="${bgColor ? `background-color: ${bgColor};` : ``}">
                            <span>${item.Quantity}</span>
                        </div>
                        <div style="${bgColor ? `background-color: ${bgColor};` : ``}">
                            <span>${price}</span>
                        </div>
                        <div style="${bgColor ? `background-color: ${bgColor};` : ``}">
                            <div>
                                <span>${Names(`Quantity`)}:</span>
                                <span>${item.Quantity}</span>
                            </div>
                            <div>
                                <span>${Names(`Price`)}:</span>
                                <span>${price}</span>
                            </div>
                        </div>
                    </div>`;

                half1 = "";
                half2 = "";
                colorIndex += 1;
            } else {
                let price = Util.formatMoney(item.Price);
                half1 = "";
                half2 = "";
                const itemName = Names("OrderDetailItemName").replace("??", size).replace("???", item.Name);

                html += `
                    <div class="order-detail-items-body">
                        <div style="${bgColor ? `background-color: ${bgColor};` : ``}">
                            ${this._getOrderDetailsBodyIndentRow(itemName, 5, false, true)}
                            ${modes !== `` ? this._getOrderDetailsBodyIndentRow(modes, 10, false, false) : ``}
                            ${item.Comment !== `` ? this._getOrderDetailsBodyIndentRow(`${Names("Comment")}: ${item.Comment}`, 25, true, false) : ``}
                        </div>
                        <div style="${bgColor ? `background-color: ${bgColor};` : ``}">
                            <span>${item.Quantity}</span>
                        </div>
                        <div style="${bgColor ? `background-color: ${bgColor};` : ``}">
                            <span>${price}</span>
                        </div>
                        <div style="${bgColor ? `background-color: ${bgColor};` : ``}">
                            <div>
                                <span>${Names(`Quantity`)}:</span>
                                <span>${item.Quantity}</span>
                            </div>
                            <div>
                                <span>${Names(`Price`)}:</span>
                                <span>${price}</span>
                            </div>
                        </div>
                    </div>`;
                colorIndex += 1;
            }
        }

        return html;
    }

    private _getOrderBodyTotalDetails(data: IOrderDetails): string {
        const total = data.Subtotal + data.TaxCollected + data.Gratuity + data.DeliveryCharge - data.Discount;

        let html = this._getOrderDetailsBodyTotals("Subtotal", Util.formatMoney(data.Subtotal));
        html += this._getOrderDetailsBodyTotals("Discounts", `-${Util.formatMoney(data.Discount)}`);
        html += this._getOrderDetailsBodyTotals("DeliveryCharge", Util.formatMoney(data.DeliveryCharge));
        html += this._getOrderDetailsBodyTotals("Tax", Util.formatMoney(data.TaxCollected));
        html += this._getOrderDetailsBodyTotals("Total", Util.formatMoney(total));
        html += this._getOrderBodyPaymentDetails(data);
        return html;
    }

    private _getOrderBodyPaymentDetails(data: IOrderDetails): string {
        if (data.Payments.length === 0)
            return "";

        let html = "";
        for (const payment of data.Payments) {
            let payName = payment.Name;

            if (payment.PaymentTypeId != null && payment.PaymentTypeId == 4) {
                if (payment.CardTypeId != null)
                    payName = this._getCreditCardTypeById(payment.CardTypeId);

                if (payment.AccountId != null)
                    payName += `(${payment.AccountId})`;
            }

            html += this._getOrderDetailsBodyTotals(payName, Util.formatMoney(payment.Amount));

            if (payment.Gratuity > 0)
                html += this._getOrderDetailsBodyTotals("Gratuity", Util.formatMoney(payment.Gratuity));

            if (payment.ChangeDue > 0)
                html += this._getOrderDetailsBodyTotals("ChangeDue", Util.formatMoney(payment.ChangeDue));
        }

        return html;
    }

    private _getCreditCardTypeById(cardTypeId: number): string {
        const cardTypes: Map<number, string> = new Map([
            [0, ""],
            [1, "Visa"],
            [2, "MasterCard"],
            [3, "Discover"],
            [4, "Amex"],
            [5, "JCB"],
            [6, "DinersClub"],
            [7, "enRoute"],
            [8, "Interac"],
            [9, "Flash"],
            [10, "Debit"],
            [11, "GiftCard"],
            [12, "EBT"],
            [13, "LevelUp"],
            [14, "Voyager"],
            [15, "Wex"],
            [16, "Other"]
        ]);

        const cardType = cardTypes.get(cardTypeId);
        return cardType ? cardType : "";
    }

    private _getOrderDetailsBodyTotals(name: string, value: string) {
        let html = `
            <div style="width: 100%; display: inline-flex; padding: 0.5em 0;">
                <div style="width: 80%; text-align: end;">
                    ${name.includes(`(`) ? `<span class="bold">${name}</span>` : `<span class="bold">${Names(name.replaceAll(" ", ""))}</span>`}
                </div>

                <div style="width: 20%; text-align: end;">
                    <span class="bold">${value}</span>
                </div>
            </div>`;
        return html;
    }

    private _getOrderDetailsBodyIndentRow(text: string, indentPercent: number, italic: boolean, bold: boolean) {
        let classList = "";
        if (bold)
            classList += "bold ";

        if (italic)
            classList += "italic";

        let html = `
            <div style="width:100%; display:flex; padding: 0.2rem">
                <span style="width: ${indentPercent}%;"></span>
                <span style="width: ${100 - indentPercent}%; text-align: left;" class="${classList}">
                    ${text}
                </span>
            </div>`;

        return html;
    }

    /**
     * Handle the click event for the favorite icon on an order card
     * @private
     * @async
     * @param {number} orderId - The ID of the order to toggle favorite status for
     * @param {boolean} isFavorite - The current favorite status of the order
     * @param {Function|null} callBack - Optional callback function to execute after toggling favorite status
     * @returns {Promise<void>}
     */
    private _favoriteIconClick = async (orderId: number, isFavorite: boolean, localAolo: IaOLO, callBack: Function | null): Promise<void> => {
        if (isFavorite) {
            this._toggleFavoriteOrder(orderId, "", localAolo, callBack);
        } else {
            DialogCreators.InputBox(Names("MyOrders2_FavoriteIconClick"),
                false,
                null,
                null,
                aOLO,
                [{
                    text: Names("Save"), close: true, callBack: (faveName: string) => {
                        this._toggleFavoriteOrder(orderId, faveName, localAolo, callBack);
                    }
                }, {
                    text: Names("Cancel"), close: true, callBack: null
                }], null, null);
        }
    }

    /**
     * Toggle the favorite status of an order
     * @private
     * @async
     * @param {number} orderID - The ID of the order to toggle favorite status for
     * @param {string} name - The name to save for the favorite order (empty string if removing from favorites)
     * @param {Function|null} callBack - Optional callback function to execute after toggling favorite status
     * @returns {Promise<boolean>} - A Promise that resolves to `true` if the favorite status was successfully toggled, `false` otherwise
     */
    private _toggleFavoriteOrder = async (orderID: number, name: string, localAolo: IaOLO, callBack: Function | null): Promise<boolean> => {
        const saved = await aOLO.Modules.ProfileService.saveFavoriteOrder(orderID, name, localAolo);
        if (saved && callBack)
            callBack(true);

        return saved;
    }

    /**
     * Handle the click event for the track order button on an order card
     * @private
     * @param {number} orderId - The ID of the order to track
     * @returns {void}
     */
    private _trackOrder = (orderId: number): void => {
        if (aOLO.isBrandSignIn) {
            aOLO.TrackerId = orderId;
            if (aOLO.TrackerId == null)
                Util.LogError(`_trackOrder --> orderId: ${orderId}`, new Error(), aOLO);

            if (this._pageFunction)
                this._pageFunction("tracker", true);
        } else {
            aOLO.RedirectToTracker = false;
            aOLO.TrackerId = orderId;
            if (aOLO.TrackerId == null)
                Util.LogError(`_trackOrder --> orderId: ${orderId}`, new Error(), aOLO);

            aOLO.Dialog.Tracker = new Tracker(aOLO.TrackerId, null);
            Util.DialogFadeIn("dia_tracker", aOLO);
        }
    }

    /**
     * Handle the click event for the reorder button on an order card
     * @private
     * @async
     * @param {number} orderId - The ID of the order to reorder
     * @returns {Promise<void>}
     */
    private _reOrder = async (orderId: number): Promise<void> => {
        if (aOLO.isBrandSignIn) {
            aOLO.reOrderId = orderId;
            if (this._pageFunction)
                this._pageFunction("locations", true);
        } else {
            if (this._reorderFunction)
                await this._reorderFunction(orderId);
            this.closeDialog();
        }
    }
}