import { AdoraHttpClient } from "../../utils/http-client";
import { IaOLO } from "../../interfaces/aolo.interface";
import { IUser } from "../../models/interfaces/user.interface";
import { Util } from "../../utils/util";
import { ISignInResponse, IaOLOUser, IaOLOUserLoyaltyReward } from "./interfaces/profile-service.interfaces";
import { ISendResetPassword, ISignInByPhone } from "./interfaces/customer-service.interface";

export class CustomerService {
    private _apiCustomerUrl: string;
    private _aOLO: IaOLO;

    constructor(localAolo: IaOLO) {
        this._aOLO = localAolo;
        this._apiCustomerUrl = this._aOLO.LoyaltyApiUrl;
    }

    get jwt(): string | null {
        return AdoraHttpClient.getJWT();
    }

    get refresh_token(): string | null {
        return AdoraHttpClient.getRefreshToken();
    }

    /**
    * Makes an HTTP request.
    * @private
    * @async
    * @param {string} method - The HTTP method (e.g. 'GET', 'POST', 'PUT', 'DELETE').
    * @param {string} url - The URL of the HTTP resource.
    * @param {object|null} payload - The payload to send with the request.
    * @param {string|null} token - The authorization token to send with the request.
    * @returns {Promise<Response>} A promise that resolves to the HTTP response.
    */
    private _httpRequest = async (method: string, url: string, payload: object | null, token: string | null = null, refreshToken: string | null = null, showProgress: boolean = true, noCache: boolean = true): Promise<Response> => {
        const response = await AdoraHttpClient.httpRequest(showProgress, method, url, payload, token, refreshToken, noCache);

        if (response.status !== 401) {
            if (response.status === 408)
                return response;

            // Check for new JWT
            if (response.headers.has('Authorization')) {
                const authHeader = response.headers.get('Authorization');
                const token = authHeader?.split(' ')[1] || "";
                AdoraHttpClient.setJWT(token);
            }
            if (response.headers.has('rtk')) {
                const refreshToken = response.headers.get('rtk');
                if (refreshToken)
                    AdoraHttpClient.setRefreshToken(refreshToken);
            }
            return response;
        }

        //this.notAuthHttpResourceEvent();
        return response;
    }

    public signInByPhone = async (data: ISignInByPhone): Promise<ISignInResponse> => {
        const payload = await Util.encrypt(JSON.stringify(data), this._aOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiCustomerUrl}olo/customer/sign_in_by_phone`, payload);
        if (response.status == 200) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            if (result.success) {
                const data = JSON.parse(result.data);
                const user = this._mapUser(data.User);
                const jwt = data.JWT;
                const token = data.Token ?? null;
                const refreshToken = data.RefreshToken;
                return { success: true, loggedIn: true, user: user, jwt: jwt, refreshToken: refreshToken, token: token, timeout: false };
            }
            return { success: false, loggedIn: false, message: result.message, timeout: false }
        }
        return { success: false, loggedIn: false, timeout: response.status === 408 };
    }

    /**************************
     ******** Password ********
     **************************/

    /**
     * Sends a password reset request for the specified user.
     * 
     * @param {ISendResetPassword} data - The user data for the password reset request.
     * @returns {Promise<boolean>} - True if the reset was successfully initiated, false otherwise.
     */
    public forgotPassword = async (data: ISendResetPassword): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(data), this._aOLO.pkey);
        const response = await this._httpRequest("POST", `${this._apiCustomerUrl}olo/customer/forgot_password`, payload);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**************************
     ******** Utilities *******
     **************************/
    private readonly _mapUser = (data: IaOLOUser): IUser => {
        const user: IUser = {
            CustomerId: data.CID,
            ProfileId: data.PID,
            StoreId: data.SID,
            Name: data.NAM,
            LastName: data.LNAM,
            Phone: data.PHN,
            CountryCodeId: data.CCDID,
            Email: data.EML,
            IsMarketingSurvey: data.ISV,
            IsMarketingEmail: data.IEML,
            IsMarketingText: data.ITXT,
            MarketingPhone: data.MKPHN,
            BirthDate: data.BD,
            IsFavoriteStore: data.FAVST,
            FavoriteStoreId: data.FAVSTID,
            FavoriteStores: data.FAVSTRs?.map(x => x.SKEY) || [],
            CultureCode: data.CLT,
            IsDarkMode: data.IDK,
            IsLoyalty: data.ILY,
            IsBlocked: data.IBLKD,
            IsAge13: data.A13,
            TermsPrivacyPolicy: data.TPP,
            Addresses: data.ADRS,
            Allergies: data.ALGS,
            Dietary: data.DITS,
            Wallet: data.WLTS,
            IsProfileComplete: data.IPC,
            LoyaltyData: data.LOYDT ? {
                Rewards: data.LOYDT.rewards?.map((rew: IaOLOUserLoyaltyReward) => ({
                    RewardId: rew.RID,
                    CouponId: rew.CPID,
                    ExpiryDate: rew.EXDT,
                    Expired: rew.EXP,
                    Names: rew.NAMs
                })) || [],
                CurrentPoints: data.LOYDT.current_points,
                EarnedPoints: data.LOYDT.earned_points,
                PointsLastUpdatedDate: data.LOYDT.points_last_updated_date,
                LoyaltyJoinedDate: data.LOYDT.loyalty_joined_date,
                TierId: data.LOYDT.tier,
                TierName: data.LOYDT.tier_name || null,
                BankedCurrency: data.LOYDT.banked_currency || null,
                UnbankedPoints: data.LOYDT.unbanked_points || null,
            } : null,
            Offers: data.Offers || null,
            DefaultStore: data.DEFST ? {
                StoreKey: data.DEFST.SKEY,
                StreetNumber: data.DEFST.STRNO,
                Address1: data.DEFST.ADDR1,
                Address2: data.DEFST.ADDR2,
                CountryId: data.DEFST.CID
            } : null,
        };

        return user;
    }
}