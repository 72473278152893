import { IUser, IUserAddress } from "../models/interfaces/user.interface";
import { IAddressParsed, IDateStartEndTime } from "./interfaces/shared.interfaces";
import { ITempAddress } from "../interfaces/temp.interface";
import { IDataStore } from "../interfaces/data.interface";
import { IDateTodayAndSelected, IFillInAddress, IOrderTypeFunctions, IThirdPartyAddress } from "./interfaces/order-type.interface";
import { Names } from "../utils/i18n";
import { Util } from "../utils/util";
import { AddressType, AddressValidation, LoggedInStatus, OrderType, AddressLookupType } from "../types/enums";
import { Common } from "../common";
import { Rewards } from "./rewards";
import { CallCenterStoresDialog } from "./call-center-stores";
import { DialogCreators } from "../utils/dialog-creators";
import { OnlineOrderingUtil } from "./online-ordering-util";
import { LimitedTime } from "./limited-time";

import '../../css/online-ordering/order-type.css';
import { IaOLO } from "../interfaces/aolo.interface";
import { IApplyCouponByIdParams } from "./interfaces/coupons.interface";
import { IAddressFormat } from "../interfaces/global.interfaces";

export class OrderTypeDialog {
    private _functions: IOrderTypeFunctions;
    private _later: boolean;
    private _dialogOverlay: HTMLElement | null = null;
    private _dialog: HTMLElement | null = null;
    private _googMapsAutoCompleteOn: boolean = aOLO.data.Settings.GOGAC == 1;
    private _futureOrderDate: Date | null = null;
    private _futureOrderTime: number | null = null;
    private _isLimitedTimes: boolean = false;

    constructor(functions: IOrderTypeFunctions, isLater: boolean, force: boolean) {
        this._functions = functions;
        this._later = isLater;
        this._init(force, aOLO);
    }

    public new = async (functions: IOrderTypeFunctions, isLater: boolean, force: boolean): Promise<void> => {
        this._functions = functions;
        this._later = isLater;
        await this._init(force, aOLO);
    }

    private _init = async (force: boolean, localAolo: IaOLO): Promise<void> => {
        this._setElementBindings();
        //this._initializeMap(localAolo);

        await this._qrCodeRender();
        await this._renderPage(force);
        this._setEventListeners();
    }

    private _setElementBindings() {
        this._dialogOverlay = document.getElementById("div-order-type-overlay") as HTMLElement;
        this._dialog = document.getElementById("dia_order_type") as HTMLElement;
    }

    private _qrCodeRender = async (): Promise<void> => {
        if (!aOLO.QrCode) {
            Util.hideElement("div_cart_table");
            Util.showElement("btn_cart_edit_order_time_type");
            return;
        }

        await this._functions.SetOrderType_Pre(OrderType.DINE_IN);
        this._setAddressDisplay(aOLO.User);
        this.handleOrderTypeOrderTimeChange(false, false);

        Util.setElement("innerText", "spn_cart_table_number", aOLO.QrCode.tblno)
        Util.showElement("div_cart_table");
        Util.hideElement("btn_cart_edit_order_time_type");
    }

    private _renderPage = async (force: boolean): Promise<void> => {
        if (aOLO.QrCode)
            return;

        if (this._later)
            Util.setElement("checked", "rdo_order_type_time_later", true);
        else
            Util.setElement("checked", "rdo_order_type_time_now", true);

        this._setDialogDisplay();
        this.selectOrderTypeButton();
        this._setLaterDateTime();
        this._setAddressDisplay(aOLO.User);
        this.handleOrderTypeOrderTimeChange(false, false, false);

        let date = this._futureOrderDate ? this._futureOrderDate : Util.NowStore(aOLO.Temp.TimeOffset);

        const holiday = OnlineOrderingUtil.CheckHolidays(date, false, aOLO.storeInfo.StoreID, aOLO);
        if (holiday && holiday.Info && holiday.Info.find(x => x.orderTypeId == aOLO.Temp.OrderTypeID)?.checked) {
            Util.hideElement("div_ddl_order_type_time_later_time");
            Util.showElement("div_txt_order_type_time_later_time");
        }
        else {
            Util.showElement("div_ddl_order_type_time_later_time");
            Util.hideElement("div_txt_order_type_time_later_time");
        }

        await this._functions.SetOrderType_Pre(aOLO.Temp.OrderTypeID, () => { this._continueRender(aOLO) }, force);
    }

    private _continueRender(localaOLO: IaOLO) {
        this._openDialog(aOLO);
        this._setCities();
        this._setZips();
    }

    private _setCities() {
        const cities = aOLO.data.Cities.split(',');
        let ddl = document.getElementById('txt_order_type_city') as HTMLSelectElement;
        if (ddl && ddl.length === 0) {
            cities.forEach(x => {
                ddl.innerHTML += `<option value="${x.replaceAll("'", "")}">${x.replaceAll("'", "")}</option>`;
            });
            ddl.selectedIndex = 0;
        }
    }
    private _setZips() {
        const zips = aOLO.data.ZipCodes.split(',');
        let ddl = document.getElementById('ddl_order_type_zip') as HTMLSelectElement;
        if (ddl && ddl.length === 0) {
            zips.forEach(x => {
                ddl.innerHTML += `<option value="${x}">${x}</option>`;
            });
            ddl.selectedIndex = 0;
        }
    }
    private _setEventListeners = (): void => {
        if (aOLO.QrCode)
            return;

        const self = this;
        Util.setElement("onchange", "rdo_order_type_dine_in", async () => {
            await self._functions.SetOrderType_Pre(OrderType.DINE_IN);
            self._setAddressDisplay(aOLO.User);
            self.handleOrderTypeOrderTimeChange(true, false, true);
        });

        Util.setElement("onchange", "rdo_order_type_take_out", async () => {
            await self._functions.SetOrderType_Pre(OrderType.TAKE_OUT);
            self._setAddressDisplay(aOLO.User);
            //self.handleOrderTypeOrderTimeChange(true, false);
        });

        Util.setElement("onchange", "rdo_order_type_delivery", async () => {
            await self._functions.SetOrderType_Pre(OrderType.DELIVERY);
            self._setAddressDisplay(aOLO.User);
            //self.handleOrderTypeOrderTimeChange(true, false);
        });

        Util.setElement("onchange", "rdo_order_type_time_now", () => {
            self._setOrderTime(false);
            self.handleOrderTypeOrderTimeChange(false, false);
        });

        Util.setElement("onchange", "rdo_order_type_time_later", () => {
            self._setOrderTime(false);
            self.handleOrderTypeOrderTimeChange(false, false);
        });

        Util.setElement("onchange", "txt_order_type_time_later_date", () => this._futureDateChanged());
        Util.setElement("onchange", "ddl_order_type_time_later_time", () => this._futureTimeChanged());
        Util.setElement("onclick", "btn_order_type_continue", () => { self._onContinueButtonClick(); });
        Util.setElement("onclick", "btn_order_type_store", () => {
            aOLO.Dialog.CallCenterStores = new CallCenterStoresDialog(this._functions.setStoreOrderInfo);
        });

        const aptInput = document.getElementById("txt_order_type_apt");
        if (aptInput)
            aptInput.onblur = function () { OnlineOrderingUtil.SetAddressAptNo(aOLO.Temp.Address); };

        Util.setElement("onclick", "btn_order_type_select_limited_time", () => { new LimitedTime(aOLO.Temp.OrderTypeID, this._futureOrderDate, this._futureOrderTime); });
    }

    private _openDialog = (localAolo: IaOLO): void => {
        aOLO.Temp.OrderingStarted = false;
        if (!this._googMapsAutoCompleteOn)
            Util.DialogFadeIn("dia_order_type", localAolo, null, false);
        else
            Util.DialogFadeIn("dia_order_type", localAolo, null, true);
        this._toggleDialogOverlay(true);
    }

    public closeDialog = (): void => {
        this._toggleDialogOverlay(false);
        Util.DialogFadeOut("dia_order_type");
    }

    private _setDialogDisplay(): void {
        if (this._googMapsAutoCompleteOn && this._dialog)
            this._dialog.classList.add("dialog-use-show");
    }

    private _toggleDialogOverlay(show: boolean): void {
        if (this._dialogOverlay)
            this._dialogOverlay.style.display = show ? "block" : "none";
    }

    public selectOrderTypeButton = (): void => {
        switch (aOLO.Order.OrderTypeID) {
            case OrderType.DINE_IN:
                Util.setElement("checked", "rdo_order_type_dine_in", true);
                break;
            case OrderType.TAKE_OUT:
                Util.setElement("checked", "rdo_order_type_take_out", true);
                break;
            case OrderType.DELIVERY:
                Util.setElement("checked", "rdo_order_type_delivery", true);
                break;
        }
    }

    private _getTempAddress = (address: IUserAddress): ITempAddress => {
        return {
            AddressID: address.AID,
            AddressTypeID: address.ATID,
            AddressName: address.ANAM,
            IsPrimary: address.IPRM,
            StreetNo: address.STRNO,
            StreetName: address.ADDR1,
            Address2: address.ADDR2,
            Address3: address.ADDR3,
            Address4: address.ADDR4,
            Address5: address.ADDR5,
            City: address.CITY,
            State: address.STA,
            Zip: address.ZIP,
            CountryID: address.CID,
            Latitude: address.LAT,
            Longitude: address.LON,
            XStreet: address.XSTR,
            ZoneID: address.ZID || 0,
            Grid: address.GRD || "",
            Distance: address.DIS || 0,
            DeliveryInstruction: address.DI || ""
        };
    }

    private _setAddressDisplay = (user: IUser): void => {
        const isDelivery = (aOLO.Temp.OrderTypeID === OrderType.DELIVERY);

        if (!isDelivery) {
            Util.setElementClass("remove", "div_order_type_address", "accordion-open");
            return;
        }

        if (aOLO.User.GU == LoggedInStatus.LOGGEDIN) {
            const addrList = document.getElementById("ul_order_type_address_list");
            if (!addrList)
                return;

            Util.removeChildren("ul_order_type_address_list");
            let allAddrsString = "";
            user.AddressCount = 0; // keeps the listed address count. only addresses in the del zones.

            for (const address of user.Addresses || []) {
                const addr = this._getTempAddress(address);
                const addrString = (addr.StreetNo + addr.StreetName + addr.City + addr.State + addr.Zip).toLowerCase();
                if (allAddrsString.indexOf(addrString) !== -1)
                    continue;

                if (aOLO.data.Settings.ADDRLOOKUP != AddressLookupType.NONE) {
                    const zone = OnlineOrderingUtil.CheckZones(addr.Latitude, addr.Longitude, []); //aOLO.data.DeliveryZones
                    if (zone?.ZTID !== 1)
                        continue;
                }
                allAddrsString += addrString;
                user.AddressCount += 1;
                addrList.appendChild(this._createAddressRadioButton(addr));
            }

            if (aOLO.Temp.Address)
                Util.setElement("checked", `rdoOrderType_Address_${aOLO.Temp.Address.AddressID}`, true);

            if (aOLO.Order.FutureDate) {
                this._futureOrderDate = Util.DateAdd(aOLO.Order.FutureDate, aOLO.Order.FutureMinute, 'm');
                Util.setElement("value", "txt_order_type_time_later_date", Util.formatDateInput(aOLO.Order.FutureDate));
            }

            Util.setElementClass("add", "div_order_type_address", "accordion-open");
            if (user.AddressCount > 0) {
                Util.hideElement("div_order_type_new_address");
                Util.showElement("div_order_type_address_list");

                const addr = OnlineOrderingUtil.getTempAddressNew();
                addr.StreetNo = Names("NewDelAddr");
                addr.IsPrimary = false;

                const newAddressRadio = document.getElementById("rdoOrderType_Address_0") as HTMLInputElement;
                if (!newAddressRadio)
                    addrList.appendChild(this._createAddressRadioButton(addr));
                else if (newAddressRadio.checked)
                    Util.showElement("div_order_type_new_address");

                if (aOLO.Temp.Address && aOLO.Temp.Address.AddressID === 0) {
                    Util.setElement("checked", `rdoOrderType_Address_${aOLO.Temp.Address.AddressID}`, true);
                    Util.showElement("div_order_type_new_address");
                }


            } else {
                Util.hideElement("div_order_type_address_list");
                Util.showElement("div_order_type_new_address");
            }
        } else {
            Util.hideElement("div_order_type_address_list");
            Util.showElement("div_order_type_new_address");
            Util.setElementClass("add", "div_order_type_address", "accordion-open");
        }
    }

    private _createAddressRadioButton = (address: ITempAddress): HTMLElement => {
        let name = address.StreetNo;
        if (address.StreetNo != Names("NewDelAddr")) {
            const tempAddress: IAddressFormat = {
                StreetNo: address.StreetNo,
                Address1: address.StreetName,
                Address2: address.Address2,
                Address3: address.Address3,
                Address4: address.Address4,
                Address5: address.Address5,
                City: address.City,
                State: address.State,
                ZipCode: address.Zip,
                CountryID: address.CountryID || 1,
                AddressTypeID: address.AddressTypeID
            };

            const addressObject = Util.formatAddressObject(tempAddress, aOLO.data.Countries);
            const unit = address.Address2.trim() !== "" ? ` #${address.Address2.trim()}` : "";
            name = `${addressObject.address1}${unit}, ${addressObject.address3 ? `${addressObject.address3}, ` : ""}${addressObject.cityState}`;
        }

        const html = `
        <div class="checkRadio">
            <input type="radio" id="rdoOrderType_Address_${address.AddressID}" name="rdoOrderType_Address" data-addrid="${address.AddressID}"/>
            <label for="rdoOrderType_Address_${address.AddressID}">
                ${name}
            </label>
        </div>`;

        const li = document.createElement("li");
        li.innerHTML = html;

        const radioButton = li.querySelector(`#rdoOrderType_Address_${address.AddressID}`) as HTMLInputElement;
        if (radioButton)
            radioButton.onchange = async () => {
                await this._onAddressSelectionChange(address.AddressID);
            };

        return li;
    }

    private _onAddressSelectionChange = async (addressId: number): Promise<void> => {
        const rdo = document.getElementById(`rdoOrderType_Address_${addressId}`) as HTMLInputElement;
        const addrID = (rdo && rdo.dataset.addrid) ? parseInt(rdo.dataset.addrid) : 0;
        aOLO.Order.AddressID = addrID;

        if (addrID === 0) {
            Util.showElement("div_order_type_new_address");
            aOLO.Temp.Address = aOLO.Temp.NewAddress ? aOLO.Temp.NewAddress : null;
        } else {
            Util.hideElement("div_order_type_new_address");
            aOLO.Temp.Address = this._getDeliveryAddress(addrID)
        }

        this._functions.setOrderTypeCharge(aOLO, aOLOModules);
        this.handleOrderTypeOrderTimeChange(false, false,true);
        if (aOLO.data.Settings.ISCC)
            await this._functions.selectStoreForCheckedAddress();
    }

    private _getDeliveryAddress = (addrID: number): ITempAddress | null => {
        if (addrID === 0 && aOLO.Temp.Address) {
            const aptNum = Util.getElementValue("txt_order_type_apt");
            const tempAddress = OnlineOrderingUtil.getTempAddressNew();
            tempAddress.StreetNo = aOLO.Temp.Address.StreetNo;
            if (aptNum !== "") {
                tempAddress.AddressTypeID = AddressType.APT;
            }
            tempAddress.StreetName = aOLO.Temp.Address.StreetName;
            tempAddress.Address2 = aptNum;
            tempAddress.Address3 = aOLO.Temp.Address.Address3;
            tempAddress.Address4 = aOLO.Temp.Address.Address4;
            tempAddress.Address5 = aOLO.Temp.Address.Address5;
            tempAddress.City = aOLO.Temp.Address.City;
            tempAddress.State = aOLO.Temp.Address.State;
            tempAddress.Zip = aOLO.Temp.Address.Zip;
            tempAddress.CountryID = aOLO.Temp.Address.CountryID;
            tempAddress.Latitude = aOLO.Temp.Address.Latitude;
            tempAddress.Longitude = aOLO.Temp.Address.Longitude;
            tempAddress.ZoneID = OnlineOrderingUtil.CheckZones(tempAddress.Latitude, tempAddress.Longitude, [])?.ZID || 0; //aOLO.data.DeliveryZones
            return tempAddress;
        } else {
            const userAddress = aOLO.User.Addresses?.find((addr: IUserAddress) => addr.AID === addrID);
            if (userAddress) {
                userAddress.ZID = OnlineOrderingUtil.CheckZones(userAddress.LAT, userAddress.LON, [])?.ZID; //aOLO.data.DeliveryZones
                return this._getTempAddress(userAddress);
            }
        }
        return null;
    }

    private _setLaterDateTime = (): void => {
        const now = Util.NowStore(aOLO.Temp.TimeOffset);
        const busDate = Util.GetBUSDate(now, aOLO);
        const date = this._futureOrderDate ? this._futureOrderDate : busDate;
        this._futureOrderDate = new Date(date);

        const laterDate = document.getElementById("txt_order_type_time_later_date") as HTMLInputElement;
        if (laterDate) {
            laterDate.min = Util.formatDateInput(new Date(busDate));;
            const formattedDate = Util.formatDateInput(new Date(date));
            laterDate.value = formattedDate;
        }

        Util.setElement("innerHTML", "ddl_order_type_time_later_time", `<option value="-1">${Names("SelectTime")}</option>`);
    }

    public handleOrderTypeOrderTimeChange = (oTypeChanged: boolean, dateChanged: boolean, skipTimeClear: boolean = false): void => {
        let date = null;
        let time = null;
        let isLaterChecked = Util.getElementChecked("rdo_order_type_time_later");
        if (isLaterChecked) {
            //const dateText = Util.getElementValue("txt_order_type_time_later_date");
            //let date = GetDateInputDate(dateText);
            //let date = this._futureOrderDate!;
            const nowStore = Util.NowStore(aOLO.Temp.TimeOffset);
            const nowDate = new Date(Util.GetBUSDate(nowStore, aOLO));
            date = this._getOrderDateByOrderType(this._futureOrderDate, this._futureOrderTime, aOLO.Temp.OrderTypeID);
            const dateDifference = Util.DateDiff("d", date, nowDate);

            const dateDiff = date != null ? dateDifference : null;
            if (date != null && dateDiff !== null && dateDiff >= 0) {
                const holiday = OnlineOrderingUtil.CheckHolidays(date, false, aOLO.storeInfo.StoreID, aOLO);
                if (holiday && holiday.Info && holiday.Info.find(x => x.orderTypeId == aOLO.Temp.OrderTypeID)?.checked) {
                    // Show Limited Order Time Dialog
                    if (dateChanged) {
                        this._futureOrderTime = null;
                        new LimitedTime(aOLO.Temp.OrderTypeID, date, null);
                    }
                    Util.hideElement("div_ddl_order_type_time_later_time");
                    Util.showElement("div_txt_order_type_time_later_time");
                } else {
                    Util.showElement("div_ddl_order_type_time_later_time");
                    Util.hideElement("div_txt_order_type_time_later_time");
                    if (dateDiff > 0 || Util.DateDiff("d", date, new Date(Util.NowStore(aOLO.Temp.TimeOffset))) == 0)
                        time = 0;
                    else
                        time = (Util.NowStore(aOLO.Temp.TimeOffset).getTime() - date.getTime()) / 1000 / 60;
                    this._renderElementsBasedOnOrderTime(date, time, oTypeChanged);
                }
            } else
                Util.setElement("innerHTML", "ddl_order_type_time_later_time", "");

            const laterDateBox = document.getElementById("txt_order_type_time_later_date");
            if (laterDateBox)
                laterDateBox.focus();
        } else {
            this._renderElementsBasedOnOrderTime(date, time, oTypeChanged);
        }

        if (!skipTimeClear)
            this._clearLimitedTime();

        this.setOrderTypeTime();
    }
    //public getTodayAndSelectedDateTimes(date: Date, minute: number, oTypeChanged: boolean): IDateTodayAndSelected {
    //    return this._getTodayAndSelectedDateTimes(date, minute, oTypeChanged);
    //}

    private _getOrderDateByOrderType(currentOrderDate: Date | null, _futureOrderTime: number | null, orderTypeID: number) {
        const now = Util.NowStore(aOLO.Temp.TimeOffset);
        const storeBusdate = new Date(Util.GetBUSDate(now, aOLO));
        const nowMinute = Util.DateDiff("m", now, storeBusdate) + aOLO.data.Settings.BSM;//, Util.GetBUSDate(now, localAOLO));// now.getMinutes() + (60 * now.getHours());
        const DSTET = this._getTodayAndSelectedDateTimes(currentOrderDate ? currentOrderDate : storeBusdate, _futureOrderTime ? _futureOrderTime : nowMinute, true);

        let date: Date;

        if (DSTET.SD.IsClosedHoliday || !DSTET.SD.Online) {
            if (DSTET.SD.IsToday) {
                let otst = OnlineOrderingUtil.getOrderType(orderTypeID, aOLO);
                const nextOpenDay = OnlineOrderingUtil.GetNextOpenDay(aOLO, otst);
                date = nextOpenDay.Date;
            } else 
                date = storeBusdate;
        } else
            date = currentOrderDate ? currentOrderDate : storeBusdate;

        return date;
    }
    private _renderElementsBasedOnOrderTime = (date: Date | null, time: number | null, oTypeChanged: boolean): void => {
        const now = Util.NowStore(aOLO.Temp.TimeOffset);
        const nowMins = now.getMinutes() + (60 * now.getHours());

        if (date === null || time === null) {
            const nextOpenDay = OnlineOrderingUtil.GetNextOpenDay(aOLO);
            date = nextOpenDay.Date || now;
            time = nextOpenDay.Minute;
        }

        const DSTET = this._getTodayAndSelectedDateTimes(date ? date : now, time ? time : nowMins, oTypeChanged);

        let disableASAP = false;
        let disableLater = false;

        Util.setElement("innerText", "p_order_type_time_now_msg", "");
        Util.setElement("innerText", "p_order_type_time_later", "");
        Util.setElementClass("remove", "rdo_order_type_time_now", "lineThrough");
        Util.setElementClass("remove", "rdo_order_type_time_later", "lineThrough");

        if (!DSTET.TD.ASAP)
            Util.setElement("innerText", "p_order_type_time_now_msg", Names(`NoOT${aOLO.Order.OrderTypeID}AtMoment`));

        if (DSTET.TD.IsClosedHoliday) {
            // is closed today, it is holiday
            Util.setElement("innerText", "p_order_type_time_now_msg", Names("ClosedHoliday").replace("??", Names(`Hol${DSTET.TD.HolidayID}`)));
            disableASAP = true;
        } else if (DSTET.TD.IsClosedNow && nowMins <= DSTET.TD.OpenMinute) {
            // is closed now will open in x minutes
            
            Util.setElement("innerText", "p_order_type_time_now_msg", Names("WillOpen").replace("{{time}}", Util.formatMinutesToTime(DSTET.TD.OpenMinute)));
            disableASAP = true;
        } else if (DSTET.TD.IsClosedNow && nowMins >= DSTET.TD.CloseMinute) {
            // is closed now will open in x minutes
            Util.setElement("innerText", "p_order_type_time_now_msg", Names("IsClosedNow"));
            disableASAP = true;
        } else if (nowMins >= DSTET.TD.CloseMinute - (aOLO.data.Settings.IWTT ? 0 : aOLO.Temp.WaitTime)) {
            // is open now but it will close in x minutes not enough time to make 
            Util.setElement("innerText", "p_order_type_time_now_msg", `${Names("WillClose").replace("??", (DSTET.TD.CloseMinute - nowMins).toString())} ${Names("NoTime").replace("???", OnlineOrderingUtil.GetOrderTypeName(aOLO.Order.OrderTypeID, aOLO)) + Names("CallStore")}`);
            disableASAP = true;
        } else if (!DSTET.TD.Online) {
            Util.setElement("innerText", "p_order_type_time_now_msg", Names("NoOnlineAtMoment") + " " + Names("CallStore"));
            disableASAP = true;
        } else if (!DSTET.TD.FutureOrder) {
            Util.setElement("innerText", "p_order_type_time_later", Names("NoFutureOnlineAtMoment").replace("???", " "));
            disableLater = true;
        }

        if (disableLater) {
            Util.setElementClass("add", "lbl_order_type_time_later", "lineThrough");
            Util.setElement("disabled", "rdo_order_type_time_later", true);
            Util.setElement("checked", "rdo_order_type_time_later", false);
        } else {
            Util.setElement("disabled", "rdo_order_type_time_later", false);
            Util.setElementClass("remove", "lbl_order_type_time_later", "lineThrough");
            this._setOrderTimeOptions(DSTET.SD, "ddl_order_type_time_later_time", "txt_order_type_time_later_date", true);
        }

        if (disableASAP || !DSTET.TD.ASAP) {
            Util.setElementClass("add", "lbl_order_type_time_now", "lineThrough");
            Util.setElement("disabled", "rdo_order_type_time_now", true);
            Util.setElement("checked", "rdo_order_type_time_now", false);
            if (!disableLater)
                Util.setElement("checked", "rdo_order_type_time_later", true);
        } else {         
            Util.setElement("disabled", "rdo_order_type_time_now", false);
            Util.setElementClass("remove", "lbl_order_type_time_now", "lineThrough");
        }
    }

    public GUI_Set_OrderType_OrderTime_Options = (DSTET: IDateStartEndTime): void => {
        this._setOrderTimeOptions(DSTET, "ddl_order_type_time_later_time", "txt_order_type_time_later_date", true);
    }
    private _getNextDivisibleNumber(number: number, divisor: number) {
        if (divisor === 0)
            return 0;
        const remainder = number % divisor;
        if (remainder === 0)
            return number;
        const nextDivisible = number + (divisor - remainder);
        return nextDivisible;
    }
    private _setOrderTimeOptions = (DSTET: IDateStartEndTime, ddlId: string, txtId: string, resetFutureMinute: boolean): void => {
        let storeOpenMinute = this._getNextDivisibleNumber(DSTET.StartOrderMinute,15);
        let storeCloseMinute = DSTET.EndOrderMinute;
        let iDate = DSTET.Date;
        let ddl = document.getElementById(ddlId) as HTMLSelectElement;
        if (ddl) {
            let selectedIndex = 0;
            let selectedTime = ddl.value;
            let optionIndex = 0;
            Util.removeChildren(ddlId);
            ddl.innerHTML += `<option value="-1">${Names("SelectTime")}</option>`;
            if (storeOpenMinute < storeCloseMinute) {
                for (let i = storeOpenMinute; i <= storeCloseMinute; i += 15) {  //Remove change 3 to 15
                    optionIndex += 1;
                    const iDatetime = new Date(iDate);
                    const setHours = Math.floor(i / 60);
                    const setMinutes = i % 60;
                    const iDatetimeMinutesSet = iDatetime.setHours(setHours, setMinutes);
                    const pDateTime = new Date(iDatetimeMinutesSet);
                    const formattedTimeOption = Util.formatTime(pDateTime, 'en-US');
                    if (selectedTime == i.toString())
                        selectedIndex = optionIndex;
                    ddl.innerHTML += `<option value="${i}">${formattedTimeOption}</option>`;
                }
            }
            ddl.selectedIndex = selectedIndex;
        }

        if (resetFutureMinute)
            aOLO.Order.FutureMinute = -1;

        this._futureOrderDate = iDate;
        Util.setElement("value", txtId, Util.formatDateInput(iDate));
    }

    private _getTodayAndSelectedDateTimes = (date: Date, minute: number, oTypeChanged: boolean): IDateTodayAndSelected => {
        const selectedDate = OnlineOrderingUtil.GetDate_StartTime_EndTime(date, minute, false, aOLO, aOLO.Order.OrderTypeSubType, oTypeChanged);
        const today = (selectedDate.IsToday) ? selectedDate : OnlineOrderingUtil.GetDate_StartTime_EndTime(Util.NowStore(aOLO.Temp.TimeOffset), 0, false, aOLO, aOLO.Order.OrderTypeSubType, oTypeChanged);
        return {
            TD: today, //today
            SD: selectedDate //SelectedDate
        };
    }

    private _futureDateChanged = (): void => {
        this._futureOrderTime = null;
        const sDate = Util.GetDateInputDate(Util.getElementValue("txt_order_type_time_later_date"));
        if (sDate)
            this._futureOrderDate = Util.DateAdd(sDate, aOLO.data.Settings.BSM, 'm');
        this._setOrderTime(false);
        this.handleOrderTypeOrderTimeChange(false, true);
    }

    private _futureTimeChanged = (): void => {
        this._futureOrderTime = parseInt(Util.getElementValue("ddl_order_type_time_later_time")) || null;
        aOLO.Order.FutureMinute = parseInt(Util.getElementValue("ddl_order_type_time_later_time")) || -1;
        //this._setOrderTime(false);
    }

    private _setOrderTime = (warn: boolean): boolean => {
        let now = Util.getElementChecked("rdo_order_type_time_now");
        let later = Util.getElementChecked("rdo_order_type_time_later");
        let validDate = true;
        if (now) {
            aOLO.Order.FutureDate = null;
            aOLO.Order.FutureMinute = -1;
            aOLO.Order.BUSDate = Util.formatDate(Util.GetBUSDate(Util.NowStore(aOLO.Temp.TimeOffset), aOLO));
        } else if (later) {
            //let date = GetDateInputDate(Util.getElementValue("txt_order_type_time_later_date"));
            let date = this._futureOrderDate;
            if (date !== null && Util.DateDiff("d", new Date(Util.GetBUSDate(Util.NowStore(aOLO.Temp.TimeOffset), aOLO)), date) <= 0) {
                let DSTET = this._getTodayAndSelectedDateTimes(date, 0, false);

                if (DSTET.SD.IsClosedHoliday) {
                    DialogCreators.messageBoxOk(Names("ClosedHoliday").replace("??", Names(`Hol${DSTET.SD.HolidayID}`)), aOLO.buttonHoverStyle);

                    if (aOLO.Order.FutureDate) {
                        this._futureOrderDate = aOLO.Order.FutureDate;
                        Util.setElement("value", "txt_order_type_time_later_date", aOLO.Order.FutureDate.toISOString().split("T")[0]);
                    } else if (aOLO.Order.BUSDate) {
                        this._futureOrderDate = new Date(aOLO.Order.BUSDate);
                        Util.setElement("value", "txt_order_type_time_later_date", new Date(aOLO.Order.BUSDate));
                    } else {
                        const nextOpenDay = OnlineOrderingUtil.GetNextOpenDay(aOLO);
                        const finalDate = (nextOpenDay.Date || Util.NowStore(aOLO.Temp.TimeOffset));
                        this._futureOrderDate = finalDate;
                        Util.setElement("value", "txt_order_type_time_later_date", finalDate.toISOString().split("T")[0]);
                    }
                    validDate = true;
                } else if (!DSTET.SD.FutureOrder) {
                    if (DSTET.SD.IsToday) {
                        DialogCreators.messageBoxOk(Names("NoFutureOnlineAtMoment").replace("???", " "), aOLO.buttonHoverStyle, null, "txt_order_type_time_later_date");
                    } else {
                        DialogCreators.messageBoxOk(Names("NoFutureOnlineOrder"), aOLO.buttonHoverStyle, null, "txt_order_type_time_later_date");
                    }
                    validDate = false;
                    if (aOLO.Order.BUSDate) {
                        const bDate = new Date(aOLO.Order.BUSDate);
                        this._futureOrderDate = bDate;

                        Util.setElement("value", "txt_order_type_time_later_date", bDate.toISOString().split("T")[0]);
                    } else {
                        const nextOpenDay = OnlineOrderingUtil.GetNextOpenDay(aOLO);
                        const finalDate = (nextOpenDay.Date || Util.NowStore(aOLO.Temp.TimeOffset));
                        this._futureOrderDate = finalDate;
                        Util.setElement("value", "txt_order_type_time_later_date", finalDate.toISOString().split("T")[0]);
                    }
                } else {
                    aOLO.Order.FutureMinute = this._futureOrderTime || 0;
                    aOLO.Order.FutureDate = new Date(new Date(DSTET.SD.Date).setHours(0,0,0,0));
                    aOLO.Order.BUSDate = Util.formatDate(date);
                }
            } else if (warn) {
                DialogCreators.messageBoxOk(Names("InvalidDate"), aOLO.buttonHoverStyle, null, "txt_order_type_time_later_date");
                validDate = false;
            }
        } else if (warn) {
            DialogCreators.messageBoxOk(Names("SelectWhenYouWantToPlaceOrder"), aOLO.buttonHoverStyle, null, "rdo_order_type_time_now");
            validDate = false;
        }

        if (validDate)
            this.setOrderTypeTime();

        return validDate;
    }

    private _onContinueButtonClick = async (): Promise<void> => {
        let asapChecked = document.getElementById("rdo_order_type_time_now") as HTMLInputElement;

        if (aOLO.Order.FutureDate && (this._futureOrderTime === -1 || this._futureOrderTime == null)) {
            let msg = Names("SelectTime") + ' ';
            msg = msg.replace("??", OnlineOrderingUtil.GetOrderTypeName(aOLO.Order.OrderTypeID, aOLO));
            msg += Util.formatDate(aOLO.Order.FutureDate);
            DialogCreators.messageBoxOk(msg, aOLO.buttonHoverStyle, null, "ddl_order_type_time_later_time");
            return;
        }
        else if (!aOLO.Order.FutureDate && !asapChecked.checked && (this._futureOrderTime === -1 || this._futureOrderTime === null)) {
            let msg = Names("SelectTime") + ' ';
            msg = msg.replace("??", OnlineOrderingUtil.GetOrderTypeName(aOLO.Order.OrderTypeID, aOLO));
            DialogCreators.messageBoxOk(msg, aOLO.buttonHoverStyle, null, "ddl_order_type_time_later_time");
            return;
        }

        if (aOLO.Order.OrderTypeID === OrderType.DELIVERY && aOLO.Order.AddressID === 0 && aOLO.data.Settings.ADDRLOOKUP === AddressLookupType.NONE) {
            await this._validateNoLookupAddress((ValidationStatus: AddressValidation) => this._validateResultStatus(ValidationStatus));
            return;
        } else if (aOLO.Order.OrderTypeID === OrderType.DELIVERY && aOLO.Order.AddressID === 0 /*&& !aOLO.Temp.Address*/) { // new delivery address
            this._validateNotSelectedAddress((ValidationStatus: AddressValidation) => this._validateResultStatus(ValidationStatus));
            return;
        } else if (aOLO.Order.OrderTypeID === OrderType.DELIVERY && aOLO.Order.AddressID !== 0 && aOLO.data.Settings.ADDRLOOKUP !== AddressLookupType.NONE) { //user selected address
            this._validateNotSelectedAddress((ValidationStatus: AddressValidation) => this._validateResultStatus(ValidationStatus));
            return;
        }

        if (aOLO.data.Settings.TPDS && aOLO.data.Settings.TPDM === 1 && aOLO.Order.OrderTypeID === OrderType.DELIVERY) {
            if (!aOLO.Temp.Address && aOLO.Order.AddressID)
                aOLO.Temp.Address = this._getDeliveryAddress(aOLO.Order.AddressID);

            const adoraAddr: IThirdPartyAddress = {
                StreetNo: aOLO.Temp.Address?.StreetNo || "",
                StreetName: aOLO.Temp.Address?.StreetName || "",
                City: aOLO.Temp.Address?.City || "",
                State: aOLO.Temp.Address?.State || "",
                Zip: aOLO.Temp.Address?.Zip || ""
            };

            let result = await this._thirdPartyEstimate(adoraAddr);
            if (result)
                await this._onContinueButtonClick_Finish();
        } else
            await this._onContinueButtonClick_Finish();
    }

    private _validateResultStatus = (statusId: AddressValidation): void => {
        switch (statusId) {
            case AddressValidation.INVALID_ST_NUMBER:
                DialogCreators.messageBoxOk(Names("UnknownAddressError"), aOLO.buttonHoverStyle, null, "txt_order_type_street_no");
                break;
            case AddressValidation.INVALID_ST_NAME:
                DialogCreators.messageBoxOk(Names("UnknownAddressError"), aOLO.buttonHoverStyle, null, "txt_order_type_street_name");
                break;
            case AddressValidation.INVALID:
                DialogCreators.messageBoxOk(Names("UnknownAddressError"), aOLO.buttonHoverStyle, null, "txt_order_type_address");
                break;
            case AddressValidation.VALID:
                OnlineOrderingUtil.StartOrdering(aOLO.Order.OrderStartTime, aOLO.Temp.TimeOffset);
                this._onContinueButtonClick_Finish();
                break;
            case AddressValidation.OUT_OF_DELIVERY_ZONE:
                //DialogCreators.messageBoxOk(Names("OutDelivery"), null, "txt_order_type_address");
                break;
            case AddressValidation.OUT_OF_TIME:
                DialogCreators.messageBoxOk(Names("OutTimeDelivery"), aOLO.buttonHoverStyle, null, "txtOrderType_Address");
                break;
            case AddressValidation.INVALID_NO_ZIP:
                DialogCreators.messageBoxOk(Names("InvalidAddressNoZipCode"), aOLO.buttonHoverStyle, null, "txtOrderType_Address");
                break;
            case AddressValidation.INVALID_NO_STREET_NUMBER:
                DialogCreators.messageBoxOk(Names("InvalidAddressNoStreetNumber"), aOLO.buttonHoverStyle, null, "txtOrderType_Address");
                break;
            case AddressValidation.BLOCKED:
                DialogCreators.messageBoxOk(Names("OutDelivery"), aOLO.buttonHoverStyle, null, "txtOrderType_Address");
                break;
        }
        return;
    }

    private _onContinueButtonClick_Finish = async (): Promise<void> => {
        if (!this._setOrderTime(true))
            return;

        if (aOLO.Order.FutureDate && aOLO.Order.FutureMinute === -1) {
            let msg = Names("SelectTime");
            msg = msg.replace("??", OnlineOrderingUtil.GetOrderTypeName(aOLO.Order.OrderTypeID, aOLO));
            msg += ` ${Util.formatDate(aOLO.Order.FutureDate)}`;
            DialogCreators.messageBoxOk(msg, aOLO.buttonHoverStyle, null, "ddl_order_type_time_later_time");
        } else {
            this._setBoxTopper();
            OnlineOrderingUtil.StartOrdering(aOLO.Order.OrderStartTime, aOLO.Temp.TimeOffset);

            // check if currently applied coupons still apply
            const removedCouponIDs = await aOLO.Modules.Coupon.RemoveCouponAll();
            for (let i = removedCouponIDs.length - 1; i >= 0; i--) {
                const params: IApplyCouponByIdParams = {
                    couponId: removedCouponIDs[i].couponId,
                    showErrorMsg: true
                };
                await aOLO.Modules.Coupon.ApplyCouponByID(params);
            }

            this._functions.GUI_SetOrder(aOLO, aOLOModules);
            await this._setSpecialCode();
            this.closeDialog();

            if (!aOLO.Temp.RewardsDisplayed) {
                if (aOLO.data.Loyalty.ProgramTypes.length > 0 && aOLO.User.LoyaltyData && aOLO.User.LoyaltyData?.Rewards.length > 0 && !aOLO.specialCode && !aOLO.CloudCoupon)
                    aOLO.Dialog.Rewards = new Rewards(aOLO, aOLOModules.LoyaltyProvider);

                aOLO.Temp.RewardsDisplayed = true;
            }

            if (aOLO.reOrderId)
                this._functions.ReOrder(aOLO.reOrderId);

            if (aOLO.orderCouponId) {
                if (aOLO.data.Coupons.find(cpn => cpn.CouponId === aOLO.orderCouponId)) {
                    aOLO.Modules.Coupon.CheckCouponHasQualifyingItems(aOLO.orderCouponId);
                } else {
                    DialogCreators.messageBoxOk(Names("CouponNotActive"), aOLO.buttonHoverStyle);
                }
            }

            if (this._isLimitedTimes && aOLO.Modules.OrderCart)
                aOLO.Modules.OrderCart.setLimitedTimesTimer();
        }

        this._functions.GUI_LoadItems();

        if (aOLO.Modules.OrderCart)
            aOLO.Modules.OrderCart.challengesSection();

        if (aOLO.anchor)
            this._jumpToAnchor(aOLO.anchor);
    }

    private _jumpToAnchor = (webCategoryId: number): void => {
        let catHeight = 0;

        let divCats = document.getElementById("div_categories");
        if (divCats) {
            let divCatsParent = divCats.parentNode as HTMLElement;
            if (divCatsParent?.classList.contains("containerTopCat"))
                catHeight = divCats.offsetHeight;
        }

        let anchor = document.getElementById(`h1CatAnchor_${webCategoryId}`);
        if (anchor) {
            let offSet = -20;
            if (aOLO.layoutStyleID === 2 || aOLO.layoutStyleID === 3)
                offSet = -80;

            let anchorTop = anchor.offsetTop - aOLO.Temp.HeaderHeight - catHeight + offSet;
            OnlineOrderingUtil.GUI_ScrollTo(anchorTop, aOLO.Temp.HeaderHeight);
        }
    }

    private _validateNoLookupAddress = async (callbackFunction: Function): Promise<void> => {

        let zoneStat = OnlineOrderingUtil.CheckZones(aOLO.data.Settings.LAT, aOLO.data.Settings.LNG, []); //aOLO.data.DeliveryZones

        let streetNo = Util.getElementValue("txt_order_type_street_no");
        let streetName = Util.getElementValue("txt_order_type_street_name");

        if (streetNo == '') {
            callbackFunction(AddressValidation.INVALID_ST_NUMBER);
            return;
        }
        if (streetName == '') {
            callbackFunction(AddressValidation.INVALID_ST_NAME);
            return;
        }
        aOLO.Temp.Address = OnlineOrderingUtil.getTempAddressNew();
        aOLO.Temp.Address.ZoneID = zoneStat ? zoneStat.ZID : 0;
        aOLO.Temp.Address.StreetNo = streetNo;
        aOLO.Temp.Address.StreetName = streetName;
        aOLO.Temp.Address.City = Util.getElementValue("txt_order_type_city");
        aOLO.Temp.Address.State = Util.getElementValue("txt_order_type_state");
        aOLO.Temp.Address.Zip = Util.getElementValue("ddl_order_type_zip");
        aOLO.Temp.Address.Latitude = 0;
        aOLO.Temp.Address.Longitude = 0;
        let aptNum = Util.getElementValue("txt_order_type_apt");
        aOLO.Temp.Address.AddressTypeID = (aptNum !== "") ? AddressType.APT : AddressType.HOUSE;
        aOLO.Temp.Address.Address2 = aptNum;
        aOLO.Temp.NewAddress = JSON.parse(JSON.stringify(aOLO.Temp.Address));

        this._functions.setOrderTypeCharge(aOLO, aOLOModules);
        this._functions.GUI_SetOrder(aOLO, aOLOModules);
        callbackFunction(AddressValidation.VALID);

    }

    private _validateNotSelectedAddress = (callbackFunction: Function): void => {
        let addressString = "";
        let addressObject = null;
        if (aOLO.Temp.Address) {
            addressString = `${aOLO.Temp.Address.StreetNo} ${aOLO.Temp.Address.StreetName}, ${aOLO.Temp.Address.City}, ${aOLO.Temp.Address.State} ${aOLO.Temp.Address.Zip}`;
            addressObject = aOLO.Temp.Address;
        } else {
            addressString = Util.getElementValue("txt_order_type_address");
        }

        if (addressString !== "")
            this._fillInNotFoundInAdoraAddress(addressString, addressObject, callbackFunction);
        else
            callbackFunction(0);
    }

    private _fillInNotFoundInAdoraAddress = (addressString: string, addressObject: ITempAddress | null, callback: Function): void => {
        let hasNoZipCode = false;
        if (addressObject)
            hasNoZipCode = addressObject.Zip == "";
        else
            hasNoZipCode = (!aOLO.data.ZipCodes.split(',').some(substring => addressString.includes(substring)) || !(addressString.split(' ').length > 2));

        if (hasNoZipCode) {
            callback(AddressValidation.INVALID_NO_ZIP);
            return;
        }

        const state = aOLO.data.Settings.STAT;
        if (addressObject && addressObject.Zip == "")
            addressObject.State = state;
        else if ((`${addressString.toLowerCase()} `).indexOf(state.toLowerCase()) === -1)
            addressString += ` ${state} `;

        let iAddr = {
            StreetNo: addressObject?.StreetNo.trim() || "",
            StreetName: addressObject?.StreetName.trim() || "",
            StreetShortName: addressObject?.StreetName.trim() || "",
            City: addressObject?.City.trim() || "",
            State: addressObject?.State.trim() || "",
            Zip: addressObject?.Zip.trim() || "",
            Latitude: addressObject?.Latitude || 0,
            Longitude: addressObject?.Longitude || 0
        };

        if (!addressObject)
            iAddr = OnlineOrderingUtil.ParseAddress(addressString, addressObject, aOLO);

        if (iAddr.StreetNo === "") {
            callback(AddressValidation.INVALID_NO_STREET_NUMBER);
            return;
        }

        if (!aOLO.geocoder) {
            //@ts-ignore
            aOLO.geocoder = new google.maps.Geocoder();
        }

        aOLO.geocoder.geocode({ 'address': addressString },
            async (results: any, status: string) => {
                if (status !== 'OK') {
                    callback(AddressValidation.INVALID);
                    return;
                }

                const zips = aOLO.data.ZipCodes;

                //const allCities = this._item.locations.flatMap(x => x.cities);
                //const uniqueCities = Array.from(new Set(allCities));

                for (const googlePlace of results) {
                    
                    const googleAddr = OnlineOrderingUtil.ParseGooglePlace(googlePlace, iAddr.StreetNo, iAddr.City, [], aOLO);

                    if (zips.indexOf(googleAddr.Zip) !== -1 && callback) {
                        // Commented Temporarily
                        //const result = await this._fillInParsedAddress(googleAddr, iAddr);
                        //if (result == AddressValidation.VALID) {
                        //    // Check for Not Deliverable Address
                        //    const temp = aOLO.Temp.Address;
                        //    const address = temp ? `${temp.StreetNo} ${temp.StreetName} ${temp.City} ${temp.State} ${temp.Zip}` : "";
                        //    const payload: ICheckAddress = {
                        //        SKY: aOLO.storeInfo.StoreKey,
                        //        ADDR: address,
                        //        APT: Util.getElementValue("txt_order_type_apt")
                        //    };
                        //    const blocked = await aOLO.Modules.OnlineOrderingService.checkAddress(payload, aOLO);
                        //    callback(blocked ? AddressValidation.BLOCKED : AddressValidation.VALID);
                        //} else
                        //    callback(result);
                        return;
                    }
                }

                callback(AddressValidation.INVALID);
            }
        );
    }

    private _thirdPartyEstimate = async (addrData: IThirdPartyAddress): Promise<boolean> => {
        const info = {
            Info: this._getAddressDataForThirdParty(addrData),
            StoreID: aOLO.storeInfo.StoreID
        };
        let response = aOLO.Modules.OnlineOrderingService.thirdPartyEstimate(info, aOLO);
        if (!response)
            DialogCreators.messageBoxOk(Names("OutDelivery"), aOLO.buttonHoverStyle, null, "txt_order_type_address");
        return response;
    }

    private _getAddressDataForThirdParty = (adoraAddress: IThirdPartyAddress): string => {
        const data = {
            SCTY: aOLO.storeInfo.City,
            SSTAT: aOLO.storeInfo.State,
            SADDR: aOLO.storeInfo.Address1,
            SADDR2: "",
            SZIP: aOLO.storeInfo.Zip,
            DCTY: adoraAddress.City,
            DSTAT: adoraAddress.State,
            DSNO: adoraAddress.StreetNo,
            DADDR: adoraAddress.StreetName,
            DADDR2: "",
            DZIP: adoraAddress.Zip,
            OVAL: 0,
            PTIM: Util.NowStore(aOLO.Temp.TimeOffset).toISOString(),
            SID: aOLO.storeInfo.StoreID
        };
        return JSON.stringify(data);
    }

    public CallCenterStoreNotSelected = async (): Promise<void> => {
        Util.setElement("checked", "rdo_order_type_delivery", true);
        await this._functions.SetOrderType_Pre(OrderType.DELIVERY);
        if (aOLO.Dialog.CallCenterStores)
            delete aOLO.Dialog.CallCenterStores;
    }

    private _setBoxTopper = (): void => {
        if (!aOLO.boxTopper)
            return;

        if (!(Util.DateDiff('d', new Date(aOLO.boxTopper.ExpirationDate), Util.NowStore(aOLO.Temp.TimeOffset)) < 0))
            aOLO.Modules.Coupon.CheckCouponHasQualifyingItems(Number(aOLO.boxTopper.CouponId), null, aOLO.boxTopper.OrderId);
        delete aOLO.boxTopper;
    }

    private _setSpecialCode = async (): Promise<void> => {
        let code = aOLO.specialCode;

        if (code && code !== "") {
            await aOLO.Modules.Coupon.ApplyCouponByCode(code);
            //delete aOLO.specialCode;
        }

        let cloudCoupon = aOLO.CloudCoupon;
        if (cloudCoupon) {
            await aOLO.Modules.Coupon.ApplyCouponByCode(cloudCoupon.CouponCode);
            //delete aOLO.CloudCoupon;
        }
    }

    public setStoreWaitTimes = (store: IDataStore): void => {
        if (aOLO.data.OrderTypeSubTypes.length === 0)
            return;

        for (const ot of aOLO.data.OrderTypeSubTypes) {
            const wts = store.WaitTimes.find(w => w.OrderTypeId === ot.OrderTypeId);
            if (wts) {
                Util.setElement("innerText", `span_order_type_${ot.OrderTypeId}_name`, Common.GetName(ot.Names, aOLO.Temp.languageCode));
                Util.setElement("innerHTML", `span_order_type_${ot.OrderTypeId}_wait`, `${wts.WaitTime} <span ltag="Minutes">${Names("Minutes")}</span>`);
            }
        }
    }

    public setOrderTypeTime = (): void => {
        let orderTime = Names("ASAP");
        if (aOLO.Order.FutureDate) {
            const iDate = new Date(aOLO.Order.FutureDate.setHours(0, 0, 0, 0));
            const pDateTime = Util.DateAdd(iDate, aOLO.Order.FutureMinute != -1 ? aOLO.Order.FutureMinute : 0, 'm');
            orderTime = Util.formatDateTime(pDateTime);
        }

        const cartOrderTimeDiv = document.getElementById("spn_cart_order_time");
        if (cartOrderTimeDiv) {
            cartOrderTimeDiv.innerText = orderTime

            if (orderTime == Names("ASAP"))
                cartOrderTimeDiv.setAttribute("ltag", "ASAP");
            else
                cartOrderTimeDiv.removeAttribute("ltag");
        }

        Util.setElement("innerText", "spn_cart_order_time", orderTime);
        Util.setElement("innerHTML", "div_checkout_order_time", `<span ${(orderTime == Names("ASAP")) ? `ltag="ASAP"` : ""}>${orderTime}</span>`);

        if (aOLO.Order.OrderTypeID === 0)
            aOLO.Order.OrderTypeID = 2; // Take Out

        const orderType = OnlineOrderingUtil.getOrderType(aOLO.Order.OrderTypeID, aOLO);
        const cartOrderTypeDiv = document.getElementById("spn_cart_order_type");
        if (cartOrderTypeDiv) {
            cartOrderTypeDiv.innerText = OnlineOrderingUtil.GetOrderTypeName(aOLO.Order.OrderTypeID, aOLO);
            if (orderType)
                cartOrderTypeDiv.setAttribute("ltagj", Util.toLtagj(orderType.Names));
        }

        Util.setElement("innerText", "div_cart_address1", "");
        Util.setElement("innerText", "div_cart_address2", "");
        Util.setElement("innerText", "div_cart_city_state", "");

        if (aOLO.Temp.Address && aOLO.Order.OrderTypeID === OrderType.DELIVERY) {
            let unit = aOLO.Temp.Address.Address2.trim();
            if (unit !== "")
                unit = ` #${unit}`;

            const tempAddress: IAddressFormat = {
                StreetNo: aOLO.Temp.Address.StreetNo,
                Address1: aOLO.Temp.Address.StreetName,
                Address2: aOLO.Temp.Address.Address2,
                Address3: aOLO.Temp.Address.Address3,
                Address4: aOLO.Temp.Address.Address4,
                Address5: aOLO.Temp.Address.Address5,
                City: aOLO.Temp.Address.City,
                State: aOLO.Temp.Address.State,
                ZipCode: aOLO.Temp.Address.Zip,
                CountryID: aOLO.Temp.Address.CountryID || 1,
                AddressTypeID: aOLO.Temp.Address.AddressTypeID
            };

            const addressObject = Util.formatAddressObject(tempAddress, aOLO.data.Countries);

            Util.setElement("innerText", "div_cart_address1", `${addressObject.address1}${unit}`);
            if (addressObject.address3)
                Util.setElement("innerText", "div_cart_address2", `${addressObject.address3}`);
            Util.setElement("innerText", "div_cart_city_state", `${addressObject.cityState}`);

            Util.setElement("innerText", "lbl_checkout_address", `${addressObject.address1}${unit}, ${addressObject.address3 ? `${addressObject.address3}, ` : ""}${addressObject.cityState}`);
        }
    }
  

    public setStoreInfo = (): void => {
        Util.hideElement("div_order_type_store");

        if (!aOLO.data.Settings.ISCC || aOLO.Order.OrderTypeID === OrderType.DELIVERY)
            return;

        let store = OnlineOrderingUtil.getCallCenterStore(aOLO.Order.StoreID, aOLO.data.Stores);
        if (!store)
            return;

        const imgStore = document.getElementById("img_order_type_store") as HTMLImageElement;
        if (imgStore) {
            imgStore.src = `${aOLO.data.Settings.CURL}/stores/store-${store.StoreId}.jpg`;
            imgStore.setAttribute("alt", "store image");
        }

        Util.setElement("innerText", "h3_order_type_store_name", store.Name);
        Util.setElement("innerText", "h5_order_type_store_nick_name", store.Nickname);
        Util.setElement("innerText", "h5_order_type_store_address_1", store.Address1);
        Util.setElement("innerText", "h5_order_type_store_address_2", `${store.City} ${store.State} ${store.ZipCode}`);
        Util.showElement("div_order_type_store");
    }

    public setLimitedTime = (minutes: number): void => {
        this._isLimitedTimes = true;
        this._futureOrderTime = minutes;
        Util.setElement("value", "txt_order_type_time_later_time", Util.formatTime(Util.getDateFromMinutes(minutes, aOLO.Temp.TimeOffset)));
        const button = document.getElementById("btn_order_type_select_limited_time");
        if (button)
            button.innerText = Names("ChangeTime");
    }

    private _clearLimitedTime = (): void => {
        this._isLimitedTimes = false;
        this._futureOrderTime = null;
        Util.setElement("value", "txt_order_type_time_later_time", "");
    }
}