import './header.css';

import EventEmitter from "eventemitter3";
import { EventTypes } from '../../../core/event-bus';
import { Pages } from '../../../types/enums';
import { IaOLO } from '../../../interfaces/aolo.interface';
import { User } from '../../../models/user';
import { LoginManager } from '../../../services/auth/login-manager';
import { ICustomerProvider } from '../../../services/customer/interfaces/customer-provider.interface';
import { Names } from '../../../utils/i18n';
import { Util } from '../../../utils/util';
import { LoginDialog } from '../../popups/Login/login';
import { Hamburger } from "../Hamburger/Hamburger";

export class Header {
    private readonly _eventBus: EventEmitter;
    private readonly _customerProvider: ICustomerProvider;
    private readonly _loginManager: LoginManager;
    private readonly _user: User;
    private readonly _aOLO: IaOLO;
    private readonly _displayList: { [key: string]: any } = {};
    private readonly _hamburger: Hamburger;

    constructor(eventBus: EventEmitter, customerProvider: ICustomerProvider, loginManager: LoginManager, user: User, aOLO: IaOLO) {
        this._eventBus = eventBus;
        this._customerProvider = customerProvider;
        this._loginManager = loginManager;
        this._user = user;
        this._aOLO = aOLO;
        this._hamburger = new Hamburger(this._eventBus, this._customerProvider, this._loginManager, this._user, this._aOLO);
        this._hamburger.init();
        this._createDisplayList();
        this._eventBusListeners();
    }

    private readonly _createDisplayList = (): void => {
        this._displayList[Pages.LANDING] = { showHamburger: true, showBackArrow: false, showLogo: true, text: "", showRightSide: true };
        this._displayList[Pages.LOCATIONS] = { showHamburger: false, showBackArrow: true, showLogo: false, text: "SelectLocation", showRightSide: false };
        this._displayList[Pages.MENU] = { showHamburger: true, showBackArrow: false, showLogo: false, text: "Menu", showRightSide: true };
        this._displayList[Pages.REWARDS] = { showHamburger: true, showBackArrow: false, showLogo: true, text: "", showRightSide: true };
        this._displayList[Pages.FAVORITES] = { showHamburger: true, showBackArrow: false, showLogo: true, text: "", showRightSide: true };
        this._displayList[Pages.ORDERS] = { showHamburger: true, showBackArrow: false, showLogo: true, text: "", showRightSide: true };
    }

    public init = (): void => {
        this._renderPage();
        this._setEventListeners();
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.LOGGED_IN, this._loggedInEvent);
        this._eventBus.on(EventTypes.LOGGED_OUT, this._loggedOutEvent);
        this._eventBus.on(EventTypes.LOCATION_CHANGE, this._locationChanged);
    }

    private readonly _renderPage = (): void => {

    }

    private readonly _setEventListeners = (): void => {
        Util.setElement("onclick", "div_header_hamburger_menu", () => {
            this._hamburger.open();
        });

        Util.setElement("onclick", "div_header_arrow_back", () => { window.history.back(); });
        Util.setElement("onclick", "div_header_store_logo", () => { this._eventBus.emit(EventTypes.LOCATION_CHANGE, Pages.LANDING); });
        Util.setElement("onclick", "btn_header_login", () => { this._logInOnClick(); });
        Util.setElement("onclick", "div_header_profile", this._logoutTempOnClick);
        Util.setElement("onclick", "div_header_shopping_bag", () => { });



        //const darkMode = document.getElementsByName("btn_dark_mode");
        //darkMode.forEach(x => {
        //    x.onclick = () => { Common.toggleDarkMode(aOLO.Temp, aOLO.ProfileUser); };
        //});

        //Util.setElement("onclick", "btn_header_order_now_desktop", () => {
        //    if (this._displayPage)
        //        this._displayPage('locations', true);
        //});

        //if (Util.isAppView()) {
        //    if (aOLO.isBrandSignIn && Util.isiOS()) {
        //        Util.setElementClass("add", "div_header", "store-info-app");
        //        Util.setElementClass("add", "nav_main_hamburger_toggler", "app");
        //        Util.setElementClass("add", "nav_main_hamburger_menu", "app");
        //        Util.setElement("onclick", "div_header_back_button", () => {
        //            goBack();
        //        });
        //    }

        //    if (aOLO.storeInfo.BrandID.toLowerCase() !== '8402E2D5-B196-4574-9DDD-30C5615F57A1'.toLowerCase()) // Temp Fix for Punchh MMP App
        //        Util.setElement("onclick", "img_header_store_logo", () => {
        //            if (aOLO.isBrandSignIn && this._displayPage)
        //                this._displayPage('landing', true);
        //            else {
        //                const url = `${location.origin}/brand/?sk=${aOLO.storeInfo.StoreKey}&mobile=true`;
        //                window.location.href = url;
        //            }
        //        });
        //}
    }

    private readonly _logInOnClick = (): void => {
        const dialog = new LoginDialog(this._eventBus, this._customerProvider, this._loginManager, this._aOLO);
        dialog.init();
    }

    private readonly _loggedInEvent = (): void => {
        Util.hideElement("btn_header_login");
        Util.showElement("div_header_profile");
    }

    private readonly _loggedOutEvent = (): void => {
        Util.showElement("btn_header_login");
        Util.hideElement("div_header_profile");
    }

    private readonly _locationChanged = (page: Pages): void => {
        const temp = this._displayList[page];
        if (!temp)
            return;

        (temp.showHamburger) ? Util.showElement("div_header_hamburger_menu") : Util.hideElement("div_header_hamburger_menu");
        (temp.showBackArrow) ? Util.showElement("div_header_arrow_back") : Util.hideElement("div_header_arrow_back");
        (temp.showLogo) ? Util.showElement("div_header_store_logo") : Util.hideElement("div_header_store_logo");
        (temp.showRightSide) ? Util.showElement("div_header_right") : Util.hideElement("div_header_right");

        if (temp.text !== "") {
            Util.showElement("div_header_center_text");
            Util.setElement("innerText", "div_header_center_text", Names(temp.text));
        } else {
            Util.hideElement("div_header_center_text");
            Util.setElement("innerText", "div_header_center_text", "");
        }
    }

    private readonly _logoutTempOnClick = (): void => {
        this._loginManager.logout(this._user);
    }
}