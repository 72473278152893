import { FieldType, EmailTextMarketingType, LoyaltyProvider } from '../types/enums';
import { Address } from './address';
import { TwoWayBinding } from '../two-way-binding';
import { Common } from '../common';
import { Util } from '../utils/util';
import { Names } from '../utils/i18n';
import { IUserAddress } from '../models/interfaces/user.interface';
import { IProfile, IProfileAddress } from './interfaces/profile.interfaces';
import { IaOLOUser } from '../services/api/interfaces/profile-service.interfaces';
import { DialogCreators } from '../utils/dialog-creators';
import { SignIn } from './sign-in';
import { IaOLO } from '../interfaces/aolo.interface';

import '../../css/shared/profile.css';

/**
 * Represents a user profile.
 */
export class Profile {
    /**
     * @private
     * @property {IProfile} _item - The Profile object's data.
     */
    private _item: IProfile;
    /**
     * @private
     * @property {boolean} _dirty - Flag indicating whether the Profile object's data has been modified.
     */
    private _dirty: boolean = false;
    /**
     * @private
     * @property {boolean} _loyOptedOut - Indicates whether the user has opted out of loyalty program.
     */
    private _loyOptedOut: boolean = false;
    /**
     * @private
     * @property {boolean} _loyHide - Flag indicating whether the loyalty program should be hidden.
     */
    private _loyHide: boolean = false;
    /**
     * @private
     * @property {boolean} _signUp - Flag indicating whether the user has signed up for the profile.
     */
    private _signUp: boolean = false;
    /**
      * @private
      * @property {TwoWayBinding[]} _bindings - An array of two-way bindings used to keep the Profile object's data in sync with the UI.
      */
    private _bindings: TwoWayBinding[] = [];
    private _brandFunction: Function | null;
    private _oloFunction: Function | null;
    private _signInClass: SignIn;
    private _incompleteUser: boolean;
    private _birthdayRequirement: number = aOLO.data.Settings.BDAYREQ;

    /**
     * Creates an instance of Profile.
     */
    constructor(brandFunction: Function | null, oloFunction: Function | null, signInClass: SignIn) {
        this._brandFunction = brandFunction;
        this._oloFunction = oloFunction;
        this._signInClass = signInClass;
        this._incompleteUser = (aOLO.User && aOLO.User.IsProfileComplete == false) || false;
        this._item = this._setDefault();
        this._initProfile();
    }

    /**
     * Sets the loyHide property of the profile.
     * @param {boolean} value - The value to set.
     */
    set loyHide(value: boolean) {
        this._loyHide = value;
    }

    /**
     * Sets the dirty property of the profile.
     * @param {boolean} value - The value to set.
     */
    set dirty(value) {
        this._dirty = value;
        for (let i = 0; i < this._bindings.length; i++) {
            this._bindings[i].dirty = value;
        }
    }

    /**
     * Gets the dirty property of the profile.
     * @returns {boolean} - The value of the dirty property.
     */
    get dirty() {
        if (this._dirty)
            return this._dirty;

        for (let i = 0; i < this._bindings.length; i++) {
            if (this._bindings[i].dirty) {
                this._dirty = true;
                break;
            }
        }
        return this._dirty;
    }

    public GetAddresses = (): IProfileAddress[] => {
        return this._item.Addresses;
    }

    public UpdateLanguage = (languageCode: string): void => {
        this._item.CultureCode = languageCode;
    } 

    /**
     * Returns a new IProfile object with default values.
     * @private
     * @returns {IProfile} The default profile object.
     */
    private _setDefault = (): IProfile => {
        return {
            CustomerId: 0,
            Name: "",
            LastName: "",
            Phone: "",
            CountryCodeId: aOLO.User?.CountryCodeId || aOLO.storeInfo?.Country?.CountryID || 1,
            Email: "",
            pwd: "",
            repeatPwd: "",
            BirthDate: null,
            BirthMonth: 1,
            BirthDay: 0,
            IsMarketingEmail: [EmailTextMarketingType.EMAIL_ONLY, EmailTextMarketingType.EMAIL_AND_TEXT].includes(aOLO.data.Settings.MRKEML),
            IsMarketingText: !['', null].includes(aOLO.data.Settings.TXNTFY) && [EmailTextMarketingType.EMAIL_AND_TEXT, EmailTextMarketingType.TEXT_ONLY].includes(aOLO.data.Settings.MRKEML),
            MarketingPhone: "",
            MarketingCountryCodeId: aOLO.User?.MarketingCountryId || aOLO.storeInfo?.Country?.CountryID || 1,
            IsMarketingSurvey: false,
            IsLoyalty: true,
            IsBlocked: false,
            StoreId: Number(aOLO.storeInfo.StoreID),
            ProfileId: 0,
            DefaultStoreId: null,
            DefaultStoreKey: "",
            CultureCode: aOLO.Temp.languageCode,
            TermsPrivacyPolicy: false,
            IsAge13:false,
            Addresses: [],
            Dietary: [],
            Allergies: [],
        };
    }

    /**
     * Sets the value of mrkLoy property in the ProfileInterface object.
     * @param {boolean} value - The value to set for mrkLoy property.
     * @returns {void}
     */
    public setLoyalty(value: boolean): void {
        this._item.IsLoyalty = value;
    }

    /**
     * Initializes the profile by creating the model, hiding the error elements, setting the values,
     * and adding event listeners.
     * @private
     * @returns {void}
     */
    private _initProfile = (): void => {
        this._createModel();
        this._hideErrorElements();
        this.setValues();
        this._populateLists();
        this._setTwoWayListeners();
        this._setEventListeners();
    }

    /**
     * Adds event listeners to the UI elements for two-way data binding.
     * @private
     * @return {void}
     */
    private _setTwoWayListeners = (): void => {
        this._bindings = [
            new TwoWayBinding(this._item, "Name", document.getElementById("txt_profile_name")),
            new TwoWayBinding(this._item, "LastName", document.getElementById("txt_profile_lastname")),
            new TwoWayBinding(this._item, "CountryCodeId", document.getElementById("dropdown_profile_country_code")),
            new TwoWayBinding(this._item, "MarketingCountryCodeId", document.getElementById("dropdown_promo_country_code")),
            new TwoWayBinding(this._item, "Email", document.getElementById("eml_profile_email")),
            new TwoWayBinding(this._item, "pwd", document.getElementById("pas_profile_password1")),
            new TwoWayBinding(this._item, "repeatPwd", document.getElementById("pas_profile_password2")),
            new TwoWayBinding(this._item, "IsMarketingEmail", document.getElementById("chk_profile_mark_promo_email")),
            new TwoWayBinding(this._item, "IsMarketingText", document.getElementById("chk_profile_mark_promo_text")),
            new TwoWayBinding(this._item, "IsLoyalty", document.getElementById("chk_profile_join_loyalty")),
            new TwoWayBinding(this._item, "IsMarketingSurvey", document.getElementById("chk_profile_survey")),
            new TwoWayBinding(this._item, "IsAge13", document.getElementById("chk_profile_age_13")),
            new TwoWayBinding(this._item, "TermsPrivacyPolicy", document.getElementById("chk_profile_terms_privacy_policy"))
        ];
    }

    /**
     * Creates a model for the Profile class based on the current user's information.
     * @private
     * @returns {void}
     */
    private _createModel = (): void => {
        if (aOLO.User.ProfileId != 0) {
            //Customer general info
            this._item.CustomerId = aOLO.User.CustomerId;
            this._item.Name = aOLO.User.Name || "";
            this._item.LastName = aOLO.User.LastName || "";
            this._item.CountryCodeId = aOLO.User?.CountryCodeId || aOLO.storeInfo?.Country?.CountryID || 1;
            this._item.MarketingCountryCodeId = aOLO.User?.MarketingCountryId || aOLO.storeInfo?.Country?.CountryID || 1;
            this._item.Phone = aOLO.User.Phone || "";
            this._item.Email = aOLO.User.Email || "";
            this._item.pwd = "";
            this._item.repeatPwd = "";
            this._item.DefaultStoreId = aOLO.User.FavoriteStoreId || null;
            this._item.DefaultStoreKey = aOLO.User.DefaultStore?.StoreKey || "";

            //Addresses
            this._item.Addresses = JSON.parse(JSON.stringify(aOLO.User.Addresses))        //creating a deep copy of User addresses array...
                .sort((x: any, y: IUserAddress) => (+ y.IPRM - x.IPRM))      //... and then sorting it so that the default address is listed first
                .map((x: IUserAddress) => {                                  //... and then transform original wrong-named address array object to correctly-named
                    return {
                        AID: x.AID,
                        STRNO: x.STRNO,
                        ADDR1: x.ADDR1,
                        ADDR2: x.ADDR2,
                        ADDR3: x.ADDR3,
                        ADDR4: x.ADDR4,
                        ADDR5: x.ADDR5,
                        XSTR: x.XSTR,
                        CITY: x.CITY,
                        STA: x.STA,
                        ZIP: x.ZIP,
                        CID: x.CID,
                        LAT: x.LAT,
                        LON: x.LON,
                        IPRM: x.IPRM
                    };
                }
                );

            //Dietry & Allergy
            this._item.Dietary = JSON.parse(JSON.stringify(aOLO.User.Dietary));
            this._item.Allergies = JSON.parse(JSON.stringify(aOLO.User.Allergies));

            //Loyalty & marketing comms setting
            this._item.IsMarketingText = aOLO.User.IsMarketingText || false;
            this._item.BirthDate = (aOLO.User.BirthDate) ? new Date(JSON.parse(JSON.stringify(aOLO.User.BirthDate))) : null;
            this._item.BirthMonth = (aOLO.User.BirthDate) ? (new Date(aOLO.User.BirthDate).getMonth() + 1) : 1;
            this._item.BirthDay = (aOLO.User.BirthDate) ? new Date(aOLO.User.BirthDate).getDate() : 0;
            this._item.IsMarketingEmail = aOLO.User.IsMarketingEmail || false;
            this._item.MarketingPhone = aOLO.User.MarketingPhone || aOLO.User.Phone || "";
            this._item.IsMarketingSurvey = aOLO.User.IsMarketingSurvey || false;
            this._item.IsLoyalty = aOLO.User.IsLoyalty || false;
            this._item.IsBlocked = aOLO.User.IsBlocked || false;
            this._item.StoreId = aOLO.User.StoreId ?? Number(aOLO.storeInfo.StoreID);
            this._item.ProfileId = aOLO.User.ProfileId;
            this._item.CultureCode = aOLO.User.CultureCode || "";
            this._item.TermsPrivacyPolicy = aOLO.User.TermsPrivacyPolicy || false;
            this._item.IsAge13 = aOLO.User.IsAge13 || false;

            this._loyHide = this._item.IsLoyalty;
            this._signUp = false;

            this._cleanAddressesAndSetDefaultAddress();

            if (this._incompleteUser) {
                this._signUp = true;
                this._item.TermsPrivacyPolicy = false;
                this._item.IsAge13 = false;
            }
        } else {
            this._signUp = true;

            if (aOLO.Temp.SignUpLoyalty) {
                this._item.IsLoyalty = true;
                delete aOLO.Temp.SignUpLoyalty;
            } else if (aOLO.SignUpLoyaltyLink) {
                this._item.Email = aOLO.SignUpLoyaltyLink.Email ?? this._item.Email;
                this._item.IsLoyalty = true;
            }
        }

        this._loyOptedOut = false;
        this.dirty = false;
    }

    /**
     * Removes duplicate default addresses if any and sets the last listed default address as a default one.
     * @private
     * @returns {void}
     */
    private _cleanAddressesAndSetDefaultAddress = (): void => {
        let duplCount = this._item.Addresses.filter(x => x.IPRM === true).length;
        if (duplCount === 0) return;
        let arr = this._item.Addresses.filter(x => x.IPRM === true).sort((x: any, y: IProfileAddress) => (x.AID - y.AID));
        if (!arr) return;
        for (let i = 0; i < arr.length; i++)
            arr[i].IPRM = false;
        arr[0].IPRM = true;
    }

    /**
     * Hides all error elements displayed on the profile page.
     * @private
     * @returns {void}
     */
    private _hideErrorElements = (): void => {
        Util.hideElement("spn_profile_incomplete_error");
        Util.hideElement("spn_profile_name_error");
        Util.hideElement("spn_profile_lastname_error");
        Util.hideElement("spn_profile_phone_error");
        Util.hideElement("spn_profile_phone_same_error");
        Util.hideElement("spn_profile_email_error");
        Util.hideElement("spn_profile_email_same_error");
        Util.hideElement("spn_profile_pass1_error");
        Util.hideElement("spn_profile_pass2_error");

        Util.hideElement("spn_profile_birth_day_error");
        Util.hideElement("spn_profile_mark_promo_text_error");
        Util.hideElement("spn_profile_age_13_error");
        Util.hideElement("spn_profile_privacy_error");
    }

    /**
     * Sets the values of the Profile fields based on the user data.
     * If the user is logged in, it hides/shows the relevant elements, and sets the corresponding values.
     * If the user is not logged in, it shows/hides the relevant elements and sets the corresponding values.
     * @returns {void}
     */
    public setValues = (): void => {
        aOLOModules.LoyaltyProvider.setProfileValues(this._item, this._signUp, this._monthOnClick, this.signUp, this.updateProfile,
            this.renderAddressList, this._setUserAllergies, this._setUserDietary, this.deleteProfile);
    }

    /**
     * Sets up event listeners for the Profile class.
     * @private
     * @returns {void}
     */
    private _setEventListeners = (): void => {
        const self = this;

        // Marketing Phone Check
        Util.setElement("onclick", "chk_profile_mark_promo_text", self._textMarketingOnClick);

        Util.setElement("onchange", "txt_profile_phone", () => {
            let element = document.getElementById("txt_profile_phone") as HTMLInputElement;
            if (element) {
                this._item.Phone = Util.cleanNonDigits(element.value);
                this._dirty = true;
            }
        });

        Util.setElement("onchange", "txt_profile_mark_promo_text", () => {
            let element = document.getElementById("txt_profile_mark_promo_text") as HTMLInputElement;
            if (element) {
                this._item.MarketingPhone = Util.cleanNonDigits(element.value);
                this._dirty = true;
            }
        });

        // Birthday Month
        Util.setElement("onchange", "sel_profile_birth_month", () => {
            let element = document.getElementById("sel_profile_birth_month") as HTMLInputElement;
            if (element) {
                self._monthOnClick()
                this._item.BirthMonth = parseInt(element.value);
                this._item.BirthDate = new Date(2000, this._item.BirthMonth - 1, this._item.BirthDay);
                this._dirty = true;
            }
        });

        // Birthday Day
        Util.setElement("onchange", "sel_profile_birth_day", () => {
            let element = document.getElementById("sel_profile_birth_day") as HTMLInputElement;
            if (element) {
                this._item.BirthDay = parseInt(element.value);
                this._item.BirthDate = this._item.BirthDay != 0 ? new Date(2000, this._item.BirthMonth - 1, this._item.BirthDay) : null;
                this._dirty = true;
            }
        });

        // Allergies
        let allergies = Array.from(document.getElementsByName("chk_profile_allergies")) as HTMLInputElement[];
        allergies.forEach((x: HTMLInputElement) => {
            Util.setElement("onclick", x.id, () => {
                if (x.checked)
                    this._item.Allergies.push({ AID: Number(x.dataset.allergid) });
                else
                    this._item.Allergies = this._item.Allergies.filter(y => y.AID != Number(x.dataset.allergid));
                this._dirty = true;
            });
        });

        // Dietary
        let dietaries = Array.from(document.getElementsByName("chk_profile_dietaries")) as HTMLInputElement[];
        dietaries.forEach((x: HTMLInputElement) => {
            Util.setElement("onclick", x.id, () => {
                if (x.checked)
                    this._item.Dietary.push({ DID: Number(x.dataset.dietid) });
                else
                    this._item.Dietary = this._item.Dietary.filter(y => y.DID != Number(x.dataset.dietid));
                this._dirty = true;
            });
        });

        Util.setElement("onclick", "btn_profile_privacy_terms_terms", () => { Common.showTerms(aOLO); });
        Util.setElement("onclick", "btn_profile_privacy_terms_privacy", () => { Common.showPrivacy(aOLO); });

        Util.setElement("onclick", "btn_profile_close", () => {
            if (self.checkUnsavedChanges("", false))
                self.closeDialog();
        });

        Util.setElement("onclick", "btn_profile_order_now", () => {
            if (self._brandFunction)
                self._brandFunction('locations', true);
        });
    }

    /**
     * Populates dropdowns for country code selectors
     * @private
     * @returns {void}
     */
    private _populateLists = (): void => {
        Util.populateCountryCodeSelectList("dropdown_profile_country_code", "txt_profile_phone", aOLO.User?.CountryCodeId || aOLO.storeInfo?.Country?.CountryID || 1);
        Util.populateCountryCodeSelectList("dropdown_promo_country_code", "txt_profile_mark_promo_text", aOLO.User?.MarketingCountryId || aOLO.storeInfo?.Country?.CountryID || 1);
    }

    /**
     * Handles onclick event for the marketing text checkbox.
     * @private
     * @returns {void}
     */
    private _textMarketingOnClick = (): void => {
        let textChk = document.getElementById("chk_profile_mark_promo_text") as HTMLInputElement;
        if (textChk) {
            Util.setElement("disabled", "txt_profile_mark_promo_text", !textChk.checked);
            Util.setElement("disabled", "dropdown_promo_country_code", !textChk.checked);

            this._item.IsMarketingText = textChk.checked;
        }
        this._dirty = true;
    }

    /**
     * Handles onclick event for the month select element.
     * @private
     * @returns {void}
     */
    private _monthOnClick = (): void => {
        let monthSel = document.getElementById("sel_profile_birth_month") as HTMLSelectElement
        this._createDaysList((monthSel?.value !== "") ? parseInt(monthSel.value) : 0);
    }

    /**
     * Creates a list of days based on the selected month.
     * @private
     * @param {number} month - The month number.
     * @returns {void}
     */
    private _createDaysList = (month: number): void => {
        let elem = document.getElementById("sel_profile_birth_day") as HTMLSelectElement;
        if (!elem) return;

        elem.innerHTML = "";
        let days = new Date(2000, month, 0).getDate();
        for (let i = 1; i <= days; i++) {
            let opt;
            if (i === 1) {
                opt = document.createElement("option") as HTMLOptionElement;
                opt.value = "0";
                opt.innerHTML = Names("MarkSelDay");
                opt.setAttribute("ltag", "MarkSelDay");
                elem.appendChild(opt);
            }
            opt = document.createElement("option") as HTMLOptionElement;
            opt.value = i.toString();
            opt.innerHTML = i.toString();
            elem.appendChild(opt);
        }
    }

    /**
     * Sets user allergies based on data in _item.mrkAlrg array.
     * @private
     * @returns {void}
     */
    private _setUserAllergies = (): void => {
        let allergies = Array.from(document.getElementsByName("chk_profile_allergies")) as HTMLInputElement[];
        if (this._item.Allergies.length > 0) {
            allergies.forEach((allergy: HTMLInputElement) => {
                let selectedAllergy = this._item.Allergies.find(x => x.AID == parseInt(allergy.dataset.allergid || "-1"));
                allergy.checked = (selectedAllergy) ? true : false;
            });
        } else
            allergies.forEach((allergy: HTMLInputElement) => { allergy.checked = false; });
    }

    /**
     * Sets user dietary restrictions based on data in _item.mrkDiet array.
     * @private
     * @returns {void}
     */
    private _setUserDietary = (): void => {
        let dietaries = Array.from(document.getElementsByName("chk_profile_dietaries")) as HTMLInputElement[];
        if (this._item.Dietary.length > 0) {
            dietaries.forEach((dietary: HTMLInputElement) => {
                let selectedDietary = this._item.Dietary.find(x => x.DID == parseInt(dietary.dataset.dietid || "-1"));
                dietary.checked = (selectedDietary) ? true : false;
            });
        } else
            dietaries.forEach((dietary: HTMLInputElement) => { dietary.checked = false; });
    }

    /* Address Section */

    /**
     * Renders the list of all addresses in the profile
     * @returns {void}
     */
    public renderAddressList = (): void => {
        aOLOModules.LoyaltyProvider.renderAddressList(this._item, this._addressEdit, this._addressRemove, this.addressSetDefault);
    }

    /**
     * Removes an address from the profile by its ID
     * @private
     * @param {string} id - The ID of the address to be removed
     * @returns {void}
     */
    private _addressRemove = (id: string): void => {
        let idx = this._item.Addresses.findIndex(x => x.AID === parseInt(id));
        if (idx !== -1) {
            let isDefault = this._item.Addresses[idx].IPRM;
            this._item.Addresses.splice(idx, 1);
            if (isDefault && (this._item.Addresses.length > 0))
                this._item.Addresses[0].IPRM = true; // if default address was deleted => set the 1st one as a default
        }
        this.dirty = true;
    }

    /**
     * Sets the specified address as the default address for the profile.
     * @param {string} id - The ID of the address to be set as default.
     * @returns {void}
     */
    public addressSetDefault = (id: string): void => {
        for (let i = 0; i < this._item.Addresses.length; i++) {
            this._item.Addresses[i].IPRM = false;
        }
        let addrElement = this._item.Addresses.find(x => x.AID === parseInt(id));
        if (addrElement !== undefined)
            addrElement.IPRM = true;
        this.dirty = true;
    }

    /**
     * Edits the specified address of the profile.
     * @private
     * @param {string} id - The ID of the address to be edited.
     * @returns {void}
     */
    private _addressEdit = (id: string): void => {
        if (aOLO.Dialog.Address)
            delete aOLO.Dialog.Address;

        let address = this._item.Addresses.find(x => x.AID === parseInt(id));
        if (!address)
            return;

        aOLO.Dialog.Address = new Address(address);
    }

    /* Update Profile Section */

    /**
     * Updates the user's profile, prompting a confirmation message if the user has opted out of loyalty.
     * @async
     * @returns {Promise<void>} A promise that resolves with no value when the update has completed.
     */
    public updateProfile = async (): Promise<void> => {
        if (this._loyOptedOut) {
            DialogCreators.messageBox(Names("CP_DeleteLoyProfileConfirmation"), aOLO.buttonHoverStyle, [
                { "text": Names("Continue"), "callBack": () => { this.updateProfileInternal(); } },
                { "text": Names("Cancel"), "callBack": null }]);
            return;
        }
        let loyaltyUpdate = this._loyHide;
        const saveResult = await this.updateProfileInternal();

        if (saveResult) {
            if (loyaltyUpdate != this._loyHide && this._item.IsLoyalty) {
                // 2DO: Common.setHamburgerMenuItems(aOLO);
                DialogCreators.messageBoxOk(Names("SuccessfullyUpdatedProfilePageJoinedLoyalty_MsgBoxOK"), aOLO.buttonHoverStyle);
            } else
                if (!this._incompleteUser)
                    DialogCreators.messageBoxOk(Names("SuccessfullyUpdatedProfilePage_MsgBoxOK"), aOLO.buttonHoverStyle);

            if (this._incompleteUser) {
                this._logInAfterSignUp(this._item.ProfileId, true);
                if (!aOLO.isBrandSignIn)
                    this.closeDialog()
            }
        }
    }
    public deleteProfile = async (): Promise<void> => {
        DialogCreators.InputBox(Names("DeleteProfileMessage"),
            true,
            Names("DeleteProfile"),
            null,
            aOLO,
            [{
                text: Names("YesDeleteMyProfile"), close: true, callBack: (password) => {
                    this.deleteProfileCountinue(password);
                }

            }, {
                text: Names("No"), close: true, callBack: null
            }], null, null,true);
    }
    public deleteProfileCountinue = async (password: string): Promise<void> => {
        // 2DO
        //if (aOLO.ProfileUser) {
        //    let deleteResult = await aOLO.ProfileUser.deleteProfile(this._item.ProfileId, password, null);
        //    const self = this;
        //    if (!deleteResult) 
        //        DialogCreators.messageBoxOk(Names("UnableToDelete"), aOLO.buttonHoverStyle);
        //    else 
        //        DialogCreators.messageBoxOk(Names("YourProfileDeleted"), aOLO.buttonHoverStyle, () => {
        //            Common.logout(aOLO.isBrandSignIn ? function () {
        //                if (self._brandFunction)
        //                    self._brandFunction(aOLO.landingPage, true);
        //            } : async function () {
        //                if (self._oloFunction)
        //                    self._oloFunction(aOLO.Temp.languageCode);

        //            }, aOLO, true);
        //            this.closeDialog();
        //        });
        //}
    }

    /**
     * Helper function for updateProfile that saves the user's profile to the server.
     * @async
     * @returns {Promise<boolean>} A promise that resolves with a boolean indicating whether the save was successful.
     */
    public updateProfileInternal = async (): Promise<boolean> => {
        const validData = await this._checkSubmittedData();
        // 2DO
        //if (validData && aOLO.ProfileUser) {
        //    let saveResult = await aOLO.ProfileUser.saveProfile(this._mapItem(), this._saveResultHandler, this._incompleteUser);
        //    if (saveResult) {
        //        this.dirty = false;
        //        return true;
        //    } else
        //        return false;
        //}
        return false;
    }

    /**
     * Validates the submitted data and returns a boolean indicating if the data is valid.
     * @private
     * @async
     * @returns {Promise<boolean>} - A Promise that resolves to a boolean indicating if the submitted data is valid.
     */
    private _checkSubmittedData = async (): Promise<boolean> => {

        /**
         * Adds a validation error message to the error flag.
         * @param message - The result of a validation check.
         * @returns {void}
         */
        function addValidationErrorMsg(message: string): void {
            if (message !== "") {
                error = true;
                ul.appendChild(Common.GetLi(message));
            }
        }

        this._hideErrorElements();

        let error = false;
        let ul = document.createElement("ul");
        ul.classList.add("warning");

        addValidationErrorMsg(await Common.ValidateInput(true,
            this._item.Name,
            FieldType.NAME,
            "spn_profile_name_error",
            true,
            aOLO
        ));

        addValidationErrorMsg(await Common.ValidateInput(true,
            this._item.LastName,
            FieldType.NAME,
            "spn_profile_lastname_error",
            true,
            aOLO
        ));

        addValidationErrorMsg(await Common.ValidateInput(true,
            this._item.Phone,
            FieldType.PHONE,
            "spn_profile_phone_error",
            true,
            aOLO
        ));

        addValidationErrorMsg(await Common.ValidateInput(true,
            this._item.Email,
            FieldType.EMAIL,
            "spn_profile_email_error",
            true,
            aOLO
        ));

        if (this._signUp && !this._incompleteUser) {
            addValidationErrorMsg(await Common.ValidateInput(true,
                this._item.pwd,
                FieldType.PASSWORD,
                "spn_profile_pass1_error",
                true,
                aOLO
            ));

            addValidationErrorMsg(await Common.ValidateInput(true,
                [this._item.pwd, this._item.repeatPwd],
                FieldType.COMPAREPASSWORDS,
                "spn_profile_pass2_error",
                true,
                aOLO
            ));
        }

        addValidationErrorMsg(await Common.ValidateInput(this._item.IsMarketingText,
            this._item.MarketingPhone,
            FieldType.PHONE,
            "spn_profile_mark_promo_text_error",
            true,
            aOLO
        ));

        if (this._birthdayRequirement === 2) {
            const bdayValue = Util.getElementValue("sel_profile_birth_day");
            addValidationErrorMsg(await Common.ValidateInput(
                ((bdayValue == "") || (bdayValue == "0")),
                (this._item.BirthDate || "").toString(),
                FieldType.DAY,
                "spn_profile_birth_day_error",
                true,
                aOLO
            ));
        }

        if (this._signUp) {
            if (aOLO.data.Settings?.PRIVPOL === 0)
                addValidationErrorMsg(await Common.ValidateInput(true,
                this._item.IsAge13.toString(),
                FieldType.BOOLEAN,
                "spn_profile_age_13_error",
                true,
                aOLO
            ));

            addValidationErrorMsg(await Common.ValidateInput(true,
                this._item.TermsPrivacyPolicy.toString(),
                FieldType.BOOLEAN,
                "spn_profile_privacy_error",
                true,
                aOLO
            ));
        }

        if (!error) {
            let profileExists = 0;
            if (aOLOModules.LoyaltyProvider.getLoyaltyProvider() != LoyaltyProvider.PUNCHH)
                profileExists = await aOLO.Modules.ProfileService.checkIfLoggedInExists(this._item.ProfileId, this._item.Email, this._item.Phone, this._item.CountryCodeId, aOLO);
            let emailIsUsedError = null, phoneIsUsedError = null;
         
            if (profileExists == -3) {
                emailIsUsedError = "EmailUsedByAnotherCustomer";
                phoneIsUsedError = "PhoneUsedByAnotherCustomer";
            } else if (profileExists == -1) {
                emailIsUsedError = "EmailUsedByAnotherCustomer";
            } else if (profileExists == -2) {
                phoneIsUsedError = "PhoneUsedByAnotherCustomer";
            }


            if (phoneIsUsedError)
                addValidationErrorMsg(await Common.ValidateInput(true,
                    this._item.Phone,
                    FieldType.FAILED,
                    "spn_profile_phone_same_error",
                    true,
                    aOLO
                ));

            if (emailIsUsedError)
                addValidationErrorMsg(await Common.ValidateInput(true,
                    this._item.Email,
                    FieldType.FAILED,
                    "spn_profile_email_same_error",
                    true,
                    aOLO
                ));
        }

        if (this._incompleteUser)
            Util.showElement("spn_profile_incomplete_error");

        if (error) {
            DialogCreators.messageBoxOk(ul, aOLO.buttonHoverStyle);
            return false;
        }
        return true;
    }

    /**
     * Handler function for saving profile result.
     * @private
     * @param {boolean} response - The response received from saving the profile.
     * @returns {void}
     */
    private _saveResultHandler = (response: boolean): void => {
        if (response) {
            this._updateaOLOUser(true);
            this.dirty = false;
            if (this._oloFunction) {
                this.closeDialog();
                this._oloFunction();
            }
            return;
        }
        DialogCreators.messageBoxOk(Names("UnexpectedError"), aOLO.buttonHoverStyle);
    }

    /**
     * Updates the aOLO user with the current Profile information.
     * @private
     * @param {boolean} [preserveData=false] - Whether to preserve the current data in the Profile.
     * @returns {void}
     */
    private _updateaOLOUser = (preserveData: boolean = false): void => {
        aOLO.User.CustomerId = this._item.CustomerId;
        aOLO.User.Name = this._item.Name;
        aOLO.User.LastName = this._item.LastName;
        aOLO.User.Email = this._item.Email;
        aOLO.User.Phone = this._item.Phone;
        aOLO.User.CountryCodeId = this._item.CountryCodeId;

        //Addresses
        aOLO.User.Addresses = JSON.parse(JSON.stringify(this._item.Addresses))
            .sort((x: any, y: IProfileAddress) => (+ y.IPRM - x.IPRM))
            .map((x: IProfileAddress) => {
                return {
                    AID: x.AID,
                    STRNO: x.STRNO,
                    ADDR1: x.ADDR1,
                    ADDR2: x.ADDR2,
                    ADDR3: x.ADDR3,
                    ADDR4: x.ADDR4,
                    ADDR5: x.ADDR5,
                    XSTR: x.XSTR,
                    CITY: x.CITY,
                    STA: x.STA,
                    ZIP: x.ZIP,
                    CID: x.CID,
                    LAT: x.LAT,
                    LON: x.LON,
                    IPRM: x.IPRM
                };
            });

        //Dietry & Allergy
        aOLO.User.Dietary = JSON.parse(JSON.stringify(this._item.Dietary));
        aOLO.User.Allergies = JSON.parse(JSON.stringify(this._item.Allergies));

        //Loyalty & marketing comms setting
        aOLO.User.IsMarketingText = this._item.IsMarketingText;
        aOLO.User.BirthDate = (this._item.BirthDate) ? new Date(JSON.parse(JSON.stringify(this._item.BirthDate))) : null;
        aOLO.User.IsMarketingEmail = this._item.IsMarketingEmail;
        aOLO.User.MarketingPhone = this._item.MarketingPhone;
        aOLO.User.MarketingCountryId = this._item.MarketingCountryCodeId;
        aOLO.User.IsMarketingSurvey = this._item.IsMarketingSurvey;
        aOLO.Temp.languageCode = this._item.CultureCode;
        aOLO.User.IsLoyalty = this._item.IsLoyalty;
        aOLO.User.IsAge13 = this._item.IsAge13;
        aOLO.User.TermsPrivacyPolicy = this._item.TermsPrivacyPolicy;
        aOLO.User.StoreId = this._item.StoreId;
        aOLO.User.ProfileId = this._item.ProfileId;
        if (!preserveData)
            this._item = this._setDefault();
        this._dirty = false;
        this._loyHide = this._item.IsLoyalty;
    }

    /* Sign Up Section */

    private _mapItem = (): IaOLOUser => {
        return {
            CID: this._item.CustomerId,
            PID: this._item.ProfileId,
            LRID: this._item.LoyaltyReferenceID,
            SID: this._item.StoreId,
            NAM: this._item.Name,
            LNAM: this._item.LastName,
            PHN: this._item.Phone,
            CCDID: Number(this._item.CountryCodeId),
            EML: this._item.Email,
            ISV: this._item.IsMarketingSurvey,
            IEML: this._item.IsMarketingEmail,
            ITXT: this._item.IsMarketingText,
            MKPHN: this._item.MarketingPhone,
            MKCCDID: Number(this._item.MarketingCountryCodeId),
            BD: this._item.BirthDate,
            ILY: this._item.IsLoyalty,
            IBLKD: this._item.IsBlocked,
            CLT: this._item.CultureCode,
            IDK: aOLO.Temp.DarkMode,
            ALGS: this._item.Allergies,
            DITS: this._item.Dietary,
            pwd: this._item.pwd,
            TPP: this._item.TermsPrivacyPolicy,
            A13: this._item.IsAge13,
            FAVST: false,
            FAVSTID: this._item.DefaultStoreId,
            FAVSTRs: [], 
            DFSTKEY: this._item.DefaultStoreKey,
            WLTS: [],
            ADRS: this._item.Addresses.map(x => {
                return {
                    AID: x.AID,
                    STRNO: x.STRNO,
                    ADDR1: x.ADDR1,
                    ADDR2: x.ADDR2,
                    ADDR3: x.ADDR3,
                    ADDR4: x.ADDR4,
                    ADDR5: x.ADDR5,
                    XSTR: x.XSTR,
                    CITY: x.CITY,
                    STA: x.STA,
                    ZIP: x.ZIP,
                    CID: x.CID,
                    LAT: x.LAT,
                    LON: x.LON,
                    IPRM: x.IPRM,
                    ATID: 0,
                    ANAM: "",
                };
            })
        };
    }

    /**
     * Signs up a user after checking submitted data.
     * @async
     * @returns {Promise<void>}
     */
    public signUp = async (): Promise<void> => {
        let validData = await this._checkSubmittedData();
        if (!validData)
            return;

        let profileId = await aOLO.Modules.ProfileService.signUp(this._mapItem(), aOLO);
        this.dirty = false;
        if (profileId)
            this._logInAfterSignUp(profileId);
        if (!aOLO.isBrandSignIn)
            this.closeDialog()
    }

    /**
     * Logs in a user after successful sign up.
     * @private
     * @param response - The response from the sign-up process.
     * @returns {void}
     */
    private _logInAfterSignUp = (profileId: number, incompleteProfile: boolean = false): void => {
        //if (profileId != 0)
        this._signInClass.signIn(this._item.Email, this._item.pwd, false, false, incompleteProfile);
    //    else
    //        DialogCreators.messageBoxOk(Names("UnexpectedError"), aOLO.buttonHoverStyle);
    }

    /**
     * Removes event listeners from the UI elements for two-way data binding.
     * @return {void}
     */
    public removeEventListeners = (): void => {
        this._bindings.forEach(binding => {
            binding.removeEventListeners();
        });
    }

    public openDialog = (localAolo: IaOLO): void => {
        Util.DialogFadeIn("dia_profile", localAolo);
    }

    public closeDialog = (): void => {
        Util.DialogFadeOut("dia_profile");
    }

    /**
     * Checks if there are any unsaved changes in the current component, and prompts the user to save them if so.
     * @param {string} component - The name of the component to check for unsaved changes.
     * @param {boolean} recordHistory - A flag indicating whether to record the current component in the browser history.
     * @returns {boolean} A flag indicating whether there were any unsaved changes (true) or not (false).
     */
    public checkUnsavedChanges = (component: string, recordHistory: boolean): boolean => {
        const self = this;
        if (!this.dirty)
            return true;

        DialogCreators.messageBox(Names("Save_your_profile_changes"), aOLO.buttonHoverStyle, [
            {
                "text": Names("Save"), "callBack":
                    () => {
                        self.dirty = false;
                        self.updateProfile();
                        if (self._brandFunction)
                            self._brandFunction(component, recordHistory);
                        else
                            self.closeDialog();
                    }
            },
            {
                "text": Names("DontSave"), "callBack":
                    () => {
                        self.dirty = false;
                        if (self._brandFunction)
                            self._brandFunction(component, recordHistory);
                        else
                            self.closeDialog();
                    }
            },
            { "text": Names("Cancel"), "callBack": null },
        ]);

        return false;
    }
}