import { TwoWayBinding } from '../two-way-binding';
import { Util } from '../utils/util';
import { Names, setPageLanguage } from '../utils/i18n';
import { Common } from '../common';
import { ISignIn } from './interfaces/sign-in.interfaces';
import { IaOLO, IaOLOModules } from '../interfaces/aolo.interface';
import { DialogCreators } from '../utils/dialog-creators';
import { ISignInResponse } from '../services/api/interfaces/profile-service.interfaces';
import { LoyaltyProvider } from '../types/enums';

import '../../css/shared/sign-in.css';

/**
 * Class representing a sign-in page for a website.
 * @class
 */
export class SignIn {
    /**
     * @private
     * @property {ISignIn} - The sign-in form data object.
     */
    private _item: ISignIn;
    private _isOLO: boolean = false;
    /**
     * @private
     * @property {TwoWayBinding[]} - The two-way bindings between the sign-in form data object and the input fields.
     */
    private _bindings: TwoWayBinding[] = [];
    /**
     * @private
     * @property {{ obj: HTMLElement, method: any, func: any }[]} - The event listeners attached to the input fields and buttons.
     */
    private _eventListeners: { obj: HTMLElement, method: any, func: any }[] = [];
    private _brandFunction: Function | null;
    private _oloFunction: Function | null;
    private _profileFunction: Function | null;
    private _orderTypeFunction: Function | null;

    /**
   * @private
   * @property {_isEmailOnlySignIn} - Setting to determine if the sign-in page allows email only or both email and phone.
   */
    _isEmailOnlySignIn: boolean = aOLO.data.Settings.SIGNINOPT === 0;

    /**
     * Initializes a new instance of the SignIn class.
     * @constructor
     */
    constructor(brandFunction: Function | null, oloFunction: Function | null, profileFunction: Function | null, orderTypeFunction: Function | null, isOLO: boolean = false) {
        this._brandFunction = brandFunction;
        this._oloFunction = oloFunction;
        this._profileFunction = profileFunction;
        this._orderTypeFunction = orderTypeFunction;
        this._isOLO = isOLO;
        this._item = this._setDefault();
        this._initProfile(aOLO, aOLOModules);
        this._configureSignInUI();
        this._populateSelectList();

        const div = document.createElement('div');
        div.classList.add('signin');
    }

    /**
     * Returns the default sign-in form data object.
     * @private
     * @returns {ISignIn} The default values for the sign-in page.
     */
    private _setDefault = (): ISignIn => {
        return {
            EmailPhone: aOLO.profilePage ? aOLO.profilePage : "",
            CountryId: 1,
            Password: "",
            IsPhone: false,
            RememberMe: false
        };
    }

    /**
     * Initializes the sign-in form by setting up the two-way bindings and event listeners.
     * @private
     * @returns {void}
     */
    private _initProfile = (localAolo: IaOLO, localAoloModules: IaOLOModules): void => {
        this._setTwoWayListeners();
        this._setEventListeners(localAolo, localAoloModules);
        if (this._isOLO)
            this._setOLOPage(localAolo);
    }

    /**
    * Initializes the sign-in UI and hides/displays/moves elements on initialization
    * @private
    * @returns {void}
    */
    _configureSignInUI = (): void => {
        let labelEmailOrPhoneNumber = document.getElementById("lbl_signin_email_phoneNo");
        if (labelEmailOrPhoneNumber) {
            if (this._isEmailOnlySignIn) {
                labelEmailOrPhoneNumber.setAttribute('ltag', 'Email');
                labelEmailOrPhoneNumber.innerText = Names('Email');
                Util.showElement("div_signin_password_parent")
                this._moveRememberMeCheckBox();
            } else {
                labelEmailOrPhoneNumber.setAttribute('ltag', 'EmailOrPhoneNumber');
                labelEmailOrPhoneNumber.innerText = Names('EmailOrPhoneNumber');
                if (aOLO.profilePage)
                    Util.showElement("div_signin_password_parent")
                else
                    Util.hideElement("div_signin_password_parent");
            }
        }
        Util.hideElement("div_signin_text_privacy_terms");

        if (Util.isAppView())
            Util.hideElement("div_signin_remember_me");
    }

    /**
     * Sets up the event listeners for the sign-in form.
     * @private
     * @returns {void}
     */
    private _setEventListeners = (localAolo: IaOLO, localAoloModules: IaOLOModules): void => {
        let self = this;

        let email = document.getElementById("txt_signin_email");
        if (email) {
            email.addEventListener("keyup", self._handleEnterKey);
            this._eventListeners.push({ obj: email, method: "keyup", func: self._handleEnterKey });
        }

        let password = document.getElementById("txt_signin_password");
        if (password) {
            password.addEventListener("keyup", self._handleEnterKey);
            this._eventListeners.push({ obj: password, method: "keyup", func: self._handleEnterKey });
        }



        Util.setElement("onfocus", "sel_signin_country_code", self._presentCountryCodeOptions);
        Util.setElement("onblur", "sel_signin_country_code", self._presentSelectedCountryCodeOption);
        Util.setElement("onchange", "sel_signin_country_code", () => Util.setFocus("txt_signin_email"));

        Util.setElement("onclick", "btn_signin_signin", async () => { await self._signInClick(); });
        Util.setElement("onclick", "btn_signin_reset_password", async () => { await self._resetPassword(localAolo, localAoloModules); });
        Util.setElement("onclick", "btn_signin_signup", () => { self._signUpClick(); });
        Util.setElement("onclick", "btn_signin_close", () => { self.closeDialog(); });
        Util.setElement("onclick", "btn_signin_guest", () => { self._oloContinue(false); });

        Util.setElement("onclick", "btn_signin_privacy_terms_terms", () => { Common.showTerms(localAolo); });
        Util.setElement("onclick", "btn_signin_privacy_terms_privacy", () => { Common.showPrivacy(localAolo); });
    }

    /**
     * Handles the Enter key press event on the sign-in form.
     * @private
     * @param {any} event - The key up event object.
     * @returns {void}
     */
    private _handleEnterKey = (event: KeyboardEvent) => {
        if (event.key === "Enter") {
            event.preventDefault();
            this._signInClick();
        } else {
            if (!this._isEmailOnlySignIn && /^(\(\d{3}\)|\d{3})-?\d{3}-?\d{4}$/.test(this._item.EmailPhone.replace(/\s/g, ""))) { // Phone Number
                let parsedPhoneNumber = this._item.EmailPhone.replace(/[-\(\)]/g, "").replace(/\s/g, "");
                if (parsedPhoneNumber.length > 9 && parsedPhoneNumber.length < 12) {
                    Util.showElement("div_signin_text_privacy_terms");
                    Util.hideElement("div_signin_password_parent");
                    Util.showElement("sel_signin_country_code_parent");
                    Util.setElementClass("REMOVE", "country_code_email_phone_row", "gridGap0");
                    this._item.IsPhone = true;
                    this._moveRememberMeCheckBox();
                }
            } else {
                Util.hideElement("div_signin_text_privacy_terms");
                Util.showElement("div_signin_password_parent");
                Util.hideElement("sel_signin_country_code_parent");
                Util.setElementClass("ADD", "country_code_email_phone_row", "gridGap0");
                this._item.IsPhone = false;
                this._moveRememberMeCheckBox();
            }
        }
    }

    /**
     * Sets up the two-way bindings between the sign-in form data object and the input fields.
     * @private
     * @returns {void}
     */
    private _setTwoWayListeners = (): void => {
        this._bindings = [
            new TwoWayBinding(this._item, "EmailPhone", document.getElementById("txt_signin_email")),
            new TwoWayBinding(this._item, "Password", document.getElementById("txt_signin_password")),
            new TwoWayBinding(this._item, "RememberMe", document.getElementById("chk_signin_remember_me")),
            new TwoWayBinding(this._item, "CountryId", document.getElementById("sel_signin_country_code"))
        ];
    }

    /**
     * Removes all event listeners attached to the input fields and buttons.
     * @public
     * @returns {void}
     */
    public removeEventListeners = (): void => {
        this._bindings.forEach(binding => {
            binding.removeEventListeners();
        });

        this._eventListeners.forEach(listener => {
            listener.obj.removeEventListener(listener.method, listener.func);
        });
    }

    private _setOLOPage = (localAolo: IaOLO): void => {
        if (localAolo.data.Settings.ISCC && localAolo.Temp.SelectedStoreID === 0) {
            Util.setElement("innerText", "h4_signin_store_address2", " ");
            Util.setElement("innerText", "h4_signin_store_phone", " ");

            const addressDiv = document.getElementById("h4_signin_store_address");
            if (addressDiv) {
                addressDiv.innerText = Names("OrderCenter");
                addressDiv.setAttribute("ltag", "OrderCenter");
            }
        }
        Util.DialogFadeIn("dia_signin", localAolo);
        const email = document.getElementById("txt_signin_email");
        if (email && !Util.isAppView())
            email.focus();
    }

    private _signUpClick = (): void => {
        if (this._brandFunction)
            this._brandFunction('profile', true);
        else if (this._profileFunction)
            this._profileFunction();
    }

    /**
     * Handles the sign-in button click event by checking the submitted data and signing in the user.
     * @private
     * @async
     * @returns {Promise<void>}
     */
    private _signInClick = async (): Promise<void> => {
        if (!this._checkSubmittedData())
            return;

        if (this._item.IsPhone) {
            const parsedPhoneNumber = this._item.EmailPhone.replace(/[-\(\)]/g, "");
            const countryId = Number(this._item.CountryId);
            const result = await aOLO.Modules.ProfileService.getPhoneCode(parsedPhoneNumber, countryId, aOLO);
            if (!result.success) {
                DialogCreators.messageBoxOk(Names(result.message), aOLO.buttonHoverStyle, null, "txt_signin_email");
                return;
            }

            const self = this;
            DialogCreators.InputBox(Names("EnterPasscodeExpiresInXMinutes"),
                true,
                Names("EnterPasscode"),
                null,
                aOLO,
                [{
                    text: Names("ResendCode"), close: false, callBack: async () => { 
                        const inputBox = document.querySelector("#txt_inputbox") as HTMLInputElement;
                        if (inputBox) {
                            inputBox.value = "";
                            inputBox.focus();
                        }
                        await aOLO.Modules.ProfileService.getPhoneCode(parsedPhoneNumber, countryId, aOLO);
                    }
                }, {
                    text: Names("SignIn"), close: true, callBack: (code: string) => {
                        self.signInByPhone(parsedPhoneNumber, countryId, code, this._item.RememberMe);
                    }
                }], null, null);
        } else
            await this.signIn(this._item.EmailPhone, this._item.Password, this._item.RememberMe, Util.isAppView());
        this._item.Password = "";
    }

    private _oloContinue = async (isSignedIn: boolean): Promise<void> => {
        if (isSignedIn && this._oloFunction) {
            aOLO.User.GU = 2;
            await this._oloFunction();
        }

        this.closeDialog();
        if (this._orderTypeFunction)
            this._orderTypeFunction(false);
    }

    /**
     * Signs in the user with the provided email and password.
     * @async
     * @param {string} email - The email address of the user.
     * @param {string} password - The password of the user.
     * @returns {Promise<void>} - A Promise that resolves with the sign-in result.
     */
    public signIn = async (email: string, password: string, rememberMe: boolean, isApp: boolean, incompleteProfile: boolean = false): Promise<void> => {
        const badPassword = !Util.CheckIsPassword(password);
        if (badPassword) {
            DialogCreators.messageBoxOk(Names("InvalidPassword"), aOLO.buttonHoverStyle, null, "txt_signin_password");
            return;
        }

        const signInData = {
            storeKey: "",
            email: email,
            password: password,
            rememberMe: rememberMe,
            app: isApp,
            incompleteProfile: incompleteProfile,
        }
        const result = await aOLOModules.LoyaltyProvider.signInAsync(signInData);
        await this._postSignIn(result);
    }

    public signInByPhone = async (phone: string, countryId: number, code: string, rememberMe: boolean): Promise<void> => {
        const isApp = Util.isAppView();
        const result = await aOLOModules.CustomerProvider.signInByPhone(phone, countryId, code, isApp, rememberMe);
        if (isApp && result.success && result.token) {
            //@ts-ignore
            if (typeof saveAppUserToken == "function" && result.token != null)
                //@ts-ignore
                saveAppUserToken(result.token);
        }
        await this._postSignIn(result);
    }

    private _postSignIn = async (result: ISignInResponse): Promise<void> => {
        if (!result.success) {
            if (!result.timeout) {
                const message = aOLOModules.LoyaltyProvider.getLoyaltyProvider() == LoyaltyProvider.PUNCHH ? (result.message || "") : Names("UnableLogin");
                DialogCreators.messageBoxOk(message, aOLO.buttonHoverStyle, null, "txt_signin_email");
            }
            return;
        }

        if (result.user)
            aOLO.User = result.user;

        if (aOLO.User.ProfileId == 0 && aOLOModules.LoyaltyProvider.getLoyaltyProvider() !== LoyaltyProvider.PUNCHH) {
            DialogCreators.messageBoxOk(Names("UnableLogin"), aOLO.buttonHoverStyle, null, "txt_signin_email");
            Util.LogError("SignIn _postSignIn", new Error("Sign in successful with ProfileId = 0"), aOLO);
            return;
        }

        if (result.jwt === null) {
            if (this._brandFunction)
                this._brandFunction("profile", false, true);
            else if (this._profileFunction)
                this._profileFunction(true);
            return;
        }

        //if (!aOLO.ProfileUser)
        //    aOLO.ProfileUser = new ProfileUser();

        //if (result.jwt !== null) {
        //    await aOLO.ProfileUser.signIn(result.jwt, result.refreshToken);
        //    await aOLOModules.LoyaltyProvider.getActiveDiscountBasket();
        //}

        //Common.setHamburgerMenuItems(aOLO);

        //if (aOLO.User.IsDarkMode !== aOLO.Temp.DarkMode)
        //    Common.toggleDarkMode(aOLO.Temp, aOLO.ProfileUser);

        if (aOLO.User.CultureCode !== aOLO.Temp.languageCode)
            setPageLanguage(aOLO.User.CultureCode || "en-us");

        if (!aOLO.User.IsProfileComplete) {
            if (this._brandFunction)
                this._brandFunction('profile', true, true);
            else if (this._profileFunction)
                this._profileFunction(true);
            return;
        }

        if (this._brandFunction) {
            if (aOLO.RedirectToTracker)
                await this._brandFunction("tracker", false);
            else if (aOLO.profilePage)
                await this._brandFunction("profile", true);
            else
                await this._brandFunction("loyalty", true);
        } else
            this._oloContinue(true);
    }

    /**
     * Checks whether the submitted data is valid.
     * @private
     * @returns {boolean} - A Boolean value indicating whether the submitted data is valid.
     */
    private _checkSubmittedData = (): boolean => {
        const email = document.getElementById("txt_signin_email") as HTMLInputElement;
        const password = document.getElementById("txt_signin_password") as HTMLInputElement;
        const emailError = document.getElementById("spn_signin_email_Error") as HTMLElement;
        const passwordError = document.getElementById("spn_signin_password_Error") as HTMLElement;

        this._updateSignInFormErrorState(email, emailError, false);
        this._updateSignInFormErrorState(password, passwordError, false);

        // Check for Phone sign-in
        if (this._item.IsPhone && email) {
            return true;
        }

        // Validate Email or phone is present
        const emailValue = email.value.trim();
        if (email && emailValue === "") {
            let errorMessage = this._isEmailOnlySignIn ? "Email is required" : "Email or Phone is required";
            this._updateSignInFormErrorState(email, emailError, true, errorMessage);
            return false;
        }

        // Validate Email
        if (email && !Util.IsValidEmail(email.value) && this._isEmailOnlySignIn) {
            let errorMessage = "Invalid Email";
            this._updateSignInFormErrorState(email, emailError, true, errorMessage);
            return false;
        }

        // Validate Password
        if (password && this._item.Password.trim() === "") {
            let errorMessage = "Password is required";
            this._updateSignInFormErrorState(password, passwordError, true, errorMessage);
            return false;
        }

        // All validations passed
        return true;
    }

    // Function to populate select list
    private _populateSelectList = () => {
        const selectList = document.getElementById('sel_signin_country_code') as HTMLSelectElement;

        // Check if the select list is already populated
        if (selectList && selectList.options.length === 0) {
            for (const country of aOLO.data.Countries) {
                const option = document.createElement('option');
                option.value = `${country.CountryID}`;
                option.dataset.isoCode = country.IsoCode;
                option.dataset.name = `${country.Name} (${country.CountryCode})`; // Set selected display value
                option.dataset.abr = `${country.IsoCode} (${country.CountryCode})`; // Set selected display value
                option.text = `${country.IsoCode} (${country.CountryCode})`;  //Names(country.IsoCode, aOLO.Temp.languageCode);
                selectList.appendChild(option);
            }
        }

        // Set default value to US option
        const defaultOption = selectList.querySelector(`option[value="${aOLO.storeInfo.Country.CountryID}"]`) as HTMLOptionElement;
        if (defaultOption) {
            defaultOption.selected = true;
            this._item.CountryId = aOLO.storeInfo.Country.CountryID;
            defaultOption.text = defaultOption.dataset.abr ? defaultOption.dataset.abr : defaultOption.text;
        }
    }

    private _presentSelectedCountryCodeOption = () => {
        const selectElement = document.getElementById("sel_signin_country_code") as HTMLSelectElement;

        // Iterate through each option element using for...of loop
        for (const option of selectElement.options) {
            // Set the text of the option to the value of its dataset.name property
            option.textContent = option.dataset.abr || option.text;
        }
    }

    private _presentCountryCodeOptions = () => {
        const selectElement = document.getElementById("sel_signin_country_code") as HTMLSelectElement;

        // Iterate through each option element using for...of loop
        for (const option of selectElement.options) {
            // Set the text of the option to the value of its dataset.name property
            option.textContent = option.dataset.name || option.text;
        }
    }

    /**
     * Turn on or off the error messages related to the SignIn form
     * @private
     * @returns {void} 
     */
    private _updateSignInFormErrorState = (inputElement: HTMLInputElement, errorElement: HTMLElement, showError: Boolean, errorMessage?: string | null): void => {
        if (showError && errorMessage) {
            errorElement.innerText = errorMessage;
            errorElement.classList.remove("field-error");
        } else {
            errorElement.classList.add("field-error");
        }
    };

    /**
     * Resets the password for the user with the provided email address.
     * @async
     * @private
     * @returns {Promise<void>} - A Promise that resolves when the password has been reset.
     */
    private _resetPassword = async (localAolo: IaOLO, localAoloModules: IaOLOModules): Promise<void> => {
        if (!Util.IsValidEmail(this._item.EmailPhone)) {
            DialogCreators.messageBoxOk(Names("InvalidEmail"), localAolo.buttonHoverStyle, null, "txt_signin_email");
            return;
        }

        await localAoloModules.CustomerProvider.forgotPassword(this._item.EmailPhone);
    }

    public closeDialog = (): void => {
        Util.DialogFadeOut("dia_signin");
    }

    /**
     * This method moves the 'Remember Me' checkbox element in the DOM.
     * The location depends on the login method: phone number or email.
     * If the login method is a phone number, the function inserts the 'Remember Me' checkbox's div before the privacy policy's div
     * If the login method is an email, move the 'Remember Me' checkbox's div to be the sibling element to the reset password's div
     * @returns {void}
     */
    private _moveRememberMeCheckBox = (): void => {
        let rememberMeDiv = document.getElementById('div_signin_remember_me');

        if (this._item.IsPhone) {
            let privacyPolicyDiv = document.getElementById('div_signin_text_privacy_terms');
            let parentToPrivacyDiv = privacyPolicyDiv?.parentNode;
            if (rememberMeDiv && privacyPolicyDiv) {
                parentToPrivacyDiv?.insertBefore(rememberMeDiv, privacyPolicyDiv);
            }
        } else { // email
            let parentElement = document.getElementById('div_signin_remember_me_reset_password_parent');
            if (rememberMeDiv && parentElement) {
                parentElement.prepend(rememberMeDiv);
            }
        }
    }
}
