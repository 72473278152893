import { IUpsaleCategory } from "./interfaces/upsale.interface";
import { Names } from "../utils/i18n";
import { Util } from "../utils/util";
import { OnlineOrderingUtil } from "./online-ordering-util";

import '../../css/online-ordering/upsale.css';
import { IaOLO } from "../interfaces/aolo.interface";

export class Upsale {
    private _CheckOut: Function;
    private _categories: IUpsaleCategory[];
    private _localAolo: IaOLO;

    constructor(CheckOut: Function, categories: IUpsaleCategory[], aOLO: IaOLO) {
        this._localAolo = aOLO;
        this._CheckOut = CheckOut;
        this._categories = categories;
        this._init();
    }

    private _init = (): void => {
        if (this._categories.length == 0) {
            this._CheckOut();
            return;
        }
        this._renderPage();
        this._setEventListeners();
        this._open();
    }

    private _renderPage = (): void => {
        let divUpsaleCats = document.getElementById("div_upsale_categories");
        if (!divUpsaleCats)
            return;

        let html = "";
        for (const category of this._categories) {
            const imageURL = category.IMG;
            const buttonStyle = this._localAolo.Temp.ButtonHoverStyles[this._localAolo.data.Settings.OLBTN];

            html += `
                <div class="center width100">
                    <div class="m1 title2 bold">${category.NAM}</div>
                    ${(imageURL !== "") ? `<div><img class="upsaleImage" alt="${category.NAM}" src="${imageURL}" /></div>` : ``}
                    <button id="btn_upsale_${category.IWCID}" name="btns_upsale" class="m1-t width100 ${buttonStyle}" data-catid="${category.IWCID}" data-catname="${category.NAM}">${Names("Select")}</button>
                </div>`;
        }

        divUpsaleCats.innerHTML = html;
    }

    private _setEventListeners = (): void => {
        const self = this;
        Util.setElement("onclick", "btn_upsale_close", () => { self._skipUpsale(); });

        let categoryButtons = Array.from(document.getElementsByName("btns_upsale"));
        for (const category of categoryButtons) {
            category.onclick = function () {
                OnlineOrderingUtil.ToggleOrderItems(true, self._localAolo);

                let catHeight = 0;

                Util.showElement("div_items");
                Util.hideElement("div_coupons");

                const divCats = document.getElementById("div_categories");
                let divCatsParent = divCats?.parentNode as HTMLElement;
                if (divCats && divCatsParent?.classList.contains("containerTopCat"))
                    catHeight = divCats.offsetHeight;

                let anchor = document.getElementById(`h1CatAnchor_${category.dataset.catid}`);
                if (anchor) {
                    let anchorTop = anchor.offsetTop - self._localAolo.Temp.HeaderHeight - catHeight - 20;
                    OnlineOrderingUtil.GUI_ScrollTo(anchorTop, self._localAolo.Temp.HeaderHeight);
                }

                Util.setElement("innerText", "div_categories_arrow", category.dataset.catname);
                self._close();
            };
        }
    }

    private _skipUpsale = (): void => {
        this._close();
        this._CheckOut();
    }

    private _open = (): void => {
        Util.DialogFadeIn("dia_upsale", this._localAolo);
    }

    private _close = (): void => {
        Util.DialogFadeOut("dia_upsale");
    }
}