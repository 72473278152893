import './login.css';

import EventEmitter from "eventemitter3";
import { IaOLO } from '../../../interfaces/aolo.interface';
import { ICustomerProvider } from '../../../services/customer/interfaces/customer-provider.interface';
import { EventTypes } from '../../../core/event-bus';
import { ILogin } from './login.interface';
import { TwoWayBindingHelper } from '../../../utils/two-way-binding';
import { Util } from '../../../utils/util';
import { DialogCreators } from '../../../utils/dialog-creators';
import { Names } from '../../../utils/i18n';
import { Common } from '../../../common';
import { FieldType } from '../../../types/enums';
import { DialogManager } from "../../../utils/dialog";
import { LoginManager } from '../../../services/auth/login-manager';

export class LoginDialog {
    private readonly _eventBus: EventEmitter;
    private readonly _customerProvider: ICustomerProvider;
    private readonly _loginManager: LoginManager;
    private readonly _aOLO: IaOLO;
    private readonly _item: ILogin;
    private readonly _bindingHelper: TwoWayBindingHelper<ILogin>;

    constructor(eventBus: EventEmitter, customerProvider: ICustomerProvider, loginManager: LoginManager, aOLO: IaOLO) {
        this._eventBus = eventBus;
        this._customerProvider = customerProvider;
        this._loginManager = loginManager;
        this._aOLO = aOLO;
        this._item = this._createModel();
        this._bindingHelper = new TwoWayBindingHelper(this._item);
        this._eventBusListeners();
    }

    private readonly _createModel = (): ILogin => {
        return {
            email: "",
            password: "",
            rememberMe: false
        };
    }

    public init = (): void => {
        this._hideErrorElements();
        this._setTwoWayListeners();
        this._setEventListeners();
        this._showDialog();
    }

    private readonly _showDialog = (): void => {
        DialogManager.showDialog("dia_login", null, false);
    }

    private readonly _closeDialog = (): void => {
        DialogManager.hideDialog("dia_login", false, false);
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.LOGGED_IN, this._closeDialog);
    }

    private readonly _setEventListeners = (): void => {
        Util.setElement("onclick", "btn_login_close", this._closeDialog);
        Util.setElement("onclick", "btn_login_forgot_password", this._forgotPasswordOnClickAsync);
        Util.setElement("onclick", "btn_login_sign_in", this._signInOnClickAsync);
        Util.setElement("onclick", "btn_login_sign_in_facebook", this._signInByFacebookOnClick);
        Util.setElement("onclick", "btn_login_sign_in_apple", this._signInByAppleOnClick);
        Util.setElement("onclick", "btn_login_sign_up", this._signUpOnClick);
    }

    private readonly _setTwoWayListeners = (): void => {
        this._bindingHelper.bindToInput("email", "txt_login_email");
        this._bindingHelper.bindToInput("password", "txt_login_password");
        this._bindingHelper.bindToInput("rememberMe", "chk_login_remember_me");
    }

    private readonly _signInOnClickAsync = async (): Promise<void> => {
        const validated = await this._checkSubmittedData();
        if (!validated)
            return;

        await this._loginManager.signInAsync(this._item.email, this._item.password, this._item.rememberMe);
    }

    private readonly _forgotPasswordOnClickAsync = async (): Promise<void> => {
        if (!Util.IsValidEmail(this._item.email)) {
            DialogCreators.messageBoxOk(Names("InvalidEmail"), this._aOLO.buttonHoverStyle, null, "txt_signin_email");
            return;
        }

        await this._customerProvider.forgotPassword(this._item.email);
    }

    private readonly _signInByFacebookOnClick = (): void => {
        // 2DO: US #47654
    }

    private readonly _signInByAppleOnClick = (): void => {
        // 2DO: US #47655
    }

    private readonly _signUpOnClick = (): void => {
        // 2DO: Open Sign Up page, pass email? US #49014
    }

    private readonly _hideErrorElements = (): void => {
        Util.hideElement("spn_login_email_error");
        Util.hideElement("spn_login_password_error");

        Util.setElementClass("remove", "txt_login_email", "invalid-input");
        Util.setElementClass("remove", "txt_login_password", "invalid-input");
    }

    private readonly _checkSubmittedData = async (): Promise<boolean> => {
        this._hideErrorElements();

        const emailError = await Common.ValidateInput(true,
            this._item.email,
            FieldType.EMAIL,
            "spn_login_email_error",
            true,
            this._aOLO
        );
        if (emailError)
            Util.setElementClass("add", "txt_login_email", "invalid-input");

        const passwordError = await Common.ValidateInput(true,
            this._item.password,
            FieldType.PASSWORD,
            "spn_login_password_error",
            true,
            this._aOLO
        );
        if (passwordError)
            Util.setElementClass("add", "txt_login_password", "invalid-input");

        return !(emailError || passwordError);
    }
}