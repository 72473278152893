import { DialogCreators } from "../utils/dialog-creators";
import { IaOLO } from "../interfaces/aolo.interface";
import { Util } from "../utils/util"

export class PrivacyTerms {

    public DisplayPrivacy = (localAolo: IaOLO): void => {
        this._setEventListeners();
        if (!localAolo.data.Settings.BRNDPPURL) {
            this._displayAdoraPrivacyPolicy(localAolo);
        } else {
            const iFramePrivacyPolicy = document.getElementById("iframe_privacy_terms_and_conditions") as HTMLIFrameElement;
            iFramePrivacyPolicy.style.display = "block";
            DialogCreators.loadIFrame(iFramePrivacyPolicy, localAolo.data.Settings.BRNDPPURL, localAolo)
                .then(() => {
                    this._displayBrandURL(localAolo);
                })
                .catch(() => {
                    this._displayAdoraPrivacyPolicy(localAolo);
                });
        }
    }

    public DisplayTerms = (localAolo: IaOLO): void => {
        this._setEventListeners();
        if (!localAolo.data.Settings.BRNDTACURL) {
            this._displayAdoraTermsAndConditions(localAolo);
        } else {
            const iFrameTerms = document.getElementById("iframe_privacy_terms_and_conditions") as HTMLIFrameElement;
            iFrameTerms.style.display = "block";
            DialogCreators.loadIFrame(iFrameTerms, localAolo.data.Settings.BRNDTACURL, localAolo)
                .then(() => {
                    this._displayBrandURL(localAolo);
                })
                .catch(() => {
                    this._displayAdoraTermsAndConditions(localAolo);
                });
        }
    }

    private _setEventListeners = (): void => {
        const self = this;
        Util.setElement("onclick", "btn_privacy_and_terms_close", () => { self._closeDialog(); });
        Util.setElement("onclick", "btn_privacy_and_terms_iframe_close", () => { self._closeDialog(); });
    }

    private _openDialog = (localAolo: IaOLO): void => {
        Util.DialogFadeIn("dia_privacy_and_terms", localAolo);
    }

    private _closeDialog = (): void => {
        Util.DialogFadeOut("dia_privacy_and_terms");
    }

    _displayAdoraPrivacyPolicy(localAolo: IaOLO): void {
        Util.showElement("div_privacy");
        Util.showElement("div_btn_privacy_and_terms_close");
        Util.hideElement("div_privacy_terms_iframe");
        Util.hideElement("div_btn_privacy_and_terms_iframe_close");
        Util.hideElement("div_terms");
        Util.hideElement("div_terms_iframe");
        this._openDialog(localAolo);
    }

    _displayAdoraTermsAndConditions(localAolo: IaOLO): void {
        Util.showElement("div_terms");
        Util.showElement("div_btn_privacy_and_terms_close");
        Util.hideElement("div_privacy_terms_iframe");
        Util.hideElement("div_btn_privacy_and_terms_iframe_close");
        Util.hideElement("div_privacy");
        Util.hideElement("div_privacy_iframe");
        this._openDialog(localAolo);
    }

    _displayBrandURL(localAolo: IaOLO): void {
        Util.showElement("div_privacy_terms_iframe");
        Util.showElement("div_btn_privacy_and_terms_iframe_close");
        Util.hideElement("div_terms");
        Util.hideElement("div_privacy");
        Util.hideElement("div_btn_privacy_and_terms_close");
        this._openDialog(localAolo);
    }
}