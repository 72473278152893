import { IUserLoyaltyReward, IUserCampaignCodesData, IUserOffers } from "../models/interfaces/user.interface";
import { Names } from "../utils/i18n";
import { Common } from "../common";
import { Util } from "../utils/util";
import { IaOLO } from "../interfaces/aolo.interface";
import { LoyaltyProvider } from "../types/enums";
import '../../css/online-ordering/rewards.css';
import { DialogCreators } from "../utils/dialog-creators";
import { ILoyaltyProvider } from "../services/loyalty/interfaces/loyalty-provider.interface";

export class Rewards {
    private _rewards: IUserLoyaltyReward[];
    private _offers: IUserOffers | null;
    private _localAolo: IaOLO;
    private _loyaltyProvider: ILoyaltyProvider;

    constructor(localAolo: IaOLO, loyaltyProvider: ILoyaltyProvider) {
        this._rewards = localAolo.User?.LoyaltyData?.Rewards.filter(reward => !reward.Expired) || [];
        this._offers = localAolo.User?.Offers || null;
        this._localAolo = localAolo;
        this._loyaltyProvider = loyaltyProvider;
        this._init();
    }

    private _init = (): void => {
        if (this._rewards.length > 0 || this._offers) {
            this._renderPage();
            this._setEventListeners();
            this.OpenDialog();
        }
        else {
            DialogCreators.messageBoxOk(Names("NoAvailableRewardsOrOffers"), this._localAolo.buttonHoverStyle);
        }
    }

    public OpenDialog = (): void => {
        Util.DialogFadeIn("dia_rewards", this._localAolo);
    }

    public CloseDialog = (): void => {
        Util.DialogFadeOut("dia_rewards");
    }

    private _renderPage() {
        this._loadRewards();
        this._loadSurveyOffersList();
    }

    private _setEventListeners = (): void => {
        Util.setElement("onclick", "btn_rewards_close", () => { this.CloseDialog(); });
    }

    /**
     * Loads the rewards data and creates the HTML for the existing rewards section.
     * @private
     * @return {void}
     */
    private _loadRewards = (): void => {
        const rewardsDiv = document.getElementById("div_rewards");
        if (!rewardsDiv)
            return;

        rewardsDiv.innerHTML = "";

        for (const reward of this._rewards) {
            rewardsDiv.innerHTML += this._createRewardTile(reward);
        }

        const redeemBtns = Array.from(document.getElementsByName("btn_rewards_redeem"));
        for (const button of redeemBtns) {
            button.onclick = async () => {
                await Common.redeemRewardOnClick(Number(button.dataset.rid), this._localAolo.User.LoyaltyData);
                this.CloseDialog();
            };
        }
    }

    /**
     * Creates the HTML Element for a reward tile.
     * @private
     * @param {Object} item - The reward data object.
     * @return {HTMLElement} The HTML Element for the reward tile.
     */
    private _createRewardTile = (reward: IUserLoyaltyReward): string => {
        const buttonName = reward.Used ? "Void" : "Redeem";
        const html = `
            <div class="reward m1-t gridCols1xAuto_resp">
                <span class="bold gridCol1">${Common.GetName(reward.Names, this._localAolo.Temp.languageCode)}</span>
                <div class="gridCol2 right">
                    ${(reward.ExpiryDate != null) ? `<span class="fontSmaller" ltag="Expires">${Names("Expires")}</span> <span>${Util.formatDate(new Date(reward.ExpiryDate))}</span>` : ``}<br />
                    <button id="btn_rewards_redeem_${reward.RewardId}" name="btn_rewards_redeem" data-rid="${reward.RewardId}" class="btnLink m0" ltag="${buttonName}">${Names(buttonName)}</button>
                </div>
            </div>`;

        return html;
    }

    /**
     * Creates the HTML Element for a offer tile.
     * @private
     * @param {Object} item - The offer data object.
     * @return {HTMLElement} The HTML Element for the reward tile.
     */
    private _createOfferTile = (offerCode: IUserCampaignCodesData): string => {
        if (offerCode.Expires != null && new Date(offerCode.Expires) < Util.NowStore(this._localAolo.Temp.TimeOffset))
            return "";

        const offerCoupon = this._localAolo.User.Offers?.Coupons.find(x => x.CouponId === offerCode.CouponId);
        if (!offerCoupon)
            return "";

        const couponName = Common.GetNewName(offerCoupon.Names, this._localAolo.Temp.languageCode) || '';
        let expireDate = offerCode.Expires != null ? Util.formatDate(new Date(offerCode.Expires)) : null;
        const buttonName = offerCode.Used ? "Void" : "Redeem";
        const html = `
            <div class="reward m1-t gridCols1xAuto_resp">
                <span class="bold gridCol1">${couponName}</span>
                <div class="gridCol2 right">
                    ${(expireDate != null) ? `<span class="fontSmaller" ltag="Expires">${Names("Expires")}</span> <span>${expireDate}</span>` : ``}<br />
                    <button id="btn_offers_redeem_${offerCode.CouponCode}" name="btn_offers_redeem" data-ccd="${offerCode.CouponCode}" class="btnLink m0" ltag="${buttonName}">${Names(buttonName)}</button>
                </div>
            </div>`;

        return html;
    }

    /**
    * Private method that renders the list of survey offers for the user's loyalty program underneath the rewards section.
    * @private
    * @return {void}
    */
    private _loadSurveyOffersList = (): void => {
        if (this._loyaltyProvider.getLoyaltyProvider() == LoyaltyProvider.PUNCHH) {
            Util.hideElement("div_loyalty_offers_parent");
            return;
        }

        if (this._localAolo.User?.IsLoyalty) {
            Util.showElement("div_loyalty_offers_parent");

            const offersDiv = document.getElementById("div_loyalty_offers_list");
            if (!offersDiv)
                return;

            offersDiv.innerHTML = "";

            if (this._localAolo.User?.Offers && this._localAolo.User.Offers?.Codes.length > 0) {
                Util.hideElement("div_loyalty_offers_list_no_offers");

                for (const cpn of this._localAolo.User.Offers.Codes)
                    offersDiv.innerHTML += this._createOfferTile(cpn);

                const redeemBtns = Array.from(document.getElementsByName("btn_offers_redeem"));
                for (const button of redeemBtns) {
                    button.onclick = async () => {
                        await Common.redeemOffersOnClick(button.dataset.ccd || "", this._localAolo.User.Offers, this._localAolo.Order.Coupons, this._localAolo.Modules.Coupon, this._localAolo.Temp.IncompleteDiscounts);
                        this.CloseDialog();
                    };
                }
            }
            else {
                Util.showElement("div_loyalty_offers_list_no_offers");
            }
        } else
            Util.hideElement("div_loyalty_offers_parent");
    }
}