import { LoyaltyProvider } from "../../../types/enums";
import { ILoyaltyProvider, MembershipLevel } from "../interfaces/loyalty-provider.interface";
import { IaOLO } from "../../../interfaces/aolo.interface";
import { IAddDiscountToOrder, IBatchRedemptionProcess, IFetchGiftCardsBalance, IFetchGiftCards, IGetActiveDiscountBasket, IGetStoreLoyaltyConfigurationInfo, ILoyaltyData_Item, IRemoveDiscountFromOrder, ISignInAsync, ISignInViaSSOAsync, ICreateFinishOrderPayloadLoyaltyResult } from "../../api/interfaces/loyalty-service.interface";
import { DialogCreators } from "../../../utils/dialog-creators";
import { IGetUserResponse, ISignInResponse } from "../../api/interfaces/profile-service.interfaces";
import { IUserLoyaltyReward } from "../../../models/interfaces/user.interface";
import { Util } from "../../../utils/util";
import { LoyaltyService } from "../../api/loyalty-service";
import { Common } from "../../../common";
import { Names } from "../../../utils/i18n";
import { OnlineOrderingUtil } from "../../../online-ordering/online-ordering-util";
import { IProfile } from "../../../shared/interfaces/profile.interfaces";
import { IGiftCard } from "../../../online-ordering/interfaces/gift-cards.interface"
import { ITempIncompleteDiscount } from "../../../interfaces/temp.interface";
import { IOrder, IOrderCoupon } from "../../../interfaces/order.interface";
import { IApplyCouponByIdParams } from "../../../online-ordering/interfaces/coupons.interface";
import { ILoginAsync } from "../../../components/popups/Login/login.interface";

export class PunchhProvider implements ILoyaltyProvider {
    private _loyaltyProvider: LoyaltyProvider = LoyaltyProvider.PUNCHH;
    private _loyaltyService: LoyaltyService;
    private _aOLO: IaOLO;
    private _phoneFormatLanguage: string;
    private _hasPointRedemption: boolean = false;
    private _hasRewardRedemption: boolean = false;
    private _hasMembershipTiers: boolean = false;
    private _hasBankedCurrency: boolean = false;
    private _hasDiscountCodes: boolean = false;
    private _hasSingleSignOn: boolean = false;
    private _onlyOneDiscountAllowed: boolean = false;
    private _punchhIsDown: boolean = false;
    private _metaData: any = {};
    private _activeBasket: { discount_basket_item_id: number, discount_type: string, discount_id: string | null, discount_amount: number | null, applied: boolean }[] = [];

    constructor(localAolo: IaOLO, loyaltyService: LoyaltyService, onlyOneDiscountAllowed: boolean) {
        this._aOLO = localAolo;
        this._loyaltyService = loyaltyService;
        this._onlyOneDiscountAllowed = onlyOneDiscountAllowed;
        this._phoneFormatLanguage = Util.getDefaultCultureCode(this._aOLO.User?.CountryCodeId || this._aOLO.storeInfo?.Country?.CountryID || 1, this._aOLO.data.Countries);
        this._setPrograms();
    }

    private _setPrograms(): void {
        this._hasPointRedemption = false;
        this._hasRewardRedemption = true;
        this._hasMembershipTiers = true;
        this._hasBankedCurrency = true;
        this._hasDiscountCodes = false;
        this._hasSingleSignOn = true;

        //if (this._metaData && this._metaData.program_meta.data.program_type == "points" && this._metaData.program_meta.data.points_conversion_type == "currency")
        //this._hasBankedCurrency = true;
    }

    hasPointRedemption(): boolean {
        return this._hasPointRedemption;
    }

    hasRewardRedemption(): boolean {
        return this._hasRewardRedemption;
    }

    hasMembershipTiers(): boolean {
        return this._hasMembershipTiers;
    }

    hasBankedCurrency(): boolean {
        return this._hasBankedCurrency;
    }

    hasDiscountCodes(): boolean {
        return this._hasDiscountCodes;
    }

    hasSingleSignOn(): boolean {
        return this._hasSingleSignOn;
    }

    onlyOneDiscountAllowed = (rewardId: number | null): boolean => {
        if (rewardId == null)
            rewardId = -1;

        //if (!this._onlyOneDiscountAllowed)
        //    return false;

        if (this._aOLO.Order.Coupons.length > 0) {
            DialogCreators.messageBoxOk(Names("OnlyOneDiscountAllowedAtATimeError"), this._aOLO.buttonHoverStyle);
            return true;
        }

        if (this._activeBasket.length > 0 && !this._activeBasket.map(x => Number(x.discount_id)).includes(rewardId)) {
            DialogCreators.messageBoxOk(Names("OnlyOneDiscountAllowedAtATimeError"), this._aOLO.buttonHoverStyle);
            return true;
        }

        if (this._aOLO.Temp.IncompleteDiscounts.length > 0 && !this._aOLO.Temp.IncompleteDiscounts.map(x => x.rewardId).includes(rewardId)) {
            DialogCreators.messageBoxOk(Names("OnlyOneDiscountAllowedAtATimeError"), this._aOLO.buttonHoverStyle);
            return true;
        }

        return false;
    }

    discountValidForOrderTime(expiryDate: string | null): boolean {
        return true;
    }

    private _setPunchhDown = async (timeout: boolean, messageId: number = 1): Promise<void> => {
        if (!timeout)
            return;

        switch (messageId) {
            case 1:
                this._punchhIsDownErrorMessage();
                break;
            case 2:
                DialogCreators.messageBoxOk(Names("LoyaltyCantReachInfoError"), this._aOLO.buttonHoverStyle);
                break;
        }
        this._punchhIsDown = true;

        setTimeout(() => {
            this._punchhIsDown = false;
        }, 300000);
    }

    private _punchhIsDownErrorMessage() {
        DialogCreators.messageBoxOk(Names("LoyaltyServerOffline"), this._aOLO.buttonHoverStyle);
    }

    isLoyaltyDown(): boolean {
        return this._punchhIsDown;
    }

    getLoyaltyProvider(): LoyaltyProvider {
        return this._loyaltyProvider;
    }

    getHamburgerMenuOptions(): string[] {
        return [
            'li_vertical_menu_rewards',
            'li_vertical_menu_profile',
            'li_vertical_menu_orders',
            'li_vertical_menu_logout',
        ];
    }

    getStartupInfo = async (): Promise<void> => {
        if (this.isLoyaltyDown())
            return;

        const payload: IGetStoreLoyaltyConfigurationInfo = {
            store_key: this._aOLO.storeInfo.StoreKey,
            data: {
                location: "olo",
                program_meta_eTag: "",
                locations_eTag: ""
            }
        };

        const response = await this._loyaltyService.getStoreLoyaltyConfigurationInfo(payload);
        if (response.success && response.data) {
            this._metaData = JSON.parse(response.data);
            this._setPrograms();
        } else if (response.timeout) {
            await this._setPunchhDown(response.timeout);
        } else if (response.message)
            DialogCreators.messageBoxOk(response.message, this._aOLO.buttonHoverStyle);
    }

    getUserAsync = async (): Promise<IGetUserResponse> => {
        if (this.isLoyaltyDown())
            return { success: false, timeout: true };

        const response = await this._loyaltyService.getUser();
        if (response.timeout)
            await this._setPunchhDown(response.timeout, 0);
        return response;
    }

    signInAsync = async (data: ILoginAsync): Promise<ISignInResponse> => {
        if (this.isLoyaltyDown()) {
            this._punchhIsDownErrorMessage();
            return { success: false, loggedIn: false, timeout: true };
        }

        const response = await this._loyaltyService.signIn(data);
        if (response.timeout)
            await this._setPunchhDown(response.timeout);
        return response;
    }

    signInViaSSOAsync = async (): Promise<ISignInResponse> => {
        if (this.isLoyaltyDown()) {
            this._punchhIsDownErrorMessage();
            return { success: false, loggedIn: false, timeout: true };
        }

        const payload: ISignInViaSSOAsync = {
            store_key: this._aOLO.storeInfo.StoreKey,
            location: "olo",
            sso_token: this._aOLO.singleSignOnToken || ""
        };
        const response = await this._loyaltyService.signInViaSSO(payload);
        if (response.timeout)
            await this._setPunchhDown(response.timeout);
        delete this._aOLO.singleSignOnToken;
        return response;
    }

    signInByAppAsync(token: string): Promise<ISignInResponse> {
        throw new Error("Method not implemented.");
    }

    getActiveDiscountBasket = async (): Promise<void> => {
        if (this.isLoyaltyDown())
            return;

        const payload: IGetActiveDiscountBasket = {
            store_key: this._aOLO.storeInfo.StoreKey,
            data: {
                location: "olo"
            }
        };

        const response = await this._loyaltyService.getActiveDiscountBasket(payload);
        if (response.success && response.data)
            this._addItemsToBasket(response.data);
        else {
            if (response.timeout) {
                await this._setPunchhDown(response.timeout, 0);
                return;
            }

            if (response.message?.includes("no_active_basket"))
                return;
            else
                DialogCreators.messageBoxOk(`${Names("SomethingWentWrong")}<br>${response.message || ""}`, this._aOLO.buttonHoverStyle);
        }
    }

    addReward = async (reward: IUserLoyaltyReward): Promise<void> => {
        if (this.isLoyaltyDown() || this.onlyOneDiscountAllowed(reward.RewardId) || !this.discountValidForOrderTime(reward.ExpiryDate)) {
            return;
        }

        if (this._activeBasket.map(x => Number(x.discount_id)).includes(reward.RewardId)) {
            await this.batchComparison(true);
            return;
        }

        const payload: IAddDiscountToOrder = {
            store_key: this._aOLO.storeInfo.StoreKey,
            data: {
                user_id: Number(this._aOLO.User.LoyaltyReferenceID),
                location: "olo",
                discount_basket_items_attributes: [
                    {
                        discount_type: "reward",
                        discount_id: reward.RewardId
                    }
                ]
            }
        };

        const response = await this._loyaltyService.addDiscountToOrder(payload);
        if (response.success && response.data) {
            this._addItemsToBasket(response.data);
            if (this._aOLO.Order.Items.length > 0)
                await this.batchComparison(true);
        } else {
            if (response.timeout)
                await this._setPunchhDown(response.timeout, 2);
            else
                DialogCreators.messageBoxOk(`${Names("SomethingWentWrong")}<br>${response.message || ""}`, this._aOLO.buttonHoverStyle);
        }
    }

    private _addItemsToBasket = (data: string): void => {
        if (!data)
            return;

        const jData = JSON.parse(data);
        for (const item of jData.discount_basket_items) {
            if (!this._activeBasket.map(x => x.discount_basket_item_id).includes(item.discount_basket_item_id))
                switch (item.discount_type) {
                    case "reward":
                        this._activeBasket.push({ discount_basket_item_id: item.discount_basket_item_id, discount_type: item.discount_type, discount_id: item.discount_id, discount_amount: null, applied: false });
                        break;
                    case "discount_amount":
                        this._activeBasket.push({ discount_basket_item_id: item.discount_basket_item_id, discount_type: item.discount_type, discount_id: null, discount_amount: item.discount_value, applied: false });
                        break;
                }

            if (item.discount_id)
                this._addRewardToIncompleteDiscounts(Number(item.discount_id));
            else
                this._addBankedCurrencyToIncompleteDiscounts();
        }

        if (this._aOLO.Order)
            OnlineOrderingUtil.GUI_SetOrder_Total(false, this._aOLO, aOLOModules);
    }

    removeReward = async (reward: IUserLoyaltyReward): Promise<void> => {
        if (this.isLoyaltyDown())
            return;

        const basketItem = this._activeBasket.find(x => Number(x.discount_id) == reward.RewardId);
        if (!basketItem)
            return;

        const payload: IRemoveDiscountFromOrder = {
            store_key: this._aOLO.storeInfo.StoreKey,
            data: {
                user_id: Number(this._aOLO.User.LoyaltyReferenceID),
                location: "olo",
                order_guid: this._aOLO.Order.GUID,
                discount_basket_item_ids: [basketItem.discount_basket_item_id]
            }
        };

        const response = await this._loyaltyService.removeDiscountFromOrder(payload);
        if (response.success || response.timeout) {
            reward.Used = false;
            this._activeBasket = this._activeBasket.filter(x => x.discount_id !== basketItem.discount_id);

            const coupon = this._aOLO.Order.Coupons.find(x => x.RewardId == reward.RewardId);
            if (coupon)
                await this._aOLO.Modules.Coupon.RemoveCouponByKey(coupon.CouponKey, true);

            const index = this._aOLO.Temp.IncompleteDiscounts.findIndex(x => x.rewardId === reward.RewardId);
            if (index > -1) {
                this._aOLO.Temp.IncompleteDiscounts.splice(index, 1);
            }
            OnlineOrderingUtil.GUI_SetOrder_Total(false, this._aOLO, aOLOModules);
        } else
            DialogCreators.messageBoxOk(`${Names("SomethingWentWrong")}<br>${response.message || ""}`, this._aOLO.buttonHoverStyle);
    }

    private removePunchhDiscount = async (basketItemId: number): Promise<void> => {
        if (this.isLoyaltyDown())
            return;

        const basketItem = this._activeBasket.find(x => Number(x.discount_basket_item_id) == basketItemId);
        if (!basketItem)
            return;

        const payload: IRemoveDiscountFromOrder = {
            store_key: this._aOLO.storeInfo.StoreKey,
            data: {
                user_id: Number(this._aOLO.User.LoyaltyReferenceID),
                location: "olo",
                order_guid: this._aOLO.Order.GUID,
                discount_basket_item_ids: [basketItem.discount_basket_item_id]
            }
        };

        const response = await this._loyaltyService.removeDiscountFromOrder(payload);
        if (response.success || response.timeout) {
            this._activeBasket = this._activeBasket.filter(x => x.discount_basket_item_id !== basketItem.discount_basket_item_id);
            OnlineOrderingUtil.GUI_SetOrder_Total(false, this._aOLO, aOLOModules);
        } else
            DialogCreators.messageBoxOk(`${Names("SomethingWentWrong")}<br>${response.message || ""}`, this._aOLO.buttonHoverStyle);
    }

    getAppliedBankedCurrency = (): number => {
        const applied = this._activeBasket.find(x => x.discount_id == null && x.applied);
        if (!applied)
            return 0;

        return applied.discount_amount || 0;
    }

    applyBankedCurrency = async (currencyToApply: number): Promise<void> => {
        if (this.isLoyaltyDown() || this.onlyOneDiscountAllowed(null)) {
            return;
        }

        const payload: IAddDiscountToOrder = {
            store_key: this._aOLO.storeInfo.StoreKey,
            data: {
                user_id: Number(this._aOLO.User.LoyaltyReferenceID),
                location: "olo",
                discount_basket_items_attributes: [
                    {
                        discount_type: "discount_amount",
                        discount_value: currencyToApply
                    }
                ]
            }
        };

        const response = await this._loyaltyService.addDiscountToOrder(payload);
        if (response.success && response.data) {
            this._addItemsToBasket(response.data);
            if (this._aOLO.Order.Items.length > 0)
                await this.batchComparison(true);
        } else {
            if (response.timeout)
                await this._setPunchhDown(response.timeout);
            else
                DialogCreators.messageBoxOk(`${Names("SomethingWentWrong")}<br>${response.message || ""}`, this._aOLO.buttonHoverStyle);
        }
    }

    removeBankedCurrency = async (): Promise<void> => {
        if (this.isLoyaltyDown())
            return;

        const currencyItem = this._activeBasket.find(x => x.discount_id == null);
        if (!currencyItem)
            return;

        const payload: IRemoveDiscountFromOrder = {
            store_key: this._aOLO.storeInfo.StoreKey,
            data: {
                user_id: Number(this._aOLO.User.LoyaltyReferenceID),
                location: "olo",
                order_guid: this._aOLO.Order.GUID,
                discount_basket_item_ids: [currencyItem.discount_basket_item_id]
            }
        };

        const response = await this._loyaltyService.removeDiscountFromOrder(payload);
        if ((response.success && response.data) || response.timeout) {
            this._activeBasket = this._activeBasket.filter(x => x.discount_id !== null);
            const coupon = this._aOLO.Order.Coupons.find(x => x.IsBankedCurrency);
            if (coupon)
                await this._aOLO.Modules.Coupon.RemoveCouponByKey(coupon.CouponKey, true);

            const index = this._aOLO.Temp.IncompleteDiscounts.findIndex(x => x.isBankedCurrency);
            if (index > -1) {
                this._aOLO.Temp.IncompleteDiscounts.splice(index, 1);
            }

            OnlineOrderingUtil.GUI_SetOrder_Total(false, this._aOLO, aOLOModules);
        } else
            DialogCreators.messageBoxOk(response.message || "", this._aOLO.buttonHoverStyle);
    }

    private _addRewardToIncompleteDiscounts = (rewardId: number): void => {
        const reward = this._aOLO.User.LoyaltyData?.Rewards.find(x => x.RewardId == rewardId);

        if (!reward || this._aOLO.Temp.IncompleteDiscounts.find(x => x.rewardId == rewardId))
            return;

        reward.Used = true;

        const incompleteCoupon: ITempIncompleteDiscount = {
            couponID: 10,
            rewardId: rewardId
        };
        this._aOLO.Temp.IncompleteDiscounts.push(incompleteCoupon);
    }

    private _addBankedCurrencyToIncompleteDiscounts = (): void => {
        if (this._aOLO.Temp.IncompleteDiscounts.find(x => x.isBankedCurrency))
            return;

        const incompleteCoupon: ITempIncompleteDiscount = {
            couponID: 11,
            isBankedCurrency: true
        };
        this._aOLO.Temp.IncompleteDiscounts.push(incompleteCoupon);
    }

    batchComparison = async (query: boolean, remove: boolean = false): Promise<boolean> => {
        if (this.isLoyaltyDown()) {
            this._punchhIsDownErrorMessage();
            return false;
        }

        if ((this._activeBasket.length == 0 || this._aOLO.Order.Items.length == 0) &&
            (!this._aOLO.User.LoyaltyData || (this._aOLO.User.LoyaltyData.Rewards.filter(x => x.Used).length == 0 && this._aOLO.Order.Coupons.filter(x => [10, 11].includes(x.CouponId)).length == 0)))
            return true;

        const order = this._aOLO.Order;
        const cultureCode = this._aOLO.Temp.languageCode;

        const discount = order.Discount - order.Coupons.reduce((accumulator: number, coupon: IOrderCoupon) => {
            return accumulator + ([10, 11].includes(coupon.CouponId) ? coupon.Amount : 0);
        }, 0);

        const payload: IBatchRedemptionProcess = {
            store_key: this._aOLO.storeInfo.StoreKey,
            data: {
                location: "olo",
                query: query,
                store_number: Number(this._aOLO.storeInfo.StoreCode),
                user_id: Number(this._aOLO.User.LoyaltyReferenceID),
                order_guid: order.GUID,
                transaction_no: query ? order.GUID : order.OrderID.toString(),
                receipt_datetime: Util.formatToISO8601(query ? order.OrderStartTime : order.OrderEndTime || new Date()),
                subtotal_amount: Number((order.SubTotal - discount).toFixed(2)),
                receipt_amount: Number((order.SubTotal - discount).toFixed(2)),
                line_items: []
            }
        };

        let halfCount = 1;
        let itemCount = 0;
        let lastItemKey = -1;

        // Menu Items
        for (const item of order.Items) {
            if (item.ItemKey != lastItemKey) {
                lastItemKey = item.ItemKey;
                halfCount = 1;
                itemCount++;
            }

            if (item.HalfCount > 1) {
                if (halfCount == 1) {
                    const halfHalfPayload: ILoyaltyData_Item = {
                        item_id: 0,
                        item_name: Common.GetName(this._aOLO.data.Items[item.Index].Names, cultureCode),
                        item_qty: item.Quantity,
                        amount: (item.HalfHalfPrice * item.Quantity),
                        item_type: "M",
                        item_family: "0",
                        item_group: `${item.ItemCategoryId}`,
                        index: `${itemCount}.0`
                    };
                    payload.data.line_items.push(halfHalfPayload);

                    const itemPayload: ILoyaltyData_Item = {
                        item_id: item.ItemId,
                        item_name: Common.GetName(this._aOLO.data.Items[item.Index].Names, cultureCode),
                        item_qty: item.Quantity,
                        amount: 0,//(item.Price * item.Quantity),
                        item_type: "M",
                        item_family: item.SizeId.toString(),
                        item_group: `${item.ItemCategoryId}`,
                        index: `${itemCount}.1`
                    };
                    payload.data.line_items.push(itemPayload);
                } else {
                    const itemPayload: ILoyaltyData_Item = {
                        item_id: item.ItemId,
                        item_name: Common.GetName(this._aOLO.data.Items[item.Index].Names, cultureCode),
                        item_qty: item.Quantity,
                        amount: 0, //(item.Price * item.Quantity),
                        item_type: "M",
                        item_family: item.SizeId.toString(),
                        item_group: `${item.ItemCategoryId}`,
                        index: `${itemCount}.${halfCount}`
                    };
                    payload.data.line_items.push(itemPayload);
                }
                halfCount++;
            } else {
                let modifierTotalPrice = 0;
                if (item.Modifiers) {
                    for (const mod of item.Modifiers) {
                        modifierTotalPrice += mod.Price;
                    }
                }

                const itemPayload: ILoyaltyData_Item = {
                    item_id: item.ItemId,
                    item_name: Common.GetName(this._aOLO.data.Items[item.Index].Names, cultureCode),
                    item_qty: item.Quantity,
                    amount: (item.Price * item.Quantity) + (!item.IsBuildYourOwn ? -modifierTotalPrice : 0),
                    item_type: "M",
                    item_family: item.SizeId.toString(),
                    item_group: `${item.ItemCategoryId}`,
                    index: `${itemCount}.0`
                };
                payload.data.line_items.push(itemPayload);

                // Modifiers
                if (item.Modifiers) {
                    for (const [index, mod] of item.Modifiers.entries()) {
                        const modifier: ILoyaltyData_Item = {
                            item_id: mod.ModifierId,
                            item_name: Common.GetName(this._aOLO.data.Modifiers[mod.Index].Names || [], cultureCode),
                            item_qty: 1,
                            amount: !item.IsBuildYourOwn ? mod.Price : 0,
                            item_type: "M",
                            item_family: "",
                            item_group: "", //`${mod.CategoryID}`,
                            index: `${itemCount}.${index + 1}`
                        };
                        payload.data.line_items.push(modifier);
                    }
                }
                itemCount++;
            }
        }

        for (const item of order.Coupons.filter(x => ![10, 11].includes(x.CouponId))) {
            const mCoupon = this._aOLO.Modules.Coupon.GetCoupon(item.CouponId, true, order.OrderTypeID, null);
            const coupon: ILoyaltyData_Item = {
                item_id: item.CouponKey,
                item_name: Common.GetName(mCoupon?.Names || [], cultureCode),
                item_qty: 1,
                amount: item.Discount,
                item_type: "D",
                item_family: "",
                item_group: "",
                index: `${itemCount}.0`
            };
            payload.data.line_items.push(coupon);
        }

        const response = await this._loyaltyService.batchRedemptionProcess(payload);
        if (response.success && response.data) {
            const jData = JSON.parse(response.data);
            for (const discount of jData.failures) {
                const reward = this._aOLO.User.LoyaltyData?.Rewards.find(x => x.RewardId == Number(discount.discount_id));
                if (!reward) {
                    await this.removePunchhDiscount(discount.discount_basket_item_id);
                    continue;
                }

                const discountBasket = this._activeBasket.find(x => x.discount_basket_item_id == discount.discount_basket_item_id);
                if (discountBasket) {
                    discountBasket.discount_id = discount.discount_id;
                    discountBasket.applied = false;
                }

                this._addRewardToIncompleteDiscounts(Number(discount.discount_id));

                if (remove)
                    await this.removeReward(reward);
                else {
                    const coupon = this._aOLO.Order.Coupons.find(x => x.RewardId == reward.RewardId);
                    if (coupon)
                        await this._aOLO.Modules.Coupon.RemoveCouponByKey(coupon.CouponKey, true);
                }
            }
            for (const discount of jData.success) {
                const basketCoupon = this._activeBasket.find(x => x.discount_basket_item_id === discount.discount_basket_item_id);
                const amount = discount.discount_type == "discount_amount" ? discount.discount_value : discount.discount_amount;

                if (basketCoupon) {
                    if (basketCoupon.applied && basketCoupon.discount_amount == amount)
                        continue;

                    basketCoupon.discount_id = discount.discount_id;

                    const rewardId = basketCoupon.discount_id ? Number(basketCoupon.discount_id) : null;
                    const isBankedCurrency = (discount.discount_type == "discount_amount");
                    const couponId = !isBankedCurrency ? 10 : 11;

                    // If coupon already applied, but amount changed, remove coupon
                    if (basketCoupon.applied && basketCoupon.discount_amount != amount) {
                        let coupon = null;
                        if (isBankedCurrency)
                            coupon = this._aOLO.Order.Coupons.find(x => x.IsBankedCurrency);
                        else if (rewardId)
                            coupon = this._aOLO.Order.Coupons.find(x => x.RewardId == rewardId);

                        if (coupon)
                            await this._aOLO.Modules.Coupon.RemoveCouponByKey(coupon.CouponKey, true);
                    }

                    basketCoupon.discount_amount = amount;

                    // Apply coupon
                    const params: IApplyCouponByIdParams = {
                        couponId: couponId,
                        discount: amount,
                        showErrorMsg: true,
                        rewardId: rewardId,
                        runBatch: false,
                        isBankedCurrency: isBankedCurrency
                    };
                    const isApplied = await this._aOLO.Modules.Coupon.ApplyCouponByID(params);
                    if (!isApplied)
                        continue;

                    basketCoupon.applied = true;

                    const coupon = aOLO.Order.Coupons.find(x => x.CouponId == couponId);
                    if (coupon) {
                        coupon.RedemptionReference = jData.redemption_ref;
                        coupon.BasketItemId = discount.discount_basket_item_id;
                    }

                    const reward = this._aOLO.User.LoyaltyData?.Rewards.find(x => x.RewardId == Number(discount.discount_id));
                    if (reward) {
                        reward.Used = true;
                        reward.json = JSON.stringify(discount);
                    }
                } else {
                    const rewardId = discount.discount_id ? Number(discount.discount_id) : null;
                    const isBankedCurrency = (discount.discount_type == "discount_amount");
                    const couponId = !isBankedCurrency ? 10 : 11;
                    const params: IApplyCouponByIdParams = {
                        couponId: couponId,
                        discount: amount,
                        showErrorMsg: true,
                        rewardId: rewardId,
                        runBatch: false,
                        isBankedCurrency: isBankedCurrency
                    };
                    const isApplied = await this._aOLO.Modules.Coupon.ApplyCouponByID(params);
                    if (!isApplied)
                        continue;

                    const appliedToBasket = {
                        discount_basket_item_id: discount.discount_basket_item_id,
                        discount_type: discount.discount_type,
                        discount_id: discount.discount_id,
                        discount_amount: amount,
                        applied: true
                    };
                    this._activeBasket.push(appliedToBasket);

                    const reward = this._aOLO.User.LoyaltyData?.Rewards.find(x => x.RewardId == Number(discount.discount_id));
                    if (reward) {
                        reward.Used = true;
                        reward.json = JSON.stringify(discount);
                    }
                }

                switch (discount.discount_type) {
                    case "reward":
                        this._aOLO.Temp.IncompleteDiscounts = this._aOLO.Temp.IncompleteDiscounts.filter(x => x.rewardId !== Number(discount.discount_id));
                        break;
                    case "discount_amount":
                        this._aOLO.Temp.IncompleteDiscounts = this._aOLO.Temp.IncompleteDiscounts.filter(x => !x.isBankedCurrency);
                        break;
                }
            }

            OnlineOrderingUtil.GUI_SetOrder_Total(true, this._aOLO, aOLOModules);
            return true;
        } else if (!response.success && response.message != "No Basket found.") {
            if (response.timeout)
                await this._setPunchhDown(response.timeout);
            else
                DialogCreators.messageBoxOk(response.message || "", this._aOLO.buttonHoverStyle);
        }
        return false;
    }

    fetchGiftCards = async (passcode: string | null): Promise<any> => {
        if (this.isLoyaltyDown())
            return false;

        const payload: IFetchGiftCards = {
            store_key: this._aOLO.storeInfo.StoreKey,
            data: {
                location: "olo",
                passcode: passcode
            }
        };

        const response = await this._loyaltyService.fetchGiftCards(payload);
        if (response.success && response.data) {
            const giftCards: IGiftCard[] = JSON.parse(response.data);

            let giftCardsDiv = document.getElementById("div_gift_cards");
            if (!giftCardsDiv)
                return;

            giftCardsDiv.innerHTML = "";

            if (!aOLO.User?.LoyaltyData)
                return;

            if (giftCards.length == 0) {
                Util.DialogFadeOut("dia_gift_cards");
                DialogCreators.messageBoxOk(Names("NoAvailableGiftCards"), this._aOLO.buttonHoverStyle);
                return;
            }

            for (const giftCard of giftCards) {
                if (giftCardsDiv) {

                    const existingCard = aOLO.Temp.GiftCards.find(card => card.cardNo === giftCard.card_number);
                    if (existingCard)
                        giftCard.last_fetched_amount -= existingCard.appliedAmount;

                    giftCardsDiv.innerHTML += this._createGiftCardTile(giftCard);
                }
            }

            const applyBtns = Array.from(document.getElementsByName("btn_gift_card_apply"));
            for (const button of applyBtns) {
                button.onclick = async () => {

                    const giftCardID = button.dataset.gcid;
                    const card = giftCards.find(x => x.gift_card_id == giftCardID);
                    if (card)
                        this._applyGiftCard(card);

                    Util.DialogFadeOut("dia_gift_cards");
                };
            }

            return true;
        }
        else {
            if (response.timeout)
                await this._setPunchhDown(response.timeout);
            else
                DialogCreators.messageBoxOk(response.message || "", this._aOLO.buttonHoverStyle);

            Util.DialogFadeOut("dia_gift_cards");
            return false;
        }
    }

    private _applyGiftCard = (giftCard: IGiftCard): void => {
        const rndString = (Math.floor(Math.random() * 100) + 1).toString();
        const divID = `div_checkout_applied_gift_${rndString}`;
        const maskedCardNumber = "**** " + giftCard.card_number.slice(-4);

        const card = {
            cardNo: giftCard.card_number,
            balance: giftCard.last_fetched_amount,
            pin: giftCard.epin,
            appliedAmount: 0,
            randomId: rndString,
            uuid: giftCard.uuid
        };

        aOLO.Temp.GiftCards.push(card);

        OnlineOrderingUtil.GUI_SetOrder_Total(true, this._aOLO, aOLOModules);

        const html = `
            <div id="${divID}" class="giftcard" data-card="${JSON.stringify(giftCard)}">
                <div class="giftcard_Number" aria-label="gift card number">${maskedCardNumber}</div>
                <div class="gridColsAuto1x">
                    <label class="gridCol1 giftcard_Label" for="div_checkout_gift_start_balance_${rndString}">${Names("StartingBalance")}</label>
                    <div id="div_checkout_gift_start_balance_${rndString}" class="gridCol2 giftcard_Amount">${Util.formatMoney(Util.Float2(card.balance))}</div>
                </div>
                <div class="gridColsAuto1x">
                    <label class="gridCol1 giftcard_Label" for="div_checkout_gift_applied_amount_${rndString}">${Names("AppliedToOrder")}</label>
                    <div id="div_checkout_gift_applied_amount_${rndString}" class="gridCol2 giftcard_Amount">${Util.formatMoney(Util.Float2(card.appliedAmount))}</div>
                </div>
                <div class="gridColsAuto1x">
                    <label class="gridCol1 giftcard_Label" for="div_checkout_gift_end_balance_${rndString}">${Names("EndingBalance")}</label>
                    <div id="div_checkout_gift_end_balance_${rndString}" class="gridCol2 giftcard_Amount">${Util.formatMoney(Util.Float2(card.balance - card.appliedAmount))}</div>
                </div>
                <div class="giftcard_Remove">
                    <button id="btn_checkout_gift_remove_${rndString}" class="btnLink" ltag="Remove">${Names("Remove")}</button>
                </div>
            </div>`;

        const mainDiv = document.getElementById("div_checkout_applied_gifts");
        if (mainDiv)
            mainDiv.appendChild(Util.createHtmlElementFromTemplate(html));

        const self = this;
        Util.setElement("onclick", `btn_checkout_gift_remove_${rndString}`, () => { self._removeGiftCard(card.cardNo, divID); });
        Util.setElement("value", "txt_checkout_gift_card_number", "");
        Util.setElement("value", "txt_checkout_gift_card_pin", "");
        return;
    }

    private _removeGiftCard = (cardNo: string, divId: string): void => {
        const div = document.getElementById(divId);
        if (div)
            div.remove();

        const index = aOLO.Temp.GiftCards.findIndex(gCard => gCard.cardNo === cardNo);
        if (index !== -1)
            aOLO.Temp.GiftCards.splice(index, 1);

        OnlineOrderingUtil.GUI_SetOrder_Total(true, this._aOLO, aOLOModules);
    }

    fetchGiftCardsBalance = async (uuids: string[]): Promise<any> => {
        if (this.isLoyaltyDown())
            return false;

        const payload: IFetchGiftCardsBalance = {
            store_key: this._aOLO.storeInfo.StoreKey,
            data: {
                location: "olo",
                uuids: uuids
            }
        };

        const response = await this._loyaltyService.fetchGiftCardsBalance(payload);
        if (response.success) {
            return true;
        } else {
            if (response.timeout)
                await this._setPunchhDown(response.timeout);
            else
                DialogCreators.messageBoxOk(response.message || "", this._aOLO.buttonHoverStyle);

            return false;
        }
    }

    /**
     * Creates the HTML for a reward tile.
     * @private
     * @param {Object} item - The reward data object.
     * @return {string} The HTML for the reward tile.
     */
    private _createRewardTile = (reward: IUserLoyaltyReward): string => {
        const redeemText = reward.Used ? "Void" : "Redeem";
        let html = `
            <div class="reward m1-t gridCols1xAuto_resp">
                <span class="bold gridCol1" ltagj='${Util.toLtagj(reward.Names)}'>${Common.GetName(reward.Names, aOLO.Temp.languageCode)}</span>
                <div class="gridCol2 right">
                    ${(reward.ExpiryDate != null) ? `<span ltag="Expires" class="fontSmaller">${Names("Expires")}</span> <span>${new Date(reward.ExpiryDate).toLocaleDateString(aOLO.User.CultureCode)}</span>` : ``}<br />
                    ${!aOLO.isBrandSignIn ? `<button id="btn_loyalty_reward_redeem_${reward.RewardId}" name="btn_loyalty_reward_redeem" data-rid="${reward.RewardId}" class="btnLink m0" ltag="${redeemText}">${Names(redeemText)}</button>` : ``}
                </div>
            </div>`;

        return html;
    }

    /**
     * Renders the rewards list on the loyalty summary page.
     * @public
     * @returns {void}
     */
    public renderRewardsList = (closeDialogFunction: Function): void => {
        const currentPoints = aOLO.User.LoyaltyData?.EarnedPoints || 0;
        let rewCnt = this._getRewardsCount();
        Util.setElement("innerHTML", "spn_loyalty_rewards", `(${rewCnt})`);

        if (aOLO.User?.CustomerId !== 0 && currentPoints !== 0) {
            Util.hideElement("div_loyalty_rewards_has_rewards");
            Util.showElement("div_loyalty_existing_rewards");
            Util.hideElement("div_loyalty_rewards_no_rewards");

            const pointsToNextReward = this._calculatePointsToNextReward();
            if (pointsToNextReward)
                Util.showElement("div_loyalty_rewards");

            this._loadRewards(closeDialogFunction);
        } else {
            Util.hideElement("div_loyalty_rewards_has_rewards");
            Util.hideElement("div_loyalty_existing_rewards");
            Util.showElement("div_loyalty_rewards_no_rewards");
        }
    }

    private _calculatePointsToNextReward(): number {
        let points = 9999;
        const pointConversionThreshold = this._getPointConversionThreshold();
        if (aOLO.User?.LoyaltyData) {
            points = pointConversionThreshold - (aOLO.User.LoyaltyData.EarnedPoints % pointConversionThreshold);
        }
        return points;
    }

    private _getPointConversionThreshold(): number {
        return this._metaData.points_conversion_threshold;
    }

    /**
     * Loads the rewards data and creates the HTML for the existing rewards section.
     * @private
     * @return {void}
     */
    private _loadRewards = (closeDialogFunction: Function): void => {
        let rewardsDiv = document.getElementById("div_loyalty_existing_rewards");
        if (!rewardsDiv)
            return;

        rewardsDiv.innerHTML = "";

        if (!aOLO.User?.LoyaltyData)
            return;

        aOLO.User.LoyaltyData.Rewards.forEach(reward => {
            if (rewardsDiv && !reward.Expired)
                rewardsDiv.innerHTML += this._createRewardTile(reward);
        });

        const redeemBtns = Array.from(document.getElementsByName("btn_loyalty_reward_redeem"));
        for (const button of redeemBtns) {
            button.onclick = async () => {
                if (!aOLO.isBrandSignIn) {
                    await Common.redeemRewardOnClick(Number(button.dataset.rid), aOLO.User.LoyaltyData);
                    closeDialogFunction();
                }
            };
        }
    }

    /**
     * Gets the count of rewards that the user has earned.
     * @private
     * @returns {number} The count of rewards that the user has earned.
     */
    private _getRewardsCount = (): number => {
        return (aOLO.User?.LoyaltyData) ? aOLO.User.LoyaltyData.Rewards.length : 0;
    }

    /**
     * Creates the HTML for a Gift card tile.
     * @private
     * @param {Object} item - The reward data object.
     * @return {string} The HTML for the reward tile.
     */
    private _createGiftCardTile = (giftCard: IGiftCard): string => {
        // Extract last 4 digits of the card number and replace the rest with *
        const maskedCardNumber = "**** " + giftCard.card_number.slice(-4);
        const formattedAmount = giftCard.last_fetched_amount.toFixed(2);

        // Construct the HTML for the "Apply" button only if last_fetched_amount is greater than 0
        const applyButtonHtml = giftCard.last_fetched_amount > 0 ?
            `<div class="gridCol2 right">
            <button name="btn_gift_card_apply" id="btn_gift_card_apply_${giftCard.gift_card_id}" ltagj="Apply" data-gcid="${giftCard.gift_card_id}" class="${aOLO.Temp.ButtonHoverStyles[aOLO.data.Settings.OLBTN]}">${Names("Apply")}</button>
        </div>` :
            '';

        let html = `
        <div class="gift_card m1-t gridCols1xAuto_resp">
            <div>
                ${Names("Number")}: <span class="bold gridCol1" >${maskedCardNumber}</span>
            </div>
            <br>
            <div>
                ${Names("Balance")}: <span> $${formattedAmount}</span>
            </div>
            ${applyButtonHtml}
            
        </div>`;

        return html;
    }

    public renderUserLoyaltyBalance(): void {
        if (!aOLO.User?.LoyaltyData)
            return;

        Util.hideElement("div_loyalty_not_joined");
        Util.hideElement("div_loyalty_last_trans_date");
        Util.hideElement("div_loyalty_points_last_updated_date");
        Util.hideElement("div_loyalty_points_title");
        Util.hideElement("div_loyalty_lifetime_points");

        Util.setElement("innerHTML", "spn_loyalty_banked_currency", Util.formatMoney(Number(aOLO.User.LoyaltyData.BankedCurrency)));
        Util.showElement("div_loyalty_banked_currency");

        Util.setElement("innerHTML", "spn_loyalty_joined_date", (aOLO.User.LoyaltyData.LoyaltyJoinedDate ? Util.formatDate(new Date(aOLO.User.LoyaltyData.LoyaltyJoinedDate)) : "n/a"));
        Util.setElement("innerHTML", "spn_loyalty_points", aOLO.User.LoyaltyData.CurrentPoints.toString());
    }

    /**
     * Renders the membership list on the loyalty summary page.
     * @public
     * @returns {void}
     */
    public renderMembershipList = (): void => {
        const currentPoints = aOLO.User.LoyaltyData?.EarnedPoints || 0;
        const currentTierName = aOLO.User.LoyaltyData?.TierName || "";

        Util.setElement("innerHTML", "spn_loyalty_membership", ` ${currentTierName}`);
        //Util.setElement("ltagj", "spn_loyalty_membership", (currentTier ? Util.toLtagj(currentTier.Names) : ""));

        if (aOLO.User) {
            Util.hideElement("div_loyalty_membership_has_membership");
            Util.hideElement("div_loyalty_membership_no_membership");

            const currentMembershipLevel = this._getCurrentMembershipLevel();
            if (currentMembershipLevel) {
                const image = `<img class="loyalty-icon-image" alt="membership icon" src="${currentMembershipLevel.background_image_url}" />`;
                Util.setElement("innerHTML", "div_loyalty_membership_icon", image);
                Util.showElement("div_loyalty_membership");
            }

            const nextMembershipLevel = this._getNextMembershipLevel();
            if (nextMembershipLevel != null) {
                Util.setElement("innerHTML", "spn_loyalty_membership_next_name", ` ${nextMembershipLevel.name}`);
                Util.setElement("innerHTML", "spn_loyalty_membership_points_needed", `${nextMembershipLevel.minimum_points - currentPoints} `);
                Util.setElement("innerHTML", "spn_loyalty_membership_bar_points", `${currentPoints}`);
                Util.setElement("innerHTML", "spn_loyalty_membership_next_bar_points", `${nextMembershipLevel.minimum_points}`);
                let widthDiv = document.getElementById("div_loyalty_membership_bar_points_width");
                if (widthDiv) {
                    let width = Math.round((currentPoints / nextMembershipLevel.maximum_points) * 100);
                    widthDiv.style.width = (width > 100 ? 100 : width) + "%";
                }
                Util.showElement("div_loyalty_membership_has_membership");
            }
        }
        else {
            Util.hideElement("div_loyalty_membership_has_membership");
            Util.showElement("div_loyalty_membership_no_membership");
        }
    }

    private _getNextMembershipLevel(): MembershipLevel | null {
        const currentPoints = aOLO.User?.LoyaltyData?.EarnedPoints || 0;
        const membershipLevels: MembershipLevel[] = this._metaData.membership_levels;
        const membershipsGreaterThan = membershipLevels.filter(x => x.minimum_points > currentPoints);

        let nextMembershipLevel: MembershipLevel | null = null;
        if (membershipsGreaterThan.length > 0) {
            nextMembershipLevel = membershipsGreaterThan[0];
        }
        return nextMembershipLevel;
    }

    private _getCurrentMembershipLevel(): MembershipLevel | undefined {
        const tierId = aOLO.User.LoyaltyData?.TierId;
        const membershipLevels: MembershipLevel[] = this._metaData.membership_levels;
        let currentMembershipLevel = membershipLevels.find(x => x.membership_level_id == tierId);
        return currentMembershipLevel;
    }

    renderSurveyOffersList(closeDialogFunction: Function, brandFunction: Function | null): void {
        Util.hideElement("div_loyalty_offers");
        return;
    }

    setIncompleteReward(rewardId: number | null): void {
        const rewardInBasket = this._activeBasket.find(x => x.discount_id === rewardId?.toString());
        if (!rewardInBasket)
            return;

        rewardInBasket.applied = false;
    }

    public setProfileValues = (profile: IProfile, isSignUp: boolean, monthOnClickFunction: Function, signUpFunction: Function, updateProfileFunction: Function,
        renderAddressListFunction: Function, setUserAllergiesFunction: Function, setUserDietaryFunction: Function, deleteProfileFunction: Function): void => {
        const self = this;
        let header = document.getElementById("h2_profile_header");
        let btn = document.getElementById("btn_profile_profile");
        let btnDelete = document.getElementById("btn_profile_delete");

        if (aOLO.User.IsBlocked)
            Util.hideElement("div_profile_join_loyalty");
        else {
            Util.showElement("div_profile_join_loyalty");
            profile.IsLoyalty = aOLO.User.IsLoyalty != undefined ? aOLO.User.IsLoyalty : true;
        }

        monthOnClickFunction();

        Util.hideElement("div_profile_age_13");
        Util.setElementClass("remove", "div_profile", "condensed");

        const allergies = document.getElementById("div_profile_allergies");
        const dietary = document.getElementById("div_profile_dietary");

        if (profile.ProfileId != 0) {
            Util.showElement("div_profile_delete");
            Util.hideElement("div_profile_password1");
            Util.hideElement("div_profile_password2");
            Util.hideElement("div_profile_terms_privacy_policy");
            Util.showElement("div_profile_addresses")
            //Util.showElement("div_profile_wallet")

            if (header) {
                header.innerText = Names("Profile");
                header.setAttribute("ltag", "Profile");
            }
            if (btnDelete) {
                btnDelete.innerText = Names("UpdateMyProfile");
                btnDelete.setAttribute("ltag", "UpdateMyProfile");
                btnDelete.onclick = function () {
                    deleteProfileFunction();
                };
            }
            if (btn) {
                btn.innerText = Names("UpdateMyProfile");
                btn.setAttribute("ltag", "UpdateMyProfile");
                btn.onclick = function () { updateProfileFunction() };
            }

            Util.setElement("disabled", "txt_profile_mark_promo_text", !profile.IsMarketingText);

            if (profile.Addresses?.length > 0) {
                Util.showElement("div_profile_all_addresses");
                Util.hideElement("spn_profile_addresses")
                renderAddressListFunction()
            } else {
                Util.hideElement("div_profile_all_addresses")
                Util.showElement("spn_profile_addresses");
            }

            if (profile.IsLoyalty)
                Util.hideElement("div_profile_join_loyalty");

            if (aOLO.data.Settings?.PRIVPOL === 0 && !profile.IsAge13)
                Util.showElement("div_profile_age_13");

            if (aOLO.User && !aOLO.User.IsProfileComplete) {
                Util.showElement("div_profile_terms_privacy_policy");
                if (!profile.Email) {
                    Util.showElement("div_profile_password1");
                    Util.showElement("div_profile_password2");
                }
            }
        } else {
            Util.showElement("div_profile_password1");
            Util.showElement("div_profile_password2");
            Util.showElement("div_profile_terms_privacy_policy");
            Util.hideElement("div_profile_addresses")
            Util.hideElement("div_profile_wallet")

            if (header) {
                header.innerText = Names("CreateAccount");
                header.setAttribute("ltag", "CreateAccount");
            }

            if (btn) {
                btn.innerText = Names("SignUp");
                btn.setAttribute("ltag", "SignUp");
                btn.onclick = function () { signUpFunction() };
            }

            if (aOLO.SignUpLoyaltyLink?.PhoneNumber)
                Util.setElement("value", "txt_profile_phone", Util.formatPhoneNumber(profile.Phone, Names("PhoneFormat", this._phoneFormatLanguage)));

            Util.setElement("disabled", "txt_profile_mark_promo_text", true);

            if (aOLO.data.Settings?.PRIVPOL === 0)
                Util.showElement("div_profile_age_13");

            if (allergies && allergies.classList.contains("hidden") && dietary && dietary.classList.contains("hidden"))
                Util.setElementClass("add", "div_profile", "condensed");
        }

        if (aOLO.data.Settings?.BDAYREQ === 0)
            Util.hideElement("div_profile_birthday");

        Util.setElement("value", "txt_profile_phone", Util.formatPhoneNumber(profile.Phone, Names("PhoneFormat", this._phoneFormatLanguage)));

        Util.setElement("value", "sel_profile_birth_month", profile.BirthMonth);
        Util.setElement("value", "sel_profile_birth_day", profile.BirthDay);
        Util.setElement("value", "txt_profile_mark_promo_text", (profile.MarketingPhone ? Util.formatPhoneNumber(profile.MarketingPhone, Names("PhoneFormat", this._phoneFormatLanguage)) : Util.formatPhoneNumber(profile.Phone, Names("PhoneFormat", this._phoneFormatLanguage))));

        setUserAllergiesFunction()
        setUserDietaryFunction()

        if (aOLO.isBrandSignIn)
            Util.showElement("div_profile_order_now");
        else
            Util.hideElement("div_profile_order_now");

        Util.hideElement("div_profile_delete");
        Util.hideElement("div_chk_profile_survey");
        Util.hideElement("div_profile_allergies");
        Util.hideElement("div_profile_dietary");
        Util.hideElement("div_profile_join_loyalty");
        Util.hideElement("div_profile_unable_to_edit");
        if (!isSignUp) {
            Util.hideElement("btn_profile_profile");
            Util.showElement("div_profile_unable_to_edit");
        }
        this._makeProfileReadOnly(isSignUp);
    }

    renderAddressList = (profile: IProfile, addressEditFunction: Function, addressRemoveFunction: Function, addressSetDefaultFunction: Function): void => {
        let div = document.getElementById("div_profile_all_addresses");
        if (div) {
            div.innerHTML = "";

            profile.Addresses.forEach(address => {
                let adr = `${address.STRNO} ${address.ADDR1}${address.ADDR2 ? ` #${address.ADDR2}` : ``}, ${address.CITY}, ${address.STA} ${address.ZIP}`;
                let html = `
                    <div id="div_profile_address_${address.AID}" class="m1-tb">
                        <span>${adr}</span> <br>
                    </div>`;

                if (div)
                    div.innerHTML += html;
            });
        }
    }

    private _makeProfileReadOnly = (isSignUp: boolean): void => {
        const profileInputs = document.querySelectorAll("[id^=txt_profile]");
        const profileCheckboxes = document.querySelectorAll("[id^=chk_profile]");
        const profileSelects = document.querySelectorAll("[id^=sel_profile]");
        const emailSelects = document.querySelectorAll("[id^=eml_profile]");

        if (!isSignUp) {
            profileInputs.forEach(input => {
                input.setAttribute("readonly", "true");
            });

            emailSelects.forEach(input => {
                input.setAttribute("readonly", "true");
            });

            profileCheckboxes.forEach(checkbox => {
                checkbox.setAttribute("disabled", "true");
            });

            profileSelects.forEach(select => {
                select.setAttribute("disabled", "true");
            });

            return;
        }

        profileInputs.forEach(input => {
            input.removeAttribute("readonly");
        });

        emailSelects.forEach(input => {
            input.removeAttribute("readonly");
        });

        profileCheckboxes.forEach(checkbox => {
            checkbox.removeAttribute("disabled");
        });

        profileSelects.forEach(select => {
            select.removeAttribute("disabled");
        });

        return;
    }

    postOrder = async (order: IOrder, redemptionIds?: number[] | undefined): Promise<void> => {
        const payload: ICreateFinishOrderPayloadLoyaltyResult = {
            store_key: this._aOLO.storeInfo.StoreKey,
            data: {
                user_id: this._aOLO.User.LoyaltyReferenceID ? Number(this._aOLO.User.LoyaltyReferenceID) : null,
                check_in: false,
                pos_version: "2",
                order_id: order.OrderID,
                is_posted: false,
                status_id: 1,
                receipt_datetime: "",
                order_type_sub_type_id: order.OrderTypeSubID.toString(),
                order_type_sub_type_name: "",
                subtotal_amount: Number((order.SubTotal - order.Discount).toFixed(2)),
                gross_amount: order.Total,
                email: this._aOLO.User.Email || "",
                employee_id: 0,
                employee_name: "",
                redemption_ids: [],
                menu_items: [],
                items: []
            }
        };

        const result = await this._loyaltyService.postOrderAsync(payload);
        return;
    }
}
