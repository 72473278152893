import { FieldType, OrderType, BahamasCoordinates, LoyaltyProvider, LoggedInStatus } from './types/enums';
import { AdoraHttpClient } from './utils/http-client';
import { Util } from './utils/util';
import { Names, mergeTranslations, setPageLanguage } from './utils/i18n';
import { IaOLO, IaOLOModules } from './interfaces/aolo.interface';
import { ITemp, ITempIncompleteDiscount, ITempTheme } from './interfaces/temp.interface';
import { IDescription, IName, INewName } from './interfaces/global.interfaces';
import { IUserCampaignCodesData, IUserLoyalty, IUserLoyaltyReward, IUserOffers } from './models/interfaces/user.interface';
import { PrivacyTerms } from './online-ordering/privacy-and-terms';
import { IDataTax } from './interfaces/data.interface';
import { Coupon } from './online-ordering/coupon';
import { IOrderCoupon } from './interfaces/order.interface';
import { User } from './models/user';

declare global {
    var aOLO: IaOLO;
    var aOLOModules: IaOLOModules;
}

/**
 * Reloads the user profile.
 * If aOLO.ProfileUser does not exist, it creates a new instance of ProfileUser class.
 * It then calls the getUser method from the ProfileService class to retrieve user data and updates aOLO.User.
 * After that, it calls the setHamburgerMenuItems function to update the hamburger menu.
 * @async
 * @returns {Promise<void>}
 */
export async function profileReloadApp(brandFunction: Function | null, oloFunction: Function | null, token: string, user: User): Promise<void> {
    if (token == "") {
        Common.logout(aOLO.isBrandSignIn ? function () {
            if (brandFunction)
                brandFunction(aOLO.landingPage, true);
        } : async function () {
            if (oloFunction)
                oloFunction(aOLO.Temp.languageCode)
        }, aOLO, user, true);
        return;
    }

    const result = await aOLOModules.LoyaltyProvider.signInByAppAsync(token);

    if (!result.success) {
        Common.logout(aOLO.isBrandSignIn ? function () {
            if (brandFunction)
                brandFunction("signin", true);
        } : async function () {
            if (oloFunction)
                oloFunction(aOLO.Temp.languageCode)
        }, aOLO, user, true);
        return;
    }

    if (result.user)
        user.user = result.user;

    if (result.jwt !== null)
        await user.signIn(result.jwt);

    Common.initiateUser(user);

    Common.setHamburgerMenuItems(user, aOLO);

    if (user.getProperty("IsDarkMode") !== aOLO.Temp.DarkMode)
        Common.toggleDarkMode(aOLO.Temp, user)

    if (user.getProperty("CultureCode") !== aOLO.Temp.languageCode)
        setPageLanguage(user.getProperty("CultureCode") || "en-us");
}

export async function getMobileLocation(): Promise<void> {
    const cookieLocation = Util.getUserLocationFromCookie();
    if (cookieLocation == null) {
        var tryCount = 0;
        return new Promise(resolve => {
            function checkFunction() {
                //@ts-ignore
                if (typeof getMobileAppLocation == "function") {
                    //@ts-ignore
                    getMobileAppLocation("");
                    resolve();
                } else {
                    if (tryCount > 10) {
                        setMobileLocation('');
                        resolve();
                    } else {
                        tryCount++;
                        setTimeout(checkFunction, 100);
                    }
                }
            }

            checkFunction();
        });
    }
}

////tempppppppppppppppp
//function getMobileAppLocation(){
//    setMobileLocation('{"lat":38.7746747,"lng":-121.3322511}');

//}
export function setMobileLocation(locationResult: string) {
        let location = { lat: 0, lng: 0 };
    if (locationResult && locationResult != '') {
        location = JSON.parse(locationResult);
        try { document.cookie = `locationData={"lat":${location.lat},"lng":${location.lng}};max-age= 3600;path=/;domain=${window.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.')}`; } catch { }
    } else {
        try { document.cookie = `locationData={"lat":${0},"lng":${0}};max-age= -10;path=/;domain=${window.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.')}`; } catch { }
    }

}
export function goBack() {
    history.go(-1);
}

export class Common {
    public static async LoadStoreData(dataUrl: string): Promise<any> {
        try {
            const myInit = this.GetInit();
            const myRequest = new Request(dataUrl);
            const response = await fetch(myRequest, myInit);
            return await response.json();
        } catch {
            return null;
        }
    }

    /**
     * Initializes the behavior of the page based on specific conditions and functionalities.
     * 
     * @param shouldRefreshToken - Determines if the token refresh mechanism should be enabled.
     */
    public static initializePageBehavior(
        shouldRefreshToken: boolean,
        user: User,
        brandDisplayPage: (component: string, recordHistory: boolean, incompleteProfile?: boolean) => Promise<void>,
        updateBannerSlideWidth: () => void
    ): void {
        window.onpopstate = async (event): Promise<void> => {
            if (event.state?.link) {
                if (event.state.loggedIn && !user.isLoggedIn())
                    await brandDisplayPage("signin", false);
                await brandDisplayPage(event.state.link, false);
            }
        };

        if (shouldRefreshToken) {
            setInterval(aOLO.Modules.ProfileService.refreshToken, 30 * 60 * 1000);
        }

        window.addEventListener('resize', () => { updateBannerSlideWidth(); });

        const isPageLoaded = sessionStorage.getItem('pageLoaded') === "true";
        const trackerUrl = sessionStorage.getItem('trackerUrl');
        if (isPageLoaded && trackerUrl) {
            sessionStorage.removeItem('pageLoaded');
            sessionStorage.removeItem('trackerUrl');
            window.location.href = trackerUrl;
            return;
        }

        sessionStorage.setItem('pageLoaded', 'true');

        function handleSessionStorageUpdate(): void {
            if (window.location.search.includes("tracker") && aOLO?.trackerUrl) {
                sessionStorage.setItem('trackerUrl', aOLO.trackerUrl);
            }
        }

        //// beforeunload not supported on safari + ios https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
        if (Common.isSafariOniOS()) {
            window.addEventListener('visibilitychange', () => {
                if (document.visibilityState === 'hidden') {
                    handleSessionStorageUpdate();
                }
            });
        } else {
            window.addEventListener('beforeunload', handleSessionStorageUpdate);
        }
    }

    public static GetInit(): object {
        let myHeaders = new Headers();
        myHeaders.append('pragma', 'no-cache');
        myHeaders.append('cache-control', 'no-store');
        const myInit = {
            method: 'GET',
            headers: myHeaders,
        };
        return myInit;
    }

    private static async voidOffer(couponCode: string, offers: IUserCampaignCodesData[], coupons: IOrderCoupon[], couponModule: Coupon, incompleteCoupons: ITempIncompleteDiscount[] | undefined): Promise<void> {
        const usedOffer = offers.find(x => x.CouponCode == couponCode && x.Used);
        if (!usedOffer)
            return;

        usedOffer.Used = false;

        const coupon = coupons.find(x => x.CouponCode.toLowerCase() === couponCode.toLowerCase());
        if (coupon)
            await couponModule.RemoveCouponByKey(coupon.CouponKey, true);
        else if (incompleteCoupons) {
            const incompleteCoupon = incompleteCoupons.find(x => x.couponCode === couponCode.toLowerCase());
            if (incompleteCoupon)
                couponModule.RemoveIncompleteCouponById(incompleteCoupon.couponID);
        }
    }

    private static applyOffer(offer: IUserCampaignCodesData, coupon: Coupon): void {
        const isCampaingCode = (offer.IsSingleUseCoupon && !offer.IsCouponPromotionCode);
        const isPromotionCode = (offer.IsSingleUseCoupon && offer.IsCouponPromotionCode);
        coupon.ApplyCouponByCode(offer.CouponCode, true, isPromotionCode, isCampaingCode);
    }

    public static async redeemOffersOnClick(couponCode: string, offers: IUserOffers | null | undefined, coupons: IOrderCoupon[], coupon: Coupon, incompleteCoupons: ITempIncompleteDiscount[] | undefined): Promise<void> {
        if (!offers || offers.Codes.length == 0)
            return;

        const offer = offers.Codes.find(offer => offer.CouponCode.toLowerCase() == couponCode.toLowerCase());
        if (!offer)
            return;

        if (!offer.Used)
            this.applyOffer(offer, coupon);
        else
            await this.voidOffer(offer.CouponCode, offers.Codes, coupons, coupon, incompleteCoupons);
    }

    public static async LoadStoreBannerData(storeKey: string, localAolo: IaOLO): Promise<any> {
        const response = await localAolo.Modules.ProfileService.getLandingBanner(storeKey, localAolo);
        return response;
    }

    public static showPrivacy(localAolo: IaOLO): void {
        new PrivacyTerms().DisplayPrivacy(localAolo);
    }

    public static showTerms(localAolo: IaOLO): void {
        new PrivacyTerms().DisplayTerms(localAolo);
    }

    public static redeemRewardOnClick = async (rewardId: number, userLoyalty: IUserLoyalty | null): Promise<void> => {
        if (!userLoyalty)
            return;

        const rewards = userLoyalty.Rewards.filter(reward => !reward.Expired);
        const reward = rewards.find(x => x.RewardId === rewardId);
        if (!reward)
            return;

        if (!reward.Used)
            await this.applyReward(reward);
        else
            await this.voidReward(reward, rewards);
    }

    private static applyReward = async (reward: IUserLoyaltyReward): Promise<void> => {
        await aOLOModules.LoyaltyProvider.addReward(reward);
    }

    private static voidReward = async (reward: IUserLoyaltyReward, rewards: IUserLoyaltyReward[]): Promise<void> => {
        const usedReward = rewards.find(y => (y.RewardId == reward.RewardId) && (y.Used));

        if (!usedReward)
            return;

        await aOLOModules.LoyaltyProvider.removeReward(reward);
    }

    /**
     * Returns the local culture code stored in localStorage, or sets and returns "en-us" if not found.
     * @returns {string} The local culture code, e.g. "en-us" or "sp-mx".
     */
    private static GetLocalCulture(): string {
        const cult = aOLO.Temp?.languageCode ? aOLO.Temp.languageCode : localStorage.getItem("CultureCode");
        switch (cult) {
            case "en-us":
            case "sp-mx":
                return cult;
            default:
                window.localStorage.setItem("CultureCode", "en-us");
                return "en-us";
        }
    }

    private static GetTheme(): ITempTheme {
        const root = document.documentElement;
        const theme: ITempTheme = {
            colorText: getComputedStyle(root).getPropertyValue('--colorText'),
            colorInputBg: getComputedStyle(root).getPropertyValue('--colorInputBg'),
            colorFocusBg: getComputedStyle(root).getPropertyValue('--colorFocusBg'),
            colorFocus: getComputedStyle(root).getPropertyValue('--colorFocus'),
            colorBg: getComputedStyle(root).getPropertyValue('--colorBg'),
            colorBgCard: getComputedStyle(root).getPropertyValue('--colorBgCard'),
            colorBgRow: getComputedStyle(root).getPropertyValue('--colorBgRow'),
            colorTheme: getComputedStyle(root).getPropertyValue('--colorTheme'),
            colorThemeBg: getComputedStyle(root).getPropertyValue('--colorThemeBg'),
            colorThemeSecondary: getComputedStyle(root).getPropertyValue('--colorThemeSecondary'),
            colorHover: getComputedStyle(root).getPropertyValue('--colorHover'),
            colorActive: getComputedStyle(root).getPropertyValue('--colorActive'),
            colorBorder: getComputedStyle(root).getPropertyValue('--colorBorder'),
            colorWarning: getComputedStyle(root).getPropertyValue('--colorWarning'),
            colorDefMod: getComputedStyle(root).getPropertyValue('--colorDefMod'),
            colorAddMod: getComputedStyle(root).getPropertyValue('--colorAddMod'),
            colorAdjustMod: getComputedStyle(root).getPropertyValue('--colorAdjustMod'),
            colorDisabled: getComputedStyle(root).getPropertyValue('--colorDisabled'),
            hedBgColor: getComputedStyle(root).getPropertyValue('--hedBgColor'),
            hedCartColor: getComputedStyle(root).getPropertyValue('--hedCartColor'),
            btnColor: getComputedStyle(root).getPropertyValue('--btnColor'),
            btnColorBg: getComputedStyle(root).getPropertyValue('--btnColorBg'),
            btnColorHover: getComputedStyle(root).getPropertyValue('--btnColorHover'),
            btnColorBgHover: getComputedStyle(root).getPropertyValue('--btnColorBgHover'),
            btnColorActive: getComputedStyle(root).getPropertyValue('--btnColorActive'),
            btnColorDisabled: getComputedStyle(root).getPropertyValue('--btnColorDisabled'),
            btnColorBgActive: getComputedStyle(root).getPropertyValue('--btnColorBgActive'),
            btnPadding: getComputedStyle(root).getPropertyValue('--btnPadding'),
            btnBorder: getComputedStyle(root).getPropertyValue('--btnBorder'),
            btnRadius: getComputedStyle(root).getPropertyValue('--btnRadius'),
            btnIconColor: getComputedStyle(root).getPropertyValue('--btnIconColor'),
            btnIconColorBg: getComputedStyle(root).getPropertyValue('--btnIconColorBg'),
            btnLinkColor: getComputedStyle(root).getPropertyValue('--btnLinkColor'),
            catColor: getComputedStyle(root).getPropertyValue('--catColor'),
            catColorBg: getComputedStyle(root).getPropertyValue('--catColorBg'),
            catColorHover: getComputedStyle(root).getPropertyValue('--catColorHover'),
            catColorActive: getComputedStyle(root).getPropertyValue('--catColorActive'),
            ordBgImage: getComputedStyle(root).getPropertyValue('--ordBgImage'),
            ordBgColor: getComputedStyle(root).getPropertyValue('--ordBgColor'),
            itmBorder: getComputedStyle(root).getPropertyValue('--itmBorder'),
            itmColorBG: getComputedStyle(root).getPropertyValue('--itmColorBG'),
            diBorder: getComputedStyle(root).getPropertyValue('--diBorder'),
            diColorBg: getComputedStyle(root).getPropertyValue('--diColorBg'),
            shadow: getComputedStyle(root).getPropertyValue('--shadow'),
            backgroundImgUrl: getComputedStyle(root).getPropertyValue('--backgroundImgUrl'),
            placeHolder: getComputedStyle(root).getPropertyValue('--colorPlaceholder'),
            colorPlaceholderOnly: getComputedStyle(root).getPropertyValue('--colorPlaceholderOnly'),
            colorDatalistHover: getComputedStyle(root).getPropertyValue('--colorDatalistHover'),
            colorOrderDetailAltRow: getComputedStyle(root).getPropertyValue('--colorOrderDetailAltRow'),
            colorOrderDetailHeader: getComputedStyle(root).getPropertyValue('--colorOrderDetailHeader'),
            colorOrderDetailHeaderText: getComputedStyle(root).getPropertyValue('--colorOrderDetailHeaderText')
        };
        return theme;
    }

    /**
    * Initializes the aOLO.Temp object with various temporary properties.
    * @returns {void}
    */
    public static InitiateTemps(_localaOLO: IaOLO) {
        const siTemp = sessionStorage.getItem("siTemp");
        _localaOLO.Temp = {
            Age: 0,
            AddingCustomItemToOrder: false,
            ButtonHoverStyles: ["hvr-none", "hvr-fade", "hvr-back-pulse", "hvr-sweep-to-right", "hvr-sweep-to-left", "hvr-sweep-to-bottom", "hvr-sweep-to-top", "hvr-bounce-to-right", "hvr-bounce-to-left", "hvr-bounce-to-bottom", "hvr-bounce-to-top", "hvr-radial-out", "hvr-radial-in", "hvr-rectangle-in", "hvr-rectangle-out", "hvr-shutter-in-horizontal", "hvr-shutter-out-horizontal", "hvr-shutter-in-vertical", "hvr-shutter-out-vertical"],
            Contactless: false,
            Curbside: false,
            CustomerAcceptedDistanceDialog: false,
            DarkMode: (siTemp && siTemp !== "") ? JSON.parse(siTemp).DarkMode : false,
            DefPreModID: 0,
            GiftCards: [],
            HeaderHeight: parseInt(window.getComputedStyle(document.documentElement).getPropertyValue('--hedHeight')),
            IncompleteDiscounts: [],
            IsSubmitting: false,
            languageCode: this.GetLocalCulture(),
            loyaltyPointCoupons: [],
            loyaltyPointsRedeemed: 0,
            MeetPromiseTimeWarning: false,
            MobileLocation: { lat: 0, lng: 0},
            Mobile_Card: false,
            NonPreModID: 0,
            OrderTypeID: OrderType.DELIVERY,
            OrderingStarted: false,
            RewardsDisplayed: false,
            SelectedAddress: "",
            SelectedStoreID: 0,
            Theme: this.GetTheme(),
            TimeOffset: 0,
            UpSold: false,
            WaitTime: 0
        };
    }

    /**
    * Sets the items to display in the hamburger menu based on the user's login status and loyalty program status.
    * @returns {void}
    */
    public static setHamburgerMenuItems(user: User, localaOLO: IaOLO): void {
        const loggedIn = user.isLoggedIn() || false;
        const loyalty = (localaOLO.data.Loyalty && localaOLO.data.Loyalty.ProviderId !== LoyaltyProvider.NONE);
        const noLoyalty = !user.getProperty("IsLoyalty");
        const isProfileComplete = user.getProperty("IsProfileComplete");

        const hideElementsName = [
            'li_vertical_menu_rewards',
            'li_vertical_menu_profile',
            'li_vertical_menu_orders',
            'li_vertical_menu_password',
            'li_vertical_menu_logout',
            'li_vertical_menu_history',
            'li_vertical_menu_signup',
            'li_vertical_menu_signin'
        ];

        const loggedInAndLoyaltyElementsName = aOLOModules.LoyaltyProvider.getHamburgerMenuOptions();

        const loggedInAndNoLoyaltyElementsName = [
            'li_vertical_menu_rewards',
            'li_vertical_menu_profile',
            'li_vertical_menu_orders',
            'li_vertical_menu_password',
            'li_vertical_menu_logout'
        ];

        const loggedInElementsName = [
            'li_vertical_menu_profile',
            'li_vertical_menu_orders',
            'li_vertical_menu_password',
            'li_vertical_menu_logout'
        ];

        const notLoggedInElementsName = [
            'li_vertical_menu_signup',
            'li_vertical_menu_signin'
        ];

        const anonymousElementsName = [
            'li_vertical_menu_locations',
            'li_vertical_menu_faq',
            'li_vertical_menu_contact',
            'li_vertical_terms_conditions'
        ];

        const incompleteProfileElements = [
            'li_vertical_menu_profile',
            'li_vertical_menu_logout'
        ]

        if (Util.isAppView())
            anonymousElementsName.push("li_vertical_menu_home");

        this.showHideElementsByName(false, hideElementsName);
        if (loggedIn) {
            if (!isProfileComplete)
                this.showHideElementsByName(true, incompleteProfileElements);
            else if (loyalty && !noLoyalty)
                this.showHideElementsByName(true, loggedInAndLoyaltyElementsName);
            else if (loyalty && noLoyalty)
                this.showHideElementsByName(true, loggedInAndNoLoyaltyElementsName);
            else
                this.showHideElementsByName(true, loggedInElementsName);
        } else
            this.showHideElementsByName(true, notLoggedInElementsName);

        this.showHideElementsByName(true, anonymousElementsName);
    }

    /**
     * Shows or hides elements with the given names based on the given show parameter.
     * @param {boolean} show - Whether to show (true) or hide (false) the elements.
     * @param {string[]} elementNames - The names of the elements
     * @return {void}
     */
    private static showHideElementsByName(show: boolean, elementNames: string[]): void {
        elementNames.forEach((elementName: string) => {
            document.getElementsByName(elementName).forEach((el: Element) => {
                if (show)
                    el.classList.remove('hidden');
                else
                    el.classList.add('hidden');
            });
        });
    }

    public static createCheckBox(parentDiv: HTMLElement, id: string, name: string, checked: boolean, caption: string, onChange: Function | null, value: string) {
        const div = document.createElement("div");
        div.classList.add("checkRadio");

        const inputCheckbox = document.createElement("input");
        inputCheckbox.type = "checkbox";
        inputCheckbox.id = id;
        inputCheckbox.name = name;
        inputCheckbox.dataset.value = value;
        inputCheckbox.checked = checked;
        if (onChange)
            inputCheckbox.onclick = () => { onChange };

        const labelCheckbox = document.createElement("label");
        labelCheckbox.setAttribute("for", id);
        labelCheckbox.innerHTML = caption;

        div.appendChild(inputCheckbox);
        div.appendChild(labelCheckbox);
        parentDiv.appendChild(div);
        return div;
    }

    /**
     * Logs out the user and returns to the sign-in page.
     * @async
     * @returns {Promise<void>} - A promise that resolves when the user is successfully logged out and returned to the sign-in page.
     */
    public static async logout(logOutFunction: Function, localaOLO: IaOLO, user: User, error: boolean = false): Promise<void> {
        if (await user.logout() || error) {
            if (error)
                localStorage.removeItem("tk");

            if (Util.isAppView()) {
                //@ts-ignore
                if (typeof deleteAppUserToken == "function")
                    //@ts-ignore
                    deleteAppUserToken("");
            }

            user.updateUser({ CustomerId: 0, ProfileId: 0, LoyaltyData: null });

            // Do not use this or self here.
            Common.setHamburgerMenuItems(user, aOLO);
            if (localaOLO.isBrandSignIn)
                logOutFunction("signin", true);
            else {
                if (localaOLO.Modules.OrderCart)
                    localaOLO.Modules.OrderCart.CleanCart();
                await logOutFunction();
            }
        }
    }

    /**
     * Validates user input based on the given type and value
     * @async
     * @param {boolean} needValidate - Indicates if validation is required
     * @param {string | null} value - The value to validate
     * @param {string} type - The type of input to validate
     * @param {string} validErrorSpanId - The id of the error message span element
     * @param {boolean} showValidErrorSpan - Indicates whether to show or hide the error message span
     * @param {boolean} [forceValidationFailed=false] - Indicates whether to force validation to fail
     * @returns {Promise<string>} - The validation message
     */
    public static async ValidateInput(needValidate: boolean, value: string | string[] | null, type: string, validErrorSpanId: string, showValidErrorSpan: boolean, localAolo: IaOLO, forceValidationFailed: boolean = false): Promise<string> {
        let msg = "";
        let tmpVal: string | number;
        let valFailed = true;
        let value1 = "";
        let value2 = "";
        let stringValue = "";
        if (value !== null) {
            if (typeof value !== 'string') {
                value1 = value[0];
                value2 = value[1];
            } else
                stringValue = value;
        }

        if (needValidate) {
            switch (type) {
                case FieldType.PHONE:
                    tmpVal = Util.cleanNonDigits(stringValue);
                    valFailed = !Util.CheckIsPhone(tmpVal.toString());
                    break;
                case FieldType.EMAIL:
                    tmpVal = stringValue.trim();
                    valFailed = !Util.CheckIsEmail(tmpVal.toString());
                    break;
                case FieldType.DAY:
                    tmpVal = parseInt(stringValue);
                    valFailed = (isNaN(tmpVal) || (tmpVal < 1) || (tmpVal > 31));
                    break;
                case FieldType.MONTH:
                    tmpVal = parseInt(stringValue.trim());
                    valFailed = (isNaN(tmpVal) || (tmpVal < 1) || (tmpVal > 12));
                    break;
                case FieldType.NAME:
                case FieldType.CAR:
                    valFailed = (stringValue === "");
                    break;
                case FieldType.PASSWORD:
                    tmpVal = stringValue.trim();
                    valFailed = !Util.CheckIsPassword(tmpVal.toString());
                    break;
                case FieldType.OLDPASSWORD:
                    tmpVal = stringValue;
                    //valFailed = !(await aOLO.Modules.ProfileService.checkPassword(tmpVal.toString()));
                    valFailed = !(await localAolo.Modules.ProfileService.checkPassword(tmpVal.toString(), localAolo));
                    break;
                case FieldType.COMPAREPASSWORDS: {
                    const tmpVal1 = value1.trim();
                    const tmpVal2 = value2.trim();
                    valFailed = (tmpVal1 != tmpVal2);
                    break;
                }
                case FieldType.BOOLEAN:
                    valFailed = (stringValue === "false");
                    break;
                case FieldType.CUSTOM:
                    valFailed = !(stringValue);
                    break;
                case FieldType.ZIP:
                    valFailed = !(await this.validateZip(stringValue));
                    break;
                case FieldType.FAILED:
                    valFailed = true;
                    break;
            }
        }

        if (validErrorSpanId) {
            if (valFailed && showValidErrorSpan && (needValidate || forceValidationFailed)) {
                Util.showElement(validErrorSpanId);
                const message = document.getElementById(validErrorSpanId);
                if (message) {
                    const errorMessage = message.getAttribute("ltag");
                    if (errorMessage)
                        msg = Names(errorMessage);
                }
            } else
                Util.hideElement(validErrorSpanId);

        }
        return msg;
    }

    /**
     * Validates a zip code using an external API
     * @async
     * @param {string} zip - The zip code to validate
     * @returns {Promise<boolean>} - Indicates if the zip code is valid
     */
    private static async validateZip(zip: string): Promise<boolean> {
        const apiURL = `${location.origin}/api/v1.0/geo/`;
        const response = await AdoraHttpClient.httpRequest(true, 'GET', `${apiURL}validate_zip?zip=${zip}`, null, null);
        if (response.status == 200) {
            const respData = await AdoraHttpClient.getResponsePayload(response);
            return respData;
        }
        return false;
    }

    /**
    * Creates a new <li> element with the given text content.
    * @param {string} text - The text to display inside the <li> element.
    * @returns {HTMLElement} - A new <li> element with the given text content.
    */
    public static GetLi(text: string): HTMLElement {
        const li = document.createElement("li");
        li.innerText = text;
        return li;
    }

    /**
    * Returns the name of a month based on its numerical ID.
    * @param {number} monthID - The numerical ID of the month.
    * @returns {string} - The name of the month.
    */
    public static getMonthText(monthID: number): string {
        switch (monthID) {
            case 1:
                return "January";
            case 2:
                return "February";
            case 3:
                return "March";
            case 4:
                return "April";
            case 5:
                return "May";
            case 6:
                return "June";
            case 7:
                return "July";
            case 8:
                return "August";
            case 9:
                return "September";
            case 10:
                return "October";
            case 11:
                return "November";
            case 12:
                return "December";
            default:
                return "";
        }
    }

    public static GetDescription(descriptions: IDescription[], fallbackLanguageCode: string, languageCode?: string): string {
        if (!languageCode)
            languageCode = fallbackLanguageCode;
        return descriptions ? (descriptions.find(d => d.CULT === languageCode)?.DSCR || "") : "";
    }

    /**
    * Toggles the dark mode for the application and updates the colors accordingly.
    * @returns {void}
    */
    public static toggleDarkMode(temp: ITemp, user: User): void {
        temp.DarkMode = !temp.DarkMode;

        if (temp.DarkMode)
            localStorage.setItem("darkMode", "true");
        else
            localStorage.removeItem('darkMode');

        if (user.isLoggedIn())
            user.updateUserDarkMode(temp.DarkMode);

        const root = document.documentElement;

        if (temp.DarkMode) {
            root.style.setProperty('--colorText', getComputedStyle(root).getPropertyValue('--darkColorText'));
            root.style.setProperty('--colorInputBg', getComputedStyle(root).getPropertyValue('--darkColorInputBg'));
            root.style.setProperty('--colorFocusBg', getComputedStyle(root).getPropertyValue('--darkColorFocusBg'));
            root.style.setProperty('--colorFocus', getComputedStyle(root).getPropertyValue('--darkColorFocus'));
            root.style.setProperty('--colorBg', getComputedStyle(root).getPropertyValue('--darkColorBg'));
            root.style.setProperty('--colorBgCard', getComputedStyle(root).getPropertyValue('--darkColorBgCard'));
            root.style.setProperty('--colorBgRow', getComputedStyle(root).getPropertyValue('--darkColorBgRow'));
            root.style.setProperty('--colorTheme', getComputedStyle(root).getPropertyValue('--darkColorTheme'));
            root.style.setProperty('--colorThemeBg', getComputedStyle(root).getPropertyValue('--darkColorThemeBg'));
            root.style.setProperty('--colorThemeSecondary', getComputedStyle(root).getPropertyValue('--darkColorThemeSecondary'));
            root.style.setProperty('--colorHover', getComputedStyle(root).getPropertyValue('--darkColorHover'));
            root.style.setProperty('--colorActive', getComputedStyle(root).getPropertyValue('--darkColorActive'));
            root.style.setProperty('--colorBorder', getComputedStyle(root).getPropertyValue('--darkColorBorder'));
            root.style.setProperty('--colorWarning', getComputedStyle(root).getPropertyValue('--darkColorWarning'));
            root.style.setProperty('--colorDefMod', getComputedStyle(root).getPropertyValue('--darkColorDefMod'));
            root.style.setProperty('--colorAddMod', getComputedStyle(root).getPropertyValue('--darkColorAddMod'));
            root.style.setProperty('--colorAdjustMod', getComputedStyle(root).getPropertyValue('--darkColorAdjustMod'));
            root.style.setProperty('--colorDisabled', getComputedStyle(root).getPropertyValue('--darkColorDisabled'));
            root.style.setProperty('--hedBgColor', getComputedStyle(root).getPropertyValue('--darkHedBgColor'));
            root.style.setProperty('--hedCartColor', getComputedStyle(root).getPropertyValue('--darkHedCartColor'));
            root.style.setProperty('--btnColor', getComputedStyle(root).getPropertyValue('--darkBtnColor'));
            root.style.setProperty('--btnColorBg', getComputedStyle(root).getPropertyValue('--darkBtnColorBg'));
            root.style.setProperty('--btnColorHover', getComputedStyle(root).getPropertyValue('--darkBtnColorHover'));
            root.style.setProperty('--btnColorBgHover', getComputedStyle(root).getPropertyValue('--darkBtnColorBgHover'));
            root.style.setProperty('--btnColorActive', getComputedStyle(root).getPropertyValue('--darkBtnColorActive'));
            root.style.setProperty('--btnColorDisabled', getComputedStyle(root).getPropertyValue('--darkBtnColorDisabled'));
            root.style.setProperty('--btnColorBgActive', getComputedStyle(root).getPropertyValue('--darkBtnColorBgActive'));
            root.style.setProperty('--btnPadding', getComputedStyle(root).getPropertyValue('--darkBtnPadding'));
            root.style.setProperty('--btnBorder', getComputedStyle(root).getPropertyValue('--darkBtnBorder'));
            root.style.setProperty('--btnRadius', getComputedStyle(root).getPropertyValue('--darkBtnRadius'));
            root.style.setProperty('--btnIconColor', getComputedStyle(root).getPropertyValue('--darkBtnIconColor'));
            root.style.setProperty('--btnIconColorBg', getComputedStyle(root).getPropertyValue('--darkBtnIconColorBg'));
            root.style.setProperty('--btnLinkColor', getComputedStyle(root).getPropertyValue('--darkBtnLinkColor'));
            root.style.setProperty('--catColor', getComputedStyle(root).getPropertyValue('--darkCatColor'));
            root.style.setProperty('--catColorBg', getComputedStyle(root).getPropertyValue('--darkCatColorBg'));
            root.style.setProperty('--catColorHover', getComputedStyle(root).getPropertyValue('--darkCatColorHover'));
            root.style.setProperty('--catColorActive', getComputedStyle(root).getPropertyValue('--darkCatColorActive'));
            root.style.setProperty('--ordBgImage', getComputedStyle(root).getPropertyValue('--darkOrdBgImage'));
            root.style.setProperty('--ordBgColor', getComputedStyle(root).getPropertyValue('--darkOrdBgColor'));
            root.style.setProperty('--itmBorder', getComputedStyle(root).getPropertyValue('--darkItmBorder'));
            root.style.setProperty('--itmColorBG', getComputedStyle(root).getPropertyValue('--darkItmColorBG'));
            root.style.setProperty('--diBorder', getComputedStyle(root).getPropertyValue('--darkDiBorder'));
            root.style.setProperty('--diColorBg', getComputedStyle(root).getPropertyValue('--darkDiColorBg'));
            root.style.setProperty('--shadow', getComputedStyle(root).getPropertyValue('--darkShadow'));
            root.style.setProperty('--backgroundImgUrl', getComputedStyle(root).getPropertyValue('--darkBackgroundImgUrl'));
            root.style.setProperty('--colorPlaceholder', getComputedStyle(root).getPropertyValue('--darkPlaceHolder'));
            root.style.setProperty('--colorPlaceholderOnly', getComputedStyle(root).getPropertyValue('--darkColorPlaceholderOnly'));
            root.style.setProperty('--colorDatalistHover', getComputedStyle(root).getPropertyValue('--darkColorDatalistHover'));
            root.style.setProperty('--colorOrderDetailAltRow', getComputedStyle(root).getPropertyValue('--darkColorOrderDetailAltRow'));
            root.style.setProperty('--colorOrderDetailHeader', getComputedStyle(root).getPropertyValue('--darkColorOrderDetailHeader'));
            root.style.setProperty('--colorOrderDetailHeaderText', getComputedStyle(root).getPropertyValue('--darkColorOrderDetailHeaderText'));
        } else {
            root.style.setProperty('--colorText', temp.Theme.colorText);
            root.style.setProperty('--colorInputBg', temp.Theme.colorInputBg);
            root.style.setProperty('--colorFocusBg', temp.Theme.colorFocusBg);
            root.style.setProperty('--colorFocus', temp.Theme.colorFocus);
            root.style.setProperty('--colorBg', temp.Theme.colorBg);
            root.style.setProperty('--colorBgCard', temp.Theme.colorBgCard);
            root.style.setProperty('--colorBgRow', temp.Theme.colorBgRow);
            root.style.setProperty('--colorTheme', temp.Theme.colorTheme);
            root.style.setProperty('--colorThemeBg', temp.Theme.colorThemeBg);
            root.style.setProperty('--colorThemeSecondary', temp.Theme.colorThemeSecondary);
            root.style.setProperty('--colorHover', temp.Theme.colorHover);
            root.style.setProperty('--colorActive', temp.Theme.colorActive);
            root.style.setProperty('--colorBorder', temp.Theme.colorBorder);
            root.style.setProperty('--colorWarning', temp.Theme.colorWarning);
            root.style.setProperty('--colorDefMod', temp.Theme.colorDefMod);
            root.style.setProperty('--colorAddMod', temp.Theme.colorAddMod);
            root.style.setProperty('--colorAdjustMod', temp.Theme.colorAdjustMod);
            root.style.setProperty('--colorDisabled', temp.Theme.colorDisabled);
            root.style.setProperty('--hedBgColor', temp.Theme.hedBgColor);
            root.style.setProperty('--hedCartColor', temp.Theme.hedCartColor);
            root.style.setProperty('--btnColor', temp.Theme.btnColor);
            root.style.setProperty('--btnColorBg', temp.Theme.btnColorBg);
            root.style.setProperty('--btnColorHover', temp.Theme.btnColorHover);
            root.style.setProperty('--btnColorBgHover', temp.Theme.btnColorBgHover);
            root.style.setProperty('--btnColorActive', temp.Theme.btnColorActive);
            root.style.setProperty('--btnColorDisabled', temp.Theme.btnColorDisabled);
            root.style.setProperty('--btnColorBgActive', temp.Theme.btnColorBgActive);
            root.style.setProperty('--btnPadding', temp.Theme.btnPadding);
            root.style.setProperty('--btnBorder', temp.Theme.btnBorder);
            root.style.setProperty('--btnRadius', temp.Theme.btnRadius);
            root.style.setProperty('--btnIconColor', temp.Theme.btnIconColor);
            root.style.setProperty('--btnIconColorBg', temp.Theme.btnIconColorBg);
            root.style.setProperty('--btnLinkColor', temp.Theme.btnLinkColor);
            root.style.setProperty('--catColor', temp.Theme.catColor);
            root.style.setProperty('--catColorBg', temp.Theme.catColorBg);
            root.style.setProperty('--catColorHover', temp.Theme.catColorHover);
            root.style.setProperty('--catColorActive', temp.Theme.catColorActive);
            root.style.setProperty('--ordBgImage', temp.Theme.ordBgImage);
            root.style.setProperty('--ordBgColor', temp.Theme.ordBgColor);
            root.style.setProperty('--itmBorder', temp.Theme.itmBorder);
            root.style.setProperty('--itmColorBG', temp.Theme.itmColorBG);
            root.style.setProperty('--diBorder', temp.Theme.diBorder);
            root.style.setProperty('--diColorBg', temp.Theme.diColorBg);
            root.style.setProperty('--shadow', temp.Theme.shadow);
            root.style.setProperty('--backgroundImgUrl', temp.Theme.backgroundImgUrl);
            root.style.setProperty('--colorPlaceholder', temp.Theme.placeHolder);
            root.style.setProperty('--colorPlaceholderOnly', temp.Theme.colorPlaceholderOnly);
            root.style.setProperty('--colorDatalistHover', temp.Theme.colorDatalistHover);
            root.style.setProperty('--colorOrderDetailAltRow', temp.Theme.colorOrderDetailAltRow);
            root.style.setProperty('--colorOrderDetailHeader', temp.Theme.colorOrderDetailHeader);
            root.style.setProperty('--colorOrderDetailHeaderText', temp.Theme.colorOrderDetailHeaderText);
        }
    }

    public static setDarkMode(user: User, localaOLO: IaOLO) {
        const darkModeEnabled = localStorage.getItem('darkMode') === 'true';
        if (darkModeEnabled)
            localaOLO.Temp.DarkMode = darkModeEnabled;

        if (localaOLO.Temp.DarkMode) {
            localaOLO.Temp.DarkMode = !localaOLO.Temp.DarkMode;
            this.toggleDarkMode(localaOLO.Temp, user);
        }
    }

    public static GetTaxDefinitionById(taxId: number, taxes: IDataTax[]): IDataTax | undefined {
        return taxes.find(t => t.TaxId == taxId);
    }

    public static GetNewName(names: INewName[], isoCode: string): string {
        let languageCode = isoCode;
        switch (languageCode) {
            case "en-us":
                languageCode = "en-US";
                break;
            case "sp-mx":
                languageCode = "es-MX";
                break;
        }
        let result = names ? (names.find(n => n.lc === languageCode)?.text || "") : "";
        // Remove once culture codes are standardized. Fallback to old langCode.
        if (!result) {
            result = names ? (names.find(n => n.lc === isoCode)?.text || "") : "";
        }
        return result;
    }

    /**
    * Empties an HTML element by removing all its child nodes.
    * @param {string} elementID - The ID of the HTML element to be emptied.
    * @returns {(HTMLElement|null)} - The HTML element that was emptied or null if no element was found with the specified ID.
    */
    public static GUI_Empty(elementID: string): HTMLElement | null {
        const ele = document.getElementById(elementID);
        if (ele) {
            const children = ele.childNodes;
            for (let i = children.length - 1; i > -1; i--) {
                children[i].remove();
            }
        }
        return ele;
    }

    /**
    * Reloads the user profile.
    * If aOLO.ProfileUser does not exist, it creates a new instance of ProfileUser class.
    * It then calls the getUser method from the ProfileService class to retrieve user data and updates aOLO.User.
    * After that, it calls the setHamburgerMenuItems function to update the hamburger menu.
    * @async
    * @returns {Promise<void>}
    */
    public static async profileReload(user: User, localAolo: IaOLO): Promise<void> {
        let getUserResult;
        let getUserResultJwt;
        let getUserResultRefreshJwt;
        // Sign the user in if we SSO token
        if (localAolo.singleSignOnToken && aOLOModules.LoyaltyProvider.hasSingleSignOn()) {
            const result = await aOLOModules.LoyaltyProvider.signInViaSSOAsync();
            getUserResult = result?.user;
            getUserResultJwt = result?.jwt;
            getUserResultRefreshJwt = result?.refreshToken;
        }
        else {
            const result = await aOLOModules.LoyaltyProvider.getUserAsync();
            getUserResult = result?.user
            getUserResultJwt = result?.jwt;
        }

        if (!getUserResult)
            return;

        user.user = getUserResult;
        await user.signIn(getUserResultJwt, getUserResultRefreshJwt);
        await aOLOModules.LoyaltyProvider.getActiveDiscountBasket();
        Common.setHamburgerMenuItems(user, localAolo);

        if (user.getProperty("IsDarkMode") !== localAolo.Temp.DarkMode)
            this.toggleDarkMode(localAolo.Temp, user);

        if (user.getProperty("CultureCode") !== localAolo.Temp.languageCode)
            setPageLanguage(user.getProperty("CultureCode") || "en-us");
    }

   public static initiateUser(user: User): void {
        if (user.isLoggedIn() && user.getProperty("IsProfileComplete")) {
            user.updateUser({ GU: LoggedInStatus.LOGGEDIN });
        } else {
            user.updateUser({
                CustomerId: 0,
                ProfileId: 0,
                GU: LoggedInStatus.ANONYMOUS,
                //ALG: false,
                Phone: "",
                LoyaltyData: null
            });
        }
    }

    /**
    * Closes the hamburger menu by unchecking the "inp_header_menu_checkbox" input element.
    * @returns {void}
    */
    public static closeHamburgerMenu(): void {
        const mnuButton = document.getElementById("inp_header_menu_checkbox") as HTMLInputElement;
        if (mnuButton)
            mnuButton.checked = false;
    }

    /**
    * Returns the name based on the culture code and name array provided.
    * It loops through the names array and returns the name that matches the current language code.
    * @param {Array<{CULT: string, NAM: string}>} names - An array of objects containing the culture code and name.
    * @returns {string} - The name in the current language code.
    */
    public static GetName(names: IName[], fallbackLanguageCode: string, languageCode?: string): string {
        if (!languageCode)
            languageCode = fallbackLanguageCode;
        return names ? (names.find(n => n.CULT === languageCode)?.NAM || "") : "";
    }

    /**
     * Determines if the current browser is Safari on an iOS device.
     * @returns {boolean} True if the browser is Safari on iOS, false otherwise.
     */
    public static isSafariOniOS(): boolean {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
            /iPad|iPhone|iPod/.test(navigator.userAgent);
    }

    /**
     * Determines if current location is Bahamas
     * @returns {boolean} True if the current location is Bahamas, false otherwise.
     */
    public static isBahamas(longitude: number, latitude: number): boolean {
        return latitude == BahamasCoordinates.LAT && longitude == BahamasCoordinates.LONG;
    }

    public static async loadAndMergeTranslations(url: string): Promise<void> {
        try {
            const myInit = Common.GetInit();
            const myRequest = new Request(url);
            const response = await fetch(myRequest, myInit);
            if (response.status != 200)
                return;

            const externalTranslations = await response.json();

            mergeTranslations(externalTranslations);
        } catch (error) {
            console.log(error);
        }
    }
}