import './menu.css';

import { EventEmitter } from 'eventemitter3';
import { EventTypes } from '../../../core/event-bus';
import { Util } from '../../../utils/util';
import { IaOLO } from '../../../interfaces/aolo.interface';
import { OrderType } from '../../../types/enums';
import { Names } from '../../../utils/i18n';
import { OnlineOrderingUtil } from '../../../online-ordering/online-ordering-util';
import { Common } from '../../../common';
import { IMealDealData, IMenuItemsCategoriesSubcategory, IMenuItemsCategory } from './menu.interface';
import { User } from '../../../models/user';
import { Data } from '../../../models/data';
import { Order } from '../../../models/order';
import { OrderTypeAddressNavBar } from '../../shared/OrderTypeAddressNavBar/order-type-address-nav-bar';
import { MenuItemCard } from '../../shared/MenuItemCard/menu-item-card';
import { MealDealItemCard } from '../../shared/MealDealItemCard/meal-deal-item-card';
import { TabBar } from '../../shared/TabBar/tab-bar';
import { ITabBarData } from '../../shared/TabBar/tab-bar.interface';
import { IDataItem } from '../../../interfaces/data.interface';

export class Menu {
    private readonly _eventBus: EventEmitter;
    private readonly _user: User;
    private readonly _data: Data;
    private readonly _order: Order;
    private readonly _item: any;
    private readonly _aOLO: IaOLO;

    constructor(eventBus: EventEmitter, data: Data, user: User, order: Order, aOLO: IaOLO) {
        this._eventBus = eventBus;
        this._user = user;
        this._data = data;
        this._order = order;
        this._aOLO = aOLO;
        this._item = this._initialModel();
        this._eventBusListeners();
    }

    private readonly _initialModel = (): any => {
        return {
            storeKey: "",
            orderTypeId: OrderType.TAKE_OUT,
            categoryId: -1
        };
    }

    public initAsync = async (): Promise<void> => {
        Util.hideElement("div_menu_main_section");
        Util.showElement("div_menu_placeholder");
        this._renderPlaceholders();
        this._showPage();
    }

    private readonly _showPage = (): void => {
        Util.showComponent("menu");
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.ORDER_TYPE_CHANGED, this._orderTypeChanged);
        this._eventBus.on(EventTypes.DATA_RETRIEVED, this._dataRetrievedAsync);
    }

    private readonly _orderTypeChanged = (orderTypeId: OrderType): void => {
        this._item.orderTypeId = orderTypeId;
    }

    private readonly _dataRetrievedAsync = async (): Promise<void> => {
        await this._renderPageAsync();
        this._setEventListeners();
    }

    private readonly _renderPageAsync = async (): Promise<void> => {
        // Build and Select Menu tab
        const mealDealCategories = this._createMealDealsArrayObject();
        const tabData = this._buildMenuBar(mealDealCategories);

        const categoryItems = this._createCategoriesAndItemsObject();
        this._buildItemCategoriesSelector(categoryItems);

        const mainItemsDiv = document.getElementById("div_menu_items_parent");
        if (mainItemsDiv) {
            mainItemsDiv.innerHTML = "";
            for (const tab of tabData.tabs) {
                const section = this._buildItemsSection(tab.id);
                if (section)
                    mainItemsDiv.appendChild(section);
            }
        }

        Util.hideElement("div_menu_placeholder");
        Util.showElement("div_menu_main_section");

        this._menuTabOnClick(this._item.categoryId);

        // If default category is a menu category
        const categoryIds = tabData.tabs.map(x => x.id);
        if (!categoryIds.includes(this._item.categoryId))
            this._categoryButtonOnClick(this._item.categoryId);

        this._syncCategoriesOnScroll();

        // Create Order Type/Address Nav Bar
        this._buildNavBar();
    }

    private readonly _setEventListeners = (): void => {
        const buttons = document.getElementsByName("btn_menu_items_category");
        for (const button of buttons) {
            const id = Number(button.dataset.id);
            button.onclick = () => {
                this._categoryButtonOnClick(id);
            };
        }

        document.body.addEventListener("scroll", () => {
            const div = document.getElementById("div_menu_categories_bar");
            if (!div) return;

            // Get the scroll position, checking both documentElement and body
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

            // Add 'scrolled' class if scrolled down by 20 pixels or more
            if (scrollTop > 10) {
                div.classList.add("scrolled");
            } else {
                div.classList.remove("scrolled");
            }
        });
    }

    private readonly _renderPlaceholders = (): void => {
        const div = document.getElementById("div_menu_placeholder");
        if (!div)
            return;

        let htmlTabs = "";
        for (let i = 0; i < 7; i++) {
            htmlTabs += ` 
                <div class="placeholder placeholder-menu-tabs">
                    <div class="placeholder-option"></div>
                </div>`;
        }

        let htmlParentItems = "";
        for (let i = 0; i < 2; i++) {
            let htmlItems = "";
            for (let j = 0; j < 8; j++) {
                htmlItems +=
                    `<div class="placeholder placeholder-menu-card">
                        <div class="placeholder-option"></div>
                    </div>`;
            }

            htmlParentItems += ` 
                <div class="placeholder placeholder-menu-category-header">
                    <div class="placeholder-option"></div>
                </div>
                <div class="menu-item-card-parent">${htmlItems}</div>`;
        }

        const html = `
            <div class="categories-bar">${htmlTabs}</div>

            <div>
                <div class="menu-parent-wrapper">${htmlParentItems}</div>
            </div>`;

        div.innerHTML = html;
    }

    private readonly _buildNavBar = (): void => {
        let address = "";
        if (this._order.order.OrderTypeID === OrderType.DELIVERY) {
            const deliveryAddress = this._order.order.Address;
            if (deliveryAddress) {
                address = Util.formatAddressObject({
                    StreetNo: deliveryAddress.StreetNo,
                    Address1: deliveryAddress.StreetName,
                    Address2: deliveryAddress.Address2,
                    Address3: deliveryAddress.Address3,
                    Address4: deliveryAddress.Address4,
                    Address5: deliveryAddress.Address5,
                    City: deliveryAddress.City,
                    State: deliveryAddress.State,
                    ZipCode: deliveryAddress.Zip,
                    CountryID: deliveryAddress.CountryID || 1,
                    AddressTypeID: 0
                }, this._aOLO.data.Countries).address1;
            }
        } else {
            const store = this._data.getProperty("StoreInfo");
            if (store) {
                address = Util.formatAddressObject({
                    StreetNo: store.streetNumber,
                    Address1: store.address1,
                    Address2: store.address2,
                    Address3: store.address3,
                    Address4: store.address4,
                    Address5: store.address5,
                    City: store.city,
                    State: store.state,
                    ZipCode: store.zip,
                    CountryID: store.countryId || 1,
                    AddressTypeID: 0
                }, this._aOLO.data.Countries).address1;
            }
        }
        const navbar = new OrderTypeAddressNavBar(this._eventBus, this._data, this._aOLO.PublicStorageUrl, this._order.order.OrderTypeID, address, this._aOLO);
        navbar.render("div_order_type_address_nav_bar");
        Util.showElement("div_order_type_address_nav_bar");
        Util.setElementClass("add", "footer", "nav-bar");
    }

    private readonly _buildMenuBar = (mealDealCategories: IMealDealData[]): ITabBarData => {
        const tabsDiv = document.getElementById("div_menu_menu_tabs");
        if (!tabsDiv)
            return { buttonId: "", tabs: [] };

        tabsDiv.innerHTML = "";

        const self = this;
        const tabData: ITabBarData = {
            buttonId: "btn_menu_menu_tab",
            tabs: [{ id: -1, text: Names("Menu"), ltag: "Menu", function: () => self._showItemsOfCategory(-1) }]
        };

        for (const category of mealDealCategories) {
            if (category.coupons.length == 0)
                continue;

            const name = Common.GetName(category.names, this._aOLO.Temp.languageCode);
            const buttonData = { id: category.id, text: name, ltagj: Util.toLtagj(category.names), function: () => self._showItemsOfCategory(category.id) };
            tabData.tabs.push(buttonData);
        }

        if (this._user.isLoggedIn()) {
            const buttonData = { id: -2, text: Names("RecentlyOrdered"), ltag: "RecentlyOrdered", function: () => self._showItemsOfCategory(-2) };
            tabData.tabs.push(buttonData);
        }

        const tabBar = new TabBar(tabData);
        tabsDiv.appendChild(tabBar.element);

        return tabData;
    }

    private readonly _menuTabOnClick = (categoryId: number): void => {
        const tabs = document.querySelectorAll(".menu-tab");
        for (const tab of tabs) {
            tab.classList.remove("active");
        }

        const tab = document.getElementById(`btn_menu_menu_tab_${categoryId}`);
        if (tab) {
            tab.classList.add("active");
            tab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        } else {
            const defaultTab = document.getElementById("btn_menu_menu_tab_-1");
            if (defaultTab) {
                defaultTab.classList.add("active");
                defaultTab.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
            }
        }

        // Show Items
        this._showItemsOfCategory(categoryId);
    }

    private readonly _categoryButtonOnClick = (categoryId: number): void => {
        this._clearSelectedCategories();

        this._categoryButtonScrollIntoView(categoryId);

        const anchor = document.getElementById(`div_menu_items_item_category_${categoryId}`);
        if (anchor) {
            const categoriesBar = document.getElementById("div_menu_categories_bar");
            const offset = anchor.offsetTop - (categoriesBar?.getBoundingClientRect().bottom || 0) - 20;
            document.body.scrollTo({ top: offset, behavior: 'smooth' });
        }

        if (this._aOLO.Modules.DataLayer)
            this._aOLO.Modules.DataLayer.itemScrollListeners();
    }

    private readonly _categoryButtonScrollIntoView = (categoryId: number): void => {
        const button = document.getElementById(`btn_menu_items_category_${categoryId}`);
        if (button) {
            button.classList.add("active");
            button.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
        }
    }

    private readonly _clearSelectedCategories = (): void => {
        const buttons = document.querySelectorAll(".category-button");
        for (const button of buttons) {
            button.classList.remove("active");
        }
    }

    private readonly _showItemsOfCategory = (categoryId: number): void => {
        const divs = document.getElementsByName("div_menu_items");
        for (const div of divs) {
            Util.hideElement(div);
        }

        const mealDealDiv = document.getElementById(`div_menu_items_${categoryId}`);
        if (mealDealDiv) {
            Util.hideElement(`div_menu_categories_bar`);
            Util.showElement(`div_menu_items_${categoryId}`);
            document.body.removeEventListener("scroll", this._syncCategoriesOnScroll);
        } else if (categoryId == -2) {
            Util.hideElement(`div_menu_categories_bar`);
            Util.showElement(`div_menu_items_recently_ordered`);
            document.body.removeEventListener("scroll", this._syncCategoriesOnScroll);
        } else {
            Util.showElement(`div_menu_categories_bar`);
            Util.showElement(`div_menu_items_main`);
            document.body.addEventListener("scroll", this._syncCategoriesOnScroll);
        }
    }

    private readonly _buildItemsSection = (categoryId: number): HTMLElement | null => {
        switch (categoryId) {
            case -2:
                return this._buildRecentlyOrderedItemsSection();
            case -1:
                return this._buildMainItemsSection();
            default:
                return this._buildHotDealsItemsSection(categoryId);
        }
    }

    /**
     * Generates an array of menu item categories and their respective items and subcategories.
     * 
     * @returns {IMenuItemsCategory[]} An array of menu item categories.
     */
    private readonly _createCategoriesAndItemsObject = (): IMenuItemsCategory[] => {
        const categories = this._data.getProperty("Categories")?.filter(x => !x.IsMealDeal) || [];
        const menuCategories: IMenuItemsCategory[] = [];

        for (const category of categories) {
            if (category.Default)
                this._item.categoryId = category.ItemWebCategoryId;

            const categoryItem: IMenuItemsCategory = { id: category.ItemWebCategoryId, names: category.Names, items: [], subcategories: [] };
            const categoryItems = this._data.getProperty("Items")?.filter(x => x.WebCategoryId === category.ItemWebCategoryId) || [];

            this._processCategoryItems(categoryItems, categoryItem);

            menuCategories.push(categoryItem);
        }

        return menuCategories;
    }

    /**
     * Processes items within a category, assigning them to items or subcategories.
     * 
     * @param {any[]} categoryItems - The items in the category.
     * @param {IMenuItemsCategory} categoryItem - The category object to populate.
     */
    private _processCategoryItems(categoryItems: any[], categoryItem: IMenuItemsCategory): void {
        let lastSubCategoryId = 0;

        for (const item of categoryItems) {
            // 2DO: Call Center
            //if (this._data.getProperty("Settings")?.ISCC && storeItems !== "") {
            //    const itemId = `*${mItem.ItemId}*`;
            //    if (storeItems.indexOf(itemId) === -1)
            //        continue;
            //}

            // Skip items with no valid sizes or QR restrictions
            if (!this._isItemValid(item))
                return;

            if (this._isNewSubCategory(item.SubCategoryId, lastSubCategoryId)) {
                lastSubCategoryId = item.SubCategoryId;
                this._addSubCategory(item.SubCategoryId, categoryItem);
            }

            if (item.SubCategoryId === 0) {
                categoryItem.items.push(item);
            } else {
                const subcategory = categoryItem.subcategories.find(subCat => subCat.id === item.SubCategoryId);
                subcategory?.items.push(item);
            }
        }
    }

    /**
     * Determines if an item is valid for inclusion.
     * 
     * @param {any} item - The item to validate.
     * @returns {boolean} Whether the item is valid.
     */
    private _isItemValid(item: IDataItem): boolean {
        let itemSizes = OnlineOrderingUtil.GetItemSizes(item, this._item.orderTypeId);

        if (this._aOLO.QrCode) {
            itemSizes = itemSizes.filter(size => size.IsOfferedQR);
        }

        return itemSizes.length > 0;
    }

    /**
     * Determines if a new subcategory needs to be created.
     * 
     * @param {number} subCategoryId - The current subcategory ID.
     * @param {number} lastSubCategoryId - The ID of the last processed subcategory.
     * @returns {boolean} Whether a new subcategory is needed.
     */
    private _isNewSubCategory(subCategoryId: number, lastSubCategoryId: number): boolean {
        return subCategoryId !== 0 && subCategoryId !== lastSubCategoryId;
    }

    /**
     * Adds a subcategory to the category item.
     * 
     * @param {number} subCategoryId - The ID of the subcategory.
     * @param {IMenuItemsCategory} categoryItem - The category object to populate.
     */
    private _addSubCategory(subCategoryId: number, categoryItem: IMenuItemsCategory): void {
        const subcategory = this._data.getProperty("SubCategories")?.find(subCat => subCat.ItemSubCategoryId === subCategoryId);

        if (subcategory) {
            categoryItem.subcategories.push({
                id: subcategory.ItemSubCategoryId,
                names: subcategory.Names,
                items: []
            });
        }
    }

    private readonly _createMealDealsArrayObject = (): IMealDealData[] => {
        const array: IMealDealData[] = [];
        const mealDealCategories = this._data.getProperty("Categories")?.filter(x => x.IsMealDeal) || [];
        for (const category of mealDealCategories) {
            if (category.Default)
                this._item.categoryId = category.ItemWebCategoryId;

            const mealDealCategory: IMealDealData = { id: category.ItemWebCategoryId, names: category.Names, default: category.Default, coupons: [] };

            const couponsData = this._data.getProperty("Coupons") || [];
            if (couponsData.length > 0) {
                const coupons = couponsData.filter(x => !x.IsHiddenOnline
                    && x.WebCategoryId === category.ItemWebCategoryId
                    && this._aOLO.Modules.Coupon.CheckCouponOTST(x, this._order.order.OrderTypeID)
                    && this._aOLO.Modules.Coupon.CheckCouponSCHS(x)
                    && this._aOLO.Modules.Coupon.VerifyCouponDate(x)
                    && (!x.IsAppOnly || (x.IsAppOnly && Util.isAppView()))
                    && (!x.IsProfileRequired || (x.IsProfileRequired && this._user.isLoggedIn())));
                mealDealCategory.coupons = coupons;
            }

            array.push(mealDealCategory);
        }
        return array;
    }

    private readonly _buildMainItemsSection = (): HTMLElement => {
        const categoryItems = this._createCategoriesAndItemsObject();
        const itemsSection = this._buildItemsSections(categoryItems);
        return itemsSection;
    }

    private readonly _buildItemCategoriesSelector = (categoryItems: IMenuItemsCategory[]): void => {
        const categoriesBarDiv = document.getElementById("div_menu_categories_bar");
        if (!categoriesBarDiv)
            return;

        let categoryButtonsHtml = "";
        for (const category of categoryItems) {
            const name = Common.GetName(category.names, this._aOLO.Temp.languageCode);
            categoryButtonsHtml += `<button id="btn_menu_items_category_${category.id}" class="category-button" name="btn_menu_items_category" data-id="${category.id}" role="tab" aria-selected="false" tabindex="-1" ltagj="${Util.toLtagj(category.names)}">${name}</button>`;
        }

        categoriesBarDiv.innerHTML = categoryButtonsHtml;
    }

    /**
     * Builds a section of menu items categorized by categories and subcategories.
     *
     * @param {IMenuItemsCategory[]} categoryItems - The list of menu item categories.
     * @returns {HTMLElement} The parent HTML element containing the built menu sections.
     */
    private readonly _buildItemsSections = (categoryItems: IMenuItemsCategory[]): HTMLElement => {
        const parentDiv = this._createParentDiv();

        for (const category of categoryItems) {
            const categoryHeader = this._createCategoryHeader(category);
            parentDiv.appendChild(categoryHeader);

            const categoryItemsSection = this._createItemsSection(category.items);
            parentDiv.appendChild(categoryItemsSection);

            for (const subcategory of category.subcategories) {
                this._appendSubcategorySection(parentDiv, category, subcategory);
            }
        }

        return parentDiv;
    }

    /**
     * Creates the parent div container for the menu items.
     *
     * @returns {HTMLElement} The parent div element.
     */
    private _createParentDiv = (): HTMLElement => {
        const parentDiv = document.createElement("div");
        parentDiv.id = "div_menu_items_main";
        parentDiv.setAttribute("name", "div_menu_items");
        parentDiv.classList.add("menu-parent-wrapper");
        return parentDiv;
    }

    /**
     * Creates and appends a subcategory section, including its items, to the parent container.
     *
     * @param {HTMLElement} parentDiv - The parent div to append sections to.
     * @param {IMenuItemsCategory} category - The parent category data.
     * @param {IMenuItemsCategory} subcategory - The subcategory data.
     */
    private _appendSubcategorySection(parentDiv: HTMLElement, category: IMenuItemsCategory, subcategory: IMenuItemsCategoriesSubcategory): void {
        const subcategoryHeader = this._createCategoryHeader(category, subcategory);
        const subcategoryItemsSection = this._createItemsSection(subcategory.items);

        parentDiv.appendChild(subcategoryHeader);
        parentDiv.appendChild(subcategoryItemsSection);
    }

    /**
     * Creates a section for items within a category or subcategory.
     *
     * @param {any[]} items - The items to be included in the section.
     * @returns {HTMLElement} The HTML element containing item cards.
     */
    private _createItemsSection = (items: IDataItem[]): HTMLElement => {
        const itemsSection = document.createElement("div");
        itemsSection.classList.add("menu-item-card-parent");

        for (const item of items) {
            const itemCard = new MenuItemCard(item, this._aOLO.Temp.languageCode);
            itemsSection.appendChild(itemCard.element);
        }

        return itemsSection;
    }

    private readonly _createCategoryHeader = (category: IMenuItemsCategory, subcategory?: IMenuItemsCategoriesSubcategory): HTMLElement => {
        let html = "";
        if (subcategory) {
            const name = Common.GetName(subcategory.names, this._aOLO.Temp.languageCode);
            html = `
                <div id="div_menu_items_item_category_${subcategory.id}" class="category-header" data-id="${subcategory.id}" data-cid="${category.id}">
                    <div ltagj="${Util.toLtagj(subcategory.names)}">${name}</div>
                    <button id="btn_menu_items_item_category_viewall_${subcategory.id}" class="button-link">${Names("ViewAll")}</button>
                </div>`;
        } else {
            const name = Common.GetName(category.names, this._aOLO.Temp.languageCode);
            html = `
                <div id="div_menu_items_item_category_${category.id}" class="category-header" data-id="${category.id}" data-cid="${category.id}">
                    <div ltagj="${Util.toLtagj(category.names)}">${name}</div>
                    <button id="btn_menu_items_item_category_viewall_${category.id}" class="button-link">${Names("ViewAll")}</button>
                </div>`;
        }

        return Util.createHtmlElementFromTemplate(html);
    }

    private readonly _syncCategoriesOnScroll = (): void => {
        const categories = Array.from(document.querySelectorAll(".category-header")) as HTMLDivElement[];
        const windowHeight = window.innerHeight;

        let activeCategoryId = 0;
        for (const category of categories) {
            if (OnlineOrderingUtil.getElementTop(category, "") > windowHeight * 0.50)
                break;

            activeCategoryId = Number(category.dataset.cid);
        }

        if (activeCategoryId === 0)
            return;

        this._clearSelectedCategories();

        this._categoryButtonScrollIntoView(activeCategoryId);
    }

    private readonly _buildHotDealsItemsSection = (categoryId: number): HTMLElement | null => {
        const couponsData = this._data.getProperty("Coupons") || [];
        if (couponsData.length === 0)
            return null;

        const coupons = couponsData.filter(x => !x.IsHiddenOnline
            && x.WebCategoryId === categoryId
            && this._aOLO.Modules.Coupon.CheckCouponOTST(x, this._order.order.OrderTypeID)
            && this._aOLO.Modules.Coupon.CheckCouponSCHS(x)
            && this._aOLO.Modules.Coupon.VerifyCouponDate(x)
            && (!x.IsAppOnly || (x.IsAppOnly && Util.isAppView()))
            && (!x.IsProfileRequired || (x.IsProfileRequired && this._user.isLoggedIn())));

        if (coupons.length == 0)
            return null;

        const parentDiv = document.createElement("div");
        parentDiv.id = `div_menu_items_${categoryId}`;
        parentDiv.setAttribute("name", "div_menu_items");
        parentDiv.classList.add("meal-deal-items-parent");

        for (const coupon of coupons) {
            const card = new MealDealItemCard(this._aOLO.Modules.Coupon, coupon, this._aOLO.Temp.languageCode);
            parentDiv.appendChild(card.element);
        }

        return parentDiv;
    }

    private readonly _buildRecentlyOrderedItemsSection = (): HTMLElement => {
        //const userOrders = this._user.getProperty("")
        // 2DO: Call server to get orders paginated

        const div = document.createElement("div");
        div.id = "div_menu_items_recently_ordered";
        div.setAttribute("name", "div_menu_items");
        return div;
    }
}