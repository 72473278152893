import { IFinishedItem, IItemsByCategory, IMealDealFunctions } from "./interfaces/meal-deal.interface";
import { IName } from "../interfaces/global.interfaces";
import { IaOLO } from "../interfaces/aolo.interface";
import { IDataCoupon, IDataItem } from "../interfaces/data.interface";
import { IOrderItem } from "../interfaces/order.interface";
import { Names } from "../utils/i18n";
import { Common } from "../common";
import { Util } from "../utils/util";
import { CustomizeItem } from "./customize-item";
import { OnlineOrderingUtil } from "./online-ordering-util";
import { CouponTypeList } from "../types/enums";
import '../../css/online-ordering/meal-deal.css';
import { IApplyCouponByIdParams } from "./interfaces/coupons.interface";

export class MealDeal {
    private _functions: IMealDealFunctions;
    private _coupon: IDataCoupon;
    private _couponCode: string | null;
    private _isCampaignCode: boolean;
    private _isPromotionCode: boolean;
    private _orderID: number | null;
    private _rewardId: number | null;
    private _finishedGroups: number = 0;
    private _groups: number = 0;
    private _selectedGroup: number = -1;
    private _finishedItems: IFinishedItem[] = [];
    private _items: number[] = [];
    private _delete: boolean = false;

    constructor(functions: IMealDealFunctions, coupon: IDataCoupon, couponCode: string | null, orderID: number | null, rewardId: number | null, isCampaignCode?: boolean, isPromotionCode?: boolean) {
        this._functions = functions;
        this._coupon = coupon;
        this._couponCode = couponCode;
        this._isCampaignCode = isCampaignCode || false;
        this._isPromotionCode = isPromotionCode || false;
        this._orderID = orderID;
        this._rewardId = rewardId;
        this._createScreen();
    }

    public GetCouponId = (): number => {
        return this._coupon.CouponId;
    }

    public GetCoupon = (): IDataCoupon => {
        return this._coupon;
    }

    public GetCouponCode = (): string | null => {
        return this._couponCode;
    }

    public GetIsCampaignCode = (): boolean => {
        return this._isCampaignCode;
    }

    public GetIsPromotionCode = (): boolean => {
        return this._isPromotionCode;
    }

    public GetOrderId = (): number | null => {
        return this._orderID;
    }

    public GetRewardId = (): number | null => {
        return this._rewardId;
    }

    public GetDelete = (): boolean => {
        return this._delete;
    }

    public GetSelectedGroup = (): number => {
        return (this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PRICE || this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PERCENTAGE) ? 1 : this._selectedGroup;
    }

    private _createScreen = async (): Promise<void> => {
        const qualifyingItems = aOLO.Modules.Coupon.QualifyCouponItems(this._coupon, false, true, true, true);
        const qualifyingKeys = (!qualifyingItems) ? [] : qualifyingItems.map(y => y.ItemKey);
        const items = aOLO.Order.Items.filter(x => qualifyingKeys.includes(x.ItemKey));
        await this._checkSingleGroupSingleItemNonCustomize(items);
    }

    public AddToOrder = async (itemKey: number | null): Promise<boolean> => {
        if (this._finishedItems) {
            let existingItem = this._finishedItems.find(x => x.Group == this._selectedGroup);
            if (existingItem && existingItem.ItemKey != null) {
                this._functions.DeleteItem(existingItem.ItemKey);
                existingItem.ItemKey = itemKey;
            } else {
                this._finishedItems.push({ Group: this._selectedGroup, ItemKey: itemKey });
                this._finishedGroups++;
            }
        } else {
            this._finishedItems = [{ Group: this._selectedGroup, ItemKey: itemKey }];
            this._finishedGroups++;
        }

        var self = this;
        if (this._finishedGroups < this._groups || this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PRICE || this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PERCENTAGE) {
            if (this._finishedGroups >= this._groups) {
                this._createSingleHeaderMixMatch();
                Util.showElement("btn_mealdeal_finish_coupon");
            }

            const groupNumber = this._selectedGroup;
            Util.setElementClass("add", `a_DISCSEL_ItemSelect_${this._selectedGroup}`, "dsFinished");
            Util.setElementClass("remove", `a_DISCSEL_ItemSelect_${this._selectedGroup}`, "dsSelected");
            const itemSelectA = document.getElementById(`a_DISCSEL_ItemSelect_${this._selectedGroup}`);
            if (itemSelectA)
                itemSelectA.onclick = function () {
                    self._rechoseItem(groupNumber);
                };
            Util.setElementClass("add", `a_DISCSEL_ItemSelect_Mobile_${this._selectedGroup}`, "dsFinished");
            Util.setElementClass("remove", `a_DISCSEL_ItemSelect_Mobile_${this._selectedGroup}`, "dsSelected");

            const items = aOLO.Order.Items.filter(x => x.ItemKey == itemKey);
            const parentDiv = document.getElementById(`div_DISCSEL_ItemSelect_${this._selectedGroup}_Name`);
            if (parentDiv) {
                if (items.length > 1) {
                    const item1 = aOLO.data.Items.find(x => x.ItemId == items[0].ItemId);
                    const size1 = (items[0].SizeId == 0) ? `` : `${Common.GetName(aOLO.data.Sizes.find(x => x.SizeId == items[0].SizeId)?.Names || [], aOLO.Temp.languageCode)} - `;
                    const item2 = aOLO.data.Items.find(x => x.ItemId == items[1].ItemId);
                    const size2 = (items[1].SizeId == 0) ? `` : `${ Common.GetName(aOLO.data.Sizes.find(x => x.SizeId == items[1].SizeId)?.Names || [], aOLO.Temp.languageCode)} - `;
                    parentDiv.classList.add("mdHalf");
                    parentDiv.innerHTML = `
                        <div>${Names(`1stHalf`)}: ${size1}${ Common.GetName(item1?.Names || [], aOLO.Temp.languageCode)}</div>
                        <div>${Names(`2ndHalf`)}: ${size2}${ Common.GetName(item2?.Names || [], aOLO.Temp.languageCode)}</div>`;
                } else {
                    const currentItem = items[0];
                    if (currentItem) {
                        const item = aOLO.data.Items.find(x => x.ItemId == items[0].ItemId);
                        const size = (items[0].SizeId == 0) ? `` : `${Common.GetName(aOLO.data.Sizes.find(x => x.SizeId == items[0].SizeId)?.Names || [], aOLO.Temp.languageCode)} - `;
                        parentDiv.innerHTML = `<div>${size}${Common.GetName(item?.Names || [], aOLO.Temp.languageCode)}</div>`;
                    }
                }
            }

            while ((this._selectedGroup <= this._groups || this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PRICE || this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PERCENTAGE) &&
                document.getElementById(`a_DISCSEL_ItemSelect_${this._selectedGroup}`)?.classList.contains("dsFinished")) {
                this._selectedGroup++;
            }

            const tempA = document.getElementById(`a_DISCSEL_ItemSelect_${this._selectedGroup}`);
            if (tempA) {
                tempA.classList.add("dsSelected");
                tempA.scrollIntoView();
            }

            const tempMobileA = document.getElementById(`a_DISCSEL_ItemSelect_Mobile_${this._selectedGroup}`);
            if (tempMobileA) {
                tempMobileA.classList.add("dsSelected");
                tempMobileA.scrollIntoView();
            }

            this._setGroupItems();
            for (let i = 0; i < this._items.length; i++) {
                const item = document.getElementById(`btn_DISCSEL_MenuItem_Final_${this._items[i]}`);
                if (item)
                    item.onclick = async () => { await this._selectItem(i); };
            }
        } else {
            await this.CloseScreen(true);
            return true;
        }

        return false;
    }

    private _checkSingleGroupSingleItemNonCustomize = async (qualifiedItems: IOrderItem[]): Promise<void> => {
        if (this._coupon.Items && this._coupon.Items.length == 1) {
            const couponItem = this._coupon.Items[0];
            const mItem = aOLO.data.Items.find(x => x.ItemId == couponItem.ItemId);
            if (!mItem)
                return;

            const sizes = this._getCouponItemSizesByGroup(this._coupon.CouponId, couponItem.ItemId, couponItem.GroupIndex);
            const mods = OnlineOrderingUtil.Create_Modifier_DropDown(mItem, aOLO);

            let mustCustomize = OnlineOrderingUtil.MustCustomize(mItem, aOLO.data.ModifierDisplayGroups, aOLO.itemStyleID);
            let canCustomize = OnlineOrderingUtil.CanCustomize(mItem, aOLO.data.ModifierDisplayGroups);

            if (mItem && !(mustCustomize || canCustomize || mods.DropdownIds || sizes.length > 1)) {
                await this._functions.Item_AddToOrder(mItem.Index, sizes[0], 1, mods.DropdownIds);
                if (this._couponCode)
                    await aOLO.Modules.Coupon.ApplyCoupon(this._coupon, this._couponCode, true, true, undefined, undefined, this._isCampaignCode, this._isPromotionCode);
                else if (this._orderID)
                    await aOLO.Modules.Coupon.ApplyCoupon(this._coupon, `bx${this._orderID}`, true, true, undefined, undefined, this._isCampaignCode, this._isPromotionCode);
                else {
                    const params: IApplyCouponByIdParams = {
                        couponId: this._coupon.CouponId,
                        showApplyMsg: true,
                        isCampaignCode: this._isCampaignCode,
                        isPromotionCode: this._isPromotionCode,
                        rewardId: this._rewardId
                    };
                    await aOLO.Modules.Coupon.ApplyCouponByID(params);
                }
                this._delete = true;
            } else
                this._createForm(qualifiedItems, aOLO);
        } else
            this._createForm(qualifiedItems, aOLO);
    }

    private _getCouponItemSizesByGroup = (couponId: number, itemId: number, groupNumber: number): number[] => {
        let coupon = aOLO.Modules.Coupon.GetCoupon(couponId, true, aOLO.Order.OrderTypeID, null);

        if (coupon?.Items) {
            let items = coupon.Items.filter(x => x.GroupIndex == groupNumber && x.ItemId == itemId);
            return (items.length > 0) ? items.map(x => x.SizeId) : [];
        }
        return [];
    }

    private _createForm = (qualifiedItems: IOrderItem[], localAolo: IaOLO): void => {
        this._finishedGroups = 0;

        Util.setElement("innerText", "div_mealdeal_coupon_name", Common.GetName(this._coupon.Names, localAolo.Temp.languageCode));
        Util.setElement("innerText", "div_mealdeal_coupon_description", Common.GetDescription(this._coupon.Descriptions, localAolo.Temp.languageCode));

        const closeBtn = document.getElementById("btn_mealdeal_close");
        if (closeBtn)
            closeBtn.onclick = async () => {
                await this.CloseScreen(false);
                if (this.GetDelete())
                    delete localAolo.Dialog.MealDeal;
            };

        const removeCouponBtn = document.getElementById("btn_mealdeal_remove_coupon");
        if (removeCouponBtn)
            removeCouponBtn.onclick = async () => { await this.CloseScreen(true); };

        const finishCouponBtn = document.getElementById("btn_mealdeal_finish_coupon");
        if (finishCouponBtn) {
            finishCouponBtn.onclick = function () { OnlineOrderingUtil.MealDealAddToOrder(null, aOLO, true); };
            Util.hideElement(finishCouponBtn);
        }

        if (!localAolo.Temp.IncompleteDiscounts.some(cpn => cpn.couponID === this._coupon.CouponId))
            localAolo.Temp.IncompleteDiscounts.push({ couponID: this._coupon.CouponId, couponCode: '', channelID: null, rewardId: this._rewardId });

        this._createGroupButton(qualifiedItems);
        this._setGroupItems();

        Util.DialogFadeIn("dia_mealdeal", localAolo, "btn_mealdeal_remove_coupon");

        const mobileViewAllBtn = document.getElementById("btn_mealdeal_item_select_mobile_view_all_items");
        if (mobileViewAllBtn)
            mobileViewAllBtn.onclick = () => { this._mobileViewAll(localAolo); };

        for (let i = 0; i < this._items.length; i++) {
            const item = document.getElementById(`btn_DISCSEL_MenuItem_Final_${this._items[i]}`);
            if (item)
                item.onclick = async () => { await this._selectItem(i); };
        }
    }

    public CloseScreen = async (deleteCoupon: boolean): Promise<void> => {
        if (deleteCoupon)
            await aOLO.Modules.Coupon.RemoveIncompleteCouponById(this._coupon.CouponId)
        OnlineOrderingUtil.GUI_SetOrder_Total(true, aOLO, aOLOModules);
        Util.DialogFadeOut("dia_mealdeal");
        this._delete = true;
    }

    private _createGroupButton = (qualifiedItems: IOrderItem[]): void => {
        let groups = this._coupon.Items ? Array.from(new Set<(number | undefined)>(this._coupon.Items.map(cpn => cpn.GroupIndex))).filter(Boolean) as number[] : [];
        if (this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PRICE || this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PERCENTAGE) {
            groups = [];
            if (this._coupon.MinimumItemQuantity) {
                for (let i = 1; i <= this._coupon.MinimumItemQuantity; i++)
                    groups.push(1);
            }
        }
        this._groups = groups.length;

        let selectionHTML = "";
        let selectionMobileHTML = "";
        let groupsSelected = [];
        let mixAndMatchTimes = 0;

        for (let i = 0; i < groups.length; i++) {
            let items = qualifiedItems;
            let item = null;
            let itemKey: number | null = null;
            if (items) {
                let cpnItms = this._coupon.Items.filter(x => x.GroupIndex == groups[i]) || [];
                cpnItms = cpnItms.filter(x => items.map(y => y.ItemId).includes(x.ItemId));
                if (cpnItms.length > 0) {
                    items = items.filter(x => cpnItms.map(y => y.ItemId).includes(x.ItemId))
                    const cpnItm = cpnItms.find(x => items.find(y => y.ItemId == x.ItemId && y.SizeId == x.SizeId));
                    if (cpnItm) {
                        itemKey = items.find(x => x.ItemId == cpnItm.ItemId && x.SizeId == cpnItm.SizeId)?.ItemKey || null;
                        item = aOLO.data.Items.find(x => x.ItemId == cpnItm.ItemId);
                        qualifiedItems = qualifiedItems.filter(x => x.ItemKey != itemKey);
                    }
                }
            }

            if (item) {
                mixAndMatchTimes++;
                if (this._finishedItems)
                    this._finishedItems.push({ Group: i + 1, ItemKey: itemKey });
                else
                    this._finishedItems = [{ Group: i + 1, ItemKey: itemKey }];
                this._finishedGroups++;

                groupsSelected.push(i + 1);
                let itemsInOrder = aOLO.Order.Items.filter(x => x.ItemKey == itemKey);
                let isHalfHalf = itemsInOrder.length > 1;
                let item2 = null;
                if (isHalfHalf) {
                    if (itemsInOrder[1].ItemId == item.ItemId)
                        item2 = aOLO.data.Items.find(x => x.ItemId == itemsInOrder[0].ItemId);
                    else
                        item2 = aOLO.data.Items.find(x => x.ItemId == itemsInOrder[1].ItemId);
                }

                let name = "";
                if (!isHalfHalf) {
                    let size = (itemsInOrder[0].SizeId == 0) ? `` : `${Common.GetName(aOLO.data.Sizes.find(x => x.SizeId == itemsInOrder[0].SizeId)?.Names || [], aOLO.Temp.languageCode)} - `;
                    name = `<div>${size}${Common.GetName(item.Names, aOLO.Temp.languageCode)}</div>`;
                } else {
                    let size1 = (itemsInOrder[0].SizeId == 0) ? `` : `${Common.GetName(aOLO.data.Sizes.find(x => x.SizeId == itemsInOrder[0].SizeId)?.Names || [], aOLO.Temp.languageCode)} - `;
                    let size2 = (itemsInOrder[1].SizeId == 0) ? `` : `${Common.GetName(aOLO.data.Sizes.find(x => x.SizeId == itemsInOrder[1].SizeId)?.Names || [], aOLO.Temp.languageCode)} - `;
                    name = `<div>${Names(`1stHalf`)}: ${size1}${Common.GetName(item.Names, aOLO.Temp.languageCode)}</div><div>${Names(`2ndHalf`)}: ${size2}${Common.GetName(item2?.Names || [], aOLO.Temp.languageCode)}</div>`;
                }

                selectionHTML += `
                <a id="a_DISCSEL_ItemSelect_${i + 1}" class="dsFinished">
                    <div id="div_DISCSEL_ItemSelect_${i + 1}_Name" class="${isHalfHalf ? `mdHalf` : ``}">${name}</div>
                    <div></div>
                </a>`;

                selectionMobileHTML += `
                <div id="a_DISCSEL_ItemSelect_Mobile_${i + 1}" class="dsFinished">
                    <div></div>
                </div>`;
            }
        }

        let firstGroup = -1;
        let headerNumber = groupsSelected.length;
        for (let i = 0; i < groups.length; i++) {
            if (!groupsSelected.includes(groups[i]) || ((this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PRICE || this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PERCENTAGE) && ((mixAndMatchTimes == 0 && headerNumber < (this._coupon.MinimumItemQuantity || 0)) || (mixAndMatchTimes > 0 && i + 1 > mixAndMatchTimes)))) {
                selectionHTML += `
                <a id="a_DISCSEL_ItemSelect_${i + 1}" class="${(firstGroup == -1) ? `dsSelected` : ``}">
                    <div id="div_DISCSEL_ItemSelect_${i + 1}_Name"><div>${Names(`SelectItem`)} ${headerNumber + 1}</div></div>
                    <div></div>
                </a>`;

                selectionMobileHTML += `
                <div id="a_DISCSEL_ItemSelect_Mobile_${i + 1}" class="${(firstGroup == -1) ? `dsSelected` : ``}"">
                    <div></div>
                </div>`;
                headerNumber++;
                if (firstGroup == -1) {
                    firstGroup = (this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PRICE || this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PERCENTAGE) ? mixAndMatchTimes + 1 : groups[i];
                    this._selectedGroup = firstGroup;
                }
            }
        }

        Util.setElement("innerHTML", "div_mealdeal_item_select", selectionHTML);
        Util.setElement("innerHTML", "div_mealdeal_item_select_mobile_items", selectionMobileHTML);

        const self = this;
        for (const group of groupsSelected) {
            let itemSel = document.getElementById(`a_DISCSEL_ItemSelect_${group}`);
            if (itemSel)
                itemSel.onclick = function () {
                    self._rechoseItem(group);
                };
        }
    }

    private _createSingleHeaderMixMatch = (): void => {
        let mealDealDivContent = "";
        const mealDealDiv = document.getElementById("div_mealdeal_item_select");
        if (mealDealDiv)
            mealDealDivContent = mealDealDiv.innerHTML;

        const selectionHTML = `
            <a id="a_DISCSEL_ItemSelect_${this._finishedGroups + 1}" class="dsSelected">
                <div id="div_DISCSEL_ItemSelect_${this._finishedGroups + 1}_Name"><div>${Names(`SelectItem`)} ${this._finishedGroups + 1}</div></div>
                <div></div>
            </a>`;

        const selectionMobileHTML = `
            <div id="a_DISCSEL_ItemSelect_Mobile_${this._finishedGroups + 1}" class="dsSelected">
                <div></div>
            </div>`;

        Util.setElement("innerHTML", "div_mealdeal_item_select", mealDealDivContent + selectionHTML);
        Util.setElement("innerHTML", "div_mealdeal_item_select_mobile_items", mealDealDivContent + selectionMobileHTML);

        var self = this;
        const itemSel = document.getElementById(`a_DISCSEL_ItemSelect_${this._finishedGroups + 1}`);
        const currGroup = this._finishedGroups + 1;
        if (itemSel)
            itemSel.onclick = function () {
                self._rechoseItem(currGroup);
            };
    }

    private _setGroupItems = (): void => {
        this._items = [];
        let parentDiv = document.getElementById("div_mealdeal_items");
        if (!parentDiv)
            return;

        parentDiv.innerHTML = "";
        let group = (this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PRICE || this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PERCENTAGE) ? 1 : this._selectedGroup;
        let filteredItems = this._coupon.Items.filter(x => x.GroupIndex == group).map(x => x.ItemId) || [];
        let items = aOLO.data.Items.filter(x => filteredItems.includes(x.ItemId));

        let itemsByCategory: IItemsByCategory[] = [];
        let lastCategoryID = -1;
        for (const item of items) {
            if (item.WebCategoryId != lastCategoryID) {
                lastCategoryID = item.WebCategoryId;
                let category = aOLO.data.Categories.find(x => x.ItemWebCategoryId == lastCategoryID);
                if (category)
                    itemsByCategory.push({ WebCategoryId: lastCategoryID, Names: category.Names, Items: [] });
            }
            itemsByCategory.find(x => x.WebCategoryId == lastCategoryID)?.Items.push(item);
        }

        const selectedItem = this._finishedItems ? this._finishedItems.find(x => x.Group == this._selectedGroup) : null;

        for (const cat of itemsByCategory) {
            if (!parentDiv)
                return;

            parentDiv.innerHTML += this._categoryHeader(cat.Names, cat.WebCategoryId);

            const oItem = selectedItem ? aOLO.Order.Items.find(x => selectedItem && x.ItemKey == selectedItem.ItemKey) : null;

            if (oItem && oItem.ItemWebCategoryId == cat.WebCategoryId) {
                const oItemTmp = oItem;
                const item = items.find(x => x.ItemId == oItemTmp.ItemId);
                if (!item)
                    continue;

                parentDiv.innerHTML += this._itemStyle(item);
                this._items.push(oItemTmp.ItemId);

                cat.Items.filter(x => x.ItemId != oItemTmp.ItemId).forEach(item => {
                    if (parentDiv) {
                        parentDiv.innerHTML += this._itemStyle(item);
                        this._items.push(item.ItemId);
                    }
                });
            } else {
                cat.Items.forEach(item => {
                    if (parentDiv) {
                        parentDiv.innerHTML += this._itemStyle(item);
                        this._items.push(item.ItemId);
                    }
                });
            }
        }
    }

    private _categoryHeader = (catSubCatName: IName[], catID: number): string => {
        const catHTML = `
        <div class="categoryHeader">
            <h1 id="h1_DISCSEL_CatAnchor_${catID}" data-catid="${catID}" class="categoryHeaderText">${Common.GetName(catSubCatName, aOLO.Temp.languageCode)}</h1>
        </div>`;
        return catHTML;
    }

    private _itemStyle = (mItem: IDataItem): string => {
        const group = (this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PRICE || this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PERCENTAGE) ? 1 : this._selectedGroup;
        const name = Common.GetName(mItem.Names, aOLO.Temp.languageCode);
        const sizes = OnlineOrderingUtil.GetItemSizes(mItem, aOLO.Order.OrderTypeID).map(x => x.SizeId);
        const couponSizes = this._coupon.Items?.filter(x => x.GroupIndex == group && x.ItemId == mItem.ItemId && sizes.includes(x.SizeId)) || [];

        let itemHTML = '';
        if (couponSizes.length > 0)
            itemHTML = `
            <div id="div_DISCSEL_MenuItem_${mItem.ItemId}" class="hMenuItem hMenuItemContent" tab-index="1">
                <button id="btn_DISCSEL_MenuItem_Final_${mItem.ItemId}" dataset-iid="${mItem.ItemId}" aria-label="${name}">
                    <div class="p1 width100">
                        <span class="menuItemName">${name}</span>
                        <p class="hMenuItemDescription">${Common.GetDescription(mItem.Descriptions, aOLO.Temp.languageCode)}</p>
                    </div>
                    <div class="hMenuItemImgRight" style="${(mItem.ImageURL !== ``) ? `background-image: url(${mItem.ImageURL});` : ``}">
                    </div>
                </button>
            </div>`;

        return itemHTML;
    }

    private _selectItem = async (index: number): Promise<void> => {
        const itemId = this._items[index];
        const mItem = aOLO.data.Items.find(x => x.ItemId == itemId);
        const group = (this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PRICE || this._coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PERCENTAGE) ? 1 : this._selectedGroup;
        const sizes = this._getCouponItemSizesByGroup(this._coupon.CouponId, itemId, group);

        if (!mItem)
            return;

        const mods = OnlineOrderingUtil.Create_Modifier_DropDown(mItem, aOLO);
        const mustCustomize = OnlineOrderingUtil.MustCustomize(mItem, aOLO.data.ModifierDisplayGroups, aOLO.itemStyleID);
        const canCustomize = OnlineOrderingUtil.CanCustomize(mItem, aOLO.data.ModifierDisplayGroups);

        if (mustCustomize || canCustomize || mods.DropdownIds || sizes.length > 1) {
            const defSize = OnlineOrderingUtil.GetItemDefaultSize(mItem, aOLO.Order.OrderTypeID, null, aOLO.data.Settings.DSZID);
            const couponQuantity = this._coupon.Items.find(x => x.GroupIndex == group && x.ItemId == itemId && x.SizeId == defSize.SizeId)?.Quantity || 1;
            aOLO.Dialog.CustomizeItem = new CustomizeItem(aOLO, mItem.Index, defSize.SizeId, couponQuantity);
        } else {
            const couponQuantity = this._coupon.Items.find(x => x.GroupIndex == group && x.ItemId == itemId && x.SizeId == sizes[0])?.Quantity || 1;
            await this._functions.Item_AddToOrder(mItem.Index, sizes[0], couponQuantity, mods.DropdownIds);
        }
    }

    private _rechoseItem = (groupNumber: number): void => {
        Util.setElementClass("remove", `a_DISCSEL_ItemSelect_${this._selectedGroup}`, "dsSelected");
        Util.setElementClass("remove", `a_DISCSEL_ItemSelect_Mobile_${this._selectedGroup}`, "dsSelected");
        this._selectedGroup = groupNumber;
        this._setGroupItems();
        Util.setElementClass("add", `a_DISCSEL_ItemSelect_${this._selectedGroup}`, "dsSelected");
        Util.setElementClass("add", `a_DISCSEL_ItemSelect_Mobile_${this._selectedGroup}`, "dsSelected");
        const existingItem = this._finishedItems.find(x => x.Group == this._selectedGroup);
        const itemId = aOLO.Order.Items.find(x => existingItem && x.ItemKey == existingItem.ItemKey)?.ItemId || -1;
        const menuDiv = document.getElementById(`div_DISCSEL_MenuItem_${itemId}`);
        if (menuDiv) {
            menuDiv.querySelector('button')?.classList.add("mealDealSelectedItem");
            menuDiv.scrollIntoView();
        }

        const itemSel = document.getElementById(`a_DISCSEL_ItemSelect_${this._selectedGroup}`);
        if (itemSel)
            itemSel.scrollIntoView();

        for (let i = 0; i < this._items.length; i++) {
            let item = document.getElementById(`btn_DISCSEL_MenuItem_Final_${this._items[i]}`);
            if (item)
                item.onclick = async () => { await this._selectItem(i); };
        }
    }

    private _mobileViewAll = (localAolo: IaOLO): void => {
        const mobileView = document.getElementById("btn_mealdeal_mobile_view_close");
        if (mobileView)
            mobileView.onclick = function () { Util.DialogFadeOut("dia_mealdeal_mobile_view_all"); };
        this._mobileCreateViewAllDialog();
        Util.DialogFadeIn("dia_mealdeal_mobile_view_all", localAolo);
    }

    private _mobileCreateViewAllDialog = (): void => {
        const groups = this._coupon.Items ? Array.from(new Set<(number | undefined)>(this._coupon.Items.map(cpn => cpn.GroupIndex))).filter(Boolean) as number[] : [];

        let mobileViewAllHTML = "";
        const groupsSelected = [];

        if (this._finishedItems) {
            for (let i = 0; i < groups.length; i++) {
                const itemKey = this._finishedItems.find(x => x.Group == groups[i])?.ItemKey;

                if (itemKey) {
                    const tmpItem = aOLO.Order.Items.find(x => x.ItemKey == itemKey);
                    const item = aOLO.data.Items.find(x => tmpItem && x.ItemId == tmpItem.ItemId);
                    groupsSelected.push(i + 1);
                    const itemsInOrder = aOLO.Order.Items.filter(x => x.ItemKey == itemKey);
                    const isHalfHalf = itemsInOrder.length > 1;
                    const item2 = (!isHalfHalf) ? null : aOLO.data.Items.find(x => x.ItemId == itemsInOrder[1].ItemId);
                    const name = (!isHalfHalf) ? Common.GetName(item?.Names || [], aOLO.Temp.languageCode) : `<div>${Names(`1stHalf`)}: ${Common.GetName(item?.Names || [], aOLO.Temp.languageCode)}</div><div>${Names(`2ndHalf`)}: ${Common.GetName(item2?.Names || [], aOLO.Temp.languageCode)}</div>`;

                    mobileViewAllHTML += `
                        <div>
                            <span>${name}</span>
                            <button id="btn_DISCSEL_Mobile_Change_${i + 1}">${Names(`ToChange`)}</button>
                        </div>`;
                }
            }
        } else
            mobileViewAllHTML = `<span>${Names("NoMenuItemsHaveBeenSelected")}<span>`;

        Util.setElement("innerHTML", "div_mealdeal_mobile_view_items", mobileViewAllHTML);

        const self = this;
        for (const group of groupsSelected) {
            const mobileChange = document.getElementById(`btn_DISCSEL_Mobile_Change_${group}`);
            if (mobileChange)
                mobileChange.onclick = function () {
                    Util.DialogFadeOut("dia_mealdeal_mobile_view_all");
                    self._rechoseItem(group);
                };
        }
    }
}