import './order-type-address-nav-bar.css';

import { EventEmitter } from 'eventemitter3';
import { EventTypes } from '../../../core/event-bus';
import { Common } from '../../../common';
import { IaOLO } from '../../../interfaces/aolo.interface';
import { Data } from '../../../models/data';
import { Util } from '../../../utils/util';
import { OrderType, Pages } from '../../../types/enums';

export class OrderTypeAddressNavBar {
    private container: HTMLElement | null = null;
    private readonly _eventBus: EventEmitter;
    private readonly _data: Data;
    private readonly _contentUrl: string;
    private _orderTypeId: OrderType;
    private _address: string;
    private readonly _aOLO: IaOLO;

    constructor(eventBus: EventEmitter, data: Data, contentUrl: string, orderTypeId: OrderType, address: string, aOLO: IaOLO) {
        this._eventBus = eventBus;
        this._data = data;
        this._contentUrl = contentUrl;
        this._orderTypeId = orderTypeId;
        this._address = address;
        this._aOLO = aOLO;
        this._eventBusListeners();
    }

    private readonly _eventBusListeners = () => {
        this._eventBus.on(EventTypes.ORDER_TYPE_CHANGED, this._orderTypeChanged);
    }

    public render(parentElement: string | HTMLElement): void {
        if (this.container) {
            this._addContainerToParent(parentElement);
            return;
        }

        const orderTypeName = this._getOrderTypeName();
        const pinUrl = `${this._contentUrl}/online3/svg/location_pinpoint.svg`;

        const html = `
            <div class="select-store-nav-bar">
                <div class="select-store-nav-bar-order-type">
                    <div>
                        <img src="${pinUrl}">
                    </div>
                    <span ltagj="${orderTypeName.ltagj}">${orderTypeName.name}</span>
                </div>
                <div class="select-store-nav-bar-address">
                    <span>${this._address}</span>
                    <div>
                        <span class="icon-arrow-back"></span>
                    </div>
                </div>
            </div>`;

        const element = Util.createHtmlElementFromTemplate(html);
        element.onclick = this._onClick;
        this.container = element;

        this._addContainerToParent(parentElement);
    }

    private _getOrderTypeName(): { name: string, ltagj: string } {
        const orderType = this._data.getProperty("OrderTypeSubTypes")?.find(x => x.OrderTypeId === this._orderTypeId);
        if (!orderType)
            return { name: "", ltagj: "" };

        const orderTypeName = Common.GetName(orderType.Names, this._aOLO.Temp.languageCode);
        return { name: orderTypeName, ltagj: Util.toLtagj(orderType.Names) };
    }

    private readonly _onClick = (): void => {
        this._eventBus.emit(EventTypes.LOCATION_CHANGE, Pages.LOCATIONS);
    } 

    private _addContainerToParent(parentElement: string | HTMLElement): void {
        if (!this.container)
            return;

        const elementToModify = (typeof parentElement === "string" ? document.getElementById(parentElement) : parentElement);
        if (elementToModify) {
            elementToModify.innerHTML = "";
            elementToModify.appendChild(this.container);
        }
    }

    //public show(parent: HTMLElement): void {
    //    if (!this.container)
    //        this.render();
        
    //    if (!parent.contains(this.container!))
    //        parent.appendChild(this.container!);
    //}

    //public hide(): void {
    //    if (this.container?.parentElement) {
    //        this.container.parentElement.removeChild(this.container);
    //    }
    //}

    public update(orderTypeId: OrderType, address: string): void {
        if (!this.container)
            return;

        if (this._orderTypeId !== orderTypeId) {
            this._orderTypeChanged(orderTypeId);
        }

        if (this._address !== address) {
            this._address = address;
            const addressText = this.container.querySelector('.select-store-nav-bar-address span:first-child');
            if (addressText)
                addressText.textContent = this._address;
        }
    }

    private _orderTypeChanged(orderTypeId: OrderType): void {
        if (!this.container)
            return;

        this._orderTypeId = orderTypeId;
        const orderTypeText = this.container.querySelector('.select-store-nav-bar-order-type span');
        if (orderTypeText) {
            const orderTypeName = this._getOrderTypeName();
            orderTypeText.textContent = orderTypeName.name;
            orderTypeText.setAttribute("ltagj", orderTypeName.ltagj);
        }
    }
}