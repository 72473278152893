import { DialogCreators } from "../utils/dialog-creators";
import { AdoraHttpClient } from "../utils/http-client";
import { Names } from "../utils/i18n";
import { IaOLO } from "../interfaces/aolo.interface";
import { Util } from "../utils/util";
import { ICurbsideHereModel, ICurbsideHereResponse, IGetOfferUrlModel } from "./interfaces/brand-service.interfaces";

export class BrandService {
    _apiVersion: string = '1.0';
    _apiBrandUrl: string = `${location.origin}/api/v${this._apiVersion}/brand/`;

    /**
    * Makes an HTTP request.
    * @private
    * @async
    * @param {string} method - The HTTP method (e.g. 'GET', 'POST', 'PUT', 'DELETE').
    * @param {string} url - The URL of the HTTP resource.
    * @param {object|null} payload - The payload to send with the request.
    * @param {string|null} token - The authorization token to send with the request.
    * @returns {Promise<Response>} A promise that resolves to the HTTP response.
    */
    _httpRequest = async (method: string, url: string, localAolo: IaOLO, payload: object | null, token: string | null = null, showProgress: boolean = true, noCache: boolean = true): Promise<Response> => {
        const response = await AdoraHttpClient.httpRequest(showProgress, method, url, payload, token, null, noCache);

        if (response.status === 500) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            DialogCreators.messageBoxOk(Names("UnexpectedError500"), localAolo.buttonHoverStyle, null, null, result.errorId);
        }

        return response;
    }

    curbsideHere = async (data: ICurbsideHereModel, localAOLO: IaOLO): Promise<ICurbsideHereResponse> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiBrandUrl}curbside-here`, localAOLO, payload, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { success: false };
    }

    getOfferUrl = async (data: IGetOfferUrlModel, localAOLO: IaOLO): Promise<{ url: string }> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAOLO.pkey);
        const response = await this._httpRequest('POST', `${this._apiBrandUrl}get-offer-url`, localAOLO, payload, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : { url: "" };
    }
}