import { Common } from "../common";
import { IStoreLocationZone, IStoreLocationZonePolygon } from "../components/pages/Locations/locations.interface";
import { DialogCreators } from "../utils/dialog-creators";
import { AddressType, Curbside, ItemStyle, LoggedInStatus, CouponTypeList, OrderType, MerchantProcessor } from "../types/enums";
import { Names, getSupportedLanguages } from "../utils/i18n";
import { IaOLO, IaOLOModules } from "../interfaces/aolo.interface";
import {
    IDataDeliveryZone, IDataHoliday, IDataItem, IDataLoyaltyItem, IDataModifierDisplayGroup, IDataOrderTypeSubType, IDataPreModifier, IDataServiceCharge,
    IDataStore, IDataTax, IDataZipTax, IDeliveryZonePolygon, IItemModifierDisplayGroupModifier, IItemOrderTypeSize, IItemPrice, IMenuItemPrice, IMenuItemTax
} from "../interfaces/data.interface";
import { IName } from "../interfaces/global.interfaces";
import { IOrder, IOrderCoupon, IOrderItem, IOrderItemCoupon, IOrderItemModifier, IOrderItemTax, IOrderPayment, IOrderServiceCharge, IOrderServiceChargeDetail, IOrderTax } from "../interfaces/order.interface";
import { ITempAddress, ITempGiftCard, ITempIncompleteDiscount } from "../interfaces/temp.interface";
import { Util } from "../utils/util";
import { IApplyCouponByIdParams, IRemovedCoupon } from "./interfaces/coupons.interface";
import { IAddressZone, ICalorie, IComponentForm, ICreateMakeList, IMake, IModDropdown, IParseAddressGetCity, IParseAddressGetState, IParseAddressGetZip, IServiceChargeDetail, ITax, InOrder, InOrderAddress, InOrderChallenge, InOrderComment, InOrderCoupon, InOrderCustomer, InOrderCustomerProfile, InOrderDonation, InOrderItem, InOrderItemComment, InOrderItemInfo, InOrderItemModifier, InOrderItemTax, InOrderMakeList, InOrderServiceCharge } from "./interfaces/online-ordering.interface";
import { IAddressParsed, IDateStartEndTime } from "./interfaces/shared.interfaces";

export class OnlineOrderingUtil {
    public static getOrderType(orderTypeID: number, localAolo: IaOLO): IDataOrderTypeSubType | null {
        return localAolo.data.OrderTypeSubTypes.find(ot => ot.OrderTypeId === orderTypeID) || null;
    }

    public static GetOrderTypeName(orderTypeID: number, localAolo: IaOLO): string {
        const orderType = this.getOrderType(orderTypeID, localAolo);
        return orderType ? Common.GetName(orderType.Names, localAolo.Temp.languageCode) : "";
    }

    public static getWaitTimeByOT(orderType: IDataOrderTypeSubType | undefined, localAolo: IaOLO): number {
        if (!orderType)
            return 99;

        const tMinutes = Util.nowTodayMinute(localAolo.Temp.TimeOffset);
        const times = localAolo.times;
        let waitTime = orderType.WaitTime;

        if (times.SET == 3 && times.WTIM) {
            const savedWaitTime = times.WTIM.find(x => x.MIN == (tMinutes - tMinutes % 15));

            if (savedWaitTime) {
                if (orderType.OrderTypeId === OrderType.DELIVERY && savedWaitTime.DELMIN && savedWaitTime.DELMIN > 0)
                    waitTime = savedWaitTime.DELMIN;
                else if (orderType.OrderTypeId !== OrderType.DELIVERY && savedWaitTime.MAKEMIN && savedWaitTime.MAKEMIN > 0)
                    waitTime = savedWaitTime.MAKEMIN;
            }
        }
        return waitTime;
    }

    public static IsHeartland(creditCardIntegration: boolean, storeId: number, heartlandPublicKey: string): boolean {
        let pkey = null;
        if (creditCardIntegration) {
            const storeID = storeId;
            const store = OnlineOrderingUtil.getCallCenterStore(storeID, aOLO.data.Stores);
            pkey = store?.Heartland || "";
        } else {
            pkey = heartlandPublicKey;
        }
        if (pkey) return true;
        return false;
    }

    public static IsOpenPay(): boolean {
        return aOLO?.data?.MerchantPids?.some(x =>
            x.pid === MerchantProcessor.OpenPay && x.tType.toUpperCase().includes('E')
        ) ?? false;
    }

    public static IsO2G(): boolean {
        return aOLO?.data?.MerchantPids?.some(x =>
            x.pid === MerchantProcessor.O2GPay && x.tType.toUpperCase().includes('E')
        ) ?? false;
    }

    public static getNamByCulture(nameJson: string, culture: string): string {
        try {
            const names = JSON.parse(nameJson) as { NAM: string; CULT: string }[];

            const nameObject = names.find(item => item.CULT === culture);

            if (nameObject?.NAM) {
                const sanitizedNam = nameObject.NAM.replace(/[^a-zA-Z0-9 ]/g, "");
                return sanitizedNam;
            }
            return 'pizza';
        } catch (error) {
            return 'pizza';
        }
    }

    public static getCallCenterStore(storeID: number, stores: IDataStore[]): IDataStore | null {
        return stores.find(store => store.StoreId === storeID) || null;
    }

    public static GetItemSizes(mItem: IDataItem, orderTypeID: number): IItemOrderTypeSize[] {
        const orderType = mItem.OrderTypes.find(ot => ot.OrderTypeId === orderTypeID);
        return orderType ? orderType.Sizes : [];
    }

    public static calcOrderTotal(subTotal: number, discount: number, orderTypeCharge: number, taxes: number, tip: number, Amount: number, donations: number, taxInSubTotal: number): number {
        return Util.Float2(subTotal) - Util.Float2(discount) + Util.Float2(orderTypeCharge) + Util.Float2(taxes) + Util.Float2(tip) + Util.Float2(Amount) + Util.Float2(donations) - Util.Float2(taxInSubTotal);
    }

    public static StartOrdering(orderStartTime: Date | null, timeOffset: number): void {
        aOLO.Temp.OrderingStarted = true;
        Util.showElement("div_cart_order_type_time_other");

        if (!orderStartTime)
            orderStartTime = Util.NowStore(timeOffset);
    }

    public static CheckHolidays(date: Date, displayHolidayMsg: boolean, selectedCallCenterStoreID: number, localAolo: IaOLO): IDataHoliday | null {
        const holidayID = Util.IsHoliday(date, localAolo);
        let holiday = this.getHolidayByID(holidayID, selectedCallCenterStoreID, localAolo);
        if (!holiday)
            return null;

        if (holiday.HolidayId === 0)
            holiday = null;
        else if (holiday.HolidayStatusId === 1 && displayHolidayMsg)
            DialogCreators.messageBoxOk(`The store is closed on ${Names("Hol" + holiday.HolidayId)}.`, localAolo.buttonHoverStyle);
        else if (holiday.HolidayStatusId === 4 && displayHolidayMsg)
            DialogCreators.messageBoxOk(`The store does not accept online orders on ${Names("Hol" + holiday.HolidayId)}. Please call the store @${Util.formatPhoneNumber(localAolo.storeInfo.Phone, Names("PhoneFormat", localAolo.storeInfo.Country.DefaultCultureCode))} to place your order over the phone.`, localAolo.buttonHoverStyle);
        else if (holiday.HolidayStatusId === 5 && displayHolidayMsg)
            DialogCreators.messageBoxOk(`The store does not accept deferred online orders on ${Names("Hol" + holiday.HolidayId)}.`, localAolo.buttonHoverStyle);
        return holiday;
    }

    public static getHolidayByID(holidayID: number, selectedStoreID: number, localAolo: IaOLO): IDataHoliday | null {
        let holidays = localAolo.data.Holidays;
        if (localAolo.data.Settings.ISCC && selectedStoreID !== 0) {
            const store = this.getCallCenterStore(selectedStoreID, localAolo.data.Stores);
            if (store)
                holidays = store.Holidays;
        }
        if (holidayID > 0 && holidays)
            return holidays.find(holiday => holiday.HolidayId == holidayID) || null;
        return null;
    }

    public static getLocation(localAolo: IaOLO) {
        localAolo.location = {
            validated: false,
            latitude: 0,
            longitude: 0
        };
        const cookieLocation = Util.getUserLocationFromCookie();
        if (cookieLocation != null) {
            localAolo.location.validated = true;
            localAolo.location.latitude = cookieLocation.lat;
            localAolo.location.longitude = cookieLocation.lng;
        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                localAolo.location.validated = true;
                localAolo.location.latitude = position.coords.latitude;
                localAolo.location.longitude = position.coords.longitude;
                try {
                    document.cookie = `locationData={"lat":${localAolo.location.latitude},"lng":${localAolo.location.longitude}};max-age= 3600;path=/;domain=${window.location.hostname.split('.').reverse().splice(0, 2).reverse().join('.')}`
                } catch { }
            });
        }
    }

    public static CheckCustomerDistance(storeLat: number, storeLng: number, localAolo: IaOLO, YesCallBack: Function, NoCallBack: Function): void {
        if (localAolo.Temp.DialogOpen)
            return;

        let customerDistance = Util.Float2(Util.GetDistance(localAolo.location.latitude, localAolo.location.longitude, storeLat, storeLng, "M"));
        if (customerDistance > 5) {
            let messageName = "XtraMiles";
            if (localAolo.storeInfo.Country.UnitsOfMeasurement == "Metric") {
                customerDistance = Util.Float2(Util.convertMilesToKilometers(customerDistance));
                messageName = "XtraKilometers";
            }
            localAolo.Temp.DialogOpen = true;
            DialogCreators.messageBox(Names(messageName).replace("??", `<div class='warning bold inline'>${customerDistance}</div>`), localAolo.buttonHoverStyle, [
                { "text": Names("YesProceed"), "callBack": YesCallBack },
                { "text": Names("NoOtherStore"), "callBack": NoCallBack }]);
        } else
            YesCallBack();
    }

    public static ToggleOrderItems(hideOrder: boolean, localAolo: IaOLO): void {
        if (hideOrder) {
            localAolo.Temp.Mobile_Card = false;
            Util.setElementClass("add", "div_cart_container", "hideOrder");
            Util.setElementClass("remove", "div_items_order", "hideItems");
            Util.showElement("div_items_coupons")
        } else {
            localAolo.Temp.Mobile_Card = true;
            Util.setElementClass("remove", "div_cart_container", "hideOrder");
            Util.setElementClass("add", "div_items_order", "hideItems");
            Util.hideElement("div_items_coupons")
            Util.setElement("innerText", "div_categories_arrow", "MENU");
            const div = document.getElementById("div_cart_float");
            if (div)
                div.style.height = "auto";
            window.scrollTo(0, 0);
        }
    }

    public static GUI_ScrollTo(anchorTop: number, headerHeight: number): void {
        const currentTop = window.scrollY;
        if (currentTop !== anchorTop) {
            const h = window.innerHeight - headerHeight;
            const scrollOptionsJump = {
                left: 0,
                top: 0
            };

            if (currentTop < anchorTop) {
                if (anchorTop - currentTop > h) {
                    scrollOptionsJump.top = anchorTop - h;
                    window.scrollTo(scrollOptionsJump);
                }
            } else {
                if (currentTop - anchorTop > h) {
                    scrollOptionsJump.top = anchorTop + h;
                    window.scrollTo(scrollOptionsJump);
                }
            }

            const scrollOptionsSmooth = {
                left: 0,
                top: anchorTop,
                behavior: 'smooth' as 'smooth' | 'auto'
            };
            setTimeout(() => {
                window.scrollTo(scrollOptionsSmooth);
            }, 10);
        }
    }

    public static GetItem(itemID: number, items: IDataItem[]): IDataItem | null {
        for (const [index, item] of items.entries()) {
            if (item.ItemId === itemID) {
                item.Index = index;
                return item;
            }
        }
        return null;
    }

    public static GetItemPrice(mItem: IDataItem, oItem: IOrderItem, localAolo: IaOLO): IMenuItemPrice {
        const iPrice: IMenuItemPrice = OnlineOrderingUtil.GetItemMenuPrice(mItem, oItem, localAolo);
        if (!mItem.Taxes || mItem.Taxes.length === 0) {
            iPrice.Tax = 0;
            return iPrice;
        }

        return iPrice;
    }

    public static GetItemMenuPrice(mItem: IDataItem, oItem: IOrderItem, localAolo: IaOLO): IMenuItemPrice {
        let iPrice = mItem.Prices.find(x => x.PriceGroupId == localAolo.Order.OrderTypeSubType.PriceGroupId && x.SizeId == oItem.SizeId);
        if (!iPrice)
            iPrice = mItem.Prices.find(x => x.PriceGroupId == localAolo.data.DefaultPriceGroupId && x.SizeId == oItem.SizeId);
        if (!iPrice)
            iPrice = mItem.Prices.find(x => x.SizeId == oItem.SizeId);

        if (!iPrice)
            iPrice = {
                MinimumPrice: 0,
                MaximumPrice: 0,
                PriceGroupId: 0,
                PerModifierPrice: 0,
                Price: 0,
                SizeId: 0,
                TaxIncluded: false
            } as IItemPrice;

        let mpCount = 0.00;
        let byItSelfChargeable = 0;
        let addToPrice = 0.00;
        let addOnMaxPriceCount = 0;
        let noneChargeableDisplayGroups: string[] = localAolo.data.Settings.NCDG;
        for (const iMod of oItem.Modifiers) {
            const modDispGroup = mItem.ModifierDisplayGroups.find(x => x.ModifierDisplayGroupId == iMod.ModifierDisplayGroupId);
            if (!modDispGroup)
                continue;

            const mod = modDispGroup.Modifiers.find(x => x.ModifierId == iMod.ModifierId);
            if (!mod)
                continue;

            // Get pre-mod price multiplier, set to 1 is set as none chargeable display group
            const preMod = this.GetPreMod(iMod.PreModifierId, aOLO.data.PreModifiers);
            let pCount = 0;
            let preModPriceMultiplier = 1.00;

            if (preMod) {
                preModPriceMultiplier = Number(preMod.PriceCountMultiplier);
                if (!noneChargeableDisplayGroups)
                    noneChargeableDisplayGroups = [];

                if (noneChargeableDisplayGroups.indexOf(`-${iMod.ModifierDisplayGroupId}-`) !== -1)
                    preModPriceMultiplier = 1;

                // Check on mod count for pricing setting
                if (mod.CountForPricing)
                    pCount = preModPriceMultiplier; // set pre-mod multiplier for later
                else if (preModPriceMultiplier > 1)
                    pCount = preModPriceMultiplier - 1;
            }

            // if pre-mod multipler is less than 0, set back to 0
            if (pCount < 0)
                pCount = 0;

            // Total mods pre-mod multiplier
            mpCount += pCount;
            iMod.Price = (pCount * Number(iPrice.PerModifierPrice));

            // Check mod adds to price setting, if it does, add to all mods addToPrice variable
            if (mod.AddsToPrice) {
                const addToPriceMod = Number(preModPriceMultiplier * this.GetModifierAddToPrice(mod, oItem.SizeId));
                addToPrice += addToPriceMod;
                iMod.Price += addToPriceMod;
            }

            // Check mod count for pricing for itself setting
            if (!mod.CountForPricing && mod.CountForPricingByItself)
                byItSelfChargeable += preModPriceMultiplier;

            //pizzas that allow 1 topping but premodifer extra selected, need to increase max price
            if (preModPriceMultiplier > 1)
                addOnMaxPriceCount += preModPriceMultiplier - 1;
        }

        // If pre mod multiplier is 0 and there is a charge by itself, then increase pre mod multiplier by 1
        if (mpCount === 0 && byItSelfChargeable > 0)
            mpCount += 1;

        // calculate total new item price
        let minprice = 0.00;
        let maxprice = 0.00;
        if (iPrice.MinimumPrice)
            minprice = iPrice.MinimumPrice;

        if (iPrice.MaximumPrice)
            maxprice = iPrice.MaximumPrice;

        const unroundedPrice = Number(iPrice.Price + (mpCount * iPrice.PerModifierPrice) + addToPrice);
        let price = Number((Math.round(unroundedPrice * 100) / 100).toFixed(2));
        const pricePerMod = Number(iPrice.PerModifierPrice);

        const mPrice = maxprice;
        if (maxprice < 0)
            maxprice = -maxprice;

        // Increase max price (pizza allows 1 topping but user selected premodifer extra)
        if (maxprice > 0 && pricePerMod > 0 && addOnMaxPriceCount > 0) {
            maxprice += (pricePerMod * addOnMaxPriceCount);
        }

        if (price < minprice)
            price = minprice

        if (price > maxprice && mPrice < 0)
            price = mPrice;
        else if (price > maxprice && maxprice !== 0)
            price = maxprice;

        //return Number(price);
        return { "Priced": (price > 0), "Price": Number(price), MinPrice: minprice, "TaxIncluded": iPrice.TaxIncluded, Tax: 0 };
    }

    public static GetPreMod(premodID: number, preModifiers: IDataPreModifier[]): IDataPreModifier | null {
        return preModifiers.find(pMod => pMod.PreModifierId === premodID) || null;
    }

    public static GetModifierAddToPrice(mod: IItemModifierDisplayGroupModifier, sizeID: number): number {
        const price = mod?.Prices.find(mPrice => mPrice.SizeId === sizeID) || null;
        return price ? price.AddOnPrice : 0.00;
    }

    public static TaxItem(mItem: IDataItem | null, oItem: IOrderItem, localAolo: IaOLO): boolean {
        let tax = OnlineOrderingUtil.GetItemTax(mItem, oItem, localAolo);
        oItem.TaxAmount = tax.TaxableAmount;
        oItem.Taxes = tax.Taxes;
        oItem.Tax = tax.TotalTax;
        return tax.Taxed;
    }

    public static GetItemTax(mItem: IDataItem | null, oItem: IOrderItem, localAolo: IaOLO): IMenuItemTax {
        let taxed = false;
        let totalTax = 0;
        const taxes = [];
        let taxTaxableAmount = 0.00;
        let itemTaxableAmount = 0.00;
        if (mItem?.Taxes) {
            for (const mItemTax of mItem.Taxes) {
                const tax = localAolo.data.Taxes[mItemTax.Index];
                let taxAmount = Util.Float5(tax.FixAmount);
                let taxRate = Util.Float5(tax.Rate);
                let taxSizeID = tax.SizeId;
                if (localAolo.data.Settings.ISCC) {
                    const storeTax = this.GetCallCenterStoreTaxRate(tax.TaxId, aOLO.Order.StoreID, aOLO.data.Stores);
                    if (storeTax) {
                        taxAmount = storeTax.taxAmount;
                        taxRate = storeTax.taxRate;
                        taxSizeID = storeTax.sizeID;
                    }
                }

                if (localAolo.Order.OrderTypeID === OrderType.DELIVERY && localAolo.Temp.Address) {
                    const zip = localAolo.Temp.Address.Zip;
                    const zipTax = this.GetZipTaxes(tax.TaxId, zip, localAolo.data.ZipTaxes);
                    if (zipTax !== null) {
                        taxAmount = Util.Float5(zipTax.FixAmount);
                        taxRate = Util.Float5(zipTax.Rate);
                        taxSizeID = zipTax.SizeId;
                    }
                }

                const iTax = {
                    TaxId: 0,
                    IsPerOrder: false,
                    DisplayNextToItem: false,
                    Amount: 0.0,
                    TaxTypeId: 0,
                    TaxTypeName: []
                } as IOrderItemTax;

                let iPush = false;
                if ((tax.IsSizeBase || tax.IsFixedAmountBase) && (taxSizeID === oItem.SizeId || taxSizeID == 0)) {
                    // if the tax is next to the Item round it to 2 decimal point.
                    iTax.Amount = Util.Float2(taxAmount * oItem.Quantity);
                    iPush = true;
                } else if (tax.IsPercentageBase) {
                    let oItemPrice = oItem.Price;
                    if (oItem.PriceIncludesTax && oItem.AfterTaxDiscount > 0)
                        oItemPrice = Util.Float2(((oItemPrice - Util.Float2(oItem.AfterTaxDiscount / oItem.Quantity)) / (1 + taxRate / 100)));
                    else if (oItem.PriceIncludesTax)
                        oItemPrice = Util.Float2((oItemPrice / (1 + taxRate / 100)));

                    itemTaxableAmount = Util.Float2((oItemPrice * oItem.Quantity) + taxTaxableAmount);

                    if (tax.IsAffectedByDiscounts)
                        itemTaxableAmount -= Util.Float2(oItem.BeforeTaxDiscount);

                    if (itemTaxableAmount < 0)
                        itemTaxableAmount = 0.00;

                    if (oItem.PriceIncludesTax) {
                        const itemPriceMinusDiscount = oItem.Price - Util.Float2(oItem.AfterTaxDiscount / oItem.Quantity);
                        iTax.Amount = Util.Float5((itemPriceMinusDiscount * oItem.Quantity) - itemTaxableAmount);
                    } else
                        iTax.Amount = Util.Float5(Util.Float2(itemTaxableAmount) * (Util.Float5(taxRate) / 100));

                    iPush = true;
                }

                if (iPush) {
                    if (tax.IsAddToSalesAmount)
                        taxTaxableAmount += Util.Float2(iTax.Amount);

                    iTax.IsPerOrder = tax.IsPerOrder;
                    iTax.DisplayNextToItem = tax.IsNextToItemIndividually;
                    iTax.TaxTypeId = tax.TaxTypeId;
                    iTax.TaxId = tax.TaxId;
                    iTax.TaxTypeName = tax.Names;
                    taxes.push(iTax);
                }
            }
            taxes.forEach((x) => {
                totalTax += x.Amount;
            });
            taxed = true;
        }

        return {
            "Taxed": taxed,
            "Taxes": taxes,
            "TaxableAmount": itemTaxableAmount,
            "TotalTax": Util.Float2(totalTax)
        }
    }

    public static GetCallCenterStoreTaxRate(taxID: number, storeID: number, stores: IDataStore[]): { taxAmount: number, taxRate: number, sizeID: number } | null {
        const store = this.getCallCenterStore(storeID, stores);
        const tax = store?.Taxes.find(tax => tax.TaxId === taxID);
        if (tax)
            return {
                taxAmount: Util.Float5(tax.FixedAmount),
                taxRate: Util.Float5(tax.Rate),
                sizeID: tax.SizeId
            };
        return null;
    }

    public static GetZipTaxes(taxID: number, zipCode: string, zipTaxes: IDataZipTax[]): IDataZipTax | null {
        return zipTaxes.find(zTax => zTax.TaxId === taxID && zTax.ZipCode === zipCode) || null;
    }

    public static async RedeemPoints(points: number | null, itemKey: number, localAolo: IaOLO): Promise<void> {
        const item = this.GetItemByItemKey(itemKey, localAolo.Order.Items);
        if (!item)
            return;

        const isHalfHalf = item.HalfCount > 1;

        if (item.IsRedeemed) {
            for (const [index, item] of localAolo.Temp.loyaltyPointCoupons.entries()) {
                if (item.itemKey === itemKey) {
                    points = item.points;
                    localAolo.Temp.loyaltyPointCoupons.splice(index, 1);
                }
            }
            localAolo.Temp.loyaltyPointsRedeemed -= points ?? 0;
            if (localAolo.User.LoyaltyData)
                localAolo.User.LoyaltyData.CurrentPoints = localAolo.User.LoyaltyData.CurrentPoints + (points ?? 0);

            if (isHalfHalf) {
                const halfItems = localAolo.Order.Items.filter(x => x.ItemKey == item.ItemKey);
                for (const halfItem of halfItems) {
                    halfItem.IsRedeemed = false;
                }
            } else
                item.IsRedeemed = false;

            Util.showElement(`btn_cart_item_redeem_${itemKey}`);
        } else {
            Util.hideElement(`btn_cart_item_redeem_${itemKey}`);
            localAolo.Temp.loyaltyPointsRedeemed += points || 0;
            if (localAolo.User.LoyaltyData)
                localAolo.User.LoyaltyData.CurrentPoints = localAolo.User.LoyaltyData.CurrentPoints - (points ?? 0);

            if (isHalfHalf) {
                const halfItems = localAolo.Order.Items.filter(x => x.ItemKey == item.ItemKey);
                for (const halfItem of halfItems) {
                    halfItem.IsRedeemed = true;
                }
            } else
                item.IsRedeemed = true;

            const currentDiscountsApplied = localAolo.Order.ItemsCoupons.filter(x => x.ItemKey === itemKey).reduce((acc, current) => acc + current.AfterTaxDiscount + current.BeforeTaxDiscount, 0);
            const loyaltyItems = localAolo.data.LoyaltyItems;
            const price = isHalfHalf ? this.getRedeemItemChargeableHalfPrice(item.ItemKey, localAolo.Order.Items, loyaltyItems): this.getRedeemItemChargeablePrice(item, loyaltyItems);
            const discountAmount = price - currentDiscountsApplied;
            const params: IApplyCouponByIdParams = {
                couponId: 7,
                discount: discountAmount,
                couponCode: (points ? points.toString() : null),
                itemKey: itemKey
            };
            await localAolo.Modules.Coupon.ApplyCouponByID(params);
            const couponKey = localAolo.Order.Coupons.find(x => x.CouponId == 7 && x.Discount == discountAmount);
            localAolo.Temp.loyaltyPointCoupons.push({ itemKey: itemKey, points: points, couponKey: couponKey?.CouponKey });
        }

        Util.setElement("innerText", "spn_cart_points_available", `${localAolo.User.LoyaltyData?.CurrentPoints || 0} `);
    }

    private static readonly getRedeemItemChargeablePrice = (item: IOrderItem, loyaltyItems: IDataLoyaltyItem[]): number => {
        const isChargeable = OnlineOrderingUtil.IsRedeemItemChargeable(item.ItemId, item.SizeId, loyaltyItems);
        const itemPrice = isChargeable ? item.MinPrice : item.Price;
        return itemPrice;
    }

    private static readonly getRedeemItemChargeableHalfPrice = (itemKey: number, orderItems: IOrderItem[], loyaltyItems: IDataLoyaltyItem[]): number => {
        let price = 0;
        const oItems = orderItems.filter(x => x.ItemKey === itemKey);
        for (const item of oItems) {
            const isChargeable = OnlineOrderingUtil.IsRedeemItemChargeable(item.ItemId, item.SizeId, loyaltyItems);
            const itemPrice = isChargeable ? Math.round(item.MinPrice / item.HalfCount) : item.Price;
            price += itemPrice;
        }

        return price;
    }

    public static GetItemByItemKey(itemKey: number, orderItems: IOrderItem[]): IOrderItem | null {
        return orderItems.find(x => x.ItemKey == itemKey) || null;
    }

    public static GetItemSize(mItem: IDataItem, orderTypeID: number, sizeID: number): IItemOrderTypeSize | null {
        const sizes = this.GetItemSizes(mItem, orderTypeID);
        return sizes?.find(size => size.SizeId === sizeID) || null;
    }

    public static ItemHasSize(mItem: IDataItem, orderTypeID: number, sizeID: number): boolean {
        return (this.GetItemSize(mItem, orderTypeID, sizeID) != null);
    }

    public static TaxOrder(order: IOrder, localAolo: IaOLO): [IOrderTax[], IOrderTax[]] {
        const orderTaxes: IOrderTax[] = [];
        let orderTaxesDetail: IOrderTax[] = [];
        for (const oItem of order.Items) {
            let excludeTax = false;

            for (const tax of oItem.Taxes) {
                const taxData = localAolo.data.Taxes.find(x => x.TaxId === tax.TaxId);
                if (taxData && taxData.OrderTypesExcluded.includes(order.OrderTypeID)) {
                    if (tax.Amount > 0)
                        tax.Amount = 0;
                    excludeTax = true;
                    continue;
                }

                const itemTax = oItem.PriceIncludesTax ? Util.Float5(oItem.Tax) : Util.Float5(tax.Amount);
                const orderTax = orderTaxes.find(x => x.TaxTypeId == tax.TaxTypeId);
                if (!orderTax) {
                    const oTax = {
                        DisplayNextToItem: tax.DisplayNextToItem,
                        Amount: itemTax,
                        TaxTypeId: tax.TaxTypeId,
                        TaxTypeName: tax.TaxTypeName
                    } as IOrderTax;
                    orderTaxes.push(oTax);
                } else
                    orderTax.Amount = Util.Float5(orderTax.Amount + Util.Float5(itemTax));

                // Tax Detail
                const taxDef: IDataTax | undefined = Common.GetTaxDefinitionById(tax.TaxId, localAolo.data.Taxes);
                if (taxDef) {
                    taxDef.Names = typeof taxDef.Names === 'string' ? JSON.parse(taxDef.Names) : taxDef.Names;
                    const itemTaxDetail = oItem.PriceIncludesTax ? Util.Float5(oItem.Tax) : Util.Float5(tax.Amount);
                    const orderTaxDetail = orderTaxesDetail.find(x => x.TaxId == tax.TaxId);
                    if (!orderTaxDetail) {
                        const oTax = {
                            DisplayNextToItem: tax.DisplayNextToItem,
                            Amount: itemTaxDetail,
                            TaxTypeId: tax.TaxTypeId,
                            TaxId: tax.TaxId,
                            TaxTypeName: taxDef.Names
                        } as IOrderTax;
                        orderTaxesDetail.push(oTax);
                    } else
                        orderTaxDetail.Amount += Util.Float5(itemTaxDetail);
                }
            }
            if (excludeTax)
                oItem.TaxAmount = 0;
        }

        if (order.OrderTypeCharge > 0) {
            const orderTypeChargeTax = this.GetOrderTypeChargeTax(order, aOLO);

            const orderTax = orderTaxes.find(x => x.TaxTypeId == order.OrderTypeSubType.TaxTypeId);
            if (!orderTax && order.OrderTypeSubType.TaxTypeId) {
                const oTax = {
                    DisplayNextToItem: false,
                    Amount: orderTypeChargeTax,
                    TaxTypeId: order.OrderTypeSubType.TaxTypeId,
                    TaxTypeName: order.OrderTypeSubType.TaxNames
                } as IOrderTax;
                orderTaxes.push(oTax);
            } else if (orderTax)
                orderTax.Amount += Util.Float5(orderTypeChargeTax);

            // Tax Detail
            const orderTypeChargeTaxId = order.OrderTypeSubType.OrderTypeChargeTaxId;
            const taxDef: IDataTax | undefined = Common.GetTaxDefinitionById(orderTypeChargeTaxId, localAolo.data.Taxes);
            if (taxDef) {
                taxDef.Names = typeof taxDef.Names === 'string' ? JSON.parse(taxDef.Names) : taxDef.Names;
                const orderTaxDetail = orderTaxesDetail.find(x => x.TaxId == orderTypeChargeTaxId);
                if (!orderTaxDetail && order.OrderTypeSubType.TaxTypeId) {
                    const oTax = {
                        DisplayNextToItem: false,
                        Amount: orderTypeChargeTax,
                        TaxTypeId: order.OrderTypeSubType.TaxTypeId,
                        TaxId: orderTypeChargeTaxId,
                        TaxTypeName: taxDef.Names
                    } as IOrderTax;
                    orderTaxesDetail.push(oTax);
                } else if (orderTaxDetail)
                    orderTaxDetail.Amount += Util.Float5(orderTypeChargeTax);
            }
        }

        if (order.ServiceCharges?.Detail.length > 0) {
            for (const serviceCharge of order.ServiceCharges.Detail) {
                if (serviceCharge.Tax <= 0)
                    continue;

                const orderTax = orderTaxes.find(x => x.TaxTypeId == serviceCharge.TaxTID);
                if (!orderTax) {
                    const oTax = {
                        DisplayNextToItem: false,
                        Amount: serviceCharge.Tax,
                        TaxTypeId: serviceCharge.TaxTID,
                        TaxTypeName: serviceCharge.Names
                    } as IOrderTax;
                    orderTaxes.push(oTax);
                } else {
                    orderTax.Amount += Util.Float5(serviceCharge.Tax);
                }

                // Tax Detail
                const orderTaxDetail = orderTaxesDetail.find(x => x.TaxId == serviceCharge.TaxID);
                if (!orderTaxDetail) {
                    const taxDef: IDataTax | undefined = Common.GetTaxDefinitionById(serviceCharge.TaxID, localAolo.data.Taxes);
                    if (taxDef) {
                        taxDef.Names = typeof taxDef.Names === 'string' ? JSON.parse(taxDef.Names) : taxDef.Names;
                        const oTax = {
                            DisplayNextToItem: false,
                            Amount: serviceCharge.Tax,
                            TaxTypeId: serviceCharge.TaxTID,
                            TaxId: serviceCharge.TaxID,
                            TaxTypeName: serviceCharge.Names
                        } as IOrderTax;
                        orderTaxesDetail.push(oTax);
                    }
                } else {
                    orderTaxDetail.Amount += Util.Float5(serviceCharge.Tax);
                }
            }
        }

        // Sorting Tax Detail array by TaxTypeName
        orderTaxesDetail = orderTaxesDetail.sort((a, b) => {
            const taxNameA: string | undefined = a.TaxTypeName?.find(x => x.CULT === localAolo.Temp.languageCode)?.NAM;
            const taxNameB: string | undefined = b.TaxTypeName?.find(x => x.CULT === localAolo.Temp.languageCode)?.NAM;
            if (taxNameA && taxNameB)
                return taxNameA.localeCompare(taxNameB);

            if (taxNameA)
                return 1;

            if (taxNameB)
                return -1;
            return 0;
        });

        return [orderTaxes, orderTaxesDetail];
    }

    public static GetOrderTypeChargeTax(order: IOrder, localAolo: IaOLO): number {
        const taxRate = this.GetTaxRate(order, localAolo);
        return (taxRate > 0) ? (order.OrderTypeCharge * taxRate * 0.01) : 0;
    }

    private static GetTaxRate(order: IOrder, localAolo: IaOLO): number {
        let taxRate = Util.Float5(order.OrderTypeSubType.Rate);
        if (localAolo.data.Settings.ISCC) {
            const storeTax = this.GetCallCenterStoreTaxRate(order.OrderTypeSubType.OrderTypeChargeTaxId, localAolo.Order.StoreID, localAolo.data.Stores);
            if (storeTax)
                taxRate = storeTax.taxRate;
        }

        if (order.OrderTypeID === OrderType.DELIVERY) {
            if (localAolo.Temp.Address) {
                const zipTax = this.GetZipTaxes(order.OrderTypeSubType.OrderTypeChargeTaxId, localAolo.Temp.Address.Zip, localAolo.data.ZipTaxes);
                if (zipTax)
                    taxRate = Util.Float5(zipTax.Rate);
            }
        }
        return taxRate;
    }

    public static ReCalculateTax(localAolo: IaOLO): void {
        for (const oItem of localAolo.Order.Items) {
            const mItem = localAolo.data.Items.find(x => x.ItemId === oItem.ItemId) || null;
            this.TaxItem(mItem, oItem, localAolo);
        }
        [localAolo.Order.Taxes, localAolo.Order.TaxesDetail] = this.TaxOrder(localAolo.Order, localAolo);
    }

    public static SetCategoryHeader(catSubCatName: IName[], catID: number | null, isMealDeal: boolean, languageCode: string): HTMLElement {
        const catDiv = document.createElement("div");
        catDiv.classList.add("categoryHeader");

        if (isMealDeal)
            catDiv.setAttribute("name", "mealDealCatHeader");

        const catHeader = document.createElement("h1");
        if (catID) {
            catHeader.id = `h1CatAnchor_${catID}`;
            catHeader.dataset.catid = catID.toString();
        }
        catHeader.classList.add("categoryHeaderText");
        catHeader.innerText = Common.GetName(catSubCatName, languageCode);
        catHeader.setAttribute("ltagj", Util.toLtagj(catSubCatName));
        catDiv.appendChild(catHeader);
        return catDiv;
    }

    public static GetLoyaltyPoints(itemID: number, sizeID: number, loyaltyItems: IDataLoyaltyItem[]): number | null {
        if (loyaltyItems.length === 0)
            return null;

        const points = loyaltyItems.filter(x => x.ItemId === itemID && x.SizeId === sizeID).map(x => x.Points);
        return (points.length == 1) ? points[0] : null;
    }

    public static IsRedeemItemChargeable(itemID: number, sizeID: number, loyaltyItems: IDataLoyaltyItem[]): boolean {
        if (loyaltyItems.length === 0)
            return false;

        const item = loyaltyItems.find(x => x.ItemId === itemID && x.SizeId === sizeID);
        return (item) ? item.IsChargeable : false;
    }

    public static GetItemDefaultSize(mItem: IDataItem, orderTypeID: number, sizeID: number | null, dszID: number): IItemOrderTypeSize {
        const sizes = this.GetItemSizes(mItem, orderTypeID);
        const iDefSize = sizes.find(siz => siz.IsDefault) || sizes.find(siz => siz.SizeId === dszID) || sizes.find(siz => siz.SizeId === sizeID) || sizes[0];
        return iDefSize;
    }

    public static getItemCalories(mItem: IDataItem, oItem: IOrderItem | null, orderTypeID: number, sizeID: number | null, localAolo: IaOLO): IName[] {
        const servingName = (mItem.ServingNames) ? mItem.ServingNames : null;

        if (sizeID == null) {
            const sizes = this.GetItemSizes(mItem, orderTypeID);
            if (sizes?.length === 1 && sizes[0].SizeId === 0)
                sizeID = sizes[0].SizeId;
            else
                sizeID = this.GetItemDefaultSize(mItem, orderTypeID, null, localAolo.data.Settings.DSZID).SizeId;
        }

        let modCals = 0;
        if (!oItem)
            modCals = this.getMenuItemModifiersCalories(mItem, sizeID, aOLO.Temp.DefPreModID);
        else
            modCals = this.getOrderItemModifiersCalories(mItem, oItem, sizeID);

        let calorieObj: ICalorie | null = null;
        const serveCals = this.getItemSizeMinMaxCalories(mItem, sizeID);
        if (serveCals.minCal !== 0 && serveCals.maxCal !== 0 && serveCals.minCal !== serveCals.maxCal) {
            if (mItem.CalorieDisplayId == 1) // per serving
                calorieObj = {
                    Name: "CalorieServingMinMax",
                    MinCal: Math.trunc((serveCals.minCal + modCals) / serveCals.serves),
                    MaxCal: Math.trunc((serveCals.maxCal + modCals) / serveCals.serves),
                    Serving: null,
                    ServingName: servingName
                };
            else if (mItem.CalorieDisplayId == 2) // fraction 
                calorieObj = {
                    Name: "CalorieFractionMinMax",
                    MinCal: Math.trunc((serveCals.minCal + modCals) / serveCals.serves),
                    MaxCal: Math.trunc((serveCals.maxCal + modCals) / serveCals.serves),
                    Serving: serveCals.serves,
                    ServingName: servingName
                };
        } else if (serveCals.minCal + modCals > 0) {
            if (mItem.CalorieDisplayId == 1) // per serving
                calorieObj = {
                    Name: "CalorieServingMin",
                    MinCal: Math.trunc((serveCals.minCal + modCals) / serveCals.serves),
                    MaxCal: null,
                    Serving: null,
                    ServingName: servingName
                };
            else if (mItem.CalorieDisplayId == 2) // fraction 
                calorieObj = {
                    Name: "CalorieFractionMin",
                    MinCal: Math.trunc((serveCals.minCal + modCals) / serveCals.serves),
                    MaxCal: null,
                    Serving: serveCals.serves,
                    ServingName: servingName
                };
        }

        if (calorieObj) {
            const languages = getSupportedLanguages();
            const nameObj: IName[] = [];
            for (const lang of languages) {
                const serving = calorieObj.ServingName ? Common.GetName(calorieObj.ServingName, aOLO.Temp.languageCode, lang) : Names("Slice", lang);
                switch (calorieObj.Name) {
                    case "CalorieServingMinMax":
                        nameObj.push({ CULT: lang, NAM: Names(calorieObj.Name, lang).replace("??", calorieObj.MinCal.toString()).replace("???", (calorieObj.MaxCal || "").toString()).replace("????", serving) });
                        break;
                    case "CalorieFractionMinMax":
                        nameObj.push({ CULT: lang, NAM: Names(calorieObj.Name, lang).replace("??", calorieObj.MinCal.toString()).replace("???", (calorieObj.MaxCal || "").toString()).replace("????", (calorieObj.Serving || "").toString()).replace("?????", serving) });
                        break;
                    case "CalorieServingMin":
                        nameObj.push({ CULT: lang, NAM: Names(calorieObj.Name, lang).replace("??", calorieObj.MinCal.toString()).replace("???", serving) });
                        break;
                    case "CalorieFractionMin":
                        nameObj.push({ CULT: lang, NAM: Names(calorieObj.Name, lang).replace("??", calorieObj.MinCal.toString()).replace("???", (calorieObj.Serving || "").toString()).replace("????", serving) });
                        break;
                }
            }
            return nameObj;
        }

        return [];
    }

    public static getMenuItemModifiersCalories(mItem: IDataItem, sizeID: number | null, defPreModID: number): number {
        if (!mItem.ModifierDisplayGroups)
            return 0;

        const disGrpModCount = [];
        let totalCals = 0;

        for (const modDG of mItem.ModifierDisplayGroups) {
            for (const mod of modDG.Modifiers) {
                if (!mod.IsDefault)
                    continue;

                const modCount: number = (disGrpModCount[mod.MergedModifierDisplayGroupId] || this.getMenuItemModCount(mItem, mod.MergedModifierDisplayGroupId));
                disGrpModCount[mod.MergedModifierDisplayGroupId] = modCount;
                totalCals += this.getMenuItemModCalories(mod, sizeID, modCount, defPreModID);
            }
        }

        return totalCals;
    }

    public static getMenuItemModCount(mItem: IDataItem, mergedMDGID: number): number {
        let count = 0;
        for (const modDG of mItem.ModifierDisplayGroups) {
            for (const mod of modDG.Modifiers) {
                if (mod.IsDefault && mod.MergedModifierDisplayGroupId == mergedMDGID)
                    count++;
            }
        }
        return count;
    }

    public static getMenuItemModCalories(mod: IItemModifierDisplayGroupModifier, sizeID: number | null, modCount: number, preModId: number): number {
        if (!mod || !mod.Calories)
            return 0;

        if (aOLO.data.Settings.PMODDM === 1) {
            let modcals = mod.Calories.find(x => x.SizeId === sizeID && x.PreModifierId === aOLO.Temp.DefPreModID && x.ModifierCount === modCount);
            if (!modcals) {
                modcals = mod.Calories.find(x => x.SizeId === sizeID && x.ModifierCount === modCount);
            }
            if (!modcals && modCount > 0) {
                const maxCount = Math.max(...Array.from(mod.Calories, o => o.ModifierCount));
                modcals = mod.Calories.find(x => x.SizeId === sizeID && x.PreModifierId === aOLO.Temp.DefPreModID && x.ModifierCount === maxCount);
                if (!modcals)
                    modcals = mod.Calories.find(x => x.SizeId === sizeID && x.ModifierCount === maxCount);
            }
            if (modcals) {
                if (preModId === aOLO.Temp.DefPreModID) {
                    return modcals.Calories;
                } else {
                    const premod = this.GetPreMod(preModId, aOLO.data.PreModifiers);
                    return modcals.Calories * (premod?.InventoryMultiplier || 1);
                }
            }
        } else {
            for (let i = modCount; i >= 0; i--) {
                const modPremodCals = mod.Calories.find(x => x.SizeId === sizeID && x.PreModifierId === preModId && x.ModifierCount === i);
                if (modPremodCals)
                    return modPremodCals.Calories;
            }
        }
        return 0;
    }

    public static getOrderItemModifiersCalories(mItem: IDataItem, oItem: IOrderItem, sizeID: number | null): number {
        const disGrpModCount = [];
        let totalCals = 0;
        for (const oMod of oItem.Modifiers) {
            let modCount = 0;
            if (disGrpModCount[oMod.MergedModifierDisplayGroupId])
                modCount = disGrpModCount[oMod.MergedModifierDisplayGroupId];
            else {
                disGrpModCount[oMod.MergedModifierDisplayGroupId] = this.GetModCount(oItem.Modifiers, oMod.MergedModifierDisplayGroupId);
                modCount = disGrpModCount[oMod.MergedModifierDisplayGroupId];
            }

            const modDispGroup = mItem.ModifierDisplayGroups.find(x => x.ModifierDisplayGroupId == oMod.ModifierDisplayGroupId);
            if (!modDispGroup)
                continue;

            const mod = modDispGroup.Modifiers.find(x => x.ModifierId == oMod.ModifierId);
            if (!mod)
                continue;

            totalCals += this.getMenuItemModCalories(mod, sizeID, modCount, oMod.PreModifierId);
        }
        return totalCals;
    }

    public static GetModCount(mods: IOrderItemModifier[], mergedModifierDisplayGroupId: number): number {
        let count = 0;
        mods.forEach(mod => {
            if (mod.PreModifierId !== aOLO.Temp.NonPreModID && mod.MergedModifierDisplayGroupId == mergedModifierDisplayGroupId)
                count += 1;
        });
        return count;
    }

    public static getItemSizeMinMaxCalories(mItem: IDataItem, sizeID: number | null): { minCal: number, maxCal: number, serves: number } {
        const cal = {
            minCal: 1000000,
            maxCal: 0,
            serves: 1
        };
        if (mItem.SizeCalories) {
            const calSize = mItem.SizeCalories.find(x => x.SizeId === sizeID);
            if (calSize) {
                cal.serves = calSize.Servings;
                if (cal.minCal > calSize.MinimumCalories)
                    cal.minCal = calSize.MinimumCalories;

                if (calSize.MaximumCalories && cal.minCal > calSize.MaximumCalories)
                    cal.minCal = calSize.MaximumCalories;

                if (cal.maxCal < calSize.MinimumCalories)
                    cal.maxCal = calSize.MinimumCalories;

                if (calSize.MaximumCalories && cal.maxCal < calSize.MaximumCalories)
                    cal.maxCal = calSize.MaximumCalories;
            }
        }

        if (cal.minCal == 1000000)
            cal.minCal = 0;

        return cal;
    }

    public static CalculateOrderHubPaymentTotals(localAOLO: IaOLO): void {
        // Convert the list of objects to IOrderServiceCharge
        const serviceCharges: IOrderServiceCharge = (aOLO.Order.ServiceCharges as any).reduce(
            (result: IOrderServiceCharge, currentServiceCharge: any) => {
                // Accumulate Amount and Tax
                result.Amount += currentServiceCharge.Amount;
                result.Tax += currentServiceCharge.TaxAmount;

                // Add details if needed
                // result.Detail.push({ /* details from currentServiceCharge */ });

                return result;
            },
            { Amount: 0, Detail: [], Tax: 0 } // Initial values
        );

        localAOLO.Order.Total = this.calcOrderTotal(localAOLO.Order.SubTotal, localAOLO.Order.Discount, localAOLO.Order.OrderTypeCharge, localAOLO.Order.CollectedTax, localAOLO.Order.Tip, serviceCharges.Amount, localAOLO.Order.TotalDonation, localAOLO.Order.TaxInSubTotal);
        localAOLO.Order.AmountDue = localAOLO.Order.Total;
    }

    public static CalculateTotals(localAOLO: IaOLO): void {
        localAOLO.Order.SubTotal = 0.0;
        localAOLO.Order.Discount = 0.0;
        localAOLO.Order.Tax = 0.0;

        localAOLO.Order.TaxInSubTotal = this.fGetTaxInSubTotal(localAOLO.Order);
        localAOLO.Order.SubTotal = this.fGetSubTotal(localAOLO.Order);

        for (const coupon of localAOLO.Order.Coupons)
            localAOLO.Order.Discount += coupon.Amount;

        this.fSetServiceCharges(localAOLO);

        for (const tax of localAOLO.Order.Taxes) {
            localAOLO.Order.Tax += tax.Amount;
        }
        localAOLO.Order.CollectedTax = Util.Float2(localAOLO.Order.Tax);

        localAOLO.Order.Total = this.calcOrderTotal(localAOLO.Order.SubTotal, localAOLO.Order.Discount, localAOLO.Order.OrderTypeCharge, localAOLO.Order.CollectedTax, localAOLO.Order.Tip, localAOLO.Order.ServiceCharges.Amount, localAOLO.Order.TotalDonation, localAOLO.Order.TaxInSubTotal);
        localAOLO.Order.AmountDue = localAOLO.Order.Total;

        this.CalculateGiftcardsInTotals(localAOLO);
    }

    public static CalculateGiftcardsInTotals(localAOLO: IaOLO): void {
        let orderAmountDue = Util.Float2(aOLO.Order.AmountDue);
        for (const giftCard of localAOLO.Temp.GiftCards) {
            const gCard = giftCard;
            if (gCard.balance > 0) {
                if (gCard.balance > orderAmountDue)
                    gCard.appliedAmount = orderAmountDue;
                else
                    gCard.appliedAmount = Util.Float2(gCard.balance);
                orderAmountDue -= Util.Float2(gCard.appliedAmount);
            }
        }
        localAOLO.Order.AmountDue = Util.Float2(orderAmountDue);
    }

    public static fGetSubTotal(order: IOrder): number {
        let subTotal = 0;
        for (const oItem of order.Items) {
            subTotal += Util.Float2(oItem.Price * oItem.Quantity);
        }
        return Util.Float2(subTotal);
    }

    public static fGetTaxInSubTotal(order: IOrder): number {
        if (!order)
            return 0;

        let subTotalTaxInc = 0;
        const items = order.Items.filter(x => x.PriceIncludesTax);

        for (const oItem of items) {
            subTotalTaxInc += Util.Float2(oItem.Tax);
        }
        return Util.Float2(subTotalTaxInc);
    }

    public static fSetServiceCharges(localAOLO: IaOLO): void {
        // Initialize and clear old values 
        localAOLO.Order.ServiceCharges.Tax = 0;
        localAOLO.Order.ServiceCharges.Amount = 0;
        localAOLO.Order.ServiceCharges.Detail = [];

        if (!localAOLO.data || localAOLO.data.ServiceCharges.length === 0)
            return;

        const serviceCharges: IDataServiceCharge[] = localAOLO.data.ServiceCharges;

        const orderServiceCharges: IServiceChargeDetail = {
            Tax: 0,
            Amount: 0,
            Detail: []
        };

        for (const sc of serviceCharges) {
            const amount = this.GetServiceChargeAmount(localAOLO, sc) || 0;
            if (amount <= 0) continue;

            const tax = (sc.TaxId > 0 && sc.TaxRate) ? Util.Float5(amount * sc.TaxRate / 100) : 0;

            const serChrg: IOrderServiceChargeDetail = {
                ID: sc.ServiceChargeId,
                Name: Common.GetName(sc.Names, aOLO.Temp.languageCode),
                Names: sc.Names,
                Amount: amount,
                Overwritten: false,
                Tax: tax,
                TaxID: sc.TaxId,
                TaxTID: sc.TaxTypeId,
                TaxNAM: sc.TaxName
            };

            orderServiceCharges.Amount += amount;
            orderServiceCharges.Tax += tax;
            orderServiceCharges.Detail.push(serChrg);
        }

        let totalTax = 0;
        let totalCharge = 0;

        for (const serviceCharge of orderServiceCharges.Detail) {
            const sch = serviceCharge;
            const oSch = this.GetExistingServiceCharge(localAOLO.Order, sch.ID);
            if (oSch) {
                if (!oSch.Overwritten) {
                    totalCharge += sch.Amount;
                    totalTax += sch.Tax;
                    oSch.Amount = sch.Amount;
                    oSch.Tax = sch.Tax
                } else {
                    totalCharge += oSch.Amount;
                    totalTax += oSch.Tax;
                }
            } else {
                totalCharge += sch.Amount;
                totalTax += sch.Tax;
                localAOLO.Order.ServiceCharges.Detail.push(sch);
            }
        }
        localAOLO.Order.ServiceCharges.Amount = totalCharge;
        localAOLO.Order.ServiceCharges.Tax = totalTax;
        [localAOLO.Order.Taxes, localAOLO.Order.TaxesDetail] = this.TaxOrder(localAOLO.Order, localAOLO);
    }

    public static GetServiceChargeAmount(localAolo: IaOLO, serviceCharge: IDataServiceCharge): number | null {
        let chargeableAmount = 0;
        if (serviceCharge.IncludesSubtotal)
            chargeableAmount += localAolo.Order.SubTotal;

        if (serviceCharge.IncludesDeliveryCharge)
            chargeableAmount += localAolo.Order.OrderTypeCharge;

        if (serviceCharge.IncludesTax)
            chargeableAmount += (this.fGetOrderTypeChargeTaxes(localAolo) + this.fGetOrderItemTaxes(localAolo.Order));

        if (serviceCharge.MinAmount && chargeableAmount < serviceCharge.MinAmount)
            return null;
        if (serviceCharge.MaxAmount && chargeableAmount > serviceCharge.MaxAmount)
            return null;

        const otst = serviceCharge.OrderTypeSubTypes?.find(otst => otst.OrderTypeId === localAolo.Order.OrderTypeSubType?.OrderTypeId && otst.OrderTypeSubId === localAolo.Order.OrderTypeSubType?.OrderTypeSubId);
        if (!otst)
            return null;

        let chargeAmount = serviceCharge.FixAmount + (chargeableAmount * serviceCharge.Percentage) / 100;
        if (chargeAmount < serviceCharge.MinCharge)
            chargeAmount = serviceCharge.MinCharge;

        if (chargeAmount > serviceCharge.MaxCharge)
            chargeAmount = serviceCharge.MaxCharge;

        return chargeAmount;
    }

    public static GetExistingServiceCharge(order: IOrder, serviceChargeID: number): IOrderServiceChargeDetail | null {
        return order.ServiceCharges.Detail.find(sc => sc.ID === serviceChargeID) || null;
    }

    public static fGetOrderItemTaxes(order: IOrder): number {
        let totalTax = 0;

        for (const item of order.Items) {
            if (item.IsRemoved) {
                item.Taxes = [];
            } else {
                for (const tax of item.Taxes) {
                    totalTax += Util.Float5(tax.Amount);
                }
            }
        }

        return totalTax;
    }

    public static fGetOrderTypeChargeTaxes(localAolo: IaOLO): number {
        if (localAolo.Order.OrderTypeCharge + localAolo.Order.SubTotal - localAolo.Order.Discount <= 0 || localAolo.Order.IsTaxExempt)
            return 0;

        const orderTypeCharge = localAolo.Order.OrderTypeCharge + Math.min(0, localAolo.Order.SubTotal - localAolo.Order.Discount);

        let taxRate = Util.Float5(localAolo.Order.OrderTypeSubType.Rate);
        if (localAolo.data.Settings.ISCC) {
            const storeTax = this.GetCallCenterStoreTaxRate(localAolo.Order.OrderTypeSubType.OrderTypeChargeTaxId, localAolo.Order.StoreID, localAolo.data.Stores);
            if (storeTax)
                taxRate = storeTax.taxRate;
        }

        if (localAolo.Order.OrderTypeID === OrderType.DELIVERY && aOLO.Temp.Address) {
            const zip = localAolo.Temp.Address?.Zip;
            if (zip) {
                const zipTax = this.GetZipTaxes(localAolo.Order.OrderTypeSubType.OrderTypeChargeTaxId, zip, localAolo.data.ZipTaxes);
                if (zipTax !== null)
                    taxRate = zipTax.Rate;
            }
        }

        return Util.Float5(orderTypeCharge * taxRate / 100);
    }

    public static async MealDealAddToOrder(itemKey: number | null, localAOLO: IaOLO, mixAndMatch: boolean = false): Promise<boolean> {
        if (!localAOLO.Dialog.MealDeal)
            return false;

        if (localAOLO.Dialog.MealDeal.GetDelete()) {
            delete localAOLO.Dialog.MealDeal;
            return false;
        } else if (mixAndMatch) {
            localAOLO.Dialog.MealDeal.CloseScreen(true);
            const params: IApplyCouponByIdParams = {
                couponId: localAOLO.Dialog.MealDeal.GetCouponId(),
                showApplyMsg: true
            };

            const couponSuccess = await localAOLO.Modules.Coupon.ApplyCouponByID(params);

            delete localAOLO.Dialog.MealDeal;
            return true;
        } else {
            const result = await localAOLO.Dialog.MealDeal.AddToOrder(itemKey);
            if (result) {
                if (localAOLO.Dialog.MealDeal.GetCouponCode())
                    await localAOLO.Modules.Coupon.ApplyCoupon(localAOLO.Dialog.MealDeal.GetCoupon(), localAOLO.Dialog.MealDeal.GetCouponCode(), true, true, undefined, undefined, localAOLO.Dialog.MealDeal.GetIsCampaignCode(), localAOLO.Dialog.MealDeal.GetIsPromotionCode());
                else if (localAOLO.Dialog.MealDeal.GetOrderId())
                    await localAOLO.Modules.Coupon.ApplyCoupon(localAOLO.Dialog.MealDeal.GetCoupon(), `bx${localAOLO.Dialog.MealDeal.GetOrderId()}`, true, true, undefined, undefined, localAOLO.Dialog.MealDeal.GetIsCampaignCode(), localAOLO.Dialog.MealDeal.GetIsPromotionCode());
                else {
                    const rewardId = localAOLO.Dialog.MealDeal.GetRewardId();
                    const params: IApplyCouponByIdParams = {
                        couponId: localAOLO.Dialog.MealDeal.GetCouponId(),
                        showApplyMsg: true,
                        rewardId: (rewardId ? rewardId : null)
                    };
                    await localAOLO.Modules.Coupon.ApplyCouponByID(params);
                }
            }
            return result
        }
    }

    public static getElementTop(el: HTMLElement | null, parentID: string): number {
        if (!el)
            return 0;

        let top = 0;
        while (el) {
            const isTopElement = (el.id === parentID || el.tagName === "BODY");
            const yScroll = isTopElement ? el.scrollTop || document.documentElement.scrollTop : el.scrollTop;
            top += el.offsetTop - yScroll + el.clientTop;
            el = el.offsetParent as HTMLElement | null;
        }
        return top;
    }

    public static GetModifierCalories(desc: string): string {
        return (desc.indexOf("cal.") > -1) ? desc : "";
    }

    public static GetRecID(newItems?: IOrderItem[]): number {
        const items = [...aOLO.Order.Items];
        if (newItems) {
            items.push(...newItems);
        }
        const maxRecID = items.reduce((max: number, item: IOrderItem) => {
            if (item.ItemRecId > max)
                return item.ItemRecId;
            return max;
        }, 99);
        return maxRecID + 1;
    }

    public static IsItemComplete(oItem: IOrderItem, displayErrorMessage: boolean, msg: string | null, localAOLO: IaOLO): boolean {
        if (oItem.SizeId == null)
            Util.LogError(`online-ordering-util -> IsItemComplete`, new Error("SizeId is null"), localAOLO);

        const mItem = localAOLO.data.Items.find(x => x.ItemId === oItem.ItemId);
        if (!mItem?.ModifierDisplayGroups)
            return true;

        for (const iMdg of mItem.ModifierDisplayGroups) {
            const mdg = localAOLO.data.ModifierDisplayGroups[iMdg.Index];
            if (!mdg.IsVisible)
                continue;

            let modCount = 0;
            for (const mod of oItem.Modifiers) {
                if (mod.ModifierDisplayGroupId === mdg.ModifierDisplayGroupId) {
                    modCount += 1;
                    if (modCount >= mdg.MinSelectionCount)
                        break;
                }
            }

            if (modCount < mdg.MinSelectionCount) {
                if (displayErrorMessage)
                    DialogCreators.messageBoxOk(msg ?? `${Names("minMod")}${mdg.MinSelectionCount} ${Common.GetName(mdg.Names, localAOLO.Temp.languageCode)}`, localAOLO.buttonHoverStyle);
                return false;
            }
        }

        return true;
    }

    public static isModOfferedOnSize(iMod: IItemModifierDisplayGroupModifier, sizeID: number): boolean {
        const modAddPrice = this.GetModifierAddToPrice(iMod, sizeID);
        return (modAddPrice !== -1);
    }

    public static Item_Get_Default_Mods(mItem: IDataItem, sizeId: number | null, localAOLO: IaOLO): IOrderItemModifier[] {
        if (!mItem.ModifierDisplayGroups)
            return [];

        const mods: IOrderItemModifier[] = [];
        for (const [index, mdg] of mItem.ModifierDisplayGroups.entries()) {
            let modAddedForThisMDG = false;

            for (const [index2, iMod] of mdg.Modifiers.entries()) {
                if (!iMod.IsDefault)
                    continue;

                if (OnlineOrderingUtil.isModOfferedOnSize(iMod, sizeId || 0)) {
                    const mod = {
                        ModifierId: iMod.ModifierId,
                        Name: iMod.Name,
                        Index: iMod.Index,
                        ModifierDisplayGroupIndex: mdg.Index,
                        iIndex: index2,
                        iModifierDisplayGroupIndex: index,
                        PreModifierId: localAOLO.Temp.DefPreModID,
                        IsDefault: iMod.IsDefault,
                        ModifierDisplayGroupId: mdg.ModifierDisplayGroupId,
                        MergedModifierDisplayGroupId: iMod.MergedModifierDisplayGroupId,
                        CountForPricing: iMod.CountForPricing,
                        CountForPricingByItself: iMod.CountForPricingByItself,
                        Price: 0
                    };
                    mods.push(mod);
                } else {
                    // Search for compatible mod
                    for (const [index3, iMod2] of mdg.Modifiers.entries()) {
                        const compatibleMod = OnlineOrderingUtil.isModOfferedOnSize(iMod2, sizeId || 0);
                        if (compatibleMod) {
                            const mod = {
                                ModifierId: iMod2.ModifierId,
                                Name: iMod2.Name,
                                Index: iMod2.Index,
                                ModifierDisplayGroupIndex: mdg.Index,
                                iIndex: index3,
                                iModifierDisplayGroupIndex: index,
                                PreModifierId: localAOLO.Temp.DefPreModID,
                                IsDefault: iMod2.IsDefault,
                                ModifierDisplayGroupId: mdg.ModifierDisplayGroupId,
                                MergedModifierDisplayGroupId: iMod2.MergedModifierDisplayGroupId,
                                CountForPricing: iMod2.CountForPricing,
                                CountForPricingByItself: iMod2.CountForPricingByItself,
                                Price: 0
                            };
                            mods.push(mod);
                            break;
                        }
                    }
                }
                modAddedForThisMDG = true;
            }

            if (!modAddedForThisMDG) {
                const dMdg = localAOLO.data.ModifierDisplayGroups[mdg.Index];
                if (dMdg.MaxSelectionCount === 1 || dMdg.MustToggle) {
                    const iMod = mdg.Modifiers[0];
                    const mod = {
                        ModifierId: iMod.ModifierId,
                        Name: iMod.Name,
                        Index: iMod.Index,
                        ModifierDisplayGroupIndex: mdg.Index,
                        iIndex: 0,
                        iModifierDisplayGroupIndex: index,
                        PreModifierId: aOLO.Temp.DefPreModID,
                        IsDefault: iMod.IsDefault,
                        ModifierDisplayGroupId: mdg.ModifierDisplayGroupId,
                        MergedModifierDisplayGroupId: iMod.MergedModifierDisplayGroupId,
                        CountForPricing: iMod.CountForPricing,
                        CountForPricingByItself: iMod.CountForPricingByItself,
                        Price: 0
                    };
                    mods.push(mod);
                    modAddedForThisMDG = true;
                }
            }
        }

        return mods;
    }

    public static PriceItem(oItem: IOrderItem, localAOLO: IaOLO): boolean {
        let priced = false;
        const mItem = localAOLO.data.Items.find(x => x.ItemId === oItem.ItemId) || null;
        if (mItem?.Prices) {
            let price1 = this.GetItemPrice(mItem, oItem, localAOLO);
            if (price1.Price < 0) price1.Price = -price1.Price;

            oItem.MenuPrice = price1.Price;
            oItem.MenuPriceTax = price1.Tax;
            oItem.PriceIncludesTax = price1.TaxIncluded
            oItem.Price = price1.Price;
            oItem.MinPrice = price1.MinPrice;
            oItem.HalfHalfPrice = price1.Price;

            if (oItem.HalfCount === 2) {
                let hhPrice = price1.Price;
                let hhMinPrice = price1.MinPrice;
                let hhPriceTax = price1.Tax;
                const oItem2 = this.GetOtherHalfItem(oItem.ItemKey, Math.round(((oItem.HalfIndex % 2) + 1)), aOLO.Order.Items);
                if (oItem2 !== null) {
                    const mItem2 = localAOLO.data.Items.find(x => x.ItemId === oItem2?.ItemId);
                    if (mItem2?.Prices) {
                        let price2 = this.GetItemPrice(mItem2, oItem2, localAOLO);
                        if (price2.Price < 0) price2.Price = -price2.Price;
                        const highestPrice = localAOLO.data.Settings.HHP === 1;
                        if (highestPrice) {
                            hhPrice = Util.Float2(Math.max(price1.Price, price2.Price));
                            hhMinPrice = Util.Float2(Math.max(price1.MinPrice, price2.MinPrice));
                            hhPriceTax = Util.Float2(Math.max(price1.Tax, price2.Tax));
                        } else {
                            hhPrice = Util.Float2((price1.Price + price2.Price) / 2);
                            hhMinPrice = Util.Float2((price1.MinPrice + price2.MinPrice) / 2);
                            hhPriceTax = Util.Float2((price1.Tax + price2.Tax) / 2);
                        }

                        oItem.Price = Util.Float2(hhPrice / 2);
                        oItem.MinPrice = Util.Float2(hhMinPrice / 2);
                        oItem.Tax = Util.Float2(hhPriceTax / 2);
                        oItem.HalfHalfPrice = hhPrice;

                        oItem2.Price = Util.Float2(hhPrice / 2);
                        oItem2.MinPrice = Util.Float2(hhMinPrice / 2);
                        oItem2.Tax = Util.Float2(hhPriceTax / 2);
                        oItem2.HalfHalfPrice = hhPrice;
                        this.TaxItem(mItem2, oItem2, localAOLO);
                    }
                }
            }
            priced = true;
        } else {
            oItem.MenuPrice = 0;
            oItem.MenuPriceTax = 0;
            oItem.Tax = 0;
            oItem.PriceIncludesTax = false;
            oItem.Price = 0;
            oItem.MinPrice = 0;
            oItem.HalfHalfPrice = 0;
        }
        this.TaxItem(mItem, oItem, localAOLO);
        return priced;
    }

    public static GetOtherHalfItem(itemKey: number, halfIndex: number, orderItems: IOrderItem[]): IOrderItem | null {
        return orderItems.find(oItem => oItem.ItemKey === itemKey && oItem.HalfCount === 2 && oItem.HalfIndex === halfIndex) || null;
    }

    public static GetSecondHalfItem(itemKey: number, orderItems: IOrderItem[]): IOrderItem | null {
        return orderItems.find(oItem => oItem.ItemKey === itemKey && oItem.HalfCount === 2 && oItem.HalfIndex === 2) || null;
    }

    public static GetNextItemKey(orderItems: IOrderItem[]): number {
        return orderItems.reduce((maxIndex: number, oItem: IOrderItem) => {
            return Math.max(maxIndex, oItem.ItemKey);
        }, 0) + 1;
    }

    public static CanCustomize(mItem: IDataItem, modDisplayGroup: IDataModifierDisplayGroup[]): boolean {
        return mItem.ModifierDisplayGroups.some(mdg => {
            const { MaxSelectionCount, MustToggle } = modDisplayGroup[mdg.Index];
            return MaxSelectionCount > 1 && !MustToggle;
        }) ?? false;
    }

    public static Create_Modifier_DropDown(mItem: IDataItem, localAOLO: IaOLO): IModDropdown {
        if (mItem.ModifierDisplayGroups.length === 0)
            return { element: null, DropdownIds: null };

        const ddlIds: string[] = [];

        const div = document.createElement("div");
        for (const [index, modG] of mItem.ModifierDisplayGroups.entries()) {
            const mdg = localAOLO.data.ModifierDisplayGroups.find(mdg => modG.ModifierDisplayGroupId === mdg.ModifierDisplayGroupId);
            let html = "";

            if (!mdg?.IsVisible)
                return { element: null, DropdownIds: null };

            const selId = `sel_item_mod_dg_${mItem.ItemId}_${mdg.ModifierDisplayGroupId}`;
            ddlIds.push(selId);

            const optionsHTML = modG.Modifiers.map((mod, index2) => {
                return `<option value="${mod.ModifierId}" data-mod-iidx="${index2}" data-mdg-iidx="${index}" ltagj="${Util.toLtagj(localAOLO.data.Modifiers[mod.Index].Names)}" ${mod.IsDefault ? "selected" : ""}>${Common.GetName(localAOLO.data.Modifiers[mod.Index].Names, localAOLO.Temp.languageCode)}</option>`;
            }).join('');

            html += `
                <div class="m1-lr">
                    <label class="m1-b" for="${selId}" ltagj="${Util.toLtagj(mdg.Names)}">${Common.GetName(mdg.Names, localAOLO.Temp.languageCode)}</label>
                    <select id="${selId}" class="m1-b">
                        ${optionsHTML}
                    </select>
                </div>`;

            div.innerHTML += html;
        }

        return { element: div, DropdownIds: ddlIds };
    }

    public static MustCustomize(mItem: IDataItem, modDisplayGroup: IDataModifierDisplayGroup[], itemStyleID: number): boolean {
        let mustCustomize = false;
        let mustSelectDG_WithNoDefault = false;
        let multiSelectDG = false;
        if (mItem.ModifierDisplayGroups) {
            for (const iMdg of mItem.ModifierDisplayGroups) {
                const mdg = modDisplayGroup[iMdg.Index];
                if (itemStyleID === ItemStyle.VERTICAL_SIZE_BTN && mdg.MaxSelectionCount > 1 && !mdg.MustToggle) {
                    mustCustomize = true;
                }
                let defCount = 0;

                for (const mod of iMdg.Modifiers) {
                    if (mod.IsDefault)
                        defCount += 1;
                }

                if (defCount === 0 && mdg.MinSelectionCount > 0)
                    mustSelectDG_WithNoDefault = true;

                if (mdg.MaxSelectionCount > 1 && !mdg.MustToggle)
                    multiSelectDG = true;

                if (!mustCustomize && mdg.MaxSelectionCount !== 1 && !mdg.MustToggle) {
                    /*
                     * if default mod counts is less than
                     * min mod in group
                     * item must be customized
                     */
                    if (defCount < mdg.MinSelectionCount)
                        mustCustomize = true;
                }

                if (mustCustomize)
                    break;
            }
        }

        if (mustSelectDG_WithNoDefault && multiSelectDG)
            mustCustomize = true;

        return mustCustomize;
    }

    public static CheckZones(lat: number, long: number, deliveryZones: IStoreLocationZone[]): IAddressZone | null {
        if (deliveryZones.length === 0)
            return null;

        const zoneStat: IAddressZone = {
            ZTID: 0,
            ZoneID: 0,
            ZID: 0,
            SID: 0,
            MNDL: 0,
            ILT: false,
            LIMTIME: []
        };

        let zoneMatchFound = false;

        const point = {
            Latitude: lat,
            Longitude: long
        } as IDeliveryZonePolygon;

        for (const zone of deliveryZones) {
            // Are the coordinates in the delivery zone
            if (this.IsPointInPolygon(zone.polygons, point)) {
                if (zoneStat.ZTID < zone.id) {
                    zoneStat.ZTID = zone.typeId;
                    zoneStat.ZID = zone.id;
                    zoneStat.SID = zone.storeId;
                    zoneStat.MNDL = zone.minimumDeliveryAmount;
                    zoneStat.ILT = zone.isLimitedTime;
                    zoneStat.LIMTIME = zone.limitedTimes;

                    zoneMatchFound = true;
                }
            }
        }

        return zoneMatchFound ? zoneStat : null;
    }

    public static IsPointInPolygon(poly: IStoreLocationZonePolygon[], point: IDeliveryZonePolygon): boolean {
        let j = poly.length - 1;
        let oddNodes = false;
        for (let i = 0; i < poly.length; i++) {
            const PointIx = Util.Float12(poly[i].longitude);
            const PointIy = Util.Float12(poly[i].latitude);
            const PointJx = Util.Float12(poly[j].longitude);
            const PointJy = Util.Float12(poly[j].latitude);
            if ((((PointIy <= point.Latitude) && (point.Latitude < PointJy)) || ((PointJy <= point.Latitude) && (point.Latitude < PointIy))) &&
                (point.Longitude < (PointJx - PointIx) * (point.Latitude - PointIy) / (PointJy - PointIy) + PointIx)) {
                oddNodes = !oddNodes;
            }
            j = i;
        }
        return oddNodes;
    }

    public static GetDate_StartTime_EndTime(busDate: Date, busMinute: number, displayHolidayMsg: boolean, localAOLO: IaOLO, otst?: IDataOrderTypeSubType | null, oTypeClicked?: boolean): IDateStartEndTime {
        busDate = Util.GetBUSDate(busDate, localAOLO);
        const day = busDate.getDay();
        const now = Util.NowStore(localAOLO.Temp.TimeOffset);
        const nowMinute = Util.DateDiff("m", now, busDate) + localAOLO.data.Settings.BSM;//, Util.GetBUSDate(now, localAOLO));// now.getMinutes() + (60 * now.getHours());
        const isToday = Util.DateDiff("d", busDate, Util.GetBUSDate(now, localAOLO)) == 0;
        const waitTime = otst?.WaitTime || 0;
        const DSTET: IDateStartEndTime = {
            IsToday: isToday,
            StartOrderMinute: localAOLO.data.Hours[day].OrderTakingStartDayMinute + waitTime,
            EndOrderMinute: localAOLO.data.Hours[day].OrderTakingEndDayMinute,
            OpenMinute: localAOLO.data.Hours[day].OpenDayMinute,
            CloseMinute: localAOLO.data.Hours[day].CloseDayMinute,
            IsClosedHoliday: false,
            Online: true,
            ASAP: true,
            Date: busDate,
            Minute: 0,
            IsClosedNow: false,
            HolidayID: 0,
            ThrottleInfo: null,
            FutureOrder: !!otst ? otst.FutureOrders : true
        };

        const holiday = this.CheckHolidays(busDate, displayHolidayMsg, localAOLO.storeInfo.StoreID, localAOLO);
        if (holiday) {
            DSTET.HolidayID = holiday.HolidayId;
            switch (holiday.HolidayStatusId) {
                case 0:
                    // do nothing is holiday but store is open
                    DSTET.ThrottleInfo = holiday.Info;
                    break;
                case 1:
                    //closed   
                    DSTET.Online = false;
                    DSTET.IsClosedHoliday = true;
                    DSTET.StartOrderMinute = 0;
                    DSTET.EndOrderMinute = 0;
                    DSTET.OpenMinute = 0;
                    DSTET.CloseMinute = 0;
                    break;
                case 2:
                    // Holiday hours
                    DSTET.StartOrderMinute = holiday.StartMinute + waitTime;
                    DSTET.EndOrderMinute = holiday.EndMinute;
                    DSTET.OpenMinute = holiday.StartMinute;
                    DSTET.CloseMinute = holiday.EndMinute;
                    DSTET.ThrottleInfo = holiday.Info;
                    break;
                case 3:
                    // open limited online times
                    DSTET.StartOrderMinute = holiday.StartMinute + waitTime;
                    DSTET.EndOrderMinute = holiday.EndMinute;
                    DSTET.ThrottleInfo = holiday.Info;

                    break;
                case 4:
                    //Open no online ordering
                    DSTET.Online = false;
                    DSTET.StartOrderMinute = 0;
                    DSTET.EndOrderMinute = 0;
                    break;
                case 5:
                    //Open no online Time/Future ordering
                    DSTET.FutureOrder = false;
            }
        }

        let paused_limited = false;
        if (otst) {
            if (otst.StartMinute != 0 || otst.EndMinute != 1440) {
                DSTET.StartOrderMinute = Math.max(DSTET.StartOrderMinute, otst.StartMinute);
                DSTET.EndOrderMinute = Math.min(DSTET.EndOrderMinute, otst.EndMinute);
                paused_limited = nowMinute < DSTET.StartOrderMinute || nowMinute > DSTET.EndOrderMinute;;
            }
            if (otst.PausedUntil != null && Util.DateDiff("s", new Date(otst.PausedUntil), now) > 0) {
                //const nowDate = Util.GetBUSDate(now, localAOLO);
                //let laterDateMin = new Date(nowDate);
                const pausedTime = new Date(otst.PausedUntil);
                if (pausedTime > now) {
                    const dateDiff = Util.DateDiff("d", pausedTime, busDate);
                    paused_limited = dateDiff >= 0;
                    if (dateDiff == 0)
                        DSTET.StartOrderMinute = Math.max(DSTET.StartOrderMinute, pausedTime.getMinutes() + (60 * pausedTime.getHours()));
                    else if (dateDiff > 0) {// || oTypeClicked) {
                        DSTET.Online = false;
                        DSTET.StartOrderMinute = 0;
                        DSTET.EndOrderMinute = 0;
                    }
                }
            }
        }
        if (isToday)
            DSTET.StartOrderMinute = Math.max(DSTET.StartOrderMinute, nowMinute + waitTime);

        DSTET.EndOrderMinute = Math.min(DSTET.EndOrderMinute, DSTET.CloseMinute);
        DSTET.StartOrderMinute = Math.max(DSTET.StartOrderMinute, DSTET.OpenMinute);
        DSTET.StartOrderMinute = Math.min(DSTET.StartOrderMinute, DSTET.EndOrderMinute);

        DSTET.IsClosedNow = DSTET.IsClosedNow || nowMinute < DSTET.OpenMinute || nowMinute > DSTET.CloseMinute;

        DSTET.ASAP = !DSTET.IsClosedNow && !paused_limited;

        DSTET.StartOrderMinute = Math.ceil(DSTET.StartOrderMinute / 5) * 5;
        DSTET.EndOrderMinute = Math.ceil(DSTET.EndOrderMinute / 5) * 5;
        DSTET.Minute = Math.max(busMinute, DSTET.StartOrderMinute);
        return DSTET;
    }

    public static GetNextOpenDay(localAOLO: IaOLO, orderTypeSubType?: IDataOrderTypeSubType | null): IDateStartEndTime {
        let takingOnlineOrder = false;
        const now = Util.NowStore(localAOLO.Temp.TimeOffset);
        let nowMins = now.getMinutes() + (60 * now.getHours());
        let busDate = new Date(Util.GetBUSDate(now, localAOLO));
        let DSTET: IDateStartEndTime = {
            IsToday: false,
            StartOrderMinute: 0,
            EndOrderMinute: 0,
            OpenMinute: 0,
            CloseMinute: 0,
            IsClosedHoliday: false,
            Online: false,
            ASAP: false,
            Date: now,
            Minute: 0,
            IsClosedNow: false,
            HolidayID: 0,
            ThrottleInfo: null,
            FutureOrder: false
        };

        while (!takingOnlineOrder) {
            DSTET = OnlineOrderingUtil.GetDate_StartTime_EndTime(busDate, nowMins, false, localAOLO, orderTypeSubType);
            takingOnlineOrder = DSTET.Online
            if (!takingOnlineOrder)
                busDate = Util.DateAdd(busDate, 1, "d");
        }
        return DSTET;
    }

    public static ParseAddress(address: string, addressObject: ITempAddress | null, localAOLO: IaOLO): IAddressParsed {
        let iAddress = address;
        let zip = "";
        let state = "";
        let city = "";
        const addrZip = this.ParseAddressGetZip(iAddress, localAOLO);
        const addrState = this.ParseAddressGetState(addrZip.Address, localAOLO);
        const addrCity = this.ParseAddressGetCity(addrState.Address, localAOLO);

        iAddress = addrCity.Address;
        zip = addrZip.Zip;
        state = addrState.State;
        city = addrCity.City;

        const streetDirAbbr = ["n", "ne", "nw", "s", "se", "sw", "e", "w"];
        const stSuffixAbbr = ["aly", "anx", "arc", "ave", "bch", "bg", "blf", "blfs", "blvd", "bnd", "br", "brg", "brk", "brks", "btm", "byp", "byu",
            "cir", "cirs", "clb", "clf", "clfs", "cmn", "cmns", "cor", "cors", "cp", "cpe", "cres", "crk", "crse", "crst", "cswy", "ct", "ctr", "cts",
            "curv", "cv", "cvs", "cyn", "dl", "dm", "dr", "drs", "dv", "est", "ests", "expy", "ext", "exts", "fall", "fld", "flds", "fls", "flt", "flts",
            "frd", "frg", "frk", "frks", "frst", "fry", "ft", "fwy", "gdn", "gdns", "gln", "grn", "grns", "grv", "grvs", "gtwy", "hbr", "hl", "hls", "holw",
            "hts", "hvn", "hwy", "inlt", "is", "isle", "jct", "knl", "knls", "ky", "kys", "land", "lck", "lcks", "ldg", "lf", "lgt", "lgts", "lk", "lks",
            "ln", "lndg", "loop", "mall", "mdw", "mdws", "mews", "ml", "mls", "mnr", "msn", "mt", "mtn", "mtwy", "nck", "opas", "orch", "oval", "park",
            "pass", "path", "pike", "pkwy", "pl", "pln", "plns", "plz", "pne", "pnes", "pr", "prt", "psge", "pt", "pts", "radl", "ramp", "rd", "rdg",
            "rdgs", "rds", "riv", "rnch", "row", "rpds", "rst", "rte", "rue", "run", "shl", "shls", "shr", "shrs", "skwy", "smt", "spg", "spgs", "spur",
            "sq", "sqs", "st", "sta", "stra", "strm", "sts", "ter", "tpke", "trak", "trce", "trfy", "trl", "trlr", "trwy", "tunl", "un", "upas", "via",
            "vis", "vl", "vlg", "vlgs", "vly", "vw", "vws", "walk", "wall", "way", "ways", "wl", "wls", "xing", "xrd", "xrds"];
        const addr = iAddress.split("  ").join(" ").trim().split(" ").join("^").toLowerCase();
        let stNo = isNaN(parseInt(addr)) ? "" : parseInt(addr).toString();
        let stPostFix = "th";
        const stNoRight = Util.RightText(stNo, 1);
        if (stNoRight === "1") {
            stPostFix = "st";
        } else if (stNoRight === "2") {
            stPostFix = "nd";
        } else if (stNoRight === "3") {
            stPostFix = "rd";
        }
        const tmpStName = stNo + stPostFix;
        if (addr.substring(0, tmpStName.length) === tmpStName) {
            stNo = "";
        }
        const Address = {
            Address: "",
            StNo: stNo,
            StPreDirAbbr: "",
            StPostDirAbbr: "",
            StSuffixAbbr: "",
            StName: "",
        };
        const stName = addr.substring(stNo.length + 1).split("^");
        if (stName.length === 1) {
            Address.StName = Util.FixStringCase(stName[0]);
        } else {
            let sIndex = 0;
            let eIndex = stName.length - 1;
            let lookIn = stName[sIndex];
            for (const abbr of streetDirAbbr) {
                if (abbr === lookIn) {
                    Address.StPreDirAbbr = lookIn.toUpperCase();
                    sIndex += 1;
                    break;
                }
            }
            if (sIndex === eIndex) {
                Address.StName = stName[sIndex];
            } else {
                lookIn = stName[eIndex];
                for (const abbr of streetDirAbbr) {
                    if (abbr === lookIn) {
                        Address.StPostDirAbbr = lookIn.toUpperCase();
                        eIndex -= 1;
                        break;
                    }
                }
                if (sIndex === eIndex) {
                    Address.StName = stName[sIndex];
                } else {
                    lookIn = stName[eIndex];
                    for (const abbr of stSuffixAbbr) {
                        if (abbr === lookIn) {
                            Address.StSuffixAbbr = Util.FixStringCase(lookIn);
                            eIndex -= 1;
                            break;
                        }
                    }
                    for (let i = sIndex; i <= eIndex; i++) {
                        Address.StName += stName[i] + " ";
                    }
                }
            }
            Address.StName = Util.FixStringCase(Address.StName);
        }
        let tempAddr = "";
        if (Address.StPreDirAbbr !== "") tempAddr += Address.StPreDirAbbr + " ";
        if (Address.StName !== "") tempAddr += Address.StName + " ";
        if (Address.StSuffixAbbr !== "") tempAddr += Address.StSuffixAbbr + " ";
        if (Address.StPostDirAbbr !== "") tempAddr += Address.StPostDirAbbr + " ";
        Address.Address = tempAddr;

        const addre = {
            StreetNo: Address.StNo.trim(),
            StreetName: tempAddr.trim(),
            StreetShortName: tempAddr.trim(),
            City: city.trim(),
            State: state.trim(),
            Zip: zip.trim(),
            Latitude: 0,
            Longitude: 0
        };

        return addre;
    }

    public static ParseAddressGetZip(address: string, localAOLO: IaOLO): IParseAddressGetZip {
        address = address.trim();
        const addr: IParseAddressGetZip = {
            Zip: "",
            Address: ""
        };

        try {
            let iZip = "";
            const zips = localAOLO.data.ZipCodes.split(",");
            let idx = -1;
            for (const zip of zips) {
                idx = address.lastIndexOf(zip);
                if (idx > 4) {
                    iZip = zip;
                    break;
                }
            }
            if (idx > 4) {
                if (idx > 4) {
                    addr.Zip = iZip;
                    const part1 = address.substring(0, idx);
                    const part2 = address.substring(idx);
                    let tempAddr = part1 + " " + part2.replace(iZip, "");
                    tempAddr = tempAddr.replaceAll("  ", " ");
                    tempAddr = tempAddr.replaceAll("  ", " ");
                    addr.Address = tempAddr.trim();
                } else {
                    addr.Zip = "";
                    addr.Address = address.trim();
                }
            }
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("ParseAddressGetZip", ex, localAOLO);

            addr.Zip = "";
            addr.Address = address.trim();
        }
        return addr;
    }

    public static ParseAddressGetState(address: string, localAOLO: IaOLO): IParseAddressGetState {
        address = address.trim();
        const addr: IParseAddressGetState = {
            State: "",
            Address: ""
        };
        try {
            const state = localAOLO.data.Settings.STAT.toUpperCase();
            const idx = address.toUpperCase().lastIndexOf(" " + state + " ");
            if (idx > -1) {
                addr.State = state;
                const part1 = address.substring(0, idx);
                const part2 = address.substring(idx);
                let tempAddr = part1 + " " + part2.replace(state, "");
                tempAddr = tempAddr.replaceAll("  ", " ");
                tempAddr = tempAddr.replaceAll("  ", " ");
                addr.Address = tempAddr;
            } else if (address.endsWith(" " + state)) {
                addr.State = state;
                addr.Address = address.substring(0, address.length - 2).trim();
            } else {
                addr.State = "";
                addr.Address = address.trim();
            }
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("ParseAddressGetState", ex, localAOLO);

            addr.State = "";
            addr.Address = address.trim();
        }
        return addr;
    }

    public static ParseAddressGetCity(address: string, localAOLO: IaOLO): IParseAddressGetCity {
        address = address.trim();
        const addr: IParseAddressGetCity = {
            Address: "",
            City: ""
        };

        try {
            let iCity = "";
            const cities = localAOLO.data.Cities.split(",");
            let idx = -1;
            for (const c of cities) {
                const city = c.toUpperCase().replaceAll("'", "").trim();

                idx = address.toUpperCase().lastIndexOf(city);
                if (idx > 4) {
                    iCity = c.replaceAll("'", "").trim();
                    break;
                }
            }
            if (idx > 4) {
                addr.City = iCity;
                const part1 = address.substring(0, idx);
                const part2 = address.substring(idx);
                let tempAddr = part1 + " " + part2.substring(iCity.length);
                tempAddr = tempAddr.replaceAll("  ", " ");
                tempAddr = tempAddr.replaceAll("  ", " ");
                addr.Address = tempAddr.trim();
            } else {
                addr.City = "";
                addr.Address = address.trim();
            }
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("ParseAddressGetCity", ex, localAOLO);

            addr.City = "";
            addr.Address = address.trim();
        }
        return addr;
    }

    public static ParseGooglePlace(place: any, streetNo: string, aCity: string, cities: string[], localAOLO: IaOLO): IAddressParsed {
        const componentForm: IComponentForm = {
            street_number: 'short_name',
            route: 'long_name',
            locality: 'long_name',
            administrative_area_level_1: 'short_name',
            country: 'long_name',
            postal_code: 'short_name',
            neighborhood: 'long_name'
        };

        let neighborhoodlong = "";
        let neighborhoodshort = "";
        const address: IAddressParsed = {
            StreetNo: "",
            StreetName: "",
            StreetShortName: "",
            City: "",
            State: "",
            Zip: "",
            Latitude: place.geometry?.location?.lat() || 0,
            Longitude: place.geometry?.location?.lng() || 0
        };

        for (const addrComponent of place.address_components || []) {
            const addressType = addrComponent.types[0];
            if (componentForm[addressType]) {
                switch (addressType) {
                    case "street_number":
                        address.StreetNo = addrComponent[componentForm[addressType]];
                        break;
                    case "route":
                        address.StreetName = addrComponent[componentForm[addressType]];
                        address.StreetShortName = addrComponent['short_name'];
                        break;
                    case "locality":
                        address.City = addrComponent[componentForm[addressType]];
                        break;
                    case "administrative_area_level_1":
                        address.State = addrComponent[componentForm[addressType]];
                        break;
                    case "postal_code":
                        address.Zip = addrComponent[componentForm[addressType]];
                        break;
                    case "neighborhood":
                        try {
                            neighborhoodlong = addrComponent['long_name'];
                        } catch (ex: unknown) {
                            if (ex instanceof Error)
                                Util.LogError("ParseGooglePlace 1", ex, localAOLO);
                        }
                        try {
                            neighborhoodshort = addrComponent['short_name'];
                        } catch (ex: unknown) {
                            if (ex instanceof Error)
                                Util.LogError("ParseGooglePlace 2", ex, localAOLO);
                        }
                        break;
                }
            }
        }

        if ((address.City === "" || address.City === null || address.City === "null" || address.City === undefined) && (neighborhoodlong !== "" || neighborhoodshort !== "")) {
            if (aCity !== "") {
                if (aCity === neighborhoodlong) {
                    address.City = aCity;
                } else if (aCity === neighborhoodshort) {
                    address.City = aCity;
                }
            }

            if (address.City === "") {
                //const cities = localAOLO.data.Cities.split(",");
                for (const c of cities) {
                    const city = c.replace("'", "").replace("'", "").trim();
                    if (city === neighborhoodlong) {
                        address.City = city;
                        break;
                    } else if (city === neighborhoodshort) {
                        address.City = city;
                        break;
                    }
                }
            }
        }
        if ((address.City === "" || address.City === null || address.City === "null" || address.City === undefined) && aCity !== "") {
            address.City = aCity;
        }
        //if (address.City === "" || address.City === null || address.City === "null" || address.City === undefined) {
        //    address.City = localAOLO.storeInfo.City;
        //}
        if (address.StreetNo === "" || address.StreetNo === undefined || address.StreetNo === null || address.StreetNo === "null") {
            if (streetNo !== "") {
                address.StreetNo = streetNo;
            }
        }
        return address;
    }

    public static SetAddressAptNo(address: ITempAddress | null | undefined): void {
        const aptNum = Util.getElementValue("txt_order_type_apt");
        if (address !== null && address !== undefined) {
            address.AddressTypeID = (aptNum !== "") ? AddressType.APT : AddressType.HOUSE;
            address.Address2 = aptNum;
        }
    }

    public static getTempAddressNew(): ITempAddress {
        return {
            AddressID: 0,
            AddressTypeID: AddressType.HOUSE,
            AddressName: 'Home',
            IsPrimary: true,
            StreetNo: "",
            StreetName: "",
            Address2: "",
            Address3: "",
            Address4: "",
            Address5: "",
            City: "",
            State: "",
            Zip: "",
            CountryID: null,
            Latitude: 0,
            Longitude: 0,
            XStreet: "",
            ZoneID: 0,
            Grid: "",
            Distance: 0,
            DeliveryInstruction: "",
        };
    }

    public static getStoreWaitTime(store: IDataStore, orderTypeID: number, timeOffset: number): number {
        const tMinutes = Util.nowTodayMinute(timeOffset);
        const savedWaitTime = store.AutoWaitTimes?.find(x => x.MIN === (tMinutes - tMinutes % 15));
        if (savedWaitTime) {
            return (orderTypeID === 3) ? savedWaitTime.DELMIN : savedWaitTime.MAKEMIN;
        }
        const waitTime = store.WaitTimes?.find(x => x.OrderTypeId === orderTypeID);
        return waitTime?.WaitTime ?? 0;
    }

    public static GotoStoreBrandPage(localAOLO: IaOLO): void {
        localAOLO.isOKNavAway = true;
        let href = window.location.href;

        if (href.endsWith('/'))
            href = href.substring(0, href.lastIndexOf('/'));

        const urlPieces = [window.location.protocol, "//", window.location.host, window.location.pathname];
        const baseurl = urlPieces.join("");
        const mobile = Util.isAppView() ? "&mobile=true" : "";
        window.location.href = `${baseurl}brand/?sk=${localAOLO.storeInfo.StoreKey}${mobile}`;
    }

    public static GetEmptyPayment(): IOrderPayment {
        return {
            PaymentKey: 0,
            PaymentTypeID: 1,
            Amount: 0,
            TaxAmount: "",
            ChangeDue: 0,
            Gratuity: 0,
            GratuityByID: null,
            GratuityTime: null,
            CurrencyID: 140,
            CurrencyAmount: 0,
            CurrencyChangeDue: 0,
            CurrencyExRate: 1,
            IsAdded: false,
            AddedByID: null,
            AddedTime: null,
            IsRemoved: false,
            RemovedByID: null,
            RemovedTime: null,

            AccountID: "",
            ExpDate: "",
            Balance: 0,
            Reference: "",
            MerchantAdoraID: 0,
            StatusID: 1,
            TransData: "",
            Token: "",
            CVM: "",
            PIN: "",
            SaveToken: false,
            BillingAddress: "",
            BillingZip: "",
            ExpMnt: "",
            ExpYr: "",
            MerchantSpecData: ""
        };
    }

    public static composeOrderObject(order: IOrder, localAOLO: IaOLO): InOrder {
        const Items_Modifiers_Taxes_Comments = this.CompleteItemsModifiersTaxesComments(order, localAOLO);
        if (!Items_Modifiers_Taxes_Comments)
            throw new Error('Could not create nOrder. CompleteItemsModifiersTaxesComments()');

        const nOrder = OnlineOrderingUtil.CompleteOrder(aOLO);
        if (!nOrder)
            throw new Error('Could not create nOrder. CompleteOrder()');

        nOrder.Challenges = this.CompleteChallenges(order);
        nOrder.Comments = this.CompleteComments(localAOLO);
        nOrder.Coupons = this.CompleteCoupons(localAOLO);
        nOrder.ServiceCharges = this.CompleteServiceCharges(order);
        nOrder.Customer = this.CompleteCustomer(order, localAOLO);
        nOrder.Address = this.CompleteAddress(localAOLO);
        nOrder.OrderItems = Items_Modifiers_Taxes_Comments?.Items || null;
        nOrder.ItemsComments = Items_Modifiers_Taxes_Comments?.Comments || null;
        nOrder.ItemsModifiers = Items_Modifiers_Taxes_Comments?.Modifiers || null;
        nOrder.ItemsTaxes = Items_Modifiers_Taxes_Comments?.Taxes || null;
        nOrder.ItemsMakeList = this.CompleteMakeList(order, nOrder.PrintTime, localAOLO);
        nOrder.ItemsCoupons = this.CompleteItemsCoupons(order, localAOLO);
        nOrder.Taxes = this.CompleteTaxes(order, localAOLO);
        nOrder.Payments = this.CompletePayments(order, localAOLO);
        nOrder.SaveToken = Util.getElementChecked("chk_checkout_credit_entry_save_token");

        if (this.IsOpenPay()) { //openpay
            nOrder.SaveToken = false
        }

        nOrder.Notify = !!localAOLO.notification?.Notification;
        nOrder.NotifyEmail = Util.getElementChecked("chk_checkout_notification_email") ? Util.getElementValue("eml_checkout_notification_email") || null : null;
        nOrder.NotifyPhone = Util.getElementChecked("chk_checkout_notification_text") ? Util.cleanNonDigits(Util.getElementValue("tel_checkout_notification_text")) || null : null;
        nOrder.NotifyCountryCodeId = Util.getElementChecked("chk_checkout_notification_text") ? Number(Util.getElementValue("dropdown_checkout_notification_country_code")) || null : null;

        nOrder.RedemptionIDs = [];
        if (localAOLO.data.Loyalty.ProgramTypes.length > 0) {
            nOrder.RedemptionIDs = this.GetRewardRedemptionIds(localAOLO).map(x => x.toString());
        }

        const customer: InOrderCustomerProfile = {
            CID: null,
            storeID: Number(localAOLO.storeInfo.StoreID),
            mrkFavStore: false,
            cult: localAOLO.Temp.languageCode,
            mrkBD: null,
            mrkEmail: null,
            mrkTxt: null,
            mrkTxtPhone: null,
            NAM: nOrder.Customer?.Name || "",
            LNAM: nOrder.Customer?.LastName || "",
            mrkSurv: null,
            mrkLoy: null,
            zip: null,
            profId: null,
            PHN: nOrder.Customer?.Phone || "",
            EMAIL: nOrder.Customer?.Email || "",
            CCDID: nOrder.Customer?.CountryCodeId || 1
        };

        if ((localAOLO.User.GU == LoggedInStatus.EXTERNAL || localAOLO.User.GU == LoggedInStatus.LOGGEDIN)) {
            customer.CID = nOrder.Customer?.CustomerID || 0;
            customer.storeID = localAOLO.User.StoreId || 0;
        }

        nOrder.CustomerProfile = JSON.stringify(customer);
        if (localAOLO.data.Fundraisers.length !== 0)
            nOrder.Fundraiser = localAOLO.Order.Fundraiser;
        return nOrder;
    }

    public static GetRewardRedemptionIds(localAOLO: IaOLO): number[] {
        const redemptionIds: number[] = [];
        localAOLO.User.LoyaltyData?.Rewards.forEach(x => {
            if (x?.Used && localAOLO.Order.Coupons.map(y => y.RewardId).includes(x.RewardId))
                redemptionIds.push(x.RewardId);
        });
        return redemptionIds;
    }

    public static CompleteOrder(localAOLO: IaOLO): InOrder | null {
        try {
            const now = Util.NowStore(localAOLO.Temp.TimeOffset);
            const order = localAOLO.Order;
            let printTime = "";
            let promiseTime = "";
            if (localAOLO.Order.FutureDate) {
                const iDate = new Date(localAOLO.Order.FutureDate);
                promiseTime = Util.formatDateTime(Util.DateAdd(iDate, localAOLO.Order.FutureMinute, "m"), "en-US");
                printTime = this.getFuturePrintTime(iDate, new Date(promiseTime), aOLO.data.Settings.FPT, aOLO.Temp.WaitTime);
            } else {
                printTime = Util.formatDateTimeSeconds(now);
                promiseTime = Util.formatDateTimeSeconds(Util.DateAdd(now, localAOLO.Temp.WaitTime, "m"));
            }

            let royaltyAmount = 0.00;
            let taxableAmount = 0.00;
            let giftCardSalesAmount = 0.00;
            let giftCertSalesAmount = 0.00;
            for (let i = 0; i < order.Items.length; i++) {
                const oItem = order.Items[i];
                const mItem = localAOLO.data.Items.find(x => x.ItemId === oItem.ItemId);
                if (mItem?.IsLoyalty) royaltyAmount += Util.Float2((oItem.Price * oItem.Quantity) - oItem.BeforeTaxDiscount - oItem.AfterTaxDiscount);
                if (mItem?.IsGiftCard) giftCardSalesAmount += Util.Float2(oItem.Price * oItem.Quantity);
                if (mItem?.IsGiftCert) giftCertSalesAmount += Util.Float2(oItem.Price * oItem.Quantity);
                taxableAmount += oItem.TaxAmount;
            }
            if (Util.Float2(order.OrderTypeSubType.Rate) > 0) {
                taxableAmount += Util.Float2(order.OrderTypeCharge);
            }
            royaltyAmount += Util.Float2(order.OrderTypeCharge);

            const donations = this.CompleteDonations(localAOLO.Order);
            let donationAmount = 0;
            donations.forEach(x => donationAmount += x.Amount);

            let statusId = 1;
            if (localAOLO.Order.FutureDate) {
                const iDate = new Date(localAOLO.Order.FutureDate);
                const bDate = new Date(Util.GetBUSDate(Util.NowStore(localAOLO.Temp.TimeOffset), localAOLO));
                statusId = (Util.DateDiff("d", bDate, iDate) < 0) ? 7 : 6; // it is next day: 7 = future day, 6 = time order
            }

            const nOrder: InOrder = {
                CultureCode: localAOLO.Temp.languageCode,
                SourceID: 2,
                StoreID: order.StoreID,

                Key: order.Key,
                GUID: order.GUID,
                OrderID: order.OrderID,
                OrderTypeID: order.OrderTypeID,
                OrderTypeSubID: order.OrderTypeSubID,
                StationID: localAOLO.data.Settings.STID,
                CustomerID: localAOLO.User.CustomerId || 0,
                PID: localAOLO.User.ProfileId || 0,
                AddressID: (localAOLO.Order.OrderTypeID === OrderType.DELIVERY) ? localAOLO.Order.AddressID : null,
                TableID: 0,
                DriveThroughID: 0,
                TakenByID: 0,
                OrderNo: 0,
                NameTableNo: localAOLO.QrCode?.tblno || "",

                OrderDate: Util.formatDate(order.OrderStartTime || new Date(), "en-US"),
                LoyaltyGroupID: localAOLO.data.Settings.LGID,
                VPhone: localAOLO.User.Phone || "",
                Loyalty: localAOLO.User.IsLoyalty || false,
                AutoLogin: localAOLO.User.ALG || false,
                PromiseTime: promiseTime,
                PrintTime: printTime,
                IsProcessed: false,
                ProcessorStationID: null,
                ProcessMarkTime: null,
                BUSDate: Util.formatDate(Util.GetBUSDate(new Date(promiseTime), localAOLO), "en-US"),
                IsFuture: (order.FutureDate !== null),
                OrderStartTime: Util.formatDateTimeSeconds(order.OrderStartTime || new Date()),
                OrderEndTime: Util.formatDateTimeSeconds(now),
                SubTotal: Util.Float2(order.SubTotal - order.TaxInSubTotal),
                Total: Util.Float2(order.Total),
                ServiceCharge: Util.Float2(order.ServiceCharges.Amount),
                GiftCardSalesAmount: Util.Float2(giftCardSalesAmount),
                GiftCertSalesAmount: Util.Float2(giftCertSalesAmount),
                RoyaltyAmount: Util.Float2(royaltyAmount),
                TaxableAmount: Util.Float2(taxableAmount),
                TaxActual: Util.Float5(order.Tax),
                TaxCollected: Util.Float2(order.Tax),
                OrderTypeCharge: Util.Float2(order.OrderTypeCharge),
                Discount: Util.Float2(order.Discount),
                Donations: donations,
                Donation: donationAmount,
                Gratuity: localAOLO.Order.Tip || 0.00,
                IsPosted: false,
                PostedTime: null,
                PostedByID: null,
                PostedStationID: null,
                StatusID: statusId,
                StatusTime: Util.formatDateTimeSeconds(now),
                StatusByID: 0,
                DispatchID: null,
                FundraiserID: order.FundraiserID,
                Fundraiser: null,
                IsTaxExempt: false,
                IsPrePaid: false,
                IsLocked: false,
                IsPriority: false,
                SplitCheckCount: 0,
                GuestCount: 0,
                Distance: order.Distance,
                PriceGroupID: order.OrderTypeSubType.PriceGroupId,
                Contactless: (localAOLO.Temp.Contactless || localAOLO.Temp.Curbside),
                SaveToken: false,

                Challenges: null,
                Comments: null,
                Coupons: null,
                ServiceCharges: null,
                Customer: null,
                Address: null,
                OrderItems: null,
                ItemsComments: null,
                ItemsModifiers: null,
                ItemsTaxes: null,
                ItemsMakeList: null,
                ItemsCoupons: null,
                Taxes: null,
                Payments: null,
                PaymentsDetail: [], // data for the detail will be populated on server side
                RedemptionIDs: [],

                Notify: false,
                NotifyEmail: null,
                NotifyPhone: null,
                NotifyCountryCodeId: null,
                CustomerProfile: ""
            };

            if (Util.isAppView())
                nOrder.SourceID = Util.isiOS() ? 4 : 3;

            return nOrder;
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CompleteOrder", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return null;
    }

    public static getFuturePrintTime(futureDate: Date, promiseTime: Date, fpt: string, waitTime: number): string {
        const pTime = fpt || "0";
        let printTime;

        try {
            const prnTime = new Date(Util.formatDateTime(Util.DateAdd(promiseTime, -waitTime, "m")));
            printTime = prnTime instanceof Date && pTime == "0" ?
                Util.formatDateTimeSeconds(prnTime) :
                Util.formatDateTimeSeconds(new Date(`${Util.formatDate(futureDate, "en-US")} ${pTime}`));
        } catch (ex) {
            printTime = Util.formatDateTimeSeconds(Util.DateAdd(futureDate, 540, "m"));
        }
        try {
            if (printTime.indexOf('Invalid') > -1)
                printTime = Util.formatDateTime(Util.DateAdd(promiseTime, -waitTime, "m"), "en-US");
        } catch {
            printTime = Util.formatDateTime(Util.DateAdd(promiseTime, -60, "m"), "en-US");
        }

        return printTime;
    }

    public static CompleteDonations(order: IOrder): InOrderDonation[] {
        const donations: InOrderDonation[] = [];
        order.Donations.Detail.forEach(donation => {
            if (donation.Amount > 0) {
                donations.push({
                    DonationID: donation.ID,
                    Amount: donation.Amount
                });
            }
        });
        return donations;
    }

    public static CompleteComments(localAOLO: IaOLO): InOrderComment[] | null {
        try {
            const now = Util.NowStore(localAOLO.Temp.TimeOffset);
            const appComment = Util.getElementValue("txt_APP_CHKOUT_Comment").trim();
            const togoDiv = document.getElementById("div_checkout_togo_supplies");
            const comments: InOrderComment[] = [];
            let oComment = Util.getElementValue("txt_checkout_comment").trim() + appComment;

            if (togoDiv && !togoDiv.hidden) {
                const tgsInitComment = "To-Go Utensils: ";
                const chkboxOptions = Array.from(document.getElementsByName("chk_checkout_togo_supply")) as HTMLInputElement[];

                let tgsBodyComment = "";
                for (const checkbox of chkboxOptions) {
                    if (checkbox.checked) {
                        if (tgsBodyComment !== "")
                            tgsBodyComment += ", ";
                        tgsBodyComment += checkbox.dataset.value;
                    }
                }

                if (tgsBodyComment !== "") {
                    if (oComment !== "")
                        oComment += ". ";
                    oComment += tgsInitComment + tgsBodyComment;
                }
            }

            if (oComment !== "") {
                const comment: InOrderComment = {
                    CommentTypeID: 3,
                    CommentTime: Util.formatDateTimeSeconds(now),
                    CommentByID: 0,
                    Comment: oComment
                };
                comments.push(comment);
            }

            let cComment = "";
            if (aOLO.Temp.Contactless)
                cComment = "  CONTACTLESS DELIVERY  ";
            else if (aOLO.Temp.Curbside)
                cComment = "    CURBSIDE PICKUP     ";

            if (cComment !== "") {
                const comment: InOrderComment = {
                    CommentTypeID: 12,
                    CommentTime: Util.formatDateTimeSeconds(now),
                    CommentByID: 0,
                    Comment: cComment
                };
                comments.push(comment);
            }

            if (localAOLO.Temp.Curbside && aOLO.data.Settings.CURBSD == Curbside.CUSTOMER_CAR_INFO) {
                const carComment = Util.getElementValue("txt_checkout_car");
                if (carComment !== "") {
                    const comment: InOrderComment = {
                        CommentTypeID: 13,
                        CommentTime: Util.formatDateTimeSeconds(now),
                        CommentByID: 0,
                        Comment: carComment
                    };
                    comments.push(comment);
                }
            }

            return comments;
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CompleteComments", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return null;
    }

    public static CompleteCoupons(localAOLO: IaOLO): InOrderCoupon[] | null {
        try {
            const coupons: InOrderCoupon[] = [];
            for (const coupon of localAOLO.Order.Coupons) {
                const iCoupon: InOrderCoupon = {
                    CouponKey: coupon.CouponKey,
                    CouponID: coupon.CouponId,
                    CouponCode: coupon.CouponCode,
                    Amount: Util.Float2(coupon.Amount),
                    IsActive: coupon.IsActive,
                    IsAdded: coupon.IsAdded,
                    AddedByID: null,
                    AddedTime: null,
                    IsRemoved: coupon.IsRemoved,
                    RemovedByID: null,
                    RemovedTime: null,
                    ChannelID: coupon.ChannelID,
                    IsCampaignCode: coupon.IsCampaignCode,
                    IsPromotionCode: coupon.IsPromotionCode,
                    IsSingleUseCoupon: (coupon.IsCampaignCode || !!localAOLO.User.Offers?.Codes.find(x => x.CouponCode == coupon.CouponCode.toUpperCase() && x.IsSingleUseCoupon)),
                    RewardId: coupon.RewardId,
                    IsBankedCurrency: coupon.IsBankedCurrency,
                    RedemptionReference: coupon.RedemptionReference,
                    BasketItemId: coupon.BasketItemId
                };
                coupons.push(iCoupon);
            }
            return coupons;
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CompleteCoupons", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return null;
    }

    public static CompleteCustomer(order: IOrder, localAOLO: IaOLO): InOrderCustomer | null {
        try {
            const customer: InOrderCustomer = {
                CustomerID: 0,
                CustomerTypeID: 1,
                Name: Util.getElementValue("txt_checkout_name").trim(),
                LastName: Util.getElementValue("txt_checkout_last_name").trim(),
                Email: Util.getElementValue("txt_checkout_email").trim(),
                ProfileID: 0,
                storeID: Number(aOLO.storeInfo.StoreID),
                PriceGroupID: order.OrderTypeSubType.PriceGroupId,
                CustomerGroupID: aOLO.data.Settings.CGID,
                Phone: Util.cleanNonDigits(Util.getElementValue("txt_checkout_phone")),
                CountryCodeId: Number(Util.getElementValue("dropdown_checkout_country_code"))
            };

            if (localAOLO.User.GU == LoggedInStatus.EXTERNAL || localAOLO.User.GU == LoggedInStatus.LOGGEDIN) {
                customer.CustomerID = aOLO.User.CustomerId;
                customer.Name = localAOLO.User.Name || "";
                customer.LastName = localAOLO.User.LastName || "";
                customer.Email = localAOLO.User.Email || "";
                customer.ProfileID = localAOLO.User.ProfileId;
                customer.storeID = localAOLO.User.StoreId || 0;

                customer.PriceGroupID = order.OrderTypeSubType.PriceGroupId;
                customer.CustomerGroupID = localAOLO.data.Settings.CGID;
                if (aOLO.User.Phone !== "")
                    customer.Phone = Util.cleanNonDigits(localAOLO.User.Phone || "");
                if (aOLO.User.CountryCodeId !== 0)
                    customer.CountryCodeId = localAOLO.User.CountryCodeId || 0;
            }
            return customer;
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CompleteCustomer", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return null;
    }

    public static CompleteAddress(localAOLO: IaOLO): InOrderAddress | null {
        try {
            if (localAOLO.Order.OrderTypeID !== OrderType.DELIVERY || !localAOLO.Temp.Address)
                return null;

            const nAddr: InOrderAddress = JSON.parse(JSON.stringify(localAOLO.Temp.Address));
            nAddr.CustomerID = localAOLO.User.CustomerId || 0;
            nAddr.Address1 = localAOLO.Temp.Address.StreetName;
            nAddr.Address2 = localAOLO.Temp.Address.Address2;
            nAddr.Address3 = localAOLO.Temp.Address.Address3;
            nAddr.Address4 = localAOLO.Temp.Address.Address4;
            nAddr.Address5 = localAOLO.Temp.Address.Address5;
            nAddr.CountryID = localAOLO.Temp.Address.CountryID;
            return nAddr;
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CompleteAddress", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return null;
    }

    public static CompleteItemsModifiersTaxesComments(order: IOrder, localAOLO: IaOLO): InOrderItemInfo | null {
        try {
            const items: InOrderItem[] = [];
            const comments: InOrderItemComment[] = [];
            const modifiers: InOrderItemModifier[] = [];
            const taxes: InOrderItemTax[] = [];

            for (const oItem of order.Items) {

                let price = oItem.Price;
                if (oItem.PriceIncludesTax)
                    price = Util.Float2(price - oItem.Tax);

                const iItem: InOrderItem = {
                    IsNew: true,
                    ItemRecID: oItem.ItemRecId,
                    ItemKey: oItem.ItemKey,
                    HalfIndex: oItem.HalfIndex,
                    ItemID: oItem.ItemId,
                    Name: oItem.Name,
                    SizeID: oItem.SizeId,
                    HalfCount: oItem.HalfCount,
                    Quantity: Util.Float5(oItem.Quantity),
                    DiscountedQuantity: oItem.DiscountedQuantity,
                    Price: price,
                    UnitPrice: oItem.MenuPrice,
                    HalfHalfPrice: oItem.HalfHalfPrice,
                    BeforeTaxDiscount: Util.formatNumber(oItem.BeforeTaxDiscount),
                    AfterTaxDiscount: Util.formatNumber(oItem.AfterTaxDiscount),
                    GuestNo: 0,
                    CheckNo: 0,
                    IsAdded: false,
                    AddedByID: null,
                    AddedTime: null,
                    IsRemoved: false,
                    RemovedByID: null,
                    RemovedTime: null,
                    IsPrinted: false,
                    Tax: oItem.Tax,
                    PriceIncludesTax: oItem.PriceIncludesTax,
                    MenuPriceTax: oItem.MenuPriceTax
                };

                if (iItem.SizeID == null) {
                    const mItem = localAOLO.data.Items[oItem.Index];
                    Util.LogError("online-ordering-util -> CompleteItemsModifiersTaxesComments", new Error("SizeId is null"), localAOLO);
                    DialogCreators.messageBoxOk(`There is a problem with the item: ${Common.GetName(mItem.Names, localAOLO.Temp.languageCode)}. Please edit the item and select a size.`, localAOLO.buttonHoverStyle);
                    throw new Error("online-ordering-util -> CompleteItemsModifiersTaxesComments -> SizeId is null.");
                }

                items.push(iItem);

                if (oItem.Comment !== "")
                    comments.push({
                        TypeID: 2,
                        ItemKey: oItem.ItemKey,
                        HalfIndex: oItem.HalfIndex,
                        ItemRecID: oItem.ItemRecId,
                        Comment: oItem.Comment,
                        CommentTime: Util.NowStore(localAOLO.Temp.TimeOffset),
                        CommentByID: 0
                    });

                if (oItem.Instructions && oItem.Instructions.length > 0) {
                    let inst = "";
                    for (const instruction of oItem.Instructions) {
                        const tmpInstruction = localAOLO.data.Instructions.find(x => x.CommentId === instruction.CommentId);
                        if (tmpInstruction)
                            inst += `${Common.GetNewName(tmpInstruction.Names, localAOLO.Temp.languageCode)}, `;
                    }
                    if (oItem.Comment === '') {
                        comments.push({
                            TypeID: 2,
                            ItemKey: oItem.ItemKey,
                            HalfIndex: oItem.HalfIndex,
                            ItemRecID: oItem.ItemRecId,
                            Comment: inst.slice(0, -2),
                            CommentTime: Util.NowStore(aOLO.Temp.TimeOffset),
                            CommentByID: 0
                        });
                    } else
                        comments[0].Comment += `, ${inst.slice(0, -2)}`;
                }

                const disGrpModCount = [];
                for (const mod of oItem.Modifiers) {
                    let modCnt;
                    if (disGrpModCount[mod.MergedModifierDisplayGroupId]) {
                        modCnt = disGrpModCount[mod.MergedModifierDisplayGroupId];
                    } else {
                        disGrpModCount[mod.MergedModifierDisplayGroupId] = OnlineOrderingUtil.GetModCount(oItem.Modifiers, mod.MergedModifierDisplayGroupId);
                        modCnt = disGrpModCount[mod.MergedModifierDisplayGroupId];
                    }
                    const nMod: InOrderItemModifier = {
                        ItemKey: oItem.ItemKey,
                        HalfIndex: oItem.HalfIndex,
                        ItemRecID: oItem.ItemRecId,
                        ModifierID: mod.ModifierId,
                        Name: mod.Name,
                        PreModifierID: mod.PreModifierId,
                        PriceGroupCount: localAOLO.data.PreModifiers.find(x => x.PreModifierId === mod.PreModifierId)?.PriceCountMultiplier,
                        ModCnt: modCnt,
                        Price: mod.IsDefault ? 0 : mod.Price
                    };
                    modifiers.push(nMod);
                }

                for (const tax of oItem.Taxes) {
                    const nTax: InOrderItemTax = {
                        TaxID: tax.TaxId,
                        ItemKey: oItem.ItemKey,
                        HalfIndex: oItem.HalfIndex,
                        ItemRecID: oItem.ItemRecId,
                        TaxAmount: tax.Amount
                    };
                    taxes.push(nTax);
                }
            }

            return {
                Modifiers: modifiers,
                Comments: comments,
                Items: items,
                Taxes: taxes
            };
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CompleteItemsModifiersTaxesComments", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return null;
    }

    public static CompleteMakeList(order: IOrder, pTime: string, localAOLO: IaOLO): InOrderMakeList[] {
        let makeList: InOrderMakeList[] = [];

        try {
            const printTime = new Date(pTime);
            let extOrderNo = 0;
            if (!order.IsNew) {
                extOrderNo = this.GetLastExtendedOrderNo(localAOLO);
            }
            for (const oItem of order.Items) {
                const mItem = localAOLO.data.Items.find(x => x.ItemId === oItem.ItemId);
                if (!mItem)
                    continue;

                const iMake = this.CheckItemPrintMakeLblCutPrp(mItem, order.OrderTypeSubType.OrderTypeId);
                //if (iMake) {
                const tmpList = this.CreateMakeList(oItem, order.Items, iMake, 1, oItem.Quantity, extOrderNo, printTime, aOLO);
                if (tmpList) {
                    extOrderNo = tmpList.ExtendedOrderNo;
                    makeList = makeList.concat(tmpList.MakeList);
                }
                //}
            }
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CompleteMakeList", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }

        return makeList;
    }

    public static GetLastExtendedOrderNo(localAOLO: IaOLO): number {
        let maxExtendedOrderNo = 0;
        try {
            for (let i = 0; i < localAOLO.Order.ItemsMakeList.length; i++) {
                if (parseInt(localAOLO.Order.ItemsMakeList[i].XONO) > maxExtendedOrderNo)
                    maxExtendedOrderNo = parseInt(localAOLO.Order.ItemsMakeList[i].XONO);
            }
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("GetLastExtendedOrderNo", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return maxExtendedOrderNo;
    }

    public static CheckItemPrintMakeLblCutPrp(mItem: IDataItem, orderTypeID: number): IMake {
        //let isMakeItem = false;
        const iMake: IMake = {
            Prn: false,
            Mak: false,
            Lbl: false,
            Prp: false,
            Cwp: false
        };

        for (const ot of mItem.OrderTypes) {
            if (ot.OrderTypeId == orderTypeID && (ot.Print || ot.Make || ot.Label || ot.CutWrap || ot.Prep)) {
                iMake.Prn = (iMake.Prn === true) ? iMake.Prn : ot.Print;
                iMake.Mak = (iMake.Mak === true) ? iMake.Mak : ot.Make;
                iMake.Lbl = (iMake.Lbl === true) ? iMake.Lbl : ot.Label;
                iMake.Prp = (iMake.Prp === true) ? iMake.Prp : ot.Prep;
                iMake.Cwp = (iMake.Cwp === true) ? iMake.Cwp : ot.CutWrap;
                //isMakeItem = true;
            }
        }

        //return isMakeItem ? iMake : null;
        return iMake;
    }

    public static CreateMakeList(oItem: IOrderItem, oItems: IOrderItem[], iMake: IMake, QTYStart: number, QTYEnd: number, extOrderNo: number, printTime: Date, localAOLO: IaOLO): ICreateMakeList | null {
        try {
            const makeList: InOrderMakeList[] = [];
            const lastItemKey = 0;
            const iExtOrderNo = extOrderNo;

            const itemIds = [... new Set(oItems.filter(x => x.ItemKey == oItem.ItemKey).map(x => x.ItemId))];
            const mItems = localAOLO.data.Items.filter(x => itemIds.includes(x.ItemId));

            let prepTime = 0;
            let makeTime = 0;
            let cookTime = 0;

            for (const mItem of mItems) {
                if (mItem.PrepTime > prepTime)
                    prepTime = mItem.PrepTime;

                if (mItem.MakeTime > makeTime)
                    makeTime = mItem.MakeTime;

                if (mItem.CookTime > cookTime)
                    cookTime = mItem.CookTime;
            }

            for (let i = QTYStart; i <= QTYEnd; i++) {
                if (lastItemKey != oItem.ItemKey)
                    extOrderNo += 1;

                const makeItem: InOrderMakeList = {
                    IsNew: true,
                    ItemKey: oItem.ItemKey,
                    HalfIndex: oItem.HalfIndex,
                    ItemRecID: oItem.ItemRecId,
                    QuantityIndex: i,
                    ExtendedOrderNo: extOrderNo,
                    ItemID: oItem.ItemId,
                    HalfCount: oItem.HalfCount,
                    IsPrinted: false,

                    PrepStartTime: null,
                    PrepEndTime: null,
                    MakeStartTime: null,
                    MakeEndTime: null,
                    CookStartTime: null,
                    CookEndTime: null,

                    IsAdded: false,
                    AddedByID: null,
                    AddedTime: null,
                    IsRemoved: false,
                    RemovedByID: null,
                    RemovedTime: null
                };

                // Prep Station
                let bypassPrepStation = false;
                if (!localAOLO.data.Settings.HPRP || !iMake.Prp) {
                    bypassPrepStation = true;
                    makeItem.PrepStartTime = Util.formatDateTimeSeconds(printTime);
                    makeItem.PrepEndTime = Util.formatDateTimeSeconds(Util.DateAdd(printTime, prepTime, "m"));
                }

                // Make Line
                let bypassMakeLine = false;
                if (bypassPrepStation && (!localAOLO.data.Settings.HML || !localAOLO.data.Settings.HMLM || !iMake.Mak)) {
                    bypassMakeLine = true
                    makeItem.MakeStartTime = Util.formatDateTimeSeconds(Util.DateAdd(printTime, prepTime, "m"));
                    makeItem.MakeEndTime = Util.formatDateTimeSeconds(Util.DateAdd(printTime, prepTime + makeTime, "m"));
                    makeItem.CookStartTime = Util.formatDateTimeSeconds(Util.DateAdd(printTime, prepTime + makeTime, "m"));
                }

                // Cut & Wrap
                if (bypassMakeLine && (!localAOLO.data.Settings.HCW || !localAOLO.data.Settings.HCWM || !iMake.Cwp)) {
                    makeItem.CookEndTime = Util.formatDateTimeSeconds(Util.DateAdd(printTime, prepTime + makeTime + cookTime, "m"));
                }

                makeList.push(makeItem);
            }

            if (oItem.HalfCount > 1 && oItem.HalfCount != oItem.HalfIndex)
                extOrderNo = iExtOrderNo;

            return {
                MakeList: makeList,
                ExtendedOrderNo: extOrderNo
            };
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CreateMakeList", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return null;
    }

    public static CompleteServiceCharges(order: IOrder): InOrderServiceCharge[] {
        const charges: InOrderServiceCharge[] = [];
        for (const oCharge of order.ServiceCharges.Detail) {
            const charge: InOrderServiceCharge = {
                ServiceChargeID: oCharge.ID,
                Amount: oCharge.Amount,
                Overwritten: oCharge.Overwritten,
                TaxAmount: oCharge.Tax
            };
            charges.push(charge);
        }
        return charges;
    }

    public static CompleteItemsCoupons(order: IOrder, localAOLO: IaOLO): IOrderItemCoupon[] | null {
        try {
            const itemCoupons: IOrderItemCoupon[] = [];

            for (const coupon of order.ItemsCoupons) {
                if (!this.IsValidItemKey(order, coupon.ItemKey))
                    continue;

                const itemCoupon: IOrderItemCoupon = {
                    ItemKey: coupon.ItemKey,
                    HalfIndex: coupon.HalfIndex,
                    ItemRecId: coupon.ItemRecId,
                    CouponKey: coupon.CouponKey,
                    QTYIndex: coupon.QTYIndex,
                    BeforeTaxDiscount: Util.Float2(coupon.BeforeTaxDiscount),
                    AfterTaxDiscount: Util.Float2(coupon.AfterTaxDiscount),
                    DiscountedQuantity: coupon.DiscountedQuantity,
                    IsActive: coupon.IsActive,
                    Priority: coupon.Priority
                };
                itemCoupons.push(itemCoupon);
            }
            return itemCoupons;
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CompleteItemsCoupos", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return null;
    }

    public static IsValidItemKey(order: IOrder, itemKey: number): boolean {
        return order.Items.some(oItem => oItem.ItemKey === itemKey);
    }

    public static CompletePayments(order: IOrder, localAOLO: IaOLO): IOrderPayment[] | null {
        try {
            const giftPayments = this.CompleteGiftCardPayments(order, localAOLO);
            let payments: IOrderPayment[] = [];

            let tip = localAOLO.Order.Tip;
            if (tip > 0) {
                const totalGiftCardTips = giftPayments?.reduce((acc, curr) => acc + curr.Gratuity, 0) || 0;
                tip = order.Tip - totalGiftCardTips;
            }

            if (localAOLO.Order.AmountDue > 0) {
                const newPay = OnlineOrderingUtil.GetEmptyPayment();
                newPay.PaymentKey = giftPayments ? giftPayments.length : 0;
                newPay.PaymentTypeID = 1;
                newPay.Amount = localAOLO.Order.AmountDue;
                newPay.TaxAmount = localAOLO.Order.Tax.toFixed(2);
                newPay.Gratuity = tip;
                if (tip > 0) {
                    newPay.GratuityByID = 0;
                    newPay.GratuityTime = Util.formatDateTimeSeconds(Util.NowStore(localAOLO.Temp.TimeOffset));
                }
                newPay.CurrencyAmount = localAOLO.Order.AmountDue;
                newPay.Balance = localAOLO.Order.AmountDue;
                newPay.SaveToken = Util.getElementChecked("chk_checkout_credit_entry_save_token");

                const payInstore = Util.getElementChecked("rdo_checkout_in_store");
                const payCash = Util.getElementChecked("rdo_checkout_cash");
                const payExCredit = Util.getElementChecked("rdo_checkout_existing_credit");
                const payCreditElement = document.getElementById("rdo_checkout_credit") as HTMLInputElement;
                const payCredit = payCreditElement ? payCreditElement.checked : true;
                const payMbCredit = Util.getElementChecked("rdo_checkout_credit_on_delivery");
                // This (and it's uses) goes away after Bahamas fix is no longer needed
                const workaround = (payMbCredit && localAOLO.storeInfo.BrandID === "A5985C38-D485-4240-8C01-931F7186D567");

                newPay.BillingAddress = "";
                newPay.BillingZip = "";

                if (payInstore || workaround) {
                    //do nothing
                } else if (payCash) {
                    newPay.PaymentTypeID = 1; //cash
                    payments.push(newPay);
                } else if (payExCredit && localAOLO.User?.Wallet && localAOLO.User.Wallet.length > 0) {
                    const wallet = localAOLO.User.Wallet[0];
                    newPay.PaymentTypeID = -4; //Ex CC
                    newPay.TransData = this.GetInfo(localAOLO.data.Info, true, wallet.CTKN, wallet.CCL4, wallet.CCTID, wallet.BADR, wallet.BZIP, wallet.EXPMNT, wallet.EXPYR, wallet.MCHSD, localAOLO);
                    newPay.BillingAddress = wallet.BADR;
                    newPay.BillingZip = wallet.BZIP;
                    newPay.ExpMnt = wallet.EXPMNT;
                    newPay.ExpYr = wallet.EXPYR;
                    payments.push(newPay);
                } else if (payCredit) {
                    newPay.PaymentTypeID = 4; //CC
                    if (aOLO.data.Settings.CCI) {
                        newPay.TransData = this.GetInfo(localAOLO.data.Info, false, null, null, null, null, null, null, null, null, localAOLO);
                        newPay.BillingAddress = Util.getElementValue("txt_checkout_credit_entry_billing_address").trim();
                        newPay.BillingZip = Util.getElementValue("txt_checkout_credit_entry_billing_zip").trim();
                        if (!OnlineOrderingUtil.IsHeartland(localAOLO.data.Settings.ISCC, localAOLO.Order.StoreID, localAOLO.data.Settings.HPK)) {
                            newPay.ExpMnt = Util.getElementValue("ddl_checkout_credit_entry_expiration_month");
                            newPay.ExpYr = Util.getElementValue("ddl_checkout_credit_entry_expiration_year");
                        }
                        if (OnlineOrderingUtil.IsOpenPay()) {
                            const customer = {
                                name: Util.getElementValue("txt_checkout_name") ? Util.getElementValue("txt_checkout_name") : aOLO.User.Name,
                                last_name: Util.getElementValue("txt_checkout_last_name") ? Util.getElementValue("txt_checkout_last_name") : aOLO.User.LastName,
                                phone_number: Util.getElementValue("txt_checkout_phone") ? Util.getElementValue("txt_checkout_phone") : aOLO.User.Phone,
                                email: Util.getElementValue("txt_checkout_email") ? Util.getElementValue("txt_checkout_email") : aOLO.User.Email
                            };
                            const merchSpechData = {
                                devSId: aOLO.data.OPDevSId,
                                sourceId: aOLO.data.OPSourceId,
                                currency: "MXN", //TODO: check out how it is done after latest change for MX
                                customer: customer
                            };
                            newPay.MerchantSpecData = JSON.stringify(merchSpechData);
                        }
                        else if (OnlineOrderingUtil.IsO2G()) {
                            let orderDetails: any = [];
                            for (const item of order.Items) {
                                var orderDetail: any = {};
                                orderDetail.Item = OnlineOrderingUtil.getNamByCulture(item.Name, 'en-us');
                                orderDetail.ServerId = "0";
                                orderDetail.AllPrice = item.Price.toFixed(2);
                                orderDetail.Price = item.Price.toFixed(2);
                                orderDetail.Qty = item.Quantity;
                                orderDetail.TaxMv = item.Tax.toFixed(2);
                                orderDetail.Waiter = "0";
                                orderDetails.push(orderDetail);
                            }
                            const mercSpecData = {
                                firstName: Util.getElementValue("txt_checkout_name") ? Util.getElementValue("txt_checkout_name") : aOLO.User.Name,
                                lastName: Util.getElementValue("txt_checkout_last_name") ? Util.getElementValue("txt_checkout_last_name") : aOLO.User.LastName,
                                phoneNumber: Util.getElementValue("txt_checkout_phone") ? Util.getElementValue("txt_checkout_phone") : aOLO.User.Phone,
                                email: Util.getElementValue("txt_checkout_email") ? Util.getElementValue("txt_checkout_email") : aOLO.User.Email,
                                items: orderDetails,
                                tax: order.Tax.toFixed(2),
                            }
                            newPay.MerchantSpecData = JSON.stringify(mercSpecData);
                        }
                    }
                    payments.push(newPay);
                } else if (payMbCredit) {
                    newPay.PaymentTypeID = 33; //mobile CC
                    payments.push(newPay);
                }
            }

            if (giftPayments && giftPayments.length > 0)
                payments = payments.concat(giftPayments);

            return payments;
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CompletePayments", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return null;
    }

    public static GetInfo(fd: string, exCard: boolean, token: string | null, last4: string | null, cctid: number | null, billingAddress: string | null, billingZip: string | null, expmnt: string | null, expyr: string | null, mchspec: string | null, localAOLO: IaOLO): string {
        let iF = "";
        for (let i = 16; i < fd.length - 18; i += 2) {
            iF += String.fromCharCode(parseInt(fd.substring(i, i + 2), 16));
        }
        const newScript = document.createElement("script");
        const inlineScript = document.createTextNode(iF);
        newScript.appendChild(inlineScript);

        const divInfo = document.getElementById("div_infoc");
        if (divInfo)
            divInfo.appendChild(newScript);

        const pData = {
            TKN: "",
            LST4: "",
            BADR: Util.getElementValue("txt_checkout_credit_entry_billing_address").trim(),
            BZIP: Util.getElementValue("txt_checkout_credit_entry_billing_zip").trim(),
            CCTID: "",
            CNO: "",
            EXPMNT: "",
            EXPYR: "",
            CVV: "",
            MCHSPEC: ""
        };

        if (exCard) {
            pData.TKN = token || "";
            pData.LST4 = last4 || "";
            pData.BADR = billingAddress || "";
            pData.BZIP = billingZip || "";
            pData.CCTID = cctid?.toString() || "";
            pData.EXPMNT = expmnt || "";
            pData.EXPYR = expyr || "";
            pData.MCHSPEC = mchspec || "";
            pData.CVV = Util.getElementValue("txt_checkout_existing_credit_cvv");
        } else if (OnlineOrderingUtil.IsHeartland(localAOLO.data.Settings.ISCC, localAOLO.Order.StoreID, localAOLO.data.Settings.HPK)) {
            pData.TKN = localAOLO.Temp.heartlandToken || "";
            pData.LST4 = localAOLO.Temp.heartlandLast4 || "";
        } else {
            pData.CNO = Util.cleanNonDigits(Util.getElementValue("txt_checkout_credit_entry_card_number").trim());
            pData.EXPMNT = Util.getElementValue("ddl_checkout_credit_entry_expiration_month");
            pData.EXPYR = Util.getElementValue("ddl_checkout_credit_entry_expiration_year");
            pData.CVV = Util.getElementValue("txt_checkout_credit_entry_cvv");
        }

        Util.removeChildren("div_infoc");
        return JSON.stringify(pData);
    }

    public static CompleteGiftCardPayments(order: IOrder, localAOLO: IaOLO): IOrderPayment[] | null {
        try {
            const payments = [];
            let totalGiftCardPayment = 0;
            let totalGiftCardTip = 0;

            for (let i = 0; i < localAOLO.Temp.GiftCards.length; i++) {
                const gCard = localAOLO.Temp.GiftCards[i];
                const newPay = OnlineOrderingUtil.GetEmptyPayment();
                newPay.PaymentKey = i;
                newPay.PaymentTypeID = 7;
                newPay.Amount = gCard.appliedAmount;
                newPay.CurrencyAmount = gCard.appliedAmount;
                newPay.AccountID = gCard.cardNo;
                newPay.Balance = gCard.balance - gCard.appliedAmount;
                newPay.PIN = gCard.pin;
                newPay.TaxAmount = '0';
                if (order.Tip > 0) {
                    const tipAppliedToThisCard = Util.Float2(gCard.appliedAmount - order.Total + totalGiftCardPayment + order.Tip - totalGiftCardTip);
                    if (tipAppliedToThisCard > 0) {
                        totalGiftCardTip += tipAppliedToThisCard;
                        newPay.Gratuity = tipAppliedToThisCard;
                        newPay.GratuityByID = 0;
                        newPay.GratuityTime = Util.formatDateTimeSeconds(Util.NowStore(localAOLO.Temp.TimeOffset));
                    }
                }
                totalGiftCardPayment += gCard.appliedAmount;
                payments.push(newPay);
            }
            return payments;
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CompleteGiftCardPayments", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return null;
    }

    public static CompleteTaxes(order: IOrder, localAOLO: IaOLO): ITax[] | null {
        try {
            const taxes = [];
            for (const oTax of order.Taxes) {
                const tax = {
                    TaxTypeID: oTax.TaxTypeId,
                    TaxAmount: oTax.Amount
                } as ITax;
                taxes.push(tax);
            }
            return taxes;
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("CompleteTaxes", ex, localAOLO);

            localAOLO.Temp.IsSubmitting = false;
        }
        return null;
    }

    public static CompleteChallenges(order: IOrder): InOrderChallenge[] | null {
        if (!order.Challenges)
            return null;

        const challenge = order.Challenges[0];
        const challenges: InOrderChallenge[] = [];
        for (const member of challenge.members) {
            if (member.events.length > 0) {
                for (const event of member.events) {
                    challenges.push({
                        challengeId: challenge.challengeId,
                        memberId: member.memberId,
                        eventId: event.eventId,
                        subMember: event.subMember,
                        subMemberId: event.subMemberId,
                        extra: event.extra
                    });
                }
            } else {
                challenges.push({
                    challengeId: challenge.challengeId,
                    memberId: member.memberId,
                    eventId: null,
                    subMember: null,
                    subMemberId: null,
                    extra: null
                });
            }
        }

        return challenges;
    }

    public static setOrderTypeCharge(localAOLO: IaOLO, localAOLOModules: IaOLOModules): void {
        let zoneID = 0;
        let zoneCharge = 0.0;
        if (localAOLO.Order.OrderTypeSubType.OrderTypeCharge > 0 && localAOLO.Temp.Address) {
            zoneID = localAOLO.Temp.Address.ZoneID;
            if (zoneID > 0) {
                const zone = OnlineOrderingUtil.GetZone(zoneID, localAOLO.data.DeliveryZones);
                if (zone) {
                    zoneCharge = Util.Float2(zone.ZoneCharge);
                }
            }
            //@ts-ignore
            OnlineOrderingUtil.GetDeliveryDistanceFromStore(zoneCharge, new google.maps.LatLng(Util.Float12(localAOLO.storeInfo.Latitude), Util.Float12(localAOLO.storeInfo.Longitude)),
                //@ts-ignore
                new google.maps.LatLng(Util.Float12(localAOLO.Temp.Address.Latitude), Util.Float12(localAOLO.Temp.Address.Longitude)), localAOLO, localAOLOModules);
        } else {
            OnlineOrderingUtil.SetOrderDeliveryCharge(zoneCharge, localAOLO, localAOLOModules);
        }
    }

    public static GetZone(zoneID: number, deliveryZones: IDataDeliveryZone[]): IDataDeliveryZone | null {
        return deliveryZones.find(zone => zone.ZoneId == zoneID) || null;
    }

    public static GetDeliveryDistanceFromStore(zoneCharge: number, storeLoc: any, delLoc: any, localAOLO: IaOLO, localAOLOModules: IaOLOModules): void {
        try {
            const request = {
                origin: storeLoc,
                destination: delLoc,
                //@ts-ignore
                travelMode: google.maps.TravelMode.DRIVING,
                //@ts-ignore
                unitSystem: google.maps.UnitSystem.IMPERIAL
            };

            const service = new google.maps.DirectionsService();
            service.route(request, function (response: any, status: any) {
                //@ts-ignore
                if (status == google.maps.DirectionsStatus.OK) {
                    localAOLO.Order.Distance = Util.Float2(response.routes[0].legs[0].distance.value / 1609.34);
                    if (localAOLO.Order.Distance > 999) localAOLO.Order.Distance = 0;
                }
                OnlineOrderingUtil.SetOrderDeliveryCharge(zoneCharge, localAOLO, localAOLOModules);
            });
        } catch (ex: unknown) {
            if (ex instanceof Error)
                Util.LogError("GetDeliveryDistanceFromStore", ex, localAOLO);

            localAOLO.Order.Distance = 0;
        }
    }

    public static SetOrderDeliveryCharge(zoneCharge: number, localAOLO: IaOLO, localAOLOModules: IaOLOModules): void {
        localAOLO.Order.OrderTypeCharge = 0;
        let orderTypeCharge = 0;

        if (localAOLO.data.Settings.CRGMIL > 0 && localAOLO.data.Settings.MDCRGMIL > 0 && localAOLO.Order.Distance > 0 && localAOLO.Order.OrderTypeSubType.OrderTypeCharge > 0) {
            localAOLO.Order.OrderTypeCharge = Util.Float2(Math.max(localAOLO.Order.Distance * localAOLO.data.Settings.CRGMIL, localAOLO.data.Settings.MDCRGMIL)); // (zoneCharge == 0) ? (aOLO.Order.Distance * aOLO.data.Settings.CRGMIL) : zoneCharge;
        } else if (localAOLO.Order.OrderTypeSubType.OrderTypeCharge && localAOLO.Order.OrderTypeSubType.OrderTypeCharge > 0) {
            orderTypeCharge = Util.Float2(localAOLO.Order.OrderTypeSubType.OrderTypeCharge) + zoneCharge;
            if (orderTypeCharge > 0)
                localAOLO.Order.OrderTypeCharge = orderTypeCharge;
        } else {
            orderTypeCharge = zoneCharge;
            if (orderTypeCharge > 0)
                localAOLO.Order.OrderTypeCharge = orderTypeCharge;
        }

        [localAOLO.Order.Taxes, localAOLO.Order.TaxesDetail] = OnlineOrderingUtil.TaxOrder(localAOLO.Order, localAOLO);
        this.GUI_SetOrder_Total(true, localAOLO, localAOLOModules);
    }

    public static updateIncompleteDiscounts(incompleteCoupon: IRemovedCoupon, incompleteDiscounts: ITempIncompleteDiscount[], localAOLOModules: IaOLOModules): void {
        if (incompleteDiscounts.some(cpn => cpn.couponID === incompleteCoupon.couponId))
            return;

        if (incompleteCoupon.offerCode)
            incompleteDiscounts.push({ couponID: incompleteCoupon.couponId, couponCode: incompleteCoupon.offerCode, channelID: null, isCampaignCode: true });
        else
            incompleteDiscounts.push({ couponID: incompleteCoupon.couponId, couponCode: '', channelID: null, rewardId: incompleteCoupon.rewardId });

        localAOLOModules.LoyaltyProvider.setIncompleteReward(incompleteCoupon.rewardId);
    }

    public static addIncompleteCouponToCart(cpnId: number, cpnCode: string, incompleteDiscounts: ITempIncompleteDiscount[], localAOLO: IaOLO, localAOLOModules: IaOLOModules,
        channelId?: number | null, isCampaignCode?: boolean, isPromotionCode?: boolean, rewardId?: number | null, isBankedCurrency?: boolean): void {
        if (!incompleteDiscounts.some(cpn => cpn.couponID === cpnId))
            incompleteDiscounts.push({
                couponID: cpnId,
                couponCode: cpnCode,
                channelID: channelId,
                isCampaignCode: isCampaignCode,
                isPromotionCode: isPromotionCode,
                rewardId: rewardId,
                isBankedCurrency: isBankedCurrency || false
            });
        OnlineOrderingUtil.GUI_SetOrder_Total(true, localAOLO, localAOLOModules);
    }

    public static async reviewRemovedCouponsForOrder(removedCoupons: IRemovedCoupon[], localAOLO: IaOLO, localAOLOModules: IaOLOModules): Promise<IRemovedCoupon[]> {
        const couponsToApplyAfterTotals: IRemovedCoupon[] = []
        for (const removedCoupon of removedCoupons) {
            if (removedCoupon.couponId === 7)
                continue;

            const coupon = localAOLO.Modules.Coupon.GetCoupon(removedCoupon.couponId, true, localAOLO.Order.OrderTypeID, null);
            if (coupon && coupon.Items.length > 0) {
                let result: boolean | null = false;
                if (coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PRICE || coupon.CouponTypeId == CouponTypeList.MIX_AND_MATCH_PERCENTAGE) {
                    const params: IApplyCouponByIdParams = {
                        couponId: removedCoupon.couponId
                    };
                    result = await localAOLO.Modules.Coupon.ApplyCouponByID(params);
                }
                if (coupon.CouponTypeId == CouponTypeList.PERCENTAGE_OFF_ENTIRE_ORDER) {
                    couponsToApplyAfterTotals.push(removedCoupon);
                    continue;
                }
                if (!result) {
                    this.updateIncompleteDiscounts(removedCoupon, localAOLO.Temp.IncompleteDiscounts, aOLOModules);
                }
            } else {
                const params: IApplyCouponByIdParams = {
                    couponId: removedCoupon.couponId,
                    couponCode: removedCoupon.offerCode,
                    rewardId: removedCoupon.rewardId,
                    isBankedCurrency: removedCoupon.isBankedCurrency
                };
                const result = await localAOLO.Modules.Coupon.ApplyCouponByID(params);
                if (!result)
                    this.addIncompleteCouponToCart(removedCoupon.couponId, removedCoupon.offerCode || '', localAOLO.Temp.IncompleteDiscounts, localAOLO, localAOLOModules, null, false, false, removedCoupon.rewardId, removedCoupon.isBankedCurrency);
            }
        }
        return couponsToApplyAfterTotals;
    }

    public static async checkItemsCouponsMeetMinimumOrderAmount(localAOLO: IaOLO, localAOLOModules: IaOLOModules): Promise<void> {
        let removedCoupons: IRemovedCoupon[] = [];
        for (const oCoupon of localAOLO.Order.Coupons) {
            const coupon = localAOLO.Modules.Coupon.GetCoupon(oCoupon.CouponId, true, localAOLO.Order.OrderTypeID, oCoupon.ChannelID);
            if (coupon) {
                const isMinimumOrderAmountOkay = localAOLO.Modules.Coupon.checkMinimumOrderAmount(coupon, true, false);

                if (!isMinimumOrderAmountOkay) {
                    //remove coupon by item coupon key
                    const iCoupon = localAOLO.Order.ItemsCoupons.find(ic => ic.CouponKey == oCoupon.CouponKey);
                    if (iCoupon)
                        removedCoupons = await localAOLO.Modules.Coupon.RemoveCouponByItemKey(iCoupon.ItemKey, true);
                }
            }
        }
        if (removedCoupons.length > 0) {
            await OnlineOrderingUtil.reviewRemovedCouponsForOrder(removedCoupons, localAOLO, localAOLOModules);
            [localAOLO.Order.Taxes, localAOLO.Order.TaxesDetail] = OnlineOrderingUtil.TaxOrder(localAOLO.Order, localAOLO);
            this.GUI_SetOrder(localAOLO, localAOLOModules);
        }
    }

    public static async DeleteItem(iKey: number, localAOLO: IaOLO, localAOLOModules: IaOLOModules): Promise<void> {
        let item;
        let dQTY = 0;
        let i = localAOLO.Order.Items.length;
        let couponsToApplyAfterTotals: IRemovedCoupon[] = [];
        while (i--) {
            const oItem = localAOLO.Order.Items[i];
            if (oItem.ItemKey === iKey) {
                item = JSON.parse(JSON.stringify(oItem));
                dQTY += oItem.DiscountedQuantity + localAOLO.Temp.loyaltyPointCoupons.filter(x => x.itemKey == iKey).length;
                if (oItem.IsRedeemed && localAOLO.Temp.loyaltyPointCoupons.length > 0)
                    await OnlineOrderingUtil.RedeemPoints(null, oItem.ItemKey, localAOLO);
                localAOLO.Order.Items.splice(i, 1);
            }
        }

        if (localAOLO.Modules.DataLayer)
            localAOLO.Modules.DataLayer.remove_from_cart(item);

        if (dQTY > 0 || localAOLO.Order.ItemsCoupons.some(ic => ic.ItemKey == iKey)) {
            const removedCoupons = await localAOLO.Modules.Coupon.RemoveCouponByItemKey(iKey);
            [localAOLO.Order.Taxes, localAOLO.Order.TaxesDetail] = OnlineOrderingUtil.TaxOrder(localAOLO.Order, localAOLO);
            OnlineOrderingUtil.GUI_SetOrder(localAOLO, localAOLOModules);
            couponsToApplyAfterTotals = await OnlineOrderingUtil.reviewRemovedCouponsForOrder(removedCoupons, localAOLO, localAOLOModules);
        }

        [localAOLO.Order.Taxes, localAOLO.Order.TaxesDetail] = OnlineOrderingUtil.TaxOrder(localAOLO.Order, localAOLO);
        OnlineOrderingUtil.GUI_SetOrder(localAOLO, localAOLOModules);

        //Reapply Percent Off entire order coupon after calculating totals
        if (couponsToApplyAfterTotals.length > 0) {
            for (const reapplyCoupon of couponsToApplyAfterTotals) {
                const params: IApplyCouponByIdParams = {
                    couponId: reapplyCoupon.couponId
                };
                const result = await localAOLO.Modules.Coupon.ApplyCouponByID(params);
                if (!result) {
                    OnlineOrderingUtil.updateIncompleteDiscounts(reapplyCoupon, localAOLO.Temp.IncompleteDiscounts, aOLOModules);
                }
            }
        }
        await OnlineOrderingUtil.checkItemsCouponsMeetMinimumOrderAmount(localAOLO, localAOLOModules);
    }

    public static GetoItems(iKey: number, orderItems: IOrderItem[]): IOrderItem[] {
        return orderItems.filter(oItem => oItem.ItemKey === iKey);
    }

    public static Item_PreEditItem(itemKey: number): void {
        const elements = document.getElementsByName("SingleModDG");
        elements.forEach(element => element.remove());

        Util.setElement("value", "txt_customize_item_comment", "");
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const { CustomizeItem } = require('./customize-item');

        aOLO.Dialog.CustomizeItem = new CustomizeItem(aOLO, itemKey, undefined, undefined, undefined, true);
        this.GUI_ItemCommentBoxDisplay(aOLO.data.Settings.OIC);
    }

    public static async ChangeQuantity(itemKey: number, qty: number, localAOLO: IaOLO, localAOLOModules: IaOLOModules): Promise<void> {
        const oItems = OnlineOrderingUtil.GetoItems(itemKey, localAOLO.Order.Items);
        if (oItems.length == 0)
            return;

        for (const oItem of oItems) {
            oItem.Quantity = qty;

            // If item is already redeemed, unredeem item, as process changes
            if (oItem.IsRedeemed) {
                const itemCoupon = localAOLO.Order.ItemsCoupons.find(x => x.ItemKey === itemKey) || null;
                if (itemCoupon)
                    await localAOLO.Modules.Coupon.RemoveCouponByKey(itemCoupon.CouponKey, true);
            }


            const mItem = localAOLO.data.Items.find(x => x.ItemId === oItem.ItemId) || null;
            this.TaxItem(mItem, oItem, localAOLO);
        }

        const removedCouponIDs = await localAOLO.Modules.Coupon.RemoveCouponByItemKey(itemKey, true);
        for (const removedCoupon of removedCouponIDs) {
            const params: IApplyCouponByIdParams = {
                couponId: removedCoupon.couponId
            };
            await localAOLO.Modules.Coupon.ApplyCouponByID(params);
        }

        [localAOLO.Order.Taxes, localAOLO.Order.TaxesDetail] = OnlineOrderingUtil.TaxOrder(localAOLO.Order, localAOLO);
        this.GUI_SetOrder(localAOLO, localAOLOModules);
        await localAOLO.Modules.Coupon.AutoApplyCoupons();
    }

    public static resetTimeFulfillmentTimeExpired(localAOLO: IaOLO, localAOLOModules: IaOLOModules): void {
        DialogCreators.messageBoxOk(Names("FulfillmentTime").replace("???", OnlineOrderingUtil.GetOrderTypeName(localAOLO.Order.OrderTypeID, localAOLO)).replace("??", localAOLO.Order.OrderTypeSubType.WaitTime.toString()), localAOLO.buttonHoverStyle);
        localAOLO.Order.FutureDate = null;
        localAOLO.Order.FutureMinute = -1;
        localAOLO.Order.PrintTime = Util.formatDateTimeSeconds(Util.NowStore(localAOLO.Temp.TimeOffset));
        localAOLO.Order.PromiseTime = Util.formatDateTimeSeconds(Util.DateAdd(Util.NowStore(localAOLO.Temp.TimeOffset), localAOLO.Order.OrderTypeSubType.WaitTime, "m"));
        if (localAOLO.Dialog.OrderType)
            localAOLO.Dialog.OrderType.handleOrderTypeOrderTimeChange(false, false);
        this.GUI_SetOrder(localAOLO, localAOLOModules);
    }

    /**** GUI ****/
    public static GUI_SetOrder(localAOLO: IaOLO, localAOLOModules: IaOLOModules): void {
        Util.removeChildren("div_cart_items");
        const divItems = document.getElementById("div_cart_items");
        if (divItems)
            OnlineOrderingUtil.GUI_Set_None_Group_Order_Get_Items(divItems, true, false, aOLO, aOLOModules);
        OnlineOrderingUtil.GUI_SetOrder_Total(true, localAOLO, localAOLOModules);
    }

    public static GUI_SetOrder_GetOrderCouponIDByCouponKey(key: number, coupons: IOrderCoupon[]): number | null {
        const coupon = coupons.find(cpn => cpn.CouponKey === key);
        return coupon ? coupon.CouponId : null;
    }

    public static GUI_SetOrder_Get_Item_Discount(oItem: IOrderItem, itemCoupons: IOrderItemCoupon[], coupons: IOrderCoupon[]): string | null {
        const coupon = itemCoupons.find(icpn => icpn.ItemKey === oItem.ItemKey);
        if (!!coupon)
            return (this.GUI_SetOrder_GetOrderCouponIDByCouponKey(coupon.CouponKey, coupons) === 7) ? "Redeemed" : "Discounted";
        return null;
    }

    public static GUI_SetOrder_Get_Item_Mods(oItem: IOrderItem, localAOLO: IaOLO): string {
        const listMods: string[] = [];
        for (const mod of oItem.Modifiers) {
            let name = "";
            const modName = Common.GetName(localAOLO.data.Modifiers[mod.Index].Names, localAOLO.Temp.languageCode);
            if (mod.PreModifierId !== localAOLO.Temp.DefPreModID) {
                const preMod = OnlineOrderingUtil.GetPreMod(mod.PreModifierId, localAOLO.data.PreModifiers);
                const pName = preMod ? Common.GetName(preMod.Names, localAOLO.Temp.languageCode) : "";
                name = `<span class="warning" ${(preMod && pName != "") ? `ltagj="${Util.toLtagj(preMod?.Names)}"` : ""}>${pName}</span> `;
            }

            if (mod.PreModifierId !== localAOLO.Temp.DefPreModID || !mod.IsDefault) {
                let preModClass = "";

                if (mod.PreModifierId !== localAOLO.Temp.DefPreModID)
                    preModClass = "modAdjust";
                else if (!mod.IsDefault)
                    preModClass = "modAdd";
                else
                    preModClass = "modDef";

                listMods.push(name + `<span class="${preModClass}" ltagj="${Util.toLtagj(localAOLO.data.Modifiers[mod.Index].Names)}">${modName}</span>`);
            }
        }

        return `${listMods.join(", ")}</div>`;
    }

    public static GUI_SetOrder_Get_Item(oItem: IOrderItem, endLine: boolean, displayMods: boolean, displayPoints: boolean, localAOLO: IaOLO, localAOLOModules: IaOLOModules): HTMLElement {
        const divItem = document.createElement("div");
        let loyaltyPoint = 0;
        if (displayPoints && oItem.DiscountedQuantity > 0)
            return divItem;

        const mItem = localAOLO.data.Items.find(x => x.ItemId === oItem.ItemId);
        if (!mItem)
            return divItem;

        divItem.classList.add("orderItem");

        const itemName = Common.GetName(mItem.Names, localAOLO.Temp.languageCode);

        if (displayPoints)
            loyaltyPoint = this.GetLoyaltyPoints(oItem.ItemId, oItem.SizeId, localAOLO.data.LoyaltyItems) || 0;

        if (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 1)) {
            // Item Name
            let title = "";
            let sizeName = "";

            if ((oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 1)) && oItem.SizeId > 0) {
                const iSize = OnlineOrderingUtil.GetItemSize(mItem, localAOLO.Order.OrderTypeID, oItem.SizeId);
                sizeName = (iSize === null) ? "Unknown" : `<span ltagj="${Util.toLtagj(localAOLO.data.Sizes[iSize.Index].Names)}">${Common.GetName(localAOLO.data.Sizes[iSize.Index].Names, localAOLO.Temp.languageCode)}</span> - `;
            }

            title += sizeName;

            if (oItem.HalfCount > 1 && oItem.HalfIndex === 1)
                title += `<span ltag="HHP">${Names("HHP")}</span>`;
            else
                title += `<span ltagj="${Util.toLtagj(mItem.Names)}">${itemName}</span>`;

            // Price
            const price = (oItem.HalfCount > 1) ? oItem.HalfHalfPrice : oItem.Price;

            divItem.innerHTML += `
                <div class="cart-item-name-price">
                        <div>${title}</div>
                        <div>${Util.formatMoney(price * oItem.Quantity)}</div>
                </div>`;
        }

        if (oItem.HalfCount == 2) {
            const half = (oItem.HalfIndex === 1) ? "FirstHalf" : "SecondHalf";
            divItem.innerHTML += `
                <div class="title3">
                    <span ltag="${half}">${Names(half)}</span>
                </div>
                <div>
                    <span ltagj="${Util.toLtagj(mItem.Names)}">${itemName}</span>
                </div>`;
        }

        // Modifiers
        if (displayMods)
            divItem.innerHTML += this.GUI_SetOrder_Get_Item_Mods(oItem, localAOLO);

        if (oItem.HalfCount == 1 || (oItem.HalfCount == 2 && oItem.HalfIndex == 2)) {
            // Calories
            const calories = this.getItemCalories(mItem, oItem, localAOLO.Order.OrderTypeID, oItem.SizeId, localAOLO);
            divItem.innerHTML += `
                <div class="cart-item-calories">
                    <div ltagj="${Util.toLtagj(calories)}">${Common.GetName(calories, localAOLO.Temp.languageCode)}</div>
                </div>`;

            // Item tax
            for (const tax of oItem.Taxes.filter(x => x.DisplayNextToItem === true)) {
                const taxData = localAOLO.data.Taxes.find(t => t.TaxId === tax.TaxId);
                let taxName = "Store-added tax";
                if (taxData && taxData.OrderTypesExcluded.includes(localAOLO.Order.OrderTypeID)) {
                    continue;
                } else if (tax.TaxTypeName) {
                    const taxTypeNameArray = typeof tax.TaxTypeName === 'string' ? JSON.parse(tax.TaxTypeName) as IName[] : tax.TaxTypeName;
                    taxName = taxTypeNameArray.find((x: { NAM: string; CULT: string; }) => x.CULT.toLowerCase() === localAOLO.Temp.languageCode.toLowerCase())?.NAM || taxName;
                }

                divItem.innerHTML += `
                    <div class="cart-item-calories">
                        <div>${taxName} ${Util.formatMoney(tax.Amount)}</div>
                    </div>`;
            }

            /* Quantity, Edit, Delete */
            let divQtyEditDel = "";

            // Quantity
            let options = "";
            for (let i = 1; i < 21; i++) {
                options += `<option value="${i}" ${(oItem.Quantity === i) ? `selected="true"` : ""}>${i}</option>`;
            }

            divQtyEditDel += `
                <div class="cart-item-quantity">
                    <div>
                        <label for="ddl_cart_item_quantity_${oItem.ItemKey}" ltag="Quantity">${Names("Quantity")}</label>:
                    </div>
                    <select id="ddl_cart_item_quantity_${oItem.ItemKey}" name="ddl_cart_item_quantity" data-iky="${oItem.ItemKey}">${options}</select>
                </div>`;

            // Edit
            if (oItem.Edit) {
                divQtyEditDel += `
                    <div>
                        <button id="btn_cart_item_edit_${oItem.ItemKey}" name="btn_cart_item_edit" class="btn-icon-link" ltag="Edit" data-iky="${oItem.ItemKey}">${Names("Edit")}</button>
                    </div>`;
            }

            // Delete
            divQtyEditDel += `
                <div>
                    <button id="btn_cart_item_remove_${oItem.ItemKey}" name="btn_cart_item_remove" class="btn-icon-link" ltag="Remove" data-iky="${oItem.ItemKey}">${Names("Remove")}</button>
                </div>`;

            divItem.innerHTML += `<div class="cart-item-quantity-edit-remove">${divQtyEditDel}</div>`;

            // Points
            if (localAOLOModules.LoyaltyProvider.hasPointRedemption() && localAOLO.User?.LoyaltyData) {
                const points = this.GetLoyaltyPoints(oItem.ItemId, oItem.SizeId, localAOLO.data.LoyaltyItems);
                if (points) {
                    let innerText = "";
                    const hidden = (oItem.IsRedeemed || points > (localAOLO.User.LoyaltyData?.CurrentPoints || 0));
                    if (!hidden) {
                        if (oItem.Quantity > 1)
                            innerText = (points) ? `<span ltag="Redeem">${Names("Redeem")}</span>&nbsp1&nbsp<span ltag="For">${Names("For")}</span>&nbsp${points}&nbsp<span ltag="Points">${Names("Points")}</span>` : "";
                        else
                            innerText = (points) ? `<span ltag="RedeemFor">${Names("RedeemFor")}</span>&nbsp${points}&nbsp<span ltag="Points">${Names("Points")}</span>` : "";

                        const btnPnts =
                            `<button id="btn_cart_item_redeem_${oItem.ItemKey}" name="btn_cart_item_redeem" class="${localAOLO.buttonHoverStyle} cart-item-redeem-btn" data-pts="${points}" data-iky="${oItem.ItemKey}">${innerText}</button>`;

                        divItem.innerHTML += btnPnts;
                    }
                }
            }
        }

        // Instructions 
        if (oItem.Instructions && oItem.Instructions.length > 0) {
            let instructionText = "";
            for (const inst of oItem.Instructions) {
                const instruction = localAOLO.data.Instructions.find(x => x.CommentId === inst.CommentId);
                if (instruction)
                    instructionText += `${Common.GetNewName(instruction.Names, localAOLO.Temp.languageCode)}   `;
            }

            divItem.innerHTML += `<div class="cart-item-row">${instructionText}</div>`;
        }

        const discRedim = this.GUI_SetOrder_Get_Item_Discount(oItem, localAOLO.Order.ItemsCoupons, localAOLO.Order.Coupons);
        if (discRedim)
            divItem.innerHTML += `<div class="cart-item-row" ltag="${discRedim}">${Names(discRedim)}</div>`;

        if (oItem.Comment !== "" && (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 2)))
            divItem.innerHTML += `<div class="cart-item-row">${oItem.Comment}</div>`;

        if (displayPoints && (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 2)))
            divItem.innerHTML += `<div style="text-align: center;">${loyaltyPoint} Points required</div>`;

        if (endLine)
            divItem.innerHTML += `<hr class="lineThemeDot75 m2-tb">`;

        return divItem;
    }

    public static GUI_Set_None_Group_Order_Get_Items(divItems: HTMLElement, displayMods: boolean, displayPoints: boolean, localAOLO: IaOLO, localAOLOModules: IaOLOModules) {
        if (localAOLO.Order.Items.length > 0)
            divItems.appendChild(Util.createHtmlElementFromTemplate(`<hr class="lineThemeDot75 m2-tb">`));
        let itemCount = 0;
        for (const [index, oItem] of localAOLO.Order.Items.entries()) {
            const endLine = (index < localAOLO.Order.Items.length - 1 && (oItem.HalfCount === 1 || (oItem.HalfCount === 2 && oItem.HalfIndex === 2)));
            divItems.appendChild(this.GUI_SetOrder_Get_Item(oItem, endLine, displayMods, displayPoints, localAOLO, localAOLOModules));

            if (oItem.HalfIndex === 0 || oItem.HalfIndex === 1)
                itemCount += oItem.Quantity;
        }
        this.GUI_Mobile_Set_Card_Number(itemCount);

        (Array.from(document.getElementsByName("ddl_cart_item_quantity")) as HTMLSelectElement[]).forEach(x => x.onchange = () => {
            const qty = parseInt(x.value);
            this.ChangeQuantity(Number(x.dataset.iky), qty, localAOLO, localAOLOModules);
        });

        document.getElementsByName("btn_cart_item_edit").forEach(x => x.onclick = () => {
            this.Item_PreEditItem(Number(x.dataset.iky));
        });

        document.getElementsByName("btn_cart_item_remove").forEach(x => x.onclick = () => {
            this.DeleteItem(Number(x.dataset.iky), localAOLO, localAOLOModules);
        });

        document.getElementsByName("btn_cart_item_redeem").forEach(x => x.onclick = async () => {
            const item = this.GetItemByItemKey(Number(x.dataset.iky), localAOLO.Order.Items);
            if (!item) {
                return;
            }
            const points = this.GetLoyaltyPoints(item.ItemId, item.SizeId, localAOLO.data.LoyaltyItems);
            await this.RedeemPoints(points, Number(x.dataset.iky), localAOLO);
        });
    }

    public static GUI_Mobile_Set_Card_Number(itemCount: number): void {
        const cardNo = document.getElementById("txt_header_cart_number");
        if (!cardNo)
            return;

        cardNo.textContent = itemCount.toString();
        if (itemCount > 99) {
            cardNo.setAttribute("transform", "matrix(1 0 0 1 160 180)");
            cardNo.style.fontSize = "var(--hedCartFontSizeXXX)";
        } else if (itemCount > 9) {
            cardNo.setAttribute("transform", "matrix(1 0 0 1 180 200)");
            cardNo.style.fontSize = "var(--hedCartFontSize)";
        } else {
            cardNo.setAttribute("transform", "matrix(1 0 0 1 230 200)");
        }
    }

    public static GUI_ItemCommentBoxDisplay(oic: boolean): void {
        if (oic) //setting 75
            Util.hideElement("div_customize_item_comment");
        else
            Util.showElement("div_customize_item_comment");
    }


    public static GUI_SetOrderHubPayment_Total(update_CHKOUT_Tip: boolean, localAOLO: IaOLO): void {
        this.CalculateOrderHubPaymentTotals(localAOLO);
        if (update_CHKOUT_Tip) {
            Util.setElement("value", "txt_checkout_tip", Util.formatNumber(localAOLO.Order.Tip));
        }

        Util.setElement("innerText", "spn_checkout_tip_total", Util.formatNumber(localAOLO.Order.AmountDue + localAOLO.Order.Tip));
        Util.setElement("innerText", "div_APP_CHKOUT_Total", Util.formatNumber(localAOLO.Order.AmountDue + localAOLO.Order.Tip));
        Util.setElement("innerText", "div_checkout_amount_due", Util.formatMoney(localAOLO.Order.AmountDue + localAOLO.Order.Tip));
    }

    public static GUI_SetOrder_Total(update_CHKOUT_Tip: boolean, localAOLO: IaOLO, localAOLOModules: IaOLOModules): void {
        this.CalculateTotals(localAOLO);
        let nextToItemTaxTotal = 0.00;
        //let endOfOrdertaxTotal = 0.00;
        for (const item of localAOLO.Order.Items) {
            for (const tax of item.Taxes) {
                if (tax.DisplayNextToItem)
                    nextToItemTaxTotal += tax.Amount;
            }
        }

        Util.removeChildren("div_cart_totals");

        const divTotals = document.getElementById("div_cart_totals");
        if (divTotals) {
            divTotals.appendChild(this.GUI_Totals_Display_Pair("Subtotal", Util.formatMoney(localAOLO.Order.SubTotal + nextToItemTaxTotal - localAOLO.Order.TaxInSubTotal), localAOLO.Temp.languageCode));

            if (localAOLO.Order.Discount > 0 || (localAOLO.Temp.IncompleteDiscounts && localAOLO.Temp.IncompleteDiscounts.length > 0)) {
                const div = document.createElement("div");
                div.id = "div_cart_discounts_block";
                div.classList.add("title3", "m1");
                div.style.fontSize = "smaller";

                const span = document.createElement("span");
                span.setAttribute("ltag", "Discounts");
                span.innerText = Names("Discounts");
                div.appendChild(span);

                for (let i = 0; i < localAOLO.Order.Coupons.length; i++) {
                    const cpn = localAOLO.Order.Coupons[i];
                    const mCpn = localAOLO.Modules.Coupon.GetCoupon(cpn.CouponId, false, null, cpn.ChannelID);

                    const tRemoveDiscount = document.createElement("button");
                    tRemoveDiscount.classList.add("btnIcon", "icon-cancel");
                    tRemoveDiscount.setAttribute("aria-label", "remove");
                    tRemoveDiscount.setAttribute("title", "Remove");
                    tRemoveDiscount.setAttribute("name", "btn_order_cart_coupon_remove");
                    tRemoveDiscount.dataset.couponkey = cpn.CouponKey.toString();
                    tRemoveDiscount.style.fontSize = "small";

                    const tNameDiscount = document.createElement("div");
                    tNameDiscount.style.fontSize = "smaller";
                    if (mCpn) {
                        tNameDiscount.setAttribute("ltagj", Util.toLtagj(mCpn.Names));
                        tNameDiscount.innerText = Common.GetName(mCpn.Names, localAOLO.Temp.languageCode);
                    }

                    const tSpanDiscount = document.createElement("span");
                    tSpanDiscount.classList.add("hidden_label");
                    tSpanDiscount.innerText = Names("Remove");

                    const tValueDiscount = document.createElement("div");
                    tValueDiscount.style.fontSize = "smaller";
                    tValueDiscount.innerText = `$(${Util.formatNumber(cpn.Amount)})`;

                    const tGroupDiscount = document.createElement("div");
                    tGroupDiscount.classList.add("totalLineCoupon");
                    tGroupDiscount.appendChild(tNameDiscount);
                    tGroupDiscount.appendChild(tValueDiscount);

                    tRemoveDiscount.appendChild(tSpanDiscount);
                    tGroupDiscount.appendChild(tRemoveDiscount);

                    const tParentDiscount = document.createElement("div");
                    tParentDiscount.appendChild(tGroupDiscount);

                    const hLine = document.createElement("hr");
                    hLine.classList.add("orderItemLine");
                    hLine.setAttribute("noshade", "true");
                    tParentDiscount.appendChild(hLine);
                    div.appendChild(tParentDiscount);
                }
                divTotals.appendChild(div);

                if (localAOLO.Modules.OrderCart)
                    localAOLO.Modules.OrderCart.showIncompleteDiscounts(localAOLO.Temp.IncompleteDiscounts, localAOLO.Order.OrderTypeID, localAOLO.buttonHoverStyle);

                const removeButtons = Array.from(document.getElementsByName("btn_order_cart_coupon_remove"));
                for (const button of removeButtons) {
                    button.onclick = async function () {
                        const cpnKey = Number(button.dataset.couponkey);
                        const coupon = localAOLO.Order.Coupons.find(x => x.CouponKey == cpnKey);
                        if (!coupon)
                            return;

                        // Check if coupon is reward
                        if (localAOLO.User.LoyaltyData && localAOLO.User.LoyaltyData?.Rewards.length > 0) {
                            const reward = localAOLO.User.LoyaltyData.Rewards.find(x => x.RewardId == coupon.RewardId);
                            if (reward) {
                                await localAOLOModules.LoyaltyProvider.removeReward(reward);
                                return;
                            }
                        }

                        // Check if coupon is banked currency
                        if (coupon.IsBankedCurrency) {
                            await localAOLOModules.LoyaltyProvider.removeBankedCurrency();
                            return;
                        }

                        // Check if coupon is an offer
                        if (localAOLO.User.Offers?.Codes && localAOLO.User.Offers.Codes.length > 0) {
                            const offer = localAOLO.User.Offers.Codes.find(x => x.CouponCode.toLowerCase() == coupon.CouponCode);
                            if (offer)
                                offer.Used = false;
                        }

                        await localAOLO.Modules.Coupon.RemoveCouponByKey(cpnKey, true);
                    };
                }
            }

            if (localAOLO.Order.OrderTypeCharge > 0)
                divTotals.appendChild(this.GUI_Totals_Display_Pair("DeliveryCharge", Util.formatMoney(localAOLO.Order.OrderTypeCharge), localAOLO.Temp.languageCode));

            if (localAOLO.Order.ServiceCharges.Amount > 0) {
                for (let i = 0; i < localAOLO.Order.ServiceCharges.Detail.length; i++) {
                    const serChrg = localAOLO.Order.ServiceCharges.Detail[i];
                    divTotals.appendChild(this.GUI_Totals_Display_Pair(serChrg.Names, Util.formatMoney(serChrg.Amount), localAOLO.Temp.languageCode, false));
                }
            }

            if (localAOLO.Order.Discount > 0)
                divTotals.appendChild(this.GUI_Totals_Display_Pair("DiscountedTotal", Util.formatMoney(localAOLO.Order.SubTotal - localAOLO.Order.Discount + localAOLO.Order.OrderTypeCharge + localAOLO.Order.ServiceCharges.Amount - localAOLO.Order.TaxInSubTotal), aOLO.Temp.languageCode));

            //if (endOfOrdertaxTotal > 0)
            //    divTotals.appendChild(this.GUI_Totals_Display_Pair("Tax", formatMoney(endOfOrdertaxTotal)));
            for (const tax of localAOLO.Order.TaxesDetail) {
                if (tax.TaxTypeName) {
                    const taxName: string = (tax.TaxTypeName.find(x => x.CULT === localAOLO.Temp.languageCode)?.NAM || "Sales Tax");
                    divTotals.appendChild(this.GUI_Tax_Totals_Display_Pair(taxName, Util.formatMoney(tax.Amount)));
                }
            }

            if (localAOLO.Order.Tip > 0)
                divTotals.appendChild(this.GUI_Totals_Display_Pair("Tip", Util.formatMoney(localAOLO.Order.Tip), localAOLO.Temp.languageCode));

            if (localAOLO.Order.TotalDonation > 0)
                divTotals.appendChild(this.GUI_Totals_Display_Pair("Donation", Util.formatMoney(localAOLO.Order.TotalDonation), localAOLO.Temp.languageCode));

            const totalAmount = this.getOrderTotal(aOLO.Order);
            const total = this.GUI_Totals_Display_Pair("OrderTotal", Util.formatMoney(totalAmount), localAOLO.Temp.languageCode);
            if (localAOLO.Temp.GiftCards.length === 0)
                total.classList.add("p-0", "title2");
            divTotals.appendChild(total);

            if (localAOLO.Temp.GiftCards.length > 0) {
                for (const giftCard of localAOLO.Temp.GiftCards) {
                    divTotals.appendChild(this.GUI_Totals_Display_Pair("GiftCard", `$(${Util.formatNumber(giftCard.appliedAmount)})`, aOLO.Temp.languageCode));
                }

                const due = this.GUI_Totals_Display_Pair("Due", Util.formatMoney(localAOLO.Order.AmountDue), localAOLO.Temp.languageCode);
                due.classList.add("p-0", "title2");
                divTotals.appendChild(due);
            }
        }

        this.GUI_Giftcard_Display_Values(localAOLO.Temp.GiftCards);
        this.GUI_CHKOUT_Display_RoundUp_Donation(localAOLO);

        if (update_CHKOUT_Tip) {
            Util.setElement("value", "txt_checkout_tip", Util.formatNumber(localAOLO.Order.Tip));
            Util.setElement("value", "txt_APP_CHKOUT_Tip", Util.formatNumber(localAOLO.Order.Tip));
        }

        Util.setElement("innerText", "spn_checkout_tip_total", Util.formatNumber(localAOLO.Order.Total - localAOLO.Order.TotalDonation));
        Util.setElement("innerText", "div_APP_CHKOUT_Total", Util.formatNumber(localAOLO.Order.Total));
        Util.setElement("innerText", "div_checkout_amount_due", Util.formatMoney(localAOLO.Order.AmountDue));
    }

    public static GUI_Totals_Display_Pair(name: string | { CULT: string, NAM: string }[], value: string, languageCode: string, ltag: boolean = true): HTMLElement {
        const group = document.createElement("div");
        group.classList.add("totalLineParaValue");

        const gName = document.createElement("div");
        if (ltag && typeof name == "string") {
            gName.innerText = Names(name);
            gName.setAttribute("ltag", name);
        } else if (typeof name != "string")
            gName.innerText = Common.GetName(name, aOLO.Temp.languageCode);
        group.appendChild(gName);

        const gValue = document.createElement("div");
        gValue.innerText = value;
        group.appendChild(gValue);
        return group;
    }

    public static GUI_Tax_Totals_Display_Pair(name: string, value: string): HTMLElement {
        const group = document.createElement("div");
        group.classList.add("totalLineParaValue");

        const gName = document.createElement("div");
        gName.innerText = name;
        gName.setAttribute("ltag", name);
        group.appendChild(gName);

        const gValue = document.createElement("div");
        gValue.innerText = value;
        group.appendChild(gValue);
        return group;
    }

    public static GUI_Giftcard_Display_Values(giftCards: ITempGiftCard[]): void {
        for (const gCard of giftCards) {
            Util.setElement("innerText", `div_checkout_gift_applied_amount_${gCard.randomId}`, Util.formatMoney(gCard.appliedAmount));
            Util.setElement("innerText", `div_checkout_gift_end_balance_${gCard.randomId}`, Util.formatMoney(gCard.balance - gCard.appliedAmount));
        }
    }

    public static GUI_CHKOUT_Display_RoundUp_Donation(localAOLO: IaOLO): void {
        if (!localAOLO.data || localAOLO.data.Donations.length === 0)
            return;

        for (const donation of localAOLO.data.Donations) {
            const noDonationOrder = OnlineOrderingUtil.calcOrderTotal(localAOLO.Order.SubTotal, localAOLO.Order.Discount, localAOLO.Order.OrderTypeCharge, localAOLO.Order.CollectedTax, localAOLO.Order.Tip, localAOLO.Order.ServiceCharges.Amount, localAOLO.Order.TotalDonation, localAOLO.Order.TaxInSubTotal);
            const donationRoundUp = Math.ceil(noDonationOrder) - noDonationOrder;
            if (donation.IsRounded && donationRoundUp > donation.Threshold)
                Util.showElement(`lbl_checkout_donation_round_up_${donation.DonationId}`);
            else {
                Util.hideElement(`lbl_checkout_donation_round_up_${donation.DonationId}`);
                const roundUpChecked = Util.getElementChecked(`rdo_checkout_donation_round_up_${donation.DonationId}`);
                if (roundUpChecked)
                    Util.setElement("checked", `rdo_checkout_donation_no_thanks_${donation.DonationId}`, true);
            }
        }
    }

    public static getOrderTotal(order: IOrder): number {
        let taxes = 0;
        order.Taxes.forEach(x => { taxes = taxes + Util.Float2(x.Amount) });
        return OnlineOrderingUtil.calcOrderTotal(order.SubTotal, order.Discount, order.OrderTypeCharge, taxes, order.Tip, order.ServiceCharges.Amount, order.TotalDonation, order.TaxInSubTotal);
    }
}