import { AdoraHttpClient } from '../utils/http-client';
import { IaOLO } from '../interfaces/aolo.interface';
import { IUser } from './interfaces/user.interface';
import { IaOLOUser } from '../services/api/interfaces/profile-service.interfaces';
import { ILoyaltyProvider } from '../services/loyalty/interfaces/loyalty-provider.interface';
import { ProfileService } from '../services/api/profile-service';

/**
 * Represents a user's profile with favorite stores, loyalty info and login status.
 */
export class User {
    private _user: IUser | null = null;
    private readonly _profileService: ProfileService;
    private readonly _loyaltyProvider: ILoyaltyProvider;
    private readonly _aOLO: IaOLO;
    /**
     * @private
     * @property {boolean} _loggedIn - A boolean value indicating whether the user is logged in or not.
     */
    private _loggedIn: boolean = false;

    constructor(profileService: ProfileService, loyaltyProvider: ILoyaltyProvider, aOLO: IaOLO) {
        this._profileService = profileService;
        this._loyaltyProvider = loyaltyProvider;
        this._aOLO = aOLO;
    }

    get user(): IUser | null {
        return this._user;
    }

    set user(newUser: IUser | null) {
        this._user = newUser;
    }

    getProperty<K extends keyof IUser>(property: K): IUser[K] | null {
        if (!this._user)
            return null;

        return this._user[property];
    }

    updateUser(updates: Partial<IUser>): void {
        if (!this._user)
            return;

        this._user = { ...this._user, ...updates };
    }

    /**
     * Logs out the user and clears any saved login state.
     * @async
     * @returns {Promise<boolean>} True if the user was successfully logged out.
     */
    public logout = async (): Promise<boolean> => {
        const result = await this._profileService.signOut(this._aOLO);
        if (result) {
            this._loggedIn = false;
            localStorage.removeItem("tk");
            localStorage.removeItem("rtk");
        }
        return result;
    }

    /**
     * Checks whether the user is currently logged in.
     * @returns {boolean} True if the user is logged in, false otherwise.
     */
    public isLoggedIn = (): boolean => {
        return this._loggedIn;
    }

    /**
    * Logs in the user and gets their favorite stores.
    * @async
    * @param {string} [jwt] - Optional JWT token to set in local storage.
    * @returns {Promise<void>} A promise that resolves when the user is logged in and their favorite stores are fetched.
    */
    public signIn = async (jwt?: string, refreshToken?: string): Promise<void> => {
        this._loggedIn = true;
        if (typeof jwt === 'string')
            AdoraHttpClient.setJWT(jwt);
        if (typeof refreshToken === 'string')
            AdoraHttpClient.setRefreshToken(refreshToken);

        //this.getFavoriteStores();

        //if (aOLO.Dialog.Locations)
        //    aOLO.Dialog.Locations.listStores(this._favoriteStores);

        await this._loyaltyProvider.getStartupInfo();
    }

    /**
     * Saves a profile object to the API and invokes a callback function with a boolean indicating success or failure.
     * @async
     * @param {IaOLOUser} profile - The profile object to be saved.
     * @param {Function} callBack - Callback function to be invoked with a boolean indicating success or failure.
     * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating success or failure.
     */
    public saveProfile = async (profile: IaOLOUser, callBack: Function, incompleteProfile: boolean = false): Promise<boolean> => {
        let saved = false;
        if (incompleteProfile)
            saved = await this._profileService.saveIncompleteProfile(profile, this._aOLO);
        else
            saved = await this._profileService.setProfile(profile, this._aOLO);

        if (callBack)
            callBack(saved);
        return saved;
    }

    /**
     * Deleteds a profile object to the API and invokes a callback function with a boolean indicating success or failure.
     * @async
     * @param {IaOLOUser} profile - The profile object to be saved.
     * @param {Function} callBack - Callback function to be invoked with a boolean indicating success or failure.
     * @returns {Promise<boolean>} - A promise that resolves with a boolean indicating success or failure.
     */
    public deleteProfile = async (profileId: number, password: string, callBack: Function | null): Promise<boolean> => {
        let deleted = await this._profileService.deleteProfile(profileId, password, this._aOLO);
        if (callBack)
            callBack(deleted);
        return deleted;
    }

    public updateUserDarkMode = (darkMode: boolean): void => {
        this.updateUser({ IsDarkMode: darkMode });
        this._profileService.updateUserDarkMode(darkMode, this._aOLO);
    }

    public updateUserLanguage = (language: string): void => {
        this.updateUser({ CultureCode: language });
        this._profileService.updateUserLanguage(language, this._aOLO);
        if (this._aOLO.Dialog.Profile)
            this._aOLO.Dialog.Profile.UpdateLanguage(language);
    }

    /**************************
     **** Favorite Stores *****
     **************************/

    /**
     * Saves a favorite store with the given storeKey and name.
     * @async
     * @param {string} storeKey - The key of the store to be saved.
     * @param {string} name - The name of the store to be saved.
     * @param {boolean} isDefaultStore - A boolean indicating whether the store is the user's default store.
     * @returns {Promise<boolean>} A Promise that resolves to a boolean indicating whether the store was saved successfully.
     */
    public saveFavoriteStore = async (storeKey: string): Promise<boolean> => {
        const saved = await this._profileService.saveFavoriteStore(storeKey, this._aOLO);
        if (saved && this.user)
            this.user.FavoriteStores.push(storeKey);
        return saved;
    }

    /**
     * Deletes a favorite store with the given storeKey.
     * @async
     * @param {string} storeKey - The key of the store to be deleted.
     * @returns {Promise<boolean>} A Promise that resolves to a boolean indicating whether the store was deleted successfully.
     */
    public deleteFavoriteStore = async (storeKey: string): Promise<boolean> => {
        const deleted = await this._profileService.deleteFavoriteStore(storeKey, this._aOLO);
        if (deleted && this._user)
            this._user.FavoriteStores = this._user.FavoriteStores.filter(x => x !== storeKey) || [];
        return deleted;
    }
}