import { Common } from "../common";
import { DialogCreators } from "../utils/dialog-creators";
import { CardBrand, Curbside, FieldType, LoggedInStatus, OrderType, PaymentType, CardOnFileCvvOption, CreditCardAvsOption } from "../types/enums";
import { Names } from "../utils/i18n";
import { IOrder } from "../interfaces/order.interface";
import { IPaymentOptions } from "../interfaces/data.interface";
import { Util } from "../utils/util";
import { IGetNotificationSettings } from "./interfaces/checkout.interface";
import { IServiceSaveOrder } from "./interfaces/online-ordering-service.interface";
import { OnlineOrderingUtil } from "./online-ordering-util";
import { OrderTypeDialog } from "./order-type";

export function OpenPaySubmit() {
    if (aOLO.Order.PaymentType === PaymentType.PAY_IN_STORE) {
        Submit(false);
    } else {
        GetDeviceSourceId();
        GetSessionId();
        aOLO.Temp.IsSubmitting;
    }
}

function GetDeviceSourceId() {
    //@ts-ignore
    OpenPay.setId(aOLO.data.OpenPayMerchant.id);
    //@ts-ignore
    OpenPay.setApiKey(aOLO.data.OpenPayMerchant.pKey);
    //@ts-ignore
    aOLO.data.OPDevSId = OpenPay.deviceData.setup("payment-form");
}

function GetSessionId() {
    //@ts-ignore
    OpenPay.setId(aOLO.data.OpenPayMerchant.id);
    //@ts-ignore
    OpenPay.setApiKey(aOLO.data.OpenPayMerchant.pKey);
    //@ts-ignore
    OpenPay.setSandboxMode(aOLO.developmentMode);
    //@ts-ignore
    OpenPay.token.extractFormAndCreate('payment-form', SuccessCallback, ErrorCallback);
}



const SuccessCallback = (response: any) => {
    aOLO.data.OPSourceId = response.data.id;
    Submit(false);
}

function ErrorCallback(response: any) {
    const desc = response.data.description != undefined ? response.data.description : response.message;
    DialogCreators.messageBoxOk(desc, aOLO.buttonHoverStyle);
}
export async function Submit(heartlandSubmit: boolean, orderHubPayment: boolean = false): Promise<void> {
    if (aOLO.Temp.IsSubmitting && !heartlandSubmit)
        return;

    aOLO.Temp.IsSubmitting = true;
    try {

        const ccChecked = Util.getElementChecked("rdo_checkout_credit") || orderHubPayment;
        if (OnlineOrderingUtil.IsHeartland(aOLO.data.Settings.ISCC, aOLO.Order.StoreID, aOLO.data.Settings.HPK) && ccChecked && !aOLO.Temp.heartlandToken) {
            const pkey = getHeartlandPublicKey();
            aOLO.Temp.hps.Messages.post(
                {
                    accumulateData: true,
                    action: 'tokenize',
                    message: pkey
                },
                'cardNumber'
            );
            return;
        }

        if (await CheckSubmitData(orderHubPayment)) {
            await saveOrder(aOLO.Order, orderHubPayment);
        } else {
            aOLO.Temp.IsSubmitting = false;
        }
    } catch (ex: unknown) {
        if (ex instanceof Error)
            Util.LogError("Submit", ex, aOLO);

        aOLO.Temp.IsSubmitting = false;
    }
}

export function GUI_CHEOUT_Payment(orderHubPayment: boolean = false): void {
    const creditChecked = Util.getElementChecked("rdo_checkout_credit");
    const creditOnDelChecked = Util.getElementChecked("rdo_checkout_credit_on_delivery");
    const exCreditChecked = Util.getElementChecked("rdo_checkout_existing_credit");
    const cashChecked = Util.getElementChecked("rdo_checkout_cash");
    Util.hideElement("lbl_checkout_credit_expect_call");
    Util.setElementClass("remove", "div_checkout_existing_credit_cvv", "accordion-open");
    Util.setElementClass("remove", "div_checkout_credit_entry", "accordion-open");
    const payOptions: IPaymentOptions | undefined = aOLO.data.Settings.OPO.find(x => x.OrderTypeID == aOLO.Order.OrderTypeID);// ? aOLO.data.Settings.OPO[aOLO.Order.OrderTypeID] : [];
    if (creditChecked) {
        aOLO.Order.PaymentType = PaymentType.CREDIT_CARD;
        Util.setElementClass("add", "div_checkout_credit_entry", "accordion-open");
        if (aOLO.data.Settings.CCI && OnlineOrderingUtil.IsHeartland(aOLO.data.Settings.ISCC, aOLO.Order.StoreID, aOLO.data.Settings.HPK)) {
            if (aOLO.Temp.HeartlandSet && !aOLO.Temp.hps)
                resetHeartland(orderHubPayment);
            else
                setHeartland(orderHubPayment);
        }
    } else if (exCreditChecked) {
        aOLO.Order.PaymentType = PaymentType.WALLET_CREDIT_CARD;
        
        if (payOptions)
            if (payOptions.CardOnFileCvvOption == CardOnFileCvvOption.MUST_MATCH ||
                (payOptions.CardOnFileCvvOption == CardOnFileCvvOption.MUST_MATCH_OVER_THERSHOLD && payOptions.PayInStoreLimit < aOLO.Order.AmountDue))
                Util.setElementClass("add", "div_checkout_existing_credit_cvv", "accordion-open");


        // This entire clause goes away after Bahamas fix is no longer needed
    } else if (creditOnDelChecked && aOLO.storeInfo?.BrandID === "A5985C38-D485-4240-8C01-931F7186D567" && !aOLO.data.Settings.CCI) {
        aOLO.Order.PaymentType = PaymentType.CREDIT_CARD_MOBILE;
        Util.showElement("lbl_checkout_credit_expect_call");
    } else if (cashChecked && payOptions && aOLO.Order.SubTotal > payOptions.CashLimit) {
        aOLO.Order.PaymentType = PaymentType.CASH;
        DialogCreators.messageBoxOk(Names("CashLimit").replace("??", "$" + Util.Float2(payOptions.CashLimit)), aOLO.buttonHoverStyle);
        Util.setElement("checked", "rdo_checkout_cash", false);
        Util.setElementClass("remove", "div_checkout_credit_entry", "accordion-open");
        Util.setElementClass("remove", "div_checkout_existing_credit_cvv", "accordion-open");
    } else {
        aOLO.Order.PaymentType = PaymentType.PAY_IN_STORE;
        Util.setElementClass("remove", "div_checkout_credit_entry", "accordion-open");
        Util.setElementClass("remove", "div_checkout_existing_credit_cvv", "accordion-open");
    }

    if (aOLO.Modules.DataLayer)
        aOLO.Modules.DataLayer.add_payment_info(aOLO.Order.Items);
}

export function resetHeartland(orderHubPayment: boolean = false): void {
    try { aOLO.Temp.hps.dispose(); } catch (ex) { }
    delete aOLO.Temp.hps;
    delete aOLO.Temp.HeartlandSet;
    setHeartland(orderHubPayment);
}

export function getHeartlandPublicKey(): string {
    if (aOLO.data.Settings.ISCC) {
        const storeID = aOLO.Order.StoreID;
        const store = OnlineOrderingUtil.getCallCenterStore(storeID, aOLO.data.Stores);
        return store?.Heartland || "";
    }

    return aOLO.data.Settings.HPK;
}

export function setHeartland(orderHubPayment: boolean = false): void {
    if (aOLO.Temp.HeartlandSet)
        return;

    aOLO.Temp.HeartlandSet = true;
    const pkey = getHeartlandPublicKey();
    //@ts-ignore
    aOLO.Temp.hps = new Heartland.HPS({
        publicKey: pkey,
        style: {
            'input': {
                'touch-action': 'manipulation',
                'color': '#333333',
                'font-size': '1rem',
                'border': '0',
                'border-bottom': '1px solid #cfcfcf',
                'background-color': '#fefefe',
                'font-family': '1.0rem RobotoCondensed, Arial, sans-serif',
                '-webkit-appearance': 'none',
                'border-radius': '0',
                'padding': '0.75rem',
                'cursor': 'text',
                'width': '90%'
            },
            '::-webkit-input-placeholder': { /* WebKit, Blink, Edge */
                'color': 'var(--colorText)'
            },
            ':-moz-placeholder': { /* Mozilla Firefox 4 to 18 */
                'color': 'var(--colorText)',
                'opacity': '1'
            },
            '::-moz-placeholder': { /* Mozilla Firefox 19+ */
                'color': 'var(--colorText)',
                'opacity': '1'
            },
            ':-ms-input-placeholder': { /* Internet Explorer 10-11 */
                'color': 'var(--colorText)'
            }
        },
        type: 'iframe',
        fields: {
            cardNumber: { target: 'iframesCardNumber', placeholder: '•••• •••• •••• ••••' }
            , cardExpiration: { target: 'iframesCardExpiration', placeholder: 'MM / YY' }
            , cardCvv: { target: 'iframesCardCvv', placeholder: 'CVV' }
        },
        onTokenSuccess: function (resp: any) {
            SetHeartlandToken(resp.token_value, resp.last_four, orderHubPayment);
        },
        onTokenError: function (resp: any) {
            DialogCreators.messageBoxOk(resp.error.message, aOLO.buttonHoverStyle);
            aOLO.Temp.IsSubmitting = false;
            Util.LogError("SetHeartland", resp.error.message, aOLO);
        },
        onResize: function () {

        },
        onEvent: function (ev: any) {
            if (ev.type === 'keyup' && ev.source === 'cardNumber' && ev.classes.indexOf('valid') !== -1) {
                aOLO.Temp.hps.setFocus('cardExpiration');
            } else if (ev.type === 'keyup' && ev.source === 'cardExpiration' && ev.classes.indexOf('valid') !== -1 &&
                ev.data.keyCode != 8 && ev.data.keyCode != 37 && ev.data.keyCode != 38 && ev.data.keyCode != 39 && ev.data.keyCode != 40 && ev.data.keyCode != 46) {
                aOLO.Temp.hps.setFocus('cardCvv');
            } else if (ev.type === 'keyup' && ev.source === 'cardCvv' && ev.classes.indexOf('valid') !== -1 && ev.data.keyCode == 13) {
                const ccAddress = document.getElementById("txt_checkout_credit_entry_billing_address");
                if (ccAddress)
                    ccAddress.focus();
            }
        }
    });
}

export async function SetHeartlandToken(token: string, last4: string, orderHubPayment: boolean = false): Promise<void> {
    aOLO.Temp.heartlandToken = token;
    aOLO.Temp.heartlandLast4 = last4;
    await Submit(true, orderHubPayment);
}

export async function CheckSubmitData(orderHubPayment: boolean = false): Promise<boolean> {

    /**
     * Adds a validation error message to the error flag.
     * @param message - The result of a validation check.
     * @returns {void}
     */
    function addValidationErrorMsg(message: string): void {
        if (message !== "") {
            error = true;
            ul.appendChild(Common.GetLi(message));
        }
    }

    hideErrorElements();

    const submitDataValidating = true;
    let error = false;
    const ul = document.createElement("ul");
    ul.classList.add("warning");

    const notif = getNotificationSettings();
    if (notif.IsTextNotify) {
        addValidationErrorMsg(await Common.ValidateInput(
            notif.TEXT.isSet,
            notif.TEXT.dataRaw,
            FieldType.PHONE,
            "spn_checkout_notification_text_error",
            submitDataValidating,
            aOLO
        ));
    }

    if (notif.IsEmailNotify) {
        addValidationErrorMsg(await Common.ValidateInput(
            notif.EMAIL.isSet,
            notif.EMAIL.dataRaw,
            FieldType.EMAIL,
            "spn_checkout_notification_email_error",
            submitDataValidating,
            aOLO
        ));
    }

    if (!(aOLO.User.GU == LoggedInStatus.EXTERNAL || aOLO.User.GU == LoggedInStatus.LOGGEDIN || aOLO.QrCode)) {
        addValidationErrorMsg(await Common.ValidateInput(true,
            Util.getElementValue("txt_checkout_name").trim(),
            FieldType.NAME,
            "spn_checkout_name_error",
            true,
            aOLO
        ));

        addValidationErrorMsg(await Common.ValidateInput(true,
            Util.getElementValue("txt_checkout_last_name").trim(),
            FieldType.NAME,
            "spn_checkout_last_name_error",
            true,
            aOLO
        ));

        addValidationErrorMsg(await Common.ValidateInput(true,
            Util.getElementValue("txt_checkout_phone").trim(),
            FieldType.PHONE,
            "spn_checkout_phone_error",
            true,
            aOLO
        ));

        addValidationErrorMsg(await Common.ValidateInput(true,
            Util.getElementValue("txt_checkout_email").trim(),
            FieldType.EMAIL,
            "spn_checkout_email_error",
            true,
            aOLO
        ));
    }

    if (aOLO.data.Settings.CURBSD == Curbside.CUSTOMER_CAR_INFO &&
        aOLO.Order.OrderTypeID === OrderType.TAKE_OUT &&
        Util.getElementChecked("chk_checkout_curbside")) {
        addValidationErrorMsg(await Common.ValidateInput(true,
            Util.getElementValue("txt_checkout_car").trim(),
            FieldType.CAR,
            "spn_checkout_car_error",
            true,
            aOLO
        ));
    }

    if (error) {
        DialogCreators.messageBoxOk(ul, aOLO.buttonHoverStyle);
        return false;
    }

    if (aOLO.Order.Tip < 0) {
        DialogCreators.messageBoxOk(Names("InvalidTip"), aOLO.buttonHoverStyle, null, "txt_checkout_tip");
        return false;
    }

    const tip = Util.Float2(Util.getElementValue("txt_checkout_tip"));
    if (tip < 0) {
        DialogCreators.messageBoxOk(Names("InvalidTip"), aOLO.buttonHoverStyle, null, "txt_checkout_tip");
        return false;
    }

    const payInstore = Util.getElementChecked("rdo_checkout_in_store");
    const payCash = Util.getElementChecked("rdo_checkout_cash");
    const payExCredit = Util.getElementChecked("rdo_checkout_existing_credit");

    const creditRadio = document.getElementById("rdo_checkout_credit") as HTMLInputElement;
    const payCredit = !!creditRadio ? creditRadio.checked : true;

    const payMbCredit = Util.getElementChecked("rdo_checkout_credit_on_delivery");
    const paidByGiftCard = checkOrderPaidByGiftCard(orderHubPayment);

    if (!payInstore && !payCash && !payExCredit && !payCredit && !payMbCredit && !paidByGiftCard) {
        DialogCreators.messageBoxOk(Names("NoPayment"), aOLO.buttonHoverStyle);
        return false;
    }
    if (payExCredit) {
        const element = document.getElementById("txt_checkout_existing_credit_cvv") as HTMLInputElement;
        const payOptions: IPaymentOptions | undefined = aOLO.data.Settings.OPO.find(x => x.OrderTypeID == aOLO.Order.OrderTypeID);
        if (element && payOptions?.CardOnFileCvvOption !== CardOnFileCvvOption.IGNORE) {
            const exCvvLen = Util.cleanNonDigits(element.value).length;
            if (exCvvLen < 3) {
                DialogCreators.messageBoxOk(Names("InvalidCVV"), aOLO.buttonHoverStyle);
                return false;
            }
        }
    }
    if (payCredit && aOLO.data.Settings.CCI) {

        const payOptions: IPaymentOptions | undefined = aOLO.data.Settings.OPO.find(x => x.OrderTypeID == aOLO.Order.OrderTypeID);

        if (!OnlineOrderingUtil.IsHeartland(aOLO.data.Settings.ISCC, aOLO.Order.StoreID, aOLO.data.Settings.HPK)) {
            const ccNumber = Util.cleanNonDigits(Util.getElementValue("txt_checkout_credit_entry_card_number"));
            if (ValidateCardNumber(ccNumber) <= 0) {
                DialogCreators.messageBoxOk(Names("InvalidCC"), aOLO.buttonHoverStyle);
                return false;
            }

            const expMonth = parseInt(Util.getElementValue("ddl_checkout_credit_entry_expiration_month"));
            const expYear = parseInt(Util.getElementValue("ddl_checkout_credit_entry_expiration_year"));
            const busMonth = Util.NowStore(aOLO.Temp.TimeOffset).getMonth() + 1;
            const busYear = Util.NowStore(aOLO.Temp.TimeOffset).getFullYear() % 100;
            if ((busYear == expYear && expMonth < busMonth) || expMonth == 0 || expYear == 0) {
                DialogCreators.messageBoxOk(Names("InvalidExp"), aOLO.buttonHoverStyle);
                if (expYear === 0) {
                    const expYear = document.getElementById("ddl_checkout_credit_entry_expiration_year");
                    if (expYear)
                        expYear.focus();
                } else {
                    const expMonth = document.getElementById("ddl_checkout_credit_entry_expiration_month");
                    if (expMonth)
                        expMonth.focus();
                }
                return false;
            }

            const brand = GetCardBrand(ccNumber);
            const cvvLen = Util.cleanNonDigits(Util.getElementValue("txt_checkout_credit_entry_cvv")).length;
            if (((brand == 1 || brand == 2 || brand == 4) && cvvLen != 3) || (brand == 3 && cvvLen != 4)) {
                DialogCreators.messageBoxOk(Names("InvalidCVV"), aOLO.buttonHoverStyle);
                return false;
            }
        }

        if (payOptions && payOptions.CreditCardAvsOption != CreditCardAvsOption.DO_NOT_VALIDATE) {
            if (payOptions.CreditCardAvsOption != CreditCardAvsOption.ZIP_ONLY_PROMPT_VALIDATE) {
                if (Util.getElementValue("txt_checkout_credit_entry_billing_address").length == 0) {
                    DialogCreators.messageBoxOk(Names("InvalidAddr"), aOLO.buttonHoverStyle);
                    return false;
                }
            }
            if (payOptions.CreditCardAvsOption != CreditCardAvsOption.ADDRESS_ONLY_PROMPT_VALIDATE) {
                const zip = Util.getElementValue("txt_checkout_credit_entry_billing_zip");
                if (zip.length !== 5 && zip.length !== 7) {
                    DialogCreators.messageBoxOk(Names("InvalidZip"), aOLO.buttonHoverStyle);
                    return false;
                }
            }
        }
    }

    if (!(aOLO.User.GU == LoggedInStatus.EXTERNAL || aOLO.User.GU == LoggedInStatus.LOGGEDIN) && !Util.getElementChecked("chk_checkout_privacy_terms")) {
        Util.setElementClass("add", "lbl_checkout_privacy_terms", "warning");
        DialogCreators.messageBoxOk(Names("CheckTerms"), aOLO.buttonHoverStyle);
        return false;
    }

    return true;
}

export async function saveOrder(order: IOrder, orderHubPayment: boolean = false): Promise<void> {
    try {
        if (orderHubPayment) {
            await checkCurrentPromiseTime();
            const payments = OnlineOrderingUtil.CompletePayments(order, aOLO);
            order.Payments = payments ? payments : order.Payments;
            const sOrder = JSON.stringify(order);
            sendOrderPaymentToServer(sOrder)
        }
        else {
            await checkCurrentPromiseTime();
            const nOrder = OnlineOrderingUtil.composeOrderObject(order, aOLO);
            const sOrder = JSON.stringify(nOrder);
            sendOrderToServer(sOrder);
        }
    } catch (ex: unknown) {
        if (ex instanceof Error)
            Util.LogError("SaveOrder", ex, aOLO);

        aOLO.Temp.IsSubmitting = false;
    }
}

export function hideErrorElements() {
    Util.hideElement("spn_checkout_notification_text_error");
    Util.hideElement("spn_checkout_notification_email_error");
    Util.hideElement("spn_checkout_name_error");
    Util.hideElement("spn_checkout_last_name_error");
    Util.hideElement("spn_checkout_phone_error");
    Util.hideElement("spn_checkout_email_error");
    Util.hideElement("spn_checkout_car_error");
}

export function getNotificationSettings(): IGetNotificationSettings {
    const isNotify = (!!aOLO.notification?.Notification);
    const isTextNotifyValue = (!!aOLO.notification?.Text);
    const isEmailNotifyValue = (!!aOLO.notification?.Email);

    const isTextNotify = (isNotify && isTextNotifyValue);
    const isEmailNotify = (isNotify && isEmailNotifyValue);

    const isEmail = (isNotify && isEmailNotifyValue && Util.getElementChecked("chk_checkout_notification_email"));
    const email = (isEmail) ? Util.getElementValue("eml_checkout_notification_email") : null;
    const emailRaw = (isNotify && document.getElementById("eml_checkout_notification_email") && document.getElementById("eml_checkout_notification_email")) ? Util.getElementValue("eml_checkout_notification_email") : null;

    const isText = (isNotify && isTextNotifyValue && Util.getElementChecked("chk_checkout_notification_text"));
    const text = (isText) ? Util.cleanNonDigits(Util.getElementValue("tel_checkout_notification_text")) : null;
    const textCountryCode = (isText) ? Util.getElementValue("dropdown_checkout_notification_country_code") : null;
    const textRaw = (isNotify && document.getElementById("tel_checkout_notification_text") && document.getElementById("tel_checkout_notification_text")) ? Util.cleanNonDigits(Util.getElementValue("tel_checkout_notification_text")) : null;

    return {
        IsNotify: isNotify,
        IsTextNotify: isTextNotify,
        IsEmailNotify: isEmailNotify,
        EMAIL: { isSet: isEmail, data: email, dataRaw: emailRaw },
        TEXT: { isSet: isText, data: text, dataRaw: textRaw },
        TEXTCOUNTRYCODE: { isSet: isText, data: textCountryCode, dataRaw: textCountryCode }
    };
}

export function checkOrderPaidByGiftCard(orderHubPayment: boolean = false) {
    if (orderHubPayment) {
        return false;
    }

    const total = OnlineOrderingUtil.getOrderTotal(aOLO.Order);
    let giftCardTotal = 0;

    for (const giftCard of aOLO.Temp.GiftCards)
        giftCardTotal += giftCard.appliedAmount;

    return total == giftCardTotal;
}

export function ValidateCardNumber(cNumber: string): number {
    const cardBrand = GetCardBrand(cNumber);
    const nLength = cNumber.length;
    let validNumber = 0;
    for (let i = 1; i == 4; i++) {
        const cc = document.getElementById(`imgCC${i}`);
        if (cc)
            cc.style.opacity = "0.2";
    }
    if (cardBrand > 0) {
        const img = document.getElementById(`imgCC${cardBrand}`);
        if (img)
            img.style.opacity = "1";
    }

    if (cardBrand === 0 && cNumber.length > 3) {
        validNumber = -100;
    } else if (
        (cardBrand === CardBrand.VISA && (nLength === 16)) ||
        (cardBrand === CardBrand.MASTERCARD && (nLength === 16)) ||
        (cardBrand === CardBrand.AMEX && (nLength === 15)) ||
        (cardBrand === CardBrand.DISCOVER && (nLength === 16)) ||
        (cardBrand === CardBrand.DINERSCLUB && (nLength === 14)) ||
        (cardBrand === CardBrand.DINERSCLUB && (nLength === 16)) ||
        (cardBrand === CardBrand.JCB && nLength === 16)
    ) {
        validNumber = cardBrand;
        let len = cNumber.length;
        let mul = 0;
        const prodArr = [[0, 1, 2, 3, 4, 5, 6, 7, 8, 9], [0, 2, 4, 6, 8, 1, 3, 5, 7, 9]];
        let sum = 0;
        while (len--) {
            sum += prodArr[mul][parseInt(cNumber.charAt(len), 10)];
            mul ^= 1;
        }
        if (!(sum % 10 === 0 && sum > 0))
            validNumber = -cardBrand;
    }
    return validNumber;
}

export function GetCardBrand(cNumber: string): CardBrand {
    switch (true) {
        case /^3[47]/.test(cNumber):
            return CardBrand.AMEX;
        case /^4/.test(cNumber):
            return CardBrand.VISA;
        case /^5[1-5]|^2[2-7]/.test(cNumber):
            return CardBrand.MASTERCARD;
        case /^6(?:011|5|44|49)/.test(cNumber):
            return CardBrand.DISCOVER;
        case /^3(?:0[0-5]|[68]|[39])/.test(cNumber):
            return CardBrand.DINERSCLUB;
        case /^(?:2131|1800|35)/.test(cNumber):
            return CardBrand.JCB;
        default:
            return CardBrand.UNKNOWN;
    }
}

export async function checkCurrentPromiseTime(): Promise<void> {
    if (aOLO.times.SET !== 3)
        return;

    const currentMinute = Util.nowTodayMinute(aOLO.Temp.TimeOffset);
    const times = aOLO.times;

    const response = await aOLO.Modules.OnlineOrderingService.getLastWaitTimes(aOLO.storeInfo.StoreKey, aOLO);
    if (!response.success)
        return;

    if (!times.WTIM)
        return;

    const newTime = JSON.parse(response.data);
    const savedWaitTime = times.WTIM.find(x => x.MIN == (currentMinute - currentMinute % 5));

    if (!newTime)
        return;

    if (savedWaitTime) {
        const averageTime = Math.ceil((savedWaitTime.MAKEMIN + newTime.MAKEMIN) / 2);
        const averageDeliveryTime = Math.ceil((savedWaitTime.DELMIN + newTime.DELMIN) / 2);
        const currMinute = times.WTIM.find(x => x.MIN == (currentMinute - currentMinute % 5));
        if (currMinute) {
            currMinute.MAKEMIN = averageTime;
            currMinute.DELMIN = averageDeliveryTime;
        }
    } else {
        const time = times.WTIM.find(x => x.MIN == (currentMinute - currentMinute % 5));
        if (time) {
            time.MAKEMIN = newTime.MAKEMIN;
            time.DELMIN = newTime.DELMIN;
        } else {
            const newTimeObj = {
                MIN: currentMinute - currentMinute % 5,
                MAKEMIN: newTime.MAKEMIN,
                DELMIN: newTime.DELMIN
            };
            times.WTIM.push(newTimeObj);
        }
    }
    aOLO.Temp.WaitTime = OnlineOrderingUtil.getWaitTimeByOT(aOLO.data.OrderTypeSubTypes.find(x => x.OrderTypeId == aOLO.Order.OrderTypeSubType.OrderTypeId), aOLO);
}

export async function sendOrderToServer(sOrder: string): Promise<void> {
    aOLO.Temp.CheckOrderTimeIntervalIsPaused = true;

    try {
        const response = await aOLO.Modules.OnlineOrderingService.saveOrder(sOrder, aOLO);

        try {

            if (response.success) {
                aOLO.Order.OrderID = response.orderId || 0;
                const usedRewardIds = OnlineOrderingUtil.GetRewardRedemptionIds(aOLO);
                await aOLOModules.LoyaltyProvider.postOrder(aOLO.Order, usedRewardIds);
            }
        } catch { }
        await OrderResult(response);
        try {
            if (response.success && aOLO.Temp.GiftCards.length > 0) {
                let uuids = aOLO.Temp.GiftCards.map(x => String(x.uuid)).filter(Boolean);
                if (uuids.length > 0)
                    aOLOModules.LoyaltyProvider.fetchGiftCardsBalance(uuids);
            }
        } catch { }
    }
    finally {
        aOLO.Temp.IsSubmitting = false;
    }
}

export async function sendOrderPaymentToServer(sOrder: string): Promise<void> {
    aOLO.Temp.CheckOrderTimeIntervalIsPaused = true;

    try {
        const response = await aOLO.Modules.OnlineOrderingService.saveOrderPayment(sOrder, aOLO);
        await OrderResult(response);
    }
    finally {
        aOLO.Temp.IsSubmitting = false;
    }
}

export function calculateTip(location: string = "checkout"): void {
    const payOptions: IPaymentOptions | undefined = aOLO.data.Settings.OPO.find(x => x.OrderTypeID == aOLO.Order.OrderTypeID)
    const orderTotal = aOLO.Order.SubTotal;
    const tip0 = Util.getElementChecked("rdo_checkout_tip_0");
    const tip10 = Util.getElementChecked("rdo_checkout_tip_10");
    const tip15 = Util.getElementChecked("rdo_checkout_tip_15");
    const tip20 = Util.getElementChecked("rdo_checkout_tip_20");
    let tip = 0.0;

    if (tip0)
        tip = 0.00;
    else if (tip10)
        tip = Util.Float2(orderTotal * 0.1);
    else if (tip15)
        tip = Util.Float2(orderTotal * 0.15);
    else if (tip20)
        tip = Util.Float2(orderTotal * 0.2);

    aOLO.Order.Tip = tip;

    if (location != "orderHubPayment") {
        OnlineOrderingUtil.GUI_SetOrder_Total(true, aOLO, aOLOModules);
        Util.hideElement("spn_checkout_tip_error");
        //GUI_CHKOUT_PaymentOptions();
    }
    else {
        OnlineOrderingUtil.GUI_SetOrderHubPayment_Total(true, aOLO);
        Util.hideElement("spn_checkout_tip_error");
    }

    if (payOptions && aOLO.Order.AmountDue > payOptions.PayInStoreLimit) {
        Util.hideElement("div_checkout_in_store");
        Util.setElement("checked", "rdo_checkout_in_store", false);
    }
    else if (payOptions?.PayInStore === true) {
        Util.showElement("div_checkout_in_store");
    }

    if (payOptions && aOLO.Order.AmountDue > payOptions.CashLimit) {
        Util.hideElement("div_checkout_cash");
        Util.setElement("checked", "rdo_checkout_cash", false);
    }
    else if (payOptions?.Cash === true) {
        Util.removeChildren("div_checkout_cash");
        createCashOption();
        Util.showElement("div_checkout_cash");
    }  
}

export function Gratuity_Format_Value(): void {
    const tip = Number(Util.getElementValue("txt_checkout_tip").replace(/,/g, '')) || 0;
    Util.setElement("value", "txt_checkout_tip", Util.formatNumber(tip));
}

export function SetOtherTip(): void {
    const tipInput = document.getElementById("txt_checkout_tip") as HTMLInputElement;
    if (tipInput) {
        tipInput.focus();
        tipInput.select();
    }
}

export function createCashOption(): void {
    const html = generatePaymentOptionHTML("checkout_cash", "Cash");
    Util.setElement("innerHTML", "div_checkout_cash", html);
}

export function createCreditOnDeliveryOption(label: string = "CreditCardUponDelivery"): void {
    const html = generatePaymentOptionHTML("checkout_credit_on_delivery", label, "CCNotInte");
    Util.setElement("innerHTML", "div_checkout_credit_on_delivery", html);
}

export function generatePaymentOptionHTML(id: string, labelText: string, warningText?: string): string {
    let warningElement = '';
    if (warningText)
        warningElement = `<span id="spn_${id}" ltag="${warningText}" class="warning block p1 m1-l"></span>`;

    const html = `
        <input id="rdo_${id}" type="radio" name="rdoPayment">
        <label id="lbl_${id}" ltag="${labelText}" for="rdo_${id}">
            <span ltag="${labelText}">${Names(labelText)}</span>
            ${warningElement}
        </label>`;

    return html;
}

export function txtCCkeyUp(event: KeyboardEvent): void {
    const txtCC = document.getElementById("txt_checkout_credit_entry_card_number") as HTMLInputElement;
    if (!txtCC)
        return;

    const ccValue = txtCC.value;
    let cPos = txtCC.selectionStart || 0;

    let isLeftDash = false;
    let isRightDash = false;
    if (cPos > 0)
        isLeftDash = (ccValue.substring(cPos - 1, cPos) === "-");

    if (cPos > 0)
        isRightDash = (ccValue.substring(cPos, cPos + 1) === "-");

    if (event.key !== "ArrowLeft" && event.key !== "ArrowRight") {
        const cLen = ccValue.length;
        const formatedCCValue = formatCreditCard(ccValue);
        txtCC.value = formatCreditCard(formatedCCValue);
        const fcLen = formatedCCValue.length;
        if ((fcLen > cLen && cPos == cLen) || isRightDash)
            cPos += 1;

        if (event.key === "Backspace" && isLeftDash)
            cPos -= 1;

        txtCC.setSelectionRange(cPos, cPos);
    }
}

export function txtCCkeyDown(event: KeyboardEvent): void {
    const txtCC = document.getElementById("txt_checkout_credit_entry_card_number") as HTMLInputElement;
    if (!txtCC)
        return;

    const ccValue = txtCC.value;
    let cPos = txtCC.selectionStart || 0;
    let isLeftDash = false;
    let isPrevLeftDash = false;
    let isRightDash = false;
    let isNextRightDash = false;

    if (cPos > 0)
        isLeftDash = (ccValue.substring(cPos - 1, cPos) === "-");

    if (cPos > 1)
        isPrevLeftDash = (ccValue.substring(cPos - 2, cPos - 1) === "-");

    if (cPos > 0)
        isRightDash = (ccValue.substring(cPos, cPos + 1) === "-");

    if (cPos > 0)
        isNextRightDash = (ccValue.substring(cPos + 1, cPos + 2) === "-");

    if (event.key === "Backspace" && isLeftDash)
        cPos = cPos - 1;
    else if (event.key === "Delete" && isRightDash)
        cPos = cPos + 1;
    else if (event.key === "ArrowLeft" && isPrevLeftDash)
        cPos = cPos - 1;
    else if (event.key === "ArrowRight" && isNextRightDash)
        cPos = cPos + 1;

    txtCC.setSelectionRange(cPos, cPos);
}

export function getExpirationYearsAhead(numYears: number): number[] {
    const currentYear = new Date().getFullYear();
    const expirationYears: number[] = [];

    for (let i = 0; i < numYears; i++) {
        expirationYears.push(currentYear + i);
    }

    return expirationYears;
}

export function populateExpirationYearsDropdown(numYears: number): void {
    const selectElement = document.getElementById("ddl_checkout_credit_entry_expiration_year");

    if (selectElement instanceof HTMLSelectElement) {
        // Clear existing options
        selectElement.innerHTML = '<option id="optExpYear0" value="00">Year</option>';

        // Get the array of expiration years
        const expirationYears = getExpirationYearsAhead(numYears);

        // Add options to the dropdown
        expirationYears.forEach((year, index) => {
            const optionElement = document.createElement("option");
            optionElement.value = year.toString().slice(-2); // Using last two digits of the year
            optionElement.text = year.toString();
            optionElement.id = `optExpYear${index + 1}`; // Incrementing index to avoid id conflicts
            selectElement.add(optionElement);
        });
    }
}

export function formatCreditCard(cno: string): string {
    cno = Util.cleanNonDigits(cno);
    const brand = GetCardBrand(cno);
    let formated = "";

    if (brand == CardBrand.AMEX) {
        if (cno.length <= 4)
            formated = cno;
        else if (cno.length < 11)
            formated = `${cno.substring(0, 4)}-${cno.substring(4)}`;
        else
            formated = `${cno.substring(0, 4)}-${cno.substring(4, 10)}-${cno.substring(10)}`;
    } else {
        if (cno.length <= 4)
            formated = cno;
        else if (cno.length < 9)
            formated = `${cno.substring(0, 4)}-${cno.substring(4)}`;
        else if (cno.length < 13)
            formated = `${cno.substring(0, 4)}-${cno.substring(4, 8)}-${cno.substring(8)}`;
        else
            formated = `${cno.substring(0, 4)}-${cno.substring(4, 8)}-${cno.substring(8, 12)}-${cno.substring(12)}`;
    }
    return formated;
}

export function CheckCCValue(): boolean {
    const txtCC = document.getElementById("txt_checkout_credit_entry_card_number") as HTMLInputElement;
    if (!txtCC)
        return false;

    const ccValue = txtCC.value;
    const ccNumber = Util.cleanNonDigits(ccValue);
    const valid = ValidateCardNumber(ccNumber);
    if (OnlineOrderingUtil.IsOpenPay()) {
        const opTxtCC = document.getElementById("txt_checkout_credit_entry_card_number_OP") as HTMLInputElement;
        opTxtCC.value = ccNumber;
    }
    
    if (valid > 0) { // it is valid brand and valid number luhn
        txtCC.classList.remove("warning");
        const expMonth = document.getElementById("ddl_checkout_credit_entry_expiration_month");
        if (expMonth)
            expMonth.focus();
        return true;
    } else if (valid == -100) { // it is not valid brand 
        txtCC.classList.add("warning");
        return false;
    } else if (valid < 0) { // it is valid brand but invalid number 
        txtCC.classList.add("warning");
        return false;
    } else if (valid == 0) { // it is valid brand but number is not complete yet
        txtCC.focus();
        return false;
    }
    return false;
}

export async function OrderResult(response: IServiceSaveOrder): Promise<void> {
    aOLO.Temp.IsSubmitting = false;
    if (!response.orderId) {
        if (response.message)
            DialogCreators.messageBoxOk(response.message, aOLO.buttonHoverStyle);
        return;
    }

    if (response.success) {
        await aOLOModules.LoyaltyProvider.batchComparison(false);

        gtmPurchaseLog(response.orderId);

        let msg = `<div style="text-align: center;">
                    <div>${Names("Conf1")}</div>`;
        if (!aOLO.QrCode) {
            if (aOLO.Order.FutureDate) {
                const iDate = new Date(aOLO.Order.FutureDate);
                if (Util.DateDiff("d", Util.NowStore(aOLO.Temp.TimeOffset), aOLO.Order.FutureDate) < 0) // it is next day
                    msg += Names("Conf2").replace("??", Util.formatDate(iDate));
                else
                    msg += Names("Conf3");
            } else
                msg += Names("Conf3");
        }
        msg += `<div class="bold">${Names("Conf4")}: ${response.orderId}<br/>${Names("Conf5")}: ${response.orderNo}<br/>`;
        let pTime = "";
        if (aOLO.Order.FutureDate) {
            const iDate = new Date(aOLO.Order.FutureDate);
            pTime = Util.formatDateTime(Util.DateAdd(iDate, aOLO.Order.FutureMinute, "m"));
        } else {
            if (aOLO.data.Settings.ISCC && aOLO.Temp.SelectedStoreID > 0) {
                const store = OnlineOrderingUtil.getCallCenterStore(aOLO.Order.StoreID, aOLO.data.Stores);
                if (store) {
                    const storeWaitTime = OnlineOrderingUtil.getStoreWaitTime(store, aOLO.Order.OrderTypeID, aOLO.Temp.TimeOffset);
                    pTime = Util.formatDateTime(Util.DateAdd(Util.NowStore(aOLO.Temp.TimeOffset), storeWaitTime, "m"));
                }
            } else
                pTime = Util.formatDateTime(Util.DateAdd(Util.NowStore(aOLO.Temp.TimeOffset), aOLO.Temp.WaitTime, "m"));
        }
        msg += `${Names("PromiseTime")}: ${pTime}`;
        msg += "</div>";
        aOLO.User.ProfileId = response.profileId || 0;
        aOLO.User.CustomerId = response.customerId || 0;

        if (response.errorId === 2)
            msg += response.message;

        if (aOLO.Dialog.Checkout)
            aOLO.Dialog.Checkout.CloseDialog();
        orderResultDialog(msg, response.trk || null);

        aOLO.isOKNavAway = true;

        if (aOLO.Modules.DataLayer)
            aOLO.Modules.DataLayer.purchase(aOLO.Order.Items, response.orderId);
    } else {
        delete aOLO.Temp.heartlandToken;
        delete aOLO.Temp.heartlandLast4;
        aOLO.Temp.IsSubmitting = false;
        DialogCreators.messageBoxOk(response.message, aOLO.buttonHoverStyle);
        if (response.orderId) {
            aOLO.Order.OrderID = response.orderId;
        }
        aOLO.Temp.CheckOrderTimeIntervalIsPaused = false;
    }
}

export function gtmPurchaseLog(orderId: number): void {
    try {
        //if (aOLO.GoogleTagManager) {
        //    Util.setElement("innerHTML", "div_Pixcel", `<img height="1" width="1" style="border-style: none;" alt="" src="https://insight.adsrvr.org/track/pxl/?adv=9m2bjvi&ct=0:ush1gw1&fmt=3&v=${aOLO.Order.SubTotal}&orderid=${orderId}" />`);
        //}

        if (!!aOLO.data.Settings.YLPPXL)
            Util.setElement("innerHTML", "div_Pixcel", `<img src='https://d.adroll.com/ipixel/F7VVXUVLBVGGXAWHD3MCWS/Q74SEERZOFGFFKMCKPTPTL?name=7f9a87fa&conv_value=${aOLO.Order.Total}&adroll_currency=USD&ar_ed_order_id=${orderId}' width='1' height='1' />`);
    } catch (ex) { }
}

function orderResultDialog(msgText: string, trkData: string | null): void {
    const hasNotify = !!aOLO.notification?.Notification;
    const isLoggedIn = false; //2DO: aOLO.ProfileUser ? aOLO.ProfileUser.isLoggedIn() : false;
    // temporary fix
    if (aOLO.storeInfo.BrandID.toLowerCase() == '5FC97EA9-A6C4-4B91-BD6C-5B3FFB592313'.toLowerCase()) {
        DialogCreators.messageBoxOk(msgText, aOLO.buttonHoverStyle, () => {
            window.location.href = 'https://www.topperspizzaplace.com/';
        });
        return;
    } else if (aOLO.storeInfo.BrandID.toLowerCase() == '8402E2D5-B196-4574-9DDD-30C5615F57A1'.toLowerCase() && Util.isAppView()) {
        DialogCreators.messageBox(msgText, aOLO.buttonHoverStyle, []);
        return;
    }
    if ((isLoggedIn && !hasNotify) || aOLO.QrCode)
        DialogCreators.messageBoxOk(msgText, aOLO.buttonHoverStyle, () => { OnlineOrderingUtil.GotoStoreBrandPage(aOLO); });
    else if (isLoggedIn && hasNotify && trkData)
        DialogCreators.messageBox(msgText,
            aOLO.buttonHoverStyle,
            [
                { "text": Names("TrackMyOrder"), "callBack": () => { orderResultTrackOrder(trkData); } },
                { "text": Names("Close"), "callBack": () => { OnlineOrderingUtil.GotoStoreBrandPage(aOLO); } }
            ]);

    else if (!isLoggedIn && hasNotify && trkData)
        DialogCreators.messageBox(msgText,
            aOLO.buttonHoverStyle, [
            { "text": Names("CreateAccountConfirmationPage"), "callBack": () => { orderResultCreateProfile(); } },
            { "text": Names("TrackMyOrder"), "callBack": () => { orderResultTrackOrder(trkData); } },
            { "text": Names("Close"), "callBack": () => { OnlineOrderingUtil.GotoStoreBrandPage(aOLO); } }
        ]);
    else //if (!isLoggedIn && !hasNotify)
        DialogCreators.messageBox(msgText, aOLO.buttonHoverStyle, [{ "text": Names("CreateAccountConfirmationPage"), "callBack": () => { orderResultCreateProfile(); } }]);
}

function orderResultTrackOrder(trkData: string): void {
    aOLO.isOKNavAway = true;
    window.location.href = `${window.location.origin}/brand/?tracker=${trkData}`;
}

function orderResultCreateProfile() {
    aOLO.isOKNavAway = true;
    window.location.href = `${window.location.origin}/brand/?sk=${aOLO.storeInfo.StoreKey}/signup`;
}

export async function CheckOrderTime(getOrderTypeFunctions : Function): Promise<void> {
    if (!aOLO.Temp.OrderingStarted) return;

    const now = Util.NowStore(aOLO.Temp.TimeOffset);
    const nowMinutes = now.getHours() * 60 + now.getMinutes();
    let DSTET = OnlineOrderingUtil.GetDate_StartTime_EndTime(now, 0, false, aOLO);
    if (aOLO.Order.FutureDate) {

        let busDate = Util.GetBUSDate(Util.DateAdd(aOLO.Order.FutureDate, aOLO.Order.FutureMinute,'m'), aOLO);


        DSTET = OnlineOrderingUtil.GetDate_StartTime_EndTime(busDate, aOLO.Order.FutureMinute, false, aOLO);
    }
    if (!DSTET.IsToday)
        return;

    if (DSTET.CloseMinute - nowMinutes <= (aOLO.data.Settings.IWTT ? 0 : aOLO.Order.OrderTypeSubType.WaitTime)) {
        aOLO.Dialog.OrderType = new OrderTypeDialog(getOrderTypeFunctions(), true, false);
        DialogCreators.messageBoxOk(Names("WillClose").replace("??", (DSTET.CloseMinute - nowMinutes).toString()) + " " + Names("NoTime").replace("???", OnlineOrderingUtil.GetOrderTypeName(aOLO.Order.OrderTypeID, aOLO)), aOLO.buttonHoverStyle);
    } else if (DSTET.CloseMinute - nowMinutes <= (aOLO.data.Settings.IWTT ? 0 : aOLO.Order.OrderTypeSubType.WaitTime) + 5 && !aOLO.Temp.MeetPromiseTimeWarning) {
        aOLO.Temp.MeetPromiseTimeWarning = true;
        DialogCreators.messageBoxOk(Names("WillClose").replace("??", (DSTET.CloseMinute - nowMinutes).toString()) + " " + Names("FinishX").replace("??", (DSTET.CloseMinute - nowMinutes - aOLO.Order.OrderTypeSubType.WaitTime).toString()), aOLO.buttonHoverStyle);
    } else if (DSTET.EndOrderMinute - nowMinutes <= 0) {
        aOLO.Dialog.OrderType = new OrderTypeDialog(getOrderTypeFunctions(), true, false);
        DialogCreators.messageBoxOk(Names("MeetPromiseTimeError"), aOLO.buttonHoverStyle);
    } else if (DSTET.EndOrderMinute - nowMinutes <= 5 && !aOLO.Temp.MeetPromiseTimeWarning) {
        aOLO.Temp.MeetPromiseTimeWarning = true;
        DialogCreators.messageBoxOk(Names("MeetPromiseTimeWarning").replace("??", OnlineOrderingUtil.GetOrderTypeName(aOLO.Order.OrderTypeID, aOLO)), aOLO.buttonHoverStyle);
    } else if (DSTET.IsToday && aOLO.Order.FutureDate && aOLO.Order.FutureMinute <= nowMinutes + aOLO.Order.OrderTypeSubType.WaitTime && aOLO.Order.FutureMinute !== -1) {
        await OnlineOrderingUtil.resetTimeFulfillmentTimeExpired(aOLO, aOLOModules);
    }
}
