import { Names } from "../../utils/i18n";
import { IaOLO } from '../../interfaces/aolo.interface';
import { Util } from '../../utils/util';
import { DialogCreators } from '../../utils/dialog-creators';
import { IAddressZone } from '../../online-ordering/interfaces/online-ordering.interface';
import { OnlineOrderingUtil } from '../../online-ordering/online-ordering-util';
import { IDeliveryAddress } from '../../shared/interfaces/delivery-address.interface';
import { IFillInAddress } from '../../online-ordering/interfaces/order-type.interface';
import { IAddressParsed } from '../../online-ordering/interfaces/shared.interfaces';
import { AddressType, AddressValidation } from '../../types/enums';
import { ITempAddress } from '../../interfaces/temp.interface';
import { IStoreLocation } from '../../components/pages/Locations/locations.interface';
import { IUserAddress } from '../../models/interfaces/user.interface';

export class AddressService {
    private readonly _item: IDeliveryAddress;
    private readonly _googleMapsAutocompleteOn: boolean;
    private readonly _selectAddressFunc: (status: AddressValidation, storeKey: string, address: ITempAddress | null) => void;
    private readonly _aOLO: IaOLO;

    constructor(addressId: string, addressDatalistId: string, apartmentId: string, locations: IStoreLocation[], aOLO: IaOLO, selectAddressFunc: (status: AddressValidation, storeKey: string, address: ITempAddress | null) => void) {
        this._item = this._createModel(addressId, addressDatalistId, apartmentId, locations);
        this._googleMapsAutocompleteOn = aOLO.data.Settings.GOGAC == 1;
        this._selectAddressFunc = selectAddressFunc;
        this._aOLO = aOLO;
    }

    private readonly _createModel = (addressId: string, addressDatalistId: string, apartmentId: string, locations: IStoreLocation[]): IDeliveryAddress => {
        return {
            addressId: addressId,
            addressDatalistId: addressDatalistId,
            apartmentId: apartmentId,
            locations: locations,
            randomId: 0,
            selectedStoreKey: "",
            selectedAddress: null,
            streetAddress: "",
            apartment: "",
            deliveryInstructions: "",
            autocomplete: null,
            geocoder: new google.maps.Geocoder()
        };
    }

    public init = (): void => {
        this._renderPage();
    }

    private readonly _renderPage = (): void => {
        if (this._googleMapsAutocompleteOn)
            this._initializeGoogleAutocomplete();
        else
            this._initializeAdoraAutocomplete();
    }

    public readonly checkDeliveryAddressAsync = async (userAddress: IUserAddress, displayError: boolean = true): Promise<void> => {
        //if (this._aOLO.data.Settings.ADDRLOOKUP === AddressLookupType.NONE)
        //    return;

        const tempAddress = this._getTempAddressFromUserAddress(userAddress);
        const closestStores = await this._getClosestStoresToAddressAsync(tempAddress.Latitude, tempAddress.Longitude);
        this._checkIfAddressIsInZone(closestStores, tempAddress, displayError);
    }

    /**
     * Initializes Adora Autocomplete for the delivery address input field.
     * Adds an event listener to populate suggestions and update the selected address based on user input.
     */
    private readonly _initializeAdoraAutocomplete = (): void => {
        const txtAddress = document.getElementById(this._item.addressId);
        if (!txtAddress)
            return;

        txtAddress.oninput = async () => {
            this._item.selectedStoreKey = "";
            this._item.selectedAddress = null;

            const allZipCodes = this._item.locations.flatMap(x => x.zipCodes);
            const uniqueZipCodes = Array.from(new Set(allZipCodes));
            await Util.UpdateAddressList(this._item.addressId, this._item.apartmentId, this._item.addressDatalistId, uniqueZipCodes.join(','), this._aOLO, null);
        };

        txtAddress.addEventListener('input', (e) => this._handleDatalistSelection(e as InputEvent));
    }

    /**
     * Handles selection of an address option from the datalist.
     * If a valid option is selected, it updates the address fields.
     *
     * @param {InputEvent} e - The input event triggered by the user.
     */
    private readonly _handleDatalistSelection = (e: InputEvent): void => {
        const input = e.target as HTMLInputElement;
        const listId = input.getAttribute('list');
        const options = Array.from(document.querySelectorAll(`#${listId} option`)) as HTMLInputElement[];
        const inputValue = input.value;

        Util.hideElement(`txt_delivery_apartment_limited_time_${this._item.randomId}`);

        let address = "";
        for (const option of options) {
            if (option.value === inputValue) {
                address = option.dataset.value || "";
                break;
            }
        }

        if (address)
            this._fillInAddressFromAdora(JSON.parse(address));
    }

    /**
     * Fills in the address fields based on the selected address item from Adora.
     * Verifies the address with Google Geocoder and fills in the parsed address information.
     *
     * @param {IFillInAddress} item - The address item selected from the datalist.
     */
    private readonly _fillInAddressFromAdora = (item: IFillInAddress): void => {
        const addressString = this._constructAddressString(item);

        const streetName = this._constructStreetName(item);
        const adoraAddress: IAddressParsed = {
            StreetNo: item.StNo,
            StreetName: streetName,
            StreetShortName: streetName,
            City: item.City,
            State: item.State,
            Zip: item.ZipCode,
            Latitude: item.Latitude,
            Longitude: item.Longitude
        };

        this._item.geocoder.geocode({ 'address': addressString }, async (results: any, status: string) => {
            let googleAddress = {} as any;
            if (status === 'OK') {
                const allCities = this._item.locations.flatMap(x => x.cities);
                const uniqueCities = Array.from(new Set(allCities));
                googleAddress = OnlineOrderingUtil.ParseGooglePlace(results[0], item.StNo, item.City, uniqueCities, this._aOLO);
                await this._verifyAndFillAddress(adoraAddress, googleAddress, item);
            } else if (status === 'ZERO_RESULTS') {
                await this._fillInParsedAddress(adoraAddress, googleAddress);
            } else {
                DialogCreators.messageBoxOk(Names("SystemCannotLocateAddress"), this._aOLO.buttonHoverStyle, null, this._item.addressId);
            }
        });
    }

    /**
     * Constructs a formatted address string for geocoding.
     *
     * @param {IFillInAddress} item - The address item containing individual address components.
     * @returns {string} - The constructed address string.
     */
    private readonly _constructAddressString = (item: IFillInAddress): string => {
        let address = item.StNo;
        if (item.StPreDirAbbr) address += ' ' + item.StPreDirAbbr;
        if (item.StName) address += ' ' + Util.FixStringCase(item.StName);
        if (item.StSuffixAbbr) address += ' ' + Util.FixStringCase(item.StSuffixAbbr);
        if (item.StPostDirAbbr) address += ' ' + item.StPostDirAbbr;
        if (item.City) address += ', ' + item.City;
        if (item.State) address += ', ' + item.State;
        if (item.ZipCode) address += ' ' + item.ZipCode;
        return address;
    }

    /**
     * Constructs the street name portion of the address.
     *
     * @param {IFillInAddress} item - The address item containing street information.
     * @returns {string} - The constructed street name.
     */
    private readonly _constructStreetName = (item: IFillInAddress): string => {
        return [
            item.StPreDirAbbr,
            item.StName && Util.FixStringCase(item.StName),
            item.StSuffixAbbr && Util.FixStringCase(item.StSuffixAbbr),
            item.StPostDirAbbr
        ].filter(Boolean).join(" ");
    }

    /**
     * Verifies and fills the parsed address based on Google Geocoder and Adora data.
     *
     * @param {IAddressFormat} adoraAddress - Address data from Adora.
     * @param {IAddressFormat} googleAddress - Parsed address data from Google.
     * @param {IFillInAddress} item - The selected address item from Adora.
     */
    private readonly _verifyAndFillAddress = async (adoraAddress: IAddressParsed, googleAddress: IAddressParsed, item: IFillInAddress): Promise<void> => {
        const googleStreetNameCompressed = googleAddress.StreetName.replace(/[^0-9a-z]/gi, '').toLowerCase();
        const googleStreetShortNameCompressed = googleAddress.StreetShortName.replace(/[^0-9a-z]/gi, '').toLowerCase();
        const adoraStreetNameCompressed = this._constructStreetName(item).replace(/[^0-9a-z]/gi, '').toLowerCase();

        // Compare ZIP codes or apply street name exceptions if necessary
        if (googleAddress.Zip !== item.ZipCode && this._zipException(googleAddress.Zip, item.ZipCode)) {
            googleAddress.Zip = this._zipException(googleAddress.Zip, item.ZipCode);
            await this._fillInParsedAddress(adoraAddress, googleAddress);
        } else if (googleStreetNameCompressed !== adoraStreetNameCompressed && googleStreetShortNameCompressed !== adoraStreetNameCompressed) {
            await this._handleStreetNameExceptions(adoraAddress, googleAddress, item);
        } else {
            await this._fillInParsedAddress(adoraAddress, googleAddress);
        }
    }

    /**
     * Handles street name exceptions for numbers (1st, 2nd, 3rd, etc.) by updating the address and re-validating.
     *
     * @param {IAddressFormat} adoraAddress - Address data from Adora.
     * @param {IAddressFormat} googleAddress - Parsed address data from Google.
     * @param {IFillInAddress} item - The selected address item from Adora.
     */
    private readonly _handleStreetNameExceptions = async (adoraAddress: IAddressParsed, googleAddress: IAddressParsed, item: IFillInAddress): Promise<void> => {
        const ordinalMap: Record<string, string> = {
            "1st": "First", "2nd": "Second", "3rd": "Third", "4th": "Fourth", "5th": "Fifth",
            "6th": "Sixth", "7th": "Seventh", "8th": "Eighth", "9th": "Ninth", "10th": "Tenth",
            "11th": "Eleventh", "12th": "Twelfth", "13th": "Thirteenth"
        };

        if (ordinalMap[item.StName.toLowerCase()]) {
            item.StName = ordinalMap[item.StName.toLowerCase()];
            this._fillInAddressFromAdora(item);
        } else {
            await this._fillInParsedAddress(adoraAddress, googleAddress);
        }
    }

    /**
     * Fills in the parsed address details from primary and secondary address sources.
     * Checks if the address falls within the delivery zone and validates it accordingly.
     *
     * @param {IAddressParsed} primaryAddr - The primary address information.
     * @param {IAddressParsed} secondaryAddr - The secondary address information.
     * @returns {Promise<void>} - The validation status of the address.
     */
    private readonly _fillInParsedAddress = async (primaryAddr: IAddressParsed, secondaryAddr: IAddressParsed): Promise<void> => {
        const address = OnlineOrderingUtil.getTempAddressNew();
        address.StreetNo = primaryAddr.StreetNo || secondaryAddr.StreetNo || "0";
        address.StreetName = primaryAddr.StreetShortName || secondaryAddr.StreetShortName || "";
        address.City = primaryAddr.City || secondaryAddr.City || this._aOLO.storeInfo.City || "";
        address.State = primaryAddr.State || secondaryAddr.State || "";
        address.Zip = primaryAddr.Zip || secondaryAddr.Zip || "";
        address.Latitude = secondaryAddr.Latitude || primaryAddr.Latitude || 0;
        address.Longitude = secondaryAddr.Longitude || primaryAddr.Longitude || 0;

        const closestStores = await this._getClosestStoresToAddressAsync(address.Latitude, address.Longitude);
        this._checkIfAddressIsInZone(closestStores, address);
    }

    private readonly _calculateDistanceFromDeliveryAddress = (latitude: number, longitude: number): void => {
        // Calculate and update distance for each location
        for (const location of this._item.locations) {
            location.deliveryDistance = Util.Float2(
                Util.GetDistance(latitude, longitude, location.latitude, location.longitude, "M")
            );
        }

        // Sort locations by distance
        this._item.locations.sort((a: IStoreLocation, b: IStoreLocation) => a.deliveryDistance - b.deliveryDistance);
    }

    private readonly _getClosestStoresToAddressAsync = async (latitude: number, longitude: number): Promise<IStoreLocation[]> => {
        // Calculate distance of stores from delivery address and get zones for closest stores
        this._calculateDistanceFromDeliveryAddress(latitude, longitude);

        const closestStores = this._item.locations.filter(x => x.deliveryDistance <= 20);
        // Get store zones only if location has no zones
        await this._getStoreDeliveryZonesAsync(closestStores.filter(x => x.zones.length == 0).map(x => x.storeId));
        return closestStores;
    }

    private readonly _getStoreDeliveryZonesAsync = async (storeIds: number[]): Promise<void> => {
        if (storeIds.length == 0)
            return;

        const deliveryZones = await this._aOLO.Modules.ProfileService.loadDeliveryZonesForLocations(storeIds, this._aOLO);
        for (const store of deliveryZones) {
            const location = this._item.locations.find(x => x.storeKey === store.storeKey);
            if (location)
                location.zones = store.zones;
        }
    }

    private readonly _checkIfAddressIsInZone = (stores: IStoreLocation[], address: ITempAddress, displayError: boolean = true): void => {
        for (const location of stores) {
            const zoneStatus = OnlineOrderingUtil.CheckZones(address.Latitude, address.Longitude, location.zones);
            if (zoneStatus && (zoneStatus.ZTID === 1 || (location.settings.ThirdPartyDelService && location.settings.ThirdPartyDeliveryMethod === 1))) {
                // 2DO: Maybe remove this or Check other stores
                let validationType = AddressValidation.VALID;
                if (zoneStatus.ILT && !this._isAddressDeliverable(zoneStatus)) {
                    Util.showElement(`txt_delivery_apartment_limited_time_${this._item.randomId}`);
                    validationType = AddressValidation.OUT_OF_TIME;
                    //DialogCreators.messageBoxOk(Names("OutTimeDelivery"), this._aOLO.buttonHoverStyle, null, `txt_delivery_address_${this._item.randomId}`);
                }

                //if (this._aOLO.data.Settings.ISCC)
                //    await this._functions.setStoreOrderInfo(zoneStat.SID, this._aOLO.Order.OrderTypeID);

                const newTempAddress = this._createTempAddress(address, zoneStatus.ZID);

                //OnlineOrderingUtil.setOrderTypeCharge(this._aOLO, aOLOModules);
                //OnlineOrderingUtil.GUI_SetOrder(this._aOLO, aOLOModules);

                Util.setFocus(this._item.apartmentId);
                this._item.selectedStoreKey = location.storeKey;
                this._item.selectedAddress = newTempAddress;
                this._selectAddressFunc(validationType, location.storeKey, newTempAddress);

                // 2DO: Set emitter to order cart to have address
                return;
            }
        }

        if (displayError)
            DialogCreators.messageBoxOk(Names("DeliveryAddressOutsideAllZonesError"), this._aOLO.buttonHoverStyle, null, this._item.addressId);

        this._item.selectedStoreKey = "";
        this._item.selectedAddress = null;
        this._selectAddressFunc(AddressValidation.OUT_OF_DELIVERY_ZONE, "", address);
        // 2DO: Set emitter to order cart to delete address
        //return AddressValidation.OUT_OF_DELIVERY_ZONE;
    }

    /**
     * Creates a temporary address based on provided address details and zone ID.
     *
     * @param {object} details - The address details to populate the temporary address.
     * @param {number} zoneId - The zone ID for the delivery area.
     * @returns {IAddressParsed} - The newly created temporary address object.
     */
    private readonly _createTempAddress = (details: ITempAddress, zoneId: number): ITempAddress => {
        const aptNum = this._item.apartment;

        return {
            AddressID: 0,
            AddressTypeID: aptNum ? AddressType.APT : AddressType.HOUSE,
            AddressName: 'Home',
            IsPrimary: true,
            StreetNo: details.StreetNo,
            StreetName: details.StreetName,
            Address2: aptNum,
            Address3: details.Address3 || "",
            Address4: details.Address4 || "",
            Address5: details.Address5 || "",
            City: details.City,
            State: details.State,
            Zip: details.Zip,
            CountryID: details.CountryID || null,
            Latitude: details.Latitude,
            Longitude: details.Longitude,
            XStreet: "",
            ZoneID: zoneId,
            Grid: "",
            Distance: 0,
            DeliveryInstruction: this._item.deliveryInstructions
        };
    }

    /**
     * Checks if the current time is within deliverable hours for the given zone.
     *
     * @param {any} zoneStatus - The zone status object containing deliverable hours.
     * @returns {boolean} - Whether the address is within deliverable hours.
     */
    private readonly _isAddressDeliverable = (zoneStatus: IAddressZone): boolean => {
        const currentDate = Util.NowStore(this._aOLO.Temp.TimeOffset);
        const currentDay = currentDate.getDay();
        const currentMinutes = currentDate.getHours() * 60 + currentDate.getMinutes();
        const schedule = zoneStatus.LIMTIME.find(x => x.weekdayId === currentDay);
        return schedule ? schedule.startMinute < currentMinutes && schedule.endMinute > currentMinutes : false;

        // 2DO: Check this later maybe
        //if (this._aOLO.Order.FutureDate) {
        //    nowWeekNo = this._futureOrderDate ? this._futureOrderDate.getUTCDay() : new Date().getUTCDay();
        //    nowMinutes = aOLO.Order.FutureMinute;
        //    schToday = zoneStat.LIMTIME.find(x => x.WeekDay === nowWeekNo);
        //    nowIsDeliverable = !schToday ? false : (schToday.StartMinute < nowMinutes && schToday.EndMinute > nowMinutes);
        //}
    }

    /**
     * Checks for ZIP code exceptions where a Google ZIP code might differ from Adora's.
     *
     * @param {string} googleZip - The ZIP code from Google.
     * @param {string} adoraZip - The ZIP code from Adora.
     * @returns {string} - Returns the Adora ZIP if an exception is found, otherwise an empty string.
     */
    private readonly _zipException = (googleZip: string, adoraZip: string): string => {
        const zipExceptions: Record<string, string> = {
            "84009": "84095",
            "91350": "91390"
        };

        return zipExceptions[adoraZip] === googleZip ? adoraZip : "";
    }

    /**
     * Initializes Google Maps Autocomplete and Directions Service.
     * Sets up the autocomplete for address input and bounds to the store location.
     * Also sets up directions service if it hasn't been initialized.
     */
    private readonly _initializeGoogleAutocomplete = (): void => {
        const input = document.getElementById(this._item.addressId) as HTMLInputElement | null;
        if (!input || !google)
            return;

        try {
            const storeLocation = new google.maps.LatLng(this._aOLO.storeInfo.Latitude, this._aOLO.storeInfo.Longitude);
            this._item.autocomplete = new google.maps.places.Autocomplete(input, { types: ['geocode'] });
            this._item.autocomplete.setBounds(new google.maps.LatLngBounds(storeLocation, storeLocation));

            google.maps.event.addListener(this._item.autocomplete, 'place_changed', async () => {
                await this._handlePlaceSelection();
            });

        } catch (error: unknown) {
            if (error instanceof Error)
                Util.LogError("InitializeGoogleMap", error, this._aOLO);
        }
    }

    /**
     * Handles the event when a place is selected in Google Maps Autocomplete.
     * Determines if reverse geocoding is needed (e.g., for specific countries like Mexico),
     * then calls a function to fill in the address with selected or overridden values.
     */
    private readonly _handlePlaceSelection = async (): Promise<void> => {
        if (!this._item.autocomplete)
            return;

        const place = this._item.autocomplete.getPlace();

        // Reverse geocode if the country is Mexico (CountryId: 2) to fetch the city.
        if (this._getCountryIdFromPlace(place) === 2)
            this._fetchCityForMexico(place);
        else
            await this._fillInSelectedAddress(place);
    }

    /**
     * Fetches city for Mexico by performing reverse geocoding.
     * This is needed due to the unique structure of place data in Mexico.
     *
     * @param {google.maps.places.PlaceResult} place - The selected place from Autocomplete.
     */
    private readonly _fetchCityForMexico = (place: google.maps.places.PlaceResult): void => {
        const cityType = "administrative_area_level_3";

        this._item.geocoder.geocode({ location: place.geometry?.location }, async (results: any, status: string) => {
            if (status === 'OK' && results) {
                let cityOverride = "";
                for (const result of results) {
                    if (!result.types.includes(cityType))
                        continue;

                    for (const component of result.address_components) {
                        if (component.types.includes(cityType))
                            cityOverride = component["long_name"];
                    }
                }
                await this._fillInSelectedAddress(place, cityOverride);
            } else {
                DialogCreators.messageBoxOk(
                    Names("SystemCannotLocateAddress"),
                    this._aOLO.buttonHoverStyle,
                    null,
                    this._item.addressId
                );
            }
        });
    }

    /**
     * Fills in the address fields based on the selected place from Google Maps Autocomplete.
     * If a cityOverride is provided, it will replace the city obtained from the place.
     *
     * @param {google.maps.places.PlaceResult} place - The place selected from Google Autocomplete.
     * @param {string | null} cityOverride - Optional city override for cases where geocoding is required.
     * @returns {Promise<boolean>} - Returns false if the address is outside the delivery zone.
     */
    private readonly _fillInSelectedAddress = async (place: google.maps.places.PlaceResult, cityOverride: string | null = null): Promise<void> => {
        // Initialize address object
        const address = OnlineOrderingUtil.getTempAddressNew();
        address.Latitude = place.geometry?.location?.lat() || 0;
        address.Longitude = place.geometry?.location?.lng() || 0;

        // Variables for additional address details
        let stateFullName = "";
        let countryLong = "";

        for (const component of place.address_components || []) {
            const addressType = component.types[0];

            switch (addressType) {
                case "street_number":
                    address.StreetNo = component['short_name'];
                    break;
                case "route":
                    address.StreetName = component['long_name'];
                    break;
                case "locality":
                    address.City = component['long_name'];
                    break;
                case "administrative_area_level_1":
                    stateFullName = component['long_name'];
                    address.State = component['short_name'];
                    break;
                case "postal_code":
                    address.Zip = component['short_name'];
                    break;
                case "neighborhood":
                    address.Address3 = component['long_name'] !== "" ? component['long_name'] : component['short_name'];
                    break;
                case "sublocality_level_1":
                case "sublocality":
                    address.Address4 = component['long_name'] !== "" ? component['long_name'] : component['short_name'];
                    break;
                case "country": {
                    countryLong = component['long_name'];
                    address.CountryID = this._getCountryIdFromPlace(place);
                    break;
                }
            }
        }

        if (cityOverride)
            address.City = cityOverride;

        // Validate and adjust address fields
        this._validateAddressCity(address, address.Address3);
        this._validateAddressStreetNumber(address);

        this._setDeliveryAddressDisplay(address, countryLong, stateFullName);

        const closestStores = await this._getClosestStoresToAddressAsync(address.Latitude, address.Longitude);
        this._checkIfAddressIsInZone(closestStores, address);
    }

    /**
     * Ensures that the city field is set correctly by checking against known neighborhoods.
     *
     * @param {IAddressFormat} address - The address object being validated.
     * @param {string} neighborhood - The neighborhood name.
     * @param {string | null} cityOverride - Optional city override value.
     */
    private readonly _validateAddressCity = (address: ITempAddress, neighborhood: string): void => {
        if (address.City || !neighborhood)
            return;

        const allCities = this._item.locations.flatMap(x => x.cities);
        if (allCities.includes(neighborhood))
            address.City = neighborhood;
    }

    /**
     * Validates the street number of the address and attempts to fill it if it's missing.
     *
     * @param {IAddressFormat} address - The address object being validated.
     */
    private readonly _validateAddressStreetNumber = (address: ITempAddress): void => {
        if (address.StreetNo || address.StreetNo === "null")
            return;

        const parsedAddress = OnlineOrderingUtil.ParseAddress(this._item.streetAddress, null, this._aOLO);
        if (parsedAddress.StreetNo)
            address.StreetNo = parsedAddress.StreetNo;
    }

    /**
     * Sets the delivery address display in the input field based on the selected address.
     *
     * @param {IAddressFormat} address - The address object to display.
     * @param {string} countryLong - The full country name.
     * @param {string} stateFullName - The full state name (for specific formatting).
     */
    private readonly _setDeliveryAddressDisplay = (address: ITempAddress, countryLong: string, stateFullName: string): void => {
        const input = document.getElementById(this._item.addressId) as HTMLInputElement;
        if (!input)
            return;

        if (address.CountryID !== 2)
            input.value = `${address.StreetNo} ${address.StreetName}, ${address.City}, ${address.State} ${address.Zip}`;
        else // Special format for Mexico
            input.value = `${address.StreetName} ${address.StreetNo}, ${address.Address4}, ${address.City}, ${address.Zip} ${stateFullName}, ${address.State}, ${countryLong}`;
    }

    /**
     * Retrieves the country ID from the provided Google Maps PlaceResult.
     * Searches through the address components to find the country ISO code and
     * matches it with known country data to return the corresponding country ID.
     *
     * @param {google.maps.places.PlaceResult} place - The Google Maps PlaceResult object.
     * @returns {number} - The country ID if found, or -1 if not found.
     */
    private readonly _getCountryIdFromPlace = (place: google.maps.places.PlaceResult): number => {
        if (!place.address_components)
            return -1;

        const countryComponent = place.address_components.find(x => x.types.includes("country"));
        if (!countryComponent)
            return -1;

        const countryIsoCode = countryComponent['short_name'].toLowerCase().trim();
        const country = this._aOLO.data.Countries.find(x => x.IsoCode.toLowerCase().trim() === countryIsoCode);
        return country ? country.CountryID : -1;
    }

    private readonly _getTempAddressFromUserAddress = (address: IUserAddress): ITempAddress => {
        return {
            AddressID: address.AID,
            AddressTypeID: address.ATID,
            AddressName: address.ANAM,
            IsPrimary: address.IPRM,
            StreetNo: address.STRNO,
            StreetName: address.ADDR1,
            Address2: address.ADDR2,
            Address3: address.ADDR3,
            Address4: address.ADDR4,
            Address5: address.ADDR5,
            City: address.CITY,
            State: address.STA,
            Zip: address.ZIP,
            CountryID: address.CID,
            Latitude: address.LAT,
            Longitude: address.LON,
            XStreet: address.XSTR,
            ZoneID: address.ZID || 0,
            Grid: address.GRD || "",
            Distance: address.DIS || 0,
            DeliveryInstruction: address.DI || ""
        };
    }
}