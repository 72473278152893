import { IUser } from '../../models/interfaces/user.interface';
import { IMyHistory } from '../../shared/interfaces/history.interface';
import { IMyOrders } from '../../shared/interfaces/my-orders.interface';
import { IChangePasswordModel, IFetchFavoriteStoresResponse, IFetchOrderDetails, IGetOrderItem, IGetPhoneCodeResponse, IResetPasswordDTO, IResetPasswordModel, IaOLOUser, IaOLOUserLoyaltyReward } from './interfaces/profile-service.interfaces';
import { ITracker } from '../../brand/interfaces/tracker.interface';
import { AdoraHttpClient } from '../../utils/http-client';
import { Util } from '../../utils/util';
import { Names } from '../../utils/i18n';
import { DialogCreators } from '../../utils/dialog-creators';
import { IaOLO } from '../../interfaces/aolo.interface';
import { IStoreLocation, IStoreLocationZone } from '../../components/pages/Locations/locations.interface';

/**
 * A service class that provides methods to manage user profile related functionality.
 * @class
 */
export class ProfileService {
    private _apiVersion: string = '1.0';
    private _apiLoyaltyApiUrl: string = "";
    private _apiProfileUrl: string = `${location.origin}/api/v${this._apiVersion}/profile/`;
    private _apiLocationsUrl: string = `${location.origin}/api/v${this._apiVersion}/locations/`;
    private _apiOrderUrl: string = `${location.origin}/api/v${this._apiVersion}/order/`;
    private _apiTrackerUrl: string = `${location.origin}/api/v${this._apiVersion}/tracker/`;

    constructor(loyaltyApiUrl: string | null) {
        if (loyaltyApiUrl) {
            this._apiLoyaltyApiUrl = `${loyaltyApiUrl}`;
        }
    }

    /**
     * Gets the JWT token from local storage.
     * @returns {string} The JWT token.
     */
    get jwt(): string | null {
        return AdoraHttpClient.getJWT();
    }

    get refresh_token(): string | null {
        return AdoraHttpClient.getRefreshToken();
    }

    /**
     * Makes an HTTP request.
     * @private
     * @async
     * @param {string} method - The HTTP method (e.g. 'GET', 'POST', 'PUT', 'DELETE').
     * @param {string} url - The URL of the HTTP resource.
     * @param {object|null} payload - The payload to send with the request.
     * @param {string|null} token - The authorization token to send with the request.
     * @returns {Promise<Response>} A promise that resolves to the HTTP response.
     */
    private readonly _httpRequest = async (showProgress: boolean, method: string, url: string, localAolo: IaOLO, payload: object | null, token: string | null = null, refreshToken: string | null = null): Promise<Response> => {
        const response = await AdoraHttpClient.httpRequest(showProgress, method, url, payload, token, refreshToken);
        if (response.status === 500) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            DialogCreators.messageBoxOk(Names("UnexpectedError500"), localAolo.buttonHoverStyle, null, null, result.errorId);
        }

        if (response.status !== 401) {
            // Check for new JWT
            if (response.headers.has('Authorization')) {
                const authHeader = response.headers.get('Authorization');
                const token = authHeader?.split(' ')[1] || "";
                AdoraHttpClient.setJWT(token);
            }
            if (response.headers.has('rtk')) {
                const refreshToken = response.headers.get('rtk');
                if (refreshToken)
                    AdoraHttpClient.setRefreshToken(refreshToken);
            }
            return response;
        }

        this.notAuthHttpResourceEvent();
        return response;
    }

    /**
     * Callback function for not authorized HTTP resource event.
     * @returns {void}
     */
    public notAuthHttpResourceEvent = (): void => {
        const self = this;

        // 2DO: Emit LogOut
        //Common.logout(localAolo.isBrandSignIn ? function () {
        //    if (self._brandFunction)
        //        self._brandFunction("signin", true);
        //} : async function () {
        //    if (self._oloFunction)
        //        self._oloFunction(localAolo.Temp.languageCode)
        //}, localAolo, true);
    }

    public refreshToken = async (localAolo: IaOLO): Promise<void> => {
        await this._httpRequest(false, "GET", `${this._apiProfileUrl}refresh_token`, localAolo, null, this.jwt, this.refresh_token);
    }

    public getPhoneCode = async (phone: string, countryId: number, localAolo: IaOLO): Promise<IGetPhoneCodeResponse> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": localAolo.storeInfo.StoreKey, "phone": phone, "countryId": countryId }), localAolo.pkey);
        const response = await this._httpRequest(true, 'POST', `${this._apiProfileUrl}get-phone-code`, localAolo, payload);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    /**
     * Registers a new user account.
     * @async
     * @param cp A complex object representing the user's profile and contact information.
     * @returns {Promise<number | null>} The response data from the server, or null if the request failed.
     */
    public signUp = async (cp: any, localAolo: IaOLO): Promise<number | null> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": localAolo.storeInfo.StoreKey, "location": "olo", "profile": cp }), localAolo.pkey);
        const response = await this._httpRequest(true, 'POST', `${this._apiLoyaltyApiUrl}olo/sign_up`, localAolo, payload);
        if (response.status !== 200) {
            DialogCreators.messageBoxOk(await AdoraHttpClient.getResponsePayload(response), localAolo.buttonHoverStyle);
            return null;
        }

        const data = await AdoraHttpClient.getResponsePayload(response);
        if (data.success)
            return data.data;

        DialogCreators.messageBoxOk(data.message, localAolo.buttonHoverStyle);
        return null;
    }

    /**
     * Checks whether a user account already exists with the given email or phone number, for a logged-in user.
     * @async
     * @param email The email address to check for.
     * @param phone The phone number to check for.
     * @returns {Promise<number>} True if an account already exists with the given email or phone number, false otherwise. Null if the request failed.
     */
    public checkIfLoggedInExists = async (profileId: number, email: string, phone: string, countryId: number, localAolo: IaOLO): Promise<number> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": localAolo.storeInfo.StoreKey, "profileId": profileId, "email": email, "phone": phone, "countryId": countryId }), localAolo.pkey);
        const response = await this._httpRequest(false, 'POST', `${this._apiProfileUrl}check`, localAolo, payload, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : -10;
    }

    /**
     * Gets the user's profile data from the server.
     * @async
     * @returns {Promise<any|null>} A promise that resolves with the user's profile data if successful, otherwise null.
     */
    public getUser = async (localAolo: IaOLO): Promise<IUser | null> => {
        if (!this.jwt) return null;
        const response = await this._httpRequest(true, 'POST', `${this._apiProfileUrl}profile`, localAolo, null, this.jwt, this.refresh_token);
        if (response.status == 200) {
            const result = await AdoraHttpClient.getResponsePayload(response);
            if (result.success)
                return this._mapUser(JSON.parse(result.profile) as IaOLOUser);
        }
        return null;
    }

    /**
    * Signs the user out.
    * @async
    * @returns {Promise<boolean>} A promise that resolves with true if the sign out was successful, otherwise false.
    */
    public signOut = async (localAolo: IaOLO): Promise<boolean> => {
        if (this.jwt != "" && localAolo.User && localAolo.User.ProfileId != 0) {
            const response = await this._httpRequest(false, 'POST', `${this._apiProfileUrl}sign-out`, localAolo, null, this.jwt, this.refresh_token);
            return (response.status == 200);
        }
        return true;
    }

    public updateUserDarkMode = (darkMode: boolean, localAolo: IaOLO): void => {
        this._httpRequest(false, 'POST', `${this._apiProfileUrl}update-user-dark-mode`, localAolo, { "darkMode": darkMode }, this.jwt, this.refresh_token);
    }

    public updateUserLanguage = (language: string, localAolo: IaOLO): void => {
        this._httpRequest(false, 'POST', `${this._apiProfileUrl}update-user-language`, localAolo, { "language": language }, this.jwt, this.refresh_token);
    }

    /**************************
     ******** Password ********
     **************************/

    /**
     * Checks whether the user's current password matches the given password.
     * @async
     * @param oldPassword The user's current password.
     * @returns {Promise<boolean>} The response data from the server, or false if the request failed.
     */
    public checkPassword = async (oldPassword: string, localAolo: IaOLO): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify({ "oldPassword": oldPassword }), localAolo.pkey);
        const response = await this._httpRequest(false, 'POST', `${this._apiProfileUrl}check-password`, localAolo, payload, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**
     * Changes the user's password.
     * @async
     * @param {string} oldPassword - The current password.
     * @param {string} newPassword - The new password.
     * @returns {Promise<boolean>} - A promise that resolves to `true` if the password was changed successfully, or `false` otherwise.
     */
    public changePassword = async (data: IChangePasswordModel, localAolo: IaOLO): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest(true, 'POST', `${this._apiProfileUrl}change-password`, localAolo, payload, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    };

    /**
    * Sends a reset password request to the server.
    * @async
    * @param {string} data - The data required for the reset password request.
    * @returns {Promise<boolean>} A promise that resolves with true if the reset password request was successful, otherwise false.
    */
    public resetPassword = async (data: IResetPasswordModel, localAolo: IaOLO): Promise<IResetPasswordDTO> => {
        const payload = await Util.encrypt(JSON.stringify(data), localAolo.pkey);
        const response = await this._httpRequest(true, 'POST', `${this._apiProfileUrl}reset-password`, localAolo, payload);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**
     * Sends a POST request to save a profile object to the API.
     * @async
     * @param {IaOLOUser} profile - The profile object to be saved.
     * @returns {Promise<boolean>} - A promise that resolves with the response data if the request is successful, or null if it fails.
     */
    public setProfile = async (profile: IaOLOUser, localAolo: IaOLO): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify(profile), localAolo.pkey);
        const response = await this._httpRequest(true, 'POST', `${this._apiProfileUrl}save-profile`, localAolo, payload, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**
     * Sends a POST request to delete a profile object to the API.
     * @async
     * @param {number} profileId - The profile object to be deleted.
     * @param {password} string - The profile object to be deleted.
     * @returns {Promise<boolean>} - A promise that resolves with the response data if the request is successful, or null if it fails.
     */
    public deleteProfile = async (profileId: number, password: string, localAolo: IaOLO): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify({ "profileId": profileId, "password": password }), localAolo.pkey);
        const response = await this._httpRequest(true, 'POST', `${this._apiProfileUrl}delete-profile`, localAolo, payload, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }


    public saveIncompleteProfile = async (profile: IaOLOUser, localAolo: IaOLO): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": localAolo.storeInfo.StoreKey, "profile": profile }), localAolo.pkey);
        const response = await this._httpRequest(true, 'POST', `${this._apiProfileUrl}save-incomplete-profile`, localAolo, payload);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**************************
     ******* Locations ********
     **************************/

    /**
     * Loads the locations associated with the given brand ID
     * @async
     * @param {string} brandId - The ID of the brand whose locations to load
     * @return {Promise<ILocation[]>} A Promise that resolves to an array of locations associated with the given brand ID, or an empty array if no locations were found
     */
    public loadLocations = async (brandId: string, localAolo: IaOLO): Promise<IStoreLocation[]> => {
        const response = await this._httpRequest(false, "GET", `${this._apiLocationsUrl}stores/${brandId}`, localAolo, null, null);
        if (response.status == 200) {
            const respData = await AdoraHttpClient.getResponsePayload(response);
            if (respData.result != "")
                return this._mapLocationsData(JSON.parse(respData.result));
        }
        return [];
    }

    private readonly _mapLocationsData = (data: any): IStoreLocation[] => {
        const mappedData = data.map((x: any) => ({
            storeKey: x.SKEY,
            storeId: x.SID,
            name: x.NAM,
            phone: x.PHN,
            streetNumber: x.SNUM,
            address1: x.ADD1,
            address2: x.ADD2,
            address3: x.ADD3,
            address4: x.ADD4,
            address5: x.ADD5,
            city: x.CITY,
            state: x.STA,
            zip: x.ZIP,
            countryId: x.CID,
            latitude: x.LAT,
            longitude: x.LON,
            statusId: x.STATID,
            url: x.OOURL,
            settings: x.SETTs.VAL,
            zones: [],
            cities: x.CITs?.map((y: any) => y.CITY) || [],
            orderTypes: x.OTs?.map((y: any) => y.OTID) || [],
            zipCodes: x.ZIPs?.map((y: any) => y.ZIP) || [],
            holidays: x.HOLs?.map((y: any) => ({
                id: y.HID,
                statusId: y.HSID,
                startMinute: y.SMIN,
                endMinute: y.EMIN
            })) || [],
            hours: x.HRs?.map((y: any) => ({
                weekdayId: y.WID,
                startMinute: y.SMIN,
                endMinute: y.EMIN
            })) || []
        })) || [];

        return mappedData;
    }

    public loadDeliveryZonesForLocations = async (storeIds: number[], localAolo: IaOLO): Promise<{ storeKey: string, zones: IStoreLocationZone[] }[]> => {
        const response = await this._httpRequest(false, "GET", `${this._apiLocationsUrl}stores-delivery-zones/?stores=${JSON.stringify(storeIds)}`, localAolo, null, null);
        if (response.status == 200) {
            const respData = await AdoraHttpClient.getResponsePayload(response);
            if (respData.result != "")
                return this._mapLocationDeliveryZonesData(JSON.parse(respData.result));
        }
        return [];
    }

    private readonly _mapLocationDeliveryZonesData = (data: any): { storeKey: string, zones: IStoreLocationZone[] }[] => {
        const mappedData = data.map((x: any) => ({
            storeKey: x.SKEY,
            zones: x.ZONs?.map((y: any) => ({
                storeId: y.SID,
                id: y.ZID,
                typeId: y.ZTID,
                charge: y.ZCHRG,
                minimumDeliveryAmount: y.MINDEL,
                isLimitedTime: y.ILT,
                limitedTimes: y.LIMTIME?.map((z: any) => ({
                    weekdayId: z.WID,
                    startMinute: z.SMIN,
                    endMinute: z.EMIN
                })) || [],
                polygons: y.PGON?.map((z: any) => ({
                    latitude: z.LAT,
                    longitude: z.LON
                })) || []
            })) || []
        })) || [];

        return mappedData;
    }

    /**
     * Sends a request to the server to fetch the list of favorite stores.
     * @async
     * @returns {Promise<Array<IFetchFavoriteStoresResponse> | null>} A Promise that resolves to an array of objects, each containing the store key and name, or null if the request failed.
     */
    public fetchFavoriteStores = async (localAolo: IaOLO): Promise<IFetchFavoriteStoresResponse[]> => {
        const response = await this._httpRequest(false, "GET", `${this._apiProfileUrl}favorite-store`, localAolo, null, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : [];
    }

    /**
     * Sends a request to the server to save the favorite store with the given storeKey and name.
     * @async
     * @param {string} storeKey - The key of the store to be saved.
     * @param {string} name - The name of the store to be saved.
     * @param {boolean} isDefaultStore - A boolean indicating whether the store is the user's default store.
     * @returns {Promise<boolean>} A Promise that resolves to a boolean indicating whether the store was saved successfully.
     */
    public saveFavoriteStore = async (storeKey: string, localAolo: IaOLO): Promise<boolean> => {
        const response = await this._httpRequest(true, "POST", `${this._apiProfileUrl}favorite-store`, localAolo, { StoreKey: storeKey }, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**
     * Sends a request to the server to delete the favorite store with the given storeKey.
     * @async
     * @param {string} storeKey - The key of the store to be deleted.
     * @returns {Promise<boolean>} A Promise that resolves to a boolean indicating whether the store was deleted successfully.
     */
    public deleteFavoriteStore = async (storeKey: string, localAolo: IaOLO): Promise<boolean> => {
        const response = await this._httpRequest(true, "POST", `${this._apiProfileUrl}favorite-store`, localAolo, { "StoreKey": storeKey }, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**************************
     ******* My Orders ********
     **************************/

    /**
     * Asynchronously fetches orders from the API.
     * @async
     * @returns {Promise<IMyOrders[]>} - A Promise that resolves with an array of orders.
     */
    public getOrders = async (localAolo: IaOLO): Promise<IMyOrders[]> => {
        const response = await this._httpRequest(false, "GET", `${this._apiProfileUrl}profile-orders`, localAolo, null, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : [];
    }

    /**
     * Fetches the details of the order with the given ID
     * @async
     * @param {number} orderId - The ID of the order to fetch details for
     * @return {Promise<IFetchOrderDetails | null>} A Promise that resolves to an object containing details of the order with the given ID, or null if the request failed or no order was found
     */
    public fetchOrderDetails = async (orderId: number, localAolo: IaOLO): Promise<IFetchOrderDetails | null> => {
        const response = await this._httpRequest(true, "GET", `${this._apiProfileUrl}order-detail/?orderId=${orderId}`, localAolo, null, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    /**
     * Saves the given order as a favorite with the given name
     * @async
     * @param {number} orderID - The ID of the order to save as a favorite
     * @param {string} name - The name to save the favorite order under
     * @return {Promise<boolean>} A Promise that resolves to true if the operation succeeded, or false otherwise
     */
    public saveFavoriteOrder = async (orderID: number, name: string, localAolo: IaOLO): Promise<boolean> => {
        const response = await this._httpRequest(true, "POST", `${this._apiProfileUrl}toggle-favorite-order`, localAolo, { "OrderID": orderID, "Name": name }, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : false;
    }

    /**
     * Fetches the items associated with the given order ID
     * @async
     * @param {number} orderId - The ID of the order whose items to fetch
     * @return {Promise<string | null>} A Promise that resolves to a JSON string representing the items associated with the given order ID, or null if the request failed or no items were found
     */
    public getOrderItems = async (orderId: number, localAolo: IaOLO): Promise<IGetOrderItem[]> => {
        const response = await this._httpRequest(true, "GET", `${this._apiOrderUrl}${orderId}`, localAolo, null, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : [];
    }

    /**************************
     ******* My History *******
     **************************/

    /**
     * Fetches loyalty history data for the specified date.
     * @async
     * @param {string} historyDate - The date for which to fetch the loyalty history, in the format "YYYY-MM-DD".
     * @returns {Promise<IMyHistory|null>} - A Promise that resolves with the loyalty history data, or null if the request fails.
     */
    public fetchLoyaltyHistory = async (historyDate: string, localAolo: IaOLO): Promise<IMyHistory[] | null> => {
        const response = await this._httpRequest(false, "GET", `${this._apiProfileUrl}history/?date=${historyDate}`, localAolo, null, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    /**************************
     ******** Tracker *********
     **************************/

    /**
     * Fetches tracker data for the specified order ID.
     * @async
     * @param {number} orderId - The ID of the order for which to fetch tracker data.
     * @returns {Promise<IFetchTrackerDataResponse|null>} - A Promise that resolves with the tracker data, or null if the request fails.
     */
    public fetchTrackerData = async (orderId: number, localAolo: IaOLO): Promise<ITracker | null> => {
        const response = await this._httpRequest(true, "POST", this._apiTrackerUrl, localAolo, { "storeKey": localAolo.storeInfo.StoreKey, "orderId": orderId }, null);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    /**
     * Fetches tracker user data for the specified order ID.
     * @async
     * @param {number} orderId - The ID of the order for which to fetch tracker user data.
     * @returns {Promise<IFetchTrackerDataResponse|null>} - A Promise that resolves with the tracker user data, or null if the request fails.
     */
    public fetchTrackerUserData = async (orderId: number, localAolo: IaOLO): Promise<ITracker | null> => {
        if (!localAolo.storeInfo.StoreKey) {
            Util.LogError(`fetchTrackerUserData -> ${JSON.stringify(localAolo.storeInfo)}`, new Error(), localAolo);
        }
        const response = await this._httpRequest(true, "POST", `${this._apiTrackerUrl}user`, localAolo, { "storeKey": localAolo.storeInfo.StoreKey, "orderId": orderId }, this.jwt, this.refresh_token);
        return (response.status == 200) ? await AdoraHttpClient.getResponsePayload(response) : null;
    }

    private readonly _mapUser = (data: IaOLOUser): IUser => {
        const user: IUser = {
            CustomerId: data.CID,
            ProfileId: data.PID,
            StoreId: data.SID,
            Name: data.NAM,
            LastName: data.LNAM,
            Phone: data.PHN,
            CountryCodeId: data.CCDID,
            Email: data.EML,
            IsMarketingSurvey: data.ISV,
            IsMarketingEmail: data.IEML,
            IsMarketingText: data.ITXT,
            MarketingPhone: data.MKPHN,
            MarketingCountryId: data.MKCCDID,
            BirthDate: data.BD,
            IsFavoriteStore: data.FAVST,
            FavoriteStoreId: data.FAVSTID,
            FavoriteStores: data.FAVSTRs?.map(x => x.SKEY) || [],
            CultureCode: data.CLT,
            IsDarkMode: data.IDK,
            IsLoyalty: data.ILY,
            IsBlocked: data.IBLKD,
            IsAge13: data.A13,
            TermsPrivacyPolicy: data.TPP,
            Addresses: data.ADRS,
            Allergies: data.ALGS,
            Dietary: data.DITS,
            Wallet: data.WLTS,
            IsProfileComplete: data.IPC,
            LoyaltyData: data.LOYDT ? {
                Rewards: data.LOYDT.rewards?.map((rew: IaOLOUserLoyaltyReward) => ({
                    RewardId: rew.RID,
                    CouponId: rew.CPID,
                    ExpiryDate: rew.EXDT,
                    Expired: rew.EXP,
                    Names: rew.NAMs
                })) || [],
                CurrentPoints: data.LOYDT.current_points,
                EarnedPoints: data.LOYDT.earned_points,
                PointsLastUpdatedDate: data.LOYDT.points_last_updated_date,
                LoyaltyJoinedDate: data.LOYDT.loyalty_joined_date,
                TierId: data.LOYDT.tier,
                TierName: data.LOYDT.tier_name || null,
                BankedCurrency: data.LOYDT.banked_currency || null,
                UnbankedPoints: data.LOYDT.unbanked_points || null,
            } : null,
            Offers: data.Offers || null,
            DefaultStore: data.DEFST ? {
                StoreKey: data.DEFST.SKEY,
                StreetNumber: data.DEFST.STRNO,
                Address1: data.DEFST.ADDR1,
                Address2: data.DEFST.ADDR2,
                CountryId: data.DEFST.CID
            } : null
        };

        return user;
    }


    /**************************
     ****** Unsubscribe *******
     **************************/
    public unsubscribe = async (storeKey: string, profileId: number, reasons: number[], campaignRunId: number, otherReason: string, localAolo: IaOLO): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": storeKey, "profileId": profileId, "reasons": reasons, "campaignRunId": campaignRunId, "otherReason": otherReason }), localAolo.pkey);
        let response = await this._httpRequest(false, 'POST', `${this._apiProfileUrl}unsubscribe`, localAolo, payload, null);
        return (response.status == 200);
    }

    public resubscribe = async (storeKey: string, profileId: number, localAolo: IaOLO): Promise<boolean> => {
        const payload = await Util.encrypt(JSON.stringify({ "storeKey": storeKey, "profileId": profileId }), localAolo.pkey);
        let response = await this._httpRequest(false, 'POST', `${this._apiProfileUrl}resubscribe`, localAolo, payload, null);
        return (response.status == 200);
    }

    /**************************
     **** Landing Banner ******
     **************************/

    /**
     * Loads the banner data associated with the given store key
     * @async
     * @param {string} storeKey 
     * @return {Promise<string>} 
     */
    public getLandingBanner = async (storeKey: string | null | undefined, localAolo: IaOLO): Promise<string> => {
        if (!storeKey)
            return '{}';

        let response = await this._httpRequest(false, "GET", `${this._apiProfileUrl}landing-banner/?storeKey=${storeKey}`, localAolo, null, null);
        if (response.status == 200) {
            let respData = await AdoraHttpClient.getResponsePayload(response);
            if (respData.BAN && respData.BAN != "")
                return respData.BAN;
        }
        return '{}';
    }
}