/**
 * Class for two-way binding between an HTML element and a JavaScript value.
 */
export class TwoWayBinding {
    /**
     * @private
     * @param {boolean} - Indicates whether the bound value has been changed since the last update.
     */
    _dirty: boolean = false;
    /**
     * @private
     * @param {string | boolean} - The current value of the bound property.
     */
    _value: string | boolean;
    /**
     * @private
     * @param {Object} - The object that contains the bound property.
     */
    _obj: any;
    /**
     * @private
     * @param {string} - The name of the bound property.
     */
    _key: string;
    /**
     * @private
     * @param {HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement | HTMLDivElement | any} - The HTML element to which the property is bound.
     */
    _el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement | HTMLDivElement | any;

    /**
     * Creates a new TwoWayBinding instance.
     * @param {Object} obj - The object that contains the property to be bound.
     * @param {string} key - The name of the property to be bound.
     * @param {HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement | HTMLDivElement | any} el - The HTML element to be bound to the property.
     * @throws {Error} If the parameters passed to the constructor are invalid.
     */
    constructor(obj: any, key: string, el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement | HTMLDivElement | any) {
        if (!obj) {
            throw new Error("Invalid object passed to TwoWayBinding constructor.");
        }

        if (!key || typeof key !== "string") {
            throw new Error("Invalid key passed to TwoWayBinding constructor.");
        }

        if (!el) {
            throw new Error("Invalid element passed to TwoWayBinding constructor.");
        }

        if (!(el instanceof HTMLInputElement) && !(el instanceof HTMLTextAreaElement) && !(el instanceof HTMLSelectElement) && !(el instanceof HTMLButtonElement) && !(el instanceof HTMLDivElement)) {
            throw new Error("Invalid element type passed to TwoWayBinding constructor.");
        }

        this._cleanInputsSetDefault(obj, key, el);

        //@ts-ignore
        switch (el.type) {
            case "checkbox":
                //@ts-ignore
                this._value = el.checked;
                break;
            case "tel":
                //@ts-ignore
                this._value = formatPhoneNumber(el.value, Names("PhoneFormat", "en-us"));
                break;
            default:
                //@ts-ignore
                this._value = el.value;
                break;
        }
        this._obj = obj;
        this._key = `${el.id ? el.id : ""}_${key}`;
        this._el = el;

        // Add the data-bind attribute to the element
        this._el.setAttribute("data-bind", this._key);

        this._el.addEventListener('input', this._handleInput, { capture: true });
        this._el.addEventListener('change', this._handleInput, { capture: true });

        Object.defineProperty(obj, key, {
            get: () => {
                return this._value;
            },
            set: (newValue: string | boolean) => {
                if (newValue !== this._value) {
                    this._value = newValue;
                    this._update();
                }
            },
        });
    }

    /**
     * Gets the dirty property.
     * @returns {boolean} - The value of the dirty property.
     */
    get dirty() {
        return this._dirty;
    }

    /**
     * Sets the dirty property.
     * @param {boolean} value - The value to set.
     */
    set dirty(value) {
        this._dirty = value;
    }

    /**
     * Sets the input element's value or checked property based on the key value from the object.
     * @private
     * @param {Object} obj - The object that contains the key-value pair to be set in the input element.
     * @param {string} key - The key to be retrieved from the object to be set in the input element.
     * @param {(HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement | HTMLDivElement | any)} el - The input element to be updated.
     */
    _cleanInputsSetDefault = (obj: any, key: string, el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLButtonElement | HTMLDivElement | any) => {
        //@ts-ignore
        switch (el.type) {
            case "checkbox":
                //@ts-ignore
                el.checked = obj[key];
                break;
            default:
                //@ts-ignore
                el.value = obj[key];
                break;
        }
    }

    /**
     * Handles the "input" event fired by the HTML element.
     * Updates the bound value if the input value has changed.
     * @private
     */
    _handleInput = () => {
        //@ts-ignore
        let newValue;
        switch (this._el.type) {
            case "checkbox":
                //@ts-ignore
                newValue = this._el.checked;
                break;
            case "tel":
                //@ts-ignore
                newValue = cleanPhoneNumber(formatPhoneNumber(this._el.value, Names("PhoneFormat", "en-us")));
                break;
            default:
                //@ts-ignore
                newValue = this._el.value;
                break;
        }
        if (newValue !== this._value) {
            this._value = newValue;
            this._update();
        }
    }

    /**
     * Updates the input element's value or checked property based on the stored value in the class.
     * @private
     */
    _update() {
        const el = document.querySelector(`[data-bind="${this._key}"]`);
        if (!el) {
            console.error(`Element with data-bind="${this._key}" not found.`);
            return;
        }

        if (typeof this._value === "boolean") {
            if (!(el instanceof HTMLInputElement)) {
                console.error(`Invalid element type for boolean value: ${el.tagName}.`);
                return;
            }
            el.checked = this._value;
            this._dirty = true;
        } else {
            if (!(el instanceof HTMLInputElement) && !(el instanceof HTMLTextAreaElement) && !(el instanceof HTMLSelectElement) && !(el instanceof HTMLButtonElement) && !(el instanceof HTMLDivElement)) {
                console.error(`Invalid element type for string value: ${el.tagName}.`);
                return;
            }
            //@ts-ignore
            el.value = this._value;
            this._dirty = true;
        }
    }

    /**
     * Removes the event listener for the input element.
     */
    removeEventListeners() {
        this._el.removeEventListener('input', this._handleInput, { capture: true });
        this._el.removeEventListener('change', this._handleInput, { capture: true });
    }
}