import { IDataCoupon } from "../interfaces/data.interface";
import { IOrderItem } from "../interfaces/order.interface";
import { IDataLayer, IDataLayer_Ecommerce_Item } from './interfaces/data-layer-service.interface';
import { Common } from "../common";
import { PaymentType } from "../types/enums";

/**
 * Class to handle interactions with the Google Analytics dataLayer.
 */
export class DataLayerService {
    viewedItems: number[] = [];
    viewedCoupons: number[] = [];

    /**
     * Method to clear the previous ecommerce object from Google Analytics.
     * This should be called before setting a new ecommerce object.
     */
    private _clearPreviousEcommerceObject() {
        //@ts-ignore
        if (!dataLayer) return;
        //@ts-ignore
        dataLayer.push({ ecommerce: null });
    }

    /** 
     * Fires when a promotion banner is displayed to a user(i.e.homepage hero or promotional tile) and includes:
     *  - promotion id, promotion name, creative name, creative slot, location id, items
     *      
     * Note: The preferred implementation of Promotion Impressions would be to only send the impressions once 80 % of the promotion 
     * is visible to the user on the page meaning new impressions would be sent as the user scrolls.
     * This would require a technical solution and additional scripting beyond the sample datalayer script provided.
     */
    public view_promotion(couponIds: number[]): void {
        this._clearPreviousEcommerceObject();

        const items: IDataLayer_Ecommerce_Item[] = [];
        for (const [index, couponId] of couponIds.entries()) {
            if (this.viewedCoupons.includes(couponId))
                continue;
            else
                this.viewedCoupons.push(couponId);

            const mCoupon = aOLO.data.Coupons.find(x => x.CouponId === couponId);

            if (!mCoupon)
                continue;

            items.push({
                item_id: mCoupon.CouponId.toString(),
                coupon: "",//Common.GetName(mCoupon.Names, aOLO.Temp.languageCode, 'en-us'),
                index: index,
                promotion_id: mCoupon.CouponId.toString(),
                promotion_name: Common.GetName(mCoupon.Names, aOLO.Temp.languageCode, 'en-us')
            });
        }

        const dataLayerObject: IDataLayer = {
            event: "view_promotion",
            ecommerce: {
                items: items
            }
        };

        //@ts-ignore
        if (items.length > 0 && !!dataLayer)
            //@ts-ignore
            dataLayer.push(dataLayerObject);
    }

    /**
     * Select_promotion: fires when a user clicks on a specific promotion and includes: 
     *  - promotion id, promotion name, creative name, creative slot, location id, items
     */
    public select_promotion(coupon: IDataCoupon, fromUrl: boolean, couponCode?: string | null): void {
        //@ts-ignore
        if (!dataLayer) return;

        this._clearPreviousEcommerceObject();

        const dataLayerObject: IDataLayer = {
            event: "select_promotion",
            ecommerce: {
                items: [{
                    coupon: couponCode ? couponCode : "", 
                    currency: "USD",
                    index: 0,
                    promotion_id: coupon.CouponId.toString(),
                    promotion_name: Common.GetName(coupon.Names, aOLO.Temp.languageCode, 'en-us'), 
                    quantity: 1
                }]
            }
        };

        if (fromUrl) {
            dataLayerObject.ecommerce.creative_name = "Home Promotion";
            dataLayerObject.ecommerce.creative_slot = "Home Promotion - Tile 1";
        }

        //@ts-ignore
        dataLayer.push(dataLayerObject);
    }

    /**
     * view_item_list: fires whenever any product is displayed to a user and includes:  
     *  - item_id, item_name, affiliation, coupon, discount, index, item_brand, item_category, item_category2(3/4/5), 
     *    item_list_id, item_list_name, item_variant, location_id, price
     * 
     * Note: The preferred implementation of Product Impressions would be to only send the impressions once 80% of the 
     * product is visible to the user on the page meaning new impressions would be sent as the user scrolls.
     * This would require a technical solution and additional scripting beyond the sample datalayer script provided. 
     */
    public view_item_list(itemIds: number[]): void {
        //@ts-ignore
        if (!dataLayer) return;

        this._clearPreviousEcommerceObject();

        const items: IDataLayer_Ecommerce_Item[] = [];
        for (const [index, itemId] of itemIds.entries()) {
            if (this.viewedItems.includes(itemId))
                continue;
            else
                this.viewedItems.push(itemId);

            const mItem = aOLO.data.Items.find(x => x.ItemId === itemId);

            if (!mItem)
                continue;

            const category = aOLO.data.Categories.find(x => x.ItemWebCategoryId == mItem.WebCategoryId);
            const categoryName = category ? Common.GetName(category.Names, aOLO.Temp.languageCode, "en-us") : "";

            items.push({
                item_id: mItem.ItemId.toString(),
                item_name: Common.GetName(mItem.Names, aOLO.Temp.languageCode),
                currency: "USD",
                index: index,
                item_category: categoryName
            });
        }

        const dataLayerObject: IDataLayer = {
            event: "view_item_list",
            ecommerce: {
                items: items
            }
        };

        if (items.length > 0)
            //@ts-ignore
            dataLayer.push(dataLayerObject);
    }

    /**
     * view_item: fires when a user views a specific product (after product click) and includes:  
     *  - item_id, item_name, affiliation, coupon, discount, item_brand, item_category, item_category2(3/4/5), 
     *    item_list_id, item_list_name, item_variant, location_id, price, item_variant
     */
    public view_item(orderItem: IOrderItem): void {
        //@ts-ignore
        if (!dataLayer) return;

        this._clearPreviousEcommerceObject();

        const dataLayerObject: IDataLayer = {
            event: "view_item",
            ecommerce: {
                items: []
            }
        };

        const items = this.get_checkout_items([orderItem]);

        dataLayerObject.ecommerce.items = items;
        //@ts-ignore
        dataLayer.push(dataLayerObject);
    }

    /**
     * add_to_cart: fires when a user adds or removes an item to and from their cart and includes:
     *  - item_id, item_name, affiliation, coupon, discount, item_brand, item_category, item_category2(3/4/5), 
     *    item_list_id, item_list_name, item_variant, location_id, price, item_variant 
     */
    public add_to_cart(orderItem: IOrderItem | undefined): void {
        //@ts-ignore
        if (!dataLayer) return;

        this._clearPreviousEcommerceObject();

        if (!orderItem)
            return;

        const dataLayerObject: IDataLayer = {
            event: "add_to_cart",
            ecommerce: {
                items: []
            }
        };

        const items = this.get_checkout_items([orderItem]);

        dataLayerObject.ecommerce.items = items;
        //@ts-ignore
        dataLayer.push(dataLayerObject);
    }

    /**
     * Method to record a "remove_from_cart" event in Google Analytics. This should fire when a user adds or removes an item to/from their cart.
     * @param {IOrderItem} orderItem - The order item associated with the event.
     */
    public remove_from_cart(orderItem: IOrderItem): void {
        //@ts-ignore
        if (!dataLayer) return;

        this._clearPreviousEcommerceObject();

        const dataLayerObject: IDataLayer = {
            event: "remove_from_cart",
            ecommerce: {
                items: []
            }
        };

        const items = this.get_checkout_items([orderItem]);

        dataLayerObject.ecommerce.items = items;
        //@ts-ignore
        dataLayer.push(dataLayerObject);
    }

    /**
     * Method to record a "begin_checkout" event in Google Analytics. This should fire when the Checkout screen is opened.
     * @param {IOrderItem[]} orderItems - The order items associated with the checkout.
     */
    public begin_checkout(orderItems: IOrderItem[]): void {
        //@ts-ignore
        if (!dataLayer) return;

        this._clearPreviousEcommerceObject();

        const dataLayerObject: IDataLayer = {
            event: "begin_checkout",
            ecommerce: {
                items: []
            }
        };

        const items = this.get_checkout_items(orderItems);

        dataLayerObject.ecommerce.items = items;
        //@ts-ignore
        dataLayer.push(dataLayerObject);
    }

    /**
     * Method to record an "add_shipping_info" event in Google Analytics. This should be decided at the beginning of the order, but recorded on checkout open page.
     * @param {IOrderItem[]} orderItems - The order items associated with the shipping.
     */
    public add_shipping_info(orderItems: IOrderItem[]): void {
        //@ts-ignore
        if (!dataLayer) return;

        this._clearPreviousEcommerceObject();

        const orderType = aOLO.data.OrderTypeSubTypes.find(x => x.OrderTypeId === aOLO.Order.OrderTypeID);

        let couponsName = "";
        for (const c of aOLO.Order.Coupons) {
            const coupon = aOLO.data.Coupons.find(x => x.CouponId == c.CouponId);
            if (coupon)
                couponsName += `${Common.GetName(coupon.Names, aOLO.Temp.languageCode, "en-us")}, `;
        }

        const dataLayerObject: IDataLayer = {
            event: "add_shipping_info",
            ecommerce: {
                currency: "USD",
                value: aOLO.Order.AmountDue,
                coupon: couponsName,
                shipping_tier: orderType ? Common.GetName(orderType.Names, aOLO.Temp.languageCode) : "",
                items: []
            }
        };

        const items = this.get_checkout_items(orderItems);

        dataLayerObject.ecommerce.items = items;
        //@ts-ignore
        dataLayer.push(dataLayerObject);
    }

    /**
     * Method to record a "add_payment_info" event in Google Analytics.
     * @param {IOrderItem[]} orderItems - The order items associated with the payment.
     */
    public add_payment_info(orderItems: IOrderItem[]): void {
        //@ts-ignore
        if (!dataLayer) return;

        this._clearPreviousEcommerceObject();

        const orderType = aOLO.data.OrderTypeSubTypes.find(x => x.OrderTypeId === aOLO.Order.OrderTypeID);

        let couponsName = "";
        for (const c of aOLO.Order.Coupons) {
            const coupon = aOLO.data.Coupons.find(x => x.CouponId == c.CouponId);
            if (coupon)
                couponsName += `${Common.GetName(coupon.Names, aOLO.Temp.languageCode, "en-us")}, `;
        }

        const dataLayerObject: IDataLayer = {
            event: "add_payment_info",
            ecommerce: {
                currency: "USD",
                value: aOLO.Order.AmountDue,
                coupon: couponsName,
                shipping_tier: orderType ? Common.GetName(orderType.Names, aOLO.Temp.languageCode) : "",
                payment_type: this.getPaymentTypeName(),
                items: []
            }
        };

        const items = this.get_checkout_items(orderItems);

        dataLayerObject.ecommerce.items = items;
        //@ts-ignore
        dataLayer.push(dataLayerObject);
    }

    /**
     * Method to record a "purchase" event in Google Analytics.
     * @param {IOrderItem[]} orderItems - The order items associated with the purchase.
     * @param {number} orderId - The ID of the order that was purchased.
     */
    public purchase(orderItems: IOrderItem[], orderId: number): void {
        //@ts-ignore
        if (!dataLayer) return;

        this._clearPreviousEcommerceObject();

        const orderType = aOLO.data.OrderTypeSubTypes.find(x => x.OrderTypeId === aOLO.Order.OrderTypeID);

        let couponsName = "";
        let promotionIds = "";
        let couponsCods = "";
        for (const c of aOLO.Order.Coupons) {
            const coupon = aOLO.data.Coupons.find(x => x.CouponId == c.CouponId);
            if (coupon) {
                couponsName += `${Common.GetName(coupon.Names, aOLO.Temp.languageCode, "en-us")}, `;
                promotionIds += `${coupon.CouponId}, `;
                if (c.CouponCode)
                    couponsCods += `${c.CouponCode}, `;
            }
        }

        const dataLayerObject: IDataLayer = {
            event: "purchase",
            ecommerce: {
                transaction_id: orderId.toString(),
                currency: "USD",
                value: aOLO.Order.AmountDue,
                tax: aOLO.Order.Tax,
                coupon: couponsCods,
                shipping: aOLO.Order.OrderTypeCharge,
                payment_type: this.getPaymentTypeName(),
                promotion_id: promotionIds,
                shipping_tier: orderType ? Common.GetName(orderType.Names, aOLO.Temp.languageCode) : "",
                promotion_name: couponsName,
                items: []
            }
        };

        const items = this.get_checkout_items(orderItems);

        dataLayerObject.ecommerce.items = items;
        //@ts-ignore
        dataLayer.push(dataLayerObject);
    }

    /**
     * Method to get checkout items for the dataLayer.
     * @param {IOrderItem[]} items - The items to be checked out.
     * @returns {IDataLayer_Checkout_Item[]} - The checkout items for the dataLayer.
     */
    private get_checkout_items(items: IOrderItem[]): IDataLayer_Ecommerce_Item[] {
        const finalItems: IDataLayer_Ecommerce_Item[] = [];

        for (const [index, item] of items.entries()) {
            const dataLayerItem: IDataLayer_Ecommerce_Item = {
                item_id: item.ItemId.toString(),
                item_name: "",
                currency: "USD",
                index: index,
                item_category: "",
                item_category2: "",
                item_category4: "",
                item_category5: "",
                price: item.Price,
                quantity: item.Quantity
            };

            const dataItem = aOLO.data.Items.find(x => x.ItemId == item.ItemId);
            dataLayerItem.item_name = dataItem ? Common.GetName(dataItem.Names, aOLO.Temp.languageCode, "en-us") : "";

            const category = aOLO.data.Categories.find(x => x.ItemWebCategoryId == dataItem?.WebCategoryId);
            dataLayerItem.item_category = category ? Common.GetName(category.Names, aOLO.Temp.languageCode, "en-us") : "";

            const size = aOLO.data.Sizes.find(x => x.SizeId == item.SizeId);
            dataLayerItem.item_category2 = size ? Common.GetName(size.Names, aOLO.Temp.languageCode, "en-us") : "";

            for (const mod of item.Modifiers) {
                const mMod = aOLO.data.Modifiers[mod.Index];
                if (mMod.IsCrust)
                    dataLayerItem.item_category4 += Common.GetName(mMod.Names, aOLO.Temp.languageCode);
                else if (!mod.IsDefault)
                    dataLayerItem.item_category5 += `${Common.GetName(mMod.Names, aOLO.Temp.languageCode)}, `;
            }

            // Discounts
            const itemDiscount = aOLO.Order.ItemsCoupons.find(x => x.ItemKey === item.ItemKey);
            if (itemDiscount) {
                const discount = itemDiscount.AfterTaxDiscount + itemDiscount.BeforeTaxDiscount;
                dataLayerItem.discount = discount;

                const orderCoupon = aOLO.Order.Coupons.find(x => x.CouponKey === itemDiscount.CouponKey);
                if (orderCoupon) {
                    const coupon = aOLO.data.Coupons.find(x => x.CouponId === orderCoupon.CouponId);
                    dataLayerItem.coupon = coupon ? Common.GetName(coupon.Names, aOLO.Temp.languageCode) : "";
                }
            }

            finalItems.push(dataLayerItem);
        }

        return finalItems;
    }

    /**
     * Method to get the payment type name.
     * @returns {string} - The name of the payment type.
     */
    private getPaymentTypeName(): string {
        let paymentTypeName = "";
        switch (aOLO.Order.PaymentType) {
            case PaymentType.PAY_IN_STORE:
                paymentTypeName = "Pay In Store";
                break;
            case PaymentType.CASH:
                paymentTypeName = "Cash";
                break;
            case PaymentType.CREDIT_CARD_MOBILE:
                paymentTypeName = "Credit Card";
                break;
            case PaymentType.CREDIT_CARD:
                paymentTypeName = "Credit Card";
                break;
            case PaymentType.WALLET_CREDIT_CARD:
                paymentTypeName = "Customer Saved Credit Card";
                break;
        }
        return paymentTypeName;
    }

    private itemListViewed(): void {
        const couponList = document.getElementById("div_items");
        if (!couponList || couponList.classList.contains("hidden"))
            return;

        const divs = document.getElementsByName('div_menu_item');
        let items: number[] = [];

        divs.forEach(div => {
            let rect = div.getBoundingClientRect();
            let isVisible = (rect.top >= 0);

            if (isVisible) {
                let visibleHeight = window.innerHeight - rect.top;
                let totalHeight = rect.bottom - rect.top;
                let visiblePercentage = visibleHeight / totalHeight;

                if (visiblePercentage >= 0.7)
                    items.push(Number(div.dataset.iid));
            }
        });

        if (items.length > 0)
            this.view_item_list(items);
    }

    private couponListViewed(): void {
        const couponList = document.getElementById("div_coupons");
        if (!couponList || couponList.classList.contains("hidden"))
            return;

        const divs = document.querySelectorAll("div.p1.coupon") as NodeListOf<HTMLElement>;
        let items: number[] = [];

        divs.forEach(div => {
            let rect = div.getBoundingClientRect();
            let isVisible = (rect.top >= 0);

            if (isVisible) {
                let visibleHeight = window.innerHeight - rect.top;
                let totalHeight = rect.bottom - rect.top;
                let visiblePercentage = visibleHeight / totalHeight;

                if (visiblePercentage >= 0.7)
                    items.push(Number(div.dataset.cid));
            }
        });

        if (items.length > 0)
            this.view_promotion(items);
    }

    private mealDealListViewed(): void {
        const divs = document.getElementsByName("mealDealCart");
        let items: number[] = [];

        divs.forEach(div => {
            let rect = div.getBoundingClientRect();
            let isVisible = (rect.top >= 0);

            if (isVisible) {
                let visibleHeight = window.innerHeight - rect.top;
                let totalHeight = rect.bottom - rect.top;
                let visiblePercentage = visibleHeight / totalHeight;

                if (visiblePercentage >= 0.7)
                    items.push(Number(div.dataset.cid));
            }
        });

        if (items.length > 0)
            this.view_promotion(items);
    }

    public itemScrollListeners(): void {
        const self = this;
        window.removeEventListener('scroll', self.throttle(self.itemListViewed.bind(self), 250));
        window.removeEventListener('scroll', self.throttle(self.couponListViewed.bind(self), 250));
        window.removeEventListener('scroll', self.throttle(self.mealDealListViewed.bind(self), 250));

        setTimeout(() => {
            this.itemListViewed();
            window.addEventListener('scroll', self.throttle(self.itemListViewed.bind(self), 250));
        }, 1000);
    }

    public couponScrollListeners(): void {
        const self = this;
        window.removeEventListener('scroll', self.throttle(self.itemListViewed.bind(self), 250));
        window.removeEventListener('scroll', self.throttle(self.couponListViewed.bind(self), 250));
        window.removeEventListener('scroll', self.throttle(self.mealDealListViewed.bind(self), 250));

        setTimeout(() => {
            self.couponListViewed();
            window.addEventListener('scroll', self.throttle(self.couponListViewed.bind(self), 250));
        }, 1000);
    }

    public mealDealScrollListeners(): void {
        const self = this;
        window.removeEventListener('scroll', self.throttle(self.itemListViewed.bind(self), 250));
        window.removeEventListener('scroll', self.throttle(self.couponListViewed.bind(self), 250));
        window.removeEventListener('scroll', self.throttle(self.mealDealListViewed.bind(self), 250));

        setTimeout(() => {
            self.mealDealListViewed();
            window.addEventListener('scroll', self.throttle(self.mealDealListViewed.bind(self), 250));
        }, 1000);
    }

    private throttle(func: (...args: any[]) => void, limit: number): (...args: any[]) => void {
        let inThrottle: boolean;

        return function (this: any, ...args: any[]): void {
            if (!inThrottle) {
                func.apply(this, args);
                inThrottle = true;
                setTimeout(() => inThrottle = false, limit);
            }
        }
    }
}