import { IVerticalMenuFunctions } from "./interfaces/vertical-menu.interface";
import { Common } from "../common";
import { DialogCreators } from "../utils/dialog-creators";
import { Names } from "../utils/i18n";
import { Util } from "../utils/util";
import { ChangePassword } from "./change-password";
import { MyHistory } from "./history";
import { LoyaltySummary } from "./loyalty-summary";
import { MyOrders } from "./my-orders";
import { Profile } from "./profile";
import { SignIn } from "./sign-in";

import '../../css/shared/vertical-menu.css';

export class VerticalMenu {
    private _functions: IVerticalMenuFunctions;

    constructor(functions: IVerticalMenuFunctions) {
        this._functions = functions;
        this._init();
    }

    private _init = (): void => {
        this._setEventListeners();
    }

    private _setEventListeners = (): void => {
        const isApp = Util.isAppView();

        if (this._functions.changePage !== null)
            this._setBrandMenuFunctions();
        else
            this._setHamburgerMenuFunctions();

        // FAQ
        const faqs = Array.from(document.getElementsByName("a_vertical_menu_faq")) as HTMLAnchorElement[];
        for (let a of faqs) {
            if (isApp)
                a.addEventListener("click", (event: MouseEvent) => {
                    event.preventDefault();
                    const href = (event.currentTarget as HTMLAnchorElement).dataset.href || "";

                    let iframe = document.createElement("iframe");
                    iframe.src = href;
                    iframe.style.width = "100%";
                    iframe.style.height = "77vh";

                    DialogCreators.messageBoxOk(iframe, aOLO.buttonHoverStyle);
                    Common.closeHamburgerMenu();
                });
            else {
                a.href = a.dataset.href || "";
                a.target = "_blank";
            }
        }

        // Terms and Conditions
        const termsAndConditions = Array.from(document.getElementsByName("a_vertical_terms_conditions")) as HTMLAnchorElement[];
        for (let a of termsAndConditions) {
            if (isApp)
                a.addEventListener("click", (event: MouseEvent) => {
                    event.preventDefault();
                    const href = (event.currentTarget as HTMLAnchorElement).dataset.href || "";

                    let iframe = document.createElement("iframe");
                    iframe.src = href;
                    iframe.style.width = "100%";
                    iframe.style.height = "77vh";

                    DialogCreators.messageBoxOk(iframe, aOLO.buttonHoverStyle);
                    Common.closeHamburgerMenu();
                });
            else {
                a.href = a.dataset.href || "";
                a.target = "_blank";
            }
        }

        // Contact Us
        const contactUs = Array.from(document.getElementsByName("a_vertical_menu_contact")) as HTMLAnchorElement[];
        for (let a of contactUs) {
            if (isApp)
                a.addEventListener("click", (event: MouseEvent) => {
                    event.preventDefault();
                    const href = (event.currentTarget as HTMLAnchorElement).dataset.href || "";

                    let iframe = document.createElement("iframe");
                    iframe.src = href;
                    iframe.style.width = "100%";
                    iframe.style.height = "77vh";

                    DialogCreators.messageBoxOk(iframe, aOLO.buttonHoverStyle);
                    Common.closeHamburgerMenu();
                });
            else {
                a.href = a.dataset.href || "";
                a.target = "_blank";
            }
        }
    }

    private _setBrandMenuFunctions(): void {
        const self = this;

        // Landing
        if (Util.isAppView()) {
            const landing = Array.from(document.getElementsByName("li_vertical_menu_home")) as HTMLLIElement[];
            for (let li of landing) {
                Util.setElement("onclick", li, (event: MouseEvent) => {
                    if (self._functions.changePage)
                        self._functions.changePage(event, 'landing');
                });
            }
        }

        // Sign In
        const signin = Array.from(document.getElementsByName("li_vertical_menu_signin")) as HTMLLIElement[];
        for (let li of signin) {
            Util.setElement("onclick", li, (event: MouseEvent) => {
                if (self._functions.changePage)
                    self._functions.changePage(event, 'signin');
            });
        }

        // Sign Up
        const signup = Array.from(document.getElementsByName("li_vertical_menu_signup")) as HTMLLIElement[];
        for (let li of signup) {
            Util.setElement("onclick", li, (event: MouseEvent) => {
                if (self._functions.changePage)
                    self._functions.changePage(event, 'signup');
            });
        }

        // Loyalty
        const loyalty = Array.from(document.getElementsByName("li_vertical_menu_rewards")) as HTMLLIElement[];
        for (let li of loyalty) {
            Util.setElement("onclick", li, (event: MouseEvent) => {
                if (self._functions.changePage)
                    self._functions.changePage(event, 'loyalty');
            });
        }

        // Profile
        const profile = Array.from(document.getElementsByName("li_vertical_menu_profile")) as HTMLLIElement[];
        for (let li of profile) {
            Util.setElement("onclick", li, (event: MouseEvent) => {
                if (self._functions.changePage)
                    self._functions.changePage(event, 'profile');
            });
        }

        // My Orders
        const myorders = Array.from(document.getElementsByName("li_vertical_menu_orders")) as HTMLLIElement[];
        for (let li of myorders) {
            Util.setElement("onclick", li, (event: MouseEvent) => {
                if (self._functions.changePage)
                    self._functions.changePage(event, 'myorders');
            });
        }

        // Locations
        const locations = Array.from(document.getElementsByName("li_vertical_menu_locations")) as HTMLLIElement[];
        for (let li of locations) {
            Util.setElement("onclick", li, (event: MouseEvent) => {
                if (self._functions.changePage)
                    self._functions.changePage(event, 'locations');
            });
        }

        // My History
        const history = Array.from(document.getElementsByName("li_vertical_menu_history")) as HTMLLIElement[];
        for (let li of history) {
            Util.setElement("onclick", li, (event: MouseEvent) => {
                if (self._functions.changePage)
                    self._functions.changePage(event, 'history');
            });
        }

        // Change Password
        const password = Array.from(document.getElementsByName("li_vertical_menu_password")) as HTMLLIElement[];
        for (let li of password) {
            Util.setElement("onclick", li, (event: MouseEvent) => {
                if (self._functions.changePage)
                    self._functions.changePage(event, 'password');
            });
        }

        // Logout
        const logoutLi = Array.from(document.getElementsByName("li_vertical_menu_logout")) as HTMLLIElement[];
        for (let li of logoutLi) {
            Util.setElement("onclick", li, () => {
                self._functions.logout(() => {
                    if (self._functions.brandDisplayPage)
                        self._functions.brandDisplayPage("signin", true);
                }, aOLO);
            });
        }
    }

    private _setHamburgerMenuFunctions(): void {
        const self = this;
        const menuItems = [
            { link: "signin", dia: "dia_signin", onSelect: self._loadSignIn },
            { link: "signup", dia: "dia_profile", onSelect: self._loadProfile },
            { link: "rewards", dia: "dia_loyalty", onSelect: self._loadMyRewards },
            { link: "profile", dia: "dia_profile", onSelect: self._loadProfile },
            { link: "orders", dia: "dia_myorders", onSelect: self._loadMyOrders },
            { link: "locations", dia: "dia_locations", onSelect: self._loadLocations },
            { link: "history", dia: "dia_history", onSelect: self._loadHistory },
            { link: "password", dia: "dia_password", onSelect: self._loadChangePassword }
        ];

        for (const item of menuItems) {
            const lis = Array.from(document.getElementsByName(`li_vertical_menu_${item.link}`));
            for (const li of lis)
                Util.setElement("onclick", li, () => {
                    Common.closeHamburgerMenu();
                    item.onSelect(self._functions);
                    Util.DialogFadeIn(item.dia, aOLO);
                });
        }

        const lis = Array.from(document.getElementsByName("li_vertical_menu_logout"));
        for (const li of lis)
            Util.setElement("onclick", li, () => {
                Common.closeHamburgerMenu();
                DialogCreators.messageBox(Names("AreYouSureSignOutOrderCleared"), aOLO.buttonHoverStyle, [
                    {
                        "text": Names("Yes"), "callBack": () => {
                            self._functions.logout(() => {
                                if (self._functions.startApp)
                                    self._functions.startApp(aOLO.Temp.languageCode);
                            }, aOLO);
                        }
                    },
                    { "text": Names("No"), "callBack": null }]);
            });

        // Landing
        if (Util.isAppView()) {
            const landing = Array.from(document.getElementsByName("li_vertical_menu_home")) as HTMLLIElement[];
            for (let li of landing) {
                Util.setElement("onclick", li, () => {
                    let url = `${location.origin}/brand/?sk=${aOLO.storeInfo.StoreKey}&mobile=true`;
                    window.location.href = url;
                });
            }
        }
    }

    /**
     * Loads the sign in page by instantiating a SignIn object.
     * @returns {void}
     */
    private _loadSignIn(functions: IVerticalMenuFunctions): void {
        aOLO.Dialog.SignIn = new SignIn(null, functions.setStartupDialogs, functions.signUpClick, functions.startOrderTypePage);
    }

    /**
     * Loads the loyalty summary page by instantiating a LoyaltySummary object.
     * @returns {void}
     */
    private _loadMyRewards(functions: IVerticalMenuFunctions): void {
        let signInInstance = new SignIn(null, functions.setStartupDialogs, functions.signUpClick, functions.startOrderTypePage);
        let profileInstance = new Profile(null, functions.GUI_SetOrder_Customer, signInInstance);
        aOLO.Dialog.LoyaltySummary = new LoyaltySummary(profileInstance, null);
    }

    /**
     * Loads the profile page by instantiating a Profile object.
     * @returns {void}
     */
    private _loadProfile(functions: IVerticalMenuFunctions): void {
        let signInInstance = new SignIn(null, functions.setStartupDialogs, functions.signUpClick, functions.startOrderTypePage);
        aOLO.Dialog.Profile = new Profile(null, functions.GUI_SetOrder_Customer, signInInstance);
    }

    /**
     * Loads the locations page by instantiating a Locations object.
     * @returns {void}
     */
    private async _loadLocations(functions: IVerticalMenuFunctions): Promise<void> {
        //if (!aOLO.Dialog.Locations || aOLO.Dialog.Locations == null)
        //    aOLO.Dialog.Locations = new Locations(null, JSON.parse(localStorage.MobileView), await aOLO.Modules.ProfileService.loadLocations(aOLO.storeInfo.BrandID, aOLO));
        //aOLO.Dialog.Locations.ViewLocations();
    }

    /**
     * Loads the my orders page by instantiating a MyOrders object.
     * @returns {void}
     */
    private async _loadMyOrders(functions: IVerticalMenuFunctions): Promise<void> {
        aOLO.Dialog.MyOrders = new MyOrders(null, functions.ReOrder);
    }

    /**
     * Loads the my history page by instantiating a MyHistory object.
     * @returns {void}
     */
    private async _loadHistory(functions: IVerticalMenuFunctions): Promise<void> {
        aOLO.Dialog.MyHistory = new MyHistory();
    }

    /**
     * Loads the change password page by instantiating a ChangePassword object.
     * @returns {void}
     */
    private _loadChangePassword(functions: IVerticalMenuFunctions): void {
        aOLO.Dialog.ChangePassword = new ChangePassword(null);
    }
}